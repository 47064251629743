import axios from './axios';
import { serviceUrls } from './serviceUrls';

export const addBusinessData = (data, config = null, axiosInstance = axios) => {
  return axiosInstance.post(serviceUrls().addBusiness, data, config);
};
export const updateBusinessData = (
  data,
  config = null,
  axiosInstance = axios
) => {
  return axiosInstance.post(serviceUrls().updateBusiness, data, config);
};

export const createBasicBusinessUser = (
  data,
  config = null,
  axiosInstance = axios
) => {
  return axiosInstance.post(serviceUrls().addBasicBusinessUser, data, config);
};

export const createBusinessUser = (
  data,
  config = null,
  axiosInstance = axios
) => {
  return axiosInstance.post(serviceUrls().addBusinessUser, data, config);
};

export const createBusinessUserWithDomain = (
  data,
  config = null,
  axiosInstance = axios
) => {
  return axiosInstance.post(serviceUrls().addBusinessUserDomain, data, config);
};

export const fetchBusinessInformation = (
  data,
  config = null,
  axiosInstance = axios
) => {
  return axiosInstance.post(
    serviceUrls().fetchBusinessDetails,
    data,
    config
  );
};

export const addBusinessAdminDetails = (
  data,
  config = null,
  axiosInstance = axios
) => {
  return axiosInstance.post(serviceUrls().addBusinessAdmin, data, config);
};

export const fetchBusinesseAdminStatus = (
  data,
  config = null,
  axiosInstance = axios
) => {
  return axiosInstance.post(serviceUrls().businesseAdminStatus, data, config);
};

export const fetchAllHRMangerListPaginated = (
  data,
  config = null,
  axiosInstance = axios
) => {
  return axiosInstance.post(
    serviceUrls().pageinatedAllBusinessUserList,
    data,
    config
  );
};

export const fetchBusinessUserDetailsList = (
  data,
  config = null,
  axiosInstance = axios
) => {
  return axiosInstance.post(serviceUrls().fetchBusinessUserList, data, config);
};

export const fetchBusinessesListPaginated = (
  data,
  config = null,
  axiosInstance = axios
) => {
  return axiosInstance.post(serviceUrls().fetchBusinessesListUrl, data, config);
};

export const fetchBusinessesUsersPaginated = (
  data,
  config = null,
  axiosInstance = axios
) => {
  return axiosInstance.post(
    serviceUrls().fetchBusinessHrManagers,
    data,
    config
  );
};

export const fetchTsTeamListPaginated = (
  data,
  config = null,
  axiosInstance = axios
) => {
  return axiosInstance.post(serviceUrls().fetchTsTeamListingUrl, data, config);
};


export const fetchUserEstimate = (
  data,
  config = null,
  axiosInstance = axios
) => {
  return axiosInstance.post(serviceUrls().userEstimate, data, config);
};

export const startInformation = (
  data,
  config = null,
  axiosInstance = axios
) => {
  return axiosInstance.post(serviceUrls().startInfo, data, config);
};

export const uploadBusinessLogo = (
  data,
  config = null,
  axiosInstance = axios
) => {
  return axiosInstance.post(serviceUrls().uploadBusinessLogo, data, config);
};

export const getBusinessLogo = (data, config = null, axiosInstance = axios) => {
  return axiosInstance.post(serviceUrls().getBusinessLogo, data, config);
};

export const changeBusinessUserStatus = (
  data,
  config = null,
  axiosInstance = axios
) => {
  return axiosInstance.patch(serviceUrls().businessUserStatus, data, config);
};

export const updateBusinessUserLevel = (
  data,
  config = null,
  axiosInstance = axios
) => {
  return axiosInstance.patch(serviceUrls().businessUserLevel, data, config);
};

export const updateBusinessSurvey = (
  data,
  config = null,
  axiosInstance = axios
) => {
  return axiosInstance.post(serviceUrls().updateBusinessSurvey, data, config);
};

export const inviteTeamMember = (
  data,
  config = null,
  axiosInstance = axios
) => {
  return axiosInstance.post(serviceUrls().inviteTeamMember, data, config);
};
