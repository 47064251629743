import React from 'react';
import { Icon } from '@chakra-ui/react';
import {
  MdLockClock,
  MdSupervisorAccount,
  MdOutlineRunCircle,
  MdCalendarMonth,
  MdPinInvoke,
  MdThumbUpOffAlt,
  MdMarkEmailRead,
} from 'react-icons/md';
import {
  AiOutlineCalendar,
  AiOutlinePuzzle,
  AiOutlineSetting,
  AiTwotoneSkin,
} from 'react-icons/ai';
import { LiaTelegram, LiaTelegramPlane } from "react-icons/lia";
import { IoPersonCircleOutline } from "react-icons/io5";
import { BsFillPersonFill, BsFillPersonVcardFill, BsPersonArmsUp, BsPersonBadge, BsPersonLinesFill, BsPersonPlusFill, BsPersonVcard } from 'react-icons/bs';
import { RiBillLine } from 'react-icons/ri';
import { SiGooglemeet } from 'react-icons/si';
import { LuClipboardCheck } from "react-icons/lu";
// Default Imports
// import NFTMarketplace from '../views/admin/nfts/marketplace';
// import Profile from '../views/admin/nfts/profile';
// import Collection from '../views/admin/nfts/collection';
// import NFTPage from '../views/admin/nfts/page';

// Admin Imports
import JobList from '../tscomponents/business/jobWizard/JobList';
import JobWizard from '../tscomponents/business/jobWizard/JobWizard';
import ProfileView from '../tscomponents/business/profile/view/ProfileView';
import BusinessTeam from '../tscomponents/business/team/listing/BusinessTeam';
import StripeBilling from '../tscomponents/business/settings/billing/StripeBilling';

// chargebee
import PurchasePage from '../tscomponents/business/settings/billing/PurchasePage';
import CompletePurchase from '../tscomponents/business/settings/billing/CompletePurchase';

// stripe
import PurchasePageStripe from '../tscomponents/business/settings/billing/PurchasePageStripe';
import CompletePurchaseStripe from '../tscomponents/business/settings/billing/CompletePurchaseStripe';

import CancelSubscription from '../tscomponents/business/settings/billing/CancelSubscription';
import ModifySubscription from '../tscomponents/business/settings/billing/ModifySubscription';
import UpgradeSubscription from '../tscomponents/business/settings/billing/UpgradeSubscription';
// import BusinessBilling from '../tscomponents/business/settings/billing/BusinessBilling';
import BusinessScheduling from '../tscomponents/business/scheduling/BusinessScheduling';
import EditJobDetails from '../tscomponents/business/jobWizard/editJobs/EditJobDetails';
// import HiringProcess from '../tscomponents/business/jobWizard/jobWorkflow/Workflow/HiringProcess';
// import CandidateProcessing from '../tscomponents/business/jobWizard/jobWorkflow/Workflow/CandidateProcessing/CandidateProcessing';
// import InterviewProcessing from '../tscomponents/business/jobWizard/jobWorkflow/Workflow/InterviewProcessing/InterviewProcessing';
import BusinessConfiguration from '../tscomponents/business/settings/configuration/BusinessConfiguration';
// import WorkflowFinalization from '../tscomponents/business/jobWizard/jobWorkflow/Workflow/WorkflowFinalization';

// import ReadyToInterviewList from '../tscomponents/business/applicants/listingbyjob/ReadyToInterviewList';
import ReadyToInterviewCandidates from '../tscomponents/business/applicants/listingbyjob/ReadyToInterviewCandidates';
import CandidateOnboarding from '../tscomponents/business/onboarding/CandidateOnboarding';

import ViewJobDetails from '../tscomponents/business/jobWizard/viewJobs/ViewJobDetails';
// import ViewEditCandidateSkills from '../tscomponents/business/applicants/listingbyjob/ViewEditCandidateSkills';
// import JobWorkflow from '../tscomponents/business/jobWizard/jobWorkflow/Workflow/JobWorkflow';
import JobWorkflowCommon from '../tscomponents/common/JobWorkflow';
import CandidateProfileView from '../tscomponents/common/CandidateProfileView';

import BusinessIntegration from '../tscomponents/business/settings/integration/BusinessIntegration';
import HrIntegrationCore from '../tscomponents/business/settings/integration/HRIntegrationCore';
import HrIntegrationVerify from '../tscomponents/business/settings/integration/HrIntegrationVerify';
import HRAndATS from '../tscomponents/business/settings/integration/HRAndATS';
import EmailTemplateList from '../tscomponents/common/EmailTemplateList';
import { EmailIcon } from '@chakra-ui/icons';

const businessRoutes = [
  {
    name: 'Team',
    path: '/team',
    layout: '/business',
    component: <BusinessTeam />,
    icon: (
      <Icon
        as={MdSupervisorAccount}
        width="20px"
        height="20px"
        color="inherit"
      />
    ),
    collapse: false,
    showToMenu: true,
  },
  {
    name: 'Jobs',
    layout: '/business',
    path: '/joblist',
    component: <JobList />,
    icon: (
      <Icon as={LiaTelegramPlane} width="20px" height="20px" color="inherit" />
    ),
    collapse: false,
    showToMenu: true,
  },
  {
    name: 'Job Intake Form',
    layout: '/business',
    path: '/addJobWizard',
    component: <JobWizard />,
    secondary: true,
    icon: (
      <Icon as={MdThumbUpOffAlt} width="20px" height="20px" color="inherit" />
    ),
    showToMenu: false,
    collapse: false,
  },
  {
    name: 'Edit Job Wizard',
    layout: '/business',
    path: '/editJobWizard',
    component: <JobWizard />,
    secondary: true,
    icon: (
      <Icon as={MdThumbUpOffAlt} width="20px" height="20px" color="inherit" />
    ),
    showToMenu: false,
    collapse: false,
  },
  {
    name: 'Edit Jobs',
    layout: '/business',
    path: '/editJobs',
    component: <EditJobDetails />,
    secondary: true,
    icon: (
      <Icon as={MdThumbUpOffAlt} width="20px" height="20px" color="inherit" />
    ),
    showToMenu: false,
    collapse: false,
  },
  {
    name: 'View Job',
    layout: '/business',
    path: '/viewJob',
    component: <ViewJobDetails />,
    secondary: true,
    icon: (
      <Icon as={MdThumbUpOffAlt} width="20px" height="20px" color="inherit" />
    ),
    showToMenu: false,
    collapse: false,
  },
  {
    name: 'Job Sourcing',
    layout: '/business',
    path: '/jobsourcing',
    component: <JobWorkflowCommon />, //Business Kanban view
    secondary: true,
    icon: (
      <Icon as={MdThumbUpOffAlt} width="20px" height="20px" color="inherit" />
    ),
    showToMenu: false,
    collapse: false,
  },
  {
    name: 'Candidate Profile',
    layout: '/business',
    path: '/jobsourcing/candidateview',
    component: <CandidateProfileView />, //Business Kanban view > Candidate View
    secondary: true,
    icon: (
      <Icon as={MdThumbUpOffAlt} width="20px" height="20px" color="inherit" />
    ),
    showToMenu: false,
    collapse: false,
  },
  // {
  //   name: 'Interview Processing',
  //   layout: '/business',
  //   path: '/interviewProcess',
  //   component: <InterviewProcessing />,
  //   secondary: true,
  //   icon: (
  //     <Icon as={MdThumbUpOffAlt} width="20px" height="20px" color="inherit" />
  //   ),
  //   showToMenu: false,
  //   collapse: false,
  // },
  // {
  //   name: 'Hiring Process',
  //   layout: '/business',
  //   path: '/hiringProcess',
  //   component: <HiringProcess />,
  //   secondary: true,
  //   icon: (
  //     <Icon as={MdThumbUpOffAlt} width="20px" height="20px" color="inherit" />
  //   ),
  //   showToMenu: false,
  //   collapse: false,
  // },
  // {
  //   name: 'Workflow Finalization',
  //   layout: '/business',
  //   path: '/workflowFinalization',
  //   component: <WorkflowFinalization />,
  //   secondary: true,
  //   icon: (
  //     <Icon as={MdThumbUpOffAlt} width="20px" height="20px" color="inherit" />
  //   ),
  //   showToMenu: false,
  //   collapse: false,
  // },
  {
    name: 'Ready To Interview',
    layout: '/business',
    path: '/interview_ready_candidates',
    component: <ReadyToInterviewCandidates />,
    icon: <Icon as={IoPersonCircleOutline} width="20px" height="20px" color="inherit" />,
    collapse: false,
    showToMenu: true,
  },
  {
    name: 'Interviewing',
    layout: '/business',
    path: '/calendar',
    component: <BusinessScheduling />,
    icon: (
      <Icon as={MdCalendarMonth} width="20px" height="20px" color="inherit" />
    ),
    collapse: false,
    showToMenu: true,
  },
  // {
  //   name: 'Scheduling',
  //   layout: '/business',
  //   path: '/calendar',
  //   component: <BusinessScheduling />,
  //   icon: (
  //     <Icon as={MdCalendarMonth} width="20px" height="20px" color="inherit" />
  //   ),
  //   collapse: false,
  //   showToMenu: true,
  // },
  // {
  //   name: 'Onboard',
  //   layout: '/business',
  //   path: '/onboarding',
  //   component: <CandidateOnboarding />,
  //   icon: (
  //     <Icon
  //       as={LuClipboardCheck}
  //       width="20px"
  //       height="20px"
  //       color="inherit"
  //     />
  //   ),
  //   collapse: false,
  //   showToMenu: false,
  // },
  {
    name: 'Settings',
    path: '/nfts',
    icon: (
      <Icon as={AiOutlineSetting} width="20px" height="20px" color="inherit" />
    ),
    collapse: true,
    items: [
      {
        name: 'Billing',
        layout: '/business',
        path: '/billing',
        component: <StripeBilling />,
        secondary: true,
        showToMenu: true,
        icon: (
          <Icon as={RiBillLine} width="15px" height="15px" color="inherit" />
        ),
      },
      {
        name: 'Purchase Plan',
        layout: '/business',
        path: '/billing/purchase',
        component: <PurchasePage />,
        // component: <PurchasePageStripe />,
        secondary: true,
        showToMenu: false,
      },
      {
        name: 'Complete Purchase',
        layout: '/business',
        path: '/billing/completepurchase',
        component: <CompletePurchase />,
        // component: <CompletePurchaseStripe />,
        secondary: true,
        showToMenu: false,
      },
      {
        name: 'Cancel Purchase',
        layout: '/business',
        path: '/billing/cancelsubscription',
        component: <CancelSubscription />,
        secondary: true,
        showToMenu: false,
      },
      {
        name: 'Modify Subscription',
        layout: '/business',
        path: '/billing/modifysubscription',
        component: <ModifySubscription />,
        secondary: true,
        showToMenu: false,
      },
      {
        name: 'Upgrade Subscription',
        layout: '/business',
        path: '/billing/upgradesubscription',
        component: <UpgradeSubscription />,
        secondary: true,
        showToMenu: false,
      },
      {
        name: 'Templates',
        layout: '/business',
        path: '/templates',
        component: <EmailTemplateList />,
        secondary: true,
        showToMenu: true,
        icon: (
          <Icon as={MdMarkEmailRead} width="14px" height="14px" color="inherit" />
        ),
      },
      {
        name: 'Profile',
        layout: '/business',
        path: '/profile',
        component: <ProfileView />,
        secondary: true,
        showToMenu: true,
        icon: (
          <Icon
            as={BsFillPersonFill}
            width="15px"
            height="15px"
            color="inherit"
          />
        ),
      },
      {
        name: 'Integration',
        layout: '/business',
        path: '/integration',
        component: <BusinessIntegration />,
        secondary: true,
        showToMenu: false,
        icon: (
          <Icon
            as={MdPinInvoke}
            width="15px"
            height="15px"
            color="inherit"
          />
        ),
      },
      {
        name: 'Integration > HR Integration',
        layout: '/business',
        path: '/integration/hrconfig',
        component: <HrIntegrationCore />,
        secondary: true,
        showToMenu: false,
        icon: (
          <Icon
            as={MdPinInvoke}
            width="15px"
            height="15px"
            color="inherit"
          />
        ),
      },
      {
        name: 'Integration > HR Integration Verification',
        layout: '/business',
        path: '/integration/hrverify',
        component: <HrIntegrationVerify />,
        secondary: true,
        showToMenu: false,
        icon: (
          <Icon
            as={MdPinInvoke}
            width="15px"
            height="15px"
            color="inherit"
          />
        ),
      },
      {
        name: 'Business Settings',
        layout: '/business',
        path: '/settings',
        component: <BusinessConfiguration />,
        secondary: true,
        showToMenu: true,
        icon: (
          <Icon
            as={AiOutlineSetting}
            width="15px"
            height="15px"
            color="inherit"
          />
        ),
      },
      {
        name: 'HR & ATS',
        layout: '/business',
        path: '/hr-and-ats',
        component: <HRAndATS />,
        secondary: true,
        showToMenu: true,
        icon: (
          <Icon
            as={BsFillPersonVcardFill}
            width="15px"
            height="15px"
            color={'inherit'}
          />
        ),
      },
    ],
  },
];

export default businessRoutes;
