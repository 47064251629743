import { Link } from 'react-router-dom';
import { Flex, useColorModeValue, Text, Tooltip, Spacer, IconButton, useDisclosure, ListItem, ListIcon, List } from "@chakra-ui/react";
import { toTitleCase } from "../../../../utils/common";
import { useState } from 'react';
import { BiCheck, BiPencil } from 'react-icons/bi';
import EditCandidateSkills from './EditCandidateSkills';
import { MdCheck } from 'react-icons/md';

export default function CandidateSkills(props){
    const {candidate, job, isEditCandidate, getAllCandidatesForJob} = props;
    const [isEditSkills, setIsEditSkills] = useState(false)
    const [readMore, setReadMore] = useState({skills: false, experience: false, experienceSummary: {}});
    const SchemeTextColor = useColorModeValue('secondaryGray.900', 'gray.800');
    const { isOpen: isEditSkillsPanelOpen, onOpen: onEditSkillsPanelOpen, onClose: onEditSkillsPanelClose } = useDisclosure();

    const experienceExtra = candidate?.details?.ex_data
    const matching_skills = JSON.parse(candidate?.workflow?.tags_skill_set || "[]")

    let skillList = []

    if (typeof experienceExtra?.all_skills == 'string'){
        experienceExtra.all_skills = experienceExtra?.all_skills?.split(',')
    }

    if (matching_skills?.length){
        skillList = [...new Set(matching_skills?.concat(experienceExtra?.all_skills))]
    }else{
        skillList = experienceExtra?.all_skills || []
    }

    const skillListLess = skillList?.slice(0, 20)

    const ReadMoreButton = ({targetName, targetValue, style}) => {
        // targetName: summary, skills, experiences
        // targetValue: true, false
        return <>
            <Link to={'javascript:void(0)'} style={{'margin-left': '5px', 'color': 'blue', 'width': '100px', ...style}} 
                  onClick={() => {
                    const target = {}
                    target[targetName] = targetValue
                    setReadMore({...readMore, ...target})
                  }}>
                read more
            </Link>
        </>
    }

    const ReadLessButton = ({targetName, targetValue, style}) => {
        return <>
            <Link to={'javascript:void(0)'} style={{'margin-left': '5px', 'color': 'blue', 'width': '100px', ...style}}
                  onClick={() => {
                    const target = {}
                    target[targetName] = targetValue
                    setReadMore({...readMore, ...target})
                  }}>
                read less
            </Link>
        </>
    }

    const EditSkillsButton = () => {
        if (isEditCandidate && !isEditSkills){
        return <>
            <Tooltip label={'Edit Skills'} fontSize='md'>
                <span>
                    <IconButton fontSize={'20px'} bg={'none'} _hover={{'bg': 'none'}} icon={<BiPencil />} color={'gray'}
                        onClick={() => {
                            setIsEditSkills(true)
                            onEditSkillsPanelOpen()
                        }}
                    />
                </span>
            </Tooltip>
        </>
        }

        if (isEditCandidate && isEditSkills){
        return <>
            <Tooltip label={'Finish Editing Skills'} fontSize='md'>
                <span><IconButton fontSize={'24px'} bg={'none'} _hover={{'bg': 'none'}} icon={<BiCheck />} color={'gray'}
                        onClick={() => {
                            setIsEditSkills(false)
                            onEditSkillsPanelClose()
                        }}
                    />
                </span>
            </Tooltip>
        </>
        }
    }

    const Skills = () => {
        return <>
            <Flex w={'100%'} color={'gray.600'} mt={10} direction={'column'}
                overflowY="auto"
                maxH={'40vh'}
                css={{
                '&::-webkit-scrollbar': {
                    width: '8px',
                },
                '&::-webkit-scrollbar-track': {
                    width: '12px',
                },
                '&::-webkit-scrollbar-thumb': {
                    borderRadius: '100px',
                    background: 'gray.500',
                    border: '6px solid rgba(0, 0, 0, 0.2)'
                },
                }}
            >
                <Flex>
                    <Text color={SchemeTextColor} fontSize={'16px'} fontWeight={'600'}>Skills</Text>
                    <Spacer />
                    <EditSkillsButton /> 
                </Flex>
                {skillList?.length == 0 && <Text as={'b'}>N/A</Text> }
                <Flex direction={'column'} hidden={readMore.skills} fontSize={'16px'} fontWeight={'500'}>
                    <List p={'5px'}>
                        {
                        skillListLess && skillListLess?.map( skill => {
                            if (matching_skills && matching_skills.includes(skill)){
                                return <ListItem width={'50%'} float={'left'} mb={2}>
                                            <ListIcon as={MdCheck} color='green.500' />
                                            {skill}
                                        </ListItem>
                            }else{
                                return (
                                    <ListItem width={'50%'} float={'left'} pl={6} mb={2}>
                                        {skill}
                                    </ListItem>
                                    )
                            }              
                        })
                        }
                    </List>
                    {skillList?.length > 20 && <ReadMoreButton targetName={'skills'} targetValue={true} style={{'margin-left': '30px'}} />}
                </Flex>
                <Flex direction={'column'} hidden={!readMore.skills} fontSize={'16px'} fontWeight={'500'}>
                    <List p={'5px'}>
                        {
                        skillList && skillList?.map( skill => {
                            if (matching_skills && matching_skills.includes(skill)){
                                return <ListItem width={'50%'} float={'left'} mb={2}>
                                            <ListIcon as={MdCheck} color='green.500' />
                                            {skill}
                                        </ListItem>
                            }else{
                                return (
                                    <ListItem width={'50%'} float={'left'} pl={6} mb={2}>
                                        {skill}
                                    </ListItem>
                                    )
                            }              
                        })
                        }
                    </List>
                    <ReadLessButton targetName={'skills'} targetValue={false} style={{'margin-left': '30px'}} />
                </Flex>
            </Flex>
        </>
    }

    return (
        <>
            <Skills />
            <EditCandidateSkills
                candidate={candidate} job={job}
                onEditSkillsPanelOpen={onEditSkillsPanelOpen}
                onEditSkillsPanelClose={onEditSkillsPanelClose}
                isEditSkillsPanelOpen={isEditSkillsPanelOpen}
                getAllCandidatesForJob={getAllCandidatesForJob}
            />
        </>
    )
}