import Card from '../../../components/card/Card';
import React, { useEffect, useState } from 'react';
import JobTableList from './component/JobTableList';
import { Flex, Spinner, useToast, Text } from '@chakra-ui/react';
import { getEmailId } from '../../../utils/localStorageIndex';
import { jobTableColumnList } from './component/JobTableColumnList';
import {
  jobWorkflowStatusCount,
  fetchJobFlowData,
} from '../../../services/jobTemplateService';
import Pagination from './component/pagination';

function JobList() {
  const toast = useToast();
  const email = getEmailId();
  const [loading, isLoading] = useState(true);
  const [tableData, setTableData] = useState([]);
  const [totalRecords, setTotalRecords] = useState(null)
  const [totalJobsCount, setTotalJobsCount] = useState([]);
  const [openJobsCount, setOpenJobsCount] = useState([]);  
  const [slotCount, setSlotCount] = useState([]);  
  const [archCount, setArchCount] = useState(0);  
  const [searchKeyword, setSearchKeyword] = useState('');
  const [searchInputText, setSearchInputText] = useState('');
  const [filterEnabledValue, setFilterEnabledValue] = useState('');
  const recordsPerPage = 100;
  const [noRecordFlag, setNoRecordFlag] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    fetchMenuCountList();
    fetchJobWorkflowFlowData();
  }, []);

  useEffect(() => {
    fetchJobWorkflowFlowData();
  }, [currentPage]);

  // fetch count list
  const fetchMenuCountList = () => {
    let objBody = {
      email: email,
    };
    jobWorkflowStatusCount(objBody)
      .then(res => {
        if (res.data[0] && res.result) {
          setOpenJobsCount(res.data[0].open_jobs);
          setTotalJobsCount(res.data[0].total_jobs);
          setSlotCount(res.data[0].total_slots);
        }
      })
      .catch(err => {
        console.log(err);
      });
  };

  const fetchJobWorkflowFlowData = (jobCode) => {
    let archivedJobs = false;
    if (jobCode === 'arc'){
      archivedJobs = true;
    }
    isLoading(true);
    let objData = {
      email: email,
      filterEnabled: filterEnabledValue,
      searchString: searchInputText,
      field_name: searchKeyword,
      records_per_page: recordsPerPage,
      page_id: currentPage,
      archived: archivedJobs
    };
    fetchJobFlowData(objData)
      .then(res => {
        isLoading(false);
        if (res?.data.length > 0 && res?.result) {
          setTotalRecords(res?.data[0].total_records)
          setNoRecordFlag(false);
          setTableData(res.data[0].records);
          setArchCount(res.data[0].arc_records);          
        } else {
          setNoRecordFlag(false);
          setTableData([]);
        }
      })
      .catch(err => {
        isLoading(false);
        setNoRecordFlag(true);
        toast({
          title: err?.response?.message,
          status: 'error',
          isClosable: true,
          duration: 3000,
        });
      });
  };

  const reRenderTableData = data => {
    fetchJobWorkflowFlowData();
  };

  const refreshJobList = (jobCode) => {
    fetchMenuCountList();    
    fetchJobWorkflowFlowData(jobCode);
  };

  return (
    <Flex direction="column" pt={{ sm: '125px', lg: '75px' }}>
      <Card px="0px">
        {loading ? (
          <Flex alignItems="center" justifyContent="center" mt="10" h="50vh">
            <Spinner size="xl" />
          </Flex>
        ) : (
          <>
            <JobTableList
              emailId={email}
              tableData={tableData}
              columnsData={jobTableColumnList}
              onDataReceived={reRenderTableData}
              openJobsCount={openJobsCount}
              totalJobsCount={totalJobsCount}
              totalSlotCount={slotCount}
              archivedCount={archCount}
              handleCallBack={refreshJobList}
            />

            <Pagination
            totalRows={totalRecords}
            pageChangeHandler={setCurrentPage}
            page={currentPage}
            rowsPerPage={recordsPerPage}
          />
          </>
        )}
      </Card>
    </Flex>
  );
}

export default JobList;
