import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Text,
  Button,
  SimpleGrid,
  Image,
  Flex,
  HStack,
  Icon,
  Spinner,
  Badge,
  IconButton,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  Input,
  ModalBody,
  Grid,
  useColorModeValue,
  useToast,
} from '@chakra-ui/react';
import { CgAsterisk } from 'react-icons/cg';
import { getEmailId, getGroupId } from '../../../../utils/localStorageIndex';
import { fetchPersonalInfo } from '../../../../services/sharedServices';

// Chakra imports

// Custom components
import Banner from './components/Banner';
import General from './components/General';
import Notifications from './components/Notifications';
import Projects from './components/Projects';
import Storage from './components/Storage';
import Upload from './components/Upload';

// Assets
import banner from '../../../../assets/img/auth/banner.png';
import avatar from '../../../../assets/img/avatars/dummy-avatar.jpg';
import ProfileSettings from '../settings/ProfileSettings';

function ProfileView() {
  const emaiId = getEmailId();
  const dispatch = useDispatch();
  const [loading, isLoading] = useState(true);
  const [userDetails, setUserDetails] = useState({});
  const [notificationMsg, setNotificationMsg] = useState({
    message: null,
    color: null,
  });

  const sideMenuStatus = useSelector(store => store.notificationHeader);
  const { profileLogoFlag } = sideMenuStatus;

  useEffect(() => {
    let objData = {
      email: emaiId,
    };
    fetchPersonalInfo(objData)
      .then(res => {
        if (res.result) {
          setUserDetails(res.data[0]);

          isLoading(false);
          setNotificationMsg({ message: res?.message, color: 'green' });
        } else {
          isLoading(false);
          setNotificationMsg({ message: res?.message, color: 'green' });
        }
      })
      .catch(err => {
        isLoading(false);
        if (err) {
          setNotificationMsg({ message: err?.message, color: 'green' });
        }
      });
  }, [profileLogoFlag]);

  function renderFormattedString(strtemp) {
    var finalString = '...';
    if (strtemp.trim().length > 0) {
      finalString = strtemp;
    }
    return finalString;
  }

  function renderFullName() {
    var fullName = null;
    if (
      userDetails.first_name !== null &&
      userDetails.first_name !== undefined &&
      userDetails.last_name !== null &&
      userDetails.last_name !== undefined
    ) {
      fullName = renderFormattedString(userDetails.first_name);
      fullName = fullName + '  ' + renderFormattedString(userDetails.last_name);
      return fullName;
    } else {
      return '...';
    }
  }

  return (
    <>
      <Box pt={{ base: '130px', md: '80px', xl: '80px' }}>
        <SimpleGrid
          mb="20px"
          columns={{ sm: 1, lg: 1 }}
          spacing={{ base: '20px', xl: '20px' }}
        >
          {/* Column Left */}
          <Flex direction="column">
            <Banner
              gridArea="1 / 1 / 2 / 2"
              banner={banner}
              avatar={userDetails.photo_url ? userDetails.photo_url : avatar}
              name={renderFullName()}
              job={
                userDetails.role !== null && userDetails.role !== undefined
                  ? userDetails.role
                  : '...'
              }
              email={
                userDetails.email !== null && userDetails.email !== undefined
                  ? userDetails.email
                  : '...'
              }
              phone={
                userDetails.phone_number !== null &&
                userDetails.phone_number !== undefined
                  ? userDetails.phone_number
                  : '...'
              }
              join_date={userDetails.date}
              social_about={userDetails.social_about}
              // posts='17'
              // followers='9.7k'
              // following='274'
            />
          </Flex>
          {/* Column Right */}
          {/* <Flex direction="column">
            <General
              gridArea={{ base: '2 / 1 / 3 / 2', lg: '1 / 2 / 2 / 3' }}
              minH="365px"
              pe="20px"
            />
          </Flex> */}
        </SimpleGrid>
        {/* Main Fields */}

        {/* <Grid
        templateColumns={{
          base: "1fr",
          lg: "1.34fr 1fr 1.62fr",
        }}
        templateRows={{
          base: "repeat(3, 1fr)",
          lg: "1fr",
        }}
        gap={{ base: "20px", xl: "20px" }}>
        <Banner
          gridArea='1 / 1 / 2 / 2'
          banner={banner}
          avatar={avatar}
          name='Adela Parkson'
          job='Product Designer'
          join_date='Jan 1, 2023'
          // posts='17'
          // followers='9.7k'
          // following='274'
        />
        <Storage
          gridArea={{ base: "2 / 1 / 3 / 2", lg: "1 / 2 / 2 / 3" }}
          used={25.6}
          total={50}
        />
        <Upload
          gridArea={{
            base: "3 / 1 / 4 / 2",
            lg: "1 / 3 / 2 / 4",
          }}
          minH={{ base: "auto", lg: "420px", "2xl": "365px" }}
          pe='20px'
          pb={{ base: "100px", lg: "20px" }}
        />
      </Grid>
      <Grid
        mb='20px'
        templateColumns={{
          base: "1fr",
          lg: "repeat(2, 1fr)",
          "2xl": "1.34fr 1.62fr 1fr",
        }}
        templateRows={{
          base: "1fr",
          lg: "repeat(2, 1fr)",
          "2xl": "1fr",
        }}
        gap={{ base: "20px", xl: "20px" }}>
        <Projects
          gridArea='1 / 2 / 2 / 2'
          banner={banner}
          avatar={avatar}
          name='Adela Parkson'
          job='Product Designer'
          posts='17'
          followers='9.7k'
          following='274'
        />
        <General
          gridArea={{ base: "2 / 1 / 3 / 2", lg: "1 / 2 / 2 / 3" }}
          minH='365px'
          pe='20px'
        />
        <Notifications
          used={25.6}
          total={50}
          gridArea={{
            base: "3 / 1 / 4 / 2",
            lg: "2 / 1 / 3 / 3",
            "2xl": "1 / 3 / 2 / 4",
          }}
        />
      </Grid> */}
      </Box>
      <ProfileSettings />
    </>
  );
}

export default ProfileView;
