const isObjectEmpty = (obj) => {
    return obj === undefined || obj === null || Object.keys(obj).length == 0;
}

const toTitleCase = (str) => {
    return str?.toLowerCase()?.split(' ').map( word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
  }

export { isObjectEmpty, toTitleCase }

export const formatCurrency = (num) => {
  if (!num || num == '-9007199254740991'){
    return null
  }

  let numStr = num.toString();
  return numStr.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
}

export const jobApplyStandardFields = [
  {
    'title': 'First Name',
    'name': 'first_name',
    'is_required': true,
    'type': 'text'
  },
  {
    'title': 'Last Name',
    'name': 'last_name',
    'is_required': true,
    'type': 'text'
  },
  {
    'title': 'Email',
    'name': 'email',
    'is_required': true,
    'type': 'text'
  },
  {
    'title': 'Linkedin Profile URL',
    'name': 'linkedin',
    'is_required': false,
    'type': 'text'
  },
  {
    'title': 'Attach Resume',
    'name': 'resume',
    'is_required': true,
    'type': 'file'
  },
]

export const workflowStagesMapping = {
  'candidate_sourced': 'Sourced',
  'candidate_screening': 'Screening',
  'ready_to_interview': 'Ready To Interview',
  'scheduled_for_interview': 'Interviewing',
  'candidate_hired': 'Offer',
  'candidate_rejected': 'Rejected'
}

export const validateScreeningQuestions = (screeningQuestions) => {
  const errors = {}
  screeningQuestions.map((question, index) => {
      if (question.question_text){
          if (!question?.is_default && question?.question_is_mandatory){
            if (question?.question_type?.code == 'number'){
                let isIdealAnswerSelected = false
                const filters = question?.question_disqualify_filters
                Object.keys(filters)?.map(key => {
                    if (filters[key]?.isChecked && filters[key]?.value){
                        isIdealAnswerSelected = true
                    }
                })
                if (!isIdealAnswerSelected){
                    errors[index] = 'A Must-have question requires an ideal answer to qualify or disqualify a candidate'
                }
            }
            if (['boolean', 'multiplechoice'].includes(question?.question_type?.code) && question.question_disqualify_filters == 0){
                errors[index] = 'A Must-have question requires an ideal answer to qualify or disqualify a candidate'
            }
          }

      }else {
        errors[index] = 'Question title is required'
    }
  })

  return errors
}


export const withHttps = (url) => {
  return url.replace(/^(?:(.*:)?\/\/)?(.*)/i, (match, schemma, nonSchemmaUrl) => schemma ? match : `https://${nonSchemmaUrl}`)
}