import React, { useCallback, useEffect, useState } from 'react';
import { Button, Divider, Flex, IconButton, Image, Popover, PopoverTrigger, PopoverContent,
        PopoverCloseButton, PopoverBody, PopoverFooter, ButtonGroup, SimpleGrid, Text, useDisclosure, 
        PopoverArrow, useToast} from '@chakra-ui/react';
import { DeleteIcon } from '@chakra-ui/icons'
import { useMergeLink } from "@mergeapi/react-merge-link";
import { getEmailId } from '../../../../utils/localStorageIndex';
import { createMergeToken, saveMergeToken, deleteMergeIntegration } from '../../../../services/IntegrationServices';
import { fetchBusinessInformation } from '../../../../services/businessServices';
import { isObjectEmpty } from '../../../../utils/common';


function HRAndATS() {

    const [isGettingMergeLinkToken, setIsGettingMergeLinkToken] = useState(false)
    const [isSavingMergeToken, setIsSavingMergeToken] = useState(false);
    const [isDeletingMergeService, setIsDeletingMergeService] = useState(false);
    const [mergeToken, setMergeToken] = useState(null);
    const [existingIntegrations, setExistingIntegrations] = useState({});
    const { onToggle: onDeleteConfirmationToggle } = useDisclosure()
    const toast = useToast();

    useEffect(() => {
        getBusinessDetails();
    }, [])

    useEffect(() => {
        if (mergeToken){
            onOpenMergeModal();
        }
    }, [mergeToken])

    const getBusinessDetails = () => {
        fetchBusinessInformation({'email': getEmailId()})
        .then(res => {
            setExistingIntegrations(res?.data[0].hr_and_ats_integrations)
        })
        .catch(err => {
            toast({
                title: 'Unable to get business details.',
                description: err.toString(),
                status: 'error',
                isClosable: true,
                duration: 3000,
            });
        })
    }

    const getMergeLinkToken = () => {
        setIsGettingMergeLinkToken(true)
        createMergeToken({'email': getEmailId()})
        .then(res => {
            if (res.result) {
                const token = res?.data[0]?.token
                if (!token){
                    toast({
                        title: 'Something went wrong. Please retry',
                        status: 'error',
                        isClosable: true,
                        duration: 3000,
                    });
                    setIsGettingMergeLinkToken(false)
                    return
                }
                setMergeToken(token)
                setIsGettingMergeLinkToken(false)
            }
        })
        .catch(err => {
            console.log(err)
            toast({
                title: 'Unable to save integration', 
                description: err.toString(),
                status: 'error',
                isClosable: true,
                duration: 3000,
            });
            setIsGettingMergeLinkToken(false)
        });
    }

    const openMergeModal = () => {
        getMergeLinkToken()   
    }
    
    const onMergeIntegrationSuccess = useCallback((public_token) => {
        setIsSavingMergeToken(true);
        const data = {'token': public_token, 'email': getEmailId()}
        saveMergeToken(data)
        .then(res => {
            if (res.result) {
                setExistingIntegrations(res?.data[0])
                toast({
                    title: 'Integrated successfully.', 
                    status: 'success',
                    isClosable: true,
                    duration: 3000,
                });
            }
            setIsSavingMergeToken(false);
            setMergeToken(null)
        })
        .catch(err => {
            console.log(err)
            setIsSavingMergeToken(false);
        });
      }, []);

    const onMergeModalExit = useCallback( () => {
        setIsSavingMergeToken(false);
        setIsGettingMergeLinkToken(false);
    })

    const { open: onOpenMergeModal, isReady: isMergeModalReady } = useMergeLink({
        linkToken: mergeToken,
        onSuccess: onMergeIntegrationSuccess,
        onExit: onMergeModalExit
    });

    const deleteIntegration = (integrationName) => {
        setIsDeletingMergeService(true);
        const data = {integration_name: integrationName, email: getEmailId()}
        deleteMergeIntegration(data)
        .then(res => {
            if (res.result) {
                setExistingIntegrations(res?.data[0])
                setIsDeletingMergeService(false);
                onDeleteConfirmationToggle();
                toast({
                    title: 'Integrated deleted successfully.', 
                    status: 'success',
                    isClosable: true,
                    duration: 3000,
                });
                return
            }
            
            setIsDeletingMergeService(false);
            toast({
                title: 'Unable to delete integration',
                status: 'error',
                isClosable: true,
                duration: 3000,
            });

        })
        .catch( err => {
            setIsDeletingMergeService(false);
            console.log(err)
            toast({
                title: 'Unable to delete integration', 
                description: err.toString(),
                status: 'error',
                isClosable: true,
                duration: 3000,
            });
        })
    }

    const renderDeleteConfirmation = (integrationName) => {
        return (
            <Popover placement='bottom'>
                <PopoverTrigger>
                    <IconButton ml={1} variant={'solid'} isRound={true}
                        width={8} height={8} icon={<DeleteIcon />}
                        onClick={onDeleteConfirmationToggle} 
                    />
                </PopoverTrigger>
                <PopoverContent color='white' bg='blue.800' borderColor='blue.800'>
                    <PopoverArrow bg='blue.800' />
                    <PopoverCloseButton />
                    <PopoverBody mt={5}>Are you sure you want to disconnect this integration?</PopoverBody>
                    <PopoverFooter border='0' display='flex' alignItems='center' justifyContent='space-between' pb={4}>
                    <ButtonGroup size='sm'>
                        <Button colorScheme='blue' onClick={() => deleteIntegration(integrationName)}
                            isLoading={isDeletingMergeService}
                            loadingText={'Deleting...'}
                        >Yes</Button>
                    </ButtonGroup>
                    </PopoverFooter>
                </PopoverContent>
            </Popover>
        )
    }

    const renderIntegratedServices = () => {

        if (isObjectEmpty(existingIntegrations)){
            return <Text mt={5}>No HR or ATS is integrated.</Text>
        }

        return (
            <SimpleGrid columns={4} fontSize={'16px'} mt={5}>
                {Object.entries(existingIntegrations).map(([key, value]) => {
                    return <Flex direction={'column'} alignItems={'center'} justifyContent={'center'}>
                                <Image
                                    borderRadius='full'
                                    boxSize='100px'
                                    fit={true}
                                    mb={2}
                                    border={'1px solid'}
                                    borderColor={'gray.200'}
                                    src={value.square_image}
                                />
                                <Flex alignItems={'center'} justifyContent={'center'}>
                                    <Text ml={5}>{key}</Text>
                                    {renderDeleteConfirmation(key)}                                   
                                </Flex>
                            </Flex>
                })}
            </SimpleGrid>
        )
    }
    

    return (
        <Flex pt={{ sm: "125px", lg: "75px" }} direction={'column'}>
            <Flex alignItems={'center'} mt={'20px'}>
                <Text fontWeight={'bold'}>Setup your HR and ATS</Text>
                <Button fontSize={'14px'} ml={10} bg={'#1E90FF'} color={'white'} width={'120px'}
                        disabled={!isMergeModalReady} onClick={openMergeModal}
                        isLoading={isSavingMergeToken || isGettingMergeLinkToken}
                        loadingText={'connecting...'}
                >
                    Connect
                </Button>
            </Flex>

            <Flex direction={'column'} mt={20}>
                <Divider mb={'20px'} />
    
                <Text fontWeight={'bold'} fontSize={'16px'}>Integrated Services</Text>
                {renderIntegratedServices()}
            </Flex>
        </Flex>
    )
}

export default HRAndATS;
