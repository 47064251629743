import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
// Chakra imports
import {
  Flex,
  Icon,
  IconButton,
  FormControl,
  Input,
  Text,
  useToast,
  useColorModeValue,
} from '@chakra-ui/react';
// Custom components
import Card from '../../../../../components/card/Card.js';
import { IoLogoFacebook, IoLogoTwitter } from 'react-icons/io';
// Assets
import { IoPaperPlane } from 'react-icons/io5';
import { inviteTeamMember } from '../../../../../services/businessServices';
import { getEmailId, getGroupId } from '../../../../../utils/localStorageIndex';

export default function TeamInviteForm(props) {
  const { referralCode, fbLink, twtLink, ...rest } = props;
  const toast = useToast();

  // Chakra Color Mode
  const textColor = useColorModeValue('secondaryGray.900', 'white');
  const iconColor = useColorModeValue('brand.500', 'white');
  const boxBg = useColorModeValue('secondaryGray.300', 'whiteAlpha.100');

  const navigate = useNavigate();
  const emailId = getEmailId();
  const [error, setError] = useState({});
  const [loading, isLoading] = useState(false);
  const [businessAdminForm, setBusinessAdminForm] = useState({
    email: emailId,
    user_email: '',
  });

  const onUpdateField = e => {
    const nextFormState = {
      ...businessAdminForm,
      [e.target.name]: e.target.value,
    };
    setBusinessAdminForm(nextFormState);
  };

  const ResetField = () => {
    const nextFormState = {
      ...businessAdminForm,
      ['user_email']: '',
    };
    setBusinessAdminForm(nextFormState);
  };

  const validateFieldForm = () => {
    let fields = businessAdminForm;
    let errors = {};
    let formIsValid = true;
    let emailPattern = new RegExp(
      /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/
    );
    if (!fields['user_email']) {
      formIsValid = false;
      errors['user_email'] = '*Business email is required.';
    } else if (!emailPattern.test(fields['user_email'])) {
      formIsValid = false;
      errors['user_email'] = '*Please enter valid business email.';
    }
    setError(errors);
    return formIsValid;
  };

  const submitTeamMemberData = () => {
    if (validateFieldForm()) {
      isLoading(true);
      inviteTeamMember(businessAdminForm)
        .then(res => {
          if (res.result) {
            isLoading(false);
            toast({
              title: res.message,
              status: 'success',
              isClosable: true,
              duration: 3000,
            });
            ResetField();
            navigate('/business/team');
            props.onDataUpdate();
          } else {
            isLoading(false);
            toast({
              title: res.message,
              status: 'error',
              isClosable: true,
              duration: 3000,
            });
          }
        })
        .catch(err => {
          isLoading(false);
        });
    }
  };

  return (
    <Card px="26px" py="30px" w="100%" {...rest}>
      <Text color={textColor} fontSize="2xl" fontWeight="700" mb="10px">
        Invite your team!
      </Text>
      <Text color="secondaryGray.600" fontSize="md" fontWeight="400" mb="30px">
        Add your team's business email address to send them an invitation to
        join!
      </Text>
      <FormControl p="20px">
        <Flex position="relative" mb="60px">
          <Input
            h="50px"
            variant="main"
            id="user_email"
            name="user_email"
            value={businessAdminForm.user_email}
            onChange={onUpdateField}
            borderRadius="65px"
            placeholder="Business Email address.."
            _placeholder={{ color: 'secondaryGray.500' }}
          />
          <IconButton
            w="40px"
            h="40px"
            variant="brand"
            borderRadius="50%"
            position="absolute"
            top="5px"
            right="6px"
            onClick={() => {
              submitTeamMemberData();
            }}
          >
            <Icon w="14px" h="14px" as={IoPaperPlane} color="white" />
          </IconButton>
        </Flex>
        <Text fontSize="sm" color={'red'} mb="20px">
          {error?.user_email}
        </Text>
      </FormControl>
      <Text color="secondaryGray.600" fontSize="xs" fontWeight="400" mb="30px">
        An email invite will be sent to your team members to register and join.
      </Text>
      {/* <Text color={textColor} fontSize="2xl" fontWeight="700" mb="10px">
          Share the referral link
        </Text>
        <Text color="secondaryGray.600" fontSize="md" fontWeight="400" mb="30px">
          You can also share your referral link by copying and sending it to your
          friends or sharing it on social media.
        </Text>
        <Flex>
          <Flex
            w={{ base: '74%', md: '100%', '2xl': '75%', '3xl': '378px' }}
            px="18px"
            align="center"
            borderRadius="50px"
            cursor="pointer"
            onClick={function () {
              navigator.clipboard.writeText(referralCode);
              toast({
                title: `Invite link copied!`,
                position: 'top',
                status: 'success',
                isClosable: true,
              });
            }}
            bg={boxBg}
            me="6px"
          >
            <Text
              fontSize="sm"
              fontWeight="500"
              color={textColor}
              w={{ base: '60%', md: '85%', '2xl': '68%', '3xl': '80%' }}
            >
              {referralCode}
            </Text>
            <Text ms="auto" color={iconColor} fontSize="sm" fontWeight="500">
              Copy link
            </Text>
          </Flex>
          <IconButton me="6px" bg={boxBg} borderRadius="50%">
            <Icon w="22px" h="22px" as={IoLogoFacebook} color={iconColor} />
          </IconButton>
          <IconButton bg={boxBg} borderRadius="50%">
            <Icon w="18px" h="18px" as={IoLogoTwitter} color={iconColor} />
          </IconButton>
        </Flex> */}
    </Card>
  );
}
