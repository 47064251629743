import { useNavigate } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { errorColor } from '../../../../../constants/index';
import GoogleLocation from '../../../../../constants/GoogleLocation';

import { getEmailId } from '../../../../../utils/localStorageIndex';
import InputField from '../../../../../components/fields/InputField';
import { setJobUUId } from '../../../../../store/actions/topNotificationActions';
import { setJobWizardStatusLevel } from '../../../../../store/actions/topNotificationActions';
import {
  addjobTitleWorkflow,
  editjobTitleWorkflow,
  saveJobWizardOneData,
  fetchJobWorkflowDetails,
} from '../../../../../services/jobTemplateService';
import {
  Stack, Spacer,
  Text,
  Flex,
  Button,
  Select,
  useToast,
  SimpleGrid,
  useColorMode,
  useColorModeValue,
} from '@chakra-ui/react';

function JobIntakeWizard1(props) {
  const wizardDetails = useSelector(store => store.wizardHeader);
  const job_uuid = wizardDetails?.job_uuId ? wizardDetails?.job_uuId : '';
  const toast = useToast();

  const dispatch = useDispatch();
  const emailId = getEmailId();
  const navigate = useNavigate();
  const [formErrors, setFormErrors] = useState({
    jobTitleText: null,
    selectedJobLocation: null
  });
  const [resideLocation, setResideLocation] = useState('');
  const [empResideLocation, setEmpResideLocation] = useState('');
  const [loading, isLoading] = useState(false);
  const [jobLocation, setJobLocation] = useState('');
  const [streetAddress, setStreetAddress] = useState('');
  const [jobTitleText, setJobTitleText] = useState('');
  const { colorMode, toggleColorMode } = useColorMode();
  const [selectedNumber, setSelectedNumber] = useState('1');
  const [selectedJobLocation, setSelectedJobLocation] = useState();
  const [saveCompleteFlag, setSaveCompleteFlag] = useState(false);
  const textColorPrimary = useColorModeValue('secondaryGray.900', 'white');

  const handleChange = e => {
    setSelectedNumber(e.target.value);
  };

  useEffect(() => {
    clearFormErrors();
    if (job_uuid.length) {
      fetchJobFlowData();
    }
  }, []);

  // Todo:Save and completed trigger event
  useEffect(() => {
    if (saveCompleteFlag) {
      saveJobTitleWorkFlow();
    }
  }, [saveCompleteFlag]);

  // Todo:Fetch Job Details if job_uuid returned
  const fetchJobFlowData = () => {
    isLoading(true);
    let objBody = {
      email: emailId,
      job_uuid: job_uuid,
    };
    fetchJobWorkflowDetails(objBody)
      .then(res => {
        isLoading(false);

        if (res.result && res.data.length) {
          setJobTitleText(res.data[0].job_title);
        }
      })
      .catch(err => {
        isLoading(true);
        // toastFunction(err?.response?.data?.detail[0]?.msg, 'error');
      });
  };

  const clearFormErrors = () => {
    setFormErrors({
      jobTitleText: null,
      selectedJobLocation: null
    })
  }
  // Todo:Save Job Title Details
  const saveJobTitleWorkFlow = () => {
    const errors = {}
    if (!jobTitleText || !jobTitleText.length){
      errors['jobTitleText'] = 'Job title is required'
    }
    
    if (!selectedJobLocation || !selectedJobLocation.length){
      errors['selectedJobLocation'] = 'Job location is required'
    }

    if (Object.keys(errors).length){
      setFormErrors(errors)
      return
    }
    
    let objBody = {
      email: emailId,
      job_title: jobTitleText,
      selectedNumber: selectedNumber,
      jobLocation: jobLocation,
      streetAddress: streetAddress,
      resideLocation: resideLocation,
      empResideLocation: empResideLocation,
      selectedJobLocation: selectedJobLocation,
    };
    console.log(objBody);
    isLoading(true);
    if (job_uuid.length) {
      objBody.job_uuid = job_uuid;
      // editjobTitleWorkflow(objBody)
      saveJobWizardOneData(objBody)
        .then(res => {
          isLoading(false);
          if (res.result) {
            const job_uuid = res.data[0].job_uuid;
            dispatch(setJobUUId(job_uuid));
            if (saveCompleteFlag) {
              // navigate(`/business/joblist`);
              toastFunction(res?.message, 'success');
              navigate(`/business/editJobs`, {
                state: {
                  job_uuid: job_uuid
                }
              });
            } else {
              dispatch(setJobWizardStatusLevel(2));
              props.onDataUpdate('synopsisTab');
              toastFunction(res?.message, 'success');
            }
          } else {
            isLoading(false);
            toastFunction(res?.message, 'error');
          }
        })
        .catch(err => {
          isLoading(false);
          toastFunction(err?.response?.data?.detail[0]?.msg, 'error');
        });
    } else {
      saveJobWizardOneData(objBody)
        .then(res => {
          isLoading(false);
          if (res.result) {
            const job_uuid = res.data[0].job_uuid;
            dispatch(setJobUUId(job_uuid));
            if (saveCompleteFlag) {
              navigate(`/business/joblist`);
              toastFunction(res?.message, 'success');
            } else {
              // navigate(`/business/createJob/jobSynopsis/${job_uuid}`);
              dispatch(setJobWizardStatusLevel(2));
              props.onDataUpdate('synopsisTab');
              toastFunction(res?.message, 'success');
            }
          } else {
            isLoading(false);
            toastFunction(res?.message, 'error');
          }
        })
        .catch(err => {
          isLoading(false);
          toastFunction(err?.response?.data?.detail[0]?.msg, 'error');
        });
    }
  };

  // Todo:receive selected data from google location
  const handleSelectedLocation = data => {
    setStreetAddress(data);
  };

  const handleLocationData = data => {
    setJobLocation(data);
  };

  const toastFunction = (message, status) => {
    return toast({
      title: message,
      status: status,
      isClosable: true,
      duration: 3000,
    });
  };

  return (
    <>
      <Flex direction="column" w="100%">
        <Stack direction="column" spacing="20px">
          <SimpleGrid columns={{ base: 1, md: 1 }} gap="20px">
            <InputField
              color="black"
              borderRadius="5px"
              mb="0px"
              placeholder="Enter Job Title"
              label="Job Title"
              value={jobTitleText}
              onChange={e => {
                setJobTitleText(e.target.value);
                setFormErrors({...formErrors, jobTitleText: null})
              }}
              required="true"
            />
            {formErrors.jobTitleText ? (
              <Text fontSize="sm" color={errorColor} mt={'-15px'}>
               {formErrors.jobTitleText}
              </Text>
            ) : null}
          </SimpleGrid>
        </Stack>
        <Stack direction="column" spacing="20px" mt="3">
          <SimpleGrid>
            <Text
              color={textColorPrimary}
              fontSize="sm"
              fontWeight="bold"
              _hover={{ cursor: 'pointer' }}
            >
              Number of people to hire for this job
            </Text>
            <Select
              placeholder="Select no of people"
              value={selectedNumber}
              onChange={handleChange}
            >
              {[...Array(9).keys()].map(num => (
                <option key={num + 1} value={num + 1}>
                  {num + 1}
                </option>
              ))}
              <option>10+</option>
              <option>Ongoing</option>
            </Select>
          </SimpleGrid>
        </Stack>
        <Stack direction="column" spacing="20px" mt="3">
          <SimpleGrid>
            <Text
              color={textColorPrimary}
              fontSize="sm"
              fontWeight="bold"
              _hover={{ cursor: 'pointer' }}
            >
              Which option best describes this job's location?
            </Text>
            <Select
              value={selectedJobLocation}
              onChange={e => {
                setSelectedJobLocation(e.target.value);
                setFormErrors({...formErrors, selectedJobLocation: null})
              }}
            >
              <option selected disabled>
                Please Select
              </option>
              <option value="on_site">On-site</option>
              <option value="hybrid">Hybrid</option>
              <option value="on_the_road">On the road</option>
              <option value="general_location">
                General location, within a limited area
              </option>
              <option value="remote">Remote</option>
            </Select>
            {formErrors.selectedJobLocation ? (
            <Text fontSize="sm" color={errorColor} mt="1">
              {formErrors.selectedJobLocation}
            </Text>
          ) : null}
          </SimpleGrid>
          {selectedJobLocation === 'on_site' ? (
            <Stack direction="column" spacing="20px" mt="3">
              <SimpleGrid>
                <Text
                  color={textColorPrimary}
                  fontSize="sm"
                  fontWeight="bold"
                  _hover={{ cursor: 'pointer' }}
                >
                  What is the street address for this location?
                </Text>
                <GoogleLocation
                  types={["establishment", "geocode"]}  // Street Level API
                  color={colorMode === 'dark' ? 'white' : 'black'}
                  sendSelectedLocation={handleSelectedLocation}
                  locationValue={streetAddress}
                  width="100%"
                  borderRadius="5px"
                  marginTop="10px"
                  fontSize="14px"
                ></GoogleLocation>
              </SimpleGrid>
            </Stack>
          ) : null}
          { selectedJobLocation === 'hybrid' ? (
            <Stack direction="column" spacing="20px" mt="3">
              <SimpleGrid>
                <Text
                  color={textColorPrimary}
                  fontSize="sm"
                  fontWeight="bold"
                  _hover={{ cursor: 'pointer' }}
                >
                  What is the street address for this location?
                </Text>
                <GoogleLocation
                  types={["establishment", "geocode"]}  //{['(cities)']}  // City Level API
                  color={colorMode === 'dark' ? 'white' : 'black'}
                  sendSelectedLocation={handleSelectedLocation}
                  locationValue={streetAddress}
                  placeholder={'Street address'}
                  width="100%"
                  borderRadius="5px"
                  marginTop="10px"
                  fontSize="14px"
                ></GoogleLocation>
              </SimpleGrid>
            </Stack>
          ) : null}
          {selectedJobLocation === 'on_the_road' ||
          selectedJobLocation === 'general_location' ? (
            <Stack direction="column" spacing="20px" mt="3">
              <SimpleGrid>
                <Text
                  color={textColorPrimary}
                  fontSize="sm"
                  fontWeight="bold"
                  _hover={{ cursor: 'pointer' }}
                >
                  What is the job location
                </Text>
                <GoogleLocation
                  types={['(cities)']}  // City Level API
                  color={colorMode === 'dark' ? 'white' : 'black'}
                  sendSelectedLocation={handleLocationData}
                  locationValue={jobLocation}
                  placeholder={'Job location city'}
                  width="100%"
                  borderRadius="5px"
                  marginTop="10px"
                  fontSize="14px"
                ></GoogleLocation>
              </SimpleGrid>
            </Stack>
          ) : null}
          {selectedJobLocation === 'remote' ? (
            <Stack direction="column" spacing="20px" mt="3">
              <SimpleGrid>
                <Text
                  color={textColorPrimary}
                  fontSize="sm"
                  fontWeight="bold"
                  _hover={{ cursor: 'pointer' }}
                >
                  Are employees required to reside in a specific location?
                </Text>
                <Select
                  mb="3"
                  value={resideLocation}
                  onChange={e => {
                    setResideLocation(e.target.value);
                  }}
                >
                  <option disabled value="">
                    Please Select
                  </option>
                  <option value="yes">Yes</option>
                  <option value="no">No</option>
                </Select>
                {resideLocation === 'yes' ? (
                  <InputField
                    borderRadius="5px"
                    placeholder="Enter Location"
                    label="In what location must employees reside"
                    value={empResideLocation}
                    onChange={e => {
                      setEmpResideLocation(e.target.value);
                    }}
                    required="true"
                    color="black"
                  />
                ) : null}
              </SimpleGrid>
            </Stack>
          ) : null}
        </Stack>

        <Flex mt="24px">
          <Spacer />
          {/* <Button
            variant="outline"
            fontSize="sm"
            borderRadius="16px"
            w={{ base: '160px', md: '200px' }}
            h="46px"
            ms="auto"
            onClick={() => {
              setSaveCompleteFlag(!saveCompleteFlag);
            }}
            isDisabled={loading ? true : false}
            isLoading={loading ? true : false}
          >
            Save And Complete Later
          </Button> */}
          <Button
            variant="darkBrand"
            fontSize="sm"
            borderRadius="16px"
            w={{ base: '150px', md: '150px' }}
            h="46px"
            ml="10px"
            onClick={() => {
              saveJobTitleWorkFlow();
            }}
            isDisabled={loading ? true : false}
            isLoading={loading ? true : false}
          >
            Next
          </Button>
        </Flex>
      </Flex>
    </>
  );
}

export default JobIntakeWizard1;
