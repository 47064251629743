import React from 'react';
import {
  Button,
  Box,
  Flex,
  Image,
  Icon,
  Text,
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  HStack,
  VStack,
  Divider,
  Drawer,
  DrawerBody,
  DrawerHeader,
  DrawerContent,
  useDisclosure,
  DrawerCloseButton,
} from '@chakra-ui/react';
import MailChimp from '../MailChimp/MailChimp';
import { BiNews } from 'react-icons/bi';
import { useNavigate } from 'react-router-dom';
import heroimage from '../../media/images/ts-hero-small.jpeg';
import logo from '../../media/images/ts-logo-200x80.png';
import { AiOutlineMinus, AiOutlinePlus } from 'react-icons/ai';
import Footer from '../../constants/Footer';
function LoginHome() {
  const navigate = useNavigate();
  const btnRef = React.useRef();
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <VStack w={'100%'}>
        <Flex
          w={'100%'}
          alignItems="top"
          align={'center'}
          height="calc(100vh - 250px)"
          backgroundImage={heroimage}
          opacity={0.9}
          bgSize={'cover'}
        >
          <HStack
            h={'50px'}
            bg={'gray.800'}
            rounded={'lg'}
            w="95%"
            position="fixed"
            left="2%"
            top="6%"
            opacity={0.9}
          >
            <HStack w={'50%'} px={5} align={'start'} alignItems={'center'}>
              <Image h="70px" mx="auto" mb="25px" src={logo} />
              <Text fontSize={'lg'} color={'yellow'}>
                The smarter way to source talent
              </Text>
            </HStack>
            <Box w={'50%'} px={5} align={'end'}>
              <Button
                color={'yellow.100'}
                variant="outline"
                borderRadius="5px"
                borderColor={'yellow.200'}
                fontSize="12px"
                size="sm"
                mt="1px"
                mr={'1px'}
                _hover={{ color: 'yellow', bg: 'gray' }}
                onClick={() => {
                  navigate('/login');
                }}
              >
                Login
              </Button>
              <Button
                mx={2}
                color={'yellow.100'}
                variant="outline"
                borderRadius="5px"
                borderColor={'yellow.200'}
                fontSize="12px"
                size="sm"
                mt="1px"
                mr={'1px'}
                _hover={{ color: 'yellow', bg: 'gray' }}
                onClick={() => {
                  navigate('/RegisterUser');
                }}
              >
                Register
              </Button>
              <Button
                mx={2}
                color={'yellow.100'}
                variant="outline"
                borderRadius="5px"
                borderColor={'yellow.200'}
                fontSize="12px"
                size="sm"
                mt="1px"
                mr={'1px'}
                _hover={{ color: 'yellow', bg: 'gray' }}
                onClick={() => {
                  navigate('/scheduleDemo');
                }}
              >
                Schedule A Demo
              </Button>
            </Box>
          </HStack>
        </Flex>
        <Flex w={'100%'} alignItems={'center'}>
          <Box w={'20%'}></Box>
          <VStack w={'60%'} alignItems={'start'} mt={10}>
            <Text fontSize={'2xl'}>Frequently asked questions</Text>
            <Accordion allowMultiple w={'100%'}>
              <AccordionItem w={'100%'} mt={4}>
                {({ isExpanded }) => (
                  <>
                    <h2>
                      <AccordionButton>
                        <Box as="span" flex="1" textAlign="left">
                          <Text fontSize={'xl'}>
                            What makes you different from a Recruitment Agency?
                          </Text>
                        </Box>
                        {isExpanded ? (
                          <Icon as={AiOutlineMinus} fontSize="20px" />
                        ) : (
                          <Icon as={AiOutlinePlus} fontSize="20px" />
                        )}
                      </AccordionButton>
                    </h2>
                    <AccordionPanel pb={4}>
                      <Text>
                        Although we cover everything a recruitment agency does,
                        we are a technology first company that provides software
                        solutions into any recruitment process. Recruiting
                        Agencies charge 20-30% Placement Fees which on average
                        is 20-30k per placement. We are a fraction of that cost.
                      </Text>
                    </AccordionPanel>
                  </>
                )}
              </AccordionItem>
              <AccordionItem w={'100%'} mt={4}>
                {({ isExpanded }) => (
                  <>
                    <h2>
                      <AccordionButton>
                        <Box as="span" flex="1" textAlign="left">
                          <Text fontSize={'xl'}>
                            What makes you different from a Candidate Sourcing
                            Tool/Platform?
                          </Text>
                        </Box>
                        {isExpanded ? (
                          <Icon as={AiOutlineMinus} fontSize="20px" />
                        ) : (
                          <Icon as={AiOutlinePlus} fontSize="20px" />
                        )}
                      </AccordionButton>
                    </h2>
                    <AccordionPanel pb={4}>
                      <Text>
                        How much is your time worth? Candidate Sourcing
                        Platforms are built for you to still spend hours sifting
                        through candidates and engagement all while purchasing
                        more credits to reveal and sift through more data.
                      </Text>
                      <Text mt={5}>
                        Talent Strap embeds this same technology, AI, and Data
                        to automate sourcing. We also provide Assessments,
                        Pre-Screening, Scheduling and much more.
                      </Text>
                    </AccordionPanel>
                  </>
                )}
              </AccordionItem>
              <AccordionItem w={'100%'} mt={4}>
                {({ isExpanded }) => (
                  <>
                    <h2>
                      <AccordionButton>
                        <Box as="span" flex="1" textAlign="left">
                          <Text fontSize={'xl'}>
                            How does Talent Strap charge me?
                          </Text>
                        </Box>
                        {isExpanded ? (
                          <Icon as={AiOutlineMinus} fontSize="20px" />
                        ) : (
                          <Icon as={AiOutlinePlus} fontSize="20px" />
                        )}
                      </AccordionButton>
                    </h2>
                    <AccordionPanel pb={4}>
                      <Text>
                        We first go through a quick demo to see what you
                        actually need in your recruitment process and roles you
                        need help filling. We estimate where we’ll cut out
                        wasted time and show you how we reduce cost normally by
                        90%. Only pay for what you need, and only use us when
                        you need us.
                      </Text>
                    </AccordionPanel>
                  </>
                )}
              </AccordionItem>
              <AccordionItem w={'100%'} mt={4}>
                {({ isExpanded }) => (
                  <>
                    <h2>
                      <AccordionButton>
                        <Box as="span" flex="1" textAlign="left">
                          <Text fontSize={'xl'}>
                            Is Talent Strap specific to any roles or industries?
                          </Text>
                        </Box>
                        {isExpanded ? (
                          <Icon as={AiOutlineMinus} fontSize="20px" />
                        ) : (
                          <Icon as={AiOutlinePlus} fontSize="20px" />
                        )}
                      </AccordionButton>
                    </h2>
                    <AccordionPanel pb={4}>
                      <Text>
                        For the most part we are able to work any role and any
                        industry. However, we normally only source talent out of
                        the Americas, Europe, and Asian countries.
                      </Text>
                    </AccordionPanel>
                  </>
                )}
              </AccordionItem>
              <AccordionItem w={'100%'} mt={4}>
                {({ isExpanded }) => (
                  <>
                    <h2>
                      <AccordionButton>
                        <Box as="span" flex="1" textAlign="left">
                          <Text fontSize={'xl'}>
                            How long does it take to hire someone using your
                            platform?
                          </Text>
                        </Box>
                        {isExpanded ? (
                          <Icon as={AiOutlineMinus} fontSize="20px" />
                        ) : (
                          <Icon as={AiOutlinePlus} fontSize="20px" />
                        )}
                      </AccordionButton>
                    </h2>
                    <AccordionPanel pb={4} mt={3}>
                      <Text>
                        This can vary depending on how many rounds of interviews
                        your company does. With the data we’ve collected, our
                        average fill time based on the type of position is the
                        following:
                      </Text>
                      <Text></Text>
                    </AccordionPanel>
                  </>
                )}
              </AccordionItem>
            </Accordion>
            <Box w="100%" textAlign="center">
              <Button
                textAlign="center"
                ref={btnRef}
                colorScheme="green"
                onClick={onOpen}
                my="5"
                fontSize={16}
              >
                Subscribe To NewsLetter
                <Icon ml={3} fontSize={20} as={BiNews} />
              </Button>
            </Box>
            <Box w={'100%'} h={'400px'}></Box>
            <Divider mt={5} />
            <Box w={'100%'} h={'50px'}></Box>
          </VStack>
          <Box w={'20%'}></Box>
        </Flex>
        <Box w="100%">
          <Footer />
        </Box>
      </VStack>
      {/* // Drawer */}
      <Drawer
        isOpen={isOpen}
        placement="right"
        onClose={onClose}
        finalFocusRef={btnRef}
        size={'lg'}
      >
        <DrawerContent pl={5} >
          <DrawerCloseButton />
          <DrawerHeader>Create your account</DrawerHeader>

          <DrawerBody>
            <MailChimp />
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  );
}

export default LoginHome;
