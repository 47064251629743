import { useLocation } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { themeFontSizeMediumHeading } from '../../../../constants/index';
import { getEmailId, getGroupId } from '../../../../utils/localStorageIndex';
import { fetchJobDetailedData } from '../../../../services/jobTemplateService';
import {
  Box,
  Text,
  Flex,
  Input,
  Checkbox,
  Spinner,
  FormLabel,
  FormControl,
  HStack,
} from '@chakra-ui/react';
import { Editor } from '@tinymce/tinymce-react';

function ViewJobDetails() {
  const email = getEmailId();
  const { state } = useLocation();
  const adminSettings = state?.adminSettings;
  const [jobUuid, setJobUuid] = useState()
  const [jobTitle, setJobTitle] = useState() //Todo:Fetching job_details from state
  const companyName = state?.companyName; //Todo:Fetching company name from state
  const [jobData, setJobData] = useState({});
  const [loading, isLoading] = useState(true);
  const [jobRequirement, setJobRequirement] = useState('');
  const groupId = getGroupId();
  const showHeaderMenu = groupId === '300' ? false : true;

  useEffect(() => {
    if (state?.job_uuid){
      setJobUuid(state?.job_uuid)      
      // delete jobTitle from state to avoid getting same behavior on reload
      delete state['jobTitle']
      fetchJobDetails()
    }

    if (state?.jobTitle){
      setJobTitle(state?.jobTitle)
    }
  }, [state])

  useEffect(() => {
    fetchJobDetails();
  }, []);

  useEffect(() => {
    fetchJobDetails();
  }, [jobUuid]);

  const fetchJobDetails = () => {
    if (!jobUuid){
      return
    }

    isLoading(true);
    let objBody = {
      email: email,
      job_uuid: jobUuid,
    };
    fetchJobDetailedData(objBody, adminSettings)
      .then(res => {
        isLoading(false);
        if (res.result && res.data[0]) {
          setJobData(res.data[0]);
        }
      })
      .catch(err => {
        isLoading(false);
      });
  };

  function formatCurrency(num) {

    if (!num || num == '-9007199254740991'){
      return '$'
    }

    let numStr = num.toString();
    return '$' + numStr.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
  }

  const renderJobDetailsData = () => {
    return (
      <>
        <Flex direction="column" pt={{ sm: '125px', lg: '75px' }}>
          {showHeaderMenu ? (
            <Flex
              bg={'gray.200'}
              p="3"
              fontSize="18px"
              fontWeight="bold"
              color={'black'}
            >
              <Text ml="5">{jobTitle}</Text>
            </Flex>
          ) : null}
          <Box borderRadius="10px" p="5">
            <Box
              css={{
                '&::-webkit-scrollbar': {
                  width: '4px',
                },
                '&::-webkit-scrollbar-track': {
                  width: '6px',
                },
                '&::-webkit-scrollbar-thumb': {
                  borderRadius: '24px',
                  background: '#fff',
                },
              }}
            >
              <FormControl isRequired mb={3}>
                <FormLabel fontSize="sm" fontWeight="bold">
                  Job Title
                </FormLabel>
                <Text
                  fontWeight="500"
                  h="44px"
                  // border={'1px solid #f3f5fa'}
                  borderRadius={'5px'}
                  p="8px"
                  fontSize="md"
                  color="gray.400"
                >
                  {jobData?.job_title ? jobData?.job_title : '-'}
                </Text>
              </FormControl>

              <Flex mt="5">
                <Box w="30%" mr="5">
                  <FormControl mb={3}>
                    <FormLabel fontSize="sm" fontWeight="bold">
                      Job Department Name
                    </FormLabel>
                    <Text
                      fontWeight="500"
                      h="44px"
                      // border={'1px solid #f3f5fa'}
                      borderRadius={'5px'}
                      p="8px"
                      fontSize="md"
                      color="gray.400"
                    >
                      {jobData?.job_department_name
                        ? jobData?.job_department_name
                        : '-'}
                    </Text>
                  </FormControl>
                </Box>

                <Box w="30%" alignItems="center">
                  <FormControl mb={3}>
                    <FormLabel fontSize="sm" fontWeight="bold">
                      Internal Code
                    </FormLabel>
                    <Text
                      fontWeight="500"
                      h="44px"
                      // border={'1px solid #f3f5fa'}
                      borderRadius={'5px'}
                      p="8px"
                      fontSize="md"
                      color="gray.400"
                    >
                      {jobData?.job_code ? jobData?.job_code : '-'}
                    </Text>
                  </FormControl>
                </Box>
              </Flex>
              <Text
                my="5"
                fontSize="18px"
                fontWeight="bold"
                padding="10px"
                bg="gray.200"
                color="#000"
              >
                Location
              </Text>
              {/* location */}
              <Flex alignItems="center" justifyContent={'space-between'}>
                {/*                
                  {selectedLocation === 'Multi Location' ? ( */}
                <Box w="70% !important">
                  <FormControl mb={3}>
                    <FormLabel fontSize="sm" fontWeight="bold">
                      Job Location
                    </FormLabel>
                    <Text
                      fontWeight="500"
                      h="44px"
                      // border={'1px solid #f3f5fa'}
                      borderRadius={'5px'}
                      p="8px"
                      fontSize="md"
                      color="gray.400"
                    >
                      {jobData?.job_location ? jobData?.job_location : ''}
                    </Text>
                  </FormControl>
                </Box>
                {/* ) : null}
                 */}
                <Box w="28%">
                  <Checkbox
                    fontSize={themeFontSizeMediumHeading}
                    size="md"
                    borderColor="#d7d7d7"
                    isChecked={jobData?.job_is_remote === true ? true : false}
                    disabled
                    mr="3"
                  >
                    <Text
                      as="span"
                      p="1"
                      px="3"
                      borderRadius={'5px'}
                      bg="gray.200"
                      color="#000"
                      fontSize={'14px'}
                      fontWeight="bold"
                    >
                      {jobData?.job_location_extended?.replaceAll('_', ' ')}
                    </Text>
                  </Checkbox>{' '}
                </Box>
              </Flex>
              <Text
                my="5"
                fontSize="18px"
                fontWeight="bold"
                padding="10px"
                bg="gray.200"
                color="#000"
              >
                Description
              </Text>
              {/* description */}
              <Box>
                <Flex
                  alignItems={'center'}
                  justifyContent={'space-between'}
                  mb="5"
                >
                  <Text fontSize={'14px'} fontWeight={'bold'}>
                    About the role
                  </Text>
                </Flex>
                <Editor
                  tinymceScriptSrc={'/tinymce/tinymce.min.js'}
                  value={jobData.job_description || ""}
                  disabled={true}
                  init={{
                      branding: false,
                      statusbar: false,
                      toolbar: false,
                      menubar: false,
                      plugins: 'autoresize',
                      content_style: "body {margin: 0; padding: 0}"
                  }}
                />  
                {/* <Input
                  fontWeight="500"
                  rows={jobData?.job_description?.length ? 20 : 5}
                  h={'auto'}
                  p="5px"
                  mt="1"
                  as="textarea"
                  // overflow="auto"
                  borderWidth={'0.5px'}
                  borderRadius={'5px'}
                  fontSize={14}
                  value={jobData?.job_description}
                  isDisabled="true"
                ></Input> */}
              </Box>
              {/* requirement */}
              {/* <Box>
                <Flex
                  alignItems={'center'}
                  justifyContent={'space-between'}
                  my="2"
                >
                  <Text fontSize={'14px'} fontWeight={'bold'}>
                    Requirement
                  </Text>
                </Flex>
                <Box w="100%">
                  <Input
                    fontWeight="500"
                    rows={jobRequirement?.length ? 20 : 5}
                    h={'auto'}
                    p="5px"
                    mt="1"
                    as="textarea"
                    overflow="auto"
                    borderWidth={'0.5px'}
                    borderRadius={'5px'}
                    borderColor={'#c6c6c6'}
                    fontSize={14}
                    value={jobRequirement}
                    isDisabled="true"
                  ></Input>
                </Box>
              </Box> */}
              {/* benifits */}
              <Box mt={5} mb="5">
                <Text my={'2'} fontSize={'14px'} fontWeight={'bold'}>
                  Benefits
                </Text>

                <Box w="100%">
                  <Flex
                    flexWrap="wrap"
                    // border={'1px solid #f3f5fa'}
                    borderRadius={'5px'}
                    p="8px"
                  >
                    {jobData?.job_benefits && jobData?.job_benefits.length ? (
                      JSON.parse(jobData?.job_benefits).map((data, index) => {
                        if (data.isChecked){
                          return (
                            <Text
                              p="1"
                              px="3"
                              borderRadius={'5px'}
                              bg="gray.200"
                              color="#000"
                              fontSize={'14px'}
                              fontWeight="bold"
                              mr="3"
                              mb="3"
                              key={index + data}
                            >
                              {data.name}
                            </Text>
                          );
                        }
                      })
                    ) : (
                      <Text p="1" mr="3" borderRadius="3px">
                        -.
                      </Text>
                    )}
                  </Flex>
                </Box>
              </Box>

              <Box mt={5} mb="5">
                <Text my={'2'} fontSize={'14px'} fontWeight={'bold'}>
                  Supplemental Benefits
                </Text>

                <Box w="100%">
                  <Flex
                    flexWrap="wrap"
                    // border={'1px solid #f3f5fa'}
                    borderRadius={'5px'}
                    p="8px"
                  >
                    {jobData?.job_supplemental_choices && jobData?.job_supplemental_choices.length ? (
                      JSON.parse(jobData?.job_supplemental_choices).map((data, index) => {
                        if (data.isChecked){
                          return (
                            <Text
                              p="1"
                              px="3"
                              borderRadius={'5px'}
                              bg="gray.200"
                              color="#000"
                              fontSize={'14px'}
                              fontWeight="bold"
                              mr="3"
                              mb="3"
                              key={index + data}
                            >
                              {data.name}
                            </Text>
                          );
                        }
                      })
                    ) : (
                      <Text p="1" mr="3" borderRadius="3px">
                        -.
                      </Text>
                    )}
                  </Flex>
                </Box>
              </Box>

              {/* company industry and job function */}
              <Text
                my="5"
                fontSize="18px"
                fontWeight="bold"
                padding="10px"
                bg="gray.200"
                color="#000"
              >
                Company industry and Job function
              </Text>
              <Flex alignItems="center" w="100%" justifyContent="space-between">
                <FormControl mb={3} w="48%" mr="3">
                  <FormLabel fontSize="sm" fontWeight="bold">
                    Comapany Industry
                  </FormLabel>
                  <Text
                    fontWeight="500"
                    h="44px"
                    // border={'1px solid #f3f5fa'}
                    borderRadius={'5px'}
                    p="8px"
                    fontSize="md"
                    color="gray.400"
                  >
                    {jobData?.job_location ? jobData?.job_location : ''}
                  </Text>
                </FormControl>

                <FormControl w="48%" mb={3}>
                  <FormLabel fontSize="sm" fontWeight="bold">
                    Job Function:
                  </FormLabel>
                  <Text
                    fontWeight="500"
                    h="44px"
                    // border={'1px solid #f3f5fa'}
                    borderRadius={'5px'}
                    p="8px"
                    fontSize="md"
                    color="gray.400"
                  >
                    {jobData?.job_function ? jobData?.job_function : ''}
                  </Text>
                </FormControl>
              </Flex>

              <Text
                my="5"
                fontSize="18px"
                fontWeight="bold"
                padding="10px"
                bg="gray.200"
                color="#000"
              >
                Employment Details
              </Text>

              <Box>
                <Box>
                  {/* Job Department */}
                  <Flex
                    flexWrap={'wrap'}
                    justifyContent={'space-between'}
                    alignItems={'center'}
                  >
                    {/* employement type */}
                    <Box w="48%">
                      <FormControl>
                        <FormLabel fontSize="sm" fontWeight="bold">
                          Employment Type:
                        </FormLabel>
                        <Flex
                          flexWrap="wrap"
                          // border={'1px solid #f3f5fa'}
                          borderRadius={'5px'}
                          p="8px"
                        >
                          {jobData?.job_employment_type &&
                          jobData?.job_employment_type.length ? (
                            jobData?.job_employment_type
                              .split(',')
                              .map((data, index) => {
                                return (
                                  <Text
                                    p="1"
                                    px="3"
                                    borderRadius={'5px'}
                                    bg="gray.200"
                                    color="#000"
                                    fontSize={'14px'}
                                    fontWeight="bold"
                                    mr="3"
                                    key={index + data}
                                  >
                                    {data}
                                  </Text>
                                );
                              })
                          ) : (
                            <Text p="1" mr="3" borderRadius="3px">
                              -
                            </Text>
                          )}
                        </Flex>
                      </FormControl>
                    </Box>
                    {/* Experience */}
                    <Box w="48%">
                      <FormControl>
                        <FormLabel fontSize="sm" fontWeight="bold">
                          Experience Level Required:
                        </FormLabel>
                        <Flex
                          flexWrap="wrap"
                          // border={'1px solid #f3f5fa'}
                          borderRadius={'5px'}
                          p="8px"
                        >
                          {jobData?.job_experience_level &&
                          jobData?.job_experience_level.length ? (
                            jobData?.job_experience_level
                              .split(',')
                              .map((data, index) => {
                                return (
                                  <Text
                                    p="1"
                                    px="3"
                                    borderRadius={'5px'}
                                    bg="gray.200"
                                    color="#000"
                                    fontSize={'14px'}
                                    fontWeight="bold"
                                    mr="3"
                                    key={index + data}
                                  >
                                    {data}
                                  </Text>
                                );
                              })
                          ) : (
                            <Text p="1" mr="3" borderRadius="3px">
                              -
                            </Text>
                          )}
                        </Flex>
                      </FormControl>
                    </Box>
                    <Box w="48%" mt="5">
                      <FormControl>
                        <FormLabel fontSize="sm" fontWeight="bold">
                          Education Required:
                        </FormLabel>
                        <Flex
                          flexWrap="wrap"
                          // border={'1px solid #f3f5fa'}
                          borderRadius={'5px'}
                          p="8px"
                        >
                          {jobData?.job_highest_education &&
                          jobData?.job_highest_education.length ? (
                            jobData?.job_highest_education
                              .split(',')
                              .map((data, index) => {
                                return (
                                  <Text
                                    p="1"
                                    px="3"
                                    borderRadius={'5px'}
                                    bg="gray.200"
                                    color="#000"
                                    fontSize={'14px'}
                                    fontWeight="bold"
                                    mr="3"
                                    mb="2"
                                    key={index + data}
                                  >
                                    {data}
                                  </Text>
                                );
                              })
                          ) : (
                            <Text p="1" mr="3" borderRadius="3px">
                              -
                            </Text>
                          )}
                        </Flex>
                      </FormControl>
                    </Box>
                  </Flex>
                </Box>

                {/* salary details */}
                {/* Range From */}
                <Text
                  my="5"
                  fontSize="18px"
                  fontWeight="bold"
                  padding="10px"
                  bg="gray.200"
                  color="#000"
                >
                  Salary
                </Text>
                <Box borderBottom={'1px'} borderColor={'#d7d7d7'} pb="10">
                  <Flex
                    w="100%"
                    justifyContent={'space-between'}
                    alignItems="center"
                    flexWrap="wrap"
                  >
                    <HStack w={'100%'}>
                      <Text>
                          From
                      </Text>
                      <Text>
                        {jobData?.job_salary_min ? formatCurrency(jobData?.job_salary_min) : '-'}
                      </Text>
                      <Text>
                          to 
                      </Text>
                      <Text>
                        {jobData?.job_salary_max ? formatCurrency(jobData?.job_salary_max) : '-'}
                      </Text>
                      <Text>
                        ({jobData?.job_salary_rate ? jobData?.job_salary_rate : '-'})
                      </Text>
                    </HStack>
                    {/* <Box w="25%">
                      <FormControl mb={3}>
                        <FormLabel fontSize="sm" fontWeight="bold">
                          From
                        </FormLabel>
                        <Text
                          fontWeight="500"
                          h="44px"
                          // border={'1px solid #f3f5fa'}
                          borderRadius={'5px'}
                          p="8px"
                          fontSize="md"
                          color="gray.400"
                        >
                          {jobData?.job_salary_min
                            ? jobData?.job_salary_min
                            : '-'}
                        </Text>
                      </FormControl>
                    </Box>
                    <Box w="25%">
                      <FormControl mb={3}>
                        <FormLabel fontSize="sm" fontWeight="bold">
                          To
                        </FormLabel>
                        <Text
                          fontWeight="500"
                          h="44px"
                          borderRadius={'5px'}
                          p="8px"
                          fontSize="md"
                          color="gray.400"
                        >
                          {jobData?.job_salary_max
                            ? jobData?.job_salary_max
                            : '-'}
                        </Text>
                      </FormControl>
                    </Box>
                    <Box w="48%">
                      <FormControl mb={3}>
                        <FormLabel fontSize="sm" fontWeight="bold">
                          Currency
                        </FormLabel>
                        <Text
                          fontWeight="500"
                          h="44px"
                          // border={'1px solid #f3f5fa'}
                          borderRadius={'5px'}
                          p="8px"
                          fontSize="md"
                          color="gray.400"
                        >
                          {jobData?.job_salary_rate
                            ? jobData?.job_salary_rate
                            : '-'}
                        </Text>
                      </FormControl>
                    </Box> */}
                  </Flex>
                </Box>

                <Flex w={'100%'} direction={'column'}>
                  <Text
                    my="5"
                    fontSize="18px"
                    fontWeight="bold"
                    padding="10px"
                    bg="gray.200"
                    color="#000"
                  >
                    Top 3 most important skills or tools for this role
                  </Text>
                  <Box borderBottom={'1px'} borderColor={'#d7d7d7'} pb="10">
                    <Flex
                      w="100%"
                      justifyContent={'space-between'}
                      alignItems="center"
                      flexWrap="wrap"
                    >
                      <Text>
                        {jobData?.top_skills ? jobData?.top_skills : '...'}
                      </Text>
                    </Flex>
                    </Box>
                </Flex>
              </Box>
            </Box>
          </Box>
        </Flex>
      </>
    );
  };

  return (
    <>
      {loading ? (
        <Flex alignItems="center" justifyContent="center" mt="10" h="70vh">
          <Spinner size="xl" />
        </Flex>
      ) : (
        <>{renderJobDetailsData()}</>
      )}
    </>
  );
}

export default ViewJobDetails;
