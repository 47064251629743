import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {Box, Text,Flex, useColorModeValue,useDisclosure,useToast, Tabs, TabList, Tab, 
  TabPanels, TabPanel, Drawer, DrawerContent, DrawerCloseButton, DrawerHeader, Button, Icon
} from '@chakra-ui/react';
import { getEmailId } from '../../../utils/localStorageIndex.js';
import { isObjectEmpty, toTitleCase, workflowStagesMapping } from '../../../utils/common.js';
import CandidateProfile from './components/CandidateProfile.js';
import CandidateFeedback from './components/CandidateFeedback.js';
import DisqualifyCandidate from './components/DisqualifyCandidate.js';
import EnrichCandidate from './components/EnrichCandidate.js';
import UploadResumeForCandidate from './components/UploadResumeForCandidate.js';
import CandidateHeadlines from './components/CandidateHeadlines.js';
import DeleteCandidate from './components/DeleteCandidate.js';
import AssignCandidateToJob from './components/AssignCandidateToJob.js';
import CandidateApplySource from '../../common/CandidateApplySource.js';
import CandidateScreeningQuestions from './components/CandidateScreeningQuestions.js';
import SendEmail from './components/SendEmail.js';
import UploadHeadshotForCandidate from './components/UploadHeadshotForCandidate.js';
import moment from 'moment';
import MoveCandidateToOtherStage from './components/MoveCandidateToOtherStage.js';
import { getCandidateDetails } from '../../../services/recruiterServices.js';
import { BiArrowBack } from 'react-icons/bi';
import RejectCandidate from '../../business/jobWizard/jobWorkflow/Workflow/CandidateSearch/RejectCandidate.js';
import QualifyCandidate from './components/QualifyCandidate.js';


function SearchedCandidateDetailView() {
  const emailId = getEmailId();
  const toast = useToast();
  const navigate = useNavigate();
  const { state } = useLocation();
  const adminSettings = state?.adminSettings ? state?.adminSettings : false;
  const [selectedBusiness, setSelectedBusiness] = useState({})
  const [selectedCandidate, setSelectedCandidate] = useState({});
  const [selectedJob, setSelectedJob] = useState({})
  const [isCandidateLoading, setIsCandidateLoading] = useState(false)
  const borderColorStyle = useColorModeValue('gray.200', 'gray.400'); // {light, dark}
  const { isOpen: isRejectionPanelOpen, onOpen: onRejectionPanelOpen, onClose: onRejectionPanelClose } = useDisclosure();

  useEffect(() => {
    if (!isObjectEmpty(state?.candidate)){
      const candidate = state?.candidate
      setSelectedCandidate(candidate)
      setSelectedJob(candidate?.job)
      setSelectedBusiness(candidate?.business)
    }
  }, [state?.candidate])

  const routeToCandidateList = () => {
    navigate('/admin/candidatelist', {
      state: {
          adminSettings: true
      },
    });
  }

  const refreshCandidateDetails = () => {
    const objBody = {
      email: emailId,
      candidate_uuid: selectedCandidate.candidate_uuid,
    };
    setIsCandidateLoading(true)
    getCandidateDetails(objBody)
      .then(res => {        
        if (res?.result) {
            setSelectedCandidate(res?.data)
        } else {
          toast({
            title: 'Unable to get candidate details',
            description: res?.message,
            status: 'error',
            duration: 5000,
          });
        }
        setIsCandidateLoading(true)
      })
      .catch(err => {
        setIsCandidateLoading(true)
        toast({
          title: 'Unable to load candidates for this job',
          description: err.toString(),
          status: 'error',
          duration: 5000,
        });
      });
  }

  const CandidateActions = () => {
    return <>
      <Flex justifyContent={'right'} p={'3px 8px'} borderWidth={0} borderColor={borderColorStyle}
        borderRadius={'2px'} boxShadow={'0 0px 0px 0 lightgray,0 0px 5px 0 lightgray'} alignItems={'center'}
      >

        <UploadResumeForCandidate
         job={selectedJob}
         adminSettings={adminSettings}
         bulkSelectedCandidates={[selectedCandidate]}
         postUploadActions={{
          refreshCandidateDetails: () => refreshCandidateDetails()
         }}
        />

        <UploadHeadshotForCandidate
         job={selectedJob}
         adminSettings={adminSettings}
         bulkSelectedCandidates={[selectedCandidate]}
         postUploadActions={{
          refreshCandidateDetails: () => refreshCandidateDetails()
         }}
        />

        <EnrichCandidate
         adminSettings={adminSettings}
         bulkSelectedCandidates={[selectedCandidate]}
         postEnrichActions={{
          refreshCandidateDetails: () => refreshCandidateDetails()
         }}
        />

        <AssignCandidateToJob
         job={selectedJob}
         adminSettings={adminSettings}
         bulkSelectedCandidates={[selectedCandidate]}
         postAssignActions={{
          refreshCandidateDetails: () => refreshCandidateDetails()
         }}
        />

        {adminSettings &&
        <SendEmail
          selectedCandidates={[selectedCandidate]}
          postEmailActions={{
            refreshCandidateDetails: () => refreshCandidateDetails()
          }}
        />
        }
      

        {!selectedCandidate?.workflow?.is_disqualified &&
        <DisqualifyCandidate
          bulkSelectedCandidates={[selectedCandidate]}
          postDisqualifyActions={{
            refreshCandidateDetails: () => refreshCandidateDetails()
          }}
        />
        }

        {selectedCandidate?.workflow?.is_disqualified &&
        <QualifyCandidate
          bulkSelectedCandidates={[selectedCandidate]}
          postQualifyActions={{
            refreshCandidateDetails: () => refreshCandidateDetails()
          }}
        />
        }

        <DeleteCandidate
          bulkSelectedCandidates={[selectedCandidate]}
          postDeleteActions={{
            routeToCandidateList: () => routeToCandidateList()
          }}
        />

        <MoveCandidateToOtherStage
          job={selectedJob}
          bulkSelectedCandidates={[selectedCandidate]}
          onRejectionPanelOpen={onRejectionPanelOpen}
          postMoveActions={{
            refreshCandidateDetails: () => refreshCandidateDetails()
          }}
        />
      </Flex>
    </>
  }

  const CandidateTabs = () => {
    return <>
      <Flex mt={'40px'} boxShadow={'0 0px 0px 0 lightgray,0 1px 5px 0 lightgray'}  borderWidth={0} borderColor={'gray.200'} padding={'20px'}>
        <Tabs colorScheme='gray' width={'100%'}>
          <TabList border={0}>
            <Tab mr={1} width={'150px'} fontWeight={'bold'} _selected={{ color: 'white', bg: '#313b46', 'borderRadius': '6px' }}>Profile</Tab>
            <Tab mr={1} width={'280px'} fontWeight={'bold'} _selected={{ color: 'white', bg: '#313b46', 'borderRadius': '6px' }}>Screening Questions</Tab>
            <Tab mr={1} width={'150px'} fontWeight={'bold'} _selected={{ color: 'white', bg: '#313b46', 'borderRadius': '6px' }}>Timeline</Tab>
            <Tab mr={1} width={'150px'} fontWeight={'bold'} _selected={{ color: 'white', bg: '#313b46', 'borderRadius': '6px' }}>Review</Tab>
            <Tab width={'150px'} fontWeight={'bold'} _selected={{ color: 'white', bg: '#313b46', 'borderRadius': '6px' }}>Feedback</Tab>
          </TabList>
          <TabPanels pt={10}>
            <TabPanel padding={0}>
              <CandidateProfile candidate={selectedCandidate} job={selectedJob}/>
            </TabPanel>
            <TabPanel padding={0}>
              <CandidateScreeningQuestions candidate={selectedCandidate} />
            </TabPanel>
            <TabPanel padding={0}>
              <Text as={'b'}>Communication!</Text>
            </TabPanel>
            <TabPanel padding={0}>
              <Text as={'b'}>Review!</Text>
            </TabPanel>
            <TabPanel padding={0}>
              <CandidateFeedback 
                candidate={selectedCandidate} 
                job={selectedJob}
                adminSettings={adminSettings}
              />
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Flex>
    </>
  }

  const CandidateCurrentStage = ({candidate}) => {
    let currentStage = ""
    if (candidate?.workflow?.workflow_position){
      currentStage = candidate?.workflow?.workflow_position
    }

    return currentStage ? <Text>At <b>{workflowStagesMapping[currentStage]}</b> stage {candidate?.workflow?.is_disqualified ? ' - Disqualified' : null}</Text> : null
  }

  const JobFunctionAndLocation = ({job}) => {
    let text = ""
    if (job?.job_function && job?.job_location){
      text = `${job?.job_function} - ${job?.job_location}`
    }
    else if (job?.job_function){
      text = job?.job_function
    }

    else if (job?.job_location){
      text = job?.job_location
    }
    
    return <Text mt={2} mb={2}>{text}</Text>
  }

  const CandidateJobDetails = ({candidate}) => {
    return <>
      <Flex direction={'column'} p={5} borderLeft={'0.1px solid'} borderLeftColor={borderColorStyle}>
        <Text as={'b'}>{selectedJob?.job_title}</Text>
        <JobFunctionAndLocation job={selectedJob} />
        <CandidateCurrentStage candidate={candidate} />
        <Flex mt={2}>
          <CandidateApplySource candidate={candidate} /> - <Text ml={1}>{moment.utc(candidate?.created_at).fromNow()}</Text>
        </Flex>        
      </Flex>
    </>
  }

  const CandidateDetails = () => {
    return <>
        <Flex direction={'column'} width={'100%'} ml={'20px'} mr={'20px'}>
            <CandidateActions />
            <Flex mt={5} justifyContent={'space-between'} borderWidth={0} borderColor={borderColorStyle} borderRadius={'2px'} boxShadow={'0 0px 0px 0 lightgray,0 1px 5px 0 lightgray'}>
                <CandidateHeadlines candidate={selectedCandidate} applyShadow={false} headshotWidth={'60px'} headshotHeight={'60px'}
                  headshotRadius={'60px'} mTop={0} candidateNameFontSize={'18px'}/>
                <CandidateJobDetails candidate={selectedCandidate} />
            </Flex>            
            <CandidateTabs />
        </Flex>
    </>
  }

  const CandidateOverview = () => {
    return <>
      <Flex direction={'column'} boxShadow={'0 0px 0px 0 lightgray,0 1px 5px 0 lightgray'} padding={'20px'}>
          <Text fontWeight={'600'} fontSize={'18px'}>Candidate Overview</Text>
          <Text fontSize={'16px'}>Upcoming events, recent comments, messages and more, will appear here.</Text>
      </Flex>
    </>
  }

  const onCandidateRejectionSuccess = () => {
    onRejectionPanelClose();
};
  
  return(
    <Box width={{'base': '400px', 'sm': '600px', 'md': '800px', 'lg': '1000px', 'xl': '1200px', '2xl': '1400px'}} pt={{ base: '180px', md: '80px', xl: '80px' }}>
        
        <Button height={8} ml={2} fontWeight={'normal'} variant={'ghost'} onClick={routeToCandidateList}>
            <Icon as={BiArrowBack} mr={2} />Back to Candidate List
        </Button>
        <Flex w={'100%'} mt={10}>            
            <CandidateDetails />
            <CandidateOverview />
        </Flex>

    {/* Rejection panel */}
    <RejectCandidate
        selectedCandidates={[selectedCandidate]}
        isRejectionPanelOpen={isRejectionPanelOpen}
        onRejectionPanelClose={onRejectionPanelClose}
        postRejectActions={{
          onCandidateRejectionSuccess: () => onCandidateRejectionSuccess()
        }}
      />
    </Box>
  )
}

export default SearchedCandidateDetailView;
