import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, location } from 'react-router-dom';
import {
    AiFillEdit,
    AiOutlineCheckCircle,
    AiFillFilePdf,
    AiFillInfoCircle,
    AiOutlineLinkedin,
    AiOutlineFilePdf,
    AiOutlineInfoCircle,
    AiOutlineArrowLeft,
    AiOutlineCaretLeft,
  } from 'react-icons/ai';
import {
  Box, Icon,
  HStack,
  Text,
  Spacer,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  CloseButton,
  UnorderedList,
  List,
  ListItem,
  ListIcon,
  Center,
  Flex,
  Button,
  Input,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  Slider,
  Link,
  TabPanel,
  useToast,
  SliderFilledTrack,
  SliderThumb,
  SliderMark,
  SliderTrack,
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerContent,
  useDisclosure,
  DrawerCloseButton,
  Spinner,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  ModalBody,
  useColorModeValue,
} from '@chakra-ui/react';
import Card from '../../../../components/card/Card';
import { getEmailId } from '../../../../utils/localStorageIndex';
import { fetchPlanDetails, fetchSelectedPlanDetails, cancelSelectedPlan } from '../../../../services/paymentServices';


function CancelSubscription() {
    const toast = useToast();
    const navigate = useNavigate();
    const { state } = useLocation();

    const stripePricingTable = process.env.REACT_APP_STRIPE_PRICING_TABLE;
    const stripePublishKey = process.env.REACT_APP_STRIPE_PUBLISH_KEY;
    const btnRef = React.useRef();
    const customerEmail = getEmailId() ? getEmailId() : null;
    const subKey = state?.subKey ? state?.subKey : null;
    const [loading, isLoading] = useState(false);

    const [subscriptionData, setSubscriptionData] = useState([]);
    const [confirmText, setConfirmText] = useState(null);
    
    const textColorPrimary = useColorModeValue('secondaryGray.900', 'gray.600');
    const [alertVisible, setAlertVisible] = useState(false);
    const [alertMessage, setAlertMessage] = useState(
      {
        title:'Bad Setting',
        message:"You do not have proper access to this page.. Please go back to contact Talent Strap support!!",
        type:'error'
      });

    const shadow = useColorModeValue(
        '14px 17px 40px 4px rgba(112, 144, 176, 0.18)',
        '14px 17px 40px 4px rgba(112, 144, 176, 0.06)'
    );

    useEffect(() => {
        fetchSelectedSubscription();
    }, [subKey]);

    const fetchSelectedSubscription = () => {
        if (subKey != null){
            let objBody = {
                email: getEmailId(),
                subKey: subKey
            };
            fetchSelectedPlanDetails(objBody)
            .then(res => {
                isLoading(false);
                if (res?.result && res?.data?.length === 1 && res?.data[0]?.subscriptions?.length > 0) {
                    setSubscriptionData(res?.data[0]?.subscriptions);
                } 
            })
            .catch(err => {
                isLoading(false);
                console.log(err);
            });
        }
    };

    const startCancellationProcess = (subKeyLocal) => {
        let objBody = {
            email: getEmailId(),
            sid: subKeyLocal
        };
        cancelSelectedPlan(objBody)
        .then(res => {
            isLoading(false);
            if (res?.result && res?.data?.length === 1 && res?.data[0]?.status) {
                toastFunction('Subscription Cancelled Successfully', 'success');
                navigate('/business/billing', {
                    state: {
                        sourcePage:'cancelplan',
                    },
                });
            } else{
                toastFunction('Unable to cancel the subscription. Please contact Talent Strap support.', 'error');
            }
        })
        .catch(err => {
            toastFunction('Unable to cancel the subscription. Please contact Talent Strap support.', 'error');
            console.log(err);
        });        
    }

    const renderSpinner = () => {
        return (
        <Flex h="50vh" alignItems="center" justifyContent="center" mt="10">
            <Spinner size="xl" />
        </Flex>
        );
    };

    const handleInputChange = e => {
        if (e.target.files) {
            setConfirmText(e.target.files);
        }
    };

    const toastFunction = (message, status) => {
        return toast({
        title: message,
        status: status,
        isClosable: true,
        duration: 3000,
        });
    };

    const backToBillingPage = () => {
        navigate('/business/billing', {
            state: {
                sourcePage:'purchaseplan',
                adminSettings: false
            },
        });
    };

    return (
        <>
        {loading ? (
            <>{renderSpinner()}</>
        ) : (
            <Flex direction="column" pt={{ sm: '125px', lg: '75px' }}>
            <Flex
                direction="column"
            >
                <Flex w={'100%'} direction={'column'} align={'center'}>
                    {subKey === null
                    ?<Flex w={'100%'} p={2} direction={'column'}>
                        <Alert status={alertMessage.type} >
                            <AlertIcon />
                            <Box>
                                <AlertTitle>{alertMessage.title}</AlertTitle>
                                <AlertDescription>{alertMessage.message}</AlertDescription>
                            </Box>
                            <Spacer />
                            <CloseButton
                                alignSelf='flex-start'
                                position='relative'
                                right={-1}
                                top={-1}
                            />                    
                        </Alert>
                    </Flex>
                    :<Flex w={'100%'} direction={'column'}>
                    <Card w={'100%'} p={1}>
                        <Flex w={'100%'} p={2}>
                            <Text mt={4} fontSize={'xl'}>{'Cancel Existing Subscription'}</Text>
                            <Spacer />
                            <Button 
                                onClick={() => backToBillingPage()}
                                mt={4} color={textColorPrimary} bg={'red.200'}>
                                <Icon as={AiOutlineCaretLeft} mr={2} />Cancel
                            </Button>
                        </Flex>
                    </Card>
                    <Card w={'100%'} mt={1}>
                        <HStack w={'100%'}>
                        <Flex w={'40%'}>
                            <Card w={'100%'} h={'400px'}>
                                <Text fontSize={'xl'}>Cancellation is done on the following criteria:</Text>
                                <List spacing={3} mt={5}>
                                    <ListItem>
                                        <ListIcon as={AiOutlineCheckCircle} color='green.500' />
                                        Cancellation will done immediately.
                                    </ListItem>
                                    <ListItem>
                                        <ListIcon as={AiOutlineCheckCircle} color='green.500' />
                                        All the open slots in this subscription will be set to "Archived" 
                                    </ListItem>
                                    <ListItem>
                                        <ListIcon as={AiOutlineCheckCircle} color='green.500' />
                                        There is no refund of any kind for monthly or annual subscription.
                                    </ListItem>
                                </List>
                            </Card>
                        </Flex>
                        <Flex w={'60%'} direction={'column'}  h={'400px'}>
                            <Flex w={'100%'} direction={'column'} align={'start'}>
                            {subKey != null 
                            ?<Card align={'start'}  h={'400px'}>
                                <Text color={'red.500'}><b>Note:</b>{' '}Subscription Cancelation action is final there is no turing back.</Text>
                                {subscriptionData?.length > 0
                                ?<Card w={'100%'}>
                                    <Flex w={'100%'} direction={'column'}>
                                        <Text fontSize={'lg'}>{'Plan Info'}</Text>
                                        <Text mt={2}  fontSize={'md'}><b>{subscriptionData[0].plan_type}</b></Text>
                                        <Text>{subscriptionData[0].plan_info}</Text>
                                        <Text>{subscriptionData[0].total_slots} Slots in this Subscription</Text>
                                        <Text>{subscriptionData[0].is_annual ? 'Annual': 'Monthly'} Plan</Text>
                                        <Text>Subscription Started: {new Date(subscriptionData[0].start_date).toLocaleDateString('en-us', 
                                        { weekday:"short", year:"numeric", month:"short", day:"numeric"})}</Text>
                                        <Text mt={5} fontSize={'xs'}>Please write <b>cancel subscription</b> in box below and use "Start Cancellation" Button </Text>
                                        <Flex 
                                            p={2} borderWidth={'0.5px'} rounded={'lg'} borderColor={'red.200'}
                                            w={'100%'} mt={2}>
                                            <Input
                                                placeholder="cancel subscription"
                                                w={'300px'}
                                                value={confirmText}
                                                onChange={e => {
                                                    setConfirmText(e.target.value);
                                                }}
                                            >
                                            </Input>
                                            <Button  
                                                isDisabled={confirmText === 'cancel subscription' ? false : true}
                                                onClick={() => startCancellationProcess(subscriptionData[0]?.sub_key)}
                                                ml={1} size={'md'} px={10} borderWidth={'0.5px'} rounded={'lg'} borderColor={'red.200'}>
                                                    Start Cancellation
                                            </Button>
                                        </Flex>
                                    </Flex>
                                </Card>
                                :<Text color={'red.400'}>Unable to get the subscription details!!</Text>}
                            </Card>
                            :<Flex w={'100%'} p={2} direction={'column'}>
                                <Text color={'red.200'}>Something is not right to subscribe business plan, please contact Talent Strap Support.</Text>
                            </Flex>}
                            </Flex>
                        </Flex>
                        </HStack>
                        </Card>
                    </Flex>}
                </Flex>
            </Flex>
            </Flex>
        )}
        </>
    );
}

export default CancelSubscription;
