import React, { useState } from 'react';
import { Button, Text, Flex, Drawer, DrawerContent, DrawerHeader, DrawerCloseButton, useToast, useColorModeValue} from '@chakra-ui/react';
import "react-datepicker/dist/react-datepicker.css";
import TagsInput from 'react-tagsinput';
import { getEmailId } from '../../../../utils/localStorageIndex';
import { generateMatchingSkillsWithAI, updateCandidateSkills } from '../../../../services/recruiterServices';


export default function EditCandidateSkills(props) {
  const {candidate, job} = props;
  const toast = useToast()
  const [isFormSubmitting, setIsFormSubmitting] = useState(false)
  const [isGeneratingMatchingSkills, setIsGeneratingMatchingSkills] = useState(false)
  const brandColor = useColorModeValue('brand.500', 'white');
  const [allSkills, setAllSkills] = useState(candidate?.details?.ex_data?.all_skills || [])
  const [matchingSkills, setMatchingSkills] = useState(JSON.parse(candidate?.workflow?.tags_skill_set || "[]"))

  const onSubmitForm = () => {
    const data = {
      all_skills: allSkills,
      matching_skills: matchingSkills,
      job_uuid: job?.job_uuid,
      candidate_uuid: candidate?.candidate_uuid,
      email: getEmailId()
    }
    setIsFormSubmitting(true);
    updateCandidateSkills(data)
    .then(res => {
        if (res?.result){
            props.getAllCandidatesForJob();
            toast({
                title: 'Skills updated successfully.',
                status: 'success', 
                isClosable: true, 
                duration: 3000
            });
        }else{
            toast({
                title: 'Skills not updated.',
                description: res?.message,
                status: 'error', 
                isClosable: true, 
                duration: 3000
              });
        }
        setIsFormSubmitting(false);
    })
    .catch(err => {
        console.log(err)
        setIsFormSubmitting(false);
        toast({
            title: 'Skills not updated.',
            description: err.toString(),
            status: 'error', 
            isClosable: true, 
            duration: 3000
          });
    })
  }

  const generateMatchingSkillsUsingAI = () => {
    const data = {
      job_uuid: job?.job_uuid,
      candidate_uuid: candidate?.candidate_uuid,
      email: getEmailId()
    }
    setIsGeneratingMatchingSkills(true);
    generateMatchingSkillsWithAI(data)
    .then(res => {
        if (res?.result){
            setMatchingSkills(res?.data)
            toast({
                title: 'Matching skills generated successfully.',
                status: 'success', 
                isClosable: true, 
                duration: 3000
            });
        }else{
            toast({
                title: 'Skills not generated.',
                description: res?.message,
                status: 'error', 
                isClosable: true, 
                duration: 3000
              });
        }
        setIsGeneratingMatchingSkills(false);
    })
    .catch(err => {
        console.log(err)
        setIsGeneratingMatchingSkills(false);
        toast({
            title: 'Skills not generated.',
            description: err.toString(),
            status: 'error', 
            isClosable: true, 
            duration: 3000
          });
    })
  }

  const onChangeAllSkills = (skills) => {
    setAllSkills(skills)
  }

  const onChangeMatchingSkills = (skills) => {
    setMatchingSkills(skills)
  }

  return (
    <Drawer size={'lg'} isOpen={props.isEditSkillsPanelOpen} onClose={props.onEditSkillsPanelClose}>
      <DrawerContent pl={5} >
        <DrawerHeader>Edit Candidate Skills</DrawerHeader>
        <DrawerCloseButton />
          <Flex p={'30px'} direction={'column'} mt={5}>
            <Flex alignItems={'center'} mb={14}>
                <Text as={'b'} minW={'140px'} mr={3}>All Skills</Text>
                <Flex direction={'column'}>
                  <TagsInput
                    // tab, enter, comma 
                    addKeys={[9, 13, 188, 186]}
                    value={allSkills}
                    borderRadius={'md'}
                    onChange={onChangeAllSkills}                    
                    inputProps={{
                      placeholder:
                        allSkills?.length === 0
                          ? 'Enter skills for candidate'
                          : '',
                    }}
                  />
                  <Text mt={1} color={'gray.500'}>Press Enter, Tab or Comma(,) after entering a skill</Text>
                </Flex>
            </Flex>

            <Flex alignItems={'center'} mb={8}>
                <Text as={'b'} minW={'140px'} mr={3}>Job Matching Skills</Text>
                <Flex direction={'column'} width={'75%'}>
                  <Flex>
                    <TagsInput
                      // tab, enter, comma 
                      addKeys={[9, 13, 188, 186]}
                      value={matchingSkills}
                      borderRadius={'md'}
                      onChange={onChangeMatchingSkills}
                      inputProps={{
                        placeholder:
                          matchingSkills?.length === 0
                            ? 'Enter job matching skills for candidate'
                            : '',
                      }}
                    />
                    <Button ml={5} onClick={generateMatchingSkillsUsingAI} width={'170px'} variant={'outline'} size={'sm'} fontSize={'14px'}
                        fontWeight="normal" isLoading={isGeneratingMatchingSkills} loadingText={'Generating...'}
                    >Generate with AI</Button>
                  </Flex>
                  <Text mt={1} color={'gray.500'}>Press Enter, Tab or Comma(,) after entering a skill</Text>
                </Flex>

                
            </Flex>

            <Button onClick={onSubmitForm} width={'200px'} variant="brand" _hover={{'bg': brandColor}}
                fontWeight="500" isLoading={isFormSubmitting} loadingText={'Updating...'}
            >
              Update
            </Button>
          </Flex> 
      </DrawerContent>
    </Drawer>    
  );
}
