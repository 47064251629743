import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, location } from 'react-router-dom';
// Chakra imports
import {
    Button,
    Box,
    Select,
    Flex,
    FormControl,
    Text,
    useDisclosure,
    AlertDialog,
    AlertDialogBody,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogContent,
    AlertDialogOverlay,
    useToast,
    useColorModeValue,
  } from "@chakra-ui/react";
  import Card from "../../../../../components/card/Card.js";
  import InputField from "../../../../../components/fields/InputField.js";
  
  export default function HrIntegrationWidget() {
    const toast = useToast();
    const navigate = useNavigate();
    const { state } = useLocation();
    const { isOpen, onOpen, onClose } = useDisclosure()
    const cancelRef = React.useRef()

    // Chakra Color Mode

    const [hrSystem, setHrSystem] = useState(null);
    const [hrSystemUuid, setHrSystemUuid] = useState(null);
    const [hrSystemsList, setHrSystemsList] = useState([
        { name: 'Gusto', value: 'Gusto' },
        ]);
    const textColorPrimary = useColorModeValue("secondaryGray.900", "white");
    const textColorSecondary = "secondaryGray.600";

    const launchIntegrationPage = () => {
      if (hrSystem != null){
        navigate('/business/integration/hrconfig', {
            state: {
                sourcePage:'hrintegrationwidget',
                hrKey:hrSystem
            },
        });
      }
    };

    const launchVerificationPage = (hrSystemUuid) => {
      if (hrSystemUuid != null){
        navigate('/business/integration/hrverify', {
            state: {
                sourcePage:'hrintegrationwidget',
                hrSystemUuid:hrSystemUuid
            },
        });
      }
    };

    const updateSelectedHr = (value) => {
      if (value != 'none'){
        setHrSystem(value);
      } else if (value == 'none'){
        setHrSystem(null);
      }
    }
    return (
      <FormControl>
        <Card mb='20px' pb='50px'>
          <Flex direction='column' mb='40px' ms='10px'>
            <Text fontSize='xl' color={textColorPrimary} fontWeight='bold'>
            HR Services integration
            </Text>
            <Text fontSize='sm' color={textColorSecondary}>
              Here you can integrate your HR system with Talent Strap
            </Text>
          </Flex>
          <Flex w={'100%'} direction='column' rounded={'md'} m={2} p={2} h={'200px'}>
            <Text fontSize='lg' color={textColorPrimary} fontWeight='bold'>Currently Configured HR Systems:</Text>
            <Flex w={'100%'} mb={1} p={2} align={'center'} borderWidth ={'0.5px'} borderColor={'gray.200'} rounded="md">
                <Box w={'100%'} >
                    <Text fontSize={'lg'}>Gusto</Text>
                </Box>
                <Box w={'450px'} >
                    <Button size={'sm'} mr={1} colorScheme='blue'>ReConfigure</Button>
                    <Button size={'sm'} mr={1} 
                      onClick={() => 
                        {
                          launchVerificationPage('uuid_comes_here');
                        }}
                      colorScheme='green'>Verify</Button>
                    <Button size={'sm'} mr={1} 
                      onClick={() => 
                      {
                          setHrSystemUuid('uuid_comes_here');
                          onOpen();
                      }}
                      colorScheme='red'>Disable</Button>
                </Box>
            </Flex>
            <Flex w={'100%'} p={2} align={'center'} borderWidth ={'0.5px'} borderColor={'gray.200'} rounded="md">
                <Box w={'100%'} >
                    <Text fontSize={'lg'}>Bamboo HR</Text>
                </Box>
                <Box w={'450px'} >
                    <Button size={'sm'} mr={1} colorScheme='blue'>ReConfigure</Button>
                    <Button size={'sm'} mr={1} colorScheme='green'>Verify</Button>
                    <Button size={'sm'} mr={1} colorScheme='red'>Disable</Button>
                </Box>
            </Flex>

          </Flex>
          <Flex mt={4} w={'100%'} direction='column' rounded={'md'} m={2} p={2} >
            <Text fontSize='lg' color={textColorPrimary} fontWeight='bold'>Configure an HR System:</Text>
            <Flex direction='column' w={'100%'} p={2}>
                <Text>Please select a HR system from the list below to configure it:</Text>
                <Select
                    size="sm"
                    value={hrSystem}
                    onChange={e => {
                        updateSelectedHr(e.target.value);
                        // setHrSystem(e.target.value);
                    }}
                    >
                    {/* <option disabled value="">
                        Please Select
                    </option> */}
                    <option value='none'>Please Select</option>
                    <option value="Gusto">Gusto</option>
                    {/* <option value="Gusto" selected>Gusto</option> */}
                    <option value="BambooHR">Bamboo HR</option>
                </Select>
            </Flex>
            <Button
                onClick={() => launchIntegrationPage()}
                variant={hrSystem === null ? 'red' : 'brand'}
                bg={hrSystem === null ? 'red.100' : null}
                minW='183px'
                fontSize='sm'
                fontWeight='500'
                disabled={hrSystem === null ? true : false}
                ms='auto'>
                Start HR Integration
            </Button>
        </Flex>
        </Card>
        <AlertDialog
              isOpen={isOpen}
              leastDestructiveRef={cancelRef}
              onClose={onClose}
          >
              <AlertDialogOverlay>
              <AlertDialogContent>
                  <AlertDialogHeader fontSize='lg' fontWeight='bold'>
                  Disable HRXX Configuration
                  </AlertDialogHeader>
                  <AlertDialogBody p={8}>
                  Are you sure to disable the HR Configuration? 
                  <br/>
                  You can enable it later if you decide in future or reconfigure.
                  </AlertDialogBody>

                  <AlertDialogFooter>
                  <Button ref={cancelRef} onClick={onClose}>
                      Cancel
                  </Button>
                  <Button colorScheme='red' 
                    //onClick={() => startModificationProcess()} 
                    ml={3}>
                      Disable Integration
                  </Button>
                  </AlertDialogFooter>
              </AlertDialogContent>
              </AlertDialogOverlay>
          </AlertDialog>
      </FormControl>
    );
  }
  