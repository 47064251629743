import axios from './axios';
import { serviceUrls } from './serviceUrls';

export const nylasReadGoogleCalender = (
    data,
    config = null,
    axiosInstance = axios
  ) => {
    return axiosInstance.post(serviceUrls().nylasReadCalender, data, config);
};

export const nylasBuildAuthUrl = (
  data,
  config = null,
  axiosInstance = axios
) => {
  return axiosInstance.post(serviceUrls().nylasBuildAuthUrl, data, config);
};

export const nylasDisconnectService = (
  data,
  config = null,
  axiosInstance = axios
) => {
  return axiosInstance.post(serviceUrls().nylasDisconnectService, data, config);
};

export const nylasReadGoogleCalenderEvents = (
    data,
    config = null,
    axiosInstance = axios
  ) => {
    return axiosInstance.post(serviceUrls().nylasReadCalenderEvents, data, config);
  };

  export const nylasDisconnectCalenders = (
    data,
    config = null,
    axiosInstance = axios
  ) => {
    return axiosInstance.post(serviceUrls().nylasDisconnectCalenders, data, config);
  };
  
  export const nylasCreateCalenderEvent = (
    data,
    config = null,
    axiosInstance = axios
  ) => {
    return axiosInstance.post(serviceUrls().nylasCreateCalenderEvent, data, config);
  };
