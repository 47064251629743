import { RiBuilding4Fill} from "react-icons/ri"
import { Flex, Icon, Text, Tooltip } from "@chakra-ui/react"

export default function CandidateLatestJob (props) {
    const {candidate, rawData, fontWeight, fontSize, fontColor, mTop, mLeft, mRight, mBottom} = props
    let latestJobText = ''

    if (candidate?.latest_title && candidate?.current_company){
        latestJobText = `${candidate?.latest_title} at ${candidate?.current_company}`
    }

    else if (rawData?.experience?.length > 0 && rawData?.experience[0]?.company?.name && rawData?.experience[0]?.title?.name){
        latestJobText = `${rawData?.experience[0]?.title?.name} at ${rawData?.experience[0]?.company?.name}`
    }

    else if (candidate?.latest_title) {
        latestJobText = candidate?.latest_title
    }

    else {
        latestJobText = "N/A"
    }

    return (
        <Flex ml={mLeft ? mLeft : null} mr={mRight ? mRight : null}
                mt={mTop ? mTop : null} mb={mBottom ? mBottom : null}
                alignItems={'center'}
            >
                <Tooltip label={'Latest Job'} fontSize='md'>
                    <Flex fontSize={fontSize ? fontSize : null}
                            fontWeight={fontWeight ? fontWeight : null}
                            color={fontColor ? fontColor : null} alignItems={'center'}
                    >
                        <Icon mr={2} fontWeight={'bold'} as={RiBuilding4Fill} />
                        <Text>{latestJobText}</Text>
                    </Flex>
                </Tooltip>
        </Flex>        
    )
  }
