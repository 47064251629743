import React, { useEffect, useState } from 'react';
import {Flex, Button, useToast, Box
} from '@chakra-ui/react';
import { submitScreeningQuestions } from '../../../../../services/questionServices';
import { getEmailId } from '../../../../../utils/localStorageIndex';
import { isObjectEmpty, validateScreeningQuestions } from '../../../../../utils/common'
import { setJobWizardStatusLevel } from '../../../../../store/actions/topNotificationActions';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import JobApplicationForm from '../../../../common/JobApplicationForm';

export default function JobIntakeWizard5(props) {
    const [questionErrors, setQuestionErrors] =  useState({})
    const [screeningQuestions, setScreeningQuestions] = useState([])
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [isFormSubmitting, setIsFormSubmitting] = useState(false);
    const toast = useToast();
	const [job, setJob] = useState({})
    const [saveCompleteFlag, setSaveCompleteFlag] = useState(false);

    useEffect(() => {
        if (!isObjectEmpty(props?.job)){
            setJob(props.job)
        }
    }, [props?.job])

    useEffect(() => {
        if (saveCompleteFlag) {
            saveScreeningQuestions();
        }
      }, [saveCompleteFlag]);


    const saveScreeningQuestions = () => {
 
      const errors = validateScreeningQuestions(screeningQuestions)
      if (!isObjectEmpty(errors)){
        setQuestionErrors({...questionErrors, ...errors})
        toast({
            title: "Please fix errors",
            status: 'error',
            duration: 3000
        })
        return false
      }

      const questionsToSubmit = []
      screeningQuestions.map((question, index) => {
        const q = {
            'question_uuid': question.question_uuid,
            'question_type': question.question_type.code,
            'question_text': question.question_text,

            // clean any unset option values
            'question_options': question.question_options.filter(option => option ? option : false),
            'question_disqualify_filters': question.question_disqualify_filters,
            'question_is_mandatory': question.question_is_mandatory,
            'is_default': question.is_default,
        }
        questionsToSubmit.push(q)
      })
        
      setQuestionErrors({})
      const formData = {
          'job_uuid': job.job_uuid,
          'user_email': getEmailId(),
          'questions': questionsToSubmit
      }
      setIsFormSubmitting(true)
      submitScreeningQuestions(formData)
      .then(res => {
          if (res?.result){
              setQuestionErrors({})
              navigate(`/business/editJobs`, {
                state: {
                  job_uuid: job?.job_uuid,
                },
              });
              toast({
                  title: 'Job details saved successfully.',
                  status: 'success',
                  duration: 3000,
              });
              setIsFormSubmitting(false)
              return
          }
          const errors = questionErrors
          if (res.data[0].error.question_uuid){
              errors[res.data[0].error.question_uuid] = res.message
          }else if (res.data[0].error.question_text){
              errors[res.data[0].error.question_text] = res.message
          }
          setQuestionErrors(errors)
          toast({
            title: 'Unable to submit screening questions', 
            description: res?.message,
            status: 'error',
            duration: 5000
        });
        setIsFormSubmitting(false)
      })
      .catch(err => {
          toast({
              title: 'Unable to submit screening questions', 
              description: err.toString(),
              status: 'error',
              duration: 5000
          });

          setIsFormSubmitting(false)
      })
    }

    return <>
        <JobApplicationForm 
          job={job}
          setScreeningQuestions={setScreeningQuestions} 
          setQuestionErrors={setQuestionErrors} 
          questionErrors={questionErrors} 
        />

        <Flex justify="space-between" mt="14px">
          <Button
            variant="light"
            fontSize="sm"
            borderRadius="16px"
            w={{ base: '128px', md: '148px' }}
            h="46px"
            onClick={() => {
              props.onDataUpdate('benifitsTab');
              dispatch(setJobWizardStatusLevel(4));
            }}
          >
            Prev
          </Button>
          <Box>
          <Button
              variant="outline"
              fontSize="sm"
              borderRadius="16px"
              w={{ base: '160px', md: '200px' }}
              h="46px"
              ms="auto"
              onClick={() => {
                setSaveCompleteFlag(!saveCompleteFlag);
              }}
              isDisabled={isFormSubmitting}
              isLoading={isFormSubmitting}
            >
              Save And Complete Later
            </Button>
            <Button
              variant="darkBrand"
              fontSize="sm"
              borderRadius="16px"
              w={{ base: '128px', md: '148px' }}
              h="46px"
              ml="10px"
              onClick={() => {
                saveScreeningQuestions();
              }}
              isDisabled={isFormSubmitting}
              isLoading={isFormSubmitting}
            >
              Next
            </Button>
          </Box>
        </Flex>
    </>
}