import { useState } from "react";
import { addCandidateExperience } from "../../services/companyServices";
import { isObjectEmpty, toTitleCase } from "../../utils/common";
import { Button, Checkbox, Drawer, DrawerCloseButton, DrawerContent, Flex, Input, Text, Textarea, useToast } from "@chakra-ui/react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

export default function AddExperience (props) {
    const candidate = props?.candidate
    const [addExperienceStartDate, setAddExperienceStartDate] = useState();
    const [addExperienceEndDate, setAddExperienceEndDate] = useState();
    const [addExperiencePresent, setAddExperiencePresent] = useState(false);
    const [isSubmittingExperience, setIsSubmittingExperience] = useState(false);
    const toast = useToast();

    const onSubmitExperience = (e) => {
        setIsSubmittingExperience(true);
        e.preventDefault()
        const form = new FormData(e.target)
        form.set('start_date', form.get('start_date').split('/').reverse().join('-'))
        if (form.get('end_date')){
            form.set('end_date', form.get('end_date').split('/').reverse().join('-'))
        }
        form.set('candidate_uuid', candidate?.candidate_uuid)        
        addCandidateExperience(form)
        .then(res => {
            if (res?.result){
                props.getCandidatePanelData(candidate);
                toast({
                    title: 'Experience added successfully.',
                    status: 'success', 
                    isClosable: true, 
                    duration: 3000
                });
                props.onAddExperiencePanelClose()
            }else{
                toast({
                    title: 'Experience not added.',
                    description: res?.message,
                    status: 'error', 
                    isClosable: true, 
                    duration: 3000
                  });
            }
            setIsSubmittingExperience(false);
        })
        .catch(err => {
            console.log(err)
            setIsSubmittingExperience(false);
            toast({
                title: 'Experience not added.',
                description: err.toString(),
                status: 'error', 
                isClosable: true, 
                duration: 3000
              });
        })
        setIsSubmittingExperience(false);
    }

    if (!candidate || isObjectEmpty(candidate)){
        return null
    }

    return (
        <Drawer size={'lg'} isOpen={props.isAddExperiencePanelOpen} placement="right" onClose={props.onAddExperiencePanelClose}>
            <DrawerContent p={5}>
            <form onSubmit={onSubmitExperience}>
                <Text fontWeight={'bold'} mb={10} fontSize={'18px'}>Add Experience for {candidate?.first_name}</Text>
                
                <Flex alignItems={'center'} mb={8}>
                    <Text as={'b'} width={'150px'} mr={3}>Company Name:</Text>
                    <Input name='company_name' required />
                </Flex>

                <Flex alignItems={'center'} mb={8}>
                    <Text as={'b'} width={'150px'} mr={3}>Job Title:</Text>
                    <Input name='job_title' required />
                </Flex>

                <Flex alignItems={'center'} mb={8}>
                    <Text as={'b'} width={'150px'} mr={3}>Start Date:</Text>
                    <DatePicker
                        autoComplete="off"
                        className='chakra-input css-1jaduyy' required selected={addExperienceStartDate}
                        onChange={(date) => setAddExperienceStartDate(date)}
                        name='start_date' dateFormat="MM/yyyy" showMonthYearPicker
                    />
                </Flex>

                <Flex alignItems={'center'} mb={10} direction={'column'}>
                    <Flex width={'100%'}>
                        <Text as={'b'} width={'150px'} mr={3}>End Date:</Text>
                        <DatePicker
                        autoComplete="off"
                        className='chakra-input css-1jaduyy' selected={addExperienceEndDate}
                        onChange={(date) => {
                            setAddExperienceEndDate(date);
                            setAddExperiencePresent(false);
                        }}
                        name='end_date' dateFormat="MM/yyyy" showMonthYearPicker
                    />
                    </Flex>

                    <Flex width={'50%'} mt={3}>
                        <Text width={'50px'} mr={3}>Present</Text>
                        <Checkbox value={addExperiencePresent} isChecked={addExperiencePresent} name='present' onChange={(e) => {
                            if (e.target.checked){                                    
                                setAddExperienceEndDate(null)                                    
                            }
                            setAddExperiencePresent(!addExperiencePresent);
                        }} />
                    </Flex>
                </Flex>

                <Flex alignItems={'center'} mb={8}>
                    <Text as={'b'} width={'150px'} mr={3}>Job Summary:</Text>
                    <Textarea name='job_summary' rows={8} />
                </Flex>

                <Flex alignItems={'center'} mb={8}>
                    <Text as={'b'} width={'150px'} mr={3}>Job Location:</Text>
                    <Input name='job_location' required />
                </Flex>

                <Flex alignItems={'center'} mb={8}>
                    <Text as={'b'} width={'150px'} mr={3}>Company Website:</Text>
                    <Input name='company_website' />
                </Flex>

                <Flex alignItems={'center'} mb={8}>
                    <Text as={'b'} width={'150px'} mr={3}>Company Logo:</Text>
                    <Input type='file' name={'company_logo'} accept='.png, .jpg, .jpeg, .svg, .webp' />
                </Flex>
                
                <Button mt={5} float={'right'} width={'100px'} variant={'solid'} colorScheme='blue' loadingText={'Adding...'}
                isLoading={isSubmittingExperience} type='submit'>Add</Button>
            </form>
            <DrawerCloseButton />
            </DrawerContent>
        </Drawer>
    )
}