import { Text } from "@chakra-ui/react";
import { isObjectEmpty } from "../../utils/common";


export default function CandidateApplySource(props){
    let jobApplySource = null
    const {candidate} = props;
    const jobApplication = candidate?.job_application
    if (isObjectEmpty(jobApplication)){
        jobApplySource = "Internal Upload"
    }else if (jobApplication?.job_apply_source){
        jobApplySource = jobApplication.job_apply_source
    }

    return jobApplySource ? <Text mr={1}>via <b>{jobApplySource}</b></Text> : null
}