// save session_id in localstorage
const saveSessionId = session_id => {
  localStorage.setItem('session_id', session_id);
};

// get session_id from localstorage
const getSessionId = () => {
  return localStorage.getItem('session_id');
};

// save email id in localstorage
const saveEmailId = emailId => {
  localStorage.setItem('emailId', emailId);
};
// get email id from localstorage
const getEmailId = () => {
  return localStorage.getItem('emailId');
};

// save Auth token in localstorage
const saveAuthToken = authToken => {
  localStorage.setItem('authToken', authToken);
};
// get Auth token from localstorage
const getAuthToken = () => {
  return localStorage.getItem('authToken');
};

// save groupId in localstorage
const savegroupId = groupId => {
  localStorage.setItem('groupId', groupId);
};

// get groupId from localstorage
const getGroupId = () => {
  return localStorage.getItem('groupId');
};

const clearLocalStorage = () => {
  return localStorage.clear();
};

const clearAppSessionData = () => {
  localStorage.clear();
}

// save candidate for interview in localstorage if hiring manage is connecting email through nylas. reload candidate when redirected to interview schedule
const saveCandidateForInterview = candidateForInterview => {
  localStorage.setItem('candidateForInterview', JSON.stringify(candidateForInterview));
};

// get candidate for interview from localstorage
const getCandidateForInterview = () => {
  return JSON.parse(localStorage.getItem('candidateForInterview'));
};

// save candidate for interview in localstorage if hiring manage is connecting email through nylas. reload candidate when redirected to interview schedule
const saveJobUuid = jobUuid => {
  localStorage.setItem('jobUuid', jobUuid);
};

// get job uuid from localstorage
const getJobUuid = () => {
  return localStorage.getItem('jobUuid');
};

// save job in localstorage if hiring manage is connecting email through nylas. reload candidate when redirected to interview schedule
const saveJob = job => {
  localStorage.setItem('job', JSON.stringify(job));
};

// get job uuid from localstorage
const getJob = () => {
  return JSON.parse(localStorage.getItem('job'));
};

// save source page in localstorage if hiring manage is connecting email through nylas. reload candidate when redirected to interview schedule
const saveSourcePage = sourcePage => {
  localStorage.setItem('sourcePage', sourcePage);
};

// get sourcePage uuid from localstorage
const getSourcePage = () => {
  return localStorage.getItem('sourcePage');
};

export {
  saveSessionId,
  getSessionId,
  saveEmailId,
  getEmailId,
  saveAuthToken,
  getAuthToken,
  savegroupId,
  getGroupId,
  clearLocalStorage,
  clearAppSessionData,
  saveCandidateForInterview,
  getCandidateForInterview,
  saveJobUuid,
  getJobUuid,
  saveJob,
  getJob,
  saveSourcePage,
  getSourcePage
};
