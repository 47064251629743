import React, { useEffect, useState } from 'react';
import JobTableList from './components/JobTableList';
import { Flex, Spinner, useToast, Text, Spacer, Button, useDisclosure } from '@chakra-ui/react';
import { getEmailId } from '../../../utils/localStorageIndex';
import { jobTableColumnList } from './components/JobTableColumnList';
import {jobWorkflowStatusCount} from '../../../services/jobTemplateService';
import Pagination from './components/pagination';
import { fetchJobByBusiness } from '../../../services/companyServices';
import { useLocation } from 'react-router-dom';
import BusinessSurvey from '../BusinessSurvey';

function JobList() {
  const toast = useToast();
  const email = getEmailId();
  const { state } = useLocation();
  const business = state.business
  const [loading, isLoading] = useState(true);
  const [tableData, setTableData] = useState([]);
  const [totalRecords, setTotalRecords] = useState(null)
  const [totalJobsCount, setTotalJobsCount] = useState([]);
  const [openJobsCount, setOpenJobsCount] = useState([]);  
  const [slotCount, setSlotCount] = useState([]);  
  const [archCount, setArchCount] = useState(0);  
  const [searchKeyword, setSearchKeyword] = useState('');
  const [searchInputText, setSearchInputText] = useState('');
  const [filterEnabledValue, setFilterEnabledValue] = useState('');
  const recordsPerPage = 100;
  const [noRecordFlag, setNoRecordFlag] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const { isOpen: isSurveyPanelOpen, onOpen: onSurveyPanelOpen, onClose: onSurveyPanelClose } = useDisclosure();  

  useEffect(() => {
    fetchMenuCountList();
    fetchJobWorkflowFlowData();
  }, []);

  useEffect(() => {
    fetchJobWorkflowFlowData();
  }, [currentPage]);

  // fetch count list
  const fetchMenuCountList = () => {
    let objBody = {
      email: email,
    };
    jobWorkflowStatusCount(objBody)
      .then(res => {
        if (res.data[0] && res.result) {
          setOpenJobsCount(res.data[0].open_jobs);
          setTotalJobsCount(res.data[0].total_jobs);
          setSlotCount(res.data[0].total_slots);
        }
      })
      .catch(err => {
        console.log(err);
      });
  };

  const fetchJobWorkflowFlowData = () => {
    let archivedJobs = false;
    isLoading(true);
    let objData = {
      email: email,
      filterEnabled: filterEnabledValue,
      searchString: searchInputText,
      field_name: searchKeyword,
      records_per_page: recordsPerPage,
      page_id: currentPage,
      archived: archivedJobs,
      business_uuid: business.business_uuid
    };
    fetchJobByBusiness(objData)
      .then(res => {
        isLoading(false);
        if (res?.data.length > 0 && res?.result) {
          setTotalRecords(res?.data[0].total_records)
          setNoRecordFlag(false);
          setTableData(res.data[0].records);
          setArchCount(res.data[0].arc_records);          
        } else {
          setNoRecordFlag(false);
          setTableData([]);
        }
      })
      .catch(err => {
        isLoading(false);
        setNoRecordFlag(true);
        toast({
          title: err?.response?.message,
          status: 'error',
          isClosable: true,
          duration: 3000,
        });
      });
  };

  const reRenderTableData = () => {
    fetchJobWorkflowFlowData();
  };

  const refreshJobList = () => {
    fetchMenuCountList();    
    fetchJobWorkflowFlowData();
  };

  return (
    <Flex direction="column" pt={{ sm: '125px', lg: '75px' }}>

        <Flex width={'76%'}>
          <Text as={'b'} fontSize={'26px'} ml={5} mb={5}>{business?.business_name}</Text>
          <Spacer />
          <Button height={8} onClick={refreshJobList} fontWeight={'normal'} variant={'outline'}>Refresh Job List</Button>
          <Button height={8} ml={2} onClick={onSurveyPanelOpen} fontWeight={'normal'} variant={'outline'}>View Business Survey</Button>
        </Flex>

        {loading ? (
          <Flex alignItems="center" justifyContent="center" mt="10" h="50vh">
            <Spinner size="xl" />
          </Flex>
        ) : (
          <>
            <JobTableList
              emailId={email}
              tableData={tableData}
              columnsData={jobTableColumnList}
              onDataReceived={reRenderTableData}
              openJobsCount={openJobsCount}
              totalJobsCount={totalJobsCount}
              totalSlotCount={slotCount}
              archivedCount={archCount}
              business={business}
            />

            <Pagination
            totalRows={totalRecords}
            pageChangeHandler={setCurrentPage}
            page={currentPage}
            rowsPerPage={recordsPerPage}
          />
          </>
        )}

        <BusinessSurvey 
          business={business}
          onSurveyPanelOpen={onSurveyPanelOpen}
          onSurveyPanelClose={onSurveyPanelClose}
          isSurveyPanelOpen={isSurveyPanelOpen}
        />
    </Flex>
  );
}

export default JobList;
