import React, { useState } from 'react';
import {  useToast, Tooltip, IconButton } from '@chakra-ui/react';
import { getEmailId } from '../../../../utils/localStorageIndex';
import { BiSolidUserDetail } from 'react-icons/bi';
import { enrichCandidateProfile } from '../../../../services/jobTemplateService';
import { isObjectEmpty } from '../../../../utils/common';


export default function EnrichCandidate(props) {
  const {adminSettings, bulkSelectedCandidates, postEnrichActions} = props;
  let candidate = {}
  if (bulkSelectedCandidates?.length > 0){
    candidate = bulkSelectedCandidates[0]
  }
  const toast = useToast()
  const [isEnrichingCandidate, setIsEnrichingCandidate] = useState({loading: false, candidate_uuid: null});
  
  const onEnrichCandidate = () => {

    let objBody = {
      email: getEmailId(),
      candidate_uuid: candidate.candidate_uuid,
      update_stage: 'start'
    };

    setIsEnrichingCandidate({loading: true, candidate_uuid: candidate.candidate_uuid})
    enrichCandidateProfile(objBody)
      .then(res => {
        if (res?.result) {
          toast({
            title: res.message,
            status: 'success',
            isClosable: true,
            duration: 8000,
          });
          // call any post enrich functions
          for (const [fnName, fn] of Object.entries(postEnrichActions)) {
            fn()
          }
        } else {
          toast({
            title: res?.message,
            status: 'error',
            isClosable: true,
            duration: 8000,
          });
        }
        setIsEnrichingCandidate({loading: false, candidate_uuid: candidate.candidate_uuid})
      })
      .catch(err => {
        toast({
          title: err.message,
          status: 'error',
          isClosable: true,
          duration: 5000,
        });
        setIsEnrichingCandidate({loading: false, candidate_uuid: candidate.candidate_uuid})
      });
  };

  const EnrichButtonLabel = () => {
    if (isEnrichingCandidate.loading && isEnrichingCandidate.candidate_uuid == candidate.candidate_uuid){
      return 'Enriching Candidate...'
    }
      
    return "Enrich Candidate. Replaces existing data for the selected candidate based on the candidate's Resume (if available) and People Data Lab (if available)"
  }

  const getEnrichButtonLoading = () => {
    return isEnrichingCandidate.loading && isEnrichingCandidate.candidate_uuid == candidate.candidate_uuid
  }

  return (
    <>
    {adminSettings &&
      <Tooltip label={<EnrichButtonLabel />} fontSize='md'>
          <span>
            <IconButton isDisabled={bulkSelectedCandidates.length > 1 || isObjectEmpty(candidate)} fontSize={'22px'} bg={'none'} _hover={{'bg': 'none'}} icon={<BiSolidUserDetail />} color={'gray'} 
              onClick={onEnrichCandidate} isLoading={getEnrichButtonLoading()}
            />
          </span>
      </Tooltip>
    } 
    </>
  );
}
