import React, { useState } from 'react';
import { CgAsterisk } from 'react-icons/cg';
import { useNavigate } from 'react-router-dom';
import { getEmailId } from '../../../../utils/localStorageIndex';
import { createRecruiter } from '../../../../services/sharedServices';
import {
  defaultThemeColor,
  errorColor,
  themeFontSizeSmallHeading,
  themeFontSizeMediumHeading,
} from '../../../../constants/index';
import {
  Box,
  Grid,
  Text,
  Flex,
  Input,
  Button,
  GridItem,
  useToast,
} from '@chakra-ui/react';

function AddRecruiter() {
  const toast = useToast();
  const navigate = useNavigate();
  const [error, setError] = useState({});
  const [loading, isLoading] = useState(false);
  const [recruiterForm, setRecruiterForm] = useState({
    email: getEmailId(),
    first_name: '',
    last_name: '',
    recruiter_email: '',
  });
  const [retype_recruitermail, setRetype_recruitermail] = useState('');
  const [retype_recruitermailErr, setRetype_recruitermailErr] = useState('');

  const onUpdateField = e => {
    const nextFormState = {
      ...recruiterForm,
      [e.target.name]: e.target.value,
    };
    setRecruiterForm(nextFormState);
  };

  const validateFieldForm = () => {
    let fields = recruiterForm;
    let errors = {};
    let formIsValid = true;
    let emailPattern = new RegExp(
      /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/
    );
    if (!fields['first_name']) {
      formIsValid = false;
      errors['first_name'] = '*First Name is required.';
    }
    if (!fields['last_name']) {
      formIsValid = false;
      errors['last_name'] = '*Last Name is required.';
    }
    if (!fields['recruiter_email']) {
      formIsValid = false;
      errors['recruiter_email'] = '*Recruiter Email is required.';
    } else if (!emailPattern.test(fields['recruiter_email'])) {
      formIsValid = false;
      errors['recruiter_email'] = '*Please enter valid email address.';
    }
    if (!retype_recruitermail) {
      formIsValid = false;
      setRetype_recruitermailErr('*Email Cannot Be Empty.');
    } else if (fields['recruiter_email'] !== retype_recruitermail) {
      formIsValid = false;
      setRetype_recruitermailErr("*Email didn't match.");
    }
    setError(errors);
    return formIsValid;
  };

  const submitRecruiterData = event => {
    event.preventDefault();
    if (validateFieldForm()) {
      isLoading(true);
      createRecruiter(recruiterForm)
        .then(res => {
          isLoading(false);
          if (res.result) {
            toast({
              title: res.message,
              status: 'success',
              isClosable: true,
              duration: 3000,
            });
            navigate('/admin/recruiter/AllRecruiters');
          } else {
            isLoading(false);
            toast({
              title: res.message,
              status: 'error',
              isClosable: true,
              duration: 3000,
            });
          }
          setRetype_recruitermailErr('');
        })
        .catch(err => {
          isLoading(false);
          if (err)
            toast({
              title: err?.response?.data?.detail,
              status: 'error',
              isClosable: true,
              duration: 3000,
            });
        });
    }
  };

  return (
    <Grid templateColumns="repeat(3, 1fr)" gap={5} p="3">
      <GridItem colSpan={1}>
        <Text fontSize={themeFontSizeMediumHeading} mb="10px" color="#d7d7d7">
          ADD NEW RECRUITER
        </Text>
        <Text color="#d7d7d7">Lorem Ipsum is simply dummy text.</Text>
      </GridItem>
      <GridItem colStart={2} colEnd={4} color="#fff">
        <Box>
          <Box mb="15px">
            <Flex>
              <Text mb="8px">First Name</Text>
              <CgAsterisk color={errorColor} />
            </Flex>
            <Input
              placeholder="Enter First Name"
              size="sm"
              borderRadius="0px"
              fontSize={themeFontSizeMediumHeading}
              borderColor="#494552"
              bg="#1c181e"
              value={recruiterForm.first_name}
              name="first_name"
              onChange={onUpdateField}
              _placeholder={{ color: 'white' }}
            />
            <Text fontSize="sm" color={errorColor} mt="1">
              {error?.first_name}
            </Text>
          </Box>
          <Box mb="15px">
            <Flex>
              <Text mb="8px">Last Name</Text>
              <CgAsterisk color={errorColor} />
            </Flex>
            <Input
              placeholder="Enter Last Name"
              size="sm"
              borderRadius="0px"
              fontSize={themeFontSizeMediumHeading}
              borderColor="#494552"
              bg="#1c181e"
              value={recruiterForm.last_name}
              name="last_name"
              onChange={onUpdateField}
              _placeholder={{ color: 'white' }}
            />
            <Text fontSize="sm" color={errorColor} mt="1">
              {error?.last_name}
            </Text>
          </Box>
          <Box mb="15px">
            <Flex>
              <Text mb="8px">Recruiter Email</Text>
              <CgAsterisk color={errorColor} />
            </Flex>
            <Input
              placeholder="Enter Recruiter Email Id"
              size="sm"
              borderRadius="0px"
              fontSize={themeFontSizeMediumHeading}
              borderColor="#494552"
              bg="#1c181e"
              value={recruiterForm.recruiter_email}
              name="recruiter_email"
              onChange={onUpdateField}
              _placeholder={{ color: 'white' }}
            />
            <Text fontSize="sm" color={errorColor} mt="1">
              {error?.recruiter_email}
            </Text>
          </Box>
          <Box mb="15px">
            <Flex>
              <Text mb="8px">Verify Email</Text>
              <CgAsterisk color={errorColor} />
            </Flex>
            <Input
              placeholder="Re-Enter Email Id"
              size="sm"
              borderRadius="0px"
              fontSize={themeFontSizeMediumHeading}
              borderColor="#494552"
              bg="#1c181e"
              value={retype_recruitermail}
              name="retype_recruitermail"
              onChange={e => {
                setRetype_recruitermail(e.target.value);
              }}
              _placeholder={{ color: 'white' }}
            />
            <Text fontSize="sm" color={errorColor} mt="1">
              {retype_recruitermailErr}
            </Text>
          </Box>

          <Button
            size="sm"
            variant="outline"
            fontWeight="normal"
            fontSize={themeFontSizeSmallHeading}
            borderColor="#494552"
            onClick={e => {
              submitRecruiterData(e);
            }}
            type="button"
            _hover={{ bg: defaultThemeColor }}
            isDisabled={loading ? true : false}
            isLoading={loading ? true : false}
          >
            Submit
          </Button>
        </Box>
      </GridItem>
    </Grid>
  );
}

export default AddRecruiter;
