// Chakra imports
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { RiEyeCloseLine } from 'react-icons/ri';
import { MdOutlineRemoveRedEye } from 'react-icons/md';
import Card from '../../../../../components/card/Card.js';
import InputField from '../../../../../components/fields/InputField';
import { updatePassword } from '../../../../../services/sharedServices';
import {
  getEmailId,
  clearLocalStorage,
} from '../../../../../utils/localStorageIndex';
import {
  Icon,
  Flex,
  Text,
  Input,
  Button,
  useToast,
  FormLabel,
  InputGroup,
  FormControl,
  InputRightElement,
  useColorModeValue,
} from '@chakra-ui/react';

export default function Settings() {
  // Chakra Color Mode
  const textColorPrimary = useColorModeValue('secondaryGray.900', 'white');
  const textColorSecondary = 'secondaryGray.600';
  const astrikStar = useColorModeValue('red.500', 'red.400');
  const toast = useToast();
  const emailId = getEmailId();
  const navigate = useNavigate();
  const [error, setError] = useState({});
  const [loading, isLoading] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
  const [showRetypePassword, setShowRetypePassword] = useState(false);
  const [changePasswordObj, setChangePasswordObj] = useState({
    current_password: '',
    new_password: '',
  });
  const [retype_password, setRetypePassword] = useState('');
  const [retypePasswordErrMsg, setRetypePasswordErrMsg] = useState('');

  const onUpdateField = e => {
    const nextFormState = {
      ...changePasswordObj,
      [e.target.name]: e.target.value,
    };
    setChangePasswordObj(nextFormState);
  };

  const validateFieldForm = () => {
    let fields = changePasswordObj;
    let errors = {};
    let formIsValid = true;
    const checkWhiteSpace = /^\S*$/;
    const checkLoerCase = /^(?=.*[a-z]).*$/;
    const checkUpperCase = /^(?=.*[A-Z]).*$/;
    const checkNumber = /.*[0-9].*/;
    const isValidLength = /^.{8,}$/;

    if (!fields['current_password']) {
      formIsValid = false;
      errors['current_password'] = '*Current Password Is Reqired.';
    }
    if (!fields['new_password']) {
      formIsValid = false;
      errors['new_password'] = '*New Password Cannot Be Empty.';
    } else if (!checkWhiteSpace.test(fields['new_password'])) {
      formIsValid = false;
      errors['new_password'] = '*Password must not contain Whitespaces';
    } else if (!checkLoerCase.test(fields['new_password'])) {
      formIsValid = false;
      errors['new_password'] =
        '*Password must have at least one Lowercase Character';
    } else if (!checkUpperCase.test(fields['new_password'])) {
      formIsValid = false;
      errors['new_password'] =
        '*Password must have at least one Uppercase Character';
    } else if (!checkNumber.test(fields['new_password'])) {
      formIsValid = false;
      errors['new_password'] = '*Password must have at least one Number';
    } else if (!isValidLength.test(fields['new_password'])) {
      formIsValid = false;
      errors['new_password'] = '*Password must be minimum 8 characters long';
    }

    if (!retype_password) {
      formIsValid = false;
      setRetypePasswordErrMsg('*Retype Password Cannot Be Empty.');
    } else if (fields['new_password'] !== retype_password) {
      formIsValid = false;
      setRetypePasswordErrMsg("*New Password didn't match.");
    }
    setError(errors);
    return formIsValid;
  };

  const submitRecruiterData = event => {
    event.preventDefault();
    if (validateFieldForm()) {
      isLoading(true);
      changePasswordObj['email'] = emailId;
      setRetypePasswordErrMsg('');
      updatePassword(changePasswordObj)
        .then(res => {
          isLoading(false);
          if (res.result) {
            toast({
              title: res?.message,
              status: 'success',
              isClosable: true,
              duration: 3000,
            });
            navigate('/login');
            clearLocalStorage();
          } else {
            toast({
              title: res?.data[0]?.error,
              status: 'error',
              isClosable: true,
              duration: 3000,
            });
          }
        })
        .catch(err => {
          isLoading(false);
          if (err)
            toast({
              title: err?.response?.data?.detail,
              status: 'error',
              isClosable: true,
              duration: 3000,
            });
        });
    }
  };
  return (
    <FormControl>
      <Card>
        <Flex direction="column" mb="40px" ms="10px">
          <Text fontSize="xl" color={textColorPrimary} fontWeight="bold">
            Change password
          </Text>
          <Text fontSize="md" color={textColorSecondary}>
            Here you can set your new password
          </Text>
        </Flex>
        <FormControl>
          <Flex flexDirection="column">
            <FormLabel
              display="flex"
              ms="4px"
              fontSize="sm"
              fontWeight="500"
              color={textColorPrimary}
              mb="8px"
            >
              Old Password<Text color={astrikStar}>*</Text>
            </FormLabel>

            <InputGroup>
              <Input
                mb="0"
                id="old"
                label="Old Password"
                placeholder="@john123"
                name="current_password"
                value={changePasswordObj.current_password}
                onChange={onUpdateField}
                type={showCurrentPassword ? 'text' : 'password'}
                borderRadius="14px"
              />
              <InputRightElement display="flex" alignItems="center" mt="4px">
                <Icon
                  color={textColorSecondary}
                  _hover={{ cursor: 'pointer' }}
                  as={
                    showCurrentPassword ? RiEyeCloseLine : MdOutlineRemoveRedEye
                  }
                  onClick={() => {
                    setShowCurrentPassword(!showCurrentPassword);
                  }}
                />
              </InputRightElement>
            </InputGroup>

            <Text fontSize="sm" color="red" mt="1" mb="5">
              {error?.current_password}
            </Text>

            <FormLabel
              display="flex"
              ms="4px"
              fontSize="sm"
              fontWeight="500"
              color={textColorPrimary}
              mb="8px"
              type={showNewPassword ? 'text' : 'password'}
              borderRadius="14px"
            >
              New Password<Text color={astrikStar}>*</Text>
            </FormLabel>

            <InputGroup>
              <Input
                isRequired={true}
                mb="0"
                label="New Password"
                placeholder="@john123"
                value={changePasswordObj.new_password}
                name="new_password"
                onChange={onUpdateField}
                borderRadius="14px"
                type={showNewPassword ? 'text' : 'password'}
              />
              <InputRightElement display="flex" alignItems="center" mt="4px">
                <Icon
                  color={textColorSecondary}
                  _hover={{ cursor: 'pointer' }}
                  as={showNewPassword ? RiEyeCloseLine : MdOutlineRemoveRedEye}
                  onClick={() => {
                    setShowNewPassword(!showNewPassword);
                  }}
                />
              </InputRightElement>
            </InputGroup>
            <Text fontSize="sm" color={'red'} mt="1" mb="5">
              {error?.new_password}
            </Text>

            <FormLabel
              display="flex"
              ms="4px"
              fontSize="sm"
              fontWeight="500"
              color={textColorPrimary}
              mb="8px"
              type={showNewPassword ? 'text' : 'password'}
              borderRadius="14px"
            >
              New Password<Text color={astrikStar}>*</Text>
            </FormLabel>

            <InputGroup>
              <Input
                isRequired={true}
                mb="0"
                label="New Password"
                placeholder="@john123"
                value={retype_password}
                name="retype_password"
                onChange={e => {
                  setRetypePassword(e.target.value);
                }}
                borderRadius="14px"
                type={showRetypePassword ? 'text' : 'password'}
              />
              <InputRightElement display="flex" alignItems="center" mt="4px">
                <Icon
                  color={textColorSecondary}
                  _hover={{ cursor: 'pointer' }}
                  as={
                    showRetypePassword ? RiEyeCloseLine : MdOutlineRemoveRedEye
                  }
                  onClick={() => {
                    setShowRetypePassword(!showRetypePassword);
                  }}
                />
              </InputRightElement>
            </InputGroup>

            <Text fontSize="sm" color={'red'} mt="1" mb="5">
              {retypePasswordErrMsg}
            </Text>
          </Flex>
        </FormControl>
        <Button
          variant="brand"
          minW="183px"
          fontSize="sm"
          fontWeight="500"
          ms="auto"
          onClick={e => {
            submitRecruiterData(e);
          }}
          isDisabled={loading ? true : false}
          isLoading={loading ? true : false}
        >
          Change Password
        </Button>
      </Card>
    </FormControl>
  );
}
