import { Button, Box, Flex, Image, Text, Heading } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { themeColor } from './index';
import logo from '../media/images/ts-logo-200x80.png';

function Header() {
  const navigate = useNavigate();

  return (
    <>
      <Box>
        <Flex
          justifyContent="space-between"
          alignItems="center"
          p="3"
          bg="#1b1a21"
          color="#fff"
        >
          <Box>
            <Image
              w="60%"
              onClick={() => {
                navigate('/');
              }}
              cursor="pointer"
              src={logo}
            />
          </Box>
          <Box>
            <Flex>
              <Text mr="5">Product</Text>
              <Text mr="5">Solutions</Text>
              <Text mr="5">Docs</Text>
              <Text mr="5">Resources</Text>
              <Text mr="5">Community</Text>
              <Text>Pricing</Text>
            </Flex>
          </Box>
          <Box>
            <Button
              color={'grey.100'}
              variant="outline"
              borderRadius="5px"
              borderColor={'grey.200'}
              fontSize="12px"
              size="sm"
              mt="1px"
              mr={'1px'}
              _hover={{ color: 'grey', bg: 'white' }}
              onClick={() => {
                navigate('/login');
              }}
            >
              Login
            </Button>
            <Button
              mx={2}
              color={'grey.100'}
              variant="outline"
              borderRadius="5px"
              borderColor={'grey.200'}
              fontSize="12px"
              size="sm"
              mt="1px"
              mr={'1px'}
              _hover={{ color: 'grey', bg: 'white' }}
              onClick={() => {
                navigate('/business/RegisterUser');
              }}
            >
              Register
            </Button>
            <Button
              mx={2}
              color={'grey.100'}
              variant="outline"
              borderRadius="5px"
              borderColor={'grey.200'}
              fontSize="12px"
              size="sm"
              mt="1px"
              mr={'1px'}
              _hover={{ color: 'grey', bg: 'white' }}
              onClick={() => {
                navigate('/scheduleDemo');
              }}
            >
              Schedule A Demo
            </Button>
          </Box>
        </Flex>
        <Box p="3" bg="#1b1a21" py="20">
          <Box textAlign="center" color="#d7d7d7">
            <Heading>
              The{' '}
              <Text as="span" color={themeColor}>
                Smarter
              </Text>{' '}
              Way To Source Talent
            </Heading>
            <Text mt="10">
              Stop Paying 20% Placement Fees to Third Party Agencies. Get
              qualified <br />
              "Ready To Interview" Candidates sent to your Hiring Managers now!
            </Text>
          </Box>
        </Box>
        <Box className="image-container ribbon-component">
          <Box className="movable-image" />
        </Box>
      </Box>
    </>
  );
}

export default Header;
