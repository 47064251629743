import React, { useEffect, useState } from 'react';
import {
  Box,
  Text,
  Button,
  SimpleGrid,
  Flex,
  HStack,
  Icon,
  Spinner,
  Badge,
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  CloseButton,
  IconButton,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  Grid,
  useColorModeValue,
} from '@chakra-ui/react';
import { getEmailId, getGroupId } from '../../../../utils/localStorageIndex';
import { fetchTsTeamListPaginated } from '../../../../services/businessServices';
import Card from '../../../../components/card/Card';
import SearchTableUsers from './components/SearchTableUsersOverivew';
import { columnsDataUsersOverview } from './components/columnsDataUsersOverview';
// import tableDataUsersOverview from "./variables/tableDataUsersOverview.json";

function AdminTeam() {
  const [loading, isLoading] = useState(true);
  const [teamListing, setTeamListing] = useState([]);
  const [noRecordFlag, setNoRecordFlag] = useState(true);
  const [errorMessage, setErrorMessage] = useState('Listing is empty..');

  const brandColor = useColorModeValue('brand.500', 'white');
  const bgBadge = useColorModeValue('brand.50', 'whiteAlpha.100');
  const bgContrast = useColorModeValue('secondaryGray.300', 'navy.900');

  useEffect(() => {
    let requestBody = {
      email: getEmailId(),
      records_per_page: 500,
      page_id: 0,
      records_length: 0,
    };
    fetchTsTeamListPaginated(requestBody)
      .then(res => {
        isLoading(false);
        if (res.data[0].total_records > 0) {
          setTeamListing(res.data[0]?.records);
          setNoRecordFlag(false);
        } else {
          setTeamListing([]);
          setNoRecordFlag(true);
        }
      })
      .catch(err => {
        isLoading(false);
        setNoRecordFlag(true);
      });
  }, []);

  function renderTeamListingDataCaller() {
    if (teamListing && teamListing.length > 0) {
      var hrUsersResult = teamListing.map((data, index) => (
        <Box bg="#1c181e" m="1" p="10px" borderLeftWidth="5px">
          <Text mb="5px" fontSize={16}>
            {' '}
            {data.first_name ? data.first_name : '-'}
            <Text as="span" pl="10px">
              {data.last_name ? data.last_name : '-'}
            </Text>
          </Text>
          <Text mb="5px"> {data.email ? data.email : '-'}</Text>
          {data.type != null ? (
            <Badge mb="5px" p={1}>
              {' '}
              {data.type}
            </Badge>
          ) : null}
          {data.is_enable ? (
            <Badge
              p={2}
              colorScheme="horizonPurple"
              rounded={'lg'}
              bg={'green'}
              color={brandColor}
              textTransform={'none'}
              letterSpacing="0px"
              px="4px"
              ms="6px"
            >
              Enabled
            </Badge>
          ) : (
            <Badge
              p={2}
              colorScheme="horizonPurple"
              rounded={'lg'}
              bg={'red.500'}
              color={brandColor}
              textTransform={'none'}
              letterSpacing="0px"
              px="4px"
              ms="6px"
            >
              Disabled
            </Badge>
          )}
        </Box>
      ));
      return hrUsersResult;
    }
  }

  return (
    <Flex direction="column" pt={{ sm: '125px', lg: '75px' }}>
      <Card px="0px">
        {loading ? (
          <Flex alignItems="center" justifyContent="center" mt="10" h="50vh">
            <Spinner size="xl" />
          </Flex>
        ) : teamListing && teamListing.length > 0 ? (
          <SearchTableUsers
            tableData={teamListing}
            columnsData={columnsDataUsersOverview}
          />
        ) : (
          <Alert borderRadius="8px" status="error" variant="subtle">
            <Flex>
              <AlertIcon />
              <Flex direction="column">
                <AlertTitle mr="12px">
                  {noRecordFlag ? 'Empty Team List' : 'Unauthorized access..'}
                </AlertTitle>
                <AlertDescription>There are no team members!!</AlertDescription>
              </Flex>
            </Flex>
          </Alert>
        )}
      </Card>
    </Flex>
  );
}

export default AdminTeam;
