import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {Box,Drawer,DrawerContent,Text,Flex, Spinner,Icon,Button,
  useColorModeValue,useDisclosure, Tooltip,useToast, Checkbox, IconButton, Select, Tabs, TabList, Tab, 
  TabPanels, TabPanel, Input, Spacer, MenuButton, MenuList, MenuItem, Menu, DrawerHeader, DrawerCloseButton
} from '@chakra-ui/react';
import { ChevronDownIcon } from '@chakra-ui/icons'
import { getEmailId } from '../../../utils/localStorageIndex';
import {candidateKanbanStageUpdate, jobAdminCollectFullUploadedList, jobWorkflowAdmSelectCandidate, jobWorkflowAdmSourceCandidate,} from '../../../services/jobTemplateService.js';
import { isObjectEmpty, toTitleCase, workflowStagesMapping } from '../../../utils/common.js';
import moment from 'moment';
import { BiArrowBack, BiCheck, BiPencil } from 'react-icons/bi';
import { MdArrowDropDown, MdMessage } from 'react-icons/md';
import { RiBuilding4Fill, RiGraduationCapFill } from 'react-icons/ri';
import CandidateProfile from './components/CandidateProfile.js';
import CandidateFeedback from './components/CandidateFeedback.js';
import DenyCandidateSearch from '../../business/jobWizard/jobWorkflow/Workflow/CandidateSearch/RejectCandidate.js';
import AddExperience from '../../common/AddExperience.js';
import AddEducation from '../../common/AddEducation.js';
import AddCandidateByResume from './components/AddCandidateByResume.js';
import AddCandidateByForm from './components/AddCandidateByForm.js';
import DisqualifyCandidate from './components/DisqualifyCandidate.js';
import EnrichCandidate from './components/EnrichCandidate.js';
import UploadResumeForCandidate from './components/UploadResumeForCandidate.js';
import CandidateHeadlines from './components/CandidateHeadlines.js';
import DeleteCandidate from './components/DeleteCandidate.js';
import CloseJob from './components/CloseJob.js';
import AssignCandidateToJob from './components/AssignCandidateToJob.js';
import CandidateApplySource from '../../common/CandidateApplySource.js';
import PublishJob from './components/PublishJob.js';
import CandidateScreeningQuestions from './components/CandidateScreeningQuestions.js';
import CandidateHeadshot from '../../common/CandidateHeadshot.js';
import SendEmail from './components/SendEmail.js';
import UploadHeadshotForCandidate from './components/UploadHeadshotForCandidate.js';
import RejectCandidate from '../../business/jobWizard/jobWorkflow/Workflow/CandidateSearch/RejectCandidate.js';
import QualifyCandidate from './components/QualifyCandidate.js';
import CandidateTimeline from './components/CandidateTimeline.js';


function JobCandidateList() {
  const emailId = getEmailId();
  const navigate = useNavigate();
  const { state } = useLocation();
  const adminSettings = state?.adminSettings ? state?.adminSettings : false;
  const toast = useToast();
  const [filteredCandidatesLoading, setFilteredCandidatesLoading] = useState(false);
  const [allCandidates, setAllCandidates] = useState([]);
  const [selectedTabCandidates, setSelectedTabCandidates] = useState([]);
  const [selectedQualifiedTabCandidates, setSelectedQualifiedTabCandidates] = useState([]);
  const [renderedCandidates, setRenderedCandidates] = useState([])
  const [selectedJob, setSelectedJob] = useState({})
  const selectedBusiness = state?.business
  const [selectedCandidate, setSelectedCandidate] = useState({});
  const [bulkSelectedCandidates, setBulkSelectedCandidates] = useState([])
  const [isMovingCandidateStage, setIsMovingCandidateStage] = useState(false);
  const { isOpen: isFeedbackPanelOpen, onOpen: onFeedbackPanelOpen, onClose: onFeedbackPanelClose } = useDisclosure();
  const { isOpen: isRejectionPanelOpen, onOpen: onRejectionPanelOpen, onClose: onRejectionPanelClose } = useDisclosure();
  const { isOpen: isAddExperiencePanelOpen, onOpen: onAddExperiencePanelOpen, onClose: onAddExperiencePanelClose } = useDisclosure();
  const { isOpen: isAddEducationPanelOpen, onOpen: onAddEducationPanelOpen, onClose: onAddEducationPanelClose } = useDisclosure();
  const { isOpen: isAddCandidateByResumePanelOpen, onOpen: onAddCandidateByResumePanelOpen, onClose: onAddCandidateByResumePanelClose } = useDisclosure();
  const { isOpen: isAddCandidateByFormPanelOpen, onOpen: onAddCandidateByFormPanelOpen, onClose: onAddCandidateByFormPanelClose } = useDisclosure();  
  const { isOpen: isCloseJobPanelOpen, onOpen: onCloseJobPanelOpen, onClose: onCloseJobPanelClose } = useDisclosure();
  const { isOpen: isPublishJobPanelOpen, onOpen: onPublishJobPanelOpen, onClose: onPublishJobPanelClose } = useDisclosure();  
  
  const [searchQuery, setSearchQuery] = useState(null);  
  let jobTabs = {
      'candidate_screening': 'Screening',
      'ready_to_interview': 'Ready To Interview',
      'scheduled_for_interview': 'Interviewing',
      'candidate_hired': 'Offered',
      'candidate_rejected': 'Rejected'
    }
  
  if (adminSettings){
    jobTabs = {...{'all': 'All', 'candidate_sourced': 'Sourcing'}, ...jobTabs}
  }

  const [candidateTargetStages, setCandidateTargetStages] = useState(workflowStagesMapping)
  const [selectedTab, setSelectedTab] = useState('candidate_screening')
  const [selectedQualifiedTab, setSelectedQualifiedTab] = useState('qualified')
  const [isEditCandidate, setIsEditCandidate] = useState(false)
  const schemeTextColor = useColorModeValue('secondaryGray.900', 'gray.400');
  const borderColorStyle = useColorModeValue('gray.200', 'gray.400'); // {light, dark}
  
  useEffect(() => {
    if (state?.job){
      setSelectedJob({...state?.job})
    }
  }, [state])

  useEffect(() => {
     if (!isObjectEmpty(selectedJob)){
        getAllCandidatesForJob()
     }
  }, [selectedJob])

  useEffect(() => setSearchQuery(""), [selectedTab, selectedQualifiedTab])

  useEffect(() => {
    setBulkSelectedCandidates([])
    filterCandidatesForSelectedTab();
  }, [selectedTab])

  useEffect(() => {
    filterCandidatesForSelectedTab();
  }, [allCandidates])

  useEffect(() => {
    setBulkSelectedCandidates([])
    filterCandidatesForSelectedQualifiedTab();
  }, [selectedQualifiedTab, selectedTabCandidates])

  useEffect(() => {
    if (state?.candidate && allCandidates?.length > 0){      
      const candidateFromState = getRefreshedCandidateByUuid(state?.candidate?.candidate_uuid)
      setSelectedCandidate({...candidateFromState})
      
      // set selected tab based on candidate current workflow position.
      // this comes handy when we get a notification and open that notification later
      // after the candidate could have been moved to some other stage      
      setSelectedTab(candidateFromState?.workflow?.workflow_position)

      // delete candidate from state to avoid setting same candidate on reload or tab switching
      delete state['candidate']
      window.history.replaceState(state, window.title, [window.location.pathname])
      return
    }

    // update happens to a selected candidate. stay on same candidate
    if (renderedCandidates?.length > 0 && isSelectedCandidateInSelectedQualifiedTab()){
      setSelectedCandidate({...getRefreshedSelectedCandidate()})
      return
    }
    
    // candidate moved from one tab to other. stay on same tab but set candidate at index 0 to selected candidate
    if (renderedCandidates?.length > 0 && !isSelectedCandidateInSelectedQualifiedTab()){
      setSelectedCandidate({...renderedCandidates[0]})  
    }
    // tab switched or page refreshed. set candidate at index 0 to selected candidate
    else if (renderedCandidates?.length > 0){
      setSelectedCandidate({...renderedCandidates[0]})
    }
    
    else{

      setSelectedCandidate({})
    }

  }, [renderedCandidates])

  useEffect( () => {
    filterCandidatesBySearchQuery();
  }, [searchQuery])

  const getRefreshedSelectedCandidate = () => {
    let updatedSelectedCandidate = {}
    selectedTabCandidates.map(candidate => {
      if (candidate.candidate_uuid == selectedCandidate.candidate_uuid){
        updatedSelectedCandidate = candidate
      }
    })
    return updatedSelectedCandidate
  }

  const getRefreshedCandidateByUuid = (candidateUuid) => {
    let updatedSelectedCandidate = {}
    allCandidates.map(candidate => {
      if (candidate.candidate_uuid == candidateUuid){
        updatedSelectedCandidate = candidate
      }
    })
    return updatedSelectedCandidate
  }

  const isSelectedCandidateInSelectedQualifiedTab = () => {
    const refreshedCandidate = getRefreshedSelectedCandidate()
    if (!isObjectEmpty(refreshedCandidate) && selectedQualifiedTab == 'qualified' && (!refreshedCandidate?.workflow || !refreshedCandidate?.workflow?.is_disqualified)){
      return true
    }

    if (!isObjectEmpty(refreshedCandidate) && selectedQualifiedTab == 'disqualified' && refreshedCandidate?.workflow && refreshedCandidate?.workflow?.is_disqualified){
      return true
    }

    return false
  }

  const getAllCandidatesForJob = () => {
    setFilteredCandidatesLoading(true);
    const objBody = {
      email: emailId,
      job_uuid: selectedJob.job_uuid,
    };
    jobAdminCollectFullUploadedList(objBody)
      .then(res => {        
        if (res?.result) {
          setAllCandidates([...res?.data[0]]);
        } else {
          toast({
            title: 'Unable to load candidates for this job',
            description: res?.message,
            status: 'error',
            duration: 5000,
          });
        }
        setFilteredCandidatesLoading(false);
      })
      .catch(err => {
        setFilteredCandidatesLoading(false);
        toast({
          title: 'Unable to load candidates for this job',
          description: err.toString(),
          status: 'error',
          duration: 5000,
        });
      });
  };

  const filterCandidatesForSelectedTab = () => {
    if (selectedTab == 'all'){
        setSelectedTabCandidates([...allCandidates])
        return
    }

    const filteredCandidates = []
    allCandidates.map(candidate => {
      if (candidate?.workflow_position?.toLowerCase() == selectedTab){
        filteredCandidates.push(candidate)
      }
    })

    setSelectedTabCandidates([...filteredCandidates])
  }

  const filterCandidatesForSelectedQualifiedTab = () => {
    const filteredCandidates = []
    if (selectedQualifiedTab == 'qualified'){        
        selectedTabCandidates.map(candidate => {
            if (!candidate?.workflow || !candidate?.workflow?.is_disqualified){
              filteredCandidates.push(candidate)
            }
        })
    }

    if (selectedQualifiedTab == 'disqualified'){        
      selectedTabCandidates.map(candidate => {
          if (candidate?.workflow && candidate?.workflow?.is_disqualified){
            filteredCandidates.push(candidate)
          }
      })
    }

    setSelectedQualifiedTabCandidates([...filteredCandidates])
    setRenderedCandidates(filteredCandidates)
  }

  const getCandidatesCountInJobTab = (tabName) => {
    let count = 0

    if (tabName == 'all'){
        return allCandidates.length > 0 ? allCandidates.length : "-"
    }

    allCandidates.map(candidate => {
      if (candidate.workflow_position == tabName){
        count += 1
      }
    })

    return count > 0 ? count : '-'
  }

  const getCandidatesCountInQualifiedTab = (tabName) => {
    if (tabName == 'qualified' && selectedQualifiedTab == tabName){        
      return renderedCandidates.filter(candidate => (!candidate?.workflow || !candidate?.workflow?.is_disqualified)).length
    }else if (tabName == 'qualified'){        
      return selectedTabCandidates.filter(candidate => (!candidate?.workflow || !candidate?.workflow?.is_disqualified)).length
    }

    if (tabName == 'disqualified' && selectedQualifiedTab == tabName){        
      return renderedCandidates.filter(candidate => (candidate?.workflow && candidate?.workflow?.is_disqualified)).length
    }else if (tabName == 'disqualified'){        
      return selectedTabCandidates.filter(candidate => (candidate?.workflow && candidate?.workflow?.is_disqualified)).length
    }
  }

  const getTabTitleFromTabName = (name) => {
    let title = null
    Object.entries(jobTabs).map(([tabName, tabTitle]) => {
      if (tabName == name){
        title = tabTitle
      }
    })
    return title
  }

  const onBulkCandidateSelect = (candidate, isChecked) => {
    const updatedBulkCandidates = bulkSelectedCandidates
    if (isChecked && !updatedBulkCandidates.includes(candidate?.candidate_uuid)){
      updatedBulkCandidates.push(candidate?.candidate_uuid)
    }

    if (!isChecked && updatedBulkCandidates.includes(candidate?.candidate_uuid)){
      updatedBulkCandidates.splice(updatedBulkCandidates.indexOf(candidate?.candidate_uuid), 1)
    }

    setBulkSelectedCandidates([...updatedBulkCandidates])
  }

  const onBulkCandidateSelectAll = (isChecked) => {
    if (isChecked){
      const allSelectedCandidates = renderedCandidates.map(candidate => candidate?.candidate_uuid)
      setBulkSelectedCandidates([...allSelectedCandidates])
    }

    if (!isChecked){
      setBulkSelectedCandidates([])
    }
  }

  const moveCandidateStage = (newWorkflowPosition) => {
    const targetTabTitle = getTabTitleFromTabName(newWorkflowPosition)

    // only validate if single candidate is selected without checkbox checked
    if (bulkSelectedCandidates.length == 0 && newWorkflowPosition == selectedCandidate?.workflow_position){
      toast({
        title: `Candidate is already in ${targetTabTitle} stage`,
        status: "error",
        duration: 3000
      })
      return
    }

    if (newWorkflowPosition == 'candidate_sourced'){
      moveCandidateToSourcing()
      return
    }

    if (newWorkflowPosition == 'candidate_screening'){
      moveCandidateToScreening()
      return
    }

    if (newWorkflowPosition == 'candidate_rejected'){
      onRejectionPanelOpen()
      return
    }

    let candidateUuids = []
    if (bulkSelectedCandidates.length > 0){
      candidateUuids = bulkSelectedCandidates
    }else{
      candidateUuids = [selectedCandidate?.candidate_uuid]
    }

    const obj = {
      email: emailId,
      job_uuid: selectedJob?.job_uuid,
      candidate_uuid: candidateUuids,
      job_workflow_position: newWorkflowPosition,
    };

    setIsMovingCandidateStage(true)
    candidateKanbanStageUpdate(obj)
      .then(res => {
        if (res?.result) {
            getAllCandidatesForJob();            
            toast({
              title: `Candidate moved successfully to ${targetTabTitle}`,
              description: res?.message,
              status: "success",
              duration: 3000
            })
        }else{
          toast({
            title: `Candidate did not move to ${targetTabTitle}`,
            description: res?.message,
            status: "error",
            duration: 5000
          })
        }
        setIsMovingCandidateStage(false)
      })
      .catch(err => {
        setIsMovingCandidateStage(false)
        toast({
          title: `Candidate did not move successfully ${targetTabTitle}`,
          description: err.toString(),
          status: "error",
          duration: 5000
        })
      });
  }

  const moveCandidateToSourcing = () => {
    let candidateUuids = []
    if (bulkSelectedCandidates.length > 0){
      candidateUuids = bulkSelectedCandidates
    }else{
      candidateUuids = [selectedCandidate?.candidate_uuid]
    }

    let objBody = {
      email: emailId,
      job_uuid: selectedJob?.job_uuid,
      candidate_uuid: candidateUuids,
    };
    setIsMovingCandidateStage(true)
    jobWorkflowAdmSelectCandidate(objBody, adminSettings)
      .then(res => {
        if (res?.result) {
          getAllCandidatesForJob()
          toast({
            title: 'Candidate moved successfully to sourcing stage.',
            description: res?.message,
            status: 'success',
            duration: 3000,
          });
        } else {
          toast({
            title: 'Candidate did not move to sourcing stage.',
            description: res?.message,
            status: 'error',
            duration: 5000,
          });
        }
        setIsMovingCandidateStage(false)
      })
      .catch(err => {
        setIsMovingCandidateStage(false)
        toast({
          title: 'Something went wrong',
          description: err.toString(),
          status: 'error',
          duration: 5000,
        });
      });
  };

  const moveCandidateToScreening = () => {
    let candidateUuids = []
    if (bulkSelectedCandidates.length > 0){
      candidateUuids = bulkSelectedCandidates
    }else{
      candidateUuids = [selectedCandidate?.candidate_uuid]
    }

    let obj = {
      email: emailId,
      job_uuid: selectedJob?.job_uuid,
      candidate_uuid: candidateUuids,
    };
    setIsMovingCandidateStage(true)
    jobWorkflowAdmSourceCandidate(obj, adminSettings)
      .then(res => {
        if (res?.result) {
          getAllCandidatesForJob();
          toast({
            title: res?.message,
            status: 'success',
            duration: 6000,
          });
        } else {
          toast({
            title: 'Candidate did not move to screening stage',
            description: res?.message,
            status: 'error',
            duration: 5000,
          });
        }
        setIsMovingCandidateStage(false)
      })
      .catch(err => {
        setIsMovingCandidateStage(false)
        toast({
          title: "Something went wrong",
          description: err.toString(),
          status: 'error',
          duration: 5000,
        });
      });
  };

  const filterCandidatesBySearchQuery = () => {
    const q = searchQuery?.toLowerCase()
    if (q){
      const updatedRenderedCandidates = []
      selectedQualifiedTabCandidates?.map(candidate => {
        if (
        (candidate?.first_name && candidate?.first_name?.toLowerCase()?.search(q) != -1) ||
        (candidate?.last_name && candidate?.last_name?.toLowerCase()?.search(q) != -1) ||
        (candidate?.email && candidate?.email?.toLowerCase()?.search(q) != -1)
        ){
          updatedRenderedCandidates.push(candidate)
        }
      });
      setRenderedCandidates([...updatedRenderedCandidates])
    }else{
      setRenderedCandidates([...selectedQualifiedTabCandidates])
    }
  }

  const unsetSelectedCandidate = () => {
    setSelectedCandidate({})
  }

  const updateJobDetails = (props) => {
    const updatedJob = selectedJob
    for (const [key, value] of Object.entries(props)){
      updatedJob[key] = value
    }
    setSelectedJob({...updatedJob})
  }

  const getCandidateListFromCandidateUUIDs = (candidateUuids) => {
    return allCandidates.filter(candidate => candidateUuids.includes(candidate?.candidate_uuid))
  }

  const routeToJobApplicationForm = () => {
    navigate('/admin/jobapplypage', {
      state: {
        job: selectedJob,
        business: selectedBusiness,
        adminSettings: adminSettings,
      },
    });
  };

  const routeToJobView = () => {
    navigate('/admin/jobDetailsView', {
      state: {
        job_uuid: selectedJob?.job_uuid,
        jobTitle: selectedJob?.job_title,
        companyName: selectedBusiness?.business_name,
        adminSettings: adminSettings,
      },
    });
  };

  const routeToJobEdit = () => {
    navigate('/admin/editJobs', {
      state: {
        job_uuid: selectedJob?.job_uuid,
        job: selectedJob,
        business: selectedBusiness,
        adminSettings: adminSettings
      },
    });
  };

  const routeToKanbanView = () => {
    navigate('/admin/jobworkflow', {
      state: {
        job_uuid: selectedJob?.job_uuid,
        job_title: selectedJob?.job_title,
        job: selectedJob,
        adminSettings: adminSettings,        
        companyName: selectedBusiness?.business_name,
      },
    });
  };

  const routeToReadyToInterview = () => {
    navigate('/admin/interview_ready_candidates', {
      state: {
          job: selectedJob,
          candidate: selectedCandidate,
          business: selectedBusiness,
          adminSettings: adminSettings
      },
    });
  }

  const routeToInterviewing = () => {
    navigate('/admin/calendar', {
      state: {
          job_uuid: selectedJob?.job_uuid,
          job_title: selectedJob?.job_title,
          candidate_uuid: selectedCandidate?.candidate_uuid,
          business: selectedBusiness,
          adminSettings: adminSettings
      },
    });
  }

  const JobDetails = () => {
    return <>
        <Flex mb={'25px'} direction={'column'}>
            <Flex>
              <Text _hover={{ cursor: 'pointer'}} color={schemeTextColor} fontSize={'22px'} fontWeight={'bold'}>
                  {selectedJob?.job_title}
              </Text>
              <Spacer />
              <AddCandidateActions />
              <JobActions />
              <Button height={8} mr={2} onClick={getAllCandidatesForJob} fontWeight={'normal'} variant={'outline'}>Refresh Candidate List</Button>              
              <Button height={8} fontWeight={'normal'} variant={'outline'} onClick={() => backToJobListingPage()}>
                  <Icon as={BiArrowBack} mr={2} />Back to Jobs
              </Button>
            </Flex>

            <Text mt={2} fontSize={'18px'} color={'gray'}>
                {selectedJob?.job_location_extended && toTitleCase(selectedJob?.job_location_extended.replaceAll('_', ' '))}
                {selectedJob?.job_location_extended && <span> - </span>}
                {selectedJob?.job_location}
            </Text>
        
        </Flex>
    </>
  }

  const JobTabButton = ({tabName, tabTitle}) => {
    return <>
        <Button width={'180px'} bg={selectedTab == tabName ? '#313b46' : 'none'}
                    color={selectedTab == tabName ? 'white' : null} borderRadius={'4px'} mr={1}
                    onClick={() => {
                      setSelectedTab(tabName);
                      setSelectedQualifiedTab('qualified')
                    }}
        >
            <Text>{tabTitle}</Text>
            <Text color={'gray'} ml={3}>{getCandidatesCountInJobTab(tabName)}</Text>
        </Button>
    </>
  }

  const JobTabs = () => {
    return <>
        <Flex>
            {Object.entries(jobTabs).map(([tabName, tabTitle]) => <JobTabButton tabName={tabName} tabTitle={tabTitle} />)}
        </Flex>
        </>
  }

  const AddCandidateActions = () => {
    if (!adminSettings){
      return null
    }

    return <Flex mr={2}>
      <Menu>
        <MenuButton as={Button} height={8} variant={'outline'} fontWeight={'normal'} rightIcon={<ChevronDownIcon />}>
          Add Candidates
        </MenuButton>
        <MenuList p={2}>
          <MenuItem onClick={onAddCandidateByResumePanelOpen}>
            <Flex direction={'column'}>
              <Text as={'b'}>Add Resumes</Text>
              <Text>Add candidates with resumes upload</Text>
            </Flex>
          </MenuItem>
          <MenuItem mt={2} onClick={onAddCandidateByFormPanelOpen}>
            <Flex direction={'column'}>
                <Text as={'b'}>Add Manually</Text>
                <Text>Enter candidate name and details</Text>            
            </Flex>
          </MenuItem>
        </MenuList>
      </Menu>
    </Flex>
  }

  const JobPublishButtonLabel = () => {
    if (selectedJob?.is_published){
      return 'UnPublishing a job will remove it from the job listing on candidateapplication.com while user is browsing jobs'
    }
    return 'Publishing a job will make it available in the job listing on candidateapplication.com while user is browsing jobs'
  }

  const JobActions = () => {
    if (!adminSettings){
      return null
    }

    return <Flex mr={2}>
      <Menu>
        <MenuButton as={Button} height={8} variant={'outline'} fontWeight={'normal'} rightIcon={<ChevronDownIcon />}>
          Job Actions
        </MenuButton>
        <MenuList width={'280px'}>
          <MenuItem _hover={{'bg': 'gray.200'}} onClick={routeToJobView}>
            <Flex direction={'column'}>
              <Text as={'b'}>View Job</Text>
            </Flex>
          </MenuItem>
          <MenuItem _hover={{'bg': 'gray.200'}} mt={2} onClick={routeToJobEdit}>
            <Flex direction={'column'}>
              <Text as={'b'}>Edit Job</Text>
            </Flex>
          </MenuItem>
          <MenuItem _hover={{'bg': 'gray.200'}} mt={2} onClick={routeToJobApplicationForm}>
            <Flex direction={'column'}>
                <Text as={'b'}>Job Apply Form</Text>         
            </Flex>
          </MenuItem>

          {/* <Tooltip label={<JobPublishButtonLabel />} fontSize='md'>
                <span>
                <MenuItem _hover={{'bg': 'gray.200'}} mt={2}>
                <Flex direction={'column'} onClick={onPublishJobPanelOpen}>
                  <Text as={'b'}>{selectedJob?.is_published ? "UnPublish" : "Publish" }</Text>
                </Flex>
                </MenuItem>
                </span>
          </Tooltip>    */}

          <MenuItem _hover={{'bg': 'gray.200'}} mt={2}
            onClick={() => {
              if (selectedJob?.job_status?.toLowerCase() != 'closed'){
                onCloseJobPanelOpen()
              }
            }}>
            <Flex direction={'column'}>
                <Text as={'b'}>Close Job</Text>
                {selectedJob?.job_status?.toLowerCase() == 'closed' && <Text>This job is already closed</Text>}   
            </Flex>
          </MenuItem>

          <MenuItem _hover={{'bg': 'gray.200'}} mt={2} onClick={routeToKanbanView}>
            <Flex direction={'column'}>
                <Text as={'b'}>Kanban View [Client Side]</Text>         
            </Flex>
          </MenuItem>
          <MenuItem _hover={{'bg': 'gray.200'}} mt={2} onClick={routeToReadyToInterview}>
            <Flex direction={'column'}>
                <Text as={'b'}>Ready To Interview Page [Client Side]</Text>         
            </Flex>
          </MenuItem>
          <MenuItem _hover={{'bg': 'gray.200'}} mt={2} onClick={routeToInterviewing}>
            <Flex direction={'column'}>
                <Text as={'b'}>Interviewing Page[Client Side]</Text>         
            </Flex>
          </MenuItem>
        </MenuList>
      </Menu>
    </Flex>
  }

  const CandidateQualifiedFilters = () => {
    if (!['all', 'candidate_sourced'].includes(selectedTab)){
      return null
    }

    return <>
      <Flex>
        <Button width={'50%'} borderRadius={0} p={5} fontWeight={'500'} variant={'ghost'} borderBottomRightRadius={'8px'}
          onClick={() => setSelectedQualifiedTab('qualified')} bg={selectedQualifiedTab != 'qualified' ? 'gray.200': 'none'}
          color={selectedQualifiedTab != 'qualified' ? 'gray.500': 'none'}
        >
          Qualified {getCandidatesCountInQualifiedTab('qualified')}
        </Button>
        <Button width={'50%'} borderRadius={0} fontWeight={'500'} variant={'ghost'} borderBottomLeftRadius={'8px'}
          onClick={() => setSelectedQualifiedTab('disqualified')} bg={selectedQualifiedTab != 'disqualified' ? 'gray.200': 'none'}
          color={selectedQualifiedTab != 'disqualified' ? 'gray.500': 'none'}
        >
          Disqualified {getCandidatesCountInQualifiedTab('disqualified')}
        </Button>
      </Flex>
    </>
  }

  const CandidateCurrentStage = ({candidate}) => {
    let currentStage = ""
    if (selectedTab == 'all'){
      if (candidate?.workflow_position){
        currentStage = candidate?.workflow_position
      } else if (candidate?.selected){
        currentStage = 'candidate_sourced'
      }
    }

    return currentStage ? <Text>At <b>{getTabTitleFromTabName(currentStage)?.replaceAll('_', ' ')}</b> stage</Text> : null
  }

  const candidateList = () => {
    if (filteredCandidatesLoading){
        return <>
          <Flex alignItems={'center'} justifyContent={'center'}><Spinner /></Flex>
        </>
    }

    return <Flex
          direction={'column'}
          overflowY="auto"
          maxH={'80vh'}
          css={{
          '&::-webkit-scrollbar': {
              width: '8px',
          },
          '&::-webkit-scrollbar-track': {
              width: '12px',
          },
          '&::-webkit-scrollbar-thumb': {
              borderRadius: '100px',
              background: 'gray.500',
              border: '6px solid rgba(0, 0, 0, 0.2)'
          },
          }}
    >
      {renderedCandidates.map((candidate, index) => {
          return <>
              <Flex key={index} pl={4} bg={selectedCandidate?.candidate_uuid == candidate?.candidate_uuid ? '#fffadf' : 'none'}
                    borderBottom={'0.1px solid'} borderColor={'gray.200'} _hover={{'bg': '#fffadf'}}>
                  <Checkbox isChecked={bulkSelectedCandidates.includes(candidate?.candidate_uuid)} onChange={(e) => onBulkCandidateSelect(candidate, e.target.checked)} size={'lg'} />
                  <Flex padding={5} width={'100%'} onClick={() => setSelectedCandidate(candidate)} _hover={{'cursor': 'pointer'}}>
                    <CandidateHeadshot candidate={candidate} mRight={'15px'}/>
                    <Flex direction={'column'}>
                        <Text fontWeight={'600'} fontSize={'20px'}>{candidate?.first_name} {candidate?.last_name}</Text>
                        <CandidateCurrentStage candidate={candidate} />
                        <CandidateApplySource candidate={candidate} />
                        <Text>{moment.utc(candidate?.created_at).fromNow()}</Text>
                    </Flex>
                  </Flex>
              </Flex>
          </>
      })} 
    </Flex>
  }

  const isRejectionDisabled = (stageName) => {
    if (selectedCandidate?.workflow?.workflow_position  == 'candidate_sourced' && stageName == 'candidate_rejected'){
      return true
    }
    return false
  }

  const CandidateActions = () => {
    const selectedCandidates = getCandidateListFromCandidateUUIDs([...bulkSelectedCandidates])
    if (bulkSelectedCandidates?.length == 0){
      selectedCandidates.push(selectedCandidate)
    }
    return <>
      <Flex justifyContent={'right'} p={'3px 8px'} borderWidth={0} borderColor={borderColorStyle}
        borderRadius={'2px'} boxShadow={'0 0px 0px 0 lightgray,0 1px 5px 0 lightgray'} alignItems={'center'}
      >

        <UploadResumeForCandidate
         job={selectedJob}
         adminSettings={adminSettings}
         bulkSelectedCandidates={selectedCandidates}
         postUploadActions={{
          getAllCandidates: () => getAllCandidatesForJob()
        }}
        />

        <UploadHeadshotForCandidate
         job={selectedJob}
         adminSettings={adminSettings}
         bulkSelectedCandidates={selectedCandidates}
         postUploadActions={{
          getAllCandidates: () => getAllCandidatesForJob()
        }}
        />

        <EnrichCandidate
         adminSettings={adminSettings}
         bulkSelectedCandidates={selectedCandidates}
         postEnrichActions={{
          getAllCandidates: () => getAllCandidatesForJob()
        }}
        />

        <AssignCandidateToJob
         job={selectedJob}
         adminSettings={adminSettings}
         bulkSelectedCandidates={selectedCandidates}
         postAssignActions={{
          getAllCandidatesForJob: () => getAllCandidatesForJob()
        }}
        />
        
        {adminSettings &&
        <Tooltip label={'Add Education'} fontSize='md'>
            <span><IconButton isDisabled={bulkSelectedCandidates.length > 1} bg={'none'} onClick={onAddEducationPanelOpen} _hover={{'bg': 'none'}} icon={<RiGraduationCapFill />} color={'gray'} /></span>
        </Tooltip>
        }

        {adminSettings &&
        <Tooltip label={'Add Experience'} fontSize='md'>
            <span><IconButton isDisabled={bulkSelectedCandidates.length > 1} bg={'none'} onClick={onAddExperiencePanelOpen} _hover={{'bg': 'none'}} icon={<RiBuilding4Fill />} color={'gray'} /></span>
        </Tooltip>
        }

        {adminSettings &&
        <SendEmail
          selectedCandidates={selectedCandidates}
          postEmailActions={{
                              getAllCandidatesForJob: () => getAllCandidatesForJob(),
                              onBulkCandidateSelectAll: () => onBulkCandidateSelectAll(false)
                            }}
        />
        }
      
        <Tooltip label={'Add Feedback'} fontSize='md'>
            <span><IconButton isDisabled={bulkSelectedCandidates.length > 1} fontSize={'18px'} onClick={onFeedbackPanelOpen} bg={'none'} _hover={{'bg': 'none'}} icon={<MdMessage />} color={'gray'} /></span>
        </Tooltip>

        {adminSettings && selectedQualifiedTab == 'qualified' && ['all', 'candidate_sourced'].includes(selectedTab) &&
        <DisqualifyCandidate
          bulkSelectedCandidates={selectedCandidates}
          postDisqualifyActions={{
            getAllCandidates: () => getAllCandidatesForJob(),
            onBulkCandidateSelectAll: () => onBulkCandidateSelectAll(false)
          }}
        />
        }

      {adminSettings && selectedQualifiedTab == 'disqualified' && ['all', 'candidate_sourced'].includes(selectedTab) &&
        <QualifyCandidate
          bulkSelectedCandidates={selectedCandidates}
          postQualifyActions={{
            getAllCandidates: () => getAllCandidatesForJob(),
            onBulkCandidateSelectAll: () => onBulkCandidateSelectAll(false)
          }}
        />
        }
        
        {adminSettings && ['all', 'candidate_sourced'].includes(selectedTab) &&
        <DeleteCandidate
          bulkSelectedCandidates={selectedCandidates}
          postDeleteActions={{
            getAllCandidatesForJob: () => getAllCandidatesForJob(),
            onBulkCandidateSelectAll: () => onBulkCandidateSelectAll(false),
            unsetSelectedCandidate: () => unsetSelectedCandidate(false)
          }}
        />
        }

        {adminSettings && !isEditCandidate && 
        <Tooltip label={'Edit Candidate'} fontSize='md'>
            <span><IconButton onClick={() => setIsEditCandidate(true)} isDisabled={bulkSelectedCandidates.length > 1} fontSize={'20px'} bg={'none'} _hover={{'bg': 'none'}} icon={<BiPencil />} color={'gray'} /></span>
        </Tooltip>
        }

        {adminSettings && isEditCandidate && 
        <Tooltip label={'Finish Editing Candidate'} fontSize='md'>
            <span><IconButton onClick={() => setIsEditCandidate(false)} isDisabled={bulkSelectedCandidates.length > 1} fontSize={'26px'} bg={'none'} _hover={{'bg': 'none'}} icon={<BiCheck />} color={'gray'} /></span>
        </Tooltip>
        }
      
      <Tooltip label={selectedQualifiedTab == 'disqualified' ? 'Switch to qualified candidates' : null} fontSize='md'>
        <span>
          <Select icon={isMovingCandidateStage ? <Spinner /> : <MdArrowDropDown />} ml={'10px'} fontWeight={'500'} value={selectedTab} isDisabled={selectedQualifiedTab == 'disqualified'} width={'200px'} height={'30px'} mt={'0 !important'} onChange={(e) => moveCandidateStage(e.target.value)}>
            <option isDisabled>Move To</option>
            {Object.entries(candidateTargetStages)?.map(([stageName, stageTitle]) => {
              return <option value={stageName} style={{'color': selectedTab==stageName ? 'gray' : 'inherit'}}
                              disabled={selectedTab==stageName || isRejectionDisabled(stageName)}
                      >
                                {stageTitle}
                      </option>
            })}
          </Select>
        </span>
      </Tooltip>  
      </Flex>
    </>
  }

  const CandidateTabs = () => {
    return <>
      <Flex mt={'40px'} boxShadow={'0 0px 0px 0 lightgray,0 1px 5px 0 lightgray'}  borderWidth={0} borderColor={'gray.200'} padding={'20px'}>
        <Tabs colorScheme='gray' width={'100%'}>
          <TabList border={0}>
            <Tab mr={1} width={'150px'} fontWeight={'bold'} _selected={{ color: 'white', bg: '#313b46', 'borderRadius': '6px' }}>Profile</Tab>
            <Tab mr={1} width={'280px'} fontWeight={'bold'} _selected={{ color: 'white', bg: '#313b46', 'borderRadius': '6px' }}>Screening Questions</Tab>
            <Tab mr={1} width={'150px'} fontWeight={'bold'} _selected={{ color: 'white', bg: '#313b46', 'borderRadius': '6px' }}>Timeline</Tab>
            <Tab mr={1} width={'150px'} fontWeight={'bold'} _selected={{ color: 'white', bg: '#313b46', 'borderRadius': '6px' }}>Review</Tab>
            <Tab width={'150px'} fontWeight={'bold'} _selected={{ color: 'white', bg: '#313b46', 'borderRadius': '6px' }}>Feedback</Tab>
          </TabList>
          <TabPanels pt={10}>
            <TabPanel padding={0}>
              <CandidateProfile 
                candidate={selectedCandidate} job={selectedJob}
                isEditCandidate={isEditCandidate}
                getAllCandidatesForJob={getAllCandidatesForJob} />
            </TabPanel>
            <TabPanel padding={0}>
              <CandidateScreeningQuestions candidate={selectedCandidate} />
            </TabPanel>
            <TabPanel padding={0}>
              <CandidateTimeline candidate={selectedCandidate} />
            </TabPanel>
            <TabPanel padding={0}>
              <Text as={'b'}>Review!</Text>
            </TabPanel>
            <TabPanel padding={0}>
              <CandidateFeedback 
                candidate={selectedCandidate} 
                job={selectedJob}
                adminSettings={adminSettings}
                isFeedbackPanelOpen={isFeedbackPanelOpen}
                onFeedbackPanelClose={onFeedbackPanelClose}
                onFeedbackPanelOpen={onFeedbackPanelOpen}
              />
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Flex>
    </>
  }

  const CandidateDetails = () => {
    return <>
        <Flex direction={'column'} width={'53%'} ml={'20px'} mr={'20px'}>
            <CandidateActions />
            <CandidateHeadlines 
              candidate={selectedCandidate} job={selectedJob}
              isEditCandidate={isEditCandidate}
              getAllCandidatesForJob={getAllCandidatesForJob}
            />
            <CandidateTabs />
        </Flex>
    </>
  }

  const CandidateOverview = () => {
    return <>
      <Flex width={'20%'} direction={'column'} boxShadow={'0 0px 0px 0 lightgray,0 1px 5px 0 lightgray'} padding={'20px'}>
          <Text fontWeight={'600'} fontSize={'18px'}>Candidate Overview</Text>
          <Text fontSize={'16px'}>Upcoming events, recent comments, messages and more, will appear here.</Text>
      </Flex>
    </>
  }

  const CandidateProfileView = () => {
    if (filteredCandidatesLoading){
       return <>
          <Flex direction={'column'} alignItems={'center'} width={'50%'} justifyContent={'center'}>
            <Spinner size={'md'} />
          </Flex>
       </>
    }

    if (renderedCandidates.length == 0){
      return <>
          <Flex direction={'column'} alignItems={'center'} width={'50%'} justifyContent={'center'}>
            <Text as={'b'}>No candidates exist in this stage</Text>
            <Text>Pick another pipeline stage to review candidates applications.</Text>
          </Flex>
        </>
    }


    if(!isObjectEmpty(selectedCandidate) && (selectedTab == 'all' || !selectedCandidate?.workflow || selectedCandidate?.workflow?.workflow_position == selectedTab)){
      return <>
            <CandidateDetails />
            <CandidateOverview />
          </>
    }
  }

  const Searchbar = () => {
    return <>
      <Flex padding={5} alignItems={'center'}>
        <Checkbox isChecked={bulkSelectedCandidates.length > 0 && bulkSelectedCandidates.length == renderedCandidates.length} onChange={(e) => onBulkCandidateSelectAll(e.target.checked)} mr={4} size={'lg'} />
        <Input borderRadius={'5px'} placeholder='Search by name, email' value={searchQuery} onChange={e => setSearchQuery(e.target.value)}/>
      </Flex>
    </>
  }

  const onCandidateRejectionSuccess = () => {
      getAllCandidatesForJob();
  };

  const backToJobListingPage = () => {
    navigate('/admin/joblist', {
        state: {
            business: selectedBusiness,
            adminSettings: adminSettings
        },
    });
  }
  
  return(
    <Box pt={{ base: '180px', md: '80px', xl: '80px' }} width={{'base': '400px', 'sm': '600px', 'md': '800px', 'lg': '1000px', 'xl': '1200px', '2xl': '1400px'}}>
        <Flex w={'100%'} direction={'column'}>

          {/* top bar for job details */}
          <Flex direction={'column'} padding={'20px'} mb={5} border={0} boxShadow={'0px 10px 10px -10px lightgray'}>
              <JobDetails />
              <JobTabs />
          </Flex>

          {/* candidate list and profile view */}
          <Flex mt={10}>
            <Flex width={'25%'} maxH={'80vh'} direction={'column'} borderRadius={'6px'} borderColor={borderColorStyle} boxShadow={'0 0px 0px 0 lightgray,0 1px 5px 0 lightgray'}>
              <CandidateQualifiedFilters />
              {Searchbar() }
              {candidateList()}
            </Flex>

            <CandidateProfileView />
          </Flex>
        </Flex>

        <RejectCandidate
          selectedCandidates={getCandidateListFromCandidateUUIDs([...bulkSelectedCandidates, selectedCandidate?.candidate_uuid])}
          isRejectionPanelOpen={isRejectionPanelOpen}
          onRejectionPanelClose={onRejectionPanelClose}
          postRejectActions={{
            onCandidateRejectionSuccess: () => onCandidateRejectionSuccess()
          }}
        />

      <AddExperience
        onAddExperiencePanelOpen={onAddExperiencePanelOpen}
        onAddExperiencePanelClose={onAddExperiencePanelClose}
        isAddExperiencePanelOpen={isAddExperiencePanelOpen}
        candidate={selectedCandidate}
        getCandidatePanelData={getAllCandidatesForJob}
      />

      <AddEducation
        onAddEducationPanelOpen={onAddEducationPanelOpen}
        onAddEducationPanelClose={onAddEducationPanelClose}
        isAddEducationPanelOpen={isAddEducationPanelOpen}
        candidate={selectedCandidate}
        getCandidatePanelData={getAllCandidatesForJob}
      />

      <AddCandidateByResume 
        job={selectedJob} 
        onAddCandidateByResumePanelOpen={onAddCandidateByResumePanelOpen}
        onAddCandidateByResumePanelClose={onAddCandidateByResumePanelClose}
        isAddCandidateByResumePanelOpen={isAddCandidateByResumePanelOpen}
        getAllCandidatesForJob={getAllCandidatesForJob}
      />

      <AddCandidateByForm
        job={selectedJob} 
        onAddCandidateByFormPanelOpen={onAddCandidateByFormPanelOpen}
        onAddCandidateByFormPanelClose={onAddCandidateByFormPanelClose}
        isAddCandidateByFormPanelOpen={isAddCandidateByFormPanelOpen}
        getAllCandidatesForJob={getAllCandidatesForJob}
      />

      <CloseJob
        job={selectedJob}
        isCloseJobPanelOpen={isCloseJobPanelOpen}
        onCloseJobPanelClose={onCloseJobPanelClose}
        onCloseJobPanelOpen={onCloseJobPanelOpen}
        updateJobDetails={updateJobDetails}
      />

    {/* <PublishJob
        job={selectedJob}
        isPublishJobPanelOpen={isPublishJobPanelOpen}
        onPublishJobPanelClose={onPublishJobPanelClose}
        onPublishJobPanelOpen={onPublishJobPanelOpen}
        updateJobDetails={updateJobDetails}
      /> */}
    </Box>
  )
}

export default JobCandidateList;
