import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, location, useSearchParams } from 'react-router-dom';

import { defaultThemeColor } from '../../../../constants/index';
import { getEmailId } from '../../../../utils/localStorageIndex';
import Pack from '../../../../views/admin/main/others/pricing/components/Pack';
import Card from '../../../../components/card/Card';
import {
    AiFillEdit,
    AiOutlineCheckCircle,
    AiFillFilePdf,
    AiFillInfoCircle,
    AiOutlineLinkedin,
    AiOutlineFilePdf,
    AiOutlineInfoCircle,
  } from 'react-icons/ai';
import {
  Box,
  Text,
  Spacer,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  CloseButton,
  UnorderedList,
  List,
  ListItem,
  ListIcon,
  Center,
  Flex,
  Button,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  Slider,
  Link,
  TabPanel,
  useToast,
  SliderFilledTrack,
  SliderThumb,
  SliderMark,
  SliderTrack,
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerContent,
  useDisclosure,
  DrawerCloseButton,
  Spinner,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  ModalBody,
  useColorModeValue,
} from '@chakra-ui/react';
import { paymentCustomerApi, fetchPlanDetails, fetchPlanDetailsChargebee, updateSubscriptionChargebee, modifySubscriptionChargebee, completePaymentApiChargebee, getPortalSession } from '../../../../services/paymentServices';
import StripePayment from './StripePayment';
import axios from 'axios';
import {isObjectEmpty} from '../../../../utils/common'
import Pricify from '@chargebee/atomicpricing';


function StripeBilling() {
    const toast = useToast();
    const navigate = useNavigate();
    const btnRef = React.useRef();
    const [loading, isLoading] = useState(true);
    const [isPopupOpen, setPopupOpen] = useState(false);

    const textColorPrimary = useColorModeValue('secondaryGray.900', 'gray.600');
    const borderColorStyle = useColorModeValue('gray.200', 'gray.400'); // {light, dark}

    const {isOpen: isVisible, onClose, onOpen, } = useDisclosure({ defaultIsOpen: true })
    
    // const [selectedPlanRes, setSelectedPlanRes] = useState({});
    // const [selectedPlanStatus, setSelectedPlanStatus] = useState(false);
    // const [planSelected, setPlanSelected] = useState(null);
    const [showPurButton, setShowPurButton] = useState(false);
    const [noBusinessPlan, setNoBusinessPlan] = useState(true);
    const [subscriptionData, setSubscriptionData] = useState([]);
    const [totalSlots, setTotalSlots] = useState(null);

    const [alertVisible, setAlertVisible] = useState(false);
    const [alertMessage, setAlertMessage] = useState(null);
    const [checkoutId, setCheckoutId] = useState(null)
    const [subId, setSubId] = useState(null)
    const [isUpdatingSubscription, setIsUpdatingSubscription] = useState(false)
    const [activeSubscription, setActiveSubscription] = useState({})
    const [portalUrl, setPortalUrl] = useState(null)

    const shadow = useColorModeValue(
        '14px 17px 40px 4px rgba(112, 144, 176, 0.18)',
        '14px 17px 40px 4px rgba(112, 144, 176, 0.06)'
    );

    const [chargebee, setChargebee] = useState(null)
    const [searchParams] = useSearchParams();
    
    useEffect(() => {
        fetchPreviousSelectedPlan();     
    }, []);

    useEffect(() => {
    let sub_id = searchParams.get("sub_id");
    if (sub_id){
        setSubId(sub_id);
        completePurchase(sub_id);
    } 
    }, []);

    useEffect(() => {
    Pricify.init();
    Pricify.setVisitor({
        email: getEmailId(),
    });
    let cbInstance = window.Chargebee.init({
            site: process.env.REACT_APP_CHARGEBEE_SITE,
            isItemsModel: true,
        });
    
    setChargebee(cbInstance)

    }, []);
  
    const completePurchase = (sub_id) => {
    let objBody = {
        email: getEmailId(),
        sub_id: sub_id
    };

    setIsUpdatingSubscription(true)
    completePaymentApiChargebee(objBody)
        .then(res => {
        setIsUpdatingSubscription(false)
        if (res?.result) {
            toast({
                title: "Subscribed successfully",
                status: 'success',
                duration: 3000
            })
            fetchPreviousSelectedPlan()

            } else {
            toast({
                title: "Unable to subscribe",
                duration: res?.message,
                status: 'error',
                duration: 5000
            })
            }
        })
        .catch(err => {
        setIsUpdatingSubscription(false)
        toast({
            title: "Unable to subscribe",
            duration: err?.toString(),
            status: 'error',
            duration: 5000
        })            
        });
    };

    useEffect(() => getActiveSubscription(), [subscriptionData])

    useEffect(() => {
        if (!isObjectEmpty(activeSubscription) && chargebee){
            chargebee.logout()
            createChargebeePortal()
        }else if (chargebee){
            chargebee.logout()
            chargebee.closeAll()
        }
    }, [activeSubscription, chargebee])

    const fetchPreviousSelectedPlan = () => {
        let objBody = {
        email: getEmailId(),
        };
        fetchPlanDetailsChargebee(objBody)
        .then(res => {
            isLoading(false);
            if (res?.result) {
                if (res?.data?.length > 0 && res?.data[0]?.subscriptions?.length > 0){
                    setSubscriptionData(res?.data[0]?.subscriptions);            
                }
            } else {
                toast({
                    title: "Unable to get subscriptions",
                    duration: res?.message,
                    status: 'error',
                    duration: 5000
                })
            }
        })
        .catch(err => {
            isLoading(false);
            toast({
                title: "Unable to get subscriptions",
                duration: err?.toString(),
                status: 'error',
                duration: 5000
            })
        });
    }

    const modifySubscription = () => {
        let objBody = {
          email: getEmailId(),
          checkout_id: checkoutId
        };
        
        setIsUpdatingSubscription(true)
        modifySubscriptionChargebee(objBody)
          .then(res => {
            setIsUpdatingSubscription(false)
            if (res?.result) {
                fetchPreviousSelectedPlan()               
                toast({
                    title: "Subscription updated successfully.",
                    status: 'success',
                    duration: 3000
                })
              } else {
                toast({
                    title: "Unable to update subscription.",
                    description: res?.message,
                    status: 'error',
                    duration: 5000
                })
            }
          })
          .catch(err => {
            setIsUpdatingSubscription(false)
            toast({
                title: "Unable to update subscription.",
                description: err?.toString(),
                status: 'error',
                duration: 5000
            })
      })
    }

    const addUpdateCustomerDetails = () => {
        let objBody = {
        email: getEmailId(),
        };
        paymentCustomerApi(objBody)
        .then(res => {
            if (res?.result && res?.data?.length > 0) {
                if (res?.data[0]?.customer_id && res?.data[0]?.customer_email){
                    launchPaymentPage(res?.data[0]?.customer_id, res?.data[0]?.customer_email);
                }
            }
        })
        .catch(err => {
            setAlertVisible(true);
            setAlertMessage({
                title:'Failed to Setup Business Plan',
                message:"Unable to setup your customer details.. please contact Talent Strap Support!!",
                type:'error'});
        });
    };

    const renderNextBillingDateOld = (is_annual, start_date) => {
        let addDays = 0
        if (is_annual){
            addDays = 365;
        } else {
            addDays = 30;
        }
        const current_date = new Date(start_date);
        const added_date = current_date.setDate(current_date.getDate() + addDays);
        let updatedDate = new Date(added_date);
        return  updatedDate.toLocaleDateString('en-us', { weekday:"short", year:"numeric", month:"short", day:"numeric"});
    }

    const renderNextBillingDate = (is_annual, start_date) => {
        let addDays = 0
        if (is_annual){
            addDays = 365;
        } else {
            addDays = 30;
        }
        const current_date = new Date(start_date);
        const added_date = current_date.setDate(current_date.getDate() + addDays);
        let updatedDate = new Date(added_date);
        return  updatedDate.toLocaleDateString('en-us', { weekday:"short", year:"numeric", month:"short", day:"numeric"});
    }

    const renderSubscriptionDetails = () => {
        if (subscriptionData?.length > 0){
            return(
                subscriptionData.map((item, index) => {
                    return(
                        <Flex 
                        borderWidth={0} borderColor={borderColorStyle}
                        borderRadius={'2px'} boxShadow={'0 0px 0px 0 lightgray,0 1px 5px 0 lightgray'}
                        key={index} p="2" w={'100%'} mt={5}>
                            <Flex direction={'column'} w={'25%'}>
                                <Text fontSize={'lg'} fontWeight={'500'}>{item.plan_type}</Text>
                                <Text>{item.plan_info}</Text>
                            </Flex>
                            <Flex w={'10%'}>
                                {item.payment_status === 'paid'
                                ?<Text p={2} rounded={'md'}>{item.payment_status}</Text>
                                :<Text p={2} rounded={'md'}>{item.payment_status}</Text>}
                            </Flex>
                            {item?.is_canceled === false
                            ?<Flex w={'10%'}>
                                {item?.sub_key && item?.is_canceled === false && item.total_slots >= 2
                                ?<Button 
                                    mt={1} mr={1}
                                    onClick={() => launchModifyPage(item?.sub_key)}
                                    size="sm" p={2}>
                                    {'-'}
                                </Button>
                                :<Button 
                                    mt={1} mr={1}
                                    onClick={() => {
                                        setAlertVisible(true);
                                        setAlertMessage({
                                            title:'Slot Modification',
                                            message:"You do not have enough slots to reduce.",
                                            type:'error'});
                                        }
                                    }
                                    size="sm" p={2}>
                                    {'-'}
                                </Button>}
                                <Text p={2} fontWeight={'500'} rounded={'md'}>{item.total_slots}</Text>
                                <Button 
                                    mt={1} ml={1}
                                    // onClick={() => launchUpgradePage(item?.sub_key)}
                                    onClick={() => upgradeSubscriptionChargebee(item?.subscription_id)}
                                    size="sm" p={2} bg={'green.200'}>
                                    {'+'}
                                </Button>
                            </Flex>
                            :<Flex w={'10%'}>
                                <Text p={2} fontWeight={'500'} rounded={'md'}>{item.total_slots}</Text>
                            </Flex>}
                            <Flex w={'10%'}>
                                <Text>{item.is_annual ? "Annual" : "Monthly"}</Text>
                            </Flex>
                            <Flex w={'10%'}>
                                <Text>{new Date(item.start_date).toLocaleDateString('en-us', 
                                    { weekday:"short", year:"numeric", month:"short", day:"numeric"})}</Text>
                            </Flex>
                            <Flex w={'10%'}>
                                {item?.is_canceled === false
                                ?<Text>
                                    {new Date(item.next_billing).toLocaleDateString('en-us', 
                                    { weekday:"short", year:"numeric", month:"short", day:"numeric"})}
                                    {/* {renderNextBillingDate(item.is_annual, item.start_date)} */}
                                </Text>
                                :<Text color={'gray.300'}>{'N/A'}</Text>}
                            </Flex>
                            <Flex w={'10%'}>
                                {item?.is_canceled
                                ?<Text p={2} color={'red.600'} fontWeight={'500'} rounded={'md'}>{'Cancelled'}</Text>
                                :<Text p={2} color={'green.600'} fontWeight={'500'} rounded={'md'}>{'Active'}</Text>}
                            </Flex>
                            <Flex w={'15%'} direction={'column'}>
                                {item?.sub_key && item?.is_canceled === false
                                ?<Button 
                                    onClick={() => launchCancelPage(item?.sub_key)}
                                    size="sm" p={2}>
                                    Cancel Subscription
                                </Button>:null}
                            </Flex>
                        </Flex>
                    )
                })
            )
        }
    }
    const launchPaymentPage = (customerId, customerEmail) => {
        navigate('/business/billing/purchase', {
            state: {
                sourcePage:'purchaseplan',
                customerEmail: customerEmail,
                customerId: customerId
            },
        });
    };

    const launchCancelPage = (subKey) => {
        navigate('/business/billing/cancelsubscription', {
            state: {
                sourcePage:'purchaseplan',
                subKey: subKey
            },
        });
    }

    const launchModifyPage = (subKey) => {
        navigate('/business/billing/modifysubscription', {
            state: {
                sourcePage:'purchaseplan',
                subKey: subKey
            },
        });
    }

    const launchUpgradePage = (subKey) => {
        navigate('/business/billing/upgradesubscription', {
            state: {
                sourcePage:'purchaseplan',
                subKey: subKey
            },
        });
    }
    
    const renderSpinner = () => {
        return (
        <Flex alignItems="center" justifyContent="center" mt="10">
            <Spinner size="sm" />
        </Flex>
        );
    };

    const toastFunction = (message, status) => {
        return toast({
        title: message,
        status: status,
        isClosable: true,
        duration: 3000,
        });
    };

    const upgradeSubscriptionChargebee = () => {
        chargebee.openCheckout({
            hostedPage: () => {
              // Hit your end point that returns hosted page object as response
              // This sample end point will call checkout new api
              // https://apidocs.chargebee.com/docs/api/hosted_pages#checkout_new_subscription
              // If you want to use paypal, go cardless and plaid, pass embed parameter as false
              return axios.post("http://localhost:8010/v1/api/payment/generate_checkout_page_chargebee", {'email': getEmailId()}).then((response) => response.data)
            },
            success(hostedPageId) {
              console.log(hostedPageId);
            },
            close:() => {
              console.log("checkout new closed");
            },
            step(step) {
              console.log("checkout", step);
            }
          });
    }

    const handleSubscriptionUpdate = (subscription_id) => {
        let objBody = {
            email: getEmailId(),
            subscription_id: subscription_id
          };
          
          setIsUpdatingSubscription(true)
          updateSubscriptionChargebee(objBody)
            .then(res => {
              setIsUpdatingSubscription(false)
              if (res?.result) {
                  fetchPreviousSelectedPlan()               
                  toast({
                      title: "Subscription updated successfully.",
                      status: 'success',
                      duration: 3000
                  })
                } else {
                  toast({
                      title: "Unable to update subscription.",
                      description: res?.message,
                      status: 'error',
                      duration: 5000
                  })
              }
            })
            .catch(err => {
              setIsUpdatingSubscription(false)
              toast({
                  title: "Unable to update subscription.",
                  description: err?.toString(),
                  status: 'error',
                  duration: 5000
              })
        })
    }

    const handlePortalSession = async () => {
        return getPortalSession({'email': getEmailId()})
            .then(res => {
                if (res?.result){
                    setPortalUrl(res?.data?.access_url)
                    return res?.data
                }else{
                    toast({
                        title: "Unable to get subscription",
                        description: res?.message,
                        status: 'error',
                        duration: 5000
                    })
                }
            })
            .catch(err => {
                toast({
                    title: "Unable to get subscription",
                    description: err?.toString(),
                    status: 'error',
                    duration: 5000
                })
            })
    }

    const createChargebeePortal = () => {
        handlePortalSession()
        chargebee.setPortalSession(() => {
            return handlePortalSession()
        });
        const cbPortal = chargebee.createChargebeePortal()
        chargebee.setPortalCallbacks({
            loaded: () => {
                console.log('loaded')
            },

            visit: () => {
                console.log('visit')
            },

            close: () => {
                console.log('close')
                chargebee.logout()
            },

            subscriptionChanged: (data) => {
                console.log('changed', data)
                handleSubscriptionUpdate(data.subscription.id)
            },

            subscriptionExtended: (data) => {
                console.log('subscriptionExtended', data)
                handleSubscriptionUpdate(data.subscription.id)
            },

            subscriptionCancelled: (data) => {
                console.log('cancelled', data)
                handleSubscriptionUpdate(data.subscription.id)
            },

            subscriptionResumed: (data) => {
                console.log('subscriptionResumed', data)
                handleSubscriptionUpdate(data.subscription.id)
            },

            subscriptionReactivated: (data) => {
                console.log('subscriptionReactivated', data)
                handleSubscriptionUpdate(data.subscription.id)
            },

            subscriptionCustomFieldsChanged: (data) =>{
                console.log('custom change')
                handleSubscriptionUpdate(data.subscription.id)
            }
        });
        cbPortal.open();
    }

    const getActiveSubscription = () => {
        let activeSubscription = {}
        subscriptionData?.map(sub => {
            if (!sub?.is_canceled){
                activeSubscription = sub
            }
        })
        setActiveSubscription(activeSubscription)
    }

    return (
        <>
        {(loading || isUpdatingSubscription ) && renderSpinner()}

        <Flex hidden direction="column" pt={{ sm: '125px', lg: '75px' }}>
            <Flex direction="column">
                <Flex w={'100%'} align={'center'}>
                    <Card w={'100%'} mt={4}>
                        <Flex
                            direction="column"
                            alignSelf="center"
                            justifySelf="center"
                            align={'center'}
                            overflow="hidden"
                        >
                            <Text fontSize={'2xl'}>Easy Plans For Your Specific Needs And Solutions</Text>
                            <Text fontSize={'xl'} color={textColorPrimary} rounded={'lg'} px={2}>Unlimited</Text>
                            <Text fontSize={'lg'}>For Local To Remote Candidates</Text>
                            <List spacing={3} mt={5}>
                                <ListItem>
                                    <ListIcon as={AiOutlineCheckCircle} color='green.500' />
                                    <b>$700</b> per slot/mo (1-2 Slots)
                                </ListItem>
                                <ListItem>
                                    <ListIcon as={AiOutlineCheckCircle} color='green.500' />
                                    <b>$600</b> per slot/mo (3-7 Slots)
                                </ListItem>
                                <ListItem>
                                    <ListIcon as={AiOutlineCheckCircle} color='green.500' />
                                    <b>$500</b> per slot/mo (8+ Slots)
                                </ListItem>
                                <ListItem>
                                    <ListIcon as={AiOutlineCheckCircle} color='green.500' />
                                    <b>$700</b> per slot/mo (1-2 Slots)
                                </ListItem>
                                <ListItem>
                                    <ListIcon as={AiOutlineCheckCircle} color='green.500' />
                                    <b>10% discount</b> per slot/mo if paid <b>anually</b>
                                </ListItem>
                                <ListItem>
                                    <ListIcon as={AiOutlineCheckCircle} color='green.500' />
                                    Unlimited Candidate Sourcing
                                </ListItem>
                                <ListItem>
                                    <ListIcon as={AiOutlineCheckCircle} color='green.500' />
                                    Unlimited Seats
                                </ListItem>
                                <ListItem>
                                    <ListIcon as={AiOutlineCheckCircle} color='green.500' />
                                    AI Sourcing Intelligence
                                </ListItem>
                                <ListItem>
                                    <ListIcon as={AiOutlineCheckCircle} color='green.500' />
                                    Candidate Pipeline CRM
                                </ListItem>
                                <ListItem>
                                    <ListIcon as={AiOutlineCheckCircle} color='green.500' />
                                    Unlimited Job Board Posts
                                </ListItem>
                                <ListItem>
                                    <ListIcon as={AiOutlineCheckCircle} color='green.500' />
                                    Automated Outreach
                                </ListItem>
                                <ListItem>
                                    <ListIcon as={AiOutlineCheckCircle} color='green.500' />
                                    Automated Interview Scheduling
                                </ListItem>
                                <ListItem>
                                    <ListIcon as={AiOutlineCheckCircle} color='green.500' />
                                    Email + Chat Support
                                </ListItem>
                                <ListItem>
                                    <ListIcon as={AiOutlineCheckCircle} color='green.500' />
                                    ATS Integration
                                </ListItem>
                                <ListItem>
                                    <ListIcon as={AiOutlineCheckCircle} color='green.500' />
                                    Dedicated Account Manager
                                </ListItem>
                            </List>
                            <Button 
                                onClick={() => addUpdateCustomerDetails()}
                                mt={4} color={textColorPrimary} bg={'blue.200'}>
                                Purchase Business Plan
                            </Button>
                        </Flex>
                    </Card>
                </Flex>
            </Flex>
        </Flex>
        
        <Flex hidden w={'100%'} direction={'column'} align={'center'} mt={20}>
            <Flex hidden={subscriptionData?.length > 0} w={'100%'}>
                <Card w={'100%'} p={1}>
                    <Flex w={'100%'}>
                        <Spacer />
                        <Button 
                            onClick={() => addUpdateCustomerDetails()}
                            mt={4} color={textColorPrimary} bg={'blue.200'}>
                            Add New Subscription
                        </Button>
                    </Flex>
                </Card>
            </Flex>

            <Card w={'100%'} p={4} mt={1}>
                <Text color={'gray.600'} fontSize={'lg'}>Here is your Talent Strap Subscripton:</Text>
                <Flex p="2" w={'100%'} mt={5} rounded={'lg'} borderWidth={'0.5px'} borderColor={'gray.200'}>
                    <Flex direction={'column'} w={'25%'}>
                        <Text>{'Plan Description'}</Text>
                    </Flex>
                    <Flex w={'10%'}>
                        <Text>{'Plan Status'}</Text>
                    </Flex>
                    <Flex w={'10%'}>
                        <Text>{'Slots Purchased'}</Text>
                    </Flex>
                    <Flex w={'10%'}>
                        <Text>{'Duration'}</Text>
                    </Flex>
                    <Flex w={'10%'}>
                        <Text>{'Start Date'}</Text>
                    </Flex>
                    <Flex w={'10%'}>
                        <Text>{'Next Billing Date'}</Text>
                    </Flex>
                    <Flex w={'10%'}>
                        <Text>Status</Text>
                        {/* <Text color={'red.300'}>{'Cancellation Status'}</Text> */}
                    </Flex>
                </Flex>
                {renderSubscriptionDetails()}
                <Flex p="2" w={'100%'} mt={1} rounded={'lg'} borderWidth={'0.5px'} borderColor={'gray.200'}>
                    <Text fontSize={'lg'}>Total Active Slots: <b>{totalSlots}</b></Text>
                </Flex>
            </Card>
        </Flex>

        <Flex hidden={!isObjectEmpty(activeSubscription)} direction="column" pt={{ sm: '125px', lg: '75px' }}>
        {process.env.REACT_APP_CHARGEBEE_SITE.endsWith('test') ?        
        <div
            id="pricify-hosted-pricing-page"
            data-pricify-site="01HY2KTKC6YRTAJ2K7K2YVH7TN"
            data-pricify-pricingpage="01HY2KTM85QZCW4YNA80GXYPJ8"
            data-pricify-viewport-defaultheight="805.1875px"
        ></div>
        :
        <div
            id="pricify-hosted-pricing-page"
            data-pricify-site="01HXWB550XC2XBBJE3D5AA6380"
            data-pricify-pricingpage="01HXWB55SBK28YRKB8086P0E2S"
            data-pricify-viewport-defaultheight="805.1875px"
        ></div>
        }
        </Flex>

        <Flex direction="column" pt={{ sm: '125px', lg: '75px' }}>
        {/* <iframe src={portalUrl} width={1000} height={1000} title='portal' overflow={'hidden'}></iframe> */}
        </Flex>

        </>
    );
}

export default StripeBilling;
