import React, { useEffect, useRef, useState } from 'react';
import {
  Button, Text, Flex, useDisclosure, useToast, Spacer, Drawer, DrawerContent, DrawerHeader, DrawerCloseButton, DrawerBody,
  DrawerFooter, Input, Popover, Tooltip, PopoverTrigger, PopoverContent, PopoverArrow, PopoverCloseButton, PopoverBody, 
  PopoverFooter, ButtonGroup, Menu, MenuButton, MenuList, MenuItem
  , Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, ModalFooter
} from '@chakra-ui/react';
import { getEmailId } from '../../utils/localStorageIndex';
import { createEmailTemplate, deleteEmailTemplate, getEmailTemplateList, updateEmailTemplate } from '../../services/emailServices';
import { Editor } from '@tinymce/tinymce-react';
import { CkeditorUtil } from '../../utils/ckeditor';
import { ChevronDownIcon } from '@chakra-ui/icons';
import { fetchPersonalInfo, updateUserInfo } from '../../services/sharedServices';


function EmailTemplateList() {
  const emailId = getEmailId();
  const toast = useToast()
  const [isTemplateCreating, setIsTemplateCreating] = useState(false);
  const [isTemplateUpdating, setIsTemplateUpdating] = useState(false);
  const [isTemplateDeleting, setIsTemplateDeleting] = useState(false);
  const [emailTemplates, setEmailTemplates] = useState([])
  const [showTemplateActions, setShowTemplateActions] = useState(null)
  const {onOpen: onTemplateCreateOpen, onClose: onTemplateCreateClose, isOpen: isTemplateCreateOpen} = useDisclosure()
  const {onOpen: onTemplatePreviewOpen, onClose: onTemplatePreviewClose, isOpen: isTemplatePreviewOpen} = useDisclosure()
  const {onOpen: onTemplateEditOpen, onClose: onTemplateEditClose, isOpen: isTemplateEditOpen} = useDisclosure()
  const { isOpen: isTemplateDeleteConfirmationOpen, onClose: onTemplateDeleteConfirmationClose, onToggle: onTemplateDeleteConfirmationToggle } = useDisclosure()
  const {onOpen: onAddCalendarPanelOpen, onClose: onAddCalendarPanelClose, isOpen: isAddCalendarPanelOpen} = useDisclosure()
  const [isCalendarUrlAdding, setIsCalendarUrlAdding] = useState(false)
  const [calendarUrl, setCalendarUrl] = useState(null)
  const [userDetails, setUserDetails] = useState({});
  const editorRef = useRef(null);

  const [templateForm, setTemplateForm] = useState({
    user_email: emailId,
    template_uuid: null,
    name: null,
    subject: null,
    content: null
  })

  useEffect(() => {
    getUserDetails()
    getTemplateList()
  }, [])

  const getUserDetails = () => {
    fetchPersonalInfo({ email: emailId }).then(res => {
        if (res.result && res?.data?.length > 0) {
          setUserDetails(res?.data[0]);
          setCalendarUrl(res?.data[0]?.calendar_url)
        }else{
          toast({
            title: 'Unable to get user details',
            description: res?.message,
            state: 'error',
            duration: 5000
          })
        }
      }).catch(err => {
        toast({
          title: 'Unable to get user details',
          description: err?.toString(),
          state: 'error',
          duration: 5000
        })
      })
  }

  const getTemplateList = () => {
    const data = {
      user_email: getEmailId()
    }
    getEmailTemplateList(data).then(res => {
      if (res?.result){
        setEmailTemplates(res?.data)
      }
    }).catch(err => {
      toast({
        title: "Unable to get email templates",
        description: err.toString(),
        duration: 5000
      })
    })
  }

  const validateTemplateForm = () => {
    if (!templateForm.name){
      toast({
        title: "Template name is required",
        status: 'error',
        duration: 3000
      })
      return false
    }
    if (!templateForm.subject){
      toast({
        title: "Template subject is required",
        status: 'error',
        duration: 3000
      })
      return false
    }
    if (!templateForm.content){
      toast({
        title: "Template email body is required",
        status: 'error',
        duration: 3000
      })
      return false
    }

    return true
  }

  const onUpdateTemplate = () => {
    if (!validateTemplateForm()){
      return
    }
    setIsTemplateUpdating(true)
    const updatedForm = {...templateForm, content: CkeditorUtil.getContentWithLineStyles(templateForm.content)}
    updateEmailTemplate(updatedForm).then(res => {
      setIsTemplateUpdating(false)
      if (res?.result){
        getTemplateList()
        onTemplateEditClose()
        toast({
          title: "Email template updated successfully",
          status: 'success',
          duration: 3000
        })
      }else{
        toast({
          title: "Unable to update template",
          description: res?.message,
          status: 'error',
          duration: 5000
        })
      }
    }).catch(err => {
      setIsTemplateUpdating(false)
      toast({
        title: "Unable to update template",
        description: err.toString(),
        status: 'error',
        duration: 5000
      })
    })
  }

  const onCreateTemplate = () => {
    if (!validateTemplateForm()){
      return
    }

    setIsTemplateCreating(true)
    const updatedForm = {...templateForm, content: CkeditorUtil.getContentWithLineStyles(templateForm.content)}
    createEmailTemplate(updatedForm).then(res => {
      setIsTemplateCreating(false)
      if (res?.result){
        getTemplateList()
        onTemplateCreateClose()
        toast({
          title: "Email template added successfully",
          status: 'success',
          duration: 3000
        })
      }else{
        toast({
          title: "Unable to add template",
          description: res?.message,
          status: 'error',
          duration: 5000
        })
      }
    }).catch(err => {
      setIsTemplateCreating(false)
      toast({
        title: "Unable to add template",
        description: err.toString(),
        status: 'error',
        duration: 5000
      })
    })
  }

  const onDeleteTemplate = (template) => {
    setIsTemplateDeleting(true)
    deleteEmailTemplate({...template, user_email: emailId}).then(res => {
      setIsTemplateDeleting(false)      
      if (res?.result){
        onTemplateDeleteConfirmationClose()
        getTemplateList()
        toast({
          title: "Email template deleted successfully",
          status: 'success',
          duration: 3000
        })
      }else{
        toast({
          title: "Unable to delete template",
          description: res?.message,
          status: 'error',
          duration: 5000
        })
      }
    }).catch(err => {
      onTemplateDeleteConfirmationClose()
      setIsTemplateDeleting(false)
      toast({
        title: "Unable to delete template",
        description: err.toString(),
        status: 'error',
        duration: 5000
      })
    })
  }

  const onAddCalendar = () => {        
    setIsCalendarUrlAdding(true)
    updateUserInfo({...userDetails, calendar_url: calendarUrl}).then(res => {
        if (res?.result && res?.data?.length > 0){
            onAddCalendarPanelClose()
            setIsCalendarUrlAdding(false)
            editorRef.current.insertContent('{calendarUrl}')
            toast({
                title: "Calendar URL updated",
                status: 'success',
                duration: 3000
            })
        }else{
            toast({
                title: "Unable to update calendar url",
                description: res?.message,
                status: 'error',
                duration: 5000
            })
        }         
    }).catch(err => {
        setIsCalendarUrlAdding(false)
        toast({
            title: "Unable to update calendar url",
            description: err?.toString(),
            status: 'error',
            duration: 5000
        })
    })        
  }

  const onPreviewPanelOpen = (template) => {
    setTemplateForm({...templateForm, ...template})
    onTemplatePreviewOpen()
  }

  const onEditPanelOpen = (template) => {
    setTemplateForm({...templateForm, ...template})
    onTemplateEditOpen()
  }

  const onCreatePanelOpen = () => {
    onTemplateCreateOpen()
    setTemplateForm({
      ...templateForm,
      template_uuid: null,
      name: null,
      content: null
    })
  }

  const TemplateDeleteButton = (template) => {
    return <>
      <Popover placement='bottom' autoFocus={false} defaultIsOpen={false} closeOnBlur={true} isOpen={isTemplateDeleteConfirmationOpen}>
          <Tooltip fontSize='md'>
            <span>
              <PopoverTrigger>
                <Button onClick={onTemplateDeleteConfirmationToggle} height={6} variant={'ghost'} bg={'none'} _hover={{bg: 'none'}}>Delete</Button>
              </PopoverTrigger>
            </span>
          </Tooltip>
        
        <PopoverContent width={'350px'} onMouseLeave={onTemplateDeleteConfirmationClose} color='white' bg='blue.800' borderColor='blue.800'>
            <PopoverArrow bg='blue.800' />
            <PopoverCloseButton onClick={onTemplateDeleteConfirmationClose} />
            <PopoverBody mt={5}>Are you sure you want to delete this template?</PopoverBody>
            <PopoverFooter border='0' display='flex' alignItems='center' justifyContent='space-between' pb={4}>
            <ButtonGroup size='sm'>
                <Button colorScheme='blue' onClick={() => onDeleteTemplate(template)} isLoading={isTemplateDeleting} loadingText={'Deleting...'}>
                  Yes
                </Button>
            </ButtonGroup>
            </PopoverFooter>
        </PopoverContent>
    </Popover>
    </>
  }

  const VariablesMenu = () => {
    return <Flex mt={3}>
      <Menu>
        <MenuButton as={Button} height={8} variant={'outline'} fontWeight={'normal'} rightIcon={<ChevronDownIcon />}>
          Variables {"{}"}
        </MenuButton>
        <MenuList width={'150px'}>
          <MenuItem _hover={{'bg': 'gray.200'}} onClick={() => {editorRef.current.insertContent('{candidateFirstName}')}}>
            <Flex direction={'column'}>
              <Text>Candidate First Name</Text>
            </Flex>
          </MenuItem>
          <MenuItem _hover={{'bg': 'gray.200'}} onClick={() => {editorRef.current.insertContent('{candidateLastName}')}}>
            <Flex direction={'column'}>
              <Text>Candidate Last Name</Text>
            </Flex>
          </MenuItem>
          <MenuItem _hover={{'bg': 'gray.200'}} onClick={() => {editorRef.current.insertContent('{candidateFullName}')}}>
            <Flex direction={'column'}>
              <Text>Candidate Full Name</Text>
            </Flex>
          </MenuItem>
          <MenuItem _hover={{'bg': 'gray.200'}} onClick={() => {editorRef.current.insertContent('{job}')}}>
            <Flex direction={'column'}>
              <Text>Job Title</Text>
            </Flex>
          </MenuItem>
          <MenuItem _hover={{'bg': 'gray.200'}} onClick={() => {editorRef.current.insertContent('{company}')}}>
            <Flex direction={'column'}>
              <Text>Company Name</Text>
            </Flex>
          </MenuItem>
          <MenuItem _hover={{'bg': 'gray.200'}} onClick={() => {editorRef.current.insertContent('{jobLocation}')}}>
            <Flex direction={'column'}>
              <Text>Job Location</Text>
            </Flex>
          </MenuItem>
          <MenuItem _hover={{'bg': 'gray.200'}} onClick={() => {editorRef.current.insertContent('{senderFullName}')}}>
            <Flex direction={'column'}>
              <Text>Sender Full Name</Text>
            </Flex>
          </MenuItem>
          <MenuItem _hover={{'bg': 'gray.200'}} onClick={onAddCalendarPanelOpen}>
            <Flex direction={'column'}>
              <Text>Calendar URL</Text>
            </Flex>
          </MenuItem>
        </MenuList>
      </Menu>
    </Flex>
  }

  return (
    <>
      <Flex pt={'120px'} pl={'50px'} fontSize={'16px'}  direction={'column'} width={{base: '500px', 'md': '800px', xl: '1000px'}}>

        {/* template list */}
        <Flex mb={10}>
          <Spacer />
          <Button onClick={onCreatePanelOpen} variant={'outline'} bg={'none'}>
            Add New Template
          </Button>
        </Flex>

        <Text hidden={emailTemplates?.length > 0}>Email templates not found. Please add a new template.</Text>
        
        {
          emailTemplates?.map((template, index) => {
            return <>
              <Flex ml={'50px'} alignItems={'center'} borderTop={index == 0 ? '0.1px solid' : 'none'}
                    borderBottom={'0.1px solid'} borderColor={'gray.200'} padding={5} _hover={{'bg': '#fffadf'}}
                    onMouseEnter={() => setShowTemplateActions(template?.template_uuid)}
                    onMouseLeave={() => setShowTemplateActions(null)}
              >                  
                  <Text fontSize={'16px'} fontWeight={'bold'}>{template?.name}</Text>
                  <Spacer />
                  <Flex hidden={showTemplateActions != template?.template_uuid}>
                    <Button onClick={() => onPreviewPanelOpen(template)} height={6} variant={'ghost'} bg={'none'} _hover={{bg: 'none'}}>Preview</Button>
                    <Button onClick={() => onEditPanelOpen(template)} height={6} variant={'ghost'} bg={'none'} _hover={{bg: 'none'}}>Edit</Button>
                    { TemplateDeleteButton(template) }
                  </Flex>
              </Flex>
            </>
          })
        }

      {/* preview template */}
      <Drawer trapFocus={false} disableEnforceFocus={true} size={'xl'} isOpen={isTemplatePreviewOpen} onClose={onTemplatePreviewClose}>
        <DrawerContent pl={5} fontSize={'16px'}  overflow={'auto'} pt={10}>
          <DrawerHeader>Preview Template - {templateForm.name}</DrawerHeader>
          <DrawerCloseButton />
          <DrawerBody>
            <Flex mt={10} direction={'column'}>
                <Text as={'b'} borderBottom={'0.1px solid'} borderBottomColor={'gray.200'}>Subject</Text>
                <Text mt={2}>{templateForm.subject}</Text>
            </Flex>

            <Flex direction={'column'} mt={10}>
            <Text  as={'b'} borderBottom={'0.1px solid'} borderBottomColor={'gray.200'}>Message</Text>
              <Editor
                  tinymceScriptSrc={'/tinymce/tinymce.min.js'}
                  value={templateForm.content || ""}
                  disabled={true}                  
                  init={{
                      branding: false,
                      statusbar: false,
                      toolbar: false,
                      menubar: false,
                      plugins: 'autoresize',
                      content_style: "body {margin: 0; padding: 0; margin-top: 5px}"
                  }}
              />
            </Flex>  

          </DrawerBody>
          <DrawerFooter>
            <Button colorScheme='teal' onClick={onTemplatePreviewClose}>Close</Button>
          </DrawerFooter>  
        </DrawerContent>
      </Drawer>

      {/* edit template */}
      <Drawer trapFocus={false} disableEnforceFocus={true} size={'xl'} isOpen={isTemplateEditOpen} onClose={onTemplateEditClose}>
        <DrawerContent pl={5}  overflow={'auto'} pt={10}>
          <DrawerHeader>Edit Template - {templateForm.name}</DrawerHeader>
          <DrawerCloseButton />
          <DrawerBody>
            <Flex mt={10} direction={'column'}>
                <Text as={'b'}>Name</Text>
                <Input value={templateForm.name} onChange={e => setTemplateForm({...templateForm, name: e.target.value})} />
            </Flex>

            <Flex mt={10} direction={'column'}>
                <Text as={'b'}>Subject</Text>
                <Input value={templateForm.subject} onChange={e => setTemplateForm({...templateForm, subject: e.target.value})} />
            </Flex>
            
            <Flex direction={'column'} border={'1px solid'} borderColor={'gray.200'} mt={10}>
              <Editor
                  tinymceScriptSrc={'/tinymce/tinymce.min.js'}
                  value={templateForm.content || ""}
                  onEditorChange={content => setTemplateForm({...templateForm, content: content})}
                  onInit={(evt, editor) => (editorRef.current = editor)}            
                  init={{
                    placeholder: 'Enter your email message here',
                    min_height: 500,
                    branding: false,
                    statusbar: false,
                    menubar: 'edit insert view format table tools',
                    promotion: false,
                    plugins: ['advlist', 'anchor', 'autolink', 'autoresize', 'autosave', 'charmap', 'code',
                              'codesample', 'directionality', 'emoticons', 'help', 'importcss', 'insertdatetime',
                              'link', 'lists', 'nonbreaking', 'pagebreak', 'save', 'searchreplace', 'table', 
                              'template', 'visualblocks', 'visualchars', 'wordcount'
                            ],
                    toolbar: 'undo redo | bold italic | alignleft aligncenter alignright alignjustify | indent outdent',
                    contextmenu: false,
                  }}
                  />
            </Flex>

            <VariablesMenu />
          </DrawerBody>
          <DrawerFooter>
            <Button colorScheme='teal' onClick={onTemplateEditClose}>Close</Button>
            <Button isLoading={isTemplateUpdating} loadingText='Saving...' ml={'5'}  colorScheme='blue' onClick={onUpdateTemplate}>
                Update
            </Button>
          </DrawerFooter>  
        </DrawerContent>
      </Drawer>

      {/* create template */}
      <Drawer trapFocus={false} disableEnforceFocus={true} size={'xl'} isOpen={isTemplateCreateOpen} onClose={onTemplateCreateClose}>
        <DrawerContent pl={5} overflow={'auto'} pt={10}>
          <DrawerHeader>Add New Template</DrawerHeader>
          <DrawerCloseButton />
          <DrawerBody>
            <Flex mt={10} direction={'column'}>
            <Text as={'b'}>Name</Text>
              <Input value={templateForm.name} onChange={e => setTemplateForm({...templateForm, name: e.target.value})} />
            </Flex>

            <Flex mt={10} direction={'column'}>
                <Text as={'b'}>Subject</Text>
                <Input value={templateForm.subject} onChange={e => setTemplateForm({...templateForm, subject: e.target.value})} />
            </Flex>

            <Flex direction={'column'} border={'1px solid'} borderColor={'gray.200'} mt={10}>
              <Editor
                  tinymceScriptSrc={'/tinymce/tinymce.min.js'}
                  value={templateForm.content || ""}
                  onEditorChange={content => setTemplateForm({...templateForm, content: content})}
                  onInit={(evt, editor) => (editorRef.current = editor)}              
                  init={{
                    placeholder: 'Enter your email message here',
                    min_height: 500,
                    branding: false,
                    statusbar: false,
                    menubar: 'edit insert view format table tools',
                    promotion: false,
                    plugins: ['advlist', 'anchor', 'autolink', 'autoresize', 'autosave', 'charmap', 'code',
                              'codesample', 'directionality', 'emoticons', 'help', 'importcss', 'insertdatetime',
                              'link', 'lists', 'nonbreaking', 'pagebreak', 'save', 'searchreplace', 'table', 
                              'template', 'visualblocks', 'visualchars', 'wordcount'
                            ],
                    toolbar: 'undo redo | bold italic | alignleft aligncenter alignright alignjustify | indent outdent',
                    contextmenu: false,
                  }}
                  />
            </Flex>

            <VariablesMenu />
          </DrawerBody>
          <DrawerFooter>
            <Button colorScheme='teal' onClick={onTemplateCreateClose}>Close</Button>
            <Button isLoading={isTemplateCreating} loadingText='Creating...' ml={'5'}  colorScheme='blue' onClick={onCreateTemplate}>
                Add
            </Button>
          </DrawerFooter>  
        </DrawerContent>
      </Drawer>

      {/* add calendar url modal */}
      <Modal isOpen={isAddCalendarPanelOpen} onClose={onAddCalendarPanelClose}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>Calendar URL</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                <Flex direction={'column'}>
                    <Text as={'b'}>Provide your availability calendar</Text>
                    <Input value={calendarUrl} onChange={e => setCalendarUrl(e.target.value)} />
                </Flex>
                </ModalBody>
                <ModalFooter>                
                <Button colorScheme='teal' onClick={onAddCalendarPanelClose}>Close</Button>
                <Button ml={'5'} colorScheme='blue' onClick={onAddCalendar} isLoading={isCalendarUrlAdding} loadingText={calendarUrl ? "Updating" : "Adding"}>
                    {calendarUrl ? "Update" : "Add"}
                </Button>
                </ModalFooter>
            </ModalContent>
          </Modal>
      </Flex>
    </>
  );
}

export default EmailTemplateList;
