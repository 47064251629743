import { Text, Flex, Drawer, DrawerContent, DrawerHeader, DrawerCloseButton } from '@chakra-ui/react';

export default function BusinessSurvey(props) {

    const business = props.business;
    const surveryData = JSON.parse(business.survey || "{}")?.sections || [];

  return (
    <Drawer size={'xl'} isOpen={props.isSurveyPanelOpen} placement="right" onClose={props.onSurveyPanelClose}>
      <DrawerContent pl={5} >
        <DrawerHeader mt={5}>{business?.business_name} Survey</DrawerHeader>
        <Flex direction={'column'} p={10}>

        {surveryData.length == 0 &&  <Text>Survey not found</Text>}

        {surveryData?.map((data, index) => {
            return <>
                <Flex direction={'column'} borderBottomWidth={'1px'} mb={10} pb={10} fontSize={'16px'}>
                    <Flex mb="2">
                        <Text mr="2" fontWeight={'600'}>Q{index + 1}: </Text>
                        <Text fontWeight={'600'}>{data?.questions}</Text>
                    </Flex>
                    <Flex>{data?.options?.join(', ')}</Flex>
                    {data?.other_value && data?.options?.length == 0 &&
                    <Flex alignItems={'center'} my="2">
                        <Text size="sm" mr="2" mb="2">{data?.other_value}</Text>
                    </Flex>
                    }
                    {!data?.other_value && data?.options?.length == 0 &&
                    <Flex alignItems={'center'} my="2">
                        <Text size="sm" mr="2" mb="2">{data?.selected_option}</Text>
                    </Flex>
                    }
                </Flex>
            </>
        })}

        </Flex>
        <DrawerCloseButton />  
      </DrawerContent>
    </Drawer> 
  );
}