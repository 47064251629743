import React, { useEffect, useState } from 'react';
import {useToast, Text, Button, useDisclosure, Flex, Input, Menu, MenuButton, MenuList, MenuItem,
    Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, ModalFooter,
} from '@chakra-ui/react';
import Select from 'react-select';
import { CkeditorUtil } from '../../utils/ckeditor';
import { getEmailId } from '../../utils/localStorageIndex';
import { createEmailTemplate, getEmailTemplateList, updateEmailTemplate } from '../../services/emailServices';
import { isObjectEmpty } from '../../utils/common';

export default function EmailTemplateSelection(props) {
  const {containerWidth='60%', containerMarginTop='10px'} = props;
  const toast = useToast()
  const emailId = getEmailId()
  const {onOpen: onCreateTemplatePanelOpen, onClose: onCreateTemplatePanelClose, isOpen: isCreateTemplatePanelOpen} = useDisclosure()
  const [templateForm, setTemplateForm] = useState({
    user_email: emailId,
    template_uuid: null,
    name: null,
    subject: null,
    content: null
  })
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [emailTemplates, setEmailTemplates] = useState([])
  const [templateSelectOptions, setTemplateSelectOptions] = useState([])
  const [isTemplateCreating, setIsTemplateCreating] = useState(false)
  const [isTemplateUpdating, setIsTemplateUpdating] = useState(false)

  const templateDropDownStyles = {
    control: (baseStyles, state) => ({
      ...baseStyles,
      width: '320px'
    }),
    menu: styles => ({ ...styles, zIndex: 999 }),
    option: (styles, state) => ({
      ...styles,
      cursor: 'pointer',
    }),
  }

  useEffect(() => {
    getTemplateList()
  }, [])

  useEffect(() => {
    const templateObject = getTemplateObjectFromSelectedTemplate()
    setTemplateForm({...templateForm, ...templateObject})
    props.setSelectedTemplate(templateObject)
  }, [selectedTemplate])

  useEffect(() => {
    if (templateForm?.name){
      templateSelectOptions?.map(option => {
        if (option?.label == templateForm?.name){
          setSelectedTemplate(option)
        }
      })
    }
  }, [templateSelectOptions]) // everytime template options changes, check if new template is created. set that as selected

  useEffect(() => {
    const updatedTemplate = {
      ...getTemplateObjectFromSelectedTemplate(),
      user_email: emailId,
      content: props.emailBody,
    }
    setTemplateForm({...templateForm, ...updatedTemplate})
  }, [props.emailBody])

  useEffect(() => {
    const updatedTemplate = {
      ...getTemplateObjectFromSelectedTemplate(),
      user_email: emailId,
      subject: props.subject,
    }
    setTemplateForm({...templateForm, ...updatedTemplate})
  }, [props.subject])

  const getTemplateObjectFromSelectedTemplate = () => {
    let template = {}
    emailTemplates.map(temp => {
      if (temp?.template_uuid == selectedTemplate?.value){
        template = temp
      }
    })
    return template
  }

  const getTemplateList = () => {
    const data = {
      user_email: emailId
    }
    getEmailTemplateList(data).then(res => {
      if (res?.result){
        setEmailTemplates(res?.data)
        const templateSelectOptions = res?.data?.map(template => {
          return {value: template?.template_uuid, label: template?.name}
        })
        setTemplateSelectOptions(templateSelectOptions)
      }
    }).catch(err => {
      toast({
        title: "Unable to get email templates",
        description: err.toString(),
        duration: 5000
      })
    })
  }

  const validateTemplateForm = () => {
    if (!templateForm.name){
      toast({
        title: "Template name is required",
        status: 'error',
        duration: 3000
      })
      return false
    }
    if (!templateForm.subject){
      toast({
        title: "Template subject is required",
        status: 'error',
        duration: 3000
      })
      return false
    }
    console.log(templateForm)
    if (!templateForm.content){
      toast({
        title: "Template email body is required",
        status: 'error',
        duration: 3000
      })
      return false
    }

    return true
  }

  const onUpdateTemplate = () => {
    if (!validateTemplateForm()){
      return
    }
    setIsTemplateUpdating(true)
    const updatedForm = {...templateForm, content: CkeditorUtil.getContentWithLineStyles(templateForm.content)}
    updateEmailTemplate(updatedForm).then(res => {
      setIsTemplateUpdating(false)
      if (res?.result){
        getTemplateList()
        toast({
          title: "Email template updated successfully",
          status: 'success',
          duration: 3000
        })
      }else{
        toast({
          title: "Unable to update template",
          description: res?.message,
          status: 'error',
          duration: 5000
        })
      }
    }).catch(err => {
      setIsTemplateUpdating(false)
      toast({
        title: "Unable to update template",
        description: err.toString(),
        status: 'error',
        duration: 5000
      })
    })
  }

  const onCreateTemplate = () => {
    if (!validateTemplateForm()){
      return
    }
    setIsTemplateCreating(true)
    const updatedForm = {...templateForm, content: CkeditorUtil.getContentWithLineStyles(templateForm.content)}
    createEmailTemplate(updatedForm).then(res => {
      setIsTemplateCreating(false)
      if (res?.result){
        getTemplateList()  
        onCreateTemplatePanelClose()
        toast({
          title: "Email template added successfully",
          status: 'success',
          duration: 3000
        })
      }else{
        toast({
          title: "Unable to add template",
          description: res?.message,
          status: 'error',
          duration: 5000
        })
      }
    }).catch(err => {
      setIsTemplateCreating(false)
      toast({
        title: "Unable to add template",
        description: err.toString(),
        status: 'error',
        duration: 5000
      })
    })
  }

  const openCreateTemplatePanel = () => {
    if (!templateForm?.subject){
      toast({
        title: "Template subject is required",
        status: 'error',
        duration: 3000
      })
      return
    }

    if (!templateForm?.content){
      toast({
        title: "Template email body is required",
        status: 'error',
        duration: 3000
      })
      return
    }

    onCreateTemplatePanelOpen()
  }

  const SaveTemplateMenu = () => {
    return <Flex ml={5}>
      <Menu>
        <MenuButton as={Button} width={'180px'} fontWeight={'normal'} variant={'outline'} borderRadius={'8px'}>
          Save Template
        </MenuButton>
        <MenuList width={'150px'} zIndex={'9999'}>
          <MenuItem _hover={{'bg': 'gray.200'}} onClick={onUpdateTemplate} 
            isLoading={isTemplateUpdating} loadingText={'Updating'} isDisabled={isObjectEmpty(selectedTemplate)}
            >
            <Flex direction={'column'}>
              <Text>Replace Current Template</Text>
            </Flex>
          </MenuItem>
          <MenuItem _hover={{'bg': 'gray.200'}} onClick={openCreateTemplatePanel} isLoading={isTemplateCreating} loadingText={'Creating...'}>
            <Flex direction={'column'}>
              <Text>Save as New Template</Text>
            </Flex>
          </MenuItem>
        </MenuList>
      </Menu>
    </Flex>
  }


  return (
        <Flex width={containerWidth} mt={containerMarginTop}>

            <Flex direction={'column'}>
                <Text as='b'>Template</Text>
                <Flex alignItems={'center'}>
                    <Select
                        placeholder= 'Select template'
                        value={selectedTemplate}
                        onChange={setSelectedTemplate}
                        options={templateSelectOptions}
                        styles={templateDropDownStyles}
                    />
                    
                    <SaveTemplateMenu />
                </Flex>
            </Flex>
          
            {/* create new template form */}
            <Modal isOpen={isCreateTemplatePanelOpen} onClose={onCreateTemplatePanelClose}>
                <ModalOverlay />
                <ModalContent>
                <ModalHeader>Save as New Template</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <Flex direction={'column'}>
                    <Text as={'b'}>Template Name</Text>
                    <Input value={templateForm?.name} onChange={e => setTemplateForm({...templateForm, name: e.target.value})} />
                    </Flex>
                </ModalBody>
                <ModalFooter>                
                    <Button colorScheme='teal' onClick={onCreateTemplatePanelClose}>Close</Button>
                    <Button isLoading={isTemplateCreating} loadingText='Creating...' ml={'5'}  colorScheme='blue' onClick={onCreateTemplate}>
                        Create
                    </Button>
                </ModalFooter>
                </ModalContent>
            </Modal>
        </Flex>
    )
}
