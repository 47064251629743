import React, { useEffect, useState } from 'react';
import {Flex, Button, useToast} from '@chakra-ui/react';
import { useLocation } from 'react-router-dom';
import { isObjectEmpty, validateScreeningQuestions} from '../../../../utils/common';
import { submitScreeningQuestions } from '../../../../services/questionServices';
import { getEmailId } from '../../../../utils/localStorageIndex';
import JobApplicationForm from '../../../common/JobApplicationForm';

export default function JobApplyPage(props) {
    const { state } = useLocation();
    const [questionErrors, setQuestionErrors] = useState({})
    const [screeningQuestions, setScreeningQuestions] = useState([])
    const [isFormSubmitting, setIsFormSubmitting] = useState(false);
    const toast = useToast();
	const [selectedJob, setSelectedJob] = useState({});

    useEffect(() => {
        if (state && !isObjectEmpty(state?.job)){
            setSelectedJob(state.job)
        }
    }, [state])

    const renderFormActionButtons = () => {
        return (
            <Flex mt={10} ml={{'base': '50%', 'xl': '42%', '2xl': '38%'}}>
                <Button isLoading={isFormSubmitting} loadingText='Saving form...' colorScheme='blue' onClick={saveForm}>
                    Save Form
                </Button>
            </Flex>
        )
    }

    const saveForm = () => {
        const errors = validateScreeningQuestions(screeningQuestions)
        if (!isObjectEmpty(errors)){
            setQuestionErrors({...questionErrors, ...errors})
            toast({
                title: "Please fix errors",
                status: 'error',
                duration: 3000
            })
            return false
        }

        const questionsToSubmit = []
        screeningQuestions.map((question, index) => {
            const q = {
                'question_uuid': question.question_uuid,
                'question_type': question.question_type.code,
                'question_text': question.question_text,

                // clean any unset option values
                'question_options': question.question_options.filter(option => option ? option : false),
                'question_disqualify_filters': question.question_disqualify_filters,
                'question_is_mandatory': question.question_is_mandatory,
                'is_default': question.is_default,
            }
            questionsToSubmit.push(q)
        })

        setQuestionErrors({})
        setIsFormSubmitting(true)

        const formData = {
            'job_uuid': selectedJob.job_uuid,
            'user_email': getEmailId(),
            'questions': questionsToSubmit
        }

        submitScreeningQuestions(formData)
        .then(res => {
            if (res?.result){
                toast({
                    title: 'Questions added succesfully', 
                    status: 'success', 
                    isClosable: true, 
                    duration: 3000
                });
                setQuestionErrors({})
                setIsFormSubmitting(false)
                return
            }else{
                toast({
                    title: 'Unable to submit screening questions',
                    description: res?.message,
                    status: 'error', 
                    duration: 5000
                });
            }

            const errors = questionErrors
            if (res.data[0].error.question_uuid){
                errors[res.data[0].error.question_uuid] = res.message
            }else if (res.data[0].error.question_text){
                errors[res.data[0].error.question_text] = res.message
            }
            setQuestionErrors(errors)
            setIsFormSubmitting(false)
        })
        .catch(err => {
            toast({
                title: 'Unable to add screening questions', 
                description: err.toString(),
                status: 'error', 
                isClosable: true, 
                duration: 3000
            });
            setIsFormSubmitting(false)
        })
    }

    if (isObjectEmpty(selectedJob)){
        return <Flex></Flex>
    }

    return (
        <Flex direction={'column'} mb={20} pt={{ sm: '125px', lg: '75px' }} w={{'xl': '1200px', '2xl': '1400px'}}>            

            <JobApplicationForm
                job={selectedJob}
                setScreeningQuestions={setScreeningQuestions} 
                setQuestionErrors={setQuestionErrors} 
                questionErrors={questionErrors}
                adminSettings={true}
            />
            
            {renderFormActionButtons()}
        </Flex>
    )

}