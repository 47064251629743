import { Box, Heading, Text, Link } from '@chakra-ui/react';
import React from 'react';
import Header from '../../constants/Header';
import Footer from '../../constants/Footer';

function PrivacyPolicy() {
  return (
    <>
      <Header />
      <Box maxW="800px" mx="auto" my={8} p={4}>
        <Heading as="h1" size="lg" mb={4}>
          Privacy Note
        </Heading>
        <Text mb={4} fontSize={'14px'}>
          Welcome to our Privacy Policy page. This page is used to inform
          website visitors regarding our policies with the collection, use, and
          disclosure of Personal Information if anyone decides to use our
          Service.
        </Text>
        <Text mb={4} fontSize={'14px'}>
          If you choose to use our Service, then you agree to the collection and
          use of information in relation to this policy. The Personal
          Information that we collect is used for providing and improving the
          Service. We will not use or share your information with anyone except
          as described in this Privacy Policy.
        </Text>
        <Text mb={4} fontSize={'14px'}>
          The terms used in this Privacy Policy have the same meanings as in our
          Terms and Conditions, which is accessible at our website unless
          otherwise defined in this Privacy Policy.
        </Text>
        <Heading as="h2" size="md" mt={6} mb={4}>
          Information Collection and Use
        </Heading>
        <Text mb={4} fontSize={'14px'}>
          For a better experience, while using our Service, we may require you
          to provide us with certain personally identifiable information,
          including but not limited to your name, phone number, and email
          address. The information that we collect will be used to contact or
          identify you.
        </Text>
        <Text mb={4} fontSize={'14px'}>
          We want to inform you that whenever you visit our Service, we collect
          information that your browser sends to us that is called Log Data.
          This Log Data may include information such as your computer's Internet
          Protocol ("IP") address, browser version, pages of our Service that
          you visit, the time and date of your visit, the time spent on those
          pages, and other statistics.
        </Text>
        <Heading as="h2" size="md" mt={6} mb={4}>
          Cookies
        </Heading>
        <Text mb={4} fontSize={'14px'}>
          Cookies are files with a small amount of data that are commonly used
          as anonymous unique identifiers. These are sent to your browser from
          the website that you visit and are stored on your computer's hard
          drive.
        </Text>
        <Text mb={4} fontSize={'14px'}>
          Our website uses these "cookies" to collection information and to
          improve our Service. You have the option to either accept or refuse
          these cookies and know when a cookie is being sent to your computer.
          If you choose to refuse our cookies, you may not be able to use some
          portions of our Service.
        </Text>
        <Heading as="h2" size="md" mt={6} mb={4}>
          Service Providers
        </Heading>
        <Text mb={4} fontSize={'14px'}>
          We may employ third-party companies and individuals due to the
          following reasons:
          <ul>
            <li>To facilitate our Service;</li>
            <li>To provide the Service on our behalf;</li>
            <li>To perform Service-related services; or</li>
            <li>To assist us in analyzing how our Service is used.</li>
          </ul>
        </Text>
        <Text mb={4} fontSize={'14px'}>
          We want to inform our Service users that these third parties have
          access to your Personal Information. The reason is to perform the
          tasks assigned to them on our behalf. However, they are obligated not
          to disclose or use the information for any other purpose.
        </Text>
        <Heading as="h2" size="md" mt={6} mb={4}>
          Security
        </Heading>
        <Text mb={4} fontSize={'14px'}>
          We value your trust in providing us your Personal Information, thus we
          are striving to use commercially acceptable means of protecting it.
          But remember that no method of transmission over the internet, or
          method of electronic storage is 100% secure and reliable, and we
          cannot guarantee its absolute security.
        </Text>
        <Heading as="h2" size="md" mt={6} mb={4}>
          Links to Other Sites
        </Heading>
        <Text mb={4} fontSize={'14px'}>
          Our Service may contain links to other sites. If you click on a
          third-party link, you will be directed to that site. Note that these
          external sites are not operated by us. Therefore, we strongly advise
          you to review the Privacy Policy of these websites. We have no control
          over, and assume no responsibility for the content, privacy policies,
          or practices of any third-party sites or services.
        </Text>
        <Heading as="h2" size="md" mt={6} mb={4}>
          Changes to This Privacy Policy
        </Heading>
        <Text mb={4} fontSize={'14px'}>
          We may update our Privacy Policy from time to time. Thus, you are
          advised to review this page periodically for any changes. We will
          notify you of any changes by posting the new Privacy Policy on this
          page.
        </Text>
        <Text mb={4} fontSize={'14px'}>
          This policy is effective as of [Date].
        </Text>
        <Heading as="h2" size="md" mt={6} mb={4}>
          Contact Us
        </Heading>
        <Text mb={4} fontSize={'14px'}>
          If you have any questions or suggestions about our Privacy Policy, do
          not hesitate to contact us.
        </Text>
        <Link color="blue.500" href="mailto:contact@talentstrap.com" isExternal>
          contact@talentstrap.com
        </Link>
      </Box>
      <Footer />
    </>
  );
}

export default PrivacyPolicy;
