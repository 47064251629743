import React, { useEffect, useState } from 'react';
import {ChevronDownIcon, ChevronUpIcon} from '@chakra-ui/icons'
import { Badge, Box, Flex, IconButton, Image, Spinner, Text, useToast} from '@chakra-ui/react';
import { getEmailId } from '../../../../utils/localStorageIndex';
import { fetchCandidateTimeline } from '../../../../services/jobTemplateService';
import moment from 'moment';
import { toTitleCase } from '../../../../utils/common';
import { Editor } from '@tinymce/tinymce-react';


const CandidateTimeline = (props) => {
    const emailId = getEmailId()
    const toast = useToast()
    const [timeline, setTimeline] = useState([])
    const [isTimelineLoading, setIsTimelineLoading] = useState(false);
    const [showTimelineItemDetails, setShowTimelineItemDetails] = useState([])

    useEffect(() => {
        getCandidateTimeline()
    }, [props.candidate])
    
    const getCandidateTimeline = () => {
        setIsTimelineLoading(true);
        let obj = {
            email: emailId,
            candidate_uuid: props?.candidate.candidate_uuid,
        };
        fetchCandidateTimeline(obj)
        .then(res => {
            setIsTimelineLoading(false);
            if (res?.result) {
                setTimeline(res?.data)
            }else{
                toast({
                    title: res?.message,
                    description: res?.error,
                    status: 'error',
                    duration: 3000,
                });
            }
        })
        .catch(err => {
            console.log(err);
            setIsTimelineLoading(false);
            toast({
                title: "Unable to load candidate timeline",
                description: err.toString(),
                status: 'error',
                duration: 3000,
            });
        });
    };

    const showTimeItemDetails = (itemId) => {
        let showedItems = showTimelineItemDetails
        showedItems.push(itemId)
        setShowTimelineItemDetails([... new Set([...showedItems])])
    }

    const hideTimeItemDetails = (itemId) => {
        let showedItems = showTimelineItemDetails
        showedItems = showedItems.filter(id => id != itemId)
        setShowTimelineItemDetails(showedItems)
    }

    const renderTimelineDetails = (item) => {
        if (item.action == 'email sent'){
            return <>
                <Editor
                        tinymceScriptSrc={'/tinymce/tinymce.min.js'}
                        value={item?.details || ""}
                        disabled={true}
                        init={{
                            branding: false,
                            statusbar: false,
                            toolbar: false,
                            menubar: false,
                            plugins: 'autoresize',
                            content_style: "body {margin: 0; padding: 0}"
                        }}
                />
            </>
        }

        return <Text padding={'10px 0px'}><pre style={{'fontFamily': 'inherit', 'textWrap': 'wrap'}} >{item?.details}</pre></Text>
    }
    
    const renderTimelineItem = (timelineItem) => {
        const user = timelineItem?.user
        const areDetailsHidden = !showTimelineItemDetails?.includes(timelineItem.timeline_id)     

        return <>
                <Flex pb={5} borderBottomWidth={'0.1px'} borderBottomColor={'gray.200'}>
                    
                    <Flex pt={2} direction={'column'} mr={3} justifyItems={'center'} alignItems={'center'}>
                            <Badge hidden={user?.photo} color={'white'} fontSize={'15px'} width={'30px'} height={'30px'} bg={'purple.300'} borderRadius={'30px'}>
                                <span>{user?.first_name.substr(0, 1)}</span>
                            </Badge>
                            <Flex hidden={!user?.photo} h={'30px'} w={'30px'}>
                                <Box h={'30px'} w={'30px'}>
                                    <Image h="100%" w="100%" objectFit="cover" src={user?.photo} borderRadius={'30px'}  />
                                </Box>
                            </Flex>                        
                    </Flex>

                    <Flex direction={'column'} width={'100%'}>                
                        <Flex>     
                            <Text mt={1} color={'gray.600'}>
                                {toTitleCase(timelineItem?.action)} by <b>{user?.first_name} {user?.last_name}</b>
                            </Text>
                            
                            <Flex hidden={!timelineItem?.details}>
                                <IconButton  hidden={!areDetailsHidden} ml={2} mt={1} size={'xs'} fontSize={'10px'} bg={'none'}
                                            _hover={{'bg': 'none', 'cursor': 'pointer'}} as={ChevronDownIcon}
                                            onClick={() => showTimeItemDetails(timelineItem.timeline_id)}
                                />
                                <IconButton  hidden={areDetailsHidden} ml={2} mt={1} size={'xs'} fontSize={'10px'} bg={'none'}
                                            _hover={{'bg': 'none', 'cursor': 'pointer'}} as={ChevronUpIcon}
                                            onClick={() => hideTimeItemDetails(timelineItem.timeline_id)}
                                /> 
                            </Flex>      
                        </Flex>

                        <Flex hidden={areDetailsHidden}>
                            {renderTimelineDetails(timelineItem)}
                        </Flex>
                        
                        <Text color='gray.500'>{moment.utc(timelineItem?.datetime_created).fromNow()}</Text>
                    </Flex>
                </Flex>
            </>

      }

    return (               
        <Flex w={'100%'} direction={'column'} fontSize={'16px'}>
            
            <Spinner hidden={!isTimelineLoading} />

            <Text textAlign={'center'} hidden={timeline?.length > 0 || isTimelineLoading}>
                No logged activity found for this candidate.
            </Text>

            {timeline?.map(timelineItem => {
                return (
                    <Flex direction={'column'} mt={4}>
                        {renderTimelineItem(timelineItem)}
                    </Flex>
                )
                })
            }

        </Flex>
    );
}

export default CandidateTimeline;
