import { useNavigate } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getEmailId } from '../../../../../utils/localStorageIndex';
import InputField from '../../../../../components/fields/InputField';
import { setJobWizardStatusLevel } from '../../../../../store/actions/topNotificationActions';
import {
  addjobSynopsisWorkflow,
  saveJobWizardTwoData,
  fetchJobWorkflowDetails,
} from '../../../../../services/jobTemplateService';
import { BsPlus, BsCheck } from 'react-icons/bs';
import {
  Box,
  Stack,
  Icon,
  Text,
  Flex,
  Button,
  useToast,
  SimpleGrid,
  useColorModeValue,
  Select,
} from '@chakra-ui/react';
import { educationRequiredOptions } from '../../../../../constants/jobFunctionRequirements';

function JobIntakeWizard2(props) {
  // Todo:Fetch Job Id from store
  const wizardDetails = useSelector(store => store.wizardHeader);
  const job_uuid = wizardDetails?.job_uuId;
  const toast = useToast();
  const emailId = getEmailId();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, isLoading] = useState(false);
  const [jobSynopsisText, setJobSynopsisText] = useState('');
  const [saveCompleteFlag, setSaveCompleteFlag] = useState(false);
  const textColorPrimary = useColorModeValue('secondaryGray.900', 'white');
  const pillTextColor = useColorModeValue('navy.700', 'gray.200');
  const [selectedEducationRequired, setSelectedEducationRequired] = useState(
    educationRequiredOptions
  );
  const [educationRequired, setEducationRequired] = useState('Not Applicable');

  const jobTypeArr = [
    { name: 'Full-Time', isChecked: false },
    { name: 'Part-Time', isChecked: false },
    { name: 'Contract', isChecked: false },
    { name: 'Temporary', isChecked: false },
    { name: 'Internship', isChecked: false },
  ];
  const travelTypeArr = [
    { name: 'No Travel', isChecked: false },
    { name: 'Up to 25% travel', isChecked: false },
    { name: 'Up to 50% travel', isChecked: false },
    { name: 'Up to 75% travel', isChecked: false },
    { name: 'Up to 100% travel', isChecked: false },
    { name: 'Others', isChecked: false },
  ];
  const scheduleTypeArr = [
    { name: 'Monday to Friday', isChecked: false },
    { name: 'On call', isChecked: false },
    { name: 'Weekends as needed', isChecked: false },
    { name: '8 hour shift', isChecked: false },
    { name: 'Others', isChecked: false },
  ];
  const [travelOtherValue, setTravelOtherValue] = useState('');
  const [renderJobArr, setRenderJobArr] = useState(jobTypeArr);
  const [scheduleOtherValue, setScheduleOtherValue] = useState('');
  const [renderTravelArr, setRenderTravelArr] = useState(travelTypeArr);
  const [renderScheduleArr, setRenderScheduleArr] = useState(scheduleTypeArr);

  useEffect(() => {
    if (job_uuid.length) {
      fetchJobFlowData();
    }
  }, []);
  // Todo:Fetch Job Details
  const fetchJobFlowData = () => {
    let objBody = {
      email: emailId,
      job_uuid: job_uuid,
    };
    isLoading(true);
    fetchJobWorkflowDetails(objBody)
      .then(res => {
        isLoading(false);
        if (res.result && res.data.length) {
          setJobSynopsisText(res.data[0].job_title_other);
        } else {
          isLoading(false);
        }
      })
      .catch(err => {
        isLoading(false);
      });
  };

  // Todo:Save and completed trigger event
  useEffect(() => {
    if (saveCompleteFlag) {
      saveJobTitleWorkFlow();
    }
  }, [saveCompleteFlag]);

  // Todo:Api call to store data
  const saveJobTitleWorkFlow = () => {
    if (job_uuid == undefined || job_uuid === null || job_uuid.trim().length == 0){
      toastFunction("Please start from first wizard tab by entering Job Title..", 'error');
    } else {
      let objBody = {
        email: emailId,
        job_uuid: job_uuid,
        jobType: returnStringArr(renderJobArr),
        travel: returnStringArr(renderTravelArr),
        schedule: returnStringArr(renderScheduleArr),
        travelOthers: travelOtherValue,
        scheduleOthers: scheduleOtherValue,
        education: educationRequired
      };
      console.log(objBody, 'objBody');
      isLoading(true);
      // addjobSynopsisWorkflow(objBody)
      saveJobWizardTwoData(objBody)
        .then(res => {
          isLoading(false);
          if (res.result) {
            if (saveCompleteFlag) {
              // navigate(`/business/joblist`);
              navigate(`/business/editJobs`, {
                state: {
                  job_uuid: job_uuid
                }
              });
            } else {
              dispatch(setJobWizardStatusLevel(3));
              props.onDataUpdate('employmentType');
            }
          }
        })
        .catch(err => {
          isLoading(false);
          toastFunction(err?.response?.data?.detail[0]?.msg, 'error');
        });
      }
  };

  const returnStringArr = data => {
    return data
      .filter(item => item.isChecked)
      .map(item => item.name)
      .join(', ');
  };

  const toastFunction = (message, status) => {
    return toast({
      title: message,
      status: status,
      isClosable: true,
      duration: 3000,
    });
  };

  // Todo:High-Order Fuction to reset check buttons
  const setCheckedValue = (data, arrValue, setterFunction) => {
    let cloneData = [...arrValue];
    cloneData?.map((subValue, index) => {
      if (subValue?.name === data) {
        subValue.isChecked = !subValue.isChecked;
      }
    });
    setterFunction(cloneData);
  };

  // Todo:Render Job Type UI
  const renderJobTypeData = renderJobArr?.map((data, index) => (
    <Button
      key={index}
      color={data?.isChecked ? 'gray.600' : pillTextColor  }
      bg={data?.isChecked ? 'gray.300' : ''}
      borderWidth={'1px'}
      borderRadius={'20px'}
      size="sm"
      mr="2"
      onClick={() => {
        setCheckedValue(data.name, renderJobArr, setRenderJobArr);
      }}
    >
      <Icon h="24px" w="24px" as={data?.isChecked ? BsCheck : BsPlus}></Icon> {data?.name}
    </Button>
  ));

  // Todo:Render Travel Type UI
  const renderTravelTypeData = renderTravelArr?.map((data, index) => (
    <>
      <Button
        key={index}
        color={data?.isChecked ? 'gray.600' : pillTextColor  }
        bg={data?.isChecked ? 'gray.300' : ''}
        borderWidth={'1px'}
        borderRadius={'20px'}
        size="sm"
        mr="2"
        mb="2"
        onClick={() => {
          setCheckedValue(data.name, renderTravelArr, setRenderTravelArr);
        }}
      >
        <Icon h="24px" w="24px" as={data?.isChecked ? BsCheck : BsPlus}></Icon> {data?.name}
      </Button>
      {data?.name === 'Others' && data?.isChecked ? (
        <InputField
          borderRadius="5px"
          mb="0px"
          width="100%"
          placeholder="Enter Others Value"
          label="Enter Others Value"
          value={travelOtherValue}
          onChange={e => {
            setTravelOtherValue(e.target.value);
          }}
          color="black"
        />
      ) : null}
    </>
  ));

  // Todo:Render Schedule Type UI
  const renderScheduleTypeData = renderScheduleArr?.map((data, index) => (
    <>
      <Button
        key={index}
        color={data?.isChecked ? 'gray.600' : pillTextColor  }
        bg={data?.isChecked ? 'gray.300' : ''}
        borderWidth={'1px'}
        borderRadius={'20px'}
        size="sm"
        mr="2"
        mb="2"
        onClick={() => {
          setCheckedValue(data.name, renderScheduleArr, setRenderScheduleArr);
        }}
      >
        <Icon h="24px" w="24px" as={data?.isChecked ? BsCheck : BsPlus}></Icon> {data?.name}
      </Button>
      {data?.name === 'Others' && data?.isChecked ? (
        <InputField
          borderRadius="5px"
          mb="0px"
          width="100%"
          placeholder="Enter Others Value"
          label="Enter Others Value"
          value={scheduleOtherValue}
          onChange={e => {
            setScheduleOtherValue(e.target.value);
          }}
          color="black"
        />
      ) : null}
    </>
  ));

  const renderEducationOption = selectedEducationRequired?.map(
    (data, index) => (
      <option key={data?.id} value={data?.name}>
        {data?.name}
      </option>
    )
  );

  return (
    <>
      <Flex direction="column" w="100%">
        <Stack direction="column" spacing="20px" mt="3">
          <SimpleGrid>
            <Text
              color={textColorPrimary}
              fontSize="sm"
              fontWeight="bold"
              _hover={{ cursor: 'pointer' }}
            >
              Job type
            </Text>
            <Flex mt="2">{renderJobTypeData}</Flex>
          </SimpleGrid>
          <SimpleGrid>
            <Text
              color={textColorPrimary}
              fontSize="sm"
              fontWeight="bold"
              _hover={{ cursor: 'pointer' }}
            >
              Travel requirement
            </Text>
            <Flex mt="2" flexWrap={'wrap'}>
              {renderTravelTypeData}
            </Flex>
          </SimpleGrid>
          <SimpleGrid mt="0px">
            <Text
              color={textColorPrimary}
              fontSize="sm"
              fontWeight="bold"
              _hover={{ cursor: 'pointer' }}
            >
              Schedule
            </Text>
            <Flex mt="2" flexWrap={'wrap'}>
              {renderScheduleTypeData}
            </Flex>
          </SimpleGrid>

          <SimpleGrid mt="0px">
            <Text
              color={textColorPrimary}
              fontSize="sm"
              fontWeight="bold"
              _hover={{ cursor: 'pointer' }}
            >
              Education
            </Text>
            <Flex flexWrap={'wrap'}>
              <Select
                fontSize={'sm'}
                size="md"
                value={educationRequired}
                onChange={e => {
                  setEducationRequired(e.target.value);
                }}
              >
                {renderEducationOption}
              </Select>
            </Flex>
          </SimpleGrid>
        </Stack>

        <Flex justify="space-between" mt="24px">
          <Button
            variant="light"
            fontSize="sm"
            borderRadius="16px"
            w={{ base: '128px', md: '148px' }}
            h="46px"
            onClick={() => {
              props.onDataUpdate('titleTab');
              dispatch(setJobWizardStatusLevel(1));
            }}
          >
            Prev
          </Button>
          <Box>
            <Button
              variant="outline"
              fontSize="sm"
              borderRadius="16px"
              w={{ base: '160px', md: '200px' }}
              h="46px"
              ms="auto"
              onClick={() => {
                setSaveCompleteFlag(!saveCompleteFlag);
              }}
              isDisabled={loading ? true : false}
              isLoading={loading ? true : false}
            >
              Save And Complete Later
            </Button>
            <Button
              variant="darkBrand"
              fontSize="sm"
              borderRadius="16px"
              w={{ base: '128px', md: '148px' }}
              ml="10px"
              h="46px"
              isDisabled={loading ? true : false}
              isLoading={loading ? true : false}
              onClick={() => {
                saveJobTitleWorkFlow();
              }}
            >
              Next
            </Button>
          </Box>
        </Flex>
      </Flex>
    </>
  );
}

export default JobIntakeWizard2;
