export const jobIndustriesJsonList = [
  {
    Code: '13-2011.00',
    Occupation: 'Accountants and Auditors',
    'Projected Growth (2021-2031)': 'Average',
    'Projected Job Openings (2021-2031)': 136400,
    Industries: 'Professional, Scientific, and Technical Services (31%)',
  },
  {
    Code: '27-2011.00',
    Occupation: 'Actors',
    'Projected Growth (2021-2031)': 'Faster than average',
    'Projected Job Openings (2021-2031)': 7000,
    Industries:
      'Information (35%), Arts, Entertainment, and Recreation (11%), Educational Services (10%)',
  },
  {
    Code: '15-2011.00',
    Occupation: 'Actuaries',
    'Projected Growth (2021-2031)': 'Much faster than average',
    'Projected Job Openings (2021-2031)': 2400,
    Industries:
      'Finance and Insurance (74%), Professional, Scientific, and Technical Services (13%)',
  },
  {
    Code: '29-1291.00',
    Occupation: 'Acupuncturists',
    'Projected Growth (2021-2031)': 'Average',
    'Projected Job Openings (2021-2031)': 1700,
    Industries: 'Health Care and Social Assistance (29%)',
  },
  {
    Code: '51-9191.00',
    Occupation: 'Adhesive Bonding Machine Operators and Tenders',
    'Projected Growth (2021-2031)': 'Slower than average',
    'Projected Job Openings (2021-2031)': 1600,
    Industries: 'Manufacturing (92%)',
  },
  {
    Code: '23-1021.00',
    Occupation: 'Administrative Law Judges, Adjudicators, and Hearing Officers',
    'Projected Growth (2021-2031)': 'Decline',
    'Projected Job Openings (2021-2031)': 600,
    Industries: 'Government (100%)',
  },
  {
    Code: '11-3012.00',
    Occupation: 'Administrative Services Managers',
    'Projected Growth (2021-2031)': 'Average',
    'Projected Job Openings (2021-2031)': 22000,
    Industries:
      'Government (15%), Educational Services (14%), Professional, Scientific, and Technical Services (12%), Health Care and Social Assistance (12%)',
  },
  {
    Code: '25-3011.00',
    Occupation:
      'Adult Basic Education, Adult Secondary Education, and English as a Second Language Instructors',
    'Projected Growth (2021-2031)': 'Decline',
    'Projected Job Openings (2021-2031)': 4700,
    Industries: 'Educational Services (73%)',
  },
  {
    Code: '11-2011.00',
    Occupation: 'Advertising and Promotions Managers',
    'Projected Growth (2021-2031)': 'Average',
    'Projected Job Openings (2021-2031)': 3000,
    Industries:
      'Professional, Scientific, and Technical Services (43%), Information (16%)',
  },
  {
    Code: '41-3011.00',
    Occupation: 'Advertising Sales Agents',
    'Projected Growth (2021-2031)': 'Decline',
    'Projected Job Openings (2021-2031)': 10900,
    Industries:
      'Information (55%), Professional, Scientific, and Technical Services (39%)',
  },
  {
    Code: '17-3021.00',
    Occupation:
      'Aerospace Engineering and Operations Technologists and Technicians',
    'Projected Growth (2021-2031)': 'Average',
    'Projected Job Openings (2021-2031)': 1200,
    Industries:
      'Manufacturing (46%), Professional, Scientific, and Technical Services (40%)',
  },
  {
    Code: '17-2011.00',
    Occupation: 'Aerospace Engineers',
    'Projected Growth (2021-2031)': 'Average',
    'Projected Job Openings (2021-2031)': 3800,
    Industries:
      'Manufacturing (43%), Professional, Scientific, and Technical Services (30%), Government (18%)',
  },
  {
    Code: '13-1011.00',
    Occupation:
      'Agents and Business Managers of Artists, Performers, and Athletes',
    'Projected Growth (2021-2031)': 'Much faster than average',
    'Projected Job Openings (2021-2031)': 2800,
    Industries: 'Arts, Entertainment, and Recreation (62%)',
  },
  {
    Code: '17-2021.00',
    Occupation: 'Agricultural Engineers',
    'Projected Growth (2021-2031)': 'Slower than average',
    'Projected Job Openings (2021-2031)': 100,
    Industries:
      'Government (48%), Professional, Scientific, and Technical Services (23%), Manufacturing (11%), Educational Services (10%)',
  },
  {
    Code: '45-2091.00',
    Occupation: 'Agricultural Equipment Operators',
    'Projected Growth (2021-2031)': 'Much faster than average',
    'Projected Job Openings (2021-2031)': 12100,
    Industries:
      'Agriculture, Forestry, Fishing, and Hunting (77%), Wholesale Trade (11%)',
  },
  {
    Code: '45-2011.00',
    Occupation: 'Agricultural Inspectors',
    'Projected Growth (2021-2031)': 'Little or no change',
    'Projected Job Openings (2021-2031)': 2500,
    Industries: 'Government (72%), Manufacturing (10%)',
  },
  {
    Code: '25-1041.00',
    Occupation: 'Agricultural Sciences Teachers, Postsecondary',
    'Projected Growth (2021-2031)': 'Average',
    'Projected Job Openings (2021-2031)': 1000,
    Industries: 'Educational Services (99%)',
  },
  {
    Code: '19-4012.00',
    Occupation: 'Agricultural Technicians',
    'Projected Growth (2021-2031)': 'Faster than average',
    'Projected Job Openings (2021-2031)': 2600,
    Industries:
      'Professional, Scientific, and Technical Services (31%), Educational Services (22%), Agriculture, Forestry, Fishing, and Hunting (20%)',
  },
  {
    Code: '19-4012.01',
    Occupation: 'Precision Agriculture Technicians',
    'Projected Growth (2021-2031)': 'Faster than average',
    'Projected Job Openings (2021-2031)': 2600,
    Industries:
      'Professional, Scientific, and Technical Services (31%), Educational Services (22%), Agriculture, Forestry, Fishing, and Hunting (20%)',
  },
  {
    Code: '45-2099.00',
    Occupation: 'Agricultural Workers, All Other',
    'Projected Growth (2021-2031)': 'Average',
    'Projected Job Openings (2021-2031)': 2000,
    Industries: 'Agriculture, Forestry, Fishing, and Hunting (52%)',
  },
  {
    Code: '53-2021.00',
    Occupation: 'Air Traffic Controllers',
    'Projected Growth (2021-2031)': 'Little or no change',
    'Projected Job Openings (2021-2031)': 2400,
    Industries: 'Government (94%)',
  },
  {
    Code: '53-1041.00',
    Occupation: 'Aircraft Cargo Handling Supervisors',
    'Projected Growth (2021-2031)': 'Much faster than average',
    'Projected Job Openings (2021-2031)': 1200,
    Industries: 'Transportation and Warehousing (95%)',
  },
  {
    Code: '49-3011.00',
    Occupation: 'Aircraft Mechanics and Service Technicians',
    'Projected Growth (2021-2031)': 'Average',
    'Projected Job Openings (2021-2031)': 11500,
    Industries:
      'Transportation and Warehousing (61%), Manufacturing (14%), Government (12%)',
  },
  {
    Code: '53-6032.00',
    Occupation: 'Aircraft Service Attendants',
    'Projected Growth (2021-2031)': 'Much faster than average',
    'Projected Job Openings (2021-2031)': 2200,
    Industries: 'Transportation and Warehousing (81%)',
  },
  {
    Code: '51-2011.00',
    Occupation: 'Aircraft Structure, Surfaces, Rigging, and Systems Assemblers',
    'Projected Growth (2021-2031)': 'Decline',
    'Projected Job Openings (2021-2031)': 2900,
    Industries: 'Manufacturing (86%)',
  },
  {
    Code: '53-2022.00',
    Occupation: 'Airfield Operations Specialists',
    'Projected Growth (2021-2031)': 'Faster than average',
    'Projected Job Openings (2021-2031)': 1500,
    Industries: 'Transportation and Warehousing (65%), Government (27%)',
  },
  {
    Code: '53-2011.00',
    Occupation: 'Airline Pilots, Copilots, and Flight Engineers',
    'Projected Growth (2021-2031)': 'Average',
    'Projected Job Openings (2021-2031)': 11800,
    Industries: 'Transportation and Warehousing (95%)',
  },
  {
    Code: '53-3011.00',
    Occupation:
      'Ambulance Drivers and Attendants, Except Emergency Medical Technicians',
    'Projected Growth (2021-2031)': 'Little or no change',
    'Projected Job Openings (2021-2031)': 1800,
    Industries:
      'Health Care and Social Assistance (73%), Government (14%), Transportation and Warehousing (11%)',
  },
  {
    Code: '39-3091.00',
    Occupation: 'Amusement and Recreation Attendants',
    'Projected Growth (2021-2031)': 'Much faster than average',
    'Projected Job Openings (2021-2031)': 84800,
    Industries: 'Arts, Entertainment, and Recreation (72%), Government (13%)',
  },
  {
    Code: '29-1211.00',
    Occupation: 'Anesthesiologists',
    'Projected Growth (2021-2031)': 'Little or no change',
    'Projected Job Openings (2021-2031)': 1000,
    Industries: 'Health Care and Social Assistance (91%)',
  },
  {
    Code: '45-2021.00',
    Occupation: 'Animal Breeders',
    'Projected Growth (2021-2031)': 'Average',
    'Projected Job Openings (2021-2031)': 1200,
    Industries: 'Agriculture, Forestry, Fishing, and Hunting (96%)',
  },
  {
    Code: '39-2021.00',
    Occupation: 'Animal Caretakers',
    'Projected Growth (2021-2031)': 'Much faster than average',
    'Projected Job Openings (2021-2031)': 70400,
    Industries:
      'Other Services (Except Public Administration) (42%), Retail Trade (14%), Professional, Scientific, and Technical Services (13%)',
  },
  {
    Code: '33-9011.00',
    Occupation: 'Animal Control Workers',
    'Projected Growth (2021-2031)': 'Average',
    'Projected Job Openings (2021-2031)': 1000,
    Industries: 'Government (92%)',
  },
  {
    Code: '19-1011.00',
    Occupation: 'Animal Scientists',
    'Projected Growth (2021-2031)': 'Much faster than average',
    'Projected Job Openings (2021-2031)': 400,
    Industries:
      'Educational Services (25%), Professional, Scientific, and Technical Services (24%), Agriculture, Forestry, Fishing, and Hunting (21%), Manufacturing (12%), Wholesale Trade (11%)',
  },
  {
    Code: '39-2011.00',
    Occupation: 'Animal Trainers',
    'Projected Growth (2021-2031)': 'Much faster than average',
    'Projected Job Openings (2021-2031)': 10600,
    Industries:
      'Agriculture, Forestry, Fishing, and Hunting (37%), Other Services (Except Public Administration) (12%)',
  },
  {
    Code: '19-3091.00',
    Occupation: 'Anthropologists and Archeologists',
    'Projected Growth (2021-2031)': 'Average',
    'Projected Job Openings (2021-2031)': 800,
    Industries:
      'Professional, Scientific, and Technical Services (56%), Government (29%)',
  },
  {
    Code: '25-1061.00',
    Occupation: 'Anthropology and Archeology Teachers, Postsecondary',
    'Projected Growth (2021-2031)': 'Faster than average',
    'Projected Job Openings (2021-2031)': 600,
    Industries: 'Educational Services (100%)',
  },
  {
    Code: '23-1022.00',
    Occupation: 'Arbitrators, Mediators, and Conciliators',
    'Projected Growth (2021-2031)': 'Average',
    'Projected Job Openings (2021-2031)': 500,
    Industries:
      'Professional, Scientific, and Technical Services (36%), Government (26%), Finance and Insurance (10%)',
  },
  {
    Code: '17-1011.00',
    Occupation: 'Architects, Except Landscape and Naval',
    'Projected Growth (2021-2031)': 'Slower than average',
    'Projected Job Openings (2021-2031)': 9100,
    Industries: 'Professional, Scientific, and Technical Services (72%)',
  },
  {
    Code: '17-3011.00',
    Occupation: 'Architectural and Civil Drafters',
    'Projected Growth (2021-2031)': 'Little or no change',
    'Projected Job Openings (2021-2031)': 11800,
    Industries:
      'Professional, Scientific, and Technical Services (71%), Construction (12%)',
  },
  {
    Code: '11-9041.00',
    Occupation: 'Architectural and Engineering Managers',
    'Projected Growth (2021-2031)': 'Slower than average',
    'Projected Job Openings (2021-2031)': 14000,
    Industries:
      'Professional, Scientific, and Technical Services (39%), Manufacturing (35%)',
  },
  {
    Code: '11-9041.01',
    Occupation:
      'Biofuels/Biodiesel Technology and Product Development Managers',
    'Projected Growth (2021-2031)': 'Slower than average',
    'Projected Job Openings (2021-2031)': 14000,
    Industries:
      'Professional, Scientific, and Technical Services (39%), Manufacturing (35%)',
  },
  {
    Code: '25-1031.00',
    Occupation: 'Architecture Teachers, Postsecondary',
    'Projected Growth (2021-2031)': 'Faster than average',
    'Projected Job Openings (2021-2031)': 700,
    Industries: 'Educational Services (100%)',
  },
  {
    Code: '25-4011.00',
    Occupation: 'Archivists',
    'Projected Growth (2021-2031)': 'Faster than average',
    'Projected Job Openings (2021-2031)': 1100,
    Industries:
      'Educational Services (24%), Government (22%), Other Services (Except Public Administration) (16%), Arts, Entertainment, and Recreation (12%), Information (10%)',
  },
  {
    Code: '25-1062.00',
    Occupation: 'Area, Ethnic, and Cultural Studies Teachers, Postsecondary',
    'Projected Growth (2021-2031)': 'Faster than average',
    'Projected Job Openings (2021-2031)': 1100,
    Industries: 'Educational Services (100%)',
  },
  {
    Code: '27-1011.00',
    Occupation: 'Art Directors',
    'Projected Growth (2021-2031)': 'Average',
    'Projected Job Openings (2021-2031)': 13400,
    Industries: 'Professional, Scientific, and Technical Services (17%)',
  },
  {
    Code: '25-1121.00',
    Occupation: 'Art, Drama, and Music Teachers, Postsecondary',
    'Projected Growth (2021-2031)': 'Faster than average',
    'Projected Job Openings (2021-2031)': 11600,
    Industries: 'Educational Services (99%)',
  },
  {
    Code: '27-1019.00',
    Occupation: 'Artists and Related Workers, All Other',
    'Projected Growth (2021-2031)': 'Average',
    'Projected Job Openings (2021-2031)': 1600,
    Industries: 'Government (24%)',
  },
  {
    Code: '19-2011.00',
    Occupation: 'Astronomers',
    'Projected Growth (2021-2031)': 'Average',
    'Projected Job Openings (2021-2031)': 200,
    Industries:
      'Professional, Scientific, and Technical Services (44%), Educational Services (24%), Government (23%)',
  },
  {
    Code: '27-2021.00',
    Occupation: 'Athletes and Sports Competitors',
    'Projected Growth (2021-2031)': 'Much faster than average',
    'Projected Job Openings (2021-2031)': 2900,
    Industries: 'Arts, Entertainment, and Recreation (80%)',
  },
  {
    Code: '29-9091.00',
    Occupation: 'Athletic Trainers',
    'Projected Growth (2021-2031)': 'Much faster than average',
    'Projected Job Openings (2021-2031)': 2500,
    Industries:
      'Health Care and Social Assistance (44%), Educational Services (42%), Arts, Entertainment, and Recreation (10%)',
  },
  {
    Code: '19-2021.00',
    Occupation: 'Atmospheric and Space Scientists',
    'Projected Growth (2021-2031)': 'Average',
    'Projected Job Openings (2021-2031)': 700,
    Industries:
      'Government (37%), Professional, Scientific, and Technical Services (30%), Information (19%), Educational Services (11%)',
  },
  {
    Code: '25-1051.00',
    Occupation:
      'Atmospheric, Earth, Marine, and Space Sciences Teachers, Postsecondary',
    'Projected Growth (2021-2031)': 'Average',
    'Projected Job Openings (2021-2031)': 1200,
    Industries: 'Educational Services (99%)',
  },
  {
    Code: '27-4011.00',
    Occupation: 'Audio and Video Technicians',
    'Projected Growth (2021-2031)': 'Much faster than average',
    'Projected Job Openings (2021-2031)': 8100,
    Industries:
      'Information (20%), Educational Services (12%), Real Estate and Rental and Leasing (12%), Other Services (Except Public Administration) (10%), Professional, Scientific, and Technical Services (10%)',
  },
  {
    Code: '29-1181.00',
    Occupation: 'Audiologists',
    'Projected Growth (2021-2031)': 'Faster than average',
    'Projected Job Openings (2021-2031)': 800,
    Industries:
      'Health Care and Social Assistance (71%), Retail Trade (10%), Educational Services (10%)',
  },
  {
    Code: '49-2097.00',
    Occupation: 'Audiovisual Equipment Installers and Repairers',
    'Projected Growth (2021-2031)': 'Decline',
    'Projected Job Openings (2021-2031)': 2700,
    Industries: 'Construction (27%), Retail Trade (23%)',
  },
  {
    Code: '53-6031.00',
    Occupation: 'Automotive and Watercraft Service Attendants',
    'Projected Growth (2021-2031)': 'Little or no change',
    'Projected Job Openings (2021-2031)': 17700,
    Industries:
      'Retail Trade (60%), Other Services (Except Public Administration) (32%)',
  },
  {
    Code: '49-3021.00',
    Occupation: 'Automotive Body and Related Repairers',
    'Projected Growth (2021-2031)': 'Slower than average',
    'Projected Job Openings (2021-2031)': 15200,
    Industries:
      'Other Services (Except Public Administration) (68%), Retail Trade (18%)',
  },
  {
    Code: '49-3022.00',
    Occupation: 'Automotive Glass Installers and Repairers',
    'Projected Growth (2021-2031)': 'Average',
    'Projected Job Openings (2021-2031)': 1800,
    Industries:
      'Other Services (Except Public Administration) (74%), Retail Trade (12%)',
  },
  {
    Code: '49-3023.00',
    Occupation: 'Automotive Service Technicians and Mechanics',
    'Projected Growth (2021-2031)': 'Little or no change',
    'Projected Job Openings (2021-2031)': 73300,
    Industries:
      'Retail Trade (43%), Other Services (Except Public Administration) (32%)',
  },
  {
    Code: '49-2091.00',
    Occupation: 'Avionics Technicians',
    'Projected Growth (2021-2031)': 'Average',
    'Projected Job Openings (2021-2031)': 1600,
    Industries: 'Transportation and Warehousing (43%), Manufacturing (36%)',
  },
  {
    Code: '39-6011.00',
    Occupation: 'Baggage Porters and Bellhops',
    'Projected Growth (2021-2031)': 'Much faster than average',
    'Projected Job Openings (2021-2031)': 4100,
    Industries:
      'Accommodation and Food Services (51%), Transportation and Warehousing (14%)',
  },
  {
    Code: '33-3011.00',
    Occupation: 'Bailiffs',
    'Projected Growth (2021-2031)': 'Decline',
    'Projected Job Openings (2021-2031)': 2100,
    Industries: 'Government (99%)',
  },
  {
    Code: '51-3011.00',
    Occupation: 'Bakers',
    'Projected Growth (2021-2031)': 'Faster than average',
    'Projected Job Openings (2021-2031)': 31300,
    Industries:
      'Manufacturing (34%), Retail Trade (31%), Accommodation and Food Services (24%)',
  },
  {
    Code: '39-5011.00',
    Occupation: 'Barbers',
    'Projected Growth (2021-2031)': 'Faster than average',
    'Projected Job Openings (2021-2031)': 6600,
    Industries: 'Other Services (Except Public Administration) (26%)',
  },
  {
    Code: '35-3011.00',
    Occupation: 'Bartenders',
    'Projected Growth (2021-2031)': 'Much faster than average',
    'Projected Job Openings (2021-2031)': 105300,
    Industries: 'Accommodation and Food Services (79%)',
  },
  {
    Code: '49-3091.00',
    Occupation: 'Bicycle Repairers',
    'Projected Growth (2021-2031)': 'Average',
    'Projected Job Openings (2021-2031)': 1800,
    Industries: 'Retail Trade (85%)',
  },
  {
    Code: '43-3011.00',
    Occupation: 'Bill and Account Collectors',
    'Projected Growth (2021-2031)': 'Decline',
    'Projected Job Openings (2021-2031)': 22100,
    Industries:
      'Administrative and Support Services (35%), Finance and Insurance (22%), Health Care and Social Assistance (10%)',
  },
  {
    Code: '43-3021.00',
    Occupation: 'Billing and Posting Clerks',
    'Projected Growth (2021-2031)': 'Little or no change',
    'Projected Job Openings (2021-2031)': 51500,
    Industries:
      'Health Care and Social Assistance (42%), Professional, Scientific, and Technical Services (13%)',
  },
  {
    Code: '19-1021.00',
    Occupation: 'Biochemists and Biophysicists',
    'Projected Growth (2021-2031)': 'Much faster than average',
    'Projected Job Openings (2021-2031)': 4000,
    Industries:
      'Professional, Scientific, and Technical Services (66%), Manufacturing (19%)',
  },
  {
    Code: '17-2031.00',
    Occupation: 'Bioengineers and Biomedical Engineers',
    'Projected Growth (2021-2031)': 'Faster than average',
    'Projected Job Openings (2021-2031)': 1200,
    Industries:
      'Professional, Scientific, and Technical Services (36%), Manufacturing (30%)',
  },
  {
    Code: '25-1042.00',
    Occupation: 'Biological Science Teachers, Postsecondary',
    'Projected Growth (2021-2031)': 'Much faster than average',
    'Projected Job Openings (2021-2031)': 6100,
    Industries: 'Educational Services (97%)',
  },
  {
    Code: '19-1029.00',
    Occupation: 'Biological Scientists, All Other',
    'Projected Growth (2021-2031)': 'Average',
    'Projected Job Openings (2021-2031)': 4600,
    Industries:
      'Government (44%), Professional, Scientific, and Technical Services (36%)',
  },
  {
    Code: '19-1029.01',
    Occupation: 'Bioinformatics Scientists',
    'Projected Growth (2021-2031)': 'Average',
    'Projected Job Openings (2021-2031)': 4600,
    Industries:
      'Government (44%), Professional, Scientific, and Technical Services (36%)',
  },
  {
    Code: '19-1029.02',
    Occupation: 'Molecular and Cellular Biologists',
    'Projected Growth (2021-2031)': 'Average',
    'Projected Job Openings (2021-2031)': 4600,
    Industries:
      'Government (44%), Professional, Scientific, and Technical Services (36%)',
  },
  {
    Code: '19-1029.03',
    Occupation: 'Geneticists',
    'Projected Growth (2021-2031)': 'Average',
    'Projected Job Openings (2021-2031)': 4600,
    Industries:
      'Government (44%), Professional, Scientific, and Technical Services (36%)',
  },
  {
    Code: '19-1029.04',
    Occupation: 'Biologists',
    'Projected Growth (2021-2031)': 'Average',
    'Projected Job Openings (2021-2031)': 4600,
    Industries:
      'Government (44%), Professional, Scientific, and Technical Services (36%)',
  },
  {
    Code: '19-4021.00',
    Occupation: 'Biological Technicians',
    'Projected Growth (2021-2031)': 'Faster than average',
    'Projected Job Openings (2021-2031)': 12200,
    Industries:
      'Professional, Scientific, and Technical Services (37%), Educational Services (25%), Government (16%), Health Care and Social Assistance (11%)',
  },
  {
    Code: '47-2011.00',
    Occupation: 'Boilermakers',
    'Projected Growth (2021-2031)': 'Decline',
    'Projected Job Openings (2021-2031)': 1100,
    Industries: 'Construction (65%), Manufacturing (11%)',
  },
  {
    Code: '43-3031.00',
    Occupation: 'Bookkeeping, Accounting, and Auditing Clerks',
    'Projected Growth (2021-2031)': 'Decline',
    'Projected Job Openings (2021-2031)': 197600,
    Industries: 'Professional, Scientific, and Technical Services (13%)',
  },
  {
    Code: '47-2021.00',
    Occupation: 'Brickmasons and Blockmasons',
    'Projected Growth (2021-2031)': 'Slower than average',
    'Projected Job Openings (2021-2031)': 6500,
    Industries: 'Construction (79%)',
  },
  {
    Code: '53-6011.00',
    Occupation: 'Bridge and Lock Tenders',
    'Projected Growth (2021-2031)': 'Little or no change',
    'Projected Job Openings (2021-2031)': 500,
    Industries: 'Government (61%), Construction (24%)',
  },
  {
    Code: '27-3011.00',
    Occupation: 'Broadcast Announcers and Radio Disc Jockeys',
    'Projected Growth (2021-2031)': 'Decline',
    'Projected Job Openings (2021-2031)': 2100,
    Industries: 'Information (89%)',
  },
  {
    Code: '27-4012.00',
    Occupation: 'Broadcast Technicians',
    'Projected Growth (2021-2031)': 'Decline',
    'Projected Job Openings (2021-2031)': 2800,
    Industries: 'Information (73%)',
  },
  {
    Code: '43-4011.00',
    Occupation: 'Brokerage Clerks',
    'Projected Growth (2021-2031)': 'Decline',
    'Projected Job Openings (2021-2031)': 4500,
    Industries: 'Finance and Insurance (94%)',
  },
  {
    Code: '13-2031.00',
    Occupation: 'Budget Analysts',
    'Projected Growth (2021-2031)': 'Slower than average',
    'Projected Job Openings (2021-2031)': 4000,
    Industries:
      'Government (47%), Educational Services (13%), Professional, Scientific, and Technical Services (10%)',
  },
  {
    Code: '37-2019.00',
    Occupation: 'Building Cleaning Workers, All Other',
    'Projected Growth (2021-2031)': 'Slower than average',
    'Projected Job Openings (2021-2031)': 2800,
    Industries: 'Administrative and Support Services (65%)',
  },
  {
    Code: '49-3031.00',
    Occupation: 'Bus and Truck Mechanics and Diesel Engine Specialists',
    'Projected Growth (2021-2031)': 'Average',
    'Projected Job Openings (2021-2031)': 28500,
    Industries:
      'Transportation and Warehousing (29%), Wholesale Trade (14%), Government (10%), Other Services (Except Public Administration) (10%)',
  },
  {
    Code: '53-3051.00',
    Occupation: 'Bus Drivers, School',
    'Projected Growth (2021-2031)': 'Average',
    'Projected Job Openings (2021-2031)': 59500,
    Industries:
      'Educational Services (54%), Transportation and Warehousing (32%), Government (11%)',
  },
  {
    Code: '53-3052.00',
    Occupation: 'Bus Drivers, Transit and Intercity',
    'Projected Growth (2021-2031)': 'Faster than average',
    'Projected Job Openings (2021-2031)': 23300,
    Industries: 'Government (58%), Transportation and Warehousing (36%)',
  },
  {
    Code: '13-1199.00',
    Occupation: 'Business Operations Specialists, All Other',
    'Projected Growth (2021-2031)': 'Average',
    'Projected Job Openings (2021-2031)': 114200,
    Industries:
      'Government (25%), Professional, Scientific, and Technical Services (13%), Educational Services (10%)',
  },
  {
    Code: '13-1199.04',
    Occupation: 'Business Continuity Planners',
    'Projected Growth (2021-2031)': 'Average',
    'Projected Job Openings (2021-2031)': 114200,
    Industries:
      'Government (25%), Professional, Scientific, and Technical Services (13%), Educational Services (10%)',
  },
  {
    Code: '13-1199.05',
    Occupation: 'Sustainability Specialists',
    'Projected Growth (2021-2031)': 'Average',
    'Projected Job Openings (2021-2031)': 114200,
    Industries:
      'Government (25%), Professional, Scientific, and Technical Services (13%), Educational Services (10%)',
  },
  {
    Code: '13-1199.06',
    Occupation: 'Online Merchants',
    'Projected Growth (2021-2031)': 'Average',
    'Projected Job Openings (2021-2031)': 114200,
    Industries:
      'Government (25%), Professional, Scientific, and Technical Services (13%), Educational Services (10%)',
  },
  {
    Code: '13-1199.07',
    Occupation: 'Security Management Specialists',
    'Projected Growth (2021-2031)': 'Average',
    'Projected Job Openings (2021-2031)': 114200,
    Industries:
      'Government (25%), Professional, Scientific, and Technical Services (13%), Educational Services (10%)',
  },
  {
    Code: '25-1011.00',
    Occupation: 'Business Teachers, Postsecondary',
    'Projected Growth (2021-2031)': 'Average',
    'Projected Job Openings (2021-2031)': 9500,
    Industries: 'Educational Services (100%)',
  },
  {
    Code: '51-3021.00',
    Occupation: 'Butchers and Meat Cutters',
    'Projected Growth (2021-2031)': 'Decline',
    'Projected Job Openings (2021-2031)': 17700,
    Industries: 'Retail Trade (86%)',
  },
  {
    Code: '',
    Occupation: 'Buyers and Purchasing Agents',
    'Projected Growth (2021-2031)': 'Decline',
    'Projected Job Openings (2021-2031)': 41000,
    Industries: 'Manufacturing (23%), Wholesale Trade (14%), Government (13%)',
  },
  {
    Code: '13-1021.00',
    Occupation: 'Buyers and Purchasing Agents, Farm Products',
    'Projected Growth (2021-2031)': 'Decline',
    'Projected Job Openings (2021-2031)': 41000,
    Industries: 'Manufacturing (23%), Wholesale Trade (14%), Government (13%)',
  },
  {
    Code: '13-1022.00',
    Occupation: 'Wholesale and Retail Buyers, Except Farm Products',
    'Projected Growth (2021-2031)': 'Decline',
    'Projected Job Openings (2021-2031)': 41000,
    Industries: 'Manufacturing (23%), Wholesale Trade (14%), Government (13%)',
  },
  {
    Code: '13-1023.00',
    Occupation:
      'Purchasing Agents, Except Wholesale, Retail, and Farm Products',
    'Projected Growth (2021-2031)': 'Decline',
    'Projected Job Openings (2021-2031)': 41000,
    Industries: 'Manufacturing (23%), Wholesale Trade (14%), Government (13%)',
  },
  {
    Code: '51-7011.00',
    Occupation: 'Cabinetmakers and Bench Carpenters',
    'Projected Growth (2021-2031)': 'Average',
    'Projected Job Openings (2021-2031)': 11500,
    Industries: 'Manufacturing (78%)',
  },
  {
    Code: '17-3028.00',
    Occupation: 'Calibration Technologists and Technicians',
    'Projected Growth (2021-2031)': 'Average',
    'Projected Job Openings (2021-2031)': 900,
    Industries:
      'Professional, Scientific, and Technical Services (37%), Manufacturing (28%), Other Services (Except Public Administration) (10%)',
  },
  {
    Code: '49-9061.00',
    Occupation: 'Camera and Photographic Equipment Repairers',
    'Projected Growth (2021-2031)': 'Average',
    'Projected Job Openings (2021-2031)': 300,
    Industries:
      'Educational Services (17%), Other Services (Except Public Administration) (14%)',
  },
  {
    Code: '27-4031.00',
    Occupation: 'Camera Operators, Television, Video, and Film',
    'Projected Growth (2021-2031)': 'Faster than average',
    'Projected Job Openings (2021-2031)': 3700,
    Industries:
      'Information (40%), Professional, Scientific, and Technical Services (10%)',
  },
  {
    Code: '53-5021.00',
    Occupation: 'Captains, Mates, and Pilots of Water Vessels',
    'Projected Growth (2021-2031)': 'Little or no change',
    'Projected Job Openings (2021-2031)': 4100,
    Industries: 'Transportation and Warehousing (72%)',
  },
  {
    Code: '29-1212.00',
    Occupation: 'Cardiologists',
    'Projected Growth (2021-2031)': 'Slower than average',
    'Projected Job Openings (2021-2031)': 600,
    Industries: 'Health Care and Social Assistance (92%)',
  },
  {
    Code: '29-2031.00',
    Occupation: 'Cardiovascular Technologists and Technicians',
    'Projected Growth (2021-2031)': 'Average',
    'Projected Job Openings (2021-2031)': 4100,
    Industries: 'Health Care and Social Assistance (94%)',
  },
  {
    Code: '25-2023.00',
    Occupation: 'Career/Technical Education Teachers, Middle School',
    'Projected Growth (2021-2031)': 'Slower than average',
    'Projected Job Openings (2021-2031)': 900,
    Industries: 'Educational Services (100%)',
  },
  {
    Code: '25-1194.00',
    Occupation: 'Career/Technical Education Teachers, Postsecondary',
    'Projected Growth (2021-2031)': 'Slower than average',
    'Projected Job Openings (2021-2031)': 10200,
    Industries: 'Educational Services (92%)',
  },
  {
    Code: '25-2032.00',
    Occupation: 'Career/Technical Education Teachers, Secondary School',
    'Projected Growth (2021-2031)': 'Slower than average',
    'Projected Job Openings (2021-2031)': 6100,
    Industries: 'Educational Services (97%)',
  },
  {
    Code: '43-5011.00',
    Occupation: 'Cargo and Freight Agents',
    'Projected Growth (2021-2031)': 'Faster than average',
    'Projected Job Openings (2021-2031)': 11200,
    Industries: 'Transportation and Warehousing (93%)',
  },
  {
    Code: '43-5011.01',
    Occupation: 'Freight Forwarders',
    'Projected Growth (2021-2031)': 'Faster than average',
    'Projected Job Openings (2021-2031)': 11200,
    Industries: 'Transportation and Warehousing (93%)',
  },
  {
    Code: '47-2031.00',
    Occupation: 'Carpenters',
    'Projected Growth (2021-2031)': 'Slower than average',
    'Projected Job Openings (2021-2031)': 91200,
    Industries: 'Construction (61%)',
  },
  {
    Code: '47-2041.00',
    Occupation: 'Carpet Installers',
    'Projected Growth (2021-2031)': 'Decline',
    'Projected Job Openings (2021-2031)': 2000,
    Industries: 'Construction (40%), Retail Trade (31%)',
  },
  {
    Code: '17-1021.00',
    Occupation: 'Cartographers and Photogrammetrists',
    'Projected Growth (2021-2031)': 'Slower than average',
    'Projected Job Openings (2021-2031)': 1000,
    Industries:
      'Government (43%), Professional, Scientific, and Technical Services (36%)',
  },
  {
    Code: '41-2011.00',
    Occupation: 'Cashiers',
    'Projected Growth (2021-2031)': 'Decline',
    'Projected Job Openings (2021-2031)': 570700,
    Industries: 'Retail Trade (82%), Accommodation and Food Services (12%)',
  },
  {
    Code: '47-2051.00',
    Occupation: 'Cement Masons and Concrete Finishers',
    'Projected Growth (2021-2031)': 'Decline',
    'Projected Job Openings (2021-2031)': 15700,
    Industries: 'Construction (92%)',
  },
  {
    Code: '35-1011.00',
    Occupation: 'Chefs and Head Cooks',
    'Projected Growth (2021-2031)': 'Much faster than average',
    'Projected Job Openings (2021-2031)': 24300,
    Industries: 'Accommodation and Food Services (68%)',
  },
  {
    Code: '17-2041.00',
    Occupation: 'Chemical Engineers',
    'Projected Growth (2021-2031)': 'Much faster than average',
    'Projected Job Openings (2021-2031)': 2000,
    Industries:
      'Manufacturing (46%), Professional, Scientific, and Technical Services (27%)',
  },
  {
    Code: '51-9011.00',
    Occupation: 'Chemical Equipment Operators and Tenders',
    'Projected Growth (2021-2031)': 'Decline',
    'Projected Job Openings (2021-2031)': 10600,
    Industries: 'Manufacturing (96%)',
  },
  {
    Code: '51-8091.00',
    Occupation: 'Chemical Plant and System Operators',
    'Projected Growth (2021-2031)': 'Little or no change',
    'Projected Job Openings (2021-2031)': 2300,
    Industries: 'Manufacturing (92%)',
  },
  {
    Code: '19-4031.00',
    Occupation: 'Chemical Technicians',
    'Projected Growth (2021-2031)': 'Average',
    'Projected Job Openings (2021-2031)': 7800,
    Industries:
      'Manufacturing (48%), Professional, Scientific, and Technical Services (30%)',
  },
  {
    Code: '25-1052.00',
    Occupation: 'Chemistry Teachers, Postsecondary',
    'Projected Growth (2021-2031)': 'Faster than average',
    'Projected Job Openings (2021-2031)': 2400,
    Industries: 'Educational Services (99%)',
  },
  {
    Code: '19-2031.00',
    Occupation: 'Chemists',
    'Projected Growth (2021-2031)': 'Average',
    'Projected Job Openings (2021-2031)': 7600,
    Industries:
      'Manufacturing (41%), Professional, Scientific, and Technical Services (30%), Government (13%)',
  },
  {
    Code: '11-1011.00',
    Occupation: 'Chief Executives',
    'Projected Growth (2021-2031)': 'Decline',
    'Projected Job Openings (2021-2031)': 17700,
    Industries: 'Professional, Scientific, and Technical Services (13%)',
  },
  {
    Code: '11-1011.03',
    Occupation: 'Chief Sustainability Officers',
    'Projected Growth (2021-2031)': 'Decline',
    'Projected Job Openings (2021-2031)': 17700,
    Industries: 'Professional, Scientific, and Technical Services (13%)',
  },
  {
    Code: '21-1021.00',
    Occupation: 'Child, Family, and School Social Workers',
    'Projected Growth (2021-2031)': 'Faster than average',
    'Projected Job Openings (2021-2031)': 36600,
    Industries:
      'Government (42%), Health Care and Social Assistance (38%), Educational Services (15%)',
  },
  {
    Code: '39-9011.00',
    Occupation: 'Childcare Workers',
    'Projected Growth (2021-2031)': 'Average',
    'Projected Job Openings (2021-2031)': 170100,
    Industries:
      'Health Care and Social Assistance (32%), Other Services (Except Public Administration) (27%), Educational Services (11%)',
  },
  {
    Code: '39-9011.01',
    Occupation: 'Nannies',
    'Projected Growth (2021-2031)': 'Average',
    'Projected Job Openings (2021-2031)': 170100,
    Industries:
      'Health Care and Social Assistance (32%), Other Services (Except Public Administration) (27%), Educational Services (11%)',
  },
  {
    Code: '29-1011.00',
    Occupation: 'Chiropractors',
    'Projected Growth (2021-2031)': 'Faster than average',
    'Projected Job Openings (2021-2031)': 2100,
    Industries: 'Health Care and Social Assistance (68%)',
  },
  {
    Code: '27-2032.00',
    Occupation: 'Choreographers',
    'Projected Growth (2021-2031)': 'Much faster than average',
    'Projected Job Openings (2021-2031)': 1400,
    Industries:
      'Educational Services (52%), Arts, Entertainment, and Recreation (15%)',
  },
  {
    Code: '17-3022.00',
    Occupation: 'Civil Engineering Technologists and Technicians',
    'Projected Growth (2021-2031)': 'Little or no change',
    'Projected Job Openings (2021-2031)': 6500,
    Industries:
      'Government (46%), Professional, Scientific, and Technical Services (46%)',
  },
  {
    Code: '17-2051.00',
    Occupation: 'Civil Engineers',
    'Projected Growth (2021-2031)': 'Average',
    'Projected Job Openings (2021-2031)': 24200,
    Industries:
      'Professional, Scientific, and Technical Services (54%), Government (25%), Construction (12%)',
  },
  {
    Code: '17-2051.01',
    Occupation: 'Transportation Engineers',
    'Projected Growth (2021-2031)': 'Average',
    'Projected Job Openings (2021-2031)': 24200,
    Industries:
      'Professional, Scientific, and Technical Services (54%), Government (25%), Construction (12%)',
  },
  {
    Code: '17-2051.02',
    Occupation: 'Water/Wastewater Engineers',
    'Projected Growth (2021-2031)': 'Average',
    'Projected Job Openings (2021-2031)': 24200,
    Industries:
      'Professional, Scientific, and Technical Services (54%), Government (25%), Construction (12%)',
  },
  {
    Code: '13-1031.00',
    Occupation: 'Claims Adjusters, Examiners, and Investigators',
    'Projected Growth (2021-2031)': 'Decline',
    'Projected Job Openings (2021-2031)': 22300,
    Industries: 'Finance and Insurance (73%), Government (19%)',
  },
  {
    Code: '53-7061.00',
    Occupation: 'Cleaners of Vehicles and Equipment',
    'Projected Growth (2021-2031)': 'Average',
    'Projected Job Openings (2021-2031)': 59900,
    Industries:
      'Other Services (Except Public Administration) (39%), Retail Trade (24%)',
  },
  {
    Code: '51-9192.00',
    Occupation:
      'Cleaning, Washing, and Metal Pickling Equipment Operators and Tenders',
    'Projected Growth (2021-2031)': 'Average',
    'Projected Job Openings (2021-2031)': 1800,
    Industries: 'Manufacturing (70%)',
  },
  {
    Code: '21-2011.00',
    Occupation: 'Clergy',
    'Projected Growth (2021-2031)': 'Average',
    'Projected Job Openings (2021-2031)': 24400,
    Industries: 'Other Services (Except Public Administration) (83%)',
  },
  {
    Code: '19-3033.00',
    Occupation: 'Clinical and Counseling Psychologists',
    'Projected Growth (2021-2031)': 'Faster than average',
    'Projected Job Openings (2021-2031)': 5100,
    Industries: 'Health Care and Social Assistance (74%)',
  },
  {
    Code: '',
    Occupation: 'Clinical Laboratory Technologists and Technicians',
    'Projected Growth (2021-2031)': 'Average',
    'Projected Job Openings (2021-2031)': 25600,
    Industries: 'Health Care and Social Assistance (85%)',
  },
  {
    Code: '29-2011.00',
    Occupation: 'Medical and Clinical Laboratory Technologists',
    'Projected Growth (2021-2031)': 'Average',
    'Projected Job Openings (2021-2031)': 25600,
    Industries: 'Health Care and Social Assistance (85%)',
  },
  {
    Code: '29-2011.01',
    Occupation: 'Cytogenetic Technologists',
    'Projected Growth (2021-2031)': 'Average',
    'Projected Job Openings (2021-2031)': 25600,
    Industries: 'Health Care and Social Assistance (85%)',
  },
  {
    Code: '29-2011.02',
    Occupation: 'Cytotechnologists',
    'Projected Growth (2021-2031)': 'Average',
    'Projected Job Openings (2021-2031)': 25600,
    Industries: 'Health Care and Social Assistance (85%)',
  },
  {
    Code: '29-2011.04',
    Occupation: 'Histotechnologists',
    'Projected Growth (2021-2031)': 'Average',
    'Projected Job Openings (2021-2031)': 25600,
    Industries: 'Health Care and Social Assistance (85%)',
  },
  {
    Code: '29-2012.00',
    Occupation: 'Medical and Clinical Laboratory Technicians',
    'Projected Growth (2021-2031)': 'Average',
    'Projected Job Openings (2021-2031)': 25600,
    Industries: 'Health Care and Social Assistance (85%)',
  },
  {
    Code: '29-2012.01',
    Occupation: 'Histology Technicians',
    'Projected Growth (2021-2031)': 'Average',
    'Projected Job Openings (2021-2031)': 25600,
    Industries: 'Health Care and Social Assistance (85%)',
  },
  {
    Code: '27-2022.00',
    Occupation: 'Coaches and Scouts',
    'Projected Growth (2021-2031)': 'Much faster than average',
    'Projected Job Openings (2021-2031)': 39900,
    Industries:
      'Educational Services (64%), Arts, Entertainment, and Recreation (20%)',
  },
  {
    Code: '51-9124.00',
    Occupation:
      'Coating, Painting, and Spraying Machine Setters, Operators, and Tenders',
    'Projected Growth (2021-2031)': 'Slower than average',
    'Projected Job Openings (2021-2031)': 16600,
    Industries:
      'Manufacturing (65%), Other Services (Except Public Administration) (16%)',
  },
  {
    Code: '51-2021.00',
    Occupation: 'Coil Winders, Tapers, and Finishers',
    'Projected Growth (2021-2031)': 'Decline',
    'Projected Job Openings (2021-2031)': 1000,
    Industries: 'Manufacturing (90%)',
  },
  {
    Code: '49-9091.00',
    Occupation: 'Coin, Vending, and Amusement Machine Servicers and Repairers',
    'Projected Growth (2021-2031)': 'Average',
    'Projected Job Openings (2021-2031)': 3900,
    Industries:
      'Retail Trade (24%), Arts, Entertainment, and Recreation (17%), Government (13%), Wholesale Trade (13%), Manufacturing (10%)',
  },
  {
    Code: '27-1021.00',
    Occupation: 'Commercial and Industrial Designers',
    'Projected Growth (2021-2031)': 'Slower than average',
    'Projected Job Openings (2021-2031)': 2700,
    Industries:
      'Manufacturing (37%), Professional, Scientific, and Technical Services (28%), Wholesale Trade (12%)',
  },
  {
    Code: '49-9092.00',
    Occupation: 'Commercial Divers',
    'Projected Growth (2021-2031)': 'Much faster than average',
    'Projected Job Openings (2021-2031)': 400,
    Industries: 'Administrative and Support Services (48%), Construction (32%)',
  },
  {
    Code: '53-2012.00',
    Occupation: 'Commercial Pilots',
    'Projected Growth (2021-2031)': 'Average',
    'Projected Job Openings (2021-2031)': 6300,
    Industries:
      'Transportation and Warehousing (49%), Health Care and Social Assistance (10%)',
  },
  {
    Code: '43-2099.00',
    Occupation: 'Communications Equipment Operators, All Other',
    'Projected Growth (2021-2031)': 'Slower than average',
    'Projected Job Openings (2021-2031)': 200,
    Industries: 'Government (48%), Health Care and Social Assistance (10%)',
  },
  {
    Code: '25-1122.00',
    Occupation: 'Communications Teachers, Postsecondary',
    'Projected Growth (2021-2031)': 'Average',
    'Projected Job Openings (2021-2031)': 3200,
    Industries: 'Educational Services (100%)',
  },
  {
    Code: '21-1099.00',
    Occupation: 'Community and Social Service Specialists, All Other',
    'Projected Growth (2021-2031)': 'Faster than average',
    'Projected Job Openings (2021-2031)': 11800,
    Industries:
      'Government (37%), Health Care and Social Assistance (28%), Other Services (Except Public Administration) (14%), Educational Services (12%)',
  },
  {
    Code: '21-1094.00',
    Occupation: 'Community Health Workers',
    'Projected Growth (2021-2031)': 'Much faster than average',
    'Projected Job Openings (2021-2031)': 8800,
    Industries:
      'Health Care and Social Assistance (48%), Government (20%), Other Services (Except Public Administration) (12%)',
  },
  {
    Code: '11-3111.00',
    Occupation: 'Compensation and Benefits Managers',
    'Projected Growth (2021-2031)': 'Slower than average',
    'Projected Job Openings (2021-2031)': 1200,
    Industries:
      'Management of Companies and Enterprises (25%), Finance and Insurance (18%), Professional, Scientific, and Technical Services (15%)',
  },
  {
    Code: '13-1141.00',
    Occupation: 'Compensation, Benefits, and Job Analysis Specialists',
    'Projected Growth (2021-2031)': 'Average',
    'Projected Job Openings (2021-2031)': 9300,
    Industries:
      'Finance and Insurance (20%), Government (13%), Other Services (Except Public Administration) (12%), Professional, Scientific, and Technical Services (12%), Management of Companies and Enterprises (11%)',
  },
  {
    Code: '13-1041.00',
    Occupation: 'Compliance Officers',
    'Projected Growth (2021-2031)': 'Average',
    'Projected Job Openings (2021-2031)': 30200,
    Industries: 'Government (38%), Finance and Insurance (13%)',
  },
  {
    Code: '13-1041.01',
    Occupation: 'Environmental Compliance Inspectors',
    'Projected Growth (2021-2031)': 'Average',
    'Projected Job Openings (2021-2031)': 30200,
    Industries: 'Government (38%), Finance and Insurance (13%)',
  },
  {
    Code: '13-1041.03',
    Occupation: 'Equal Opportunity Representatives and Officers',
    'Projected Growth (2021-2031)': 'Average',
    'Projected Job Openings (2021-2031)': 30200,
    Industries: 'Government (38%), Finance and Insurance (13%)',
  },
  {
    Code: '13-1041.04',
    Occupation: 'Government Property Inspectors and Investigators',
    'Projected Growth (2021-2031)': 'Average',
    'Projected Job Openings (2021-2031)': 30200,
    Industries: 'Government (38%), Finance and Insurance (13%)',
  },
  {
    Code: '13-1041.06',
    Occupation: 'Coroners',
    'Projected Growth (2021-2031)': 'Average',
    'Projected Job Openings (2021-2031)': 30200,
    Industries: 'Government (38%), Finance and Insurance (13%)',
  },
  {
    Code: '13-1041.07',
    Occupation: 'Regulatory Affairs Specialists',
    'Projected Growth (2021-2031)': 'Average',
    'Projected Job Openings (2021-2031)': 30200,
    Industries: 'Government (38%), Finance and Insurance (13%)',
  },
  {
    Code: '13-1041.08',
    Occupation: 'Customs Brokers',
    'Projected Growth (2021-2031)': 'Average',
    'Projected Job Openings (2021-2031)': 30200,
    Industries: 'Government (38%), Finance and Insurance (13%)',
  },
  {
    Code: '15-1221.00',
    Occupation: 'Computer and Information Research Scientists',
    'Projected Growth (2021-2031)': 'Much faster than average',
    'Projected Job Openings (2021-2031)': 3300,
    Industries:
      'Professional, Scientific, and Technical Services (40%), Government (31%), Information (11%)',
  },
  {
    Code: '11-3021.00',
    Occupation: 'Computer and Information Systems Managers',
    'Projected Growth (2021-2031)': 'Much faster than average',
    'Projected Job Openings (2021-2031)': 48500,
    Industries:
      'Professional, Scientific, and Technical Services (34%), Information (13%), Finance and Insurance (12%)',
  },
  {
    Code: '17-2061.00',
    Occupation: 'Computer Hardware Engineers',
    'Projected Growth (2021-2031)': 'Average',
    'Projected Job Openings (2021-2031)': 5300,
    Industries:
      'Professional, Scientific, and Technical Services (44%), Manufacturing (29%), Information (10%)',
  },
  {
    Code: '15-1241.00',
    Occupation: 'Computer Network Architects',
    'Projected Growth (2021-2031)': 'Average',
    'Projected Job Openings (2021-2031)': 11800,
    Industries:
      'Professional, Scientific, and Technical Services (38%), Information (17%)',
  },
  {
    Code: '15-1241.01',
    Occupation: 'Telecommunications Engineering Specialists',
    'Projected Growth (2021-2031)': 'Average',
    'Projected Job Openings (2021-2031)': 11800,
    Industries:
      'Professional, Scientific, and Technical Services (38%), Information (17%)',
  },
  {
    Code: '15-1231.00',
    Occupation: 'Computer Network Support Specialists',
    'Projected Growth (2021-2031)': 'Average',
    'Projected Job Openings (2021-2031)': 16100,
    Industries:
      'Professional, Scientific, and Technical Services (25%), Information (21%), Educational Services (11%)',
  },
  {
    Code: '51-9161.00',
    Occupation: 'Computer Numerically Controlled Tool Operators',
    'Projected Growth (2021-2031)': 'Decline',
    'Projected Job Openings (2021-2031)': 14700,
    Industries: 'Manufacturing (93%)',
  },
  {
    Code: '51-9162.00',
    Occupation: 'Computer Numerically Controlled Tool Programmers',
    'Projected Growth (2021-2031)': 'Much faster than average',
    'Projected Job Openings (2021-2031)': 3600,
    Industries: 'Manufacturing (90%)',
  },
  {
    Code: '15-1299.00',
    Occupation: 'Computer Occupations, All Other',
    'Projected Growth (2021-2031)': 'Faster than average',
    'Projected Job Openings (2021-2031)': 34700,
    Industries:
      'Professional, Scientific, and Technical Services (29%), Government (24%)',
  },
  {
    Code: '15-1299.01',
    Occupation: 'Web Administrators',
    'Projected Growth (2021-2031)': 'Faster than average',
    'Projected Job Openings (2021-2031)': 34700,
    Industries:
      'Professional, Scientific, and Technical Services (29%), Government (24%)',
  },
  {
    Code: '15-1299.02',
    Occupation: 'Geographic Information Systems Technologists and Technicians',
    'Projected Growth (2021-2031)': 'Faster than average',
    'Projected Job Openings (2021-2031)': 34700,
    Industries:
      'Professional, Scientific, and Technical Services (29%), Government (24%)',
  },
  {
    Code: '15-1299.03',
    Occupation: 'Document Management Specialists',
    'Projected Growth (2021-2031)': 'Faster than average',
    'Projected Job Openings (2021-2031)': 34700,
    Industries:
      'Professional, Scientific, and Technical Services (29%), Government (24%)',
  },
  {
    Code: '15-1299.04',
    Occupation: 'Penetration Testers',
    'Projected Growth (2021-2031)': 'Faster than average',
    'Projected Job Openings (2021-2031)': 34700,
    Industries:
      'Professional, Scientific, and Technical Services (29%), Government (24%)',
  },
  {
    Code: '15-1299.05',
    Occupation: 'Information Security Engineers',
    'Projected Growth (2021-2031)': 'Faster than average',
    'Projected Job Openings (2021-2031)': 34700,
    Industries:
      'Professional, Scientific, and Technical Services (29%), Government (24%)',
  },
  {
    Code: '15-1299.06',
    Occupation: 'Digital Forensics Analysts',
    'Projected Growth (2021-2031)': 'Faster than average',
    'Projected Job Openings (2021-2031)': 34700,
    Industries:
      'Professional, Scientific, and Technical Services (29%), Government (24%)',
  },
  {
    Code: '15-1299.07',
    Occupation: 'Blockchain Engineers',
    'Projected Growth (2021-2031)': 'Faster than average',
    'Projected Job Openings (2021-2031)': 34700,
    Industries:
      'Professional, Scientific, and Technical Services (29%), Government (24%)',
  },
  {
    Code: '15-1299.08',
    Occupation: 'Computer Systems Engineers/Architects',
    'Projected Growth (2021-2031)': 'Faster than average',
    'Projected Job Openings (2021-2031)': 34700,
    Industries:
      'Professional, Scientific, and Technical Services (29%), Government (24%)',
  },
  {
    Code: '15-1299.09',
    Occupation: 'Information Technology Project Managers',
    'Projected Growth (2021-2031)': 'Faster than average',
    'Projected Job Openings (2021-2031)': 34700,
    Industries:
      'Professional, Scientific, and Technical Services (29%), Government (24%)',
  },
  {
    Code: '15-1251.00',
    Occupation: 'Computer Programmers',
    'Projected Growth (2021-2031)': 'Decline',
    'Projected Job Openings (2021-2031)': 9600,
    Industries:
      'Professional, Scientific, and Technical Services (43%), Information (10%)',
  },
  {
    Code: '25-1021.00',
    Occupation: 'Computer Science Teachers, Postsecondary',
    'Projected Growth (2021-2031)': 'Average',
    'Projected Job Openings (2021-2031)': 4500,
    Industries: 'Educational Services (100%)',
  },
  {
    Code: '15-1211.00',
    Occupation: 'Computer Systems Analysts',
    'Projected Growth (2021-2031)': 'Faster than average',
    'Projected Job Openings (2021-2031)': 44500,
    Industries:
      'Professional, Scientific, and Technical Services (34%), Finance and Insurance (15%), Management of Companies and Enterprises (10%)',
  },
  {
    Code: '15-1211.01',
    Occupation: 'Health Informatics Specialists',
    'Projected Growth (2021-2031)': 'Faster than average',
    'Projected Job Openings (2021-2031)': 44500,
    Industries:
      'Professional, Scientific, and Technical Services (34%), Finance and Insurance (15%), Management of Companies and Enterprises (10%)',
  },
  {
    Code: '15-1232.00',
    Occupation: 'Computer User Support Specialists',
    'Projected Growth (2021-2031)': 'Average',
    'Projected Job Openings (2021-2031)': 58900,
    Industries:
      'Professional, Scientific, and Technical Services (29%), Educational Services (11%), Information (11%), Administrative and Support Services (10%)',
  },
  {
    Code: '49-2011.00',
    Occupation: 'Computer, Automated Teller, and Office Machine Repairers',
    'Projected Growth (2021-2031)': 'Decline',
    'Projected Job Openings (2021-2031)': 10300,
    Industries:
      'Wholesale Trade (26%), Retail Trade (26%), Other Services (Except Public Administration) (15%)',
  },
  {
    Code: '39-6012.00',
    Occupation: 'Concierges',
    'Projected Growth (2021-2031)': 'Much faster than average',
    'Projected Job Openings (2021-2031)': 6100,
    Industries:
      'Real Estate and Rental and Leasing (28%), Health Care and Social Assistance (21%), Accommodation and Food Services (18%), Other Services (Except Public Administration) (17%)',
  },
  {
    Code: '19-1031.00',
    Occupation: 'Conservation Scientists',
    'Projected Growth (2021-2031)': 'Slower than average',
    'Projected Job Openings (2021-2031)': 2300,
    Industries:
      'Government (72%), Other Services (Except Public Administration) (15%)',
  },
  {
    Code: '19-1031.02',
    Occupation: 'Range Managers',
    'Projected Growth (2021-2031)': 'Slower than average',
    'Projected Job Openings (2021-2031)': 2300,
    Industries:
      'Government (72%), Other Services (Except Public Administration) (15%)',
  },
  {
    Code: '19-1031.03',
    Occupation: 'Park Naturalists',
    'Projected Growth (2021-2031)': 'Slower than average',
    'Projected Job Openings (2021-2031)': 2300,
    Industries:
      'Government (72%), Other Services (Except Public Administration) (15%)',
  },
  {
    Code: '47-4011.00',
    Occupation: 'Construction and Building Inspectors',
    'Projected Growth (2021-2031)': 'Decline',
    'Projected Job Openings (2021-2031)': 14800,
    Industries:
      'Government (43%), Professional, Scientific, and Technical Services (35%)',
  },
  {
    Code: '47-4011.01',
    Occupation: 'Energy Auditors',
    'Projected Growth (2021-2031)': 'Decline',
    'Projected Job Openings (2021-2031)': 14800,
    Industries:
      'Government (43%), Professional, Scientific, and Technical Services (35%)',
  },
  {
    Code: '47-2061.00',
    Occupation: 'Construction Laborers',
    'Projected Growth (2021-2031)': 'Average',
    'Projected Job Openings (2021-2031)': 143200,
    Industries: 'Construction (61%)',
  },
  {
    Code: '11-9021.00',
    Occupation: 'Construction Managers',
    'Projected Growth (2021-2031)': 'Faster than average',
    'Projected Job Openings (2021-2031)': 41500,
    Industries: 'Construction (49%)',
  },
  {
    Code: '47-5041.00',
    Occupation: 'Continuous Mining Machine Operators',
    'Projected Growth (2021-2031)': 'Slower than average',
    'Projected Job Openings (2021-2031)': 1800,
    Industries: 'Mining, Quarrying, and Oil and Gas Extraction (96%)',
  },
  {
    Code: '49-9012.00',
    Occupation:
      'Control and Valve Installers and Repairers, Except Mechanical Door',
    'Projected Growth (2021-2031)': 'Little or no change',
    'Projected Job Openings (2021-2031)': 3600,
    Industries: 'Utilities (50%), Government (19%)',
  },
  {
    Code: '53-7011.00',
    Occupation: 'Conveyor Operators and Tenders',
    'Projected Growth (2021-2031)': 'Average',
    'Projected Job Openings (2021-2031)': 5000,
    Industries:
      'Transportation and Warehousing (42%), Manufacturing (19%), Wholesale Trade (13%)',
  },
  {
    Code: '35-2019.00',
    Occupation: 'Cooks, All Other',
    'Projected Growth (2021-2031)': 'Faster than average',
    'Projected Job Openings (2021-2031)': 3300,
    Industries: 'Accommodation and Food Services (43%), Retail Trade (18%)',
  },
  {
    Code: '35-2011.00',
    Occupation: 'Cooks, Fast Food',
    'Projected Growth (2021-2031)': 'Decline',
    'Projected Job Openings (2021-2031)': 109400,
    Industries: 'Accommodation and Food Services (97%)',
  },
  {
    Code: '35-2012.00',
    Occupation: 'Cooks, Institution and Cafeteria',
    'Projected Growth (2021-2031)': 'Average',
    'Projected Job Openings (2021-2031)': 67500,
    Industries:
      'Health Care and Social Assistance (41%), Educational Services (34%), Accommodation and Food Services (16%)',
  },
  {
    Code: '35-2013.00',
    Occupation: 'Cooks, Private Household',
    'Projected Growth (2021-2031)': 'Decline',
    'Projected Job Openings (2021-2031)': 6200,
    Industries: 'Other Services (Except Public Administration) (15%)',
  },
  {
    Code: '35-2014.00',
    Occupation: 'Cooks, Restaurant',
    'Projected Growth (2021-2031)': 'Much faster than average',
    'Projected Job Openings (2021-2031)': 274000,
    Industries: 'Accommodation and Food Services (94%)',
  },
  {
    Code: '35-2015.00',
    Occupation: 'Cooks, Short Order',
    'Projected Growth (2021-2031)': 'Little or no change',
    'Projected Job Openings (2021-2031)': 20100,
    Industries: 'Accommodation and Food Services (77%), Retail Trade (15%)',
  },
  {
    Code: '51-9193.00',
    Occupation: 'Cooling and Freezing Equipment Operators and Tenders',
    'Projected Growth (2021-2031)': 'Average',
    'Projected Job Openings (2021-2031)': 1000,
    Industries: 'Manufacturing (80%)',
  },
  {
    Code: '33-3012.00',
    Occupation: 'Correctional Officers and Jailers',
    'Projected Growth (2021-2031)': 'Decline',
    'Projected Job Openings (2021-2031)': 31200,
    Industries: 'Government (95%)',
  },
  {
    Code: '43-4021.00',
    Occupation: 'Correspondence Clerks',
    'Projected Growth (2021-2031)': 'Decline',
    'Projected Job Openings (2021-2031)': 900,
    Industries:
      'Finance and Insurance (32%), Administrative and Support Services (17%), Professional, Scientific, and Technical Services (13%), Health Care and Social Assistance (10%)',
  },
  {
    Code: '13-1051.00',
    Occupation: 'Cost Estimators',
    'Projected Growth (2021-2031)': 'Decline',
    'Projected Job Openings (2021-2031)': 18500,
    Industries: 'Construction (58%), Manufacturing (13%)',
  },
  {
    Code: '39-3092.00',
    Occupation: 'Costume Attendants',
    'Projected Growth (2021-2031)': 'Much faster than average',
    'Projected Job Openings (2021-2031)': 1200,
    Industries:
      'Information (41%), Arts, Entertainment, and Recreation (34%), Educational Services (11%)',
  },
  {
    Code: '21-1019.00',
    Occupation: 'Counselors, All Other',
    'Projected Growth (2021-2031)': 'Faster than average',
    'Projected Job Openings (2021-2031)': 8100,
    Industries: 'Health Care and Social Assistance (25%)',
  },
  {
    Code: '41-2021.00',
    Occupation: 'Counter and Rental Clerks',
    'Projected Growth (2021-2031)': 'Average',
    'Projected Job Openings (2021-2031)': 50600,
    Industries:
      'Real Estate and Rental and Leasing (51%), Retail Trade (18%), Other Services (Except Public Administration) (13%)',
  },
  {
    Code: '43-5021.00',
    Occupation: 'Couriers and Messengers',
    'Projected Growth (2021-2031)': 'Little or no change',
    'Projected Job Openings (2021-2031)': 19100,
    Industries:
      'Transportation and Warehousing (13%), Health Care and Social Assistance (13%)',
  },
  {
    Code: '27-3092.00',
    Occupation: 'Court Reporters and Simultaneous Captioners',
    'Projected Growth (2021-2031)': 'Little or no change',
    'Projected Job Openings (2021-2031)': 2000,
    Industries: 'Government (46%), Administrative and Support Services (19%)',
  },
  {
    Code: '43-4031.00',
    Occupation: 'Court, Municipal, and License Clerks',
    'Projected Growth (2021-2031)': 'Slower than average',
    'Projected Job Openings (2021-2031)': 18300,
    Industries: 'Government (96%)',
  },
  {
    Code: '27-1012.00',
    Occupation: 'Craft Artists',
    'Projected Growth (2021-2031)': 'Average',
    'Projected Job Openings (2021-2031)': 1200,
    Industries: 'Arts, Entertainment, and Recreation (14%)',
  },
  {
    Code: '53-7021.00',
    Occupation: 'Crane and Tower Operators',
    'Projected Growth (2021-2031)': 'Little or no change',
    'Projected Job Openings (2021-2031)': 4800,
    Industries:
      'Construction (40%), Manufacturing (20%), Transportation and Warehousing (13%), Wholesale Trade (10%)',
  },
  {
    Code: '13-2041.00',
    Occupation: 'Credit Analysts',
    'Projected Growth (2021-2031)': 'Decline',
    'Projected Job Openings (2021-2031)': 5400,
    Industries:
      'Finance and Insurance (74%), Management of Companies and Enterprises (11%)',
  },
  {
    Code: '43-4041.00',
    Occupation: 'Credit Authorizers, Checkers, and Clerks',
    'Projected Growth (2021-2031)': 'Decline',
    'Projected Job Openings (2021-2031)': 1700,
    Industries:
      'Finance and Insurance (41%), Administrative and Support Services (14%), Wholesale Trade (11%), Retail Trade (10%)',
  },
  {
    Code: '13-2071.00',
    Occupation: 'Credit Counselors',
    'Projected Growth (2021-2031)': 'Faster than average',
    'Projected Job Openings (2021-2031)': 3200,
    Industries:
      'Educational Services (44%), Finance and Insurance (19%), Professional, Scientific, and Technical Services (15%), Health Care and Social Assistance (11%)',
  },
  {
    Code: '39-4012.00',
    Occupation: 'Crematory Operators',
    'Projected Growth (2021-2031)': 'Faster than average',
    'Projected Job Openings (2021-2031)': 400,
    Industries: 'Other Services (Except Public Administration) (91%)',
  },
  {
    Code: '25-1111.00',
    Occupation: 'Criminal Justice and Law Enforcement Teachers, Postsecondary',
    'Projected Growth (2021-2031)': 'Faster than average',
    'Projected Job Openings (2021-2031)': 1600,
    Industries: 'Educational Services (99%)',
  },
  {
    Code: '33-9091.00',
    Occupation: 'Crossing Guards and Flaggers',
    'Projected Growth (2021-2031)': 'Faster than average',
    'Projected Job Openings (2021-2031)': 20000,
    Industries: 'Government (43%), Administrative and Support Services (42%)',
  },
  {
    Code: '51-9021.00',
    Occupation:
      'Crushing, Grinding, and Polishing Machine Setters, Operators, and Tenders',
    'Projected Growth (2021-2031)': 'Decline',
    'Projected Job Openings (2021-2031)': 3500,
    Industries:
      'Manufacturing (63%), Mining, Quarrying, and Oil and Gas Extraction (15%)',
  },
  {
    Code: '25-4012.00',
    Occupation: 'Curators',
    'Projected Growth (2021-2031)': 'Much faster than average',
    'Projected Job Openings (2021-2031)': 1800,
    Industries:
      'Arts, Entertainment, and Recreation (49%), Government (20%), Educational Services (17%)',
  },
  {
    Code: '43-4051.00',
    Occupation: 'Customer Service Representatives',
    'Projected Growth (2021-2031)': 'Decline',
    'Projected Job Openings (2021-2031)': 389400,
    Industries:
      'Finance and Insurance (19%), Administrative and Support Services (18%), Retail Trade (18%)',
  },
  {
    Code: '51-9031.00',
    Occupation: 'Cutters and Trimmers, Hand',
    'Projected Growth (2021-2031)': 'Decline',
    'Projected Job Openings (2021-2031)': 700,
    Industries: 'Manufacturing (76%)',
  },
  {
    Code: '51-9032.00',
    Occupation: 'Cutting and Slicing Machine Setters, Operators, and Tenders',
    'Projected Growth (2021-2031)': 'Decline',
    'Projected Job Openings (2021-2031)': 7300,
    Industries: 'Manufacturing (82%)',
  },
  {
    Code: '51-4031.00',
    Occupation:
      'Cutting, Punching, and Press Machine Setters, Operators, and Tenders, Metal and Plastic',
    'Projected Growth (2021-2031)': 'Decline',
    'Projected Job Openings (2021-2031)': 17100,
    Industries: 'Manufacturing (90%)',
  },
  {
    Code: '27-2031.00',
    Occupation: 'Dancers',
    'Projected Growth (2021-2031)': 'Much faster than average',
    'Projected Job Openings (2021-2031)': 1300,
    Industries:
      'Arts, Entertainment, and Recreation (46%), Educational Services (10%)',
  },
  {
    Code: '43-9021.00',
    Occupation: 'Data Entry Keyers',
    'Projected Growth (2021-2031)': 'Decline',
    'Projected Job Openings (2021-2031)': 13100,
    Industries:
      'Administrative and Support Services (21%), Professional, Scientific, and Technical Services (16%)',
  },
  {
    Code: '15-2051.00',
    Occupation: 'Data Scientists',
    'Projected Growth (2021-2031)': 'Much faster than average',
    'Projected Job Openings (2021-2031)': 13500,
    Industries:
      'Professional, Scientific, and Technical Services (34%), Finance and Insurance (18%), Information (11%), Management of Companies and Enterprises (10%)',
  },
  {
    Code: '15-2051.01',
    Occupation: 'Business Intelligence Analysts',
    'Projected Growth (2021-2031)': 'Much faster than average',
    'Projected Job Openings (2021-2031)': 13500,
    Industries:
      'Professional, Scientific, and Technical Services (34%), Finance and Insurance (18%), Information (11%), Management of Companies and Enterprises (10%)',
  },
  {
    Code: '15-2051.02',
    Occupation: 'Clinical Data Managers',
    'Projected Growth (2021-2031)': 'Much faster than average',
    'Projected Job Openings (2021-2031)': 13500,
    Industries:
      'Professional, Scientific, and Technical Services (34%), Finance and Insurance (18%), Information (11%), Management of Companies and Enterprises (10%)',
  },
  {
    Code: '15-1242.00',
    Occupation: 'Database Administrators',
    'Projected Growth (2021-2031)': 'Faster than average',
    'Projected Job Openings (2021-2031)': 7200,
    Industries:
      'Professional, Scientific, and Technical Services (23%), Finance and Insurance (13%), Information (12%)',
  },
  {
    Code: '15-1243.00',
    Occupation: 'Database Architects',
    'Projected Growth (2021-2031)': 'Faster than average',
    'Projected Job Openings (2021-2031)': 4300,
    Industries:
      'Professional, Scientific, and Technical Services (40%), Information (15%), Finance and Insurance (13%)',
  },
  {
    Code: '15-1243.01',
    Occupation: 'Data Warehousing Specialists',
    'Projected Growth (2021-2031)': 'Faster than average',
    'Projected Job Openings (2021-2031)': 4300,
    Industries:
      'Professional, Scientific, and Technical Services (40%), Information (15%), Finance and Insurance (13%)',
  },
  {
    Code: '41-9011.00',
    Occupation: 'Demonstrators and Product Promoters',
    'Projected Growth (2021-2031)': 'Slower than average',
    'Projected Job Openings (2021-2031)': 8600,
    Industries:
      'Manufacturing (24%), Professional, Scientific, and Technical Services (24%), Retail Trade (22%)',
  },
  {
    Code: '31-9091.00',
    Occupation: 'Dental Assistants',
    'Projected Growth (2021-2031)': 'Faster than average',
    'Projected Job Openings (2021-2031)': 56400,
    Industries: 'Health Care and Social Assistance (95%)',
  },
  {
    Code: '29-1292.00',
    Occupation: 'Dental Hygienists',
    'Projected Growth (2021-2031)': 'Faster than average',
    'Projected Job Openings (2021-2031)': 16300,
    Industries: 'Health Care and Social Assistance (96%)',
  },
  {
    Code: '51-9081.00',
    Occupation: 'Dental Laboratory Technicians',
    'Projected Growth (2021-2031)': 'Little or no change',
    'Projected Job Openings (2021-2031)': 4800,
    Industries: 'Manufacturing (81%), Health Care and Social Assistance (12%)',
  },
  {
    Code: '29-1029.00',
    Occupation: 'Dentists, All Other Specialists',
    'Projected Growth (2021-2031)': 'Little or no change',
    'Projected Job Openings (2021-2031)': 200,
    Industries: 'Health Care and Social Assistance (47%), Government (44%)',
  },
  {
    Code: '29-1021.00',
    Occupation: 'Dentists, General',
    'Projected Growth (2021-2031)': 'Average',
    'Projected Job Openings (2021-2031)': 4500,
    Industries: 'Health Care and Social Assistance (85%)',
  },
  {
    Code: '29-1213.00',
    Occupation: 'Dermatologists',
    'Projected Growth (2021-2031)': 'Slower than average',
    'Projected Job Openings (2021-2031)': 300,
    Industries: 'Health Care and Social Assistance (90%)',
  },
  {
    Code: '47-5011.00',
    Occupation: 'Derrick Operators, Oil and Gas',
    'Projected Growth (2021-2031)': 'Much faster than average',
    'Projected Job Openings (2021-2031)': 1200,
    Industries: 'Mining, Quarrying, and Oil and Gas Extraction (86%)',
  },
  {
    Code: '43-9031.00',
    Occupation: 'Desktop Publishers',
    'Projected Growth (2021-2031)': 'Decline',
    'Projected Job Openings (2021-2031)': 900,
    Industries:
      'Information (38%), Professional, Scientific, and Technical Services (13%)',
  },
  {
    Code: '33-3021.00',
    Occupation: 'Detectives and Criminal Investigators',
    'Projected Growth (2021-2031)': 'Little or no change',
    'Projected Job Openings (2021-2031)': 8800,
    Industries: 'Government (100%)',
  },
  {
    Code: '33-3021.02',
    Occupation: 'Police Identification and Records Officers',
    'Projected Growth (2021-2031)': 'Little or no change',
    'Projected Job Openings (2021-2031)': 8800,
    Industries: 'Government (100%)',
  },
  {
    Code: '33-3021.06',
    Occupation: 'Intelligence Analysts',
    'Projected Growth (2021-2031)': 'Little or no change',
    'Projected Job Openings (2021-2031)': 8800,
    Industries: 'Government (100%)',
  },
  {
    Code: '29-2032.00',
    Occupation: 'Diagnostic Medical Sonographers',
    'Projected Growth (2021-2031)': 'Much faster than average',
    'Projected Job Openings (2021-2031)': 6000,
    Industries: 'Health Care and Social Assistance (95%)',
  },
  {
    Code: '29-2051.00',
    Occupation: 'Dietetic Technicians',
    'Projected Growth (2021-2031)': 'Average',
    'Projected Job Openings (2021-2031)': 2500,
    Industries: 'Health Care and Social Assistance (84%)',
  },
  {
    Code: '29-1031.00',
    Occupation: 'Dietitians and Nutritionists',
    'Projected Growth (2021-2031)': 'Average',
    'Projected Job Openings (2021-2031)': 5600,
    Industries: 'Health Care and Social Assistance (62%), Government (12%)',
  },
  {
    Code: '35-9011.00',
    Occupation: 'Dining Room and Cafeteria Attendants and Bartender Helpers',
    'Projected Growth (2021-2031)': 'Much faster than average',
    'Projected Job Openings (2021-2031)': 76100,
    Industries:
      'Accommodation and Food Services (77%), Educational Services (10%)',
  },
  {
    Code: '21-2021.00',
    Occupation: 'Directors, Religious Activities and Education',
    'Projected Growth (2021-2031)': 'Average',
    'Projected Job Openings (2021-2031)': 17700,
    Industries: 'Other Services (Except Public Administration) (95%)',
  },
  {
    Code: '27-2091.00',
    Occupation: 'Disc Jockeys, Except Radio',
    'Projected Growth (2021-2031)': 'Average',
    'Projected Job Openings (2021-2031)': 1400,
    Industries:
      'Arts, Entertainment, and Recreation (15%), Accommodation and Food Services (12%)',
  },
  {
    Code: '35-9021.00',
    Occupation: 'Dishwashers',
    'Projected Growth (2021-2031)': 'Faster than average',
    'Projected Job Openings (2021-2031)': 74800,
    Industries: 'Accommodation and Food Services (86%)',
  },
  {
    Code: '43-5032.00',
    Occupation: 'Dispatchers, Except Police, Fire, and Ambulance',
    'Projected Growth (2021-2031)': 'Little or no change',
    'Projected Job Openings (2021-2031)': 20700,
    Industries:
      'Transportation and Warehousing (44%), Administrative and Support Services (10%)',
  },
  {
    Code: '17-3019.00',
    Occupation: 'Drafters, All Other',
    'Projected Growth (2021-2031)': 'Decline',
    'Projected Job Openings (2021-2031)': 1300,
    Industries:
      'Manufacturing (36%), Professional, Scientific, and Technical Services (34%), Construction (10%)',
  },
  {
    Code: '53-7031.00',
    Occupation: 'Dredge Operators',
    'Projected Growth (2021-2031)': 'Slower than average',
    'Projected Job Openings (2021-2031)': 300,
    Industries:
      'Construction (47%), Mining, Quarrying, and Oil and Gas Extraction (22%)',
  },
  {
    Code: '51-4032.00',
    Occupation:
      'Drilling and Boring Machine Tool Setters, Operators, and Tenders, Metal and Plastic',
    'Projected Growth (2021-2031)': 'Decline',
    'Projected Job Openings (2021-2031)': 700,
    Industries: 'Manufacturing (82%), Construction (12%)',
  },
  {
    Code: '53-3031.00',
    Occupation: 'Driver/Sales Workers',
    'Projected Growth (2021-2031)': 'Much faster than average',
    'Projected Job Openings (2021-2031)': 72300,
    Industries:
      'Accommodation and Food Services (48%), Wholesale Trade (20%), Retail Trade (10%)',
  },
  {
    Code: '47-2081.00',
    Occupation: 'Drywall and Ceiling Tile Installers',
    'Projected Growth (2021-2031)': 'Average',
    'Projected Job Openings (2021-2031)': 9600,
    Industries: 'Construction (86%)',
  },
  {
    Code: '47-5023.00',
    Occupation: 'Earth Drillers, Except Oil and Gas',
    'Projected Growth (2021-2031)': 'Faster than average',
    'Projected Job Openings (2021-2031)': 1900,
    Industries:
      'Construction (59%), Mining, Quarrying, and Oil and Gas Extraction (22%)',
  },
  {
    Code: '25-1063.00',
    Occupation: 'Economics Teachers, Postsecondary',
    'Projected Growth (2021-2031)': 'Faster than average',
    'Projected Job Openings (2021-2031)': 1500,
    Industries: 'Educational Services (100%)',
  },
  {
    Code: '19-3011.00',
    Occupation: 'Economists',
    'Projected Growth (2021-2031)': 'Average',
    'Projected Job Openings (2021-2031)': 1400,
    Industries:
      'Government (47%), Professional, Scientific, and Technical Services (34%)',
  },
  {
    Code: '19-3011.01',
    Occupation: 'Environmental Economists',
    'Projected Growth (2021-2031)': 'Average',
    'Projected Job Openings (2021-2031)': 1400,
    Industries:
      'Government (47%), Professional, Scientific, and Technical Services (34%)',
  },
  {
    Code: '27-3041.00',
    Occupation: 'Editors',
    'Projected Growth (2021-2031)': 'Decline',
    'Projected Job Openings (2021-2031)': 10200,
    Industries:
      'Information (54%), Professional, Scientific, and Technical Services (11%)',
  },
  {
    Code: '11-9039.00',
    Occupation: 'Education Administrators, All Other',
    'Projected Growth (2021-2031)': 'Faster than average',
    'Projected Job Openings (2021-2031)': 5300,
    Industries: 'Educational Services (63%), Government (15%)',
  },
  {
    Code: '11-9032.00',
    Occupation: 'Education Administrators, Kindergarten through Secondary',
    'Projected Growth (2021-2031)': 'Average',
    'Projected Job Openings (2021-2031)': 23500,
    Industries: 'Educational Services (95%)',
  },
  {
    Code: '11-9033.00',
    Occupation: 'Education Administrators, Postsecondary',
    'Projected Growth (2021-2031)': 'Average',
    'Projected Job Openings (2021-2031)': 17600,
    Industries: 'Educational Services (95%)',
  },
  {
    Code: '11-9031.00',
    Occupation: 'Education and Childcare Administrators, Preschool and Daycare',
    'Projected Growth (2021-2031)': 'Faster than average',
    'Projected Job Openings (2021-2031)': 6400,
    Industries:
      'Health Care and Social Assistance (70%), Other Services (Except Public Administration) (11%)',
  },
  {
    Code: '25-1081.00',
    Occupation: 'Education Teachers, Postsecondary',
    'Projected Growth (2021-2031)': 'Faster than average',
    'Projected Job Openings (2021-2031)': 7300,
    Industries: 'Educational Services (100%)',
  },
  {
    Code: '25-9099.00',
    Occupation: 'Educational Instruction and Library Workers, All Other',
    'Projected Growth (2021-2031)': 'Average',
    'Projected Job Openings (2021-2031)': 18200,
    Industries: 'Educational Services (91%)',
  },
  {
    Code: '21-1012.00',
    Occupation: 'Educational, Guidance, and Career Counselors and Advisors',
    'Projected Growth (2021-2031)': 'Faster than average',
    'Projected Job Openings (2021-2031)': 32000,
    Industries: 'Educational Services (88%)',
  },
  {
    Code: '49-2092.00',
    Occupation: 'Electric Motor, Power Tool, and Related Repairers',
    'Projected Growth (2021-2031)': 'Average',
    'Projected Job Openings (2021-2031)': 1500,
    Industries:
      'Wholesale Trade (41%), Other Services (Except Public Administration) (19%)',
  },
  {
    Code: '17-3023.00',
    Occupation:
      'Electrical and Electronic Engineering Technologists and Technicians',
    'Projected Growth (2021-2031)': 'Little or no change',
    'Projected Job Openings (2021-2031)': 11100,
    Industries:
      'Manufacturing (40%), Professional, Scientific, and Technical Services (23%), Government (15%)',
  },
  {
    Code: '17-3012.00',
    Occupation: 'Electrical and Electronics Drafters',
    'Projected Growth (2021-2031)': 'Little or no change',
    'Projected Job Openings (2021-2031)': 2000,
    Industries:
      'Professional, Scientific, and Technical Services (39%), Manufacturing (22%), Construction (14%), Utilities (10%)',
  },
  {
    Code: '49-2093.00',
    Occupation:
      'Electrical and Electronics Installers and Repairers, Transportation Equipment',
    'Projected Growth (2021-2031)': 'Average',
    'Projected Job Openings (2021-2031)': 900,
    Industries:
      'Transportation and Warehousing (43%), Government (20%), Manufacturing (13%)',
  },
  {
    Code: '49-2094.00',
    Occupation:
      'Electrical and Electronics Repairers, Commercial and Industrial Equipment',
    'Projected Growth (2021-2031)': 'Slower than average',
    'Projected Job Openings (2021-2031)': 5000,
    Industries: 'Manufacturing (30%), Government (24%)',
  },
  {
    Code: '49-2095.00',
    Occupation:
      'Electrical and Electronics Repairers, Powerhouse, Substation, and Relay',
    'Projected Growth (2021-2031)': 'Decline',
    'Projected Job Openings (2021-2031)': 1900,
    Industries: 'Utilities (64%), Government (17%)',
  },
  {
    Code: '17-2071.00',
    Occupation: 'Electrical Engineers',
    'Projected Growth (2021-2031)': 'Slower than average',
    'Projected Job Openings (2021-2031)': 12300,
    Industries:
      'Professional, Scientific, and Technical Services (34%), Manufacturing (33%), Utilities (10%)',
  },
  {
    Code: '49-9051.00',
    Occupation: 'Electrical Power-Line Installers and Repairers',
    'Projected Growth (2021-2031)': 'Slower than average',
    'Projected Job Openings (2021-2031)': 11100,
    Industries: 'Utilities (49%), Construction (35%), Government (13%)',
  },
  {
    Code: '',
    Occupation:
      'Electrical, Electronic, and Electromechanical Assemblers, Except Coil Winders, Tapers, and Finishers',
    'Projected Growth (2021-2031)': 'Slower than average',
    'Projected Job Openings (2021-2031)': 32800,
    Industries: 'Manufacturing (89%)',
  },
  {
    Code: '51-2022.00',
    Occupation: 'Electrical and Electronic Equipment Assemblers',
    'Projected Growth (2021-2031)': 'Slower than average',
    'Projected Job Openings (2021-2031)': 32800,
    Industries: 'Manufacturing (89%)',
  },
  {
    Code: '51-2023.00',
    Occupation: 'Electromechanical Equipment Assemblers',
    'Projected Growth (2021-2031)': 'Slower than average',
    'Projected Job Openings (2021-2031)': 32800,
    Industries: 'Manufacturing (89%)',
  },
  {
    Code: '47-2111.00',
    Occupation: 'Electricians',
    'Projected Growth (2021-2031)': 'Average',
    'Projected Job Openings (2021-2031)': 79900,
    Industries: 'Construction (72%)',
  },
  {
    Code: '17-3024.00',
    Occupation:
      'Electro-Mechanical and Mechatronics Technologists and Technicians',
    'Projected Growth (2021-2031)': 'Decline',
    'Projected Job Openings (2021-2031)': 1100,
    Industries:
      'Manufacturing (40%), Professional, Scientific, and Technical Services (29%)',
  },
  {
    Code: '17-3024.01',
    Occupation: 'Robotics Technicians',
    'Projected Growth (2021-2031)': 'Decline',
    'Projected Job Openings (2021-2031)': 1100,
    Industries:
      'Manufacturing (40%), Professional, Scientific, and Technical Services (29%)',
  },
  {
    Code: '49-2096.00',
    Occupation: 'Electronic Equipment Installers and Repairers, Motor Vehicles',
    'Projected Growth (2021-2031)': 'Decline',
    'Projected Job Openings (2021-2031)': 500,
    Industries:
      'Retail Trade (50%), Other Services (Except Public Administration) (19%), Manufacturing (10%), Wholesale Trade (10%)',
  },
  {
    Code: '17-2072.00',
    Occupation: 'Electronics Engineers, Except Computer',
    'Projected Growth (2021-2031)': 'Average',
    'Projected Job Openings (2021-2031)': 7800,
    Industries:
      'Manufacturing (30%), Professional, Scientific, and Technical Services (21%), Information (19%), Government (16%)',
  },
  {
    Code: '17-2072.01',
    Occupation: 'Radio Frequency Identification Device Specialists',
    'Projected Growth (2021-2031)': 'Average',
    'Projected Job Openings (2021-2031)': 7800,
    Industries:
      'Manufacturing (30%), Professional, Scientific, and Technical Services (21%), Information (19%), Government (16%)',
  },
  {
    Code: '25-2021.00',
    Occupation: 'Elementary School Teachers, Except Special Education',
    'Projected Growth (2021-2031)': 'Average',
    'Projected Job Openings (2021-2031)': 106100,
    Industries: 'Educational Services (99%)',
  },
  {
    Code: '47-4021.00',
    Occupation: 'Elevator and Escalator Installers and Repairers',
    'Projected Growth (2021-2031)': 'Slower than average',
    'Projected Job Openings (2021-2031)': 2100,
    Industries: 'Construction (87%)',
  },
  {
    Code: '43-4061.00',
    Occupation: 'Eligibility Interviewers, Government Programs',
    'Projected Growth (2021-2031)': 'Little or no change',
    'Projected Job Openings (2021-2031)': 15400,
    Industries: 'Government (94%)',
  },
  {
    Code: '39-4011.00',
    Occupation: 'Embalmers',
    'Projected Growth (2021-2031)': 'Average',
    'Projected Job Openings (2021-2031)': 700,
    Industries: 'Other Services (Except Public Administration) (93%)',
  },
  {
    Code: '11-9161.00',
    Occupation: 'Emergency Management Directors',
    'Projected Growth (2021-2031)': 'Slower than average',
    'Projected Job Openings (2021-2031)': 900,
    Industries: 'Government (76%)',
  },
  {
    Code: '29-2042.00',
    Occupation: 'Emergency Medical Technicians',
    'Projected Growth (2021-2031)': 'Average',
    'Projected Job Openings (2021-2031)': 13900,
    Industries: 'Health Care and Social Assistance (70%), Government (25%)',
  },
  {
    Code: '29-1214.00',
    Occupation: 'Emergency Medicine Physicians',
    'Projected Growth (2021-2031)': 'Slower than average',
    'Projected Job Openings (2021-2031)': 1300,
    Industries: 'Health Care and Social Assistance (91%)',
  },
  {
    Code: '51-2031.00',
    Occupation: 'Engine and Other Machine Assemblers',
    'Projected Growth (2021-2031)': 'Decline',
    'Projected Job Openings (2021-2031)': 5700,
    Industries: 'Manufacturing (93%)',
  },
  {
    Code: '25-1032.00',
    Occupation: 'Engineering Teachers, Postsecondary',
    'Projected Growth (2021-2031)': 'Much faster than average',
    'Projected Job Openings (2021-2031)': 4700,
    Industries: 'Educational Services (100%)',
  },
  {
    Code: '17-3029.00',
    Occupation:
      'Engineering Technologists and Technicians, Except Drafters, All Other',
    'Projected Growth (2021-2031)': 'Slower than average',
    'Projected Job Openings (2021-2031)': 7800,
    Industries:
      'Professional, Scientific, and Technical Services (29%), Government (26%), Manufacturing (23%), Administrative and Support Services (10%)',
  },
  {
    Code: '17-3029.01',
    Occupation: 'Non-Destructive Testing Specialists',
    'Projected Growth (2021-2031)': 'Slower than average',
    'Projected Job Openings (2021-2031)': 7800,
    Industries:
      'Professional, Scientific, and Technical Services (29%), Government (26%), Manufacturing (23%), Administrative and Support Services (10%)',
  },
  {
    Code: '17-3029.08',
    Occupation: 'Photonics Technicians',
    'Projected Growth (2021-2031)': 'Slower than average',
    'Projected Job Openings (2021-2031)': 7800,
    Industries:
      'Professional, Scientific, and Technical Services (29%), Government (26%), Manufacturing (23%), Administrative and Support Services (10%)',
  },
  {
    Code: '17-2199.00',
    Occupation: 'Engineers, All Other',
    'Projected Growth (2021-2031)': 'Little or no change',
    'Projected Job Openings (2021-2031)': 10800,
    Industries:
      'Professional, Scientific, and Technical Services (26%), Government (24%), Manufacturing (23%)',
  },
  {
    Code: '17-2199.03',
    Occupation: 'Energy Engineers, Except Wind and Solar',
    'Projected Growth (2021-2031)': 'Little or no change',
    'Projected Job Openings (2021-2031)': 10800,
    Industries:
      'Professional, Scientific, and Technical Services (26%), Government (24%), Manufacturing (23%)',
  },
  {
    Code: '17-2199.05',
    Occupation: 'Mechatronics Engineers',
    'Projected Growth (2021-2031)': 'Little or no change',
    'Projected Job Openings (2021-2031)': 10800,
    Industries:
      'Professional, Scientific, and Technical Services (26%), Government (24%), Manufacturing (23%)',
  },
  {
    Code: '17-2199.06',
    Occupation: 'Microsystems Engineers',
    'Projected Growth (2021-2031)': 'Little or no change',
    'Projected Job Openings (2021-2031)': 10800,
    Industries:
      'Professional, Scientific, and Technical Services (26%), Government (24%), Manufacturing (23%)',
  },
  {
    Code: '17-2199.07',
    Occupation: 'Photonics Engineers',
    'Projected Growth (2021-2031)': 'Little or no change',
    'Projected Job Openings (2021-2031)': 10800,
    Industries:
      'Professional, Scientific, and Technical Services (26%), Government (24%), Manufacturing (23%)',
  },
  {
    Code: '17-2199.08',
    Occupation: 'Robotics Engineers',
    'Projected Growth (2021-2031)': 'Little or no change',
    'Projected Job Openings (2021-2031)': 10800,
    Industries:
      'Professional, Scientific, and Technical Services (26%), Government (24%), Manufacturing (23%)',
  },
  {
    Code: '17-2199.09',
    Occupation: 'Nanosystems Engineers',
    'Projected Growth (2021-2031)': 'Little or no change',
    'Projected Job Openings (2021-2031)': 10800,
    Industries:
      'Professional, Scientific, and Technical Services (26%), Government (24%), Manufacturing (23%)',
  },
  {
    Code: '17-2199.10',
    Occupation: 'Wind Energy Engineers',
    'Projected Growth (2021-2031)': 'Little or no change',
    'Projected Job Openings (2021-2031)': 10800,
    Industries:
      'Professional, Scientific, and Technical Services (26%), Government (24%), Manufacturing (23%)',
  },
  {
    Code: '17-2199.11',
    Occupation: 'Solar Energy Systems Engineers',
    'Projected Growth (2021-2031)': 'Little or no change',
    'Projected Job Openings (2021-2031)': 10800,
    Industries:
      'Professional, Scientific, and Technical Services (26%), Government (24%), Manufacturing (23%)',
  },
  {
    Code: '25-1123.00',
    Occupation: 'English Language and Literature Teachers, Postsecondary',
    'Projected Growth (2021-2031)': 'Average',
    'Projected Job Openings (2021-2031)': 6700,
    Industries: 'Educational Services (100%)',
  },
  {
    Code: '27-2099.00',
    Occupation:
      'Entertainers and Performers, Sports and Related Workers, All Other',
    'Projected Growth (2021-2031)': 'Much faster than average',
    'Projected Job Openings (2021-2031)': 4700,
    Industries: 'Arts, Entertainment, and Recreation (11%)',
  },
  {
    Code: '11-9072.00',
    Occupation: 'Entertainment and Recreation Managers, Except Gambling',
    'Projected Growth (2021-2031)': 'Much faster than average',
    'Projected Job Openings (2021-2031)': 3000,
    Industries: 'Arts, Entertainment, and Recreation (49%), Government (20%)',
  },
  {
    Code: '39-3099.00',
    Occupation: 'Entertainment Attendants and Related Workers, All Other',
    'Projected Growth (2021-2031)': 'Much faster than average',
    'Projected Job Openings (2021-2031)': 1500,
    Industries: 'Arts, Entertainment, and Recreation (57%), Government (11%)',
  },
  {
    Code: '17-3025.00',
    Occupation: 'Environmental Engineering Technologists and Technicians',
    'Projected Growth (2021-2031)': 'Average',
    'Projected Job Openings (2021-2031)': 1600,
    Industries:
      'Professional, Scientific, and Technical Services (43%), Government (22%), Administrative and Support Services (16%)',
  },
  {
    Code: '17-2081.00',
    Occupation: 'Environmental Engineers',
    'Projected Growth (2021-2031)': 'Average',
    'Projected Job Openings (2021-2031)': 3400,
    Industries:
      'Professional, Scientific, and Technical Services (52%), Government (28%)',
  },
  {
    Code: '19-4042.00',
    Occupation:
      'Environmental Science and Protection Technicians, Including Health',
    'Projected Growth (2021-2031)': 'Average',
    'Projected Job Openings (2021-2031)': 4000,
    Industries:
      'Professional, Scientific, and Technical Services (52%), Government (24%)',
  },
  {
    Code: '25-1053.00',
    Occupation: 'Environmental Science Teachers, Postsecondary',
    'Projected Growth (2021-2031)': 'Faster than average',
    'Projected Job Openings (2021-2031)': 700,
    Industries: 'Educational Services (100%)',
  },
  {
    Code: '19-2041.00',
    Occupation: 'Environmental Scientists and Specialists, Including Health',
    'Projected Growth (2021-2031)': 'Average',
    'Projected Job Openings (2021-2031)': 7800,
    Industries:
      'Government (47%), Professional, Scientific, and Technical Services (39%)',
  },
  {
    Code: '19-2041.01',
    Occupation: 'Climate Change Policy Analysts',
    'Projected Growth (2021-2031)': 'Average',
    'Projected Job Openings (2021-2031)': 7800,
    Industries:
      'Government (47%), Professional, Scientific, and Technical Services (39%)',
  },
  {
    Code: '19-2041.02',
    Occupation: 'Environmental Restoration Planners',
    'Projected Growth (2021-2031)': 'Average',
    'Projected Job Openings (2021-2031)': 7800,
    Industries:
      'Government (47%), Professional, Scientific, and Technical Services (39%)',
  },
  {
    Code: '19-2041.03',
    Occupation: 'Industrial Ecologists',
    'Projected Growth (2021-2031)': 'Average',
    'Projected Job Openings (2021-2031)': 7800,
    Industries:
      'Government (47%), Professional, Scientific, and Technical Services (39%)',
  },
  {
    Code: '19-1041.00',
    Occupation: 'Epidemiologists',
    'Projected Growth (2021-2031)': 'Much faster than average',
    'Projected Job Openings (2021-2031)': 800,
    Industries: 'Government (58%), Health Care and Social Assistance (17%)',
  },
  {
    Code: '51-9194.00',
    Occupation: 'Etchers and Engravers',
    'Projected Growth (2021-2031)': 'Slower than average',
    'Projected Job Openings (2021-2031)': 800,
    Industries: 'Manufacturing (49%), Retail Trade (41%)',
  },
  {
    Code: '47-5022.00',
    Occupation:
      'Excavating and Loading Machine and Dragline Operators, Surface Mining',
    'Projected Growth (2021-2031)': 'Little or no change',
    'Projected Job Openings (2021-2031)': 3800,
    Industries:
      'Mining, Quarrying, and Oil and Gas Extraction (49%), Construction (37%)',
  },
  {
    Code: '43-6011.00',
    Occupation: 'Executive Secretaries and Executive Administrative Assistants',
    'Projected Growth (2021-2031)': 'Decline',
    'Projected Job Openings (2021-2031)': 42300,
    Industries:
      'Educational Services (17%), Government (13%), Professional, Scientific, and Technical Services (12%), Finance and Insurance (11%)',
  },
  {
    Code: '29-1128.00',
    Occupation: 'Exercise Physiologists',
    'Projected Growth (2021-2031)': 'Faster than average',
    'Projected Job Openings (2021-2031)': 1700,
    Industries: 'Health Care and Social Assistance (29%)',
  },
  {
    Code: '39-9031.00',
    Occupation: 'Exercise Trainers and Group Fitness Instructors',
    'Projected Growth (2021-2031)': 'Much faster than average',
    'Projected Job Openings (2021-2031)': 65500,
    Industries: 'Arts, Entertainment, and Recreation (53%)',
  },
  {
    Code: '47-5032.00',
    Occupation: 'Explosives Workers, Ordnance Handling Experts, and Blasters',
    'Projected Growth (2021-2031)': 'Slower than average',
    'Projected Job Openings (2021-2031)': 700,
    Industries:
      'Government (23%), Mining, Quarrying, and Oil and Gas Extraction (13%), Construction (13%), Wholesale Trade (13%)',
  },
  {
    Code: '47-5099.00',
    Occupation: 'Extraction Workers, All Other',
    'Projected Growth (2021-2031)': 'Faster than average',
    'Projected Job Openings (2021-2031)': 700,
    Industries:
      'Mining, Quarrying, and Oil and Gas Extraction (73%), Construction (10%)',
  },
  {
    Code: '51-4021.00',
    Occupation:
      'Extruding and Drawing Machine Setters, Operators, and Tenders, Metal and Plastic',
    'Projected Growth (2021-2031)': 'Little or no change',
    'Projected Job Openings (2021-2031)': 6600,
    Industries: 'Manufacturing (93%)',
  },
  {
    Code: '51-6091.00',
    Occupation:
      'Extruding and Forming Machine Setters, Operators, and Tenders, Synthetic and Glass Fibers',
    'Projected Growth (2021-2031)': 'Decline',
    'Projected Job Openings (2021-2031)': 1500,
    Industries: 'Manufacturing (99%)',
  },
  {
    Code: '51-9041.00',
    Occupation:
      'Extruding, Forming, Pressing, and Compacting Machine Setters, Operators, and Tenders',
    'Projected Growth (2021-2031)': 'Average',
    'Projected Job Openings (2021-2031)': 6300,
    Industries: 'Manufacturing (93%)',
  },
  {
    Code: '51-6092.00',
    Occupation: 'Fabric and Apparel Patternmakers',
    'Projected Growth (2021-2031)': 'Decline',
    'Projected Job Openings (2021-2031)': 300,
    Industries:
      'Manufacturing (60%), Management of Companies and Enterprises (20%)',
  },
  {
    Code: '11-3013.00',
    Occupation: 'Facilities Managers',
    'Projected Growth (2021-2031)': 'Average',
    'Projected Job Openings (2021-2031)': 9900,
    Industries:
      'Educational Services (15%), Government (11%), Health Care and Social Assistance (11%), Manufacturing (11%)',
  },
  {
    Code: '11-3013.01',
    Occupation: 'Security Managers',
    'Projected Growth (2021-2031)': 'Average',
    'Projected Job Openings (2021-2031)': 9900,
    Industries:
      'Educational Services (15%), Government (11%), Health Care and Social Assistance (11%), Manufacturing (11%)',
  },
  {
    Code: '45-4021.00',
    Occupation: 'Fallers',
    'Projected Growth (2021-2031)': 'Decline',
    'Projected Job Openings (2021-2031)': 800,
    Industries:
      'Agriculture, Forestry, Fishing, and Hunting (49%), Administrative and Support Services (18%)',
  },
  {
    Code: '25-1192.00',
    Occupation: 'Family and Consumer Sciences Teachers, Postsecondary',
    'Projected Growth (2021-2031)': 'Average',
    'Projected Job Openings (2021-2031)': 300,
    Industries: 'Educational Services (100%)',
  },
  {
    Code: '29-1215.00',
    Occupation: 'Family Medicine Physicians',
    'Projected Growth (2021-2031)': 'Slower than average',
    'Projected Job Openings (2021-2031)': 3600,
    Industries: 'Health Care and Social Assistance (89%)',
  },
  {
    Code: '25-9021.00',
    Occupation: 'Farm and Home Management Educators',
    'Projected Growth (2021-2031)': 'Little or no change',
    'Projected Job Openings (2021-2031)': 1200,
    Industries: 'Educational Services (83%), Government (11%)',
  },
  {
    Code: '49-3041.00',
    Occupation: 'Farm Equipment Mechanics and Service Technicians',
    'Projected Growth (2021-2031)': 'Much faster than average',
    'Projected Job Openings (2021-2031)': 5500,
    Industries:
      'Wholesale Trade (55%), Agriculture, Forestry, Fishing, and Hunting (30%)',
  },
  {
    Code: '13-1074.00',
    Occupation: 'Farm Labor Contractors',
    'Projected Growth (2021-2031)': 'Much faster than average',
    'Projected Job Openings (2021-2031)': 200,
    Industries: 'Agriculture, Forestry, Fishing, and Hunting (98%)',
  },
  {
    Code: '11-9013.00',
    Occupation: 'Farmers, Ranchers, and Other Agricultural Managers',
    'Projected Growth (2021-2031)': 'Decline',
    'Projected Job Openings (2021-2031)': 85600,
    Industries: 'Agriculture, Forestry, Fishing, and Hunting (31%)',
  },
  {
    Code: '45-2092.00',
    Occupation: 'Farmworkers and Laborers, Crop, Nursery, and Greenhouse',
    'Projected Growth (2021-2031)': 'Slower than average',
    'Projected Job Openings (2021-2031)': 92600,
    Industries: 'Agriculture, Forestry, Fishing, and Hunting (81%)',
  },
  {
    Code: '45-2093.00',
    Occupation: 'Farmworkers, Farm, Ranch, and Aquacultural Animals',
    'Projected Growth (2021-2031)': 'Decline',
    'Projected Job Openings (2021-2031)': 34000,
    Industries: 'Agriculture, Forestry, Fishing, and Hunting (89%)',
  },
  {
    Code: '27-1022.00',
    Occupation: 'Fashion Designers',
    'Projected Growth (2021-2031)': 'Slower than average',
    'Projected Job Openings (2021-2031)': 2300,
    Industries:
      'Wholesale Trade (36%), Management of Companies and Enterprises (18%), Retail Trade (11%), Manufacturing (10%)',
  },
  {
    Code: '35-3023.00',
    Occupation: 'Fast Food and Counter Workers',
    'Projected Growth (2021-2031)': 'Faster than average',
    'Projected Job Openings (2021-2031)': 741400,
    Industries: 'Accommodation and Food Services (86%)',
  },
  {
    Code: '35-3023.01',
    Occupation: 'Baristas',
    'Projected Growth (2021-2031)': 'Faster than average',
    'Projected Job Openings (2021-2031)': 741400,
    Industries: 'Accommodation and Food Services (86%)',
  },
  {
    Code: '47-4031.00',
    Occupation: 'Fence Erectors',
    'Projected Growth (2021-2031)': 'Little or no change',
    'Projected Job Openings (2021-2031)': 3000,
    Industries: 'Construction (73%)',
  },
  {
    Code: '51-2051.00',
    Occupation: 'Fiberglass Laminators and Fabricators',
    'Projected Growth (2021-2031)': 'Little or no change',
    'Projected Job Openings (2021-2031)': 2100,
    Industries: 'Manufacturing (93%)',
  },
  {
    Code: '43-4071.00',
    Occupation: 'File Clerks',
    'Projected Growth (2021-2031)': 'Decline',
    'Projected Job Openings (2021-2031)': 10000,
    Industries:
      'Health Care and Social Assistance (17%), Government (17%), Professional, Scientific, and Technical Services (17%)',
  },
  {
    Code: '27-4032.00',
    Occupation: 'Film and Video Editors',
    'Projected Growth (2021-2031)': 'Much faster than average',
    'Projected Job Openings (2021-2031)': 5700,
    Industries: 'Information (49%)',
  },
  {
    Code: '13-2051.00',
    Occupation: 'Financial and Investment Analysts',
    'Projected Growth (2021-2031)': 'Faster than average',
    'Projected Job Openings (2021-2031)': 26900,
    Industries:
      'Finance and Insurance (43%), Professional, Scientific, and Technical Services (12%), Management of Companies and Enterprises (11%)',
  },
  {
    Code: '43-3099.00',
    Occupation: 'Financial Clerks, All Other',
    'Projected Growth (2021-2031)': 'Average',
    'Projected Job Openings (2021-2031)': 3800,
    Industries:
      'Finance and Insurance (49%), Management of Companies and Enterprises (10%)',
  },
  {
    Code: '13-2061.00',
    Occupation: 'Financial Examiners',
    'Projected Growth (2021-2031)': 'Much faster than average',
    'Projected Job Openings (2021-2031)': 6800,
    Industries: 'Finance and Insurance (66%), Government (19%)',
  },
  {
    Code: '11-3031.00',
    Occupation: 'Financial Managers',
    'Projected Growth (2021-2031)': 'Much faster than average',
    'Projected Job Openings (2021-2031)': 71300,
    Industries:
      'Finance and Insurance (30%), Professional, Scientific, and Technical Services (15%), Management of Companies and Enterprises (10%)',
  },
  {
    Code: '11-3031.01',
    Occupation: 'Treasurers and Controllers',
    'Projected Growth (2021-2031)': 'Much faster than average',
    'Projected Job Openings (2021-2031)': 71300,
    Industries:
      'Finance and Insurance (30%), Professional, Scientific, and Technical Services (15%), Management of Companies and Enterprises (10%)',
  },
  {
    Code: '11-3031.03',
    Occupation: 'Investment Fund Managers',
    'Projected Growth (2021-2031)': 'Much faster than average',
    'Projected Job Openings (2021-2031)': 71300,
    Industries:
      'Finance and Insurance (30%), Professional, Scientific, and Technical Services (15%), Management of Companies and Enterprises (10%)',
  },
  {
    Code: '13-2054.00',
    Occupation: 'Financial Risk Specialists',
    'Projected Growth (2021-2031)': 'Faster than average',
    'Projected Job Openings (2021-2031)': 5100,
    Industries:
      'Finance and Insurance (61%), Management of Companies and Enterprises (14%), Professional, Scientific, and Technical Services (10%)',
  },
  {
    Code: '13-2099.00',
    Occupation: 'Financial Specialists, All Other',
    'Projected Growth (2021-2031)': 'Average',
    'Projected Job Openings (2021-2031)': 11400,
    Industries: 'Finance and Insurance (29%), Government (26%)',
  },
  {
    Code: '13-2099.01',
    Occupation: 'Financial Quantitative Analysts',
    'Projected Growth (2021-2031)': 'Average',
    'Projected Job Openings (2021-2031)': 11400,
    Industries: 'Finance and Insurance (29%), Government (26%)',
  },
  {
    Code: '13-2099.04',
    Occupation: 'Fraud Examiners, Investigators and Analysts',
    'Projected Growth (2021-2031)': 'Average',
    'Projected Job Openings (2021-2031)': 11400,
    Industries: 'Finance and Insurance (29%), Government (26%)',
  },
  {
    Code: '27-1013.00',
    Occupation: 'Fine Artists, Including Painters, Sculptors, and Illustrators',
    'Projected Growth (2021-2031)': 'Average',
    'Projected Job Openings (2021-2031)': 3100,
    Industries: 'Arts, Entertainment, and Recreation (12%)',
  },
  {
    Code: '33-2021.00',
    Occupation: 'Fire Inspectors and Investigators',
    'Projected Growth (2021-2031)': 'Slower than average',
    'Projected Job Openings (2021-2031)': 1500,
    Industries: 'Government (75%)',
  },
  {
    Code: '33-2011.00',
    Occupation: 'Firefighters',
    'Projected Growth (2021-2031)': 'Average',
    'Projected Job Openings (2021-2031)': 28000,
    Industries: 'Government (93%)',
  },
  {
    Code: '47-1011.00',
    Occupation:
      'First-Line Supervisors of Construction Trades and Extraction Workers',
    'Projected Growth (2021-2031)': 'Average',
    'Projected Job Openings (2021-2031)': 72700,
    Industries: 'Construction (70%)',
  },
  {
    Code: '47-1011.03',
    Occupation: 'Solar Energy Installation Managers',
    'Projected Growth (2021-2031)': 'Average',
    'Projected Job Openings (2021-2031)': 72700,
    Industries: 'Construction (70%)',
  },
  {
    Code: '33-1011.00',
    Occupation: 'First-Line Supervisors of Correctional Officers',
    'Projected Growth (2021-2031)': 'Decline',
    'Projected Job Openings (2021-2031)': 4300,
    Industries: 'Government (97%)',
  },
  {
    Code: '39-1014.00',
    Occupation:
      'First-Line Supervisors of Entertainment and Recreation Workers, Except Gambling Services',
    'Projected Growth (2021-2031)': 'Much faster than average',
    'Projected Job Openings (2021-2031)': 14200,
    Industries: 'Arts, Entertainment, and Recreation (32%), Government (16%)',
  },
  {
    Code: '45-1011.00',
    Occupation:
      'First-Line Supervisors of Farming, Fishing, and Forestry Workers',
    'Projected Growth (2021-2031)': 'Average',
    'Projected Job Openings (2021-2031)': 8200,
    Industries: 'Agriculture, Forestry, Fishing, and Hunting (62%)',
  },
  {
    Code: '33-1021.00',
    Occupation: 'First-Line Supervisors of Firefighting and Prevention Workers',
    'Projected Growth (2021-2031)': 'Average',
    'Projected Job Openings (2021-2031)': 6500,
    Industries: 'Government (96%)',
  },
  {
    Code: '35-1012.00',
    Occupation:
      'First-Line Supervisors of Food Preparation and Serving Workers',
    'Projected Growth (2021-2031)': 'Much faster than average',
    'Projected Job Openings (2021-2031)': 196700,
    Industries: 'Accommodation and Food Services (84%)',
  },
  {
    Code: '39-1013.00',
    Occupation: 'First-Line Supervisors of Gambling Services Workers',
    'Projected Growth (2021-2031)': 'Much faster than average',
    'Projected Job Openings (2021-2031)': 3800,
    Industries:
      'Accommodation and Food Services (28%), Government (28%), Arts, Entertainment, and Recreation (18%)',
  },
  {
    Code: '37-1011.00',
    Occupation: 'First-Line Supervisors of Housekeeping and Janitorial Workers',
    'Projected Growth (2021-2031)': 'Average',
    'Projected Job Openings (2021-2031)': 31100,
    Industries:
      'Administrative and Support Services (26%), Accommodation and Food Services (13%)',
  },
  {
    Code: '37-1012.00',
    Occupation:
      'First-Line Supervisors of Landscaping, Lawn Service, and Groundskeeping Workers',
    'Projected Growth (2021-2031)': 'Slower than average',
    'Projected Job Openings (2021-2031)': 23600,
    Industries: 'Administrative and Support Services (36%)',
  },
  {
    Code: '49-1011.00',
    Occupation:
      'First-Line Supervisors of Mechanics, Installers, and Repairers',
    'Projected Growth (2021-2031)': 'Average',
    'Projected Job Openings (2021-2031)': 53500,
    Industries:
      'Other Services (Except Public Administration) (12%), Construction (11%), Retail Trade (11%), Government (11%), Manufacturing (10%), Real Estate and Rental and Leasing (10%)',
  },
  {
    Code: '41-1012.00',
    Occupation: 'First-Line Supervisors of Non-Retail Sales Workers',
    'Projected Growth (2021-2031)': 'Little or no change',
    'Projected Job Openings (2021-2031)': 37100,
    Industries: 'Wholesale Trade (17%), Finance and Insurance (11%)',
  },
  {
    Code: '43-1011.00',
    Occupation:
      'First-Line Supervisors of Office and Administrative Support Workers',
    'Projected Growth (2021-2031)': 'Decline',
    'Projected Job Openings (2021-2031)': 159600,
    Industries:
      'Health Care and Social Assistance (16%), Finance and Insurance (14%)',
  },
  {
    Code: '39-1022.00',
    Occupation: 'First-Line Supervisors of Personal Service Workers',
    'Projected Growth (2021-2031)': 'Much faster than average',
    'Projected Job Openings (2021-2031)': 22300,
    Industries:
      'Other Services (Except Public Administration) (25%), Health Care and Social Assistance (22%)',
  },
  {
    Code: '33-1012.00',
    Occupation: 'First-Line Supervisors of Police and Detectives',
    'Projected Growth (2021-2031)': 'Slower than average',
    'Projected Job Openings (2021-2031)': 9300,
    Industries: 'Government (96%)',
  },
  {
    Code: '51-1011.00',
    Occupation: 'First-Line Supervisors of Production and Operating Workers',
    'Projected Growth (2021-2031)': 'Slower than average',
    'Projected Job Openings (2021-2031)': 68700,
    Industries: 'Manufacturing (74%)',
  },
  {
    Code: '33-1099.00',
    Occupation:
      'First-Line Supervisors of Protective Service Workers, All Other',
    'Projected Growth (2021-2031)': 'Average',
    'Projected Job Openings (2021-2031)': 2600,
    Industries:
      'Government (45%), Administrative and Support Services (15%), Retail Trade (10%)',
  },
  {
    Code: '41-1011.00',
    Occupation: 'First-Line Supervisors of Retail Sales Workers',
    'Projected Growth (2021-2031)': 'Decline',
    'Projected Job Openings (2021-2031)': 153000,
    Industries: 'Retail Trade (69%)',
  },
  {
    Code: '33-1091.00',
    Occupation: 'First-Line Supervisors of Security Workers',
    'Projected Growth (2021-2031)': 'Little or no change',
    'Projected Job Openings (2021-2031)': 5600,
    Industries: 'Administrative and Support Services (48%)',
  },
  {
    Code: '',
    Occupation:
      'First-Line Supervisors of Transportation and Material Moving Workers, Except Aircraft Cargo Handling Supervisors',
    'Projected Growth (2021-2031)': 'Average',
    'Projected Job Openings (2021-2031)': 71300,
    Industries:
      'Transportation and Warehousing (30%), Retail Trade (21%), Wholesale Trade (16%)',
  },
  {
    Code: '53-1042.00',
    Occupation:
      'First-Line Supervisors of Helpers, Laborers, and Material Movers, Hand',
    'Projected Growth (2021-2031)': 'Average',
    'Projected Job Openings (2021-2031)': 71300,
    Industries:
      'Transportation and Warehousing (30%), Retail Trade (21%), Wholesale Trade (16%)',
  },
  {
    Code: '53-1042.01',
    Occupation: 'Recycling Coordinators',
    'Projected Growth (2021-2031)': 'Average',
    'Projected Job Openings (2021-2031)': 71300,
    Industries:
      'Transportation and Warehousing (30%), Retail Trade (21%), Wholesale Trade (16%)',
  },
  {
    Code: '53-1043.00',
    Occupation:
      'First-Line Supervisors of Material-Moving Machine and Vehicle Operators',
    'Projected Growth (2021-2031)': 'Average',
    'Projected Job Openings (2021-2031)': 71300,
    Industries:
      'Transportation and Warehousing (30%), Retail Trade (21%), Wholesale Trade (16%)',
  },
  {
    Code: '53-1044.00',
    Occupation: 'First-Line Supervisors of Passenger Attendants',
    'Projected Growth (2021-2031)': 'Average',
    'Projected Job Openings (2021-2031)': 71300,
    Industries:
      'Transportation and Warehousing (30%), Retail Trade (21%), Wholesale Trade (16%)',
  },
  {
    Code: '53-1049.00',
    Occupation: 'First-Line Supervisors of Transportation Workers, All Other',
    'Projected Growth (2021-2031)': 'Average',
    'Projected Job Openings (2021-2031)': 71300,
    Industries:
      'Transportation and Warehousing (30%), Retail Trade (21%), Wholesale Trade (16%)',
  },
  {
    Code: '33-3031.00',
    Occupation: 'Fish and Game Wardens',
    'Projected Growth (2021-2031)': 'Decline',
    'Projected Job Openings (2021-2031)': 700,
    Industries: 'Government (100%)',
  },
  {
    Code: '45-3031.00',
    Occupation: 'Fishing and Hunting Workers',
    'Projected Growth (2021-2031)': 'Slower than average',
    'Projected Job Openings (2021-2031)': 4400,
    Industries: 'Agriculture, Forestry, Fishing, and Hunting (55%)',
  },
  {
    Code: '53-2031.00',
    Occupation: 'Flight Attendants',
    'Projected Growth (2021-2031)': 'Much faster than average',
    'Projected Job Openings (2021-2031)': 18100,
    Industries: 'Transportation and Warehousing (97%)',
  },
  {
    Code: '47-2042.00',
    Occupation: 'Floor Layers, Except Carpet, Wood, and Hard Tiles',
    'Projected Growth (2021-2031)': 'Faster than average',
    'Projected Job Openings (2021-2031)': 2500,
    Industries: 'Construction (58%), Retail Trade (12%)',
  },
  {
    Code: '47-2043.00',
    Occupation: 'Floor Sanders and Finishers',
    'Projected Growth (2021-2031)': 'Slower than average',
    'Projected Job Openings (2021-2031)': 500,
    Industries: 'Construction (67%)',
  },
  {
    Code: '27-1023.00',
    Occupation: 'Floral Designers',
    'Projected Growth (2021-2031)': 'Decline',
    'Projected Job Openings (2021-2031)': 3800,
    Industries: 'Retail Trade (74%)',
  },
  {
    Code: '51-3091.00',
    Occupation:
      'Food and Tobacco Roasting, Baking, and Drying Machine Operators and Tenders',
    'Projected Growth (2021-2031)': 'Slower than average',
    'Projected Job Openings (2021-2031)': 2800,
    Industries: 'Manufacturing (78%)',
  },
  {
    Code: '51-3092.00',
    Occupation: 'Food Batchmakers',
    'Projected Growth (2021-2031)': 'Average',
    'Projected Job Openings (2021-2031)': 23500,
    Industries: 'Manufacturing (82%)',
  },
  {
    Code: '51-3093.00',
    Occupation: 'Food Cooking Machine Operators and Tenders',
    'Projected Growth (2021-2031)': 'Little or no change',
    'Projected Job Openings (2021-2031)': 5000,
    Industries: 'Manufacturing (79%), Accommodation and Food Services (10%)',
  },
  {
    Code: '35-9099.00',
    Occupation: 'Food Preparation and Serving Related Workers, All Other',
    'Projected Growth (2021-2031)': 'Much faster than average',
    'Projected Job Openings (2021-2031)': 22500,
    Industries: 'Accommodation and Food Services (74%)',
  },
  {
    Code: '35-2021.00',
    Occupation: 'Food Preparation Workers',
    'Projected Growth (2021-2031)': 'Slower than average',
    'Projected Job Openings (2021-2031)': 145800,
    Industries: 'Accommodation and Food Services (57%), Retail Trade (27%)',
  },
  {
    Code: '51-3099.00',
    Occupation: 'Food Processing Workers, All Other',
    'Projected Growth (2021-2031)': 'Little or no change',
    'Projected Job Openings (2021-2031)': 5400,
    Industries:
      'Manufacturing (55%), Retail Trade (23%), Administrative and Support Services (10%)',
  },
  {
    Code: '19-4013.00',
    Occupation: 'Food Science Technicians',
    'Projected Growth (2021-2031)': 'Faster than average',
    'Projected Job Openings (2021-2031)': 2300,
    Industries:
      'Manufacturing (60%), Agriculture, Forestry, Fishing, and Hunting (24%)',
  },
  {
    Code: '19-1012.00',
    Occupation: 'Food Scientists and Technologists',
    'Projected Growth (2021-2031)': 'Average',
    'Projected Job Openings (2021-2031)': 1500,
    Industries:
      'Manufacturing (51%), Professional, Scientific, and Technical Services (17%), Management of Companies and Enterprises (13%)',
  },
  {
    Code: '35-3041.00',
    Occupation: 'Food Servers, Nonrestaurant',
    'Projected Growth (2021-2031)': 'Average',
    'Projected Job Openings (2021-2031)': 42400,
    Industries:
      'Health Care and Social Assistance (68%), Accommodation and Food Services (21%)',
  },
  {
    Code: '11-9051.00',
    Occupation: 'Food Service Managers',
    'Projected Growth (2021-2031)': 'Faster than average',
    'Projected Job Openings (2021-2031)': 45000,
    Industries: 'Accommodation and Food Services (57%)',
  },
  {
    Code: '25-1124.00',
    Occupation: 'Foreign Language and Literature Teachers, Postsecondary',
    'Projected Growth (2021-2031)': 'Faster than average',
    'Projected Job Openings (2021-2031)': 2400,
    Industries: 'Educational Services (99%)',
  },
  {
    Code: '19-4092.00',
    Occupation: 'Forensic Science Technicians',
    'Projected Growth (2021-2031)': 'Much faster than average',
    'Projected Job Openings (2021-2031)': 2500,
    Industries: 'Government (90%)',
  },
  {
    Code: '19-4071.00',
    Occupation: 'Forest and Conservation Technicians',
    'Projected Growth (2021-2031)': 'Decline',
    'Projected Job Openings (2021-2031)': 3900,
    Industries: 'Government (91%)',
  },
  {
    Code: '45-4011.00',
    Occupation: 'Forest and Conservation Workers',
    'Projected Growth (2021-2031)': 'Decline',
    'Projected Job Openings (2021-2031)': 2300,
    Industries:
      'Agriculture, Forestry, Fishing, and Hunting (41%), Government (31%)',
  },
  {
    Code: '33-2022.00',
    Occupation: 'Forest Fire Inspectors and Prevention Specialists',
    'Projected Growth (2021-2031)': 'Much faster than average',
    'Projected Job Openings (2021-2031)': 300,
    Industries: 'Government (96%)',
  },
  {
    Code: '19-1032.00',
    Occupation: 'Foresters',
    'Projected Growth (2021-2031)': 'Average',
    'Projected Job Openings (2021-2031)': 1500,
    Industries:
      'Agriculture, Forestry, Fishing, and Hunting (38%), Government (37%)',
  },
  {
    Code: '25-1043.00',
    Occupation: 'Forestry and Conservation Science Teachers, Postsecondary',
    'Projected Growth (2021-2031)': 'Faster than average',
    'Projected Job Openings (2021-2031)': 100,
    Industries: 'Educational Services (100%)',
  },
  {
    Code: '51-4022.00',
    Occupation:
      'Forging Machine Setters, Operators, and Tenders, Metal and Plastic',
    'Projected Growth (2021-2031)': 'Decline',
    'Projected Job Openings (2021-2031)': 900,
    Industries: 'Manufacturing (93%)',
  },
  {
    Code: '51-4071.00',
    Occupation: 'Foundry Mold and Coremakers',
    'Projected Growth (2021-2031)': 'Decline',
    'Projected Job Openings (2021-2031)': 1200,
    Industries: 'Manufacturing (97%)',
  },
  {
    Code: '13-1131.00',
    Occupation: 'Fundraisers',
    'Projected Growth (2021-2031)': 'Much faster than average',
    'Projected Job Openings (2021-2031)': 11400,
    Industries:
      'Other Services (Except Public Administration) (41%), Educational Services (25%), Health Care and Social Assistance (17%)',
  },
  {
    Code: '11-2033.00',
    Occupation: 'Fundraising Managers',
    'Projected Growth (2021-2031)': 'Faster than average',
    'Projected Job Openings (2021-2031)': 3000,
    Industries:
      'Other Services (Except Public Administration) (41%), Educational Services (28%), Health Care and Social Assistance (15%)',
  },
  {
    Code: '39-4021.00',
    Occupation: 'Funeral Attendants',
    'Projected Growth (2021-2031)': 'Faster than average',
    'Projected Job Openings (2021-2031)': 6000,
    Industries: 'Other Services (Except Public Administration) (96%)',
  },
  {
    Code: '11-9171.00',
    Occupation: 'Funeral Home Managers',
    'Projected Growth (2021-2031)': 'Average',
    'Projected Job Openings (2021-2031)': 3600,
    Industries: 'Other Services (Except Public Administration) (32%)',
  },
  {
    Code: '51-9051.00',
    Occupation: 'Furnace, Kiln, Oven, Drier, and Kettle Operators and Tenders',
    'Projected Growth (2021-2031)': 'Slower than average',
    'Projected Job Openings (2021-2031)': 1800,
    Industries: 'Manufacturing (84%)',
  },
  {
    Code: '51-7021.00',
    Occupation: 'Furniture Finishers',
    'Projected Growth (2021-2031)': 'Slower than average',
    'Projected Job Openings (2021-2031)': 2700,
    Industries:
      'Manufacturing (60%), Other Services (Except Public Administration) (11%)',
  },
  {
    Code: '39-3012.00',
    Occupation: 'Gambling and Sports Book Writers and Runners',
    'Projected Growth (2021-2031)': 'Little or no change',
    'Projected Job Openings (2021-2031)': 1400,
    Industries:
      'Arts, Entertainment, and Recreation (58%), Other Services (Except Public Administration) (19%), Government (11%)',
  },
  {
    Code: '43-3041.00',
    Occupation: 'Gambling Cage Workers',
    'Projected Growth (2021-2031)': 'Little or no change',
    'Projected Job Openings (2021-2031)': 1300,
    Industries:
      'Government (51%), Arts, Entertainment, and Recreation (25%), Accommodation and Food Services (22%)',
  },
  {
    Code: '41-2012.00',
    Occupation: 'Gambling Change Persons and Booth Cashiers',
    'Projected Growth (2021-2031)': 'Decline',
    'Projected Job Openings (2021-2031)': 3300,
    Industries:
      'Government (46%), Arts, Entertainment, and Recreation (35%), Accommodation and Food Services (15%)',
  },
  {
    Code: '39-3011.00',
    Occupation: 'Gambling Dealers',
    'Projected Growth (2021-2031)': 'Much faster than average',
    'Projected Job Openings (2021-2031)': 14100,
    Industries:
      'Accommodation and Food Services (41%), Arts, Entertainment, and Recreation (28%), Government (27%)',
  },
  {
    Code: '11-9071.00',
    Occupation: 'Gambling Managers',
    'Projected Growth (2021-2031)': 'Much faster than average',
    'Projected Job Openings (2021-2031)': 500,
    Industries:
      'Government (38%), Arts, Entertainment, and Recreation (30%), Accommodation and Food Services (22%)',
  },
  {
    Code: '39-3019.00',
    Occupation: 'Gambling Service Workers, All Other',
    'Projected Growth (2021-2031)': 'Much faster than average',
    'Projected Job Openings (2021-2031)': 2400,
    Industries:
      'Arts, Entertainment, and Recreation (36%), Government (26%), Accommodation and Food Services (20%), Other Services (Except Public Administration) (12%)',
  },
  {
    Code: '33-9031.00',
    Occupation: 'Gambling Surveillance Officers and Gambling Investigators',
    'Projected Growth (2021-2031)': 'Faster than average',
    'Projected Job Openings (2021-2031)': 1400,
    Industries:
      'Government (62%), Arts, Entertainment, and Recreation (21%), Accommodation and Food Services (16%)',
  },
  {
    Code: '53-7071.00',
    Occupation: 'Gas Compressor and Gas Pumping Station Operators',
    'Projected Growth (2021-2031)': 'Little or no change',
    'Projected Job Openings (2021-2031)': 400,
    Industries:
      'Transportation and Warehousing (36%), Wholesale Trade (19%), Utilities (15%)',
  },
  {
    Code: '51-8092.00',
    Occupation: 'Gas Plant Operators',
    'Projected Growth (2021-2031)': 'Decline',
    'Projected Job Openings (2021-2031)': 1400,
    Industries: 'Utilities (47%), Transportation and Warehousing (24%)',
  },
  {
    Code: '11-1021.00',
    Occupation: 'General and Operations Managers',
    'Projected Growth (2021-2031)': 'Average',
    'Projected Job Openings (2021-2031)': 300400,
    Industries:
      'Retail Trade (12%), Professional, Scientific, and Technical Services (12%)',
  },
  {
    Code: '29-1216.00',
    Occupation: 'General Internal Medicine Physicians',
    'Projected Growth (2021-2031)': 'Slower than average',
    'Projected Job Openings (2021-2031)': 1900,
    Industries: 'Health Care and Social Assistance (89%)',
  },
  {
    Code: '29-9092.00',
    Occupation: 'Genetic Counselors',
    'Projected Growth (2021-2031)': 'Much faster than average',
    'Projected Job Openings (2021-2031)': 300,
    Industries: 'Health Care and Social Assistance (85%)',
  },
  {
    Code: '19-3092.00',
    Occupation: 'Geographers',
    'Projected Growth (2021-2031)': 'Little or no change',
    'Projected Job Openings (2021-2031)': 100,
    Industries: 'Government (76%)',
  },
  {
    Code: '25-1064.00',
    Occupation: 'Geography Teachers, Postsecondary',
    'Projected Growth (2021-2031)': 'Faster than average',
    'Projected Job Openings (2021-2031)': 400,
    Industries: 'Educational Services (100%)',
  },
  {
    Code: '19-4043.00',
    Occupation: 'Geological Technicians, Except Hydrologic Technicians',
    'Projected Growth (2021-2031)': 'Average',
    'Projected Job Openings (2021-2031)': 1000,
    Industries:
      'Professional, Scientific, and Technical Services (59%), Mining, Quarrying, and Oil and Gas Extraction (18%)',
  },
  {
    Code: '19-2042.00',
    Occupation: 'Geoscientists, Except Hydrologists and Geographers',
    'Projected Growth (2021-2031)': 'Average',
    'Projected Job Openings (2021-2031)': 2400,
    Industries:
      'Professional, Scientific, and Technical Services (48%), Government (19%), Mining, Quarrying, and Oil and Gas Extraction (13%), Management of Companies and Enterprises (10%)',
  },
  {
    Code: '47-2121.00',
    Occupation: 'Glaziers',
    'Projected Growth (2021-2031)': 'Average',
    'Projected Job Openings (2021-2031)': 6500,
    Industries: 'Construction (72%), Retail Trade (11%)',
  },
  {
    Code: '45-2041.00',
    Occupation: 'Graders and Sorters, Agricultural Products',
    'Projected Growth (2021-2031)': 'Little or no change',
    'Projected Job Openings (2021-2031)': 5200,
    Industries:
      'Agriculture, Forestry, Fishing, and Hunting (52%), Manufacturing (26%)',
  },
  {
    Code: '27-1024.00',
    Occupation: 'Graphic Designers',
    'Projected Growth (2021-2031)': 'Slower than average',
    'Projected Job Openings (2021-2031)': 24800,
    Industries:
      'Professional, Scientific, and Technical Services (27%), Manufacturing (15%), Information (10%)',
  },
  {
    Code: '51-9022.00',
    Occupation: 'Grinding and Polishing Workers, Hand',
    'Projected Growth (2021-2031)': 'Decline',
    'Projected Job Openings (2021-2031)': 1300,
    Industries:
      'Manufacturing (74%), Administrative and Support Services (18%)',
  },
  {
    Code: '51-4033.00',
    Occupation:
      'Grinding, Lapping, Polishing, and Buffing Machine Tool Setters, Operators, and Tenders, Metal and Plastic',
    'Projected Growth (2021-2031)': 'Decline',
    'Projected Job Openings (2021-2031)': 7800,
    Industries: 'Manufacturing (96%)',
  },
  {
    Code: '37-3019.00',
    Occupation: 'Grounds Maintenance Workers, All Other',
    'Projected Growth (2021-2031)': 'Average',
    'Projected Job Openings (2021-2031)': 2200,
    Industries: 'Administrative and Support Services (44%), Government (23%)',
  },
  {
    Code: '39-5012.00',
    Occupation: 'Hairdressers, Hairstylists, and Cosmetologists',
    'Projected Growth (2021-2031)': 'Much faster than average',
    'Projected Job Openings (2021-2031)': 87200,
    Industries: 'Other Services (Except Public Administration) (47%)',
  },
  {
    Code: '47-4041.00',
    Occupation: 'Hazardous Materials Removal Workers',
    'Projected Growth (2021-2031)': 'Little or no change',
    'Projected Job Openings (2021-2031)': 5700,
    Industries: 'Administrative and Support Services (85%)',
  },
  {
    Code: '17-2111.00',
    Occupation:
      'Health and Safety Engineers, Except Mining Safety Engineers and Inspectors',
    'Projected Growth (2021-2031)': 'Average',
    'Projected Job Openings (2021-2031)': 1500,
    Industries:
      'Manufacturing (25%), Professional, Scientific, and Technical Services (24%), Government (14%), Construction (12%)',
  },
  {
    Code: '17-2111.02',
    Occupation: 'Fire-Prevention and Protection Engineers',
    'Projected Growth (2021-2031)': 'Average',
    'Projected Job Openings (2021-2031)': 1500,
    Industries:
      'Manufacturing (25%), Professional, Scientific, and Technical Services (24%), Government (14%), Construction (12%)',
  },
  {
    Code: '21-1091.00',
    Occupation: 'Health Education Specialists',
    'Projected Growth (2021-2031)': 'Faster than average',
    'Projected Job Openings (2021-2031)': 7100,
    Industries: 'Health Care and Social Assistance (45%), Government (26%)',
  },
  {
    Code: '29-9021.00',
    Occupation: 'Health Information Technologists and Medical Registrars',
    'Projected Growth (2021-2031)': 'Much faster than average',
    'Projected Job Openings (2021-2031)': 3400,
    Industries: 'Health Care and Social Assistance (67%)',
  },
  {
    Code: '25-1071.00',
    Occupation: 'Health Specialties Teachers, Postsecondary',
    'Projected Growth (2021-2031)': 'Much faster than average',
    'Projected Job Openings (2021-2031)': 28900,
    Industries: 'Educational Services (95%)',
  },
  {
    Code: '29-2099.00',
    Occupation: 'Health Technologists and Technicians, All Other',
    'Projected Growth (2021-2031)': 'Average',
    'Projected Job Openings (2021-2031)': 11800,
    Industries: 'Health Care and Social Assistance (87%)',
  },
  {
    Code: '29-2099.01',
    Occupation: 'Neurodiagnostic Technologists',
    'Projected Growth (2021-2031)': 'Average',
    'Projected Job Openings (2021-2031)': 11800,
    Industries: 'Health Care and Social Assistance (87%)',
  },
  {
    Code: '29-2099.05',
    Occupation: 'Ophthalmic Medical Technologists',
    'Projected Growth (2021-2031)': 'Average',
    'Projected Job Openings (2021-2031)': 11800,
    Industries: 'Health Care and Social Assistance (87%)',
  },
  {
    Code: '29-2099.08',
    Occupation: 'Patient Representatives',
    'Projected Growth (2021-2031)': 'Average',
    'Projected Job Openings (2021-2031)': 11800,
    Industries: 'Health Care and Social Assistance (87%)',
  },
  {
    Code: '29-1299.00',
    Occupation: 'Healthcare Diagnosing or Treating Practitioners, All Other',
    'Projected Growth (2021-2031)': 'Little or no change',
    'Projected Job Openings (2021-2031)': 2500,
    Industries: 'Government (56%), Health Care and Social Assistance (21%)',
  },
  {
    Code: '29-1299.01',
    Occupation: 'Naturopathic Physicians',
    'Projected Growth (2021-2031)': 'Little or no change',
    'Projected Job Openings (2021-2031)': 2500,
    Industries: 'Government (56%), Health Care and Social Assistance (21%)',
  },
  {
    Code: '29-1299.02',
    Occupation: 'Orthoptists',
    'Projected Growth (2021-2031)': 'Little or no change',
    'Projected Job Openings (2021-2031)': 2500,
    Industries: 'Government (56%), Health Care and Social Assistance (21%)',
  },
  {
    Code: '29-9099.00',
    Occupation: 'Healthcare Practitioners and Technical Workers, All Other',
    'Projected Growth (2021-2031)': 'Average',
    'Projected Job Openings (2021-2031)': 3300,
    Industries: 'Health Care and Social Assistance (53%), Government (18%)',
  },
  {
    Code: '29-9099.01',
    Occupation: 'Midwives',
    'Projected Growth (2021-2031)': 'Average',
    'Projected Job Openings (2021-2031)': 3300,
    Industries: 'Health Care and Social Assistance (53%), Government (18%)',
  },
  {
    Code: '21-1022.00',
    Occupation: 'Healthcare Social Workers',
    'Projected Growth (2021-2031)': 'Much faster than average',
    'Projected Job Openings (2021-2031)': 19700,
    Industries: 'Health Care and Social Assistance (80%)',
  },
  {
    Code: '31-9099.00',
    Occupation: 'Healthcare Support Workers, All Other',
    'Projected Growth (2021-2031)': 'Average',
    'Projected Job Openings (2021-2031)': 17200,
    Industries:
      'Health Care and Social Assistance (59%), Government (20%), Educational Services (10%)',
  },
  {
    Code: '31-9099.01',
    Occupation: 'Speech-Language Pathology Assistants',
    'Projected Growth (2021-2031)': 'Average',
    'Projected Job Openings (2021-2031)': 17200,
    Industries:
      'Health Care and Social Assistance (59%), Government (20%), Educational Services (10%)',
  },
  {
    Code: '31-9099.02',
    Occupation: 'Endoscopy Technicians',
    'Projected Growth (2021-2031)': 'Average',
    'Projected Job Openings (2021-2031)': 17200,
    Industries:
      'Health Care and Social Assistance (59%), Government (20%), Educational Services (10%)',
  },
  {
    Code: '29-2092.00',
    Occupation: 'Hearing Aid Specialists',
    'Projected Growth (2021-2031)': 'Much faster than average',
    'Projected Job Openings (2021-2031)': 1000,
    Industries: 'Retail Trade (56%), Health Care and Social Assistance (29%)',
  },
  {
    Code: '51-4191.00',
    Occupation:
      'Heat Treating Equipment Setters, Operators, and Tenders, Metal and Plastic',
    'Projected Growth (2021-2031)': 'Decline',
    'Projected Job Openings (2021-2031)': 1300,
    Industries: 'Manufacturing (87%)',
  },
  {
    Code: '49-9021.00',
    Occupation:
      'Heating, Air Conditioning, and Refrigeration Mechanics and Installers',
    'Projected Growth (2021-2031)': 'Average',
    'Projected Job Openings (2021-2031)': 40100,
    Industries: 'Construction (70%)',
  },
  {
    Code: '53-3032.00',
    Occupation: 'Heavy and Tractor-Trailer Truck Drivers',
    'Projected Growth (2021-2031)': 'Average',
    'Projected Job Openings (2021-2031)': 259900,
    Industries: 'Transportation and Warehousing (53%), Wholesale Trade (12%)',
  },
  {
    Code: '47-3019.00',
    Occupation: 'Helpers, Construction Trades, All Other',
    'Projected Growth (2021-2031)': 'Slower than average',
    'Projected Job Openings (2021-2031)': 3600,
    Industries: 'Construction (78%)',
  },
  {
    Code: '47-3011.00',
    Occupation:
      'Helpers--Brickmasons, Blockmasons, Stonemasons, and Tile and Marble Setters',
    'Projected Growth (2021-2031)': 'Decline',
    'Projected Job Openings (2021-2031)': 2000,
    Industries: 'Construction (95%)',
  },
  {
    Code: '47-3012.00',
    Occupation: 'Helpers--Carpenters',
    'Projected Growth (2021-2031)': 'Decline',
    'Projected Job Openings (2021-2031)': 3300,
    Industries: 'Construction (88%)',
  },
  {
    Code: '47-3013.00',
    Occupation: 'Helpers--Electricians',
    'Projected Growth (2021-2031)': 'Decline',
    'Projected Job Openings (2021-2031)': 8600,
    Industries: 'Construction (92%)',
  },
  {
    Code: '47-5081.00',
    Occupation: 'Helpers--Extraction Workers',
    'Projected Growth (2021-2031)': 'Much faster than average',
    'Projected Job Openings (2021-2031)': 900,
    Industries:
      'Mining, Quarrying, and Oil and Gas Extraction (55%), Construction (35%)',
  },
  {
    Code: '49-9098.00',
    Occupation: 'Helpers--Installation, Maintenance, and Repair Workers',
    'Projected Growth (2021-2031)': 'Average',
    'Projected Job Openings (2021-2031)': 11400,
    Industries:
      'Construction (35%), Other Services (Except Public Administration) (19%)',
  },
  {
    Code: '47-3014.00',
    Occupation:
      'Helpers--Painters, Paperhangers, Plasterers, and Stucco Masons',
    'Projected Growth (2021-2031)': 'Slower than average',
    'Projected Job Openings (2021-2031)': 1100,
    Industries: 'Construction (91%)',
  },
  {
    Code: '47-3015.00',
    Occupation: 'Helpers--Pipelayers, Plumbers, Pipefitters, and Steamfitters',
    'Projected Growth (2021-2031)': 'Slower than average',
    'Projected Job Openings (2021-2031)': 5900,
    Industries: 'Construction (93%)',
  },
  {
    Code: '51-9198.00',
    Occupation: 'Helpers--Production Workers',
    'Projected Growth (2021-2031)': 'Decline',
    'Projected Job Openings (2021-2031)': 28700,
    Industries:
      'Manufacturing (67%), Administrative and Support Services (19%)',
  },
  {
    Code: '47-3016.00',
    Occupation: 'Helpers--Roofers',
    'Projected Growth (2021-2031)': 'Slower than average',
    'Projected Job Openings (2021-2031)': 900,
    Industries: 'Construction (96%)',
  },
  {
    Code: '47-4051.00',
    Occupation: 'Highway Maintenance Workers',
    'Projected Growth (2021-2031)': 'Average',
    'Projected Job Openings (2021-2031)': 16500,
    Industries: 'Government (93%)',
  },
  {
    Code: '19-3093.00',
    Occupation: 'Historians',
    'Projected Growth (2021-2031)': 'Average',
    'Projected Job Openings (2021-2031)': 300,
    Industries:
      'Government (54%), Professional, Scientific, and Technical Services (25%)',
  },
  {
    Code: '25-1125.00',
    Occupation: 'History Teachers, Postsecondary',
    'Projected Growth (2021-2031)': 'Average',
    'Projected Job Openings (2021-2031)': 2200,
    Industries: 'Educational Services (100%)',
  },
  {
    Code: '53-7041.00',
    Occupation: 'Hoist and Winch Operators',
    'Projected Growth (2021-2031)': 'Little or no change',
    'Projected Job Openings (2021-2031)': 400,
    Industries:
      'Transportation and Warehousing (19%), Manufacturing (17%), Construction (10%)',
  },
  {
    Code: '49-9031.00',
    Occupation: 'Home Appliance Repairers',
    'Projected Growth (2021-2031)': 'Little or no change',
    'Projected Job Openings (2021-2031)': 3600,
    Industries:
      'Other Services (Except Public Administration) (39%), Retail Trade (26%)',
  },
  {
    Code: '',
    Occupation: 'Home Health and Personal Care Aides',
    'Projected Growth (2021-2031)': 'Much faster than average',
    'Projected Job Openings (2021-2031)': 711700,
    Industries: 'Health Care and Social Assistance (91%)',
  },
  {
    Code: '31-1121.00',
    Occupation: 'Home Health Aides',
    'Projected Growth (2021-2031)': 'Much faster than average',
    'Projected Job Openings (2021-2031)': 711700,
    Industries: 'Health Care and Social Assistance (91%)',
  },
  {
    Code: '31-1122.00',
    Occupation: 'Personal Care Aides',
    'Projected Growth (2021-2031)': 'Much faster than average',
    'Projected Job Openings (2021-2031)': 711700,
    Industries: 'Health Care and Social Assistance (91%)',
  },
  {
    Code: '35-9031.00',
    Occupation: 'Hosts and Hostesses, Restaurant, Lounge, and Coffee Shop',
    'Projected Growth (2021-2031)': 'Much faster than average',
    'Projected Job Openings (2021-2031)': 95200,
    Industries: 'Accommodation and Food Services (93%)',
  },
  {
    Code: '43-4081.00',
    Occupation: 'Hotel, Motel, and Resort Desk Clerks',
    'Projected Growth (2021-2031)': 'Much faster than average',
    'Projected Job Openings (2021-2031)': 49200,
    Industries: 'Accommodation and Food Services (93%)',
  },
  {
    Code: '43-4161.00',
    Occupation: 'Human Resources Assistants, Except Payroll and Timekeeping',
    'Projected Growth (2021-2031)': 'Decline',
    'Projected Job Openings (2021-2031)': 11200,
    Industries:
      'Government (17%), Health Care and Social Assistance (14%), Administrative and Support Services (12%)',
  },
  {
    Code: '11-3121.00',
    Occupation: 'Human Resources Managers',
    'Projected Growth (2021-2031)': 'Average',
    'Projected Job Openings (2021-2031)': 16300,
    Industries:
      'Professional, Scientific, and Technical Services (16%), Management of Companies and Enterprises (13%), Manufacturing (10%)',
  },
  {
    Code: '13-1071.00',
    Occupation: 'Human Resources Specialists',
    'Projected Growth (2021-2031)': 'Faster than average',
    'Projected Job Openings (2021-2031)': 81900,
    Industries:
      'Administrative and Support Services (21%), Professional, Scientific, and Technical Services (13%), Government (10%), Health Care and Social Assistance (10%)',
  },
  {
    Code: '19-4044.00',
    Occupation: 'Hydrologic Technicians',
    'Projected Growth (2021-2031)': 'Little or no change',
    'Projected Job Openings (2021-2031)': 400,
    Industries:
      'Government (57%), Mining, Quarrying, and Oil and Gas Extraction (13%), Manufacturing (13%)',
  },
  {
    Code: '19-2043.00',
    Occupation: 'Hydrologists',
    'Projected Growth (2021-2031)': 'Little or no change',
    'Projected Job Openings (2021-2031)': 600,
    Industries:
      'Government (59%), Professional, Scientific, and Technical Services (34%)',
  },
  {
    Code: '17-3026.00',
    Occupation: 'Industrial Engineering Technologists and Technicians',
    'Projected Growth (2021-2031)': 'Slower than average',
    'Projected Job Openings (2021-2031)': 6600,
    Industries:
      'Manufacturing (77%), Professional, Scientific, and Technical Services (10%)',
  },
  {
    Code: '17-3026.01',
    Occupation: 'Nanotechnology Engineering Technologists and Technicians',
    'Projected Growth (2021-2031)': 'Slower than average',
    'Projected Job Openings (2021-2031)': 6600,
    Industries:
      'Manufacturing (77%), Professional, Scientific, and Technical Services (10%)',
  },
  {
    Code: '17-2112.00',
    Occupation: 'Industrial Engineers',
    'Projected Growth (2021-2031)': 'Faster than average',
    'Projected Job Openings (2021-2031)': 22400,
    Industries:
      'Manufacturing (71%), Professional, Scientific, and Technical Services (12%)',
  },
  {
    Code: '17-2112.01',
    Occupation: 'Human Factors Engineers and Ergonomists',
    'Projected Growth (2021-2031)': 'Faster than average',
    'Projected Job Openings (2021-2031)': 22400,
    Industries:
      'Manufacturing (71%), Professional, Scientific, and Technical Services (12%)',
  },
  {
    Code: '17-2112.02',
    Occupation: 'Validation Engineers',
    'Projected Growth (2021-2031)': 'Faster than average',
    'Projected Job Openings (2021-2031)': 22400,
    Industries:
      'Manufacturing (71%), Professional, Scientific, and Technical Services (12%)',
  },
  {
    Code: '17-2112.03',
    Occupation: 'Manufacturing Engineers',
    'Projected Growth (2021-2031)': 'Faster than average',
    'Projected Job Openings (2021-2031)': 22400,
    Industries:
      'Manufacturing (71%), Professional, Scientific, and Technical Services (12%)',
  },
  {
    Code: '49-9041.00',
    Occupation: 'Industrial Machinery Mechanics',
    'Projected Growth (2021-2031)': 'Much faster than average',
    'Projected Job Openings (2021-2031)': 42500,
    Industries:
      'Manufacturing (54%), Wholesale Trade (14%), Other Services (Except Public Administration) (12%)',
  },
  {
    Code: '11-3051.00',
    Occupation: 'Industrial Production Managers',
    'Projected Growth (2021-2031)': 'Average',
    'Projected Job Openings (2021-2031)': 15400,
    Industries: 'Manufacturing (74%)',
  },
  {
    Code: '11-3051.01',
    Occupation: 'Quality Control Systems Managers',
    'Projected Growth (2021-2031)': 'Average',
    'Projected Job Openings (2021-2031)': 15400,
    Industries: 'Manufacturing (74%)',
  },
  {
    Code: '11-3051.02',
    Occupation: 'Geothermal Production Managers',
    'Projected Growth (2021-2031)': 'Average',
    'Projected Job Openings (2021-2031)': 15400,
    Industries: 'Manufacturing (74%)',
  },
  {
    Code: '11-3051.03',
    Occupation: 'Biofuels Production Managers',
    'Projected Growth (2021-2031)': 'Average',
    'Projected Job Openings (2021-2031)': 15400,
    Industries: 'Manufacturing (74%)',
  },
  {
    Code: '11-3051.04',
    Occupation: 'Biomass Power Plant Managers',
    'Projected Growth (2021-2031)': 'Average',
    'Projected Job Openings (2021-2031)': 15400,
    Industries: 'Manufacturing (74%)',
  },
  {
    Code: '11-3051.06',
    Occupation: 'Hydroelectric Production Managers',
    'Projected Growth (2021-2031)': 'Average',
    'Projected Job Openings (2021-2031)': 15400,
    Industries: 'Manufacturing (74%)',
  },
  {
    Code: '53-7051.00',
    Occupation: 'Industrial Truck and Tractor Operators',
    'Projected Growth (2021-2031)': 'Average',
    'Projected Job Openings (2021-2031)': 95300,
    Industries:
      'Transportation and Warehousing (49%), Manufacturing (22%), Administrative and Support Services (11%), Wholesale Trade (10%)',
  },
  {
    Code: '19-3032.00',
    Occupation: 'Industrial-Organizational Psychologists',
    'Projected Growth (2021-2031)': 'Average',
    'Projected Job Openings (2021-2031)': 200,
    Industries: 'Educational Services (12%)',
  },
  {
    Code: '43-4199.00',
    Occupation: 'Information and Record Clerks, All Other',
    'Projected Growth (2021-2031)': 'Slower than average',
    'Projected Job Openings (2021-2031)': 20100,
    Industries: 'Government (51%), Educational Services (19%)',
  },
  {
    Code: '15-1212.00',
    Occupation: 'Information Security Analysts',
    'Projected Growth (2021-2031)': 'Much faster than average',
    'Projected Job Openings (2021-2031)': 19500,
    Industries:
      'Professional, Scientific, and Technical Services (42%), Finance and Insurance (15%), Information (14%)',
  },
  {
    Code: '51-9061.00',
    Occupation: 'Inspectors, Testers, Sorters, Samplers, and Weighers',
    'Projected Growth (2021-2031)': 'Decline',
    'Projected Job Openings (2021-2031)': 67800,
    Industries: 'Manufacturing (64%)',
  },
  {
    Code: '49-9099.00',
    Occupation: 'Installation, Maintenance, and Repair Workers, All Other',
    'Projected Growth (2021-2031)': 'Slower than average',
    'Projected Job Openings (2021-2031)': 19300,
    Industries:
      'Wholesale Trade (15%), Retail Trade (15%), Administrative and Support Services (13%), Construction (12%), Manufacturing (11%)',
  },
  {
    Code: '49-9099.01',
    Occupation: 'Geothermal Technicians',
    'Projected Growth (2021-2031)': 'Slower than average',
    'Projected Job Openings (2021-2031)': 19300,
    Industries:
      'Wholesale Trade (15%), Retail Trade (15%), Administrative and Support Services (13%), Construction (12%), Manufacturing (11%)',
  },
  {
    Code: '25-9031.00',
    Occupation: 'Instructional Coordinators',
    'Projected Growth (2021-2031)': 'Average',
    'Projected Job Openings (2021-2031)': 20900,
    Industries: 'Educational Services (80%)',
  },
  {
    Code: '47-2131.00',
    Occupation: 'Insulation Workers, Floor, Ceiling, and Wall',
    'Projected Growth (2021-2031)': 'Average',
    'Projected Job Openings (2021-2031)': 3100,
    Industries: 'Construction (86%)',
  },
  {
    Code: '47-2132.00',
    Occupation: 'Insulation Workers, Mechanical',
    'Projected Growth (2021-2031)': 'Slower than average',
    'Projected Job Openings (2021-2031)': 2900,
    Industries: 'Construction (83%)',
  },
  {
    Code: '13-1032.00',
    Occupation: 'Insurance Appraisers, Auto Damage',
    'Projected Growth (2021-2031)': 'Decline',
    'Projected Job Openings (2021-2031)': 900,
    Industries: 'Finance and Insurance (96%)',
  },
  {
    Code: '43-9041.00',
    Occupation: 'Insurance Claims and Policy Processing Clerks',
    'Projected Growth (2021-2031)': 'Decline',
    'Projected Job Openings (2021-2031)': 24600,
    Industries: 'Finance and Insurance (85%)',
  },
  {
    Code: '41-3021.00',
    Occupation: 'Insurance Sales Agents',
    'Projected Growth (2021-2031)': 'Average',
    'Projected Job Openings (2021-2031)': 52700,
    Industries: 'Finance and Insurance (83%)',
  },
  {
    Code: '13-2053.00',
    Occupation: 'Insurance Underwriters',
    'Projected Growth (2021-2031)': 'Decline',
    'Projected Job Openings (2021-2031)': 8400,
    Industries: 'Finance and Insurance (95%)',
  },
  {
    Code: '27-1025.00',
    Occupation: 'Interior Designers',
    'Projected Growth (2021-2031)': 'Little or no change',
    'Projected Job Openings (2021-2031)': 8200,
    Industries:
      'Professional, Scientific, and Technical Services (39%), Retail Trade (13%)',
  },
  {
    Code: '27-3091.00',
    Occupation: 'Interpreters and Translators',
    'Projected Growth (2021-2031)': 'Much faster than average',
    'Projected Job Openings (2021-2031)': 9200,
    Industries:
      'Professional, Scientific, and Technical Services (32%), Educational Services (20%), Health Care and Social Assistance (14%)',
  },
  {
    Code: '43-4111.00',
    Occupation: 'Interviewers, Except Eligibility and Loan',
    'Projected Growth (2021-2031)': 'Decline',
    'Projected Job Openings (2021-2031)': 20700,
    Industries:
      'Health Care and Social Assistance (65%), Professional, Scientific, and Technical Services (12%)',
  },
  {
    Code: '37-2011.00',
    Occupation: 'Janitors and Cleaners, Except Maids and Housekeeping Cleaners',
    'Projected Growth (2021-2031)': 'Average',
    'Projected Job Openings (2021-2031)': 335500,
    Industries:
      'Administrative and Support Services (40%), Educational Services (18%)',
  },
  {
    Code: '51-9071.00',
    Occupation: 'Jewelers and Precious Stone and Metal Workers',
    'Projected Growth (2021-2031)': 'Little or no change',
    'Projected Job Openings (2021-2031)': 6100,
    Industries: 'Retail Trade (33%), Manufacturing (13%)',
  },
  {
    Code: '51-9071.06',
    Occupation: 'Gem and Diamond Workers',
    'Projected Growth (2021-2031)': 'Little or no change',
    'Projected Job Openings (2021-2031)': 6100,
    Industries: 'Retail Trade (33%), Manufacturing (13%)',
  },
  {
    Code: '23-1023.00',
    Occupation: 'Judges, Magistrate Judges, and Magistrates',
    'Projected Growth (2021-2031)': 'Little or no change',
    'Projected Job Openings (2021-2031)': 1300,
    Industries: 'Government (100%)',
  },
  {
    Code: '23-1012.00',
    Occupation: 'Judicial Law Clerks',
    'Projected Growth (2021-2031)': 'Little or no change',
    'Projected Job Openings (2021-2031)': 1100,
    Industries: 'Government (93%)',
  },
  {
    Code: '25-2012.00',
    Occupation: 'Kindergarten Teachers, Except Special Education',
    'Projected Growth (2021-2031)': 'Average',
    'Projected Job Openings (2021-2031)': 14200,
    Industries: 'Educational Services (97%)',
  },
  {
    Code: '13-1075.00',
    Occupation: 'Labor Relations Specialists',
    'Projected Growth (2021-2031)': 'Decline',
    'Projected Job Openings (2021-2031)': 5800,
    Industries: 'Other Services (Except Public Administration) (79%)',
  },
  {
    Code: '53-7062.00',
    Occupation: 'Laborers and Freight, Stock, and Material Movers, Hand',
    'Projected Growth (2021-2031)': 'Average',
    'Projected Job Openings (2021-2031)': 421900,
    Industries:
      'Transportation and Warehousing (32%), Administrative and Support Services (22%), Manufacturing (14%), Wholesale Trade (13%)',
  },
  {
    Code: '53-7062.04',
    Occupation: 'Recycling and Reclamation Workers',
    'Projected Growth (2021-2031)': 'Average',
    'Projected Job Openings (2021-2031)': 421900,
    Industries:
      'Transportation and Warehousing (32%), Administrative and Support Services (22%), Manufacturing (14%), Wholesale Trade (13%)',
  },
  {
    Code: '17-1012.00',
    Occupation: 'Landscape Architects',
    'Projected Growth (2021-2031)': 'Little or no change',
    'Projected Job Openings (2021-2031)': 1500,
    Industries:
      'Professional, Scientific, and Technical Services (58%), Administrative and Support Services (17%)',
  },
  {
    Code: '37-3011.00',
    Occupation: 'Landscaping and Groundskeeping Workers',
    'Projected Growth (2021-2031)': 'Average',
    'Projected Job Openings (2021-2031)': 164500,
    Industries: 'Administrative and Support Services (47%)',
  },
  {
    Code: '51-4034.00',
    Occupation:
      'Lathe and Turning Machine Tool Setters, Operators, and Tenders, Metal and Plastic',
    'Projected Growth (2021-2031)': 'Decline',
    'Projected Job Openings (2021-2031)': 2400,
    Industries: 'Manufacturing (94%)',
  },
  {
    Code: '51-6011.00',
    Occupation: 'Laundry and Dry-Cleaning Workers',
    'Projected Growth (2021-2031)': 'Much faster than average',
    'Projected Job Openings (2021-2031)': 26600,
    Industries:
      'Other Services (Except Public Administration) (50%), Health Care and Social Assistance (19%), Accommodation and Food Services (17%)',
  },
  {
    Code: '25-1112.00',
    Occupation: 'Law Teachers, Postsecondary',
    'Projected Growth (2021-2031)': 'Faster than average',
    'Projected Job Openings (2021-2031)': 1800,
    Industries: 'Educational Services (100%)',
  },
  {
    Code: '23-1011.00',
    Occupation: 'Lawyers',
    'Projected Growth (2021-2031)': 'Faster than average',
    'Projected Job Openings (2021-2031)': 48700,
    Industries:
      'Professional, Scientific, and Technical Services (53%), Government (18%)',
  },
  {
    Code: '51-4192.00',
    Occupation: 'Layout Workers, Metal and Plastic',
    'Projected Growth (2021-2031)': 'Decline',
    'Projected Job Openings (2021-2031)': 600,
    Industries: 'Manufacturing (75%), Government (16%)',
  },
  {
    Code: '43-6012.00',
    Occupation: 'Legal Secretaries and Administrative Assistants',
    'Projected Growth (2021-2031)': 'Decline',
    'Projected Job Openings (2021-2031)': 14600,
    Industries:
      'Professional, Scientific, and Technical Services (82%), Government (12%)',
  },
  {
    Code: '23-2099.00',
    Occupation: 'Legal Support Workers, All Other',
    'Projected Growth (2021-2031)': 'Little or no change',
    'Projected Job Openings (2021-2031)': 5900,
    Industries:
      'Government (49%), Professional, Scientific, and Technical Services (30%)',
  },
  {
    Code: '11-1031.00',
    Occupation: 'Legislators',
    'Projected Growth (2021-2031)': 'Slower than average',
    'Projected Job Openings (2021-2031)': 3800,
    Industries: 'Government (100%)',
  },
  {
    Code: '25-4022.00',
    Occupation: 'Librarians and Media Collections Specialists',
    'Projected Growth (2021-2031)': 'Average',
    'Projected Job Openings (2021-2031)': 14900,
    Industries: 'Educational Services (58%), Government (32%)',
  },
  {
    Code: '43-4121.00',
    Occupation: 'Library Assistants, Clerical',
    'Projected Growth (2021-2031)': 'Decline',
    'Projected Job Openings (2021-2031)': 13300,
    Industries:
      'Government (64%), Educational Services (25%), Information (10%)',
  },
  {
    Code: '25-1082.00',
    Occupation: 'Library Science Teachers, Postsecondary',
    'Projected Growth (2021-2031)': 'Faster than average',
    'Projected Job Openings (2021-2031)': 500,
    Industries: 'Educational Services (99%)',
  },
  {
    Code: '25-4031.00',
    Occupation: 'Library Technicians',
    'Projected Growth (2021-2031)': 'Decline',
    'Projected Job Openings (2021-2031)': 10700,
    Industries: 'Government (54%), Educational Services (38%)',
  },
  {
    Code: '29-2061.00',
    Occupation: 'Licensed Practical and Licensed Vocational Nurses',
    'Projected Growth (2021-2031)': 'Average',
    'Projected Job Openings (2021-2031)': 58800,
    Industries: 'Health Care and Social Assistance (84%)',
  },
  {
    Code: '19-1099.00',
    Occupation: 'Life Scientists, All Other',
    'Projected Growth (2021-2031)': 'Average',
    'Projected Job Openings (2021-2031)': 500,
    Industries:
      'Professional, Scientific, and Technical Services (35%), Educational Services (26%), Government (20%)',
  },
  {
    Code: '19-4099.00',
    Occupation: 'Life, Physical, and Social Science Technicians, All Other',
    'Projected Growth (2021-2031)': 'Average',
    'Projected Job Openings (2021-2031)': 9700,
    Industries:
      'Educational Services (30%), Professional, Scientific, and Technical Services (22%), Administrative and Support Services (14%), Government (13%)',
  },
  {
    Code: '19-4099.01',
    Occupation: 'Quality Control Analysts',
    'Projected Growth (2021-2031)': 'Average',
    'Projected Job Openings (2021-2031)': 9700,
    Industries:
      'Educational Services (30%), Professional, Scientific, and Technical Services (22%), Administrative and Support Services (14%), Government (13%)',
  },
  {
    Code: '19-4099.03',
    Occupation: 'Remote Sensing Technicians',
    'Projected Growth (2021-2031)': 'Average',
    'Projected Job Openings (2021-2031)': 9700,
    Industries:
      'Educational Services (30%), Professional, Scientific, and Technical Services (22%), Administrative and Support Services (14%), Government (13%)',
  },
  {
    Code: '33-9092.00',
    Occupation:
      'Lifeguards, Ski Patrol, and Other Recreational Protective Service Workers',
    'Projected Growth (2021-2031)': 'Much faster than average',
    'Projected Job Openings (2021-2031)': 36200,
    Industries:
      'Arts, Entertainment, and Recreation (40%), Government (36%), Other Services (Except Public Administration) (13%)',
  },
  {
    Code: '53-3033.00',
    Occupation: 'Light Truck Drivers',
    'Projected Growth (2021-2031)': 'Faster than average',
    'Projected Job Openings (2021-2031)': 147600,
    Industries:
      'Transportation and Warehousing (44%), Retail Trade (17%), Wholesale Trade (15%)',
  },
  {
    Code: '27-4015.00',
    Occupation: 'Lighting Technicians',
    'Projected Growth (2021-2031)': 'Much faster than average',
    'Projected Job Openings (2021-2031)': 700,
    Industries:
      'Arts, Entertainment, and Recreation (20%), Accommodation and Food Services (12%), Real Estate and Rental and Leasing (10%)',
  },
  {
    Code: '47-5044.00',
    Occupation: 'Loading and Moving Machine Operators, Underground Mining',
    'Projected Growth (2021-2031)': 'Decline',
    'Projected Job Openings (2021-2031)': 400,
    Industries: 'Mining, Quarrying, and Oil and Gas Extraction (94%)',
  },
  {
    Code: '43-4131.00',
    Occupation: 'Loan Interviewers and Clerks',
    'Projected Growth (2021-2031)': 'Little or no change',
    'Projected Job Openings (2021-2031)': 23300,
    Industries: 'Finance and Insurance (86%)',
  },
  {
    Code: '13-2072.00',
    Occupation: 'Loan Officers',
    'Projected Growth (2021-2031)': 'Average',
    'Projected Job Openings (2021-2031)': 29400,
    Industries: 'Finance and Insurance (86%)',
  },
  {
    Code: '39-3093.00',
    Occupation: 'Locker Room, Coatroom, and Dressing Room Attendants',
    'Projected Growth (2021-2031)': 'Much faster than average',
    'Projected Job Openings (2021-2031)': 3100,
    Industries:
      'Arts, Entertainment, and Recreation (47%), Other Services (Except Public Administration) (21%), Accommodation and Food Services (15%)',
  },
  {
    Code: '49-9094.00',
    Occupation: 'Locksmiths and Safe Repairers',
    'Projected Growth (2021-2031)': 'Decline',
    'Projected Job Openings (2021-2031)': 1600,
    Industries:
      'Administrative and Support Services (55%), Educational Services (10%)',
  },
  {
    Code: '53-4011.00',
    Occupation: 'Locomotive Engineers',
    'Projected Growth (2021-2031)': 'Average',
    'Projected Job Openings (2021-2031)': 2600,
    Industries: 'Transportation and Warehousing (97%)',
  },
  {
    Code: '11-9081.00',
    Occupation: 'Lodging Managers',
    'Projected Growth (2021-2031)': 'Much faster than average',
    'Projected Job Openings (2021-2031)': 7100,
    Industries: 'Accommodation and Food Services (69%)',
  },
  {
    Code: '45-4023.00',
    Occupation: 'Log Graders and Scalers',
    'Projected Growth (2021-2031)': 'Average',
    'Projected Job Openings (2021-2031)': 700,
    Industries: 'Manufacturing (62%)',
  },
  {
    Code: '45-4022.00',
    Occupation: 'Logging Equipment Operators',
    'Projected Growth (2021-2031)': 'Decline',
    'Projected Job Openings (2021-2031)': 5200,
    Industries:
      'Agriculture, Forestry, Fishing, and Hunting (57%), Manufacturing (12%)',
  },
  {
    Code: '45-4029.00',
    Occupation: 'Logging Workers, All Other',
    'Projected Growth (2021-2031)': 'Decline',
    'Projected Job Openings (2021-2031)': 600,
    Industries: 'Agriculture, Forestry, Fishing, and Hunting (66%)',
  },
  {
    Code: '13-1081.00',
    Occupation: 'Logisticians',
    'Projected Growth (2021-2031)': 'Much faster than average',
    'Projected Job Openings (2021-2031)': 24800,
    Industries:
      'Manufacturing (24%), Government (19%), Professional, Scientific, and Technical Services (16%), Transportation and Warehousing (10%), Management of Companies and Enterprises (10%)',
  },
  {
    Code: '13-1081.01',
    Occupation: 'Logistics Engineers',
    'Projected Growth (2021-2031)': 'Much faster than average',
    'Projected Job Openings (2021-2031)': 24800,
    Industries:
      'Manufacturing (24%), Government (19%), Professional, Scientific, and Technical Services (16%), Transportation and Warehousing (10%), Management of Companies and Enterprises (10%)',
  },
  {
    Code: '13-1081.02',
    Occupation: 'Logistics Analysts',
    'Projected Growth (2021-2031)': 'Much faster than average',
    'Projected Job Openings (2021-2031)': 24800,
    Industries:
      'Manufacturing (24%), Government (19%), Professional, Scientific, and Technical Services (16%), Transportation and Warehousing (10%), Management of Companies and Enterprises (10%)',
  },
  {
    Code: '53-7063.00',
    Occupation: 'Machine Feeders and Offbearers',
    'Projected Growth (2021-2031)': 'Average',
    'Projected Job Openings (2021-2031)': 10800,
    Industries: 'Manufacturing (54%), Transportation and Warehousing (29%)',
  },
  {
    Code: '51-4041.00',
    Occupation: 'Machinists',
    'Projected Growth (2021-2031)': 'Slower than average',
    'Projected Job Openings (2021-2031)': 37500,
    Industries: 'Manufacturing (83%)',
  },
  {
    Code: '29-2035.00',
    Occupation: 'Magnetic Resonance Imaging Technologists',
    'Projected Growth (2021-2031)': 'Average',
    'Projected Job Openings (2021-2031)': 2800,
    Industries: 'Health Care and Social Assistance (92%)',
  },
  {
    Code: '37-2012.00',
    Occupation: 'Maids and Housekeeping Cleaners',
    'Projected Growth (2021-2031)': 'Faster than average',
    'Projected Job Openings (2021-2031)': 193500,
    Industries:
      'Accommodation and Food Services (27%), Other Services (Except Public Administration) (25%), Health Care and Social Assistance (18%), Administrative and Support Services (10%)',
  },
  {
    Code: '43-9051.00',
    Occupation: 'Mail Clerks and Mail Machine Operators, Except Postal Service',
    'Projected Growth (2021-2031)': 'Decline',
    'Projected Job Openings (2021-2031)': 8900,
    Industries:
      'Administrative and Support Services (27%), Professional, Scientific, and Technical Services (13%), Finance and Insurance (11%), Government (10%)',
  },
  {
    Code: '49-9071.00',
    Occupation: 'Maintenance and Repair Workers, General',
    'Projected Growth (2021-2031)': 'Average',
    'Projected Job Openings (2021-2031)': 160100,
    Industries:
      'Real Estate and Rental and Leasing (21%), Manufacturing (13%), Government (12%)',
  },
  {
    Code: '49-9043.00',
    Occupation: 'Maintenance Workers, Machinery',
    'Projected Growth (2021-2031)': 'Faster than average',
    'Projected Job Openings (2021-2031)': 6900,
    Industries: 'Manufacturing (60%)',
  },
  {
    Code: '13-1111.00',
    Occupation: 'Management Analysts',
    'Projected Growth (2021-2031)': 'Much faster than average',
    'Projected Job Openings (2021-2031)': 101900,
    Industries:
      'Professional, Scientific, and Technical Services (33%), Government (17%), Finance and Insurance (13%)',
  },
  {
    Code: '11-9199.00',
    Occupation: 'Managers, All Other',
    'Projected Growth (2021-2031)': 'Average',
    'Projected Job Openings (2021-2031)': 113100,
    Industries: 'Government (10%)',
  },
  {
    Code: '11-9199.01',
    Occupation: 'Regulatory Affairs Managers',
    'Projected Growth (2021-2031)': 'Average',
    'Projected Job Openings (2021-2031)': 113100,
    Industries: 'Government (10%)',
  },
  {
    Code: '11-9199.02',
    Occupation: 'Compliance Managers',
    'Projected Growth (2021-2031)': 'Average',
    'Projected Job Openings (2021-2031)': 113100,
    Industries: 'Government (10%)',
  },
  {
    Code: '11-9199.08',
    Occupation: 'Loss Prevention Managers',
    'Projected Growth (2021-2031)': 'Average',
    'Projected Job Openings (2021-2031)': 113100,
    Industries: 'Government (10%)',
  },
  {
    Code: '11-9199.09',
    Occupation: 'Wind Energy Operations Managers',
    'Projected Growth (2021-2031)': 'Average',
    'Projected Job Openings (2021-2031)': 113100,
    Industries: 'Government (10%)',
  },
  {
    Code: '11-9199.10',
    Occupation: 'Wind Energy Development Managers',
    'Projected Growth (2021-2031)': 'Average',
    'Projected Job Openings (2021-2031)': 113100,
    Industries: 'Government (10%)',
  },
  {
    Code: '11-9199.11',
    Occupation: 'Brownfield Redevelopment Specialists and Site Managers',
    'Projected Growth (2021-2031)': 'Average',
    'Projected Job Openings (2021-2031)': 113100,
    Industries: 'Government (10%)',
  },
  {
    Code: '39-5092.00',
    Occupation: 'Manicurists and Pedicurists',
    'Projected Growth (2021-2031)': 'Much faster than average',
    'Projected Job Openings (2021-2031)': 26600,
    Industries: 'Other Services (Except Public Administration) (75%)',
  },
  {
    Code: '49-9095.00',
    Occupation: 'Manufactured Building and Mobile Home Installers',
    'Projected Growth (2021-2031)': 'Decline',
    'Projected Job Openings (2021-2031)': 300,
    Industries: 'Retail Trade (37%), Construction (22%), Manufacturing (21%)',
  },
  {
    Code: '17-2121.00',
    Occupation: 'Marine Engineers and Naval Architects',
    'Projected Growth (2021-2031)': 'Average',
    'Projected Job Openings (2021-2031)': 400,
    Industries:
      'Professional, Scientific, and Technical Services (36%), Manufacturing (36%), Government (17%)',
  },
  {
    Code: '13-1161.00',
    Occupation: 'Market Research Analysts and Marketing Specialists',
    'Projected Growth (2021-2031)': 'Much faster than average',
    'Projected Job Openings (2021-2031)': 99800,
    Industries:
      'Professional, Scientific, and Technical Services (27%), Information (10%), Finance and Insurance (10%)',
  },
  {
    Code: '13-1161.01',
    Occupation: 'Search Marketing Strategists',
    'Projected Growth (2021-2031)': 'Much faster than average',
    'Projected Job Openings (2021-2031)': 99800,
    Industries:
      'Professional, Scientific, and Technical Services (27%), Information (10%), Finance and Insurance (10%)',
  },
  {
    Code: '11-2021.00',
    Occupation: 'Marketing Managers',
    'Projected Growth (2021-2031)': 'Faster than average',
    'Projected Job Openings (2021-2031)': 32200,
    Industries:
      'Professional, Scientific, and Technical Services (22%), Management of Companies and Enterprises (12%), Information (11%), Finance and Insurance (11%)',
  },
  {
    Code: '21-1013.00',
    Occupation: 'Marriage and Family Therapists',
    'Projected Growth (2021-2031)': 'Much faster than average',
    'Projected Job Openings (2021-2031)': 6400,
    Industries: 'Health Care and Social Assistance (74%)',
  },
  {
    Code: '31-9011.00',
    Occupation: 'Massage Therapists',
    'Projected Growth (2021-2031)': 'Much faster than average',
    'Projected Job Openings (2021-2031)': 25200,
    Industries:
      'Other Services (Except Public Administration) (29%), Health Care and Social Assistance (21%)',
  },
  {
    Code: '53-7199.00',
    Occupation: 'Material Moving Workers, All Other',
    'Projected Growth (2021-2031)': 'Average',
    'Projected Job Openings (2021-2031)': 3500,
    Industries:
      'Retail Trade (19%), Transportation and Warehousing (13%), Real Estate and Rental and Leasing (12%), Manufacturing (10%)',
  },
  {
    Code: '17-2131.00',
    Occupation: 'Materials Engineers',
    'Projected Growth (2021-2031)': 'Average',
    'Projected Job Openings (2021-2031)': 1700,
    Industries:
      'Manufacturing (52%), Professional, Scientific, and Technical Services (23%)',
  },
  {
    Code: '19-2032.00',
    Occupation: 'Materials Scientists',
    'Projected Growth (2021-2031)': 'Average',
    'Projected Job Openings (2021-2031)': 600,
    Industries:
      'Professional, Scientific, and Technical Services (47%), Manufacturing (34%), Educational Services (11%)',
  },
  {
    Code: '15-2099.00',
    Occupation: 'Mathematical Science Occupations, All Other',
    'Projected Growth (2021-2031)': 'Average',
    'Projected Job Openings (2021-2031)': 300,
    Industries:
      'Professional, Scientific, and Technical Services (36%), Government (22%), Educational Services (15%)',
  },
  {
    Code: '15-2099.01',
    Occupation: 'Bioinformatics Technicians',
    'Projected Growth (2021-2031)': 'Average',
    'Projected Job Openings (2021-2031)': 300,
    Industries:
      'Professional, Scientific, and Technical Services (36%), Government (22%), Educational Services (15%)',
  },
  {
    Code: '25-1022.00',
    Occupation: 'Mathematical Science Teachers, Postsecondary',
    'Projected Growth (2021-2031)': 'Average',
    'Projected Job Openings (2021-2031)': 4900,
    Industries: 'Educational Services (100%)',
  },
  {
    Code: '15-2021.00',
    Occupation: 'Mathematicians',
    'Projected Growth (2021-2031)': 'Little or no change',
    'Projected Job Openings (2021-2031)': 100,
    Industries:
      'Government (65%), Professional, Scientific, and Technical Services (13%), Educational Services (13%)',
  },
  {
    Code: '51-3022.00',
    Occupation: 'Meat, Poultry, and Fish Cutters and Trimmers',
    'Projected Growth (2021-2031)': 'Little or no change',
    'Projected Job Openings (2021-2031)': 17500,
    Industries: 'Manufacturing (78%), Retail Trade (13%)',
  },
  {
    Code: '49-9011.00',
    Occupation: 'Mechanical Door Repairers',
    'Projected Growth (2021-2031)': 'Much faster than average',
    'Projected Job Openings (2021-2031)': 2200,
    Industries: 'Construction (71%), Retail Trade (18%)',
  },
  {
    Code: '17-3013.00',
    Occupation: 'Mechanical Drafters',
    'Projected Growth (2021-2031)': 'Decline',
    'Projected Job Openings (2021-2031)': 3900,
    Industries:
      'Manufacturing (52%), Professional, Scientific, and Technical Services (25%)',
  },
  {
    Code: '17-3027.00',
    Occupation: 'Mechanical Engineering Technologists and Technicians',
    'Projected Growth (2021-2031)': 'Slower than average',
    'Projected Job Openings (2021-2031)': 4200,
    Industries:
      'Manufacturing (46%), Professional, Scientific, and Technical Services (35%)',
  },
  {
    Code: '17-3027.01',
    Occupation: 'Automotive Engineering Technicians',
    'Projected Growth (2021-2031)': 'Slower than average',
    'Projected Job Openings (2021-2031)': 4200,
    Industries:
      'Manufacturing (46%), Professional, Scientific, and Technical Services (35%)',
  },
  {
    Code: '17-2141.00',
    Occupation: 'Mechanical Engineers',
    'Projected Growth (2021-2031)': 'Slower than average',
    'Projected Job Openings (2021-2031)': 17900,
    Industries:
      'Manufacturing (48%), Professional, Scientific, and Technical Services (30%)',
  },
  {
    Code: '17-2141.01',
    Occupation: 'Fuel Cell Engineers',
    'Projected Growth (2021-2031)': 'Slower than average',
    'Projected Job Openings (2021-2031)': 17900,
    Industries:
      'Manufacturing (48%), Professional, Scientific, and Technical Services (30%)',
  },
  {
    Code: '17-2141.02',
    Occupation: 'Automotive Engineers',
    'Projected Growth (2021-2031)': 'Slower than average',
    'Projected Job Openings (2021-2031)': 17900,
    Industries:
      'Manufacturing (48%), Professional, Scientific, and Technical Services (30%)',
  },
  {
    Code: '27-4099.00',
    Occupation: 'Media and Communication Equipment Workers, All Other',
    'Projected Growth (2021-2031)': 'Average',
    'Projected Job Openings (2021-2031)': 2100,
    Industries:
      'Information (39%), Government (24%), Educational Services (10%)',
  },
  {
    Code: '27-3099.00',
    Occupation: 'Media and Communication Workers, All Other',
    'Projected Growth (2021-2031)': 'Average',
    'Projected Job Openings (2021-2031)': 2100,
    Industries: 'Information (33%), Educational Services (17%)',
  },
  {
    Code: '11-9111.00',
    Occupation: 'Medical and Health Services Managers',
    'Projected Growth (2021-2031)': 'Much faster than average',
    'Projected Job Openings (2021-2031)': 56600,
    Industries: 'Health Care and Social Assistance (73%)',
  },
  {
    Code: '51-9082.00',
    Occupation: 'Medical Appliance Technicians',
    'Projected Growth (2021-2031)': 'Average',
    'Projected Job Openings (2021-2031)': 2300,
    Industries:
      'Manufacturing (54%), Health Care and Social Assistance (14%), Wholesale Trade (10%)',
  },
  {
    Code: '31-9092.00',
    Occupation: 'Medical Assistants',
    'Projected Growth (2021-2031)': 'Much faster than average',
    'Projected Job Openings (2021-2031)': 123000,
    Industries: 'Health Care and Social Assistance (94%)',
  },
  {
    Code: '29-2036.00',
    Occupation: 'Medical Dosimetrists',
    'Projected Growth (2021-2031)': 'Slower than average',
    'Projected Job Openings (2021-2031)': 100,
    Industries: 'Health Care and Social Assistance (86%)',
  },
  {
    Code: '31-9093.00',
    Occupation: 'Medical Equipment Preparers',
    'Projected Growth (2021-2031)': 'Average',
    'Projected Job Openings (2021-2031)': 9500,
    Industries: 'Health Care and Social Assistance (92%)',
  },
  {
    Code: '49-9062.00',
    Occupation: 'Medical Equipment Repairers',
    'Projected Growth (2021-2031)': 'Much faster than average',
    'Projected Job Openings (2021-2031)': 7700,
    Industries:
      'Wholesale Trade (38%), Health Care and Social Assistance (21%), Other Services (Except Public Administration) (18%)',
  },
  {
    Code: '29-2072.00',
    Occupation: 'Medical Records Specialists',
    'Projected Growth (2021-2031)': 'Average',
    'Projected Job Openings (2021-2031)': 14900,
    Industries: 'Health Care and Social Assistance (64%)',
  },
  {
    Code: '19-1042.00',
    Occupation: 'Medical Scientists, Except Epidemiologists',
    'Projected Growth (2021-2031)': 'Much faster than average',
    'Projected Job Openings (2021-2031)': 10000,
    Industries:
      'Professional, Scientific, and Technical Services (39%), Health Care and Social Assistance (25%), Educational Services (23%)',
  },
  {
    Code: '43-6013.00',
    Occupation: 'Medical Secretaries and Administrative Assistants',
    'Projected Growth (2021-2031)': 'Faster than average',
    'Projected Job Openings (2021-2031)': 85800,
    Industries: 'Health Care and Social Assistance (91%)',
  },
  {
    Code: '31-9094.00',
    Occupation: 'Medical Transcriptionists',
    'Projected Growth (2021-2031)': 'Decline',
    'Projected Job Openings (2021-2031)': 9300,
    Industries:
      'Health Care and Social Assistance (46%), Administrative and Support Services (43%)',
  },
  {
    Code: '13-1121.00',
    Occupation: 'Meeting, Convention, and Event Planners',
    'Projected Growth (2021-2031)': 'Much faster than average',
    'Projected Job Openings (2021-2031)': 16600,
    Industries:
      'Other Services (Except Public Administration) (22%), Educational Services (12%), Arts, Entertainment, and Recreation (11%)',
  },
  {
    Code: '21-1023.00',
    Occupation: 'Mental Health and Substance Abuse Social Workers',
    'Projected Growth (2021-2031)': 'Much faster than average',
    'Projected Job Openings (2021-2031)': 12700,
    Industries: 'Health Care and Social Assistance (72%), Government (20%)',
  },
  {
    Code: '27-1026.00',
    Occupation: 'Merchandise Displayers and Window Trimmers',
    'Projected Growth (2021-2031)': 'Average',
    'Projected Job Openings (2021-2031)': 20100,
    Industries:
      'Retail Trade (45%), Wholesale Trade (29%), Professional, Scientific, and Technical Services (10%)',
  },
  {
    Code: '51-4199.00',
    Occupation: 'Metal Workers and Plastic Workers, All Other',
    'Projected Growth (2021-2031)': 'Decline',
    'Projected Job Openings (2021-2031)': 1800,
    Industries:
      'Manufacturing (52%), Administrative and Support Services (33%)',
  },
  {
    Code: '51-4051.00',
    Occupation: 'Metal-Refining Furnace Operators and Tenders',
    'Projected Growth (2021-2031)': 'Decline',
    'Projected Job Openings (2021-2031)': 1500,
    Industries: 'Manufacturing (97%)',
  },
  {
    Code: '43-5041.00',
    Occupation: 'Meter Readers, Utilities',
    'Projected Growth (2021-2031)': 'Decline',
    'Projected Job Openings (2021-2031)': 1600,
    Industries:
      'Government (46%), Utilities (36%), Administrative and Support Services (14%)',
  },
  {
    Code: '19-1022.00',
    Occupation: 'Microbiologists',
    'Projected Growth (2021-2031)': 'Faster than average',
    'Projected Job Openings (2021-2031)': 2000,
    Industries:
      'Professional, Scientific, and Technical Services (38%), Government (21%), Manufacturing (20%)',
  },
  {
    Code: '25-2022.00',
    Occupation:
      'Middle School Teachers, Except Special and Career/Technical Education',
    'Projected Growth (2021-2031)': 'Average',
    'Projected Job Openings (2021-2031)': 47300,
    Industries: 'Educational Services (99%)',
  },
  {
    Code: '51-4035.00',
    Occupation:
      'Milling and Planing Machine Setters, Operators, and Tenders, Metal and Plastic',
    'Projected Growth (2021-2031)': 'Decline',
    'Projected Job Openings (2021-2031)': 1600,
    Industries: 'Manufacturing (87%)',
  },
  {
    Code: '49-9044.00',
    Occupation: 'Millwrights',
    'Projected Growth (2021-2031)': 'Average',
    'Projected Job Openings (2021-2031)': 3800,
    Industries: 'Construction (47%), Manufacturing (33%)',
  },
  {
    Code: '17-2151.00',
    Occupation:
      'Mining and Geological Engineers, Including Mining Safety Engineers',
    'Projected Growth (2021-2031)': 'Slower than average',
    'Projected Job Openings (2021-2031)': 500,
    Industries:
      'Professional, Scientific, and Technical Services (40%), Mining, Quarrying, and Oil and Gas Extraction (37%)',
  },
  {
    Code: '',
    Occupation: 'Miscellaneous Assemblers and Fabricators',
    'Projected Growth (2021-2031)': 'Decline',
    'Projected Job Openings (2021-2031)': 142700,
    Industries:
      'Manufacturing (74%), Administrative and Support Services (16%)',
  },
  {
    Code: '51-2092.00',
    Occupation: 'Team Assemblers',
    'Projected Growth (2021-2031)': 'Decline',
    'Projected Job Openings (2021-2031)': 142700,
    Industries:
      'Manufacturing (74%), Administrative and Support Services (16%)',
  },
  {
    Code: '51-2099.00',
    Occupation: 'Assemblers and Fabricators, All Other',
    'Projected Growth (2021-2031)': 'Decline',
    'Projected Job Openings (2021-2031)': 142700,
    Industries:
      'Manufacturing (74%), Administrative and Support Services (16%)',
  },
  {
    Code: '',
    Occupation: 'Miscellaneous Construction and Related Workers',
    'Projected Growth (2021-2031)': 'Average',
    'Projected Job Openings (2021-2031)': 3800,
    Industries: 'Construction (55%)',
  },
  {
    Code: '47-4091.00',
    Occupation: 'Segmental Pavers',
    'Projected Growth (2021-2031)': 'Average',
    'Projected Job Openings (2021-2031)': 3800,
    Industries: 'Construction (55%)',
  },
  {
    Code: '47-4099.00',
    Occupation: 'Construction and Related Workers, All Other',
    'Projected Growth (2021-2031)': 'Average',
    'Projected Job Openings (2021-2031)': 3800,
    Industries: 'Construction (55%)',
  },
  {
    Code: '47-4099.03',
    Occupation: 'Weatherization Installers and Technicians',
    'Projected Growth (2021-2031)': 'Average',
    'Projected Job Openings (2021-2031)': 3800,
    Industries: 'Construction (55%)',
  },
  {
    Code: '51-9023.00',
    Occupation: 'Mixing and Blending Machine Setters, Operators, and Tenders',
    'Projected Growth (2021-2031)': 'Slower than average',
    'Projected Job Openings (2021-2031)': 12800,
    Industries: 'Manufacturing (85%)',
  },
  {
    Code: '49-3042.00',
    Occupation: 'Mobile Heavy Equipment Mechanics, Except Engines',
    'Projected Growth (2021-2031)': 'Faster than average',
    'Projected Job Openings (2021-2031)': 16300,
    Industries:
      'Wholesale Trade (27%), Construction (23%), Real Estate and Rental and Leasing (11%), Government (11%)',
  },
  {
    Code: '51-4061.00',
    Occupation: 'Model Makers, Metal and Plastic',
    'Projected Growth (2021-2031)': 'Decline',
    'Projected Job Openings (2021-2031)': 300,
    Industries:
      'Manufacturing (61%), Professional, Scientific, and Technical Services (33%)',
  },
  {
    Code: '51-7031.00',
    Occupation: 'Model Makers, Wood',
    'Projected Growth (2021-2031)': 'Average',
    'Projected Job Openings (2021-2031)': 100,
    Industries: 'Manufacturing (23%), Government (12%)',
  },
  {
    Code: '41-9012.00',
    Occupation: 'Models',
    'Projected Growth (2021-2031)': 'Faster than average',
    'Projected Job Openings (2021-2031)': 500,
    Industries: 'Educational Services (31%)',
  },
  {
    Code: '51-9195.00',
    Occupation: 'Molders, Shapers, and Casters, Except Metal and Plastic',
    'Projected Growth (2021-2031)': 'Much faster than average',
    'Projected Job Openings (2021-2031)': 5700,
    Industries: 'Manufacturing (77%)',
  },
  {
    Code: '51-9195.03',
    Occupation: 'Stone Cutters and Carvers, Manufacturing',
    'Projected Growth (2021-2031)': 'Much faster than average',
    'Projected Job Openings (2021-2031)': 5700,
    Industries: 'Manufacturing (77%)',
  },
  {
    Code: '51-9195.04',
    Occupation: 'Glass Blowers, Molders, Benders, and Finishers',
    'Projected Growth (2021-2031)': 'Much faster than average',
    'Projected Job Openings (2021-2031)': 5700,
    Industries: 'Manufacturing (77%)',
  },
  {
    Code: '51-9195.05',
    Occupation: 'Potters, Manufacturing',
    'Projected Growth (2021-2031)': 'Much faster than average',
    'Projected Job Openings (2021-2031)': 5700,
    Industries: 'Manufacturing (77%)',
  },
  {
    Code: '51-4072.00',
    Occupation:
      'Molding, Coremaking, and Casting Machine Setters, Operators, and Tenders, Metal and Plastic',
    'Projected Growth (2021-2031)': 'Little or no change',
    'Projected Job Openings (2021-2031)': 16800,
    Industries: 'Manufacturing (90%)',
  },
  {
    Code: '39-4031.00',
    Occupation: 'Morticians, Undertakers, and Funeral Arrangers',
    'Projected Growth (2021-2031)': 'Faster than average',
    'Projected Job Openings (2021-2031)': 4300,
    Industries: 'Other Services (Except Public Administration) (91%)',
  },
  {
    Code: '39-3021.00',
    Occupation: 'Motion Picture Projectionists',
    'Projected Growth (2021-2031)': 'Much faster than average',
    'Projected Job Openings (2021-2031)': 700,
    Industries: 'Information (85%)',
  },
  {
    Code: '53-3099.00',
    Occupation: 'Motor Vehicle Operators, All Other',
    'Projected Growth (2021-2031)': 'Faster than average',
    'Projected Job Openings (2021-2031)': 11900,
    Industries:
      'Retail Trade (14%), Government (13%), Administrative and Support Services (10%)',
  },
  {
    Code: '49-3051.00',
    Occupation: 'Motorboat Mechanics and Service Technicians',
    'Projected Growth (2021-2031)': 'Faster than average',
    'Projected Job Openings (2021-2031)': 3000,
    Industries:
      'Retail Trade (41%), Other Services (Except Public Administration) (22%), Arts, Entertainment, and Recreation (15%)',
  },
  {
    Code: '53-5022.00',
    Occupation: 'Motorboat Operators',
    'Projected Growth (2021-2031)': 'Little or no change',
    'Projected Job Openings (2021-2031)': 300,
    Industries:
      'Transportation and Warehousing (30%), Construction (17%), Arts, Entertainment, and Recreation (16%), Government (12%)',
  },
  {
    Code: '49-3052.00',
    Occupation: 'Motorcycle Mechanics',
    'Projected Growth (2021-2031)': 'Average',
    'Projected Job Openings (2021-2031)': 1800,
    Industries:
      'Retail Trade (80%), Other Services (Except Public Administration) (10%)',
  },
  {
    Code: '51-4081.00',
    Occupation:
      'Multiple Machine Tool Setters, Operators, and Tenders, Metal and Plastic',
    'Projected Growth (2021-2031)': 'Slower than average',
    'Projected Job Openings (2021-2031)': 15300,
    Industries:
      'Manufacturing (84%), Administrative and Support Services (10%)',
  },
  {
    Code: '25-4013.00',
    Occupation: 'Museum Technicians and Conservators',
    'Projected Growth (2021-2031)': 'Much faster than average',
    'Projected Job Openings (2021-2031)': 1800,
    Industries:
      'Arts, Entertainment, and Recreation (48%), Government (28%), Educational Services (15%)',
  },
  {
    Code: '27-2041.00',
    Occupation: 'Music Directors and Composers',
    'Projected Growth (2021-2031)': 'Average',
    'Projected Job Openings (2021-2031)': 5800,
    Industries: 'Other Services (Except Public Administration) (60%)',
  },
  {
    Code: '49-9063.00',
    Occupation: 'Musical Instrument Repairers and Tuners',
    'Projected Growth (2021-2031)': 'Decline',
    'Projected Job Openings (2021-2031)': 600,
    Industries:
      'Retail Trade (63%), Other Services (Except Public Administration) (12%)',
  },
  {
    Code: '27-2042.00',
    Occupation: 'Musicians and Singers',
    'Projected Growth (2021-2031)': 'Average',
    'Projected Job Openings (2021-2031)': 20800,
    Industries: 'Other Services (Except Public Administration) (43%)',
  },
  {
    Code: '11-9121.00',
    Occupation: 'Natural Sciences Managers',
    'Projected Growth (2021-2031)': 'Average',
    'Projected Job Openings (2021-2031)': 6900,
    Industries:
      'Professional, Scientific, and Technical Services (44%), Government (23%)',
  },
  {
    Code: '11-9121.01',
    Occupation: 'Clinical Research Coordinators',
    'Projected Growth (2021-2031)': 'Average',
    'Projected Job Openings (2021-2031)': 6900,
    Industries:
      'Professional, Scientific, and Technical Services (44%), Government (23%)',
  },
  {
    Code: '11-9121.02',
    Occupation: 'Water Resource Specialists',
    'Projected Growth (2021-2031)': 'Average',
    'Projected Job Openings (2021-2031)': 6900,
    Industries:
      'Professional, Scientific, and Technical Services (44%), Government (23%)',
  },
  {
    Code: '15-1244.00',
    Occupation: 'Network and Computer Systems Administrators',
    'Projected Growth (2021-2031)': 'Slower than average',
    'Projected Job Openings (2021-2031)': 23900,
    Industries:
      'Professional, Scientific, and Technical Services (27%), Educational Services (11%), Information (10%)',
  },
  {
    Code: '29-1217.00',
    Occupation: 'Neurologists',
    'Projected Growth (2021-2031)': 'Slower than average',
    'Projected Job Openings (2021-2031)': 200,
    Industries: 'Health Care and Social Assistance (91%)',
  },
  {
    Code: '43-4141.00',
    Occupation: 'New Accounts Clerks',
    'Projected Growth (2021-2031)': 'Decline',
    'Projected Job Openings (2021-2031)': 3900,
    Industries: 'Finance and Insurance (94%)',
  },
  {
    Code: '27-3023.00',
    Occupation: 'News Analysts, Reporters, and Journalists',
    'Projected Growth (2021-2031)': 'Decline',
    'Projected Job Openings (2021-2031)': 4900,
    Industries: 'Information (80%)',
  },
  {
    Code: '17-2161.00',
    Occupation: 'Nuclear Engineers',
    'Projected Growth (2021-2031)': 'Decline',
    'Projected Job Openings (2021-2031)': 700,
    Industries:
      'Utilities (32%), Professional, Scientific, and Technical Services (26%), Government (22%)',
  },
  {
    Code: '29-2033.00',
    Occupation: 'Nuclear Medicine Technologists',
    'Projected Growth (2021-2031)': 'Slower than average',
    'Projected Job Openings (2021-2031)': 1000,
    Industries: 'Health Care and Social Assistance (90%)',
  },
  {
    Code: '51-8011.00',
    Occupation: 'Nuclear Power Reactor Operators',
    'Projected Growth (2021-2031)': 'Decline',
    'Projected Job Openings (2021-2031)': 300,
    Industries: 'Utilities (81%), Government (14%)',
  },
  {
    Code: '19-4051.00',
    Occupation: 'Nuclear Technicians',
    'Projected Growth (2021-2031)': 'Decline',
    'Projected Job Openings (2021-2031)': 400,
    Industries:
      'Utilities (58%), Professional, Scientific, and Technical Services (24%)',
  },
  {
    Code: '19-4051.02',
    Occupation: 'Nuclear Monitoring Technicians',
    'Projected Growth (2021-2031)': 'Decline',
    'Projected Job Openings (2021-2031)': 400,
    Industries:
      'Utilities (58%), Professional, Scientific, and Technical Services (24%)',
  },
  {
    Code: '29-1151.00',
    Occupation: 'Nurse Anesthetists',
    'Projected Growth (2021-2031)': 'Much faster than average',
    'Projected Job Openings (2021-2031)': 2900,
    Industries: 'Health Care and Social Assistance (96%)',
  },
  {
    Code: '29-1161.00',
    Occupation: 'Nurse Midwives',
    'Projected Growth (2021-2031)': 'Faster than average',
    'Projected Job Openings (2021-2031)': 500,
    Industries: 'Health Care and Social Assistance (95%)',
  },
  {
    Code: '29-1171.00',
    Occupation: 'Nurse Practitioners',
    'Projected Growth (2021-2031)': 'Much faster than average',
    'Projected Job Openings (2021-2031)': 26800,
    Industries: 'Health Care and Social Assistance (88%)',
  },
  {
    Code: '31-1131.00',
    Occupation: 'Nursing Assistants',
    'Projected Growth (2021-2031)': 'Average',
    'Projected Job Openings (2021-2031)': 212700,
    Industries: 'Health Care and Social Assistance (90%)',
  },
  {
    Code: '25-1072.00',
    Occupation: 'Nursing Instructors and Teachers, Postsecondary',
    'Projected Growth (2021-2031)': 'Much faster than average',
    'Projected Job Openings (2021-2031)': 9900,
    Industries: 'Educational Services (95%)',
  },
  {
    Code: '29-1218.00',
    Occupation: 'Obstetricians and Gynecologists',
    'Projected Growth (2021-2031)': 'Slower than average',
    'Projected Job Openings (2021-2031)': 700,
    Industries: 'Health Care and Social Assistance (91%)',
  },
  {
    Code: '19-5011.00',
    Occupation: 'Occupational Health and Safety Specialists',
    'Projected Growth (2021-2031)': 'Average',
    'Projected Job Openings (2021-2031)': 12000,
    Industries:
      'Government (21%), Manufacturing (16%), Professional, Scientific, and Technical Services (14%), Construction (13%)',
  },
  {
    Code: '19-5012.00',
    Occupation: 'Occupational Health and Safety Technicians',
    'Projected Growth (2021-2031)': 'Average',
    'Projected Job Openings (2021-2031)': 2500,
    Industries:
      'Manufacturing (23%), Professional, Scientific, and Technical Services (21%), Construction (10%)',
  },
  {
    Code: '29-1122.00',
    Occupation: 'Occupational Therapists',
    'Projected Growth (2021-2031)': 'Much faster than average',
    'Projected Job Openings (2021-2031)': 10100,
    Industries:
      'Health Care and Social Assistance (81%), Educational Services (13%)',
  },
  {
    Code: '29-1122.01',
    Occupation:
      'Low Vision Therapists, Orientation and Mobility Specialists, and Vision Rehabilitation Therapists',
    'Projected Growth (2021-2031)': 'Much faster than average',
    'Projected Job Openings (2021-2031)': 10100,
    Industries:
      'Health Care and Social Assistance (81%), Educational Services (13%)',
  },
  {
    Code: '31-2012.00',
    Occupation: 'Occupational Therapy Aides',
    'Projected Growth (2021-2031)': 'Much faster than average',
    'Projected Job Openings (2021-2031)': 600,
    Industries: 'Health Care and Social Assistance (95%)',
  },
  {
    Code: '31-2011.00',
    Occupation: 'Occupational Therapy Assistants',
    'Projected Growth (2021-2031)': 'Much faster than average',
    'Projected Job Openings (2021-2031)': 8700,
    Industries: 'Health Care and Social Assistance (91%)',
  },
  {
    Code: '43-9199.00',
    Occupation: 'Office and Administrative Support Workers, All Other',
    'Projected Growth (2021-2031)': 'Decline',
    'Projected Job Openings (2021-2031)': 19000,
    Industries: 'Government (25%), Educational Services (24%)',
  },
  {
    Code: '43-9061.00',
    Occupation: 'Office Clerks, General',
    'Projected Growth (2021-2031)': 'Decline',
    'Projected Job Openings (2021-2031)': 325400,
    Industries:
      'Health Care and Social Assistance (11%), Educational Services (11%), Government (10%)',
  },
  {
    Code: '43-9071.00',
    Occupation: 'Office Machine Operators, Except Computer',
    'Projected Growth (2021-2031)': 'Decline',
    'Projected Job Openings (2021-2031)': 4300,
    Industries:
      'Administrative and Support Services (24%), Finance and Insurance (15%), Information (12%)',
  },
  {
    Code: '47-2073.00',
    Occupation:
      'Operating Engineers and Other Construction Equipment Operators',
    'Projected Growth (2021-2031)': 'Average',
    'Projected Job Openings (2021-2031)': 45400,
    Industries: 'Construction (61%), Government (19%)',
  },
  {
    Code: '15-2031.00',
    Occupation: 'Operations Research Analysts',
    'Projected Growth (2021-2031)': 'Much faster than average',
    'Projected Job Openings (2021-2031)': 10300,
    Industries:
      'Professional, Scientific, and Technical Services (28%), Finance and Insurance (25%), Government (11%)',
  },
  {
    Code: '51-9083.00',
    Occupation: 'Ophthalmic Laboratory Technicians',
    'Projected Growth (2021-2031)': 'Average',
    'Projected Job Openings (2021-2031)': 2800,
    Industries:
      'Manufacturing (36%), Health Care and Social Assistance (32%), Retail Trade (19%)',
  },
  {
    Code: '29-2057.00',
    Occupation: 'Ophthalmic Medical Technicians',
    'Projected Growth (2021-2031)': 'Much faster than average',
    'Projected Job Openings (2021-2031)': 8700,
    Industries: 'Health Care and Social Assistance (95%)',
  },
  {
    Code: '29-1241.00',
    Occupation: 'Ophthalmologists, Except Pediatric',
    'Projected Growth (2021-2031)': 'Average',
    'Projected Job Openings (2021-2031)': 400,
    Industries: 'Health Care and Social Assistance (95%)',
  },
  {
    Code: '29-2081.00',
    Occupation: 'Opticians, Dispensing',
    'Projected Growth (2021-2031)': 'Average',
    'Projected Job Openings (2021-2031)': 6700,
    Industries: 'Health Care and Social Assistance (55%), Retail Trade (43%)',
  },
  {
    Code: '29-1041.00',
    Occupation: 'Optometrists',
    'Projected Growth (2021-2031)': 'Faster than average',
    'Projected Job Openings (2021-2031)': 1700,
    Industries: 'Health Care and Social Assistance (82%), Retail Trade (12%)',
  },
  {
    Code: '29-1022.00',
    Occupation: 'Oral and Maxillofacial Surgeons',
    'Projected Growth (2021-2031)': 'Average',
    'Projected Job Openings (2021-2031)': 200,
    Industries: 'Health Care and Social Assistance (84%)',
  },
  {
    Code: '43-4151.00',
    Occupation: 'Order Clerks',
    'Projected Growth (2021-2031)': 'Decline',
    'Projected Job Openings (2021-2031)': 13000,
    Industries:
      'Retail Trade (34%), Wholesale Trade (24%), Manufacturing (11%), Transportation and Warehousing (10%)',
  },
  {
    Code: '31-1132.00',
    Occupation: 'Orderlies',
    'Projected Growth (2021-2031)': 'Average',
    'Projected Job Openings (2021-2031)': 7500,
    Industries: 'Health Care and Social Assistance (94%)',
  },
  {
    Code: '29-1023.00',
    Occupation: 'Orthodontists',
    'Projected Growth (2021-2031)': 'Average',
    'Projected Job Openings (2021-2031)': 200,
    Industries: 'Health Care and Social Assistance (87%)',
  },
  {
    Code: '29-1242.00',
    Occupation: 'Orthopedic Surgeons, Except Pediatric',
    'Projected Growth (2021-2031)': 'Slower than average',
    'Projected Job Openings (2021-2031)': 500,
    Industries: 'Health Care and Social Assistance (97%)',
  },
  {
    Code: '29-2091.00',
    Occupation: 'Orthotists and Prosthetists',
    'Projected Growth (2021-2031)': 'Much faster than average',
    'Projected Job Openings (2021-2031)': 1000,
    Industries:
      'Health Care and Social Assistance (39%), Manufacturing (32%), Retail Trade (13%)',
  },
  {
    Code: '49-3053.00',
    Occupation: 'Outdoor Power Equipment and Other Small Engine Mechanics',
    'Projected Growth (2021-2031)': 'Slower than average',
    'Projected Job Openings (2021-2031)': 4100,
    Industries:
      'Retail Trade (39%), Administrative and Support Services (16%), Arts, Entertainment, and Recreation (12%)',
  },
  {
    Code: '51-9111.00',
    Occupation: 'Packaging and Filling Machine Operators and Tenders',
    'Projected Growth (2021-2031)': 'Average',
    'Projected Job Openings (2021-2031)': 43800,
    Industries:
      'Manufacturing (81%), Administrative and Support Services (10%)',
  },
  {
    Code: '53-7064.00',
    Occupation: 'Packers and Packagers, Hand',
    'Projected Growth (2021-2031)': 'Little or no change',
    'Projected Job Openings (2021-2031)': 92400,
    Industries:
      'Retail Trade (25%), Manufacturing (24%), Administrative and Support Services (23%), Transportation and Warehousing (14%)',
  },
  {
    Code: '47-2141.00',
    Occupation: 'Painters, Construction and Maintenance',
    'Projected Growth (2021-2031)': 'Little or no change',
    'Projected Job Openings (2021-2031)': 31600,
    Industries: 'Construction (47%)',
  },
  {
    Code: '51-9123.00',
    Occupation: 'Painting, Coating, and Decorating Workers',
    'Projected Growth (2021-2031)': 'Slower than average',
    'Projected Job Openings (2021-2031)': 1400,
    Industries: 'Manufacturing (64%)',
  },
  {
    Code: '51-9196.00',
    Occupation: 'Paper Goods Machine Setters, Operators, and Tenders',
    'Projected Growth (2021-2031)': 'Decline',
    'Projected Job Openings (2021-2031)': 10100,
    Industries: 'Manufacturing (94%)',
  },
  {
    Code: '47-2142.00',
    Occupation: 'Paperhangers',
    'Projected Growth (2021-2031)': 'Slower than average',
    'Projected Job Openings (2021-2031)': 400,
    Industries:
      'Professional, Scientific, and Technical Services (26%), Construction (24%)',
  },
  {
    Code: '23-2011.00',
    Occupation: 'Paralegals and Legal Assistants',
    'Projected Growth (2021-2031)': 'Much faster than average',
    'Projected Job Openings (2021-2031)': 45800,
    Industries:
      'Professional, Scientific, and Technical Services (75%), Government (12%)',
  },
  {
    Code: '29-2043.00',
    Occupation: 'Paramedics',
    'Projected Growth (2021-2031)': 'Average',
    'Projected Job Openings (2021-2031)': 6000,
    Industries: 'Health Care and Social Assistance (63%), Government (34%)',
  },
  {
    Code: '53-6021.00',
    Occupation: 'Parking Attendants',
    'Projected Growth (2021-2031)': 'Average',
    'Projected Job Openings (2021-2031)': 14800,
    Industries:
      'Other Services (Except Public Administration) (51%), Retail Trade (17%)',
  },
  {
    Code: '33-3041.00',
    Occupation: 'Parking Enforcement Workers',
    'Projected Growth (2021-2031)': 'Decline',
    'Projected Job Openings (2021-2031)': 400,
    Industries: 'Government (75%), Educational Services (12%)',
  },
  {
    Code: '41-2022.00',
    Occupation: 'Parts Salespersons',
    'Projected Growth (2021-2031)': 'Average',
    'Projected Job Openings (2021-2031)': 35600,
    Industries: 'Retail Trade (72%), Wholesale Trade (20%)',
  },
  {
    Code: '53-6061.00',
    Occupation: 'Passenger Attendants',
    'Projected Growth (2021-2031)': 'Little or no change',
    'Projected Job Openings (2021-2031)': 4000,
    Industries: 'Transportation and Warehousing (81%), Government (10%)',
  },
  {
    Code: '51-4062.00',
    Occupation: 'Patternmakers, Metal and Plastic',
    'Projected Growth (2021-2031)': 'Decline',
    'Projected Job Openings (2021-2031)': 200,
    Industries: 'Manufacturing (99%)',
  },
  {
    Code: '51-7032.00',
    Occupation: 'Patternmakers, Wood',
    'Projected Growth (2021-2031)': 'Faster than average',
    'Projected Job Openings (2021-2031)': 0,
    Industries: 'Manufacturing (64%)',
  },
  {
    Code: '47-2071.00',
    Occupation: 'Paving, Surfacing, and Tamping Equipment Operators',
    'Projected Growth (2021-2031)': 'Average',
    'Projected Job Openings (2021-2031)': 4900,
    Industries: 'Construction (77%), Government (12%)',
  },
  {
    Code: '43-3051.00',
    Occupation: 'Payroll and Timekeeping Clerks',
    'Projected Growth (2021-2031)': 'Decline',
    'Projected Job Openings (2021-2031)': 13800,
    Industries:
      'Professional, Scientific, and Technical Services (14%), Administrative and Support Services (12%), Health Care and Social Assistance (10%)',
  },
  {
    Code: '29-1243.00',
    Occupation: 'Pediatric Surgeons',
    'Projected Growth (2021-2031)': 'Slower than average',
    'Projected Job Openings (2021-2031)': 0,
    Industries: 'Health Care and Social Assistance (98%)',
  },
  {
    Code: '29-1221.00',
    Occupation: 'Pediatricians, General',
    'Projected Growth (2021-2031)': 'Little or no change',
    'Projected Job Openings (2021-2031)': 1100,
    Industries: 'Health Care and Social Assistance (91%)',
  },
  {
    Code: '39-9099.00',
    Occupation: 'Personal Care and Service Workers, All Other',
    'Projected Growth (2021-2031)': 'Much faster than average',
    'Projected Job Openings (2021-2031)': 22600,
    Industries:
      'Health Care and Social Assistance (45%), Other Services (Except Public Administration) (24%)',
  },
  {
    Code: '13-2052.00',
    Occupation: 'Personal Financial Advisors',
    'Projected Growth (2021-2031)': 'Much faster than average',
    'Projected Job Openings (2021-2031)': 30500,
    Industries: 'Finance and Insurance (77%)',
  },
  {
    Code: '11-9179.00',
    Occupation: 'Personal Service Managers, All Other',
    'Projected Growth (2021-2031)': 'Faster than average',
    'Projected Job Openings (2021-2031)': 2100,
    Industries: 'Other Services (Except Public Administration) (21%)',
  },
  {
    Code: '11-9179.01',
    Occupation: 'Fitness and Wellness Coordinators',
    'Projected Growth (2021-2031)': 'Faster than average',
    'Projected Job Openings (2021-2031)': 2100,
    Industries: 'Other Services (Except Public Administration) (21%)',
  },
  {
    Code: '11-9179.02',
    Occupation: 'Spa Managers',
    'Projected Growth (2021-2031)': 'Faster than average',
    'Projected Job Openings (2021-2031)': 2100,
    Industries: 'Other Services (Except Public Administration) (21%)',
  },
  {
    Code: '37-2021.00',
    Occupation: 'Pest Control Workers',
    'Projected Growth (2021-2031)': 'Average',
    'Projected Job Openings (2021-2031)': 13300,
    Industries: 'Administrative and Support Services (93%)',
  },
  {
    Code: '37-3012.00',
    Occupation: 'Pesticide Handlers, Sprayers, and Applicators, Vegetation',
    'Projected Growth (2021-2031)': 'Average',
    'Projected Job Openings (2021-2031)': 3800,
    Industries:
      'Administrative and Support Services (45%), Wholesale Trade (26%), Agriculture, Forestry, Fishing, and Hunting (12%)',
  },
  {
    Code: '17-2171.00',
    Occupation: 'Petroleum Engineers',
    'Projected Growth (2021-2031)': 'Faster than average',
    'Projected Job Openings (2021-2031)': 1700,
    Industries:
      'Mining, Quarrying, and Oil and Gas Extraction (44%), Management of Companies and Enterprises (20%), Professional, Scientific, and Technical Services (15%)',
  },
  {
    Code: '51-8093.00',
    Occupation:
      'Petroleum Pump System Operators, Refinery Operators, and Gaugers',
    'Projected Growth (2021-2031)': 'Slower than average',
    'Projected Job Openings (2021-2031)': 3900,
    Industries:
      'Manufacturing (46%), Transportation and Warehousing (26%), Mining, Quarrying, and Oil and Gas Extraction (13%)',
  },
  {
    Code: '29-1051.00',
    Occupation: 'Pharmacists',
    'Projected Growth (2021-2031)': 'Slower than average',
    'Projected Job Openings (2021-2031)': 13600,
    Industries: 'Retail Trade (56%), Health Care and Social Assistance (33%)',
  },
  {
    Code: '31-9095.00',
    Occupation: 'Pharmacy Aides',
    'Projected Growth (2021-2031)': 'Decline',
    'Projected Job Openings (2021-2031)': 7500,
    Industries: 'Retail Trade (81%), Health Care and Social Assistance (16%)',
  },
  {
    Code: '29-2052.00',
    Occupation: 'Pharmacy Technicians',
    'Projected Growth (2021-2031)': 'Average',
    'Projected Job Openings (2021-2031)': 43500,
    Industries: 'Retail Trade (72%), Health Care and Social Assistance (20%)',
  },
  {
    Code: '25-1126.00',
    Occupation: 'Philosophy and Religion Teachers, Postsecondary',
    'Projected Growth (2021-2031)': 'Faster than average',
    'Projected Job Openings (2021-2031)': 2700,
    Industries: 'Educational Services (98%)',
  },
  {
    Code: '31-9097.00',
    Occupation: 'Phlebotomists',
    'Projected Growth (2021-2031)': 'Faster than average',
    'Projected Job Openings (2021-2031)': 21500,
    Industries: 'Health Care and Social Assistance (95%)',
  },
  {
    Code: '27-4021.00',
    Occupation: 'Photographers',
    'Projected Growth (2021-2031)': 'Faster than average',
    'Projected Job Openings (2021-2031)': 12700,
    Industries: 'Professional, Scientific, and Technical Services (17%)',
  },
  {
    Code: '51-9151.00',
    Occupation: 'Photographic Process Workers and Processing Machine Operators',
    'Projected Growth (2021-2031)': 'Average',
    'Projected Job Openings (2021-2031)': 1100,
    Industries:
      'Professional, Scientific, and Technical Services (29%), Other Services (Except Public Administration) (16%), Retail Trade (10%)',
  },
  {
    Code: '19-2099.00',
    Occupation: 'Physical Scientists, All Other',
    'Projected Growth (2021-2031)': 'Little or no change',
    'Projected Job Openings (2021-2031)': 2200,
    Industries:
      'Government (39%), Educational Services (15%), Professional, Scientific, and Technical Services (13%), Agriculture, Forestry, Fishing, and Hunting (11%)',
  },
  {
    Code: '19-2099.01',
    Occupation: 'Remote Sensing Scientists and Technologists',
    'Projected Growth (2021-2031)': 'Little or no change',
    'Projected Job Openings (2021-2031)': 2200,
    Industries:
      'Government (39%), Educational Services (15%), Professional, Scientific, and Technical Services (13%), Agriculture, Forestry, Fishing, and Hunting (11%)',
  },
  {
    Code: '31-2022.00',
    Occupation: 'Physical Therapist Aides',
    'Projected Growth (2021-2031)': 'Much faster than average',
    'Projected Job Openings (2021-2031)': 7600,
    Industries: 'Health Care and Social Assistance (96%)',
  },
  {
    Code: '31-2021.00',
    Occupation: 'Physical Therapist Assistants',
    'Projected Growth (2021-2031)': 'Much faster than average',
    'Projected Job Openings (2021-2031)': 17900,
    Industries: 'Health Care and Social Assistance (97%)',
  },
  {
    Code: '29-1123.00',
    Occupation: 'Physical Therapists',
    'Projected Growth (2021-2031)': 'Much faster than average',
    'Projected Job Openings (2021-2031)': 15400,
    Industries: 'Health Care and Social Assistance (91%)',
  },
  {
    Code: '29-1071.00',
    Occupation: 'Physician Assistants',
    'Projected Growth (2021-2031)': 'Much faster than average',
    'Projected Job Openings (2021-2031)': 12700,
    Industries: 'Health Care and Social Assistance (87%)',
  },
  {
    Code: '29-1071.01',
    Occupation: 'Anesthesiologist Assistants',
    'Projected Growth (2021-2031)': 'Much faster than average',
    'Projected Job Openings (2021-2031)': 12700,
    Industries: 'Health Care and Social Assistance (87%)',
  },
  {
    Code: '29-1229.00',
    Occupation: 'Physicians, All Other',
    'Projected Growth (2021-2031)': 'Slower than average',
    'Projected Job Openings (2021-2031)': 8700,
    Industries: 'Health Care and Social Assistance (73%), Government (15%)',
  },
  {
    Code: '29-1229.01',
    Occupation: 'Allergists and Immunologists',
    'Projected Growth (2021-2031)': 'Slower than average',
    'Projected Job Openings (2021-2031)': 8700,
    Industries: 'Health Care and Social Assistance (73%), Government (15%)',
  },
  {
    Code: '29-1229.02',
    Occupation: 'Hospitalists',
    'Projected Growth (2021-2031)': 'Slower than average',
    'Projected Job Openings (2021-2031)': 8700,
    Industries: 'Health Care and Social Assistance (73%), Government (15%)',
  },
  {
    Code: '29-1229.03',
    Occupation: 'Urologists',
    'Projected Growth (2021-2031)': 'Slower than average',
    'Projected Job Openings (2021-2031)': 8700,
    Industries: 'Health Care and Social Assistance (73%), Government (15%)',
  },
  {
    Code: '29-1229.04',
    Occupation: 'Physical Medicine and Rehabilitation Physicians',
    'Projected Growth (2021-2031)': 'Slower than average',
    'Projected Job Openings (2021-2031)': 8700,
    Industries: 'Health Care and Social Assistance (73%), Government (15%)',
  },
  {
    Code: '29-1229.05',
    Occupation: 'Preventive Medicine Physicians',
    'Projected Growth (2021-2031)': 'Slower than average',
    'Projected Job Openings (2021-2031)': 8700,
    Industries: 'Health Care and Social Assistance (73%), Government (15%)',
  },
  {
    Code: '29-1229.06',
    Occupation: 'Sports Medicine Physicians',
    'Projected Growth (2021-2031)': 'Slower than average',
    'Projected Job Openings (2021-2031)': 8700,
    Industries: 'Health Care and Social Assistance (73%), Government (15%)',
  },
  {
    Code: '29-1222.00',
    Occupation: 'Physicians, Pathologists',
    'Projected Growth (2021-2031)': 'Average',
    'Projected Job Openings (2021-2031)': 400,
    Industries: 'Health Care and Social Assistance (83%)',
  },
  {
    Code: '19-2012.00',
    Occupation: 'Physicists',
    'Projected Growth (2021-2031)': 'Faster than average',
    'Projected Job Openings (2021-2031)': 1900,
    Industries:
      'Professional, Scientific, and Technical Services (48%), Government (16%), Educational Services (12%), Health Care and Social Assistance (10%)',
  },
  {
    Code: '25-1054.00',
    Occupation: 'Physics Teachers, Postsecondary',
    'Projected Growth (2021-2031)': 'Faster than average',
    'Projected Job Openings (2021-2031)': 1500,
    Industries: 'Educational Services (100%)',
  },
  {
    Code: '47-2072.00',
    Occupation: 'Pile Driver Operators',
    'Projected Growth (2021-2031)': 'Average',
    'Projected Job Openings (2021-2031)': 400,
    Industries: 'Construction (91%)',
  },
  {
    Code: '47-2151.00',
    Occupation: 'Pipelayers',
    'Projected Growth (2021-2031)': 'Decline',
    'Projected Job Openings (2021-2031)': 3200,
    Industries: 'Construction (70%), Government (22%)',
  },
  {
    Code: '51-8099.00',
    Occupation: 'Plant and System Operators, All Other',
    'Projected Growth (2021-2031)': 'Little or no change',
    'Projected Job Openings (2021-2031)': 1700,
    Industries: 'Manufacturing (47%), Government (19%)',
  },
  {
    Code: '51-8099.01',
    Occupation: 'Biofuels Processing Technicians',
    'Projected Growth (2021-2031)': 'Little or no change',
    'Projected Job Openings (2021-2031)': 1700,
    Industries: 'Manufacturing (47%), Government (19%)',
  },
  {
    Code: '47-2161.00',
    Occupation: 'Plasterers and Stucco Masons',
    'Projected Growth (2021-2031)': 'Average',
    'Projected Job Openings (2021-2031)': 2900,
    Industries: 'Construction (83%)',
  },
  {
    Code: '51-4193.00',
    Occupation:
      'Plating Machine Setters, Operators, and Tenders, Metal and Plastic',
    'Projected Growth (2021-2031)': 'Decline',
    'Projected Job Openings (2021-2031)': 3000,
    Industries: 'Manufacturing (94%)',
  },
  {
    Code: '47-2152.00',
    Occupation: 'Plumbers, Pipefitters, and Steamfitters',
    'Projected Growth (2021-2031)': 'Slower than average',
    'Projected Job Openings (2021-2031)': 48600,
    Industries: 'Construction (75%)',
  },
  {
    Code: '47-2152.04',
    Occupation: 'Solar Thermal Installers and Technicians',
    'Projected Growth (2021-2031)': 'Slower than average',
    'Projected Job Openings (2021-2031)': 48600,
    Industries: 'Construction (75%)',
  },
  {
    Code: '29-1081.00',
    Occupation: 'Podiatrists',
    'Projected Growth (2021-2031)': 'Slower than average',
    'Projected Job Openings (2021-2031)': 300,
    Industries: 'Health Care and Social Assistance (75%)',
  },
  {
    Code: '33-3051.00',
    Occupation: "Police and Sheriff's Patrol Officers",
    'Projected Growth (2021-2031)': 'Slower than average',
    'Projected Job Openings (2021-2031)': 58700,
    Industries: 'Government (95%)',
  },
  {
    Code: '33-3051.04',
    Occupation: 'Customs and Border Protection Officers',
    'Projected Growth (2021-2031)': 'Slower than average',
    'Projected Job Openings (2021-2031)': 58700,
    Industries: 'Government (95%)',
  },
  {
    Code: '25-1065.00',
    Occupation: 'Political Science Teachers, Postsecondary',
    'Projected Growth (2021-2031)': 'Faster than average',
    'Projected Job Openings (2021-2031)': 1700,
    Industries: 'Educational Services (100%)',
  },
  {
    Code: '19-3094.00',
    Occupation: 'Political Scientists',
    'Projected Growth (2021-2031)': 'Average',
    'Projected Job Openings (2021-2031)': 600,
    Industries:
      'Government (63%), Professional, Scientific, and Technical Services (16%), Other Services (Except Public Administration) (13%)',
  },
  {
    Code: '43-5051.00',
    Occupation: 'Postal Service Clerks',
    'Projected Growth (2021-2031)': 'Decline',
    'Projected Job Openings (2021-2031)': 6400,
    Industries: 'Government (100%)',
  },
  {
    Code: '43-5052.00',
    Occupation: 'Postal Service Mail Carriers',
    'Projected Growth (2021-2031)': 'Decline',
    'Projected Job Openings (2021-2031)': 22800,
    Industries: 'Government (100%)',
  },
  {
    Code: '43-5053.00',
    Occupation:
      'Postal Service Mail Sorters, Processors, and Processing Machine Operators',
    'Projected Growth (2021-2031)': 'Decline',
    'Projected Job Openings (2021-2031)': 8900,
    Industries: 'Government (100%)',
  },
  {
    Code: '11-9131.00',
    Occupation: 'Postmasters and Mail Superintendents',
    'Projected Growth (2021-2031)': 'Decline',
    'Projected Job Openings (2021-2031)': 900,
    Industries: 'Government (100%)',
  },
  {
    Code: '25-1199.00',
    Occupation: 'Postsecondary Teachers, All Other',
    'Projected Growth (2021-2031)': 'Average',
    'Projected Job Openings (2021-2031)': 23600,
    Industries: 'Educational Services (99%)',
  },
  {
    Code: '51-4052.00',
    Occupation: 'Pourers and Casters, Metal',
    'Projected Growth (2021-2031)': 'Decline',
    'Projected Job Openings (2021-2031)': 600,
    Industries: 'Manufacturing (98%)',
  },
  {
    Code: '51-8012.00',
    Occupation: 'Power Distributors and Dispatchers',
    'Projected Growth (2021-2031)': 'Decline',
    'Projected Job Openings (2021-2031)': 800,
    Industries:
      'Utilities (65%), Government (17%), Management of Companies and Enterprises (12%)',
  },
  {
    Code: '51-8013.00',
    Occupation: 'Power Plant Operators',
    'Projected Growth (2021-2031)': 'Decline',
    'Projected Job Openings (2021-2031)': 2100,
    Industries: 'Utilities (71%), Government (17%)',
  },
  {
    Code: '51-8013.03',
    Occupation: 'Biomass Plant Technicians',
    'Projected Growth (2021-2031)': 'Decline',
    'Projected Job Openings (2021-2031)': 2100,
    Industries: 'Utilities (71%), Government (17%)',
  },
  {
    Code: '51-8013.04',
    Occupation: 'Hydroelectric Plant Technicians',
    'Projected Growth (2021-2031)': 'Decline',
    'Projected Job Openings (2021-2031)': 2100,
    Industries: 'Utilities (71%), Government (17%)',
  },
  {
    Code: '49-9069.00',
    Occupation: 'Precision Instrument and Equipment Repairers, All Other',
    'Projected Growth (2021-2031)': 'Slower than average',
    'Projected Job Openings (2021-2031)': 1300,
    Industries:
      'Other Services (Except Public Administration) (33%), Government (19%), Wholesale Trade (17%)',
  },
  {
    Code: '51-5111.00',
    Occupation: 'Prepress Technicians and Workers',
    'Projected Growth (2021-2031)': 'Decline',
    'Projected Job Openings (2021-2031)': 2400,
    Industries: 'Manufacturing (75%)',
  },
  {
    Code: '25-2011.00',
    Occupation: 'Preschool Teachers, Except Special Education',
    'Projected Growth (2021-2031)': 'Much faster than average',
    'Projected Job Openings (2021-2031)': 63100,
    Industries:
      'Health Care and Social Assistance (66%), Educational Services (18%), Other Services (Except Public Administration) (12%)',
  },
  {
    Code: '51-6021.00',
    Occupation: 'Pressers, Textile, Garment, and Related Materials',
    'Projected Growth (2021-2031)': 'Decline',
    'Projected Job Openings (2021-2031)': 2900,
    Industries: 'Other Services (Except Public Administration) (91%)',
  },
  {
    Code: '51-5113.00',
    Occupation: 'Print Binding and Finishing Workers',
    'Projected Growth (2021-2031)': 'Decline',
    'Projected Job Openings (2021-2031)': 2800,
    Industries: 'Manufacturing (79%)',
  },
  {
    Code: '51-5112.00',
    Occupation: 'Printing Press Operators',
    'Projected Growth (2021-2031)': 'Decline',
    'Projected Job Openings (2021-2031)': 13200,
    Industries: 'Manufacturing (71%)',
  },
  {
    Code: '33-9021.00',
    Occupation: 'Private Detectives and Investigators',
    'Projected Growth (2021-2031)': 'Average',
    'Projected Job Openings (2021-2031)': 3700,
    Industries:
      'Administrative and Support Services (21%), Professional, Scientific, and Technical Services (12%), Finance and Insurance (12%), Government (10%), Retail Trade (10%)',
  },
  {
    Code: '21-1092.00',
    Occupation: 'Probation Officers and Correctional Treatment Specialists',
    'Projected Growth (2021-2031)': 'Little or no change',
    'Projected Job Openings (2021-2031)': 8000,
    Industries: 'Government (97%)',
  },
  {
    Code: '43-3061.00',
    Occupation: 'Procurement Clerks',
    'Projected Growth (2021-2031)': 'Decline',
    'Projected Job Openings (2021-2031)': 5800,
    Industries:
      'Government (23%), Manufacturing (15%), Wholesale Trade (14%), Health Care and Social Assistance (10%)',
  },
  {
    Code: '27-2012.00',
    Occupation: 'Producers and Directors',
    'Projected Growth (2021-2031)': 'Faster than average',
    'Projected Job Openings (2021-2031)': 17500,
    Industries: 'Information (60%), Arts, Entertainment, and Recreation (11%)',
  },
  {
    Code: '27-2012.03',
    Occupation: 'Media Programming Directors',
    'Projected Growth (2021-2031)': 'Faster than average',
    'Projected Job Openings (2021-2031)': 17500,
    Industries: 'Information (60%), Arts, Entertainment, and Recreation (11%)',
  },
  {
    Code: '27-2012.04',
    Occupation: 'Talent Directors',
    'Projected Growth (2021-2031)': 'Faster than average',
    'Projected Job Openings (2021-2031)': 17500,
    Industries: 'Information (60%), Arts, Entertainment, and Recreation (11%)',
  },
  {
    Code: '27-2012.05',
    Occupation: 'Media Technical Directors/Managers',
    'Projected Growth (2021-2031)': 'Faster than average',
    'Projected Job Openings (2021-2031)': 17500,
    Industries: 'Information (60%), Arts, Entertainment, and Recreation (11%)',
  },
  {
    Code: '51-9199.00',
    Occupation: 'Production Workers, All Other',
    'Projected Growth (2021-2031)': 'Average',
    'Projected Job Openings (2021-2031)': 28300,
    Industries:
      'Manufacturing (40%), Administrative and Support Services (35%)',
  },
  {
    Code: '43-5061.00',
    Occupation: 'Production, Planning, and Expediting Clerks',
    'Projected Growth (2021-2031)': 'Average',
    'Projected Job Openings (2021-2031)': 44800,
    Industries: 'Manufacturing (31%), Health Care and Social Assistance (10%)',
  },
  {
    Code: '13-1082.00',
    Occupation: 'Project Management Specialists',
    'Projected Growth (2021-2031)': 'Average',
    'Projected Job Openings (2021-2031)': 70400,
    Industries:
      'Professional, Scientific, and Technical Services (29%), Construction (19%)',
  },
  {
    Code: '43-9081.00',
    Occupation: 'Proofreaders and Copy Markers',
    'Projected Growth (2021-2031)': 'Slower than average',
    'Projected Job Openings (2021-2031)': 1500,
    Industries:
      'Information (14%), Professional, Scientific, and Technical Services (12%), Administrative and Support Services (10%)',
  },
  {
    Code: '',
    Occupation: 'Property Appraisers and Assessors',
    'Projected Growth (2021-2031)': 'Average',
    'Projected Job Openings (2021-2031)': 6800,
    Industries: 'Government (37%), Real Estate and Rental and Leasing (25%)',
  },
  {
    Code: '13-2022.00',
    Occupation: 'Appraisers of Personal and Business Property',
    'Projected Growth (2021-2031)': 'Average',
    'Projected Job Openings (2021-2031)': 6800,
    Industries: 'Government (37%), Real Estate and Rental and Leasing (25%)',
  },
  {
    Code: '13-2023.00',
    Occupation: 'Appraisers and Assessors of Real Estate',
    'Projected Growth (2021-2031)': 'Average',
    'Projected Job Openings (2021-2031)': 6800,
    Industries: 'Government (37%), Real Estate and Rental and Leasing (25%)',
  },
  {
    Code: '11-9141.00',
    Occupation: 'Property, Real Estate, and Community Association Managers',
    'Projected Growth (2021-2031)': 'Slower than average',
    'Projected Job Openings (2021-2031)': 33300,
    Industries: 'Real Estate and Rental and Leasing (50%)',
  },
  {
    Code: '29-1024.00',
    Occupation: 'Prosthodontists',
    'Projected Growth (2021-2031)': 'Average',
    'Projected Job Openings (2021-2031)': 0,
    Industries: 'Health Care and Social Assistance (85%)',
  },
  {
    Code: '33-9099.00',
    Occupation: 'Protective Service Workers, All Other',
    'Projected Growth (2021-2031)': 'Average',
    'Projected Job Openings (2021-2031)': 27900,
    Industries:
      'Educational Services (37%), Government (24%), Administrative and Support Services (12%)',
  },
  {
    Code: '33-9099.02',
    Occupation: 'Retail Loss Prevention Specialists',
    'Projected Growth (2021-2031)': 'Average',
    'Projected Job Openings (2021-2031)': 27900,
    Industries:
      'Educational Services (37%), Government (24%), Administrative and Support Services (12%)',
  },
  {
    Code: '31-1133.00',
    Occupation: 'Psychiatric Aides',
    'Projected Growth (2021-2031)': 'Average',
    'Projected Job Openings (2021-2031)': 6700,
    Industries: 'Health Care and Social Assistance (75%), Government (22%)',
  },
  {
    Code: '29-2053.00',
    Occupation: 'Psychiatric Technicians',
    'Projected Growth (2021-2031)': 'Much faster than average',
    'Projected Job Openings (2021-2031)': 9900,
    Industries: 'Health Care and Social Assistance (90%)',
  },
  {
    Code: '29-1223.00',
    Occupation: 'Psychiatrists',
    'Projected Growth (2021-2031)': 'Faster than average',
    'Projected Job Openings (2021-2031)': 1100,
    Industries: 'Health Care and Social Assistance (80%), Government (10%)',
  },
  {
    Code: '19-3039.00',
    Occupation: 'Psychologists, All Other',
    'Projected Growth (2021-2031)': 'Slower than average',
    'Projected Job Openings (2021-2031)': 4000,
    Industries: 'Government (17%)',
  },
  {
    Code: '19-3039.02',
    Occupation: 'Neuropsychologists',
    'Projected Growth (2021-2031)': 'Slower than average',
    'Projected Job Openings (2021-2031)': 4000,
    Industries: 'Government (17%)',
  },
  {
    Code: '19-3039.03',
    Occupation: 'Clinical Neuropsychologists',
    'Projected Growth (2021-2031)': 'Slower than average',
    'Projected Job Openings (2021-2031)': 4000,
    Industries: 'Government (17%)',
  },
  {
    Code: '25-1066.00',
    Occupation: 'Psychology Teachers, Postsecondary',
    'Projected Growth (2021-2031)': 'Faster than average',
    'Projected Job Openings (2021-2031)': 4500,
    Industries: 'Educational Services (100%)',
  },
  {
    Code: '11-2032.00',
    Occupation: 'Public Relations Managers',
    'Projected Growth (2021-2031)': 'Faster than average',
    'Projected Job Openings (2021-2031)': 6100,
    Industries:
      'Professional, Scientific, and Technical Services (22%), Other Services (Except Public Administration) (18%), Educational Services (14%)',
  },
  {
    Code: '27-3031.00',
    Occupation: 'Public Relations Specialists',
    'Projected Growth (2021-2031)': 'Faster than average',
    'Projected Job Openings (2021-2031)': 27400,
    Industries:
      'Other Services (Except Public Administration) (21%), Professional, Scientific, and Technical Services (21%), Educational Services (14%), Government (10%)',
  },
  {
    Code: '43-5031.00',
    Occupation: 'Public Safety Telecommunicators',
    'Projected Growth (2021-2031)': 'Average',
    'Projected Job Openings (2021-2031)': 10900,
    Industries: 'Government (87%)',
  },
  {
    Code: '53-7072.00',
    Occupation: 'Pump Operators, Except Wellhead Pumpers',
    'Projected Growth (2021-2031)': 'Much faster than average',
    'Projected Job Openings (2021-2031)': 1500,
    Industries:
      'Mining, Quarrying, and Oil and Gas Extraction (26%), Construction (19%), Transportation and Warehousing (11%), Wholesale Trade (11%)',
  },
  {
    Code: '11-3061.00',
    Occupation: 'Purchasing Managers',
    'Projected Growth (2021-2031)': 'Average',
    'Projected Job Openings (2021-2031)': 6300,
    Industries:
      'Manufacturing (26%), Management of Companies and Enterprises (19%), Government (12%), Wholesale Trade (11%)',
  },
  {
    Code: '29-1124.00',
    Occupation: 'Radiation Therapists',
    'Projected Growth (2021-2031)': 'Average',
    'Projected Job Openings (2021-2031)': 800,
    Industries: 'Health Care and Social Assistance (98%)',
  },
  {
    Code: '49-2021.00',
    Occupation: 'Radio, Cellular, and Tower Equipment Installers and Repairers',
    'Projected Growth (2021-2031)': 'Average',
    'Projected Job Openings (2021-2031)': 1700,
    Industries:
      'Information (33%), Construction (14%), Retail Trade (11%), Government (11%)',
  },
  {
    Code: '29-2034.00',
    Occupation: 'Radiologic Technologists and Technicians',
    'Projected Growth (2021-2031)': 'Average',
    'Projected Job Openings (2021-2031)': 13800,
    Industries: 'Health Care and Social Assistance (93%)',
  },
  {
    Code: '29-1224.00',
    Occupation: 'Radiologists',
    'Projected Growth (2021-2031)': 'Average',
    'Projected Job Openings (2021-2031)': 1100,
    Industries: 'Health Care and Social Assistance (89%)',
  },
  {
    Code: '49-3043.00',
    Occupation: 'Rail Car Repairers',
    'Projected Growth (2021-2031)': 'Average',
    'Projected Job Openings (2021-2031)': 2100,
    Industries: 'Transportation and Warehousing (76%), Government (16%)',
  },
  {
    Code: '53-4099.00',
    Occupation: 'Rail Transportation Workers, All Other',
    'Projected Growth (2021-2031)': 'Little or no change',
    'Projected Job Openings (2021-2031)': 100,
    Industries: 'Transportation and Warehousing (61%), Government (18%)',
  },
  {
    Code: '53-4013.00',
    Occupation: 'Rail Yard Engineers, Dinkey Operators, and Hostlers',
    'Projected Growth (2021-2031)': 'Slower than average',
    'Projected Job Openings (2021-2031)': 400,
    Industries: 'Transportation and Warehousing (86%)',
  },
  {
    Code: '47-4061.00',
    Occupation: 'Rail-Track Laying and Maintenance Equipment Operators',
    'Projected Growth (2021-2031)': 'Average',
    'Projected Job Openings (2021-2031)': 1400,
    Industries:
      'Transportation and Warehousing (51%), Government (33%), Construction (15%)',
  },
  {
    Code: '53-4022.00',
    Occupation:
      'Railroad Brake, Signal, and Switch Operators and Locomotive Firers',
    'Projected Growth (2021-2031)': 'Little or no change',
    'Projected Job Openings (2021-2031)': 1300,
    Industries: 'Transportation and Warehousing (92%)',
  },
  {
    Code: '53-4031.00',
    Occupation: 'Railroad Conductors and Yardmasters',
    'Projected Growth (2021-2031)': 'Average',
    'Projected Job Openings (2021-2031)': 3200,
    Industries: 'Transportation and Warehousing (84%), Government (16%)',
  },
  {
    Code: '41-9021.00',
    Occupation: 'Real Estate Brokers',
    'Projected Growth (2021-2031)': 'Average',
    'Projected Job Openings (2021-2031)': 12100,
    Industries: 'Real Estate and Rental and Leasing (38%)',
  },
  {
    Code: '41-9022.00',
    Occupation: 'Real Estate Sales Agents',
    'Projected Growth (2021-2031)': 'Average',
    'Projected Job Openings (2021-2031)': 42800,
    Industries: 'Real Estate and Rental and Leasing (34%)',
  },
  {
    Code: '43-4171.00',
    Occupation: 'Receptionists and Information Clerks',
    'Projected Growth (2021-2031)': 'Little or no change',
    'Projected Job Openings (2021-2031)': 142300,
    Industries:
      'Health Care and Social Assistance (46%), Professional, Scientific, and Technical Services (12%), Other Services (Except Public Administration) (12%)',
  },
  {
    Code: '25-1193.00',
    Occupation: 'Recreation and Fitness Studies Teachers, Postsecondary',
    'Projected Growth (2021-2031)': 'Average',
    'Projected Job Openings (2021-2031)': 1600,
    Industries: 'Educational Services (99%)',
  },
  {
    Code: '39-9032.00',
    Occupation: 'Recreation Workers',
    'Projected Growth (2021-2031)': 'Faster than average',
    'Projected Job Openings (2021-2031)': 61700,
    Industries:
      'Government (32%), Health Care and Social Assistance (31%), Arts, Entertainment, and Recreation (12%), Other Services (Except Public Administration) (11%)',
  },
  {
    Code: '29-1125.00',
    Occupation: 'Recreational Therapists',
    'Projected Growth (2021-2031)': 'Average',
    'Projected Job Openings (2021-2031)': 1500,
    Industries: 'Health Care and Social Assistance (79%), Government (18%)',
  },
  {
    Code: '49-3092.00',
    Occupation: 'Recreational Vehicle Service Technicians',
    'Projected Growth (2021-2031)': 'Much faster than average',
    'Projected Job Openings (2021-2031)': 2200,
    Industries:
      'Retail Trade (77%), Other Services (Except Public Administration) (10%)',
  },
  {
    Code: '49-9045.00',
    Occupation: 'Refractory Materials Repairers, Except Brickmasons',
    'Projected Growth (2021-2031)': 'Decline',
    'Projected Job Openings (2021-2031)': 0,
    Industries: 'Manufacturing (51%), Construction (43%)',
  },
  {
    Code: '53-7081.00',
    Occupation: 'Refuse and Recyclable Material Collectors',
    'Projected Growth (2021-2031)': 'Average',
    'Projected Job Openings (2021-2031)': 20600,
    Industries: 'Administrative and Support Services (61%), Government (30%)',
  },
  {
    Code: '29-1141.00',
    Occupation: 'Registered Nurses',
    'Projected Growth (2021-2031)': 'Average',
    'Projected Job Openings (2021-2031)': 203200,
    Industries: 'Health Care and Social Assistance (85%)',
  },
  {
    Code: '29-1141.01',
    Occupation: 'Acute Care Nurses',
    'Projected Growth (2021-2031)': 'Average',
    'Projected Job Openings (2021-2031)': 203200,
    Industries: 'Health Care and Social Assistance (85%)',
  },
  {
    Code: '29-1141.02',
    Occupation: 'Advanced Practice Psychiatric Nurses',
    'Projected Growth (2021-2031)': 'Average',
    'Projected Job Openings (2021-2031)': 203200,
    Industries: 'Health Care and Social Assistance (85%)',
  },
  {
    Code: '29-1141.03',
    Occupation: 'Critical Care Nurses',
    'Projected Growth (2021-2031)': 'Average',
    'Projected Job Openings (2021-2031)': 203200,
    Industries: 'Health Care and Social Assistance (85%)',
  },
  {
    Code: '29-1141.04',
    Occupation: 'Clinical Nurse Specialists',
    'Projected Growth (2021-2031)': 'Average',
    'Projected Job Openings (2021-2031)': 203200,
    Industries: 'Health Care and Social Assistance (85%)',
  },
  {
    Code: '21-1015.00',
    Occupation: 'Rehabilitation Counselors',
    'Projected Growth (2021-2031)': 'Much faster than average',
    'Projected Job Openings (2021-2031)': 9900,
    Industries: 'Health Care and Social Assistance (72%), Government (21%)',
  },
  {
    Code: '47-2171.00',
    Occupation: 'Reinforcing Iron and Rebar Workers',
    'Projected Growth (2021-2031)': 'Slower than average',
    'Projected Job Openings (2021-2031)': 1900,
    Industries: 'Construction (76%)',
  },
  {
    Code: '21-2099.00',
    Occupation: 'Religious Workers, All Other',
    'Projected Growth (2021-2031)': 'Average',
    'Projected Job Openings (2021-2031)': 10000,
    Industries: 'Other Services (Except Public Administration) (93%)',
  },
  {
    Code: '43-4181.00',
    Occupation:
      'Reservation and Transportation Ticket Agents and Travel Clerks',
    'Projected Growth (2021-2031)': 'Average',
    'Projected Job Openings (2021-2031)': 14300,
    Industries:
      'Transportation and Warehousing (77%), Administrative and Support Services (14%)',
  },
  {
    Code: '39-9041.00',
    Occupation: 'Residential Advisors',
    'Projected Growth (2021-2031)': 'Faster than average',
    'Projected Job Openings (2021-2031)': 18900,
    Industries:
      'Health Care and Social Assistance (69%), Educational Services (21%)',
  },
  {
    Code: '29-1126.00',
    Occupation: 'Respiratory Therapists',
    'Projected Growth (2021-2031)': 'Much faster than average',
    'Projected Job Openings (2021-2031)': 9400,
    Industries: 'Health Care and Social Assistance (92%)',
  },
  {
    Code: '41-2031.00',
    Occupation: 'Retail Salespersons',
    'Projected Growth (2021-2031)': 'Little or no change',
    'Projected Job Openings (2021-2031)': 558800,
    Industries: 'Retail Trade (88%)',
  },
  {
    Code: '49-9096.00',
    Occupation: 'Riggers',
    'Projected Growth (2021-2031)': 'Average',
    'Projected Job Openings (2021-2031)': 2200,
    Industries: 'Construction (31%), Manufacturing (21%), Government (10%)',
  },
  {
    Code: '47-5051.00',
    Occupation: 'Rock Splitters, Quarry',
    'Projected Growth (2021-2031)': 'Average',
    'Projected Job Openings (2021-2031)': 600,
    Industries:
      'Mining, Quarrying, and Oil and Gas Extraction (61%), Manufacturing (32%)',
  },
  {
    Code: '51-4023.00',
    Occupation:
      'Rolling Machine Setters, Operators, and Tenders, Metal and Plastic',
    'Projected Growth (2021-2031)': 'Decline',
    'Projected Job Openings (2021-2031)': 2900,
    Industries: 'Manufacturing (94%)',
  },
  {
    Code: '47-5043.00',
    Occupation: 'Roof Bolters, Mining',
    'Projected Growth (2021-2031)': 'Decline',
    'Projected Job Openings (2021-2031)': 200,
    Industries: 'Mining, Quarrying, and Oil and Gas Extraction (92%)',
  },
  {
    Code: '47-2181.00',
    Occupation: 'Roofers',
    'Projected Growth (2021-2031)': 'Little or no change',
    'Projected Job Openings (2021-2031)': 15000,
    Industries: 'Construction (80%)',
  },
  {
    Code: '47-5012.00',
    Occupation: 'Rotary Drill Operators, Oil and Gas',
    'Projected Growth (2021-2031)': 'Much faster than average',
    'Projected Job Openings (2021-2031)': 1700,
    Industries: 'Mining, Quarrying, and Oil and Gas Extraction (87%)',
  },
  {
    Code: '47-5071.00',
    Occupation: 'Roustabouts, Oil and Gas',
    'Projected Growth (2021-2031)': 'Much faster than average',
    'Projected Job Openings (2021-2031)': 5600,
    Industries: 'Mining, Quarrying, and Oil and Gas Extraction (81%)',
  },
  {
    Code: '53-5011.00',
    Occupation: 'Sailors and Marine Oilers',
    'Projected Growth (2021-2031)': 'Little or no change',
    'Projected Job Openings (2021-2031)': 3300,
    Industries: 'Transportation and Warehousing (67%), Government (16%)',
  },
  {
    Code: '41-9099.00',
    Occupation: 'Sales and Related Workers, All Other',
    'Projected Growth (2021-2031)': 'Slower than average',
    'Projected Job Openings (2021-2031)': 36600,
    Industries: 'Retail Trade (64%)',
  },
  {
    Code: '41-9031.00',
    Occupation: 'Sales Engineers',
    'Projected Growth (2021-2031)': 'Average',
    'Projected Job Openings (2021-2031)': 6900,
    Industries:
      'Professional, Scientific, and Technical Services (27%), Wholesale Trade (26%), Manufacturing (23%), Information (13%)',
  },
  {
    Code: '11-2022.00',
    Occupation: 'Sales Managers',
    'Projected Growth (2021-2031)': 'Average',
    'Projected Job Openings (2021-2031)': 41900,
    Industries:
      'Wholesale Trade (20%), Retail Trade (17%), Professional, Scientific, and Technical Services (13%), Finance and Insurance (11%), Manufacturing (11%)',
  },
  {
    Code: '41-3091.00',
    Occupation:
      'Sales Representatives of Services, Except Advertising, Insurance, Financial Services, and Travel',
    'Projected Growth (2021-2031)': 'Average',
    'Projected Job Openings (2021-2031)': 125300,
    Industries:
      'Professional, Scientific, and Technical Services (22%), Information (14%), Administrative and Support Services (14%), Retail Trade (12%)',
  },
  {
    Code: '41-4012.00',
    Occupation:
      'Sales Representatives, Wholesale and Manufacturing, Except Technical and Scientific Products',
    'Projected Growth (2021-2031)': 'Average',
    'Projected Job Openings (2021-2031)': 139700,
    Industries: 'Wholesale Trade (62%), Manufacturing (18%)',
  },
  {
    Code: '41-4011.00',
    Occupation:
      'Sales Representatives, Wholesale and Manufacturing, Technical and Scientific Products',
    'Projected Growth (2021-2031)': 'Average',
    'Projected Job Openings (2021-2031)': 30400,
    Industries:
      'Wholesale Trade (54%), Manufacturing (14%), Professional, Scientific, and Technical Services (12%), Information (10%)',
  },
  {
    Code: '41-4011.07',
    Occupation: 'Solar Sales Representatives and Assessors',
    'Projected Growth (2021-2031)': 'Average',
    'Projected Job Openings (2021-2031)': 30400,
    Industries:
      'Wholesale Trade (54%), Manufacturing (14%), Professional, Scientific, and Technical Services (12%), Information (10%)',
  },
  {
    Code: '51-7041.00',
    Occupation: 'Sawing Machine Setters, Operators, and Tenders, Wood',
    'Projected Growth (2021-2031)': 'Average',
    'Projected Job Openings (2021-2031)': 5700,
    Industries: 'Manufacturing (84%)',
  },
  {
    Code: '33-9094.00',
    Occupation: 'School Bus Monitors',
    'Projected Growth (2021-2031)': 'Average',
    'Projected Job Openings (2021-2031)': 11600,
    Industries:
      'Educational Services (54%), Transportation and Warehousing (34%), Government (10%)',
  },
  {
    Code: '19-3034.00',
    Occupation: 'School Psychologists',
    'Projected Growth (2021-2031)': 'Average',
    'Projected Job Openings (2021-2031)': 4800,
    Industries: 'Educational Services (91%)',
  },
  {
    Code: '25-2031.00',
    Occupation:
      'Secondary School Teachers, Except Special and Career/Technical Education',
    'Projected Growth (2021-2031)': 'Average',
    'Projected Job Openings (2021-2031)': 77900,
    Industries: 'Educational Services (99%)',
  },
  {
    Code: '43-6014.00',
    Occupation:
      'Secretaries and Administrative Assistants, Except Legal, Medical, and Executive',
    'Projected Growth (2021-2031)': 'Decline',
    'Projected Job Openings (2021-2031)': 211200,
    Industries:
      'Educational Services (20%), Health Care and Social Assistance (11%), Other Services (Except Public Administration) (10%)',
  },
  {
    Code: '41-3031.00',
    Occupation: 'Securities, Commodities, and Financial Services Sales Agents',
    'Projected Growth (2021-2031)': 'Faster than average',
    'Projected Job Openings (2021-2031)': 46600,
    Industries: 'Finance and Insurance (89%)',
  },
  {
    Code: '49-2098.00',
    Occupation: 'Security and Fire Alarm Systems Installers',
    'Projected Growth (2021-2031)': 'Average',
    'Projected Job Openings (2021-2031)': 9400,
    Industries: 'Administrative and Support Services (60%), Construction (25%)',
  },
  {
    Code: '33-9032.00',
    Occupation: 'Security Guards',
    'Projected Growth (2021-2031)': 'Slower than average',
    'Projected Job Openings (2021-2031)': 154500,
    Industries: 'Administrative and Support Services (63%)',
  },
  {
    Code: '25-3021.00',
    Occupation: 'Self-Enrichment Teachers',
    'Projected Growth (2021-2031)': 'Much faster than average',
    'Projected Job Openings (2021-2031)': 49000,
    Industries:
      'Educational Services (44%), Other Services (Except Public Administration) (17%)',
  },
  {
    Code: '51-9141.00',
    Occupation: 'Semiconductor Processing Technicians',
    'Projected Growth (2021-2031)': 'Slower than average',
    'Projected Job Openings (2021-2031)': 3200,
    Industries: 'Manufacturing (89%)',
  },
  {
    Code: '51-9012.00',
    Occupation:
      'Separating, Filtering, Clarifying, Precipitating, and Still Machine Setters, Operators, and Tenders',
    'Projected Growth (2021-2031)': 'Little or no change',
    'Projected Job Openings (2021-2031)': 5400,
    Industries: 'Manufacturing (78%)',
  },
  {
    Code: '47-4071.00',
    Occupation: 'Septic Tank Servicers and Sewer Pipe Cleaners',
    'Projected Growth (2021-2031)': 'Average',
    'Projected Job Openings (2021-2031)': 3600,
    Industries:
      'Administrative and Support Services (54%), Government (28%), Construction (12%)',
  },
  {
    Code: '47-5013.00',
    Occupation: 'Service Unit Operators, Oil and Gas',
    'Projected Growth (2021-2031)': 'Much faster than average',
    'Projected Job Openings (2021-2031)': 5100,
    Industries: 'Mining, Quarrying, and Oil and Gas Extraction (86%)',
  },
  {
    Code: '27-1027.00',
    Occupation: 'Set and Exhibit Designers',
    'Projected Growth (2021-2031)': 'Average',
    'Projected Job Openings (2021-2031)': 2700,
    Industries: 'Information (10%)',
  },
  {
    Code: '51-6051.00',
    Occupation: 'Sewers, Hand',
    'Projected Growth (2021-2031)': 'Little or no change',
    'Projected Job Openings (2021-2031)': 1200,
    Industries: 'Manufacturing (22%), Retail Trade (12%)',
  },
  {
    Code: '51-6031.00',
    Occupation: 'Sewing Machine Operators',
    'Projected Growth (2021-2031)': 'Decline',
    'Projected Job Openings (2021-2031)': 14000,
    Industries: 'Manufacturing (74%)',
  },
  {
    Code: '39-5093.00',
    Occupation: 'Shampooers',
    'Projected Growth (2021-2031)': 'Much faster than average',
    'Projected Job Openings (2021-2031)': 3500,
    Industries: 'Other Services (Except Public Administration) (46%)',
  },
  {
    Code: '47-2211.00',
    Occupation: 'Sheet Metal Workers',
    'Projected Growth (2021-2031)': 'Little or no change',
    'Projected Job Openings (2021-2031)': 12300,
    Industries: 'Construction (62%), Manufacturing (23%)',
  },
  {
    Code: '53-5031.00',
    Occupation: 'Ship Engineers',
    'Projected Growth (2021-2031)': 'Little or no change',
    'Projected Job Openings (2021-2031)': 900,
    Industries: 'Transportation and Warehousing (66%), Government (23%)',
  },
  {
    Code: '43-5071.00',
    Occupation: 'Shipping, Receiving, and Inventory Clerks',
    'Projected Growth (2021-2031)': 'Decline',
    'Projected Job Openings (2021-2031)': 80700,
    Industries:
      'Manufacturing (27%), Retail Trade (21%), Wholesale Trade (17%), Transportation and Warehousing (16%)',
  },
  {
    Code: '51-6041.00',
    Occupation: 'Shoe and Leather Workers and Repairers',
    'Projected Growth (2021-2031)': 'Decline',
    'Projected Job Openings (2021-2031)': 900,
    Industries: 'Manufacturing (54%), Retail Trade (13%)',
  },
  {
    Code: '51-6042.00',
    Occupation: 'Shoe Machine Operators and Tenders',
    'Projected Growth (2021-2031)': 'Decline',
    'Projected Job Openings (2021-2031)': 500,
    Industries: 'Manufacturing (73%)',
  },
  {
    Code: '53-3053.00',
    Occupation: 'Shuttle Drivers and Chauffeurs',
    'Projected Growth (2021-2031)': 'Much faster than average',
    'Projected Job Openings (2021-2031)': 29000,
    Industries:
      'Transportation and Warehousing (39%), Health Care and Social Assistance (24%)',
  },
  {
    Code: '49-9097.00',
    Occupation: 'Signal and Track Switch Repairers',
    'Projected Growth (2021-2031)': 'Average',
    'Projected Job Openings (2021-2031)': 700,
    Industries: 'Transportation and Warehousing (60%), Government (31%)',
  },
  {
    Code: '39-5094.00',
    Occupation: 'Skincare Specialists',
    'Projected Growth (2021-2031)': 'Much faster than average',
    'Projected Job Openings (2021-2031)': 13500,
    Industries:
      'Other Services (Except Public Administration) (43%), Health Care and Social Assistance (10%)',
  },
  {
    Code: '51-3023.00',
    Occupation: 'Slaughterers and Meat Packers',
    'Projected Growth (2021-2031)': 'Decline',
    'Projected Job Openings (2021-2031)': 10800,
    Industries: 'Manufacturing (91%)',
  },
  {
    Code: '11-9151.00',
    Occupation: 'Social and Community Service Managers',
    'Projected Growth (2021-2031)': 'Much faster than average',
    'Projected Job Openings (2021-2031)': 18000,
    Industries:
      'Health Care and Social Assistance (56%), Government (19%), Other Services (Except Public Administration) (11%)',
  },
  {
    Code: '21-1093.00',
    Occupation: 'Social and Human Service Assistants',
    'Projected Growth (2021-2031)': 'Much faster than average',
    'Projected Job Openings (2021-2031)': 55900,
    Industries: 'Health Care and Social Assistance (62%), Government (21%)',
  },
  {
    Code: '19-4061.00',
    Occupation: 'Social Science Research Assistants',
    'Projected Growth (2021-2031)': 'Much faster than average',
    'Projected Job Openings (2021-2031)': 5000,
    Industries:
      'Educational Services (58%), Professional, Scientific, and Technical Services (27%)',
  },
  {
    Code: '25-1069.00',
    Occupation: 'Social Sciences Teachers, Postsecondary, All Other',
    'Projected Growth (2021-2031)': 'Average',
    'Projected Job Openings (2021-2031)': 1800,
    Industries: 'Educational Services (100%)',
  },
  {
    Code: '19-3099.00',
    Occupation: 'Social Scientists and Related Workers, All Other',
    'Projected Growth (2021-2031)': 'Slower than average',
    'Projected Job Openings (2021-2031)': 3700,
    Industries:
      'Government (61%), Professional, Scientific, and Technical Services (16%), Educational Services (13%)',
  },
  {
    Code: '19-3099.01',
    Occupation: 'Transportation Planners',
    'Projected Growth (2021-2031)': 'Slower than average',
    'Projected Job Openings (2021-2031)': 3700,
    Industries:
      'Government (61%), Professional, Scientific, and Technical Services (16%), Educational Services (13%)',
  },
  {
    Code: '25-1113.00',
    Occupation: 'Social Work Teachers, Postsecondary',
    'Projected Growth (2021-2031)': 'Faster than average',
    'Projected Job Openings (2021-2031)': 1500,
    Industries: 'Educational Services (100%)',
  },
  {
    Code: '21-1029.00',
    Occupation: 'Social Workers, All Other',
    'Projected Growth (2021-2031)': 'Slower than average',
    'Projected Job Openings (2021-2031)': 5700,
    Industries: 'Government (61%), Health Care and Social Assistance (19%)',
  },
  {
    Code: '19-3041.00',
    Occupation: 'Sociologists',
    'Projected Growth (2021-2031)': 'Average',
    'Projected Job Openings (2021-2031)': 300,
    Industries:
      'Professional, Scientific, and Technical Services (44%), Government (25%), Educational Services (14%)',
  },
  {
    Code: '25-1067.00',
    Occupation: 'Sociology Teachers, Postsecondary',
    'Projected Growth (2021-2031)': 'Faster than average',
    'Projected Job Openings (2021-2031)': 1500,
    Industries: 'Educational Services (100%)',
  },
  {
    Code: '15-1252.00',
    Occupation: 'Software Developers',
    'Projected Growth (2021-2031)': 'Much faster than average',
    'Projected Job Openings (2021-2031)': 143400,
    Industries:
      'Professional, Scientific, and Technical Services (42%), Information (19%), Finance and Insurance (11%)',
  },
  {
    Code: '15-1253.00',
    Occupation: 'Software Quality Assurance Analysts and Testers',
    'Projected Growth (2021-2031)': 'Much faster than average',
    'Projected Job Openings (2021-2031)': 19500,
    Industries:
      'Professional, Scientific, and Technical Services (42%), Information (19%), Finance and Insurance (11%)',
  },
  {
    Code: '19-1013.00',
    Occupation: 'Soil and Plant Scientists',
    'Projected Growth (2021-2031)': 'Faster than average',
    'Projected Job Openings (2021-2031)': 2100,
    Industries:
      'Professional, Scientific, and Technical Services (34%), Educational Services (16%), Government (15%), Agriculture, Forestry, Fishing, and Hunting (13%), Wholesale Trade (10%)',
  },
  {
    Code: '47-2231.00',
    Occupation: 'Solar Photovoltaic Installers',
    'Projected Growth (2021-2031)': 'Much faster than average',
    'Projected Job Openings (2021-2031)': 2500,
    Industries: 'Construction (75%)',
  },
  {
    Code: '27-4014.00',
    Occupation: 'Sound Engineering Technicians',
    'Projected Growth (2021-2031)': 'Faster than average',
    'Projected Job Openings (2021-2031)': 1600,
    Industries: 'Information (48%)',
  },
  {
    Code: '25-2059.00',
    Occupation: 'Special Education Teachers, All Other',
    'Projected Growth (2021-2031)': 'Average',
    'Projected Job Openings (2021-2031)': 3200,
    Industries: 'Educational Services (80%)',
  },
  {
    Code: '25-2059.01',
    Occupation: 'Adapted Physical Education Specialists',
    'Projected Growth (2021-2031)': 'Average',
    'Projected Job Openings (2021-2031)': 3200,
    Industries: 'Educational Services (80%)',
  },
  {
    Code: '',
    Occupation:
      'Special Education Teachers, Kindergarten and Elementary School',
    'Projected Growth (2021-2031)': 'Average',
    'Projected Job Openings (2021-2031)': 14700,
    Industries: 'Educational Services (98%)',
  },
  {
    Code: '25-2055.00',
    Occupation: 'Special Education Teachers, Kindergarten',
    'Projected Growth (2021-2031)': 'Average',
    'Projected Job Openings (2021-2031)': 14700,
    Industries: 'Educational Services (98%)',
  },
  {
    Code: '25-2056.00',
    Occupation: 'Special Education Teachers, Elementary School',
    'Projected Growth (2021-2031)': 'Average',
    'Projected Job Openings (2021-2031)': 14700,
    Industries: 'Educational Services (98%)',
  },
  {
    Code: '25-2057.00',
    Occupation: 'Special Education Teachers, Middle School',
    'Projected Growth (2021-2031)': 'Average',
    'Projected Job Openings (2021-2031)': 6200,
    Industries: 'Educational Services (100%)',
  },
  {
    Code: '25-2051.00',
    Occupation: 'Special Education Teachers, Preschool',
    'Projected Growth (2021-2031)': 'Faster than average',
    'Projected Job Openings (2021-2031)': 1900,
    Industries:
      'Educational Services (66%), Health Care and Social Assistance (29%)',
  },
  {
    Code: '25-2058.00',
    Occupation: 'Special Education Teachers, Secondary School',
    'Projected Growth (2021-2031)': 'Average',
    'Projected Job Openings (2021-2031)': 11600,
    Industries: 'Educational Services (98%)',
  },
  {
    Code: '27-1014.00',
    Occupation: 'Special Effects Artists and Animators',
    'Projected Growth (2021-2031)': 'Average',
    'Projected Job Openings (2021-2031)': 6700,
    Industries:
      'Information (20%), Professional, Scientific, and Technical Services (11%)',
  },
  {
    Code: '29-1127.00',
    Occupation: 'Speech-Language Pathologists',
    'Projected Growth (2021-2031)': 'Much faster than average',
    'Projected Job Openings (2021-2031)': 14000,
    Industries:
      'Health Care and Social Assistance (50%), Educational Services (41%)',
  },
  {
    Code: '51-8021.00',
    Occupation: 'Stationary Engineers and Boiler Operators',
    'Projected Growth (2021-2031)': 'Average',
    'Projected Job Openings (2021-2031)': 4200,
    Industries:
      'Government (20%), Educational Services (19%), Health Care and Social Assistance (17%), Manufacturing (17%)',
  },
  {
    Code: '43-9111.00',
    Occupation: 'Statistical Assistants',
    'Projected Growth (2021-2031)': 'Slower than average',
    'Projected Job Openings (2021-2031)': 900,
    Industries:
      'Educational Services (16%), Finance and Insurance (16%), Professional, Scientific, and Technical Services (15%), Government (15%)',
  },
  {
    Code: '15-2041.00',
    Occupation: 'Statisticians',
    'Projected Growth (2021-2031)': 'Much faster than average',
    'Projected Job Openings (2021-2031)': 3900,
    Industries:
      'Professional, Scientific, and Technical Services (33%), Government (23%), Educational Services (11%)',
  },
  {
    Code: '15-2041.01',
    Occupation: 'Biostatisticians',
    'Projected Growth (2021-2031)': 'Much faster than average',
    'Projected Job Openings (2021-2031)': 3900,
    Industries:
      'Professional, Scientific, and Technical Services (33%), Government (23%), Educational Services (11%)',
  },
  {
    Code: '53-7065.00',
    Occupation: 'Stockers and Order Fillers',
    'Projected Growth (2021-2031)': 'Average',
    'Projected Job Openings (2021-2031)': 450400,
    Industries:
      'Retail Trade (63%), Transportation and Warehousing (14%), Wholesale Trade (11%)',
  },
  {
    Code: '47-2022.00',
    Occupation: 'Stonemasons',
    'Projected Growth (2021-2031)': 'Decline',
    'Projected Job Openings (2021-2031)': 900,
    Industries: 'Construction (69%)',
  },
  {
    Code: '47-2221.00',
    Occupation: 'Structural Iron and Steel Workers',
    'Projected Growth (2021-2031)': 'Average',
    'Projected Job Openings (2021-2031)': 7500,
    Industries: 'Construction (84%)',
  },
  {
    Code: '51-2041.00',
    Occupation: 'Structural Metal Fabricators and Fitters',
    'Projected Growth (2021-2031)': 'Decline',
    'Projected Job Openings (2021-2031)': 4700,
    Industries: 'Manufacturing (80%)',
  },
  {
    Code: '',
    Occupation:
      'Substance Abuse, Behavioral Disorder, and Mental Health Counselors',
    'Projected Growth (2021-2031)': 'Much faster than average',
    'Projected Job Openings (2021-2031)': 43600,
    Industries: 'Health Care and Social Assistance (74%)',
  },
  {
    Code: '21-1011.00',
    Occupation: 'Substance Abuse and Behavioral Disorder Counselors',
    'Projected Growth (2021-2031)': 'Much faster than average',
    'Projected Job Openings (2021-2031)': 43600,
    Industries: 'Health Care and Social Assistance (74%)',
  },
  {
    Code: '21-1014.00',
    Occupation: 'Mental Health Counselors',
    'Projected Growth (2021-2031)': 'Much faster than average',
    'Projected Job Openings (2021-2031)': 43600,
    Industries: 'Health Care and Social Assistance (74%)',
  },
  {
    Code: '25-3031.00',
    Occupation: 'Substitute Teachers, Short-Term',
    'Projected Growth (2021-2031)': 'Faster than average',
    'Projected Job Openings (2021-2031)': 50600,
    Industries: 'Educational Services (88%)',
  },
  {
    Code: '53-4041.00',
    Occupation: 'Subway and Streetcar Operators',
    'Projected Growth (2021-2031)': 'Average',
    'Projected Job Openings (2021-2031)': 1200,
    Industries: 'Government (93%)',
  },
  {
    Code: '29-1249.00',
    Occupation: 'Surgeons, All Other',
    'Projected Growth (2021-2031)': 'Slower than average',
    'Projected Job Openings (2021-2031)': 900,
    Industries: 'Health Care and Social Assistance (95%)',
  },
  {
    Code: '29-9093.00',
    Occupation: 'Surgical Assistants',
    'Projected Growth (2021-2031)': 'Average',
    'Projected Job Openings (2021-2031)': 1300,
    Industries: 'Health Care and Social Assistance (93%)',
  },
  {
    Code: '29-2055.00',
    Occupation: 'Surgical Technologists',
    'Projected Growth (2021-2031)': 'Average',
    'Projected Job Openings (2021-2031)': 8300,
    Industries: 'Health Care and Social Assistance (97%)',
  },
  {
    Code: '19-3022.00',
    Occupation: 'Survey Researchers',
    'Projected Growth (2021-2031)': 'Average',
    'Projected Job Openings (2021-2031)': 1000,
    Industries:
      'Professional, Scientific, and Technical Services (60%), Educational Services (17%)',
  },
  {
    Code: '17-3031.00',
    Occupation: 'Surveying and Mapping Technicians',
    'Projected Growth (2021-2031)': 'Average',
    'Projected Job Openings (2021-2031)': 7800,
    Industries:
      'Professional, Scientific, and Technical Services (66%), Government (13%)',
  },
  {
    Code: '17-1022.00',
    Occupation: 'Surveyors',
    'Projected Growth (2021-2031)': 'Little or no change',
    'Projected Job Openings (2021-2031)': 3800,
    Industries: 'Professional, Scientific, and Technical Services (73%)',
  },
  {
    Code: '17-1022.01',
    Occupation: 'Geodetic Surveyors',
    'Projected Growth (2021-2031)': 'Little or no change',
    'Projected Job Openings (2021-2031)': 3800,
    Industries: 'Professional, Scientific, and Technical Services (73%)',
  },
  {
    Code: '43-2011.00',
    Occupation: 'Switchboard Operators, Including Answering Service',
    'Projected Growth (2021-2031)': 'Decline',
    'Projected Job Openings (2021-2031)': 4000,
    Industries:
      'Health Care and Social Assistance (43%), Administrative and Support Services (25%)',
  },
  {
    Code: '51-6052.00',
    Occupation: 'Tailors, Dressmakers, and Custom Sewers',
    'Projected Growth (2021-2031)': 'Little or no change',
    'Projected Job Openings (2021-2031)': 5000,
    Industries:
      'Retail Trade (25%), Other Services (Except Public Administration) (13%)',
  },
  {
    Code: '53-7121.00',
    Occupation: 'Tank Car, Truck, and Ship Loaders',
    'Projected Growth (2021-2031)': 'Decline',
    'Projected Job Openings (2021-2031)': 1700,
    Industries:
      'Transportation and Warehousing (47%), Manufacturing (18%), Wholesale Trade (17%)',
  },
  {
    Code: '47-2082.00',
    Occupation: 'Tapers',
    'Projected Growth (2021-2031)': 'Little or no change',
    'Projected Job Openings (2021-2031)': 1300,
    Industries: 'Construction (87%)',
  },
  {
    Code: '13-2081.00',
    Occupation: 'Tax Examiners and Collectors, and Revenue Agents',
    'Projected Growth (2021-2031)': 'Decline',
    'Projected Job Openings (2021-2031)': 4300,
    Industries: 'Government (99%)',
  },
  {
    Code: '13-2082.00',
    Occupation: 'Tax Preparers',
    'Projected Growth (2021-2031)': 'Average',
    'Projected Job Openings (2021-2031)': 13000,
    Industries: 'Professional, Scientific, and Technical Services (79%)',
  },
  {
    Code: '25-3099.00',
    Occupation: 'Teachers and Instructors, All Other',
    'Projected Growth (2021-2031)': 'Faster than average',
    'Projected Job Openings (2021-2031)': 26600,
    Industries: 'Educational Services (65%), Government (12%)',
  },
  {
    Code: '',
    Occupation: 'Teaching Assistants, Except Postsecondary',
    'Projected Growth (2021-2031)': 'Average',
    'Projected Job Openings (2021-2031)': 153700,
    Industries:
      'Educational Services (85%), Health Care and Social Assistance (11%)',
  },
  {
    Code: '25-9042.00',
    Occupation:
      'Teaching Assistants, Preschool, Elementary, Middle, and Secondary School, Except Special Education',
    'Projected Growth (2021-2031)': 'Average',
    'Projected Job Openings (2021-2031)': 153700,
    Industries:
      'Educational Services (85%), Health Care and Social Assistance (11%)',
  },
  {
    Code: '25-9043.00',
    Occupation: 'Teaching Assistants, Special Education',
    'Projected Growth (2021-2031)': 'Average',
    'Projected Job Openings (2021-2031)': 153700,
    Industries:
      'Educational Services (85%), Health Care and Social Assistance (11%)',
  },
  {
    Code: '25-9049.00',
    Occupation: 'Teaching Assistants, All Other',
    'Projected Growth (2021-2031)': 'Average',
    'Projected Job Openings (2021-2031)': 153700,
    Industries:
      'Educational Services (85%), Health Care and Social Assistance (11%)',
  },
  {
    Code: '25-9044.00',
    Occupation: 'Teaching Assistants, Postsecondary',
    'Projected Growth (2021-2031)': 'Faster than average',
    'Projected Job Openings (2021-2031)': 20100,
    Industries: 'Educational Services (99%)',
  },
  {
    Code: '27-3042.00',
    Occupation: 'Technical Writers',
    'Projected Growth (2021-2031)': 'Average',
    'Projected Job Openings (2021-2031)': 5400,
    Industries:
      'Professional, Scientific, and Technical Services (36%), Manufacturing (13%), Information (10%)',
  },
  {
    Code: '49-2022.00',
    Occupation:
      'Telecommunications Equipment Installers and Repairers, Except Line Installers',
    'Projected Growth (2021-2031)': 'Faster than average',
    'Projected Job Openings (2021-2031)': 22500,
    Industries: 'Information (68%), Construction (14%)',
  },
  {
    Code: '49-9052.00',
    Occupation: 'Telecommunications Line Installers and Repairers',
    'Projected Growth (2021-2031)': 'Faster than average',
    'Projected Job Openings (2021-2031)': 12400,
    Industries: 'Information (61%), Construction (30%)',
  },
  {
    Code: '41-9041.00',
    Occupation: 'Telemarketers',
    'Projected Growth (2021-2031)': 'Decline',
    'Projected Job Openings (2021-2031)': 13300,
    Industries: 'Administrative and Support Services (69%)',
  },
  {
    Code: '43-2021.00',
    Occupation: 'Telephone Operators',
    'Projected Growth (2021-2031)': 'Decline',
    'Projected Job Openings (2021-2031)': 400,
    Industries:
      'Health Care and Social Assistance (47%), Information (14%), Accommodation and Food Services (12%)',
  },
  {
    Code: '43-3071.00',
    Occupation: 'Tellers',
    'Projected Growth (2021-2031)': 'Decline',
    'Projected Job Openings (2021-2031)': 35100,
    Industries: 'Finance and Insurance (98%)',
  },
  {
    Code: '47-2053.00',
    Occupation: 'Terrazzo Workers and Finishers',
    'Projected Growth (2021-2031)': 'Decline',
    'Projected Job Openings (2021-2031)': 200,
    Industries: 'Construction (98%)',
  },
  {
    Code: '51-6061.00',
    Occupation: 'Textile Bleaching and Dyeing Machine Operators and Tenders',
    'Projected Growth (2021-2031)': 'Decline',
    'Projected Job Openings (2021-2031)': 700,
    Industries: 'Manufacturing (97%)',
  },
  {
    Code: '51-6062.00',
    Occupation: 'Textile Cutting Machine Setters, Operators, and Tenders',
    'Projected Growth (2021-2031)': 'Decline',
    'Projected Job Openings (2021-2031)': 1400,
    Industries: 'Manufacturing (84%)',
  },
  {
    Code: '51-6063.00',
    Occupation:
      'Textile Knitting and Weaving Machine Setters, Operators, and Tenders',
    'Projected Growth (2021-2031)': 'Decline',
    'Projected Job Openings (2021-2031)': 1900,
    Industries: 'Manufacturing (97%)',
  },
  {
    Code: '51-6064.00',
    Occupation:
      'Textile Winding, Twisting, and Drawing Out Machine Setters, Operators, and Tenders',
    'Projected Growth (2021-2031)': 'Decline',
    'Projected Job Openings (2021-2031)': 2600,
    Industries: 'Manufacturing (98%)',
  },
  {
    Code: '51-6099.00',
    Occupation: 'Textile, Apparel, and Furnishings Workers, All Other',
    'Projected Growth (2021-2031)': 'Decline',
    'Projected Job Openings (2021-2031)': 1200,
    Industries:
      'Manufacturing (64%), Administrative and Support Services (14%)',
  },
  {
    Code: '29-1129.00',
    Occupation: 'Therapists, All Other',
    'Projected Growth (2021-2031)': 'Much faster than average',
    'Projected Job Openings (2021-2031)': 3300,
    Industries: 'Health Care and Social Assistance (28%)',
  },
  {
    Code: '29-1129.01',
    Occupation: 'Art Therapists',
    'Projected Growth (2021-2031)': 'Much faster than average',
    'Projected Job Openings (2021-2031)': 3300,
    Industries: 'Health Care and Social Assistance (28%)',
  },
  {
    Code: '29-1129.02',
    Occupation: 'Music Therapists',
    'Projected Growth (2021-2031)': 'Much faster than average',
    'Projected Job Openings (2021-2031)': 3300,
    Industries: 'Health Care and Social Assistance (28%)',
  },
  {
    Code: '47-2044.00',
    Occupation: 'Tile and Stone Setters',
    'Projected Growth (2021-2031)': 'Faster than average',
    'Projected Job Openings (2021-2031)': 5600,
    Industries: 'Construction (59%), Manufacturing (10%)',
  },
  {
    Code: '51-2061.00',
    Occupation: 'Timing Device Assemblers and Adjusters',
    'Projected Growth (2021-2031)': 'Decline',
    'Projected Job Openings (2021-2031)': 100,
    Industries: 'Manufacturing (84%)',
  },
  {
    Code: '51-9197.00',
    Occupation: 'Tire Builders',
    'Projected Growth (2021-2031)': 'Faster than average',
    'Projected Job Openings (2021-2031)': 2100,
    Industries: 'Manufacturing (97%)',
  },
  {
    Code: '49-3093.00',
    Occupation: 'Tire Repairers and Changers',
    'Projected Growth (2021-2031)': 'Slower than average',
    'Projected Job Openings (2021-2031)': 11100,
    Industries: 'Retail Trade (84%)',
  },
  {
    Code: '23-2093.00',
    Occupation: 'Title Examiners, Abstractors, and Searchers',
    'Projected Growth (2021-2031)': 'Slower than average',
    'Projected Job Openings (2021-2031)': 6000,
    Industries:
      'Finance and Insurance (39%), Professional, Scientific, and Technical Services (36%)',
  },
  {
    Code: '51-4111.00',
    Occupation: 'Tool and Die Makers',
    'Projected Growth (2021-2031)': 'Decline',
    'Projected Job Openings (2021-2031)': 6500,
    Industries: 'Manufacturing (94%)',
  },
  {
    Code: '51-4194.00',
    Occupation: 'Tool Grinders, Filers, and Sharpeners',
    'Projected Growth (2021-2031)': 'Decline',
    'Projected Job Openings (2021-2031)': 600,
    Industries:
      'Manufacturing (66%), Other Services (Except Public Administration) (19%), Wholesale Trade (10%)',
  },
  {
    Code: '',
    Occupation: 'Tour and Travel Guides',
    'Projected Growth (2021-2031)': 'Much faster than average',
    'Projected Job Openings (2021-2031)': 9600,
    Industries:
      'Arts, Entertainment, and Recreation (42%), Administrative and Support Services (10%)',
  },
  {
    Code: '39-7011.00',
    Occupation: 'Tour Guides and Escorts',
    'Projected Growth (2021-2031)': 'Much faster than average',
    'Projected Job Openings (2021-2031)': 9600,
    Industries:
      'Arts, Entertainment, and Recreation (42%), Administrative and Support Services (10%)',
  },
  {
    Code: '39-7012.00',
    Occupation: 'Travel Guides',
    'Projected Growth (2021-2031)': 'Much faster than average',
    'Projected Job Openings (2021-2031)': 9600,
    Industries:
      'Arts, Entertainment, and Recreation (42%), Administrative and Support Services (10%)',
  },
  {
    Code: '53-6041.00',
    Occupation: 'Traffic Technicians',
    'Projected Growth (2021-2031)': 'Slower than average',
    'Projected Job Openings (2021-2031)': 1000,
    Industries:
      'Government (80%), Professional, Scientific, and Technical Services (11%)',
  },
  {
    Code: '11-3131.00',
    Occupation: 'Training and Development Managers',
    'Projected Growth (2021-2031)': 'Faster than average',
    'Projected Job Openings (2021-2031)': 3700,
    Industries:
      'Professional, Scientific, and Technical Services (14%), Management of Companies and Enterprises (13%), Educational Services (11%), Finance and Insurance (10%)',
  },
  {
    Code: '13-1151.00',
    Occupation: 'Training and Development Specialists',
    'Projected Growth (2021-2031)': 'Faster than average',
    'Projected Job Openings (2021-2031)': 36500,
    Industries:
      'Professional, Scientific, and Technical Services (12%), Health Care and Social Assistance (12%), Educational Services (11%)',
  },
  {
    Code: '33-3052.00',
    Occupation: 'Transit and Railroad Police',
    'Projected Growth (2021-2031)': 'Slower than average',
    'Projected Job Openings (2021-2031)': 300,
    Industries: 'Government (91%)',
  },
  {
    Code: '53-6051.00',
    Occupation: 'Transportation Inspectors',
    'Projected Growth (2021-2031)': 'Slower than average',
    'Projected Job Openings (2021-2031)': 2800,
    Industries: 'Government (52%), Transportation and Warehousing (31%)',
  },
  {
    Code: '53-6051.01',
    Occupation: 'Aviation Inspectors',
    'Projected Growth (2021-2031)': 'Slower than average',
    'Projected Job Openings (2021-2031)': 2800,
    Industries: 'Government (52%), Transportation and Warehousing (31%)',
  },
  {
    Code: '53-6051.07',
    Occupation:
      'Transportation Vehicle, Equipment and Systems Inspectors, Except Aviation',
    'Projected Growth (2021-2031)': 'Slower than average',
    'Projected Job Openings (2021-2031)': 2800,
    Industries: 'Government (52%), Transportation and Warehousing (31%)',
  },
  {
    Code: '33-9093.00',
    Occupation: 'Transportation Security Screeners',
    'Projected Growth (2021-2031)': 'Little or no change',
    'Projected Job Openings (2021-2031)': 4600,
    Industries: 'Government (87%)',
  },
  {
    Code: '53-6099.00',
    Occupation: 'Transportation Workers, All Other',
    'Projected Growth (2021-2031)': 'Average',
    'Projected Job Openings (2021-2031)': 1900,
    Industries:
      'Transportation and Warehousing (40%), Government (13%), Retail Trade (13%), Administrative and Support Services (12%)',
  },
  {
    Code: '11-3071.00',
    Occupation: 'Transportation, Storage, and Distribution Managers',
    'Projected Growth (2021-2031)': 'Faster than average',
    'Projected Job Openings (2021-2031)': 14200,
    Industries:
      'Transportation and Warehousing (31%), Wholesale Trade (15%), Manufacturing (11%), Government (11%)',
  },
  {
    Code: '11-3071.04',
    Occupation: 'Supply Chain Managers',
    'Projected Growth (2021-2031)': 'Faster than average',
    'Projected Job Openings (2021-2031)': 14200,
    Industries:
      'Transportation and Warehousing (31%), Wholesale Trade (15%), Manufacturing (11%), Government (11%)',
  },
  {
    Code: '41-3041.00',
    Occupation: 'Travel Agents',
    'Projected Growth (2021-2031)': 'Much faster than average',
    'Projected Job Openings (2021-2031)': 7500,
    Industries: 'Administrative and Support Services (71%)',
  },
  {
    Code: '37-3013.00',
    Occupation: 'Tree Trimmers and Pruners',
    'Projected Growth (2021-2031)': 'Slower than average',
    'Projected Job Openings (2021-2031)': 9100,
    Industries: 'Administrative and Support Services (57%)',
  },
  {
    Code: '25-3041.00',
    Occupation: 'Tutors',
    'Projected Growth (2021-2031)': 'Much faster than average',
    'Projected Job Openings (2021-2031)': 35000,
    Industries: 'Educational Services (70%)',
  },
  {
    Code: '27-2023.00',
    Occupation: 'Umpires, Referees, and Other Sports Officials',
    'Projected Growth (2021-2031)': 'Much faster than average',
    'Projected Job Openings (2021-2031)': 3600,
    Industries: 'Arts, Entertainment, and Recreation (31%), Government (27%)',
  },
  {
    Code: '47-5049.00',
    Occupation: 'Underground Mining Machine Operators, All Other',
    'Projected Growth (2021-2031)': 'Decline',
    'Projected Job Openings (2021-2031)': 400,
    Industries: 'Mining, Quarrying, and Oil and Gas Extraction (84%)',
  },
  {
    Code: '51-6093.00',
    Occupation: 'Upholsterers',
    'Projected Growth (2021-2031)': 'Slower than average',
    'Projected Job Openings (2021-2031)': 3700,
    Industries:
      'Manufacturing (57%), Other Services (Except Public Administration) (20%)',
  },
  {
    Code: '19-3051.00',
    Occupation: 'Urban and Regional Planners',
    'Projected Growth (2021-2031)': 'Average',
    'Projected Job Openings (2021-2031)': 3800,
    Industries:
      'Government (79%), Professional, Scientific, and Technical Services (13%)',
  },
  {
    Code: '39-3031.00',
    Occupation: 'Ushers, Lobby Attendants, and Ticket Takers',
    'Projected Growth (2021-2031)': 'Much faster than average',
    'Projected Job Openings (2021-2031)': 24100,
    Industries:
      'Information (42%), Arts, Entertainment, and Recreation (29%), Administrative and Support Services (10%)',
  },
  {
    Code: '29-1131.00',
    Occupation: 'Veterinarians',
    'Projected Growth (2021-2031)': 'Much faster than average',
    'Projected Job Openings (2021-2031)': 4800,
    Industries: 'Professional, Scientific, and Technical Services (85%)',
  },
  {
    Code: '31-9096.00',
    Occupation: 'Veterinary Assistants and Laboratory Animal Caretakers',
    'Projected Growth (2021-2031)': 'Much faster than average',
    'Projected Job Openings (2021-2031)': 23900,
    Industries: 'Professional, Scientific, and Technical Services (91%)',
  },
  {
    Code: '29-2056.00',
    Occupation: 'Veterinary Technologists and Technicians',
    'Projected Growth (2021-2031)': 'Much faster than average',
    'Projected Job Openings (2021-2031)': 15500,
    Industries: 'Professional, Scientific, and Technical Services (92%)',
  },
  {
    Code: '35-3031.00',
    Occupation: 'Waiters and Waitresses',
    'Projected Growth (2021-2031)': 'Faster than average',
    'Projected Job Openings (2021-2031)': 425800,
    Industries: 'Accommodation and Food Services (92%)',
  },
  {
    Code: '49-9064.00',
    Occupation: 'Watch and Clock Repairers',
    'Projected Growth (2021-2031)': 'Decline',
    'Projected Job Openings (2021-2031)': 100,
    Industries:
      'Retail Trade (58%), Other Services (Except Public Administration) (28%)',
  },
  {
    Code: '51-8031.00',
    Occupation: 'Water and Wastewater Treatment Plant and System Operators',
    'Projected Growth (2021-2031)': 'Decline',
    'Projected Job Openings (2021-2031)': 10800,
    Industries: 'Government (76%), Utilities (12%)',
  },
  {
    Code: '15-1255.00',
    Occupation: 'Web and Digital Interface Designers',
    'Projected Growth (2021-2031)': 'Much faster than average',
    'Projected Job Openings (2021-2031)': 10800,
    Industries:
      'Professional, Scientific, and Technical Services (27%), Information (24%)',
  },
  {
    Code: '15-1255.01',
    Occupation: 'Video Game Designers',
    'Projected Growth (2021-2031)': 'Much faster than average',
    'Projected Job Openings (2021-2031)': 10800,
    Industries:
      'Professional, Scientific, and Technical Services (27%), Information (24%)',
  },
  {
    Code: '15-1254.00',
    Occupation: 'Web Developers',
    'Projected Growth (2021-2031)': 'Much faster than average',
    'Projected Job Openings (2021-2031)': 11000,
    Industries:
      'Professional, Scientific, and Technical Services (34%), Information (16%)',
  },
  {
    Code: '43-5111.00',
    Occupation: 'Weighers, Measurers, Checkers, and Samplers, Recordkeeping',
    'Projected Growth (2021-2031)': 'Little or no change',
    'Projected Job Openings (2021-2031)': 6400,
    Industries:
      'Transportation and Warehousing (28%), Manufacturing (16%), Administrative and Support Services (14%), Wholesale Trade (14%), Retail Trade (10%)',
  },
  {
    Code: '51-4121.00',
    Occupation: 'Welders, Cutters, Solderers, and Brazers',
    'Projected Growth (2021-2031)': 'Slower than average',
    'Projected Job Openings (2021-2031)': 47600,
    Industries: 'Manufacturing (64%), Construction (12%)',
  },
  {
    Code: '51-4122.00',
    Occupation:
      'Welding, Soldering, and Brazing Machine Setters, Operators, and Tenders',
    'Projected Growth (2021-2031)': 'Decline',
    'Projected Job Openings (2021-2031)': 3100,
    Industries: 'Manufacturing (82%)',
  },
  {
    Code: '53-7073.00',
    Occupation: 'Wellhead Pumpers',
    'Projected Growth (2021-2031)': 'Slower than average',
    'Projected Job Openings (2021-2031)': 2300,
    Industries: 'Mining, Quarrying, and Oil and Gas Extraction (92%)',
  },
  {
    Code: '49-9081.00',
    Occupation: 'Wind Turbine Service Technicians',
    'Projected Growth (2021-2031)': 'Much faster than average',
    'Projected Job Openings (2021-2031)': 1900,
    Industries:
      'Utilities (33%), Other Services (Except Public Administration) (25%), Construction (22%)',
  },
  {
    Code: '51-7099.00',
    Occupation: 'Woodworkers, All Other',
    'Projected Growth (2021-2031)': 'Faster than average',
    'Projected Job Openings (2021-2031)': 1400,
    Industries: 'Manufacturing (49%)',
  },
  {
    Code: '51-7042.00',
    Occupation:
      'Woodworking Machine Setters, Operators, and Tenders, Except Sawing',
    'Projected Growth (2021-2031)': 'Slower than average',
    'Projected Job Openings (2021-2031)': 7200,
    Industries: 'Manufacturing (91%)',
  },
  {
    Code: '43-9022.00',
    Occupation: 'Word Processors and Typists',
    'Projected Growth (2021-2031)': 'Decline',
    'Projected Job Openings (2021-2031)': 3100,
    Industries: 'Government (47%), Educational Services (29%)',
  },
  {
    Code: '27-3043.00',
    Occupation: 'Writers and Authors',
    'Projected Growth (2021-2031)': 'Average',
    'Projected Job Openings (2021-2031)': 15200,
    Industries:
      'Professional, Scientific, and Technical Services (11%), Information (11%)',
  },
  {
    Code: '27-3043.05',
    Occupation: 'Poets, Lyricists and Creative Writers',
    'Projected Growth (2021-2031)': 'Average',
    'Projected Job Openings (2021-2031)': 15200,
    Industries:
      'Professional, Scientific, and Technical Services (11%), Information (11%)',
  },
  {
    Code: '19-1023.00',
    Occupation: 'Zoologists and Wildlife Biologists',
    'Projected Growth (2021-2031)': 'Little or no change',
    'Projected Job Openings (2021-2031)': 1500,
    Industries:
      'Government (72%), Professional, Scientific, and Technical Services (13%)',
  },
];
