import { isObjectEmpty, toTitleCase } from "../../utils/common"
import { updateCompanyLogo } from "../../services/companyServices"
import { useState } from "react";
import { Button, Drawer, DrawerCloseButton, DrawerContent, Input, Text, useToast } from "@chakra-ui/react";


export default function UpdateLogo (props) {
    const company = props?.company
    const candidate = props?.candidate
    const [isSubmittingCompanyLogo, setIsSubmittingCompanyLogo] = useState(false);
    const toast = useToast();

    const onChangeCompanyLogo = (e) => {
        e.preventDefault()
        const form = new FormData(e.target)
        if (!form.get('logo')){
            return false
        }

        form.set('candidate_uuid', candidate?.candidate_uuid)   
        form.set('company_name', company?.name)  
        form.set('company_website', company?.website)   
        setIsSubmittingCompanyLogo(true);
        updateCompanyLogo(form)
        .then(res => {
            if (res?.result){
                props.getCandidatePanelData(candidate);
                toast({
                    title: 'Logo updated successfully.',
                    status: 'success', 
                    isClosable: true, 
                    duration: 3000
                });
                props.onCompanyLogoPanelClose()
            }else{
                toast({
                    title: 'Logo not updated.',
                    description: res?.message,
                    status: 'error', 
                    isClosable: true, 
                    duration: 3000
                  });
            }
            setIsSubmittingCompanyLogo(false);
        })
        .catch(err => {
            console.log(err)
            setIsSubmittingCompanyLogo(false);
            toast({
                title: 'Logo not updated.',
                description: err.toString(),
                status: 'error', 
                isClosable: true, 
                duration: 3000
              });
        })
    } 

    if (!company || isObjectEmpty(company) || !candidate || isObjectEmpty(candidate)){
        return null
    }

    return (
        <Drawer size={'md'} isOpen={props.isCompanyLogoPanelOpen} placement="right" onClose={props.onCompanyLogoPanelClose} >
                <DrawerContent p={10}>
                <form onSubmit={onChangeCompanyLogo}>
                    <Text fontWeight={'bold'} mt={10} mb={10} fontSize={'18px'}>Change Logo for {company?.name && company?.name}</Text>
                    <Input type='file' name={'logo'} accept='.png, .jpg, .jpeg, .svg, .webp'
                    required mb={10}
                    />

                    <Button variant={'solid'} colorScheme='blue' loadingText={'Uploading...'}
                    isLoading={isSubmittingCompanyLogo} type='submit'>Update</Button>
                </form>
                <DrawerCloseButton />
                </DrawerContent>
            </Drawer>
    )
}