import { MdLocationPin } from "react-icons/md"
import { isObjectEmpty, toTitleCase } from "../../utils/common"
import { Flex, Icon, Text, Tooltip } from "@chakra-ui/react"

export default function CandidateLocation (props) {
    const {candidate, fontWeight, fontSize, fontColor, mTop, mLeft, mRight, mBottom} = props
    let educationText = ''
    if (candidate?.highest_education){
        educationText = candidate?.highest_education && candidate?.highest_education
    }

    else if (!isObjectEmpty(candidate?.raw_data) && candidate?.raw_data?.education?.length > 0 && candidate?.raw_data?.education[0]?.school?.name){
        educationText = candidate?.raw_data?.education[0]?.school?.name
    }

    else {
        educationText = "N/A"
    }
    return (
        <Flex ml={mLeft ? mLeft : null} mr={mRight ? mRight : null}
                mt={mTop ? mTop : null} mb={mBottom ? mBottom : null}
                alignItems={'center'}
            >
            <Tooltip label={'Current Location'} fontSize='md'>
                <Flex fontSize={fontSize ? fontSize : null}
                    fontWeight={fontWeight ? fontWeight : null}
                    color={fontColor ? fontColor : null} alignItems={'center'}
                >
                    <Icon mr={2} fontWeight={'bold'} as={MdLocationPin} />
                    <Text>{candidate?.current_location ? candidate?.current_location : "N/A"}</Text>
                </Flex>
            </Tooltip>
            
        </Flex>        
    )
  }
