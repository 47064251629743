/*!
  _   _  ___  ____  ___ ________  _   _   _   _ ___   ____  ____   ___  
 | | | |/ _ \|  _ \|_ _|__  / _ \| \ | | | | | |_ _| |  _ \|  _ \ / _ \ 
 | |_| | | | | |_) || |  / / | | |  \| | | | | || |  | |_) | |_) | | | |
 |  _  | |_| |  _ < | | / /| |_| | |\  | | |_| || |  |  __/|  _ <| |_| |
 |_| |_|\___/|_| \_\___/____\___/|_| \_|  \___/|___| |_|   |_| \_\\___/ 
                                                                                                                                                                                                                                                                                                                                       
=========================================================
* Horizon UI Dashboard PRO - v1.0.0
=========================================================

* Product Page: https://www.horizon-ui.com/pro/
* Copyright 2022 Horizon UI (https://www.horizon-ui.com/)

* Designed and Coded by Simmmple

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

// Chakra imports
import {
  Box,
  Button,
  Flex,
  FormControl,
  PinInput,
  PinInputField,
  Heading,
  Text,
  useToast,
  useColorModeValue,
} from '@chakra-ui/react';
// Assets
import illustration from '../../../assets/img/auth/auth.png';
import DefaultAuth from '../../../layouts/auth/types/Default';
import CenteredAuth from '../../../layouts/auth/types/Centered';

import {
  verifyUserEmail,
  reGenerateEmailCode,
} from '../../../services/sharedServices';


function TsVerficationMail() {
  // Chakra color mode
  // const textColor = useColorModeValue('navy.700', 'white');
  // const textColorDetails = useColorModeValue('navy.700', 'secondaryGray.600');
  // const textColorBrand = useColorModeValue('brand.500', 'white');
  // const borderColor = useColorModeValue('secondaryGray.400', 'whiteAlpha.100');

  const toast = useToast();
  const [valueOne, setValueOne] = useState('');
  const [valueTwo, setValueTwo] = useState('');
  const [valueThree, setValueThree] = useState('');
  const [valueFour, setValueFour] = useState('');
  // const [valueFive, setValueFive] = useState('');
  // const [valueSix, setValueSix] = useState('');
  const [loading, isLoading] = useState(false);
  const { state } = useLocation();
  const navigate = useNavigate();
  const userEmailId = state?.user_emailId;

  // Chakra color mode
  const textColor = useColorModeValue('secondaryGray.900', 'white');
  const textColorSecondary = 'gray.400';
  const borderColor = useColorModeValue('secondaryGray.400', 'whiteAlpha.100');
  const textColorDetails = useColorModeValue('navy.700', 'secondaryGray.600');
  const textColorBrand = useColorModeValue('brand.500', 'white');

  const verifyCode = () => {
    let objData = {
      email: userEmailId,
      email_code: `${valueOne}${valueTwo}${valueThree}${valueFour}`,
    };
    isLoading(true);
    verifyUserEmail(objData)
      .then(res => {
        isLoading(false);
        if (res?.result) {
          navigate('/login');
          toastFunction(res.message, 'success');
        } else {
            toastFunction(res.message, 'error');
        }
      })
      .catch(err => {
        isLoading(false);
        toastFunction(err.message, 'error');
      });
  };

  const resendVerificationCode = () => {
    let obj = {
      email: userEmailId,
    };
    isLoading(true);

    reGenerateEmailCode(obj)
      .then(res => {
        isLoading(false);
        if (res?.result) {
          toastFunction(res.message, 'success');
        } else {
          if (res?.result) {
            toastFunction(res.message, 'error');
          }
        }
      })
      .catch(err => {
        isLoading(false);
        toastFunction(err.message, 'error');
      });
  };

  const toastFunction = (message, status) => {
    return toast({
      title: message,
      status: status,
      isClosable: true,
      duration: 3000,
    });
  };


  return (
    // <DefaultAuth illustrationBackground={illustration} image={illustration}>
    <CenteredAuth 
        cardTop={{ base: '140px', md: '14vh' }}
        cardBottom={{ base: '50px', lg: 'auto' }}
        mx="0px">
      <Flex
        w="100%"
        maxW="max-content"
        mx={{ base: 'auto', lg: '0px' }}
        me="auto"
        h="100%"
        alignItems="start"
        justifyContent="center"
        mb={{ base: '30px', md: '60px', lg: '90px', xl: '60px' }}
        px={{ base: '25px', md: '0px' }}
        mt={{ base: '40px', lg: '14vh', xl: '22vh' }}
        flexDirection="column"
      >
        <Box me="auto" mb="34px">
          <Heading
            color={textColor}
            fontSize="36px"
            mb="16px"
            mx={{ base: 'auto', lg: 'unset' }}
            textAlign={{ base: 'center', lg: 'left' }}
          >
            2-Step Verification
          </Heading>
          <Text
            color="gray.400"
            fontSize="md"
            maxW={{ base: '95%', md: '100%' }}
            mx={{ base: 'auto', lg: 'unset' }}
            textAlign={{ base: 'center', lg: 'left' }}
          >
            Enter your 2-Step Verification email code to unlock!
          </Text>
        </Box>
        <Flex
          zIndex="2"
          direction="column"
          w={{ base: '100%', md: '395px' }}
          maxW="100%"
          background="transparent"
          borderRadius="15px"
          mx={{ base: 'auto', lg: 'unset' }}
          me="auto"
          mb={{ base: '20px', md: 'auto' }}
        >
          <FormControl>
            <Flex justify="center">
              <PinInput mx="auto" otp>
                <PinInputField
                  fontSize="36px"
                  color={textColor}
                  borderRadius="16px"
                  borderColor={borderColor}
                  h={{ base: '63px', md: '95px' }}
                  w={{ base: '63px', md: '95px' }}
                  me="10px"
                  value={valueOne}
                  onChange={e => {
                    setValueOne(e.target.value);
                  }}

                />
                <PinInputField
                  fontSize="36px"
                  color={textColor}
                  borderRadius="16px"
                  borderColor={borderColor}
                  h={{ base: '63px', md: '95px' }}
                  w={{ base: '63px', md: '95px' }}
                  me="10px"
                  value={valueTwo}
                  onChange={e => {
                    setValueTwo(e.target.value);
                  }}

                />
                <PinInputField
                  fontSize="36px"
                  color={textColor}
                  borderRadius="16px"
                  borderColor={borderColor}
                  h={{ base: '63px', md: '95px' }}
                  w={{ base: '63px', md: '95px' }}
                  me="10px"
                  value={valueThree}
                  onChange={e => {
                    setValueThree(e.target.value);
                  }}

                />
                <PinInputField
                  fontSize="36px"
                  color={textColor}
                  borderRadius="16px"
                  borderColor={borderColor}
                  h={{ base: '63px', md: '95px' }}
                  w={{ base: '63px', md: '95px' }}
                  value={valueFour}
                  onChange={e => {
                    setValueFour(e.target.value);
                  }}

                />
              </PinInput>
            </Flex>

            <Button
              fontSize="14px"
              variant="brand"
              borderRadius="16px"
              fontWeight="500"
              w="100%"
              h="50"
              mb="24px"
              mt="12px"
              onClick={() => {
                verifyCode();
              }}
              isDisabled={loading ? true : false}
              isLoading={loading ? true : false}
            >
              Unlock
            </Button>
          </FormControl>
          <Flex
            flexDirection="column"
            justifyContent="center"
            alignItems="start"
            maxW="100%"
            mt="0px"
          >
            <Text
              color={textColorDetails}
              fontWeight="400"
              fontSize="14px"
              mx={{ base: 'auto', lg: 'unset' }}
              textAlign={{ base: 'center', lg: 'left' }}
              onClick={() => resendVerificationCode()}
            >
              Please check your email and possibly in the spam folder for the email verification code. 
              If you haven't received the code please use this option: 
              <Text color={textColorBrand} as="span" ms="5px" fontWeight="500">
                Resend a new code
              </Text>
            </Text>
          </Flex>
        </Flex>
      </Flex>
    </CenteredAuth>
  );
}

export default TsVerficationMail;
