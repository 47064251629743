import axios from './axios';
import { serviceUrls } from './serviceUrls';

export const fetchJobTemplateServices = (
  data,
  config = null,
  axiosInstance = axios
) => {
  return axiosInstance.post(serviceUrls().fetchJobTemplateList, data, config);
};

export const createJobTemplate = (
  data,
  config = null,
  axiosInstance = axios
) => {
  return axiosInstance.post(serviceUrls().addJobTemplate, data, config);
};

export const updateJobTemplateDetails = (
  data,
  config = null,
  axiosInstance = axios
) => {
  return axiosInstance.post(serviceUrls().updateJobTemplate, data, config);
};

export const updateJobTemplateStatus = (
  data,
  config = null,
  axiosInstance = axios
) => {
  return axiosInstance.post(
    serviceUrls().manageJobTemplateStatus,
    data,
    config
  );
};

export const searchJobTemplateByKeyword = (
  data,
  config = null,
  axiosInstance = axios
) => {
  return axiosInstance.post(serviceUrls().serchJobtemplate, data, config);
};

export const fetchJobFlowData = (
  data,
  config = null,
  axiosInstance = axios
) => {
  return axiosInstance.post(serviceUrls().jobWorkflowList, data, config);
};

export const fetchActiveJobsList = (
  data,
  config = null,
  axiosInstance = axios
) => {
  return axiosInstance.post(serviceUrls().activeJobsList, data, config);
};

// Applicant API
export const fetchApplicantJobList = (
  data,
  config = null,
  axiosInstance = axios
) => {
  return axiosInstance.post(serviceUrls().applicantJobsList, data, config);
};
export const fetchApplicantCandidateList = (
  data,
  config = null,
  axiosInstance = axios
) => {
  return axiosInstance.post(serviceUrls().applicantCandidatesList, data, config);
};
export const fetchInterviewReadyCandidates = (
  data,
  config = null,
  axiosInstance = axios
) => {
  return axiosInstance.post(serviceUrls().interviewReadyCandidatesApi, data, config);
};

// Scheduled Candidate List based on selected Job ID
export const fetchScheduledCandidateList = (
  data,
  config = null,
  axiosInstance = axios
) => {
  return axiosInstance.post(serviceUrls().scheduledCandidatesList, data, config);
};

// Calendar API
export const fetchCalendarJobList = (
  data,
  config = null,
  axiosInstance = axios
) => {
  return axiosInstance.post(serviceUrls().calendarJobsList, data, config);
};

export const fetchAllActiveJobList = (
  data,
  config = null,
  axiosInstance = axios
) => {
  return axiosInstance.post(serviceUrls().allActiveJobsList, data, config);
};

export const fetchOnBoardingJobList = (
  data,
  config = null,
  axiosInstance = axios
) => {
  return axiosInstance.post(serviceUrls().onboardingJobsList, data, config);
};


export const fetchOnboardingCandidateList = (
  data,
  config = null,
  axiosInstance = axios
) => {
  return axiosInstance.post(serviceUrls().onboardingCandidatesList, data, config);
};


export const addjobTitleWorkflow = (
  data,
  config = null,
  axiosInstance = axios
) => {
  return axiosInstance.post(serviceUrls().jobTitleWorkflow, data, config);
};

export const saveJobWizardOneData = (
  data,
  config = null,
  axiosInstance = axios
) => {
  return axiosInstance.post(serviceUrls().jobWizardOneApi, data, config);
};
export const saveJobWizardTwoData = (
  data,
  config = null,
  axiosInstance = axios
) => {
  return axiosInstance.post(serviceUrls().jobWizardTwoApi, data, config);
};
export const saveJobWizardThreeData = (
  data,
  config = null,
  axiosInstance = axios
) => {
  return axiosInstance.post(serviceUrls().jobWizardThreeApi, data, config);
};
export const saveJobWizardFourData = (
  data,
  config = null,
  axiosInstance = axios
) => {
  return axiosInstance.post(serviceUrls().jobWizardFourApi, data, config);
};


export const editjobTitleWorkflow = (
  data,
  config = null,
  axiosInstance = axios
) => {
  return axiosInstance.post(serviceUrls().jobTitleEditWorkflow, data, config);
};

export const addjobSynopsisWorkflow = (
  data,
  config = null,
  axiosInstance = axios
) => {
  return axiosInstance.post(serviceUrls().jobSynopsisWorkflow, data, config);
};

export const addjobBenifitsWorkflow = (
  data,
  config = null,
  axiosInstance = axios
) => {
  return axiosInstance.post(serviceUrls().jobBenifitsWorkflow, data, config);
};

export const addjobLocationWorkflow = (
  data,
  config = null,
  axiosInstance = axios
) => {
  return axiosInstance.post(serviceUrls().jobLocationWorkflow, data, config);
};

export const addEmployeeTypeWorkflow = (
  data,
  config = null,
  axiosInstance = axios
) => {
  return axiosInstance.post(serviceUrls().employeeTypeWorkflow, data, config);
};

export const addExpericeWorkFlow = (
  data,
  config = null,
  axiosInstance = axios
) => {
  return axiosInstance.post(serviceUrls().experienceWorkflow, data, config);
};

export const addEmployeeEducationWorkFlow = (
  data,
  config = null,
  axiosInstance = axios
) => {
  return axiosInstance.post(
    serviceUrls().employeeEducationWorkFlow,
    data,
    config
  );
};

export const addEmployeeQuotedAmount = (
  data,
  config = null,
  axiosInstance = axios
) => {
  return axiosInstance.post(serviceUrls().employeeQuotedAmount, data, config);
};

export const addEmployeeRateWorkflow = (
  data,
  config = null,
  axiosInstance = axios
) => {
  return axiosInstance.post(serviceUrls().employeeRateWorkflow, data, config);
};

export const addEmployeeSuplementPay = (
  data,
  config = null,
  axiosInstance = axios
) => {
  return axiosInstance.post(
    serviceUrls().employeeSuplementPayWorkflow,
    data,
    config
  );
};

export const submitEmployeeSurvey = (
  data,
  config = null,
  axiosInstance = axios
) => {
  return axiosInstance.post(serviceUrls().submitSurveryWorkflow, data, config);
};

export const fetchJobWorkflowDetails = (
  data,
  config = null,
  axiosInstance = axios
) => {
  return axiosInstance.post(serviceUrls().fetchJobWorkflowData, data, config);
};

export const fetchJobDetailedData = (
  data,
  adminSettings,
  config = null,
  axiosInstance = axios
) => {
  if (adminSettings === 'true') {
    return axiosInstance.post(serviceUrls().adminJobFullData, data, config);
  } else {
    return axiosInstance.post(serviceUrls().viewJobData, data, config);
  }
};

export const fetchJobDetailedPublicData = (
  data,
  config = null,
  axiosInstance = axios
) => {
  return axiosInstance.post(serviceUrls().viewJobDataPublic, data, config);
};

export const submitJobApplication = (
  form,
  config = null,
  axiosInstance = axios
) => {
  return axiosInstance.postForm(serviceUrls().submitJobApplication, form, config);
};

export const fetchAutomatedAdditionalInfo = (
  data,
  config = null,
  axiosInstance = axios
) => {
  return axiosInstance.post(serviceUrls().automatedInformation, data, config);
};

export const processBase64PdfData = (
  data,
  config = null,
  axiosInstance = axios
) => {
  return axiosInstance.post(serviceUrls().jobWizardPdfData, data, config);
};

export const getTextFromDocument = (
  data,
  config = null,
  axiosInstance = axios
) => {
  return axiosInstance.postForm(serviceUrls().getTextFromDocument, data, config);
};

export const saveAdditionalJobDetails = (
  data,
  config = null,
  axiosInstance = axios
) => {
  return axiosInstance.post(serviceUrls().additionalInformation, data, config);
};

export const saveJobDepartment = (
  data,
  config = null,
  axiosInstance = axios
) => {
  return axiosInstance.post(serviceUrls().updateJobDepartment, data, config);
};

export const saveJobSpecification = (
  data,
  config = null,
  axiosInstance = axios
) => {
  return axiosInstance.post(serviceUrls().updateJobSpecification, data, config);
};

export const jobWorkflowStatus = (
  data,
  config = null,
  axiosInstance = axios
) => {
  return axiosInstance.post(serviceUrls().jobWorflowStatus, data, config);
};

export const jobWorkflowStatusCount = (
  data,
  config = null,
  axiosInstance = axios
) => {
  return axiosInstance.post(serviceUrls().fetchJobWorkFlowCount, data, config);
};

export const jobWorkflowStarted = (
  data,
  config = null,
  axiosInstance = axios
) => {
  return axiosInstance.post(serviceUrls().jobWorkflowStart, data, config);
};

export const jobWorkFindCandidates = (
  data,
  adminSettings,
  config = null,
  axiosInstance = axios
) => {
  if (adminSettings === 'true') {
    return axiosInstance.post(serviceUrls().findAdminCandidate, data, config);
  } else {
    return axiosInstance.post(serviceUrls().findCandidate, data, config);
  }
};

export const jobAdminCollectFullUploadedList = (
  data,
  config = null,
  axiosInstance = axios
) => {
  return axiosInstance.post(serviceUrls().adminUploadedCandidatesList, data, config);
};

// Avkash - Removed This part 2
// export const jobWorkflowShortListCandidate = (
//   data,
//   adminSettings,
//   config = null,
//   axiosInstance = axios
// ) => {
//   if (adminSettings === 'true') {
//     return axiosInstance.post(
//       serviceUrls().adminShortlistCandidate,
//       data,
//       config
//     );
//   } else {
//     return axiosInstance.post(serviceUrls().shortListCandidate, data, config);
//   }
// };
// export const setCandidateScreening = (
//   data,
//   adminSettings,
//   config = null,
//   axiosInstance = axios
// ) => {
//   if (adminSettings === 'true') {
//     return axiosInstance.post(
//       serviceUrls().adminStartScreeningCandidate,
//       data,
//       config
//     );
//   } else {
//     return axiosInstance.post(serviceUrls().screeningCandidate, data, config);
//   }
// };
// This API is replaced with 
// export const fetchJobListedCandidate = (
//   data,
//   adminSettings,
//   config = null,
//   axiosInstance = axios
// ) => {
//   if (adminSettings === 'true') {
//     return axiosInstance.post(
//       serviceUrls().adminShortListedCandidate,
//       data,
//       config
//     );
//   } else {
//     return axiosInstance.post(
//       serviceUrls().fetchShortListedCandidate,
//       data,
//       config
//     );
//   }
// };
export const jobWorkflowInprocess = (
  data,
  adminSettings,
  config = null,
  axiosInstance = axios
) => {
  if (adminSettings === 'true') {
    return axiosInstance.post(
      serviceUrls().adminInProcessCandidate,
      data,
      config
    );
  } else {
    return axiosInstance.post(serviceUrls().inProcessCandidate, data, config);
  }
};

export const jobWorkflowAdmSelectCandidate = ( // Replacement to -> jobWorkflowShortListCandidate
  data,
  adminSettings,
  config = null,
  axiosInstance = axios
) => {
    return axiosInstance.post(
      serviceUrls().admin2SelectCandidate,    //adminShortlistCandidate,
      data,
      config
    );
};
export const jobWorkflowAdmSourceCandidate = (    //setCandidateScreening
  data,
  adminSettings,
  config = null,
  axiosInstance = axios
) => {
    return axiosInstance.post(
      serviceUrls().admin2SourceCandidate,  // adminStartScreeningCandidate,
      data,
      config
    );
};
export const jobWorkflowAdmGetSelectedSourcedCandidates = (
  data,
  adminSettings,
  config = null,
  axiosInstance = axios
) => {
    return axiosInstance.post(
      serviceUrls().admin2SelectedSourcedCandidates,
      data,
      config
    );
};
export const jobWorkflowKanbanList = (
  data, adminSettings, config = null, axiosInstance = axios
) => {
    return axiosInstance.post(serviceUrls().fullKanbanCandidatesList, data, config);
};

export const jobWorkflowKanbanAction = (
  data, adminSettings, config = null, axiosInstance = axios
) => {
    return axiosInstance.post(serviceUrls().kanbanClickActionUpdate, data, config);
};

export const rejectCandidateFromJobWorkflow = (
  data,
  config = null,
  axiosInstance = axios
) => {
  return axiosInstance.post(serviceUrls().rejectCandidate, data, config);
};

export const fetchCandidateDetails = (
  data,
  adminSettings,
  config = null,
  axiosInstance = axios
) => {
  if (adminSettings === 'true') {
    return axiosInstance.post(
      serviceUrls().adminShowCandiateFullInfo,
      data,
      config
    );
  } else {
    return axiosInstance.post(
      serviceUrls().fetchJobCandidateDetails,
      data,
      config
    );
  }
};

export const updateCandidateDetails = (
  data,
  adminSettings,
  config = null,
  axiosInstance = axios
) => {
  if (adminSettings === 'true') {
    return axiosInstance.post(serviceUrls().adminUpdateCandidate, data, config);
  } else {
    return axiosInstance.post(
      serviceUrls().updateCandidateDetails,
      data,
      config
    );
  }
};

export const rejectedCandidateList = (
  data,
  adminSettings,
  config = null,
  axiosInstance = axios
) => {
  if (adminSettings === 'true') {
    return axiosInstance.post(
      serviceUrls().adminRejectedCandidate,
      data,
      config
    );
  } else {
    return axiosInstance.post(serviceUrls().rejectedCandidate, data, config);
  }
};


export const jobWorkflowInInterview = (
  data,
  adminSettings,
  config = null,
  axiosInstance = axios
) => {
  if (adminSettings === 'true') {
    return axiosInstance.post(
      serviceUrls().adminInterviewProcessCandidate,
      data,
      config
    );
  } else {
    return axiosInstance.post(serviceUrls().inInterviewCandidate, data, config);
  }
};

export const rejectedCandidateInfo = (
  data,
  config = null,
  axiosInstance = axios
) => {
  return axiosInstance.post(serviceUrls().rejectedDetails, data, config);
};

export const candidateKanbanStageUpdate = (
  data,
  config = null,
  axiosInstance = axios
) => {
  return axiosInstance.post(
    serviceUrls().kanbanUpdateStage,
    data,
    config
  );
};

export const partialUpdateFields = (
  data,
  config = null,
  axiosInstance = axios
) => {
  return axiosInstance.post(serviceUrls().partialUpdate, data, config);
};

export const scheduleCandidateInterview = (
  data,
  config = null,
  axiosInstance = axios
) => {
  return axiosInstance.post(serviceUrls().createInterview, data, config);
};

export const fetchCandidateInterviewDetails = (
  data,
  config = null,
  axiosInstance = axios
) => {
  return axiosInstance.get(
    serviceUrls().fetchInterviewDetails + '?candidate_uuid=' + data,
    config
  );
};

export const updateInterviewDetails = (
  interview_uuId,
  data,
  config = null,
  axiosInstance = axios
) => {
  return axiosInstance.put(
    serviceUrls().updateInterviewFields + '?interview_uuid=' + interview_uuId,
    data,
    config
  );
};

export const finalizeCandidateInterview = (
  data,
  config = null,
  axiosInstance = axios
) => {
  return axiosInstance.post(serviceUrls().interviewStageUpdate, data, config);
};

export const fetchFinalizeCandidateList = (
  data,
  adminSettings,
  config = null,
  axiosInstance = axios
) => {
  if (adminSettings === 'true') {
    return axiosInstance.post(
      serviceUrls().adminFinalizeCandidate,
      data,
      config
    );
  } else {
    return axiosInstance.post(
      serviceUrls().finalizeCandidateList,
      data,
      config
    );
  }
};

// export const hireCandidate = (data, config = null, axiosInstance = axios) => {
//   return axiosInstance.post(serviceUrls().hireCandidateStage, data, config);
// };

// export const hiredCandidateDetails = (
//   data,
//   adminSettings,
//   config = null,
//   axiosInstance = axios
// ) => {
//   if (adminSettings === 'true') {
//     return axiosInstance.post(serviceUrls().adminHiredCandidate, data, config);
//   } else {
//     return axiosInstance.post(serviceUrls().hireCandidateList, data, config);
//   }
// };

export const changeProcessingHeadingData = (
  data,
  config = null,
  axiosInstance = axios
) => {
  return axiosInstance.post(
    serviceUrls().changeProcessingHeadings,
    data,
    config
  );
};

export const fetchProcessingHeadingData = (
  data,
  config = null,
  axiosInstance = axios
) => {
  return axiosInstance.post(
    serviceUrls().fetchProcessingHeadings,
    data,
    config
  );
};

export const partiallyUpdateFields = (
  data,
  config = null,
  axiosInstance = axios
) => {
  return axiosInstance.post(serviceUrls().partialUpdateNew, data, config);
};

export const enrichCandidateProfile = (
  data,
  config = null,
  axiosInstance = axios
) => {
  return axiosInstance.post(serviceUrls().enrichmentCandidate, data, config);
};

export const updateJobArchiveStatus = (
  data,
  config = null,
  axiosInstance = axios
) => {
  return axiosInstance.post(serviceUrls().adminJobArchival, data, config);
};

export const updateCandidateTags = (
  data,
  config = null,
  axiosInstance = axios
) => {
  return axiosInstance.post(serviceUrls().updateSkillsWorkTags, data, config);
};

export const updateCandidatePersonalInfo = (
  data,
  config = null,
  axiosInstance = axios
) => {
  return axiosInstance.post(serviceUrls().updateCandidatePInfo, data, config);
};
export const updateCandidateRanking = (
  data,
  config = null,
  axiosInstance = axios
) => {
  return axiosInstance.post(serviceUrls().candidateUpdateRank, data, config);
};

export const updateCandidateProfileData = (
  data,
  config = null,
  axiosInstance = axios
) => {
  return axiosInstance.post(serviceUrls().updateSourcedProfileData, data, config);
};


export const adminUploadCandidateCSV = (
  data,
  config = null,
  axiosInstance = axios
) => {
  return axiosInstance.post(serviceUrls().adminUploadCandidateData, data, config);
};

export const adminSaveCandidateData = (
  data,
  config = null,
  axiosInstance = axios
) => {
  return axiosInstance.post(serviceUrls().adminSaveCandidateData, data, config);
};


// Calender Events Setup
export const setupCalenderEvent = (
  data,
  config = null,
  axiosInstance = axios
) => {
  return axiosInstance.post(serviceUrls().calenderEventSetupApi, data, config);
};
export const fetchCalenderEvents = (
  data,
  config = null,
  axiosInstance = axios
) => {
  return axiosInstance.post(serviceUrls().calenderAllEvents, data, config);
};

export const fetchCandidatePanelDetails = (
  data,
  config = null,
  axiosInstance = axios
) => {
  return axiosInstance.post(serviceUrls().candidatePanelDetails, data, config);
};

export const fetchCandidateFeedback = (
  data,
  config = null,
  axiosInstance = axios
) => {
  return axiosInstance.post(serviceUrls().candidateCollectFeedback, data, config);
};

export const saveCandidateFeedback = (
  data,
  config = null,
  axiosInstance = axios
) => {
  return axiosInstance.post(serviceUrls().candidateUpdateFeedback, data, config);
};

export const scheduleCandidateForInterview = (
  data,
  config = null,
  axiosInstance = axios
) => {
  return axiosInstance.post(serviceUrls().candidateScheduleInterview, data, config);
};


export const editJob = (
  data,
  config = null,
  axiosInstance = axios
) => {
  return axiosInstance.post(serviceUrls().editJob, data, config);
};


export const fetchCandidateTimeline = (
  data,
  config = null,
  axiosInstance = axios
) => {
  return axiosInstance.post(serviceUrls().candidateCollectTimeline, data, config);
};