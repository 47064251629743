import React, { useState } from 'react';
import {useToast, Popover, PopoverTrigger, PopoverContent, Text,
  PopoverArrow, PopoverCloseButton, PopoverBody, PopoverFooter, Tooltip, IconButton, useDisclosure, Select,
} from '@chakra-ui/react';
import { MdMoveUp } from 'react-icons/md';
import { isObjectEmpty, workflowStagesMapping } from '../../../../utils/common';
import { getEmailId } from '../../../../utils/localStorageIndex';
import { candidateKanbanStageUpdate, jobWorkflowAdmSelectCandidate, jobWorkflowAdmSourceCandidate } from '../../../../services/jobTemplateService';


export default function MoveCandidateToOtherStage(props) {
  const toast = useToast()
  const emailId = getEmailId()
  const {bulkSelectedCandidates, postMoveActions, job, adminSettings} = props
  let candidate = {}
  if (bulkSelectedCandidates?.length > 0){
    candidate = bulkSelectedCandidates[0]
  }
  const { onToggle: onMoveConfirmationToggle } = useDisclosure()
  const [isMovingCandidate, setIsMovingCandidate] = useState(false);

  const moveCandidateStage = (newWorkflowPosition) => {

    // only validate if single candidate is selected without checkbox checked
    if (bulkSelectedCandidates.length == 0 && newWorkflowPosition == candidate?.workflow_position){
      toast({
        title: `Candidate is already in ${newWorkflowPosition} stage`,
        status: "error",
        duration: 3000
      })
      return
    }

    if (newWorkflowPosition == 'candidate_sourced'){
      moveCandidateToSourcing()
      return
    }

    if (newWorkflowPosition == 'candidate_screening'){
      moveCandidateToScreening()
      return
    }

    if (newWorkflowPosition == 'candidate_rejected'){
      props.onRejectionPanelOpen()
      return
    }

    const obj = {
      email: emailId,
      job_uuid: job?.job_uuid,
      candidate_uuid: bulkSelectedCandidates?.map(candidate => candidate?.candidate_uuid),
      job_workflow_position: newWorkflowPosition,
    };

    setIsMovingCandidate(true)
    candidateKanbanStageUpdate(obj)
      .then(res => {
        if (res?.result) {
            // call any post move functions
            for (const [fnName, fn] of Object.entries(postMoveActions)) {
              fn()
            }
            onMoveConfirmationToggle()
            toast({
              title: `Candidate moved successfully to ${newWorkflowPosition}`,
              description: res?.message,
              status: "success",
              duration: 3000
            })
        }else{
          toast({
            title: `Candidate did not move to ${newWorkflowPosition}`,
            description: res?.message,
            status: "error",
            duration: 3000
          })
        }

        setIsMovingCandidate(false)
      })
      .catch(err => {
        setIsMovingCandidate(false)
        toast({
          title: `Candidate did not move successfully ${newWorkflowPosition}`,
          description: err.toString(),
          status: "error",
          duration: 3000
        })
      });
  }

  const moveCandidateToSourcing = () => {
    let objBody = {
      email: emailId,
      job_uuid: job?.job_uuid,
      candidate_uuid: bulkSelectedCandidates?.map(candidate => candidate?.candidate_uuid),
    };
    setIsMovingCandidate(true)
    jobWorkflowAdmSelectCandidate(objBody, adminSettings)
      .then(res => {
        if (res?.result) {
          // call any post move functions
          for (const [fnName, fn] of Object.entries(postMoveActions)) {
            fn()
          }
          onMoveConfirmationToggle()
          toast({
            title: 'Candidate moved successfully to sourcing stage.',
            description: res?.message,
            status: 'success',
            duration: 3000,
          });
        } else {
          toast({
            title: 'Candidate did not move to sourcing stage.',
            description: res?.message,
            status: 'error',
            duration: 3000,
          });
        }

        setIsMovingCandidate(false)
      })
      .catch(err => {
        setIsMovingCandidate(false)
        toast({
          title: 'Something went wrong',
          description: err.toString(),
          status: 'error',
          duration: 3000,
        });
      });
  };

  const moveCandidateToScreening = () => {
    let obj = {
      email: emailId,
      job_uuid: job?.job_uuid,
      candidate_uuid: bulkSelectedCandidates?.map(candidate => candidate?.candidate_uuid),
    };
    
    setIsMovingCandidate(false)
    jobWorkflowAdmSourceCandidate(obj, adminSettings)
      .then(res => {
        if (res?.result) {
          // call any post move functions
          for (const [fnName, fn] of Object.entries(postMoveActions)) {
            fn()
          }
          onMoveConfirmationToggle()
          toast({
            title: res?.message,
            status: 'success',
            duration: 6000,
          });

          setIsMovingCandidate(false)
        } else {
          toast({
            title: 'Candidate did not move to screening stage',
            description: res?.message,
            status: 'error',
            duration: 3000,
          });
        }
      })
      .catch(err => {
        setIsMovingCandidate(false)
        toast({
          title: "Something went wrong",
          description: err.toString(),
          status: 'error',
          duration: 3000,
        });
      });
  };

  const isCurrentStageSelected = (stageName) => {
    return stageName == candidate?.workflow?.workflow_position
  }

  const isRejectionDisabled = (stageName) => {
    if (candidate?.workflow?.workflow_position  == 'candidate_sourced' && stageName == 'candidate_rejected'){
      return true
    }
    return false
  }

  return (
        <Popover placement='bottom'>
              <Tooltip label={'Move Candidates'} fontSize='md'>
                <span>
                  <PopoverTrigger>
                    <IconButton isLoading={isMovingCandidate} isDisabled={bulkSelectedCandidates?.length > 1 || isObjectEmpty(candidate)} onClick={onMoveConfirmationToggle} bg={'none'} _hover={{'bg': 'none'}} icon={<MdMoveUp />} color={'gray'} fontSize={'18px'} />
                  </PopoverTrigger>
                </span>
              </Tooltip>
            
            <PopoverContent fontSize={'16px'} width={'350px'} color='white' bg='blue.800' borderColor='blue.800'>
                <PopoverArrow bg='blue.800' />
                <PopoverCloseButton />
                <PopoverFooter flexDirection={'column'} border='0' display='flex' alignItems='center' justifyContent='space-between' pb={4}>
                  <Text mb={5}>Move candidate to other stage</Text>
                  <Select ml={'10px'} fontWeight={'500'} width={'200px'} height={'30px'} mt={'0 !important'} onChange={(e) => moveCandidateStage(e.target.value)}>
                    <option disabled selected>Select target stage</option>
                    {Object.keys(workflowStagesMapping)?.map(stageName => {
                      return <option selected={isCurrentStageSelected(stageName)} 
                                    disabled={isCurrentStageSelected(stageName) || isRejectionDisabled(stageName)} value={stageName}
                                    style={{'color': isCurrentStageSelected(stageName) || isRejectionDisabled(stageName) ? 'gray' : 'black'}}
                                  >
                              {workflowStagesMapping[stageName]}
                            </option>
                    })}
                  </Select>
                </PopoverFooter>
            </PopoverContent>
        </Popover>
    )
}
