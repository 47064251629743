import React, { useEffect, useState } from 'react';
import {Text, Flex, Button, Spacer, useColorModeValue, IconButton, Select, Box, Textarea, ListItem,
        Spinner, Link, Input, Tooltip, useToast, Checkbox, Radio, RadioGroup, Switch, List, Icon, Image
} from '@chakra-ui/react';
import { DeleteIcon, ExternalLinkIcon, QuestionOutlineIcon } from '@chakra-ui/icons'
import { BsCheck2, BsCheckLg, BsLinkedin, BsPencil, BsPlusLg, BsXLg } from 'react-icons/bs';
import { fetchDefaultQuestions, fetchJobQuestions } from '../../services/questionServices';
import { getEmailId } from '../../utils/localStorageIndex';
import { isObjectEmpty, jobApplyStandardFields, toTitleCase } from '../../utils/common'
import { BiArrowBack } from 'react-icons/bi';
import { useLocation, useNavigate } from 'react-router-dom';
import IndeedLogo from '../../../src/image/indeed_logo.webp'

export default function JobApplicationForm(props) {
    const toast = useToast()
    const navigate = useNavigate()
    const {state} = useLocation()
    const {adminSettings=false, questionErrors} = props;
    const [isDefaultQuestionLoading, setIsDefaultQuestionLoading] = useState(false);
    const [isJobQuestionLoading, setIsJobQuestionLoading] = useState(false);
    const [defaultQuestions, setDefaultQuestions] = useState(
        [
            {
                "question_text": "Do you have a valid U.S. Drivers License?",
                "question_type": {'code': "boolean", 'value': 'Yes / No'},
                "question_options": []
            },
            {
                "question_text": "Do you now or in the future require any sponsorship?",
                "question_type": {'code': "boolean", 'value': 'Yes / No'},
                "question_options": []
            },
            {   
                "question_text": "Do you have any applicable certifications?",
                "question_type": {'code': "boolean", 'value': 'Yes / No'},
                "question_options": []
            }
        ]
    )
    const [formQuestions, setFormQuestions] = useState([])

    const [selectedJob, setSelectedJob] = useState({})
    const borderColorStyle = useColorModeValue('gray.200', 'gray.400'); // {light, dark}
    const [jobApplyURL, setJobApplyURL] = useState()

    const disqualifyQuestionTypes = ['boolean', 'number', 'multiplechoice']
    const numberFilters = ['less than', 'greater than', 'equal to']
    // {question_index: {greater than: {isChecked: true, value: 1}, less than: {isChecked: false, value: 10}}}
    const [numberDisqualifyFilterValues, setNumberDisqualifyFilterValues] = useState({})

    const customFieldTypes = [
                                {code: 'subjective', value: 'Text'},
                                {code: 'number', value: 'Numerical'},
                                {code: 'boolean', value: 'Yes / No'},
                                {code: 'multiplechoice', value: 'Multiple Choice'},
                                {code: 'file', value: 'File Upload'}
                            ]

    useEffect(() => {
        if (!isObjectEmpty(props?.job)){
            setSelectedJob(props.job)
        }
    }, [props?.job])

    useEffect(() => {
        if(!isObjectEmpty(selectedJob)){
            // loadDefaultQuestions();
            loadJobQuestions();
            setJobApplyURL(`https://candidateapplication.com/job?id=${selectedJob?.job_public_url}`)
        }
    }, [selectedJob])

    useEffect(() => {
        updateDefaultQuestionsCheckUncheck();
        props.setScreeningQuestions(formQuestions)
        updateNumberDisqualifyFilterValues()
    }, [formQuestions])

    const loadDefaultQuestions = () => {
        fetchDefaultQuestions({'user_email': getEmailId()})
        .then( res => {
            setDefaultQuestions([...res])
            setIsDefaultQuestionLoading(false);
        })
        .catch( err =>  {
            toast({
                title: 'Unable to get default questions', 
                description: err.toString(),
                status: 'error',
                duration: 3000
            });
            setIsDefaultQuestionLoading(false);
        })
    }

    const loadJobQuestions = () => {
        fetchJobQuestions({'user_email': getEmailId(), 'job_uuid': selectedJob.job_uuid})
        .then( res => {
            setFormQuestions([...res]);
            setIsJobQuestionLoading(false);
        })
        .catch( err =>  {
            toast({
                title: 'Unable to get job questions', 
                description: err.toString(),
                status: 'error',
                duration: 3000
            });
            setIsJobQuestionLoading(false);
        })
    }

    const isQuestionExistInForm = (question) => {
        let exist = false;
        formQuestions.forEach(formQuestion => {
            if (formQuestion.question_text == question.question_text){
                exist = true;
            }
        })

        return exist
    }

    async function copyTextToClipboard(text) {
        return await navigator.clipboard.writeText(text);
    }

    const routeToJobCandidateList = () => {
        navigate('/admin/jobcandidatelist', {
            state: {
            job: selectedJob,
            business: state?.business,
            adminSettings: 'true',
            },
        });
    };

    const updateDefaultQuestionsCheckUncheck = () => {
        const updatedDefaultQuestions = defaultQuestions.map(defaultQuestion => {
            defaultQuestion.is_added_to_form = false;
            if (isQuestionExistInForm(defaultQuestion)){
                defaultQuestion.is_added_to_form = true;
            }
            return defaultQuestion;
        })

        setDefaultQuestions([...updatedDefaultQuestions])
    }

    const updateNumberDisqualifyFilterValues = () => {
        if (numberDisqualifyFilterValues?.length > 0){
            return
        }

        const values = []
        formQuestions?.map((question, index) => {
            if (question?.question_disqualify_filters?.length > 0){
                values[index] = Object.values(question?.question_disqualify_filters[0])[0]
            }
        })
        setNumberDisqualifyFilterValues([...values])
    }

    const addDefaultQuestionToForm = (question) => {
        question.question_disqualify_filters = []
        if (question?.question_type?.code == 'number'){
            // {greater than : {isChecked: false, value: null}, less than : {isChecked: false, value: null}}
            const filters = {}
            numberFilters?.map(filterName => {
                filters[filterName] = {isChecked: false, value: null}
            })
            question.question_disqualify_filters = filters
        }
        setFormQuestions([...formQuestions, question])
    }

    const addCustomQuestionToForm = () => {
        const newQuestion = {
            question_placeholder: 'Try asking a question like,  "How many years of experience do you have?"',
            question_text: null,
            is_custom: true,
            question_type: {code: 'subjective', value: 'Text'},
            question_options: [],
            question_disqualify_filters: [],
        }
        setFormQuestions([...formQuestions, newQuestion])
    }

    const removeQuestionFromForm = (index) => {
        const updatedFormQuestions = formQuestions;
        updatedFormQuestions.splice(index, 1)
        setFormQuestions([...updatedFormQuestions]);
    }

    const onChangeCustomQuestionResponseType = (index, responseType) => {
        const newFormQuestions = formQuestions
        newFormQuestions[index].question_type.code = responseType
        newFormQuestions[index].question_disqualify_filters = [] 
        if (responseType == 'number'){
            // {greater than : {isChecked: false, value: null}, less than : {isChecked: false, value: null}}
            const filters = {}
            newFormQuestions[index].question_disqualify_filters = numberFilters?.map(filterName => {
                filters[filterName] = {isChecked: false, value: null}
            })
            newFormQuestions[index].question_disqualify_filters = filters
        }        
        
        setFormQuestions([...newFormQuestions])
    }

    const onChangeCustomQuestionText = (index, text) => {        
        const newFormQuestions = formQuestions
        newFormQuestions[index].question_text = text
        setFormQuestions([...newFormQuestions])
        const errors = questionErrors
        delete errors[index]
        props.setQuestionErrors({...errors})
    }

    const addQuestionDisqualifyFilter = (index, filter) => {
        const newFormQuestions = formQuestions
        const question = newFormQuestions[index]
        const filters = [...new Set([...question.question_disqualify_filters, filter])]
        newFormQuestions[index]['question_disqualify_filters'] = filters
        setFormQuestions([...newFormQuestions])
    }

    const removeQuestionDisqualifyFilter = (index, filterName) => {
        const newFormQuestions = formQuestions
        const question = newFormQuestions[index]
        const filters = question.question_disqualify_filters?.filter(filter => filter != filterName)
        newFormQuestions[index]['question_disqualify_filters'] = filters
        setFormQuestions([...newFormQuestions])
    }

    const addQuestionDisqualifyFilterForNumber = (index, filterName) => {
        // [{"greater than": 5}, {"less than": 10}]
        const newFormQuestions = formQuestions
        const question = newFormQuestions[index]
        const filters = question?.question_disqualify_filters
        
        Object.keys(filters)?.map( key => {
            if (key == filterName){
                filters[key]['isChecked'] = true
            }
        })
        newFormQuestions[index]['question_disqualify_filters'] = filters
        setFormQuestions([...newFormQuestions])
    }

    const addValueForQuestionDisqualifyFilterForNumber = (index, filterName, filterValue) => {
        // {index: {'greater than': {isChecked: true, value: 1}, 'less than': {isChecked: true, value: 1}, 'equal to': {isChecked: true, value: 1}}}
        const newFormQuestions = formQuestions
        const question = newFormQuestions[index]
        const filters = question?.question_disqualify_filters
        console.log(question)
        Object.keys(filters)?.map( key => {
            if (key == filterName){
                filters[key]['value'] = filterValue
            }
        })
        console.log(filters)
        newFormQuestions[index]['question_disqualify_filters'] = filters
        const updatedNumberDisqualifyFilterValues = numberDisqualifyFilterValues
        updatedNumberDisqualifyFilterValues[index] = filters
        setNumberDisqualifyFilterValues(updatedNumberDisqualifyFilterValues)
        setFormQuestions([...newFormQuestions])
    }

    const removeQuestionDisqualifyFilterForNumber = (index, filterName) => {
        const newFormQuestions = formQuestions
        const question = newFormQuestions[index]
        const filters = question?.question_disqualify_filters
        
        Object.keys(filters)?.map( key => {
            if (key == filterName){
                filters[key]['isChecked'] = false
            }
        })
        newFormQuestions[index]['question_disqualify_filters'] = filters
        setFormQuestions([...newFormQuestions])
    }

    const onChangeIsQuestionMandatory = (index) => {
        const newFormQuestions = formQuestions
        newFormQuestions[index].question_is_mandatory = !newFormQuestions[index].question_is_mandatory
        setFormQuestions([...newFormQuestions])
    }

    const setEditAnswersForMultipleChoice = (index) => {
        const updatedFormQuestions = formQuestions
        updatedFormQuestions[index].isEditAnswers = true;
        setFormQuestions([...updatedFormQuestions])
    }

    const setDisableAnswersForMultipleChoice = (index) => {
        const updatedFormQuestions = formQuestions
        updatedFormQuestions[index].isEditAnswers = false;
        setFormQuestions([...updatedFormQuestions])
    }

    const addOptionInputForMultipleChoice = (index) => {
        const updatedFormQuestions = formQuestions
        updatedFormQuestions[index].question_options = [... new Set([...updatedFormQuestions[index].question_options, ""])]
        setFormQuestions([...updatedFormQuestions])
    }

    const removeOptionInputForMultipleChoice = (questionIndex, optionIndex) => {
        const updatedFormQuestions = formQuestions
        if (updatedFormQuestions[questionIndex].question_options_count || updatedFormQuestions[questionIndex].question_options_count >= 0){
            updatedFormQuestions[questionIndex].question_options_count = updatedFormQuestions[questionIndex].question_options_count - 1
        }
        
        updatedFormQuestions[questionIndex].question_options.splice(optionIndex, 1)
        setFormQuestions([...updatedFormQuestions])
    }

    const onChangeOptionValueForMultipleChoice = (questionIndex, optionIndex, optionValue) => {
        const updatedFormQuestions = formQuestions
        if (optionValue){
            updatedFormQuestions[questionIndex].question_options[optionIndex] = optionValue
        }else{
            updatedFormQuestions[questionIndex].question_options.splice(optionIndex, 1)
        }

        setFormQuestions([...updatedFormQuestions])
    }

    const isDisqualifyNumberFilterChecked = (index, filterName) => {
        let isChecked = false;
        const filters = formQuestions[index]?.question_disqualify_filters
        Object.keys(filters)?.map(key => {
            if (key == filterName && filters[key].isChecked){
                isChecked = true
            }
        })

        return isChecked
    }

    const disqualifyNumberFilterValue = (index, filterName) => {
        let value = "";
        const filters = formQuestions[index]?.question_disqualify_filters
        Object.keys(filters)?.map(key => {
            if (key == filterName){
                value = filters[key].value
            }
        })

        return value
    }

    const renderOptionInputs = (question, index) => {
        if (question.question_type.code != 'multiplechoice' || !question.isEditAnswers){
            return null
        }

        const optionBoxes = question.question_options.map( (optionValue, optionIndex) => {
            return (
                <Flex mt={2}>
                    <Input
                        placeholder={'Enter answer text'}
                        value={optionValue}
                        onChange={(e) => onChangeOptionValueForMultipleChoice(index, optionIndex, e.target.value)}
                    />
                    <IconButton 
                        width={'30px'}
                        onClick={() => removeOptionInputForMultipleChoice(index, optionIndex)}
                        icon={<DeleteIcon />}
                    />
                </Flex>
            )
        })
        
        return optionBoxes
    }

    const RenderAutoDisqualifyFilterSelection = (question, index) => {
        if (question.question_type.code == 'boolean'){
            return  <Flex direction={'column'} mr={5} mt={2}>
                        <Text mb={1}>Select ideal answer</Text>   
                        <RadioGroup mt={2} value={question?.question_disqualify_filters?.length == 1 && question?.question_disqualify_filters[0]}>
                        <Flex >
                            <Text mr={2}>Yes</Text>                           
                            <Radio size={'lg'} name={`boolean-${index}`} value={'yes'} 
                                onChange={(e) => {
                                    addQuestionDisqualifyFilter(index, 'yes')
                                    removeQuestionDisqualifyFilter(index, 'no')
                                }}
                            />

                            <Text mr={2} ml={'5'}>No</Text>
                            <Radio size={'lg'} name={`boolean-${index}`} value={'no'}
                                onChange={(e) => {
                                    addQuestionDisqualifyFilter(index, 'no')
                                    removeQuestionDisqualifyFilter(index, 'yes')
                                }}
                            />
                        </Flex>
                        </RadioGroup>
                    </Flex>                 
        }
        if (question.question_type.code == 'multiplechoice'){
            return <Flex mt={2} direction={'column'}>
                <Text mb={1}>Select ideal answers</Text>
                {question.question_options?.filter(option => option != "")?.map(option => {
                    return <Flex mr={5} mt={2}>                        
                        <Checkbox size={'lg'} isChecked={question?.question_disqualify_filters?.includes(option)} 
                            onChange={(e) => {
                            if (e.target.checked){
                                addQuestionDisqualifyFilter(index, option)
                            }else{
                                removeQuestionDisqualifyFilter(index, option)
                            }
                        }} />
                        <Text ml={4}>{option}</Text>
                    </Flex>
                })}                        
                </Flex>
        }
        if (question.question_type.code == 'number'){
            return <Flex mt={5} direction={'column'}>
                    <Text mb={1}>Select ideal answers</Text>
                    {numberFilters.map(option => {
                        return <Flex mr={5} mt={2} alignItems={'center'}>
                            <Text width={'110px'}>{toTitleCase(option)}</Text>
                            <Input type='number' mr={3} value={disqualifyNumberFilterValue(index, option)} width={'180px'}
                                onChange={(e) => addValueForQuestionDisqualifyFilterForNumber(index, option, e.target.value)}
                            />
                            <Checkbox size={'lg'} isChecked={isDisqualifyNumberFilterChecked(index, option)} 
                                onChange={(e) => {
                                if (e.target.checked){
                                    addQuestionDisqualifyFilterForNumber(index, option)
                                }else{
                                    removeQuestionDisqualifyFilterForNumber(index, option)
                                }
                            }} />
                        </Flex>
                    })}             
                </Flex>
        }
    }

    const isAutoDisqualifyHidden = (question) => {
        if (!disqualifyQuestionTypes.includes(question?.question_type?.code) || !question?.question_is_mandatory){
            return true
        }

        if (question?.question_type?.code == 'multiplechoice' && question?.question_options?.length == 0 ){
            return true
        }

        return false

    }

    const renderFormQuestions = () => {

        if(isJobQuestionLoading){
            return (
                <Flex direction={'column'} justifyContent={'center'} alignItems={'center'}>
                    <Spinner />
                </Flex>
            )
        }

        return (
            <Flex direction={'column'} fontSize={'16px'}>
                {formQuestions.map( (question, index) => {
                return (
                    <Flex mt={5} width={'100%'} key={`${question}-${index}`} p={'15px 10px'}
                        borderWidth={'0.1px'} borderColor={borderColorStyle} borderRadius={'6px'}
                        boxShadow={'0 3px 3px 0 lightgray, 0 1px 2px 0 lightgray'}
                    >
                        <Flex width={'75%'} direction={'column'}>
                            <Flex>
                                {question.is_custom || !question.is_default ?
                                    <Textarea width={'100%'} rows={2} placeholder={question.question_placeholder}
                                        value={question.question_text}
                                        onChange={(e) => onChangeCustomQuestionText(index, e.target.value)}
                                    />
                                :
                                    <Text as='b' fontSize={'18px'}>{question.question_text}</Text>
                                }
                            </Flex>

                            <Flex>
                                <Flex width={'50%'} direction={'column'}>
                                    
                                    <Text mt={5} fontSize={'18px'}>Response type</Text>
                                    {question.is_custom || !question.is_default ?
                                        <Select width={'200px'} onChange={(e) => onChangeCustomQuestionResponseType(index, e.target.value)}>
                                            {customFieldTypes.map( (questionType, index) => {
                                                return <option
                                                            selected={questionType.code == question.question_type.code}
                                                            key={`custom-question-${index}`} 
                                                            value={questionType.code}
                                                        >
                                                        {questionType.value}
                                                        </option>
                                            })}
                                        </Select>
                                    :
                                        <Flex alignItems={'center'}>
                                            <Select width={'200px'}  disabled  mr={2}>
                                                <option 
                                                    value={question.question_type.code}
                                                >
                                                {question.question_type.value}
                                                </option>
                                            </Select>
                                            <Tooltip label='Default question cannot be edited. If you need to edit this, you can add a new custom question from the left side bar and remove this one using X button' fontSize='md'>
                                                <QuestionOutlineIcon />
                                            </Tooltip>
                                        </Flex>
                                    }
                                    
                                    {question.question_type.code == 'multiplechoice' ? 
                                        <Box mt={5}>
                                            <List>
                                                {
                                                    question.question_options.map( (option, index) => {
                                                        return (
                                                            option ? <ListItem key={`question-option-${index}`}>
                                                                <Flex>
                                                                    <Checkbox isChecked={false} mr={2} /><Text>{option}</Text>
                                                                </Flex>
                                                            </ListItem> : null
                                                        )
                                                    })
                                                }
                                            </List>
                                        </Box>
                                    :
                                        null
                                    }
                                </Flex>

                                <Flex pt={50} direction={'column'}>
                                    {question.question_type.code == 'multiplechoice' && !question.is_default ?
                                        <Flex alignItems={'center'}>

                                            <Button hidden={question.isEditAnswers} variant={'ghost'} fontWeight={'400'} rightIcon={<BsPencil />}
                                                isDisabled={question.isEditAnswers}
                                                onClick={() => {
                                                                setEditAnswersForMultipleChoice(index); 
                                                                addOptionInputForMultipleChoice(index)
                                                            }}
                                            >Edit Answers</Button>
                                            
                                            <Button variant={'ghost'} fontWeight={'400'} rightIcon={<BsCheck2 fontSize={'26px'} />}
                                                hidden={!question.isEditAnswers}
                                                onClick={() => {
                                                                setDisableAnswersForMultipleChoice(index); 
                                                            }}
                                            >Edit Answers</Button>
                                            
                                            {question.isEditAnswers ?
                                                <IconButton ml={5} width={'30px'} icon={<BsPlusLg />}
                                                    onClick={() => addOptionInputForMultipleChoice(index)}                                                    
                                                />
                                                :
                                                null
                                            }
                                            
                                        </Flex>
                                        
                                        :
                                        null
                                    }

                                    {renderOptionInputs(question, index)}
                                </Flex>
                            </Flex>

                            <Flex hidden={isAutoDisqualifyHidden(question)} mt={8} mb={5} direction={'column'}>
                                {/* <Flex alignItems={'center'}>
                                    <Text mr={2}>Filter out and send rejection email to applicants who don't meet the ideal answers.</Text>
                                    <Tooltip label='This will only effect candidates applying in the future. It does not qualify or disqualify candidates if they have already applied in the past.' fontSize='md'>
                                        <QuestionOutlineIcon />
                                    </Tooltip>
                                </Flex> */}
                                {RenderAutoDisqualifyFilterSelection(question, index) }
                            </Flex>

                            <Text color="red">{questionErrors[question.question_uuid]}</Text>
                            <Text color="red">{questionErrors[question.question_text]}</Text>
                            <Text color="red">{questionErrors[index]}</Text>
                        </Flex>
                        
                        <Spacer />

                        <Flex direction={'column'} width={'200px'} justifyContent={'right'}>
                            <Flex justifyContent={'right'} alignItems={'center'} hidden={question?.is_default || !disqualifyQuestionTypes?.includes(question?.question_type?.code)}>
                                <Switch isChecked={question.question_is_mandatory} onChange={(e) => onChangeIsQuestionMandatory(index)}/>
                                <Text ml={2}>Must-have question</Text>
                            </Flex>
                            <Flex justifyContent={'right'} mt={5}>
                                <IconButton  width={'50px'} onClick={() => removeQuestionFromForm(index)} icon={<BsXLg />} />
                            </Flex>
                        </Flex>
                    </Flex>   
                )
            })}
            </Flex>
        )
    }

    const renderDefaultQuestions = () => {
        if(isDefaultQuestionLoading){
            return (
                <Flex direction={'column'} justifyContent={'center'} alignItems={'center'}>
                    <Spinner />
                    <Text>Loading default questions</Text>
                </Flex>
            )
        }
        
        return (
            defaultQuestions.map( (question, index) => {
                return (
                    <Box mt={5} float={'left'} key={`${question}-${index}`}>
                        <Button width={'100%'} style={{'text-wrap': 'wrap'}} fontWeight={'400'} leftIcon={question.is_added_to_form ? <BsCheckLg /> : <BsPlusLg />}
                            variant={'outline'} height={'30px'}
                            isDisabled={question.is_added_to_form ? true : false}
                            onClick={() => addDefaultQuestionToForm(question)}
                        >
                            {question.question_text}
                        </Button>
                    </Box>
                )
            })
        )
    }

    const StandardQuestions = () => {
        if (!adminSettings){
            return null
        }

        return jobApplyStandardFields?.map( (question, index) => {
              return (
                <Flex
                  width={'100%'}
                  key={index}
                  p={'15px 10px'}
                  direction={'column'}
                  mt={3}
                >
                  <Flex>
                    {question.is_required && <Text color={'red'} mr={1}>*</Text> }
                    <Text as='b' mb={1} fontSize={'18px'}>{question.title}</Text>
                  </Flex>
    
                  {question.type == 'text' ?
                    <Flex alignItems={'center'}>
                        <Input disabled width={'400px'} />
                    </Flex>
                    : null 
                  }
    
                  {question.type == 'file' ?
                    <Flex alignItems={'center'}>
                        <Input borderRadius={0} pl={0} border={0} disabled type={'file'} />
                    </Flex>
                    : null 
                }
                
                </Flex>
              )
            })  
    }

    if (isObjectEmpty(selectedJob)){
        return <Flex></Flex>
    }

    return (
        <Flex direction={'column'} pt={{ sm: '30px', lg: '0px' }} w={{'2xl': '90%'}}>

            <Flex alignItems={'center'}>
                <Text fontSize={'20px'} as={'b'}>{selectedJob.job_title}</Text>
                <Spacer/>
                <Tooltip label={'Copy Job Apply URL for Linkedin'} fontSize='md'>
                    <span hidden={!adminSettings}>
                        <Icon mt={'10px'} mr={5} fontSize={'18px'} as={BsLinkedin} color={'blue.400'} _hover={{'cursor': 'pointer'}}
                            onClick={() => {
                                copyTextToClipboard(`${jobApplyURL}&source=linkedin`).then(
                                    toast({
                                        title: "Job Apply URL copied to clipboard for Linkedin!",
                                        status: "success",
                                        duration: 3000
                                    })
                                )
                            }}
                        />
                    </span>
                </Tooltip>
                <Tooltip label={'Copy Job Apply URL for Indeed'} fontSize='md'>
                    <span hidden={!adminSettings}>
                    <Flex mt={'3px'} width={'21px'} height={'21px'} alignItems={'center'} mr={5} _hover={{'cursor': 'pointer'}}
                        onClick={() => {
                            copyTextToClipboard(`${jobApplyURL}&source=indeed`).then(
                                toast({
                                    title: "Job Apply URL copied to clipboard for Indeed!",
                                    status: "success",
                                    duration: 3000
                                })
                            )
                        }}
                    >
                        <Image
                            borderRadius='full'
                            boxSize='21px'
                            objectFit={'contain'}
                            src={IndeedLogo}
                            />
                        </Flex>
                    </span>
                </Tooltip>
                <Tooltip label={'Preview Job Apply Page on canddiateapplication.com'} fontSize='md'>
                    <span>
                    <Link mr={5} fontSize={'md'} isExternal href={jobApplyURL}>
                        <ExternalLinkIcon fontSize={'21px'} />
                    </Link>
                    </span>
                </Tooltip>
                <Button hidden={!adminSettings} height={8} onClick={routeToJobCandidateList} fontWeight={'normal'} variant={'outline'}>
                    <Icon as={BiArrowBack} mr={2} />Back to Candidate List
                </Button>
            </Flex>

            <Flex mt={8} direction={{ base: 'column', 'xl': 'row' }} fontSize={'16px'}>
                {/* left side bar with default and custom question boxes */}
                <Flex width={{base: '100%', xl: '40%'}} mt={5} direction={'column'}
                    borderRight={{xl: '1px solid'}} borderRightColor={{xl: 'gray.200'}}
                    height={'500px'} pr={{ base: 0, xl: 10}}
                > 

                    <Text mb={3}>Anything else you'd like us to screen for</Text>
                    
                    {renderDefaultQuestions()}
                    
                    <Button fontWeight={'400'}  mt={5} width={'190px'} leftIcon={<BsPlusLg />} variant={'outline'}
                    onClick={addCustomQuestionToForm} height={'30px'}
                    >
                        Custom Question
                    </Button>
                </Flex>

                {/* right side bar with generated form and questions */}
                <Flex pl={{xl: '30px'}} mt={{ base: '20px' }} width={{ base: '100%', xl: '60%'}} direction={'column'}>
                    <Text>These screening questions will be asked from candidates for the <b>{selectedJob?.job_title}</b> position.</Text>                    
                    <StandardQuestions />

                    {renderFormQuestions()}
                </Flex>
            </Flex>
        </Flex>
    )
}
