export const serviceUrls = (urlVar = null) => {
  return {
    loginApi: `/v1/api/user/login`,
    tokenApi: `/v1/api/token`,
    dummyInfo: `/v1/api/utils/info`,
    updateInfo: `/v1/api/user/update_info`,
    changePassword: `/v1/api/user/forgot_password`,
    updatePassword: `/v1/api/user/update_password`,
    updateDefaultPassword: `/v1/api/update_default_password`,
    logoutApi: `/v1/api/user/logout`,
    enableDisableUser: `/v1/api/user/enable-disable`,
    resetEmailPassword: `/v1/api/reset_password`,
    userInfo: `/v1/api/user/personal_info`,
    setNotificationAsRead: `/v1/api/notifications/mark-read`,
    userInfoEx: `/v1/api/user/personal_info_ex`,
    uploadHeadshot: `/v1/api/user/update_headshot`,
    getHeadshot: `/v1/api/user/get_headshot`,
    // Admin Urls
    fetchBusinessesListUrl: `/v1/api/admin/businesses_list`,
    fetchBusinessHrManagers: `/v1/api/admin/business_users`,
    fetchTsTeamListingUrl: `/v1/api/admin/team_listing`,
    // bussiness urls
    addBasicBusinessUser: `/v1/api/business/register_basic_user`,
    addBusinessUser: `/v1/api/business/register_user`,
    addBusinessUserDomain: `/v1/api/business/register_with_domain`,
    businesseAdminStatus: `/v1/api/business/business_admin`,
    addBusiness: `/v1/api/business/add_business`,
    updateBusiness: `/v1/api/business/update_business`,
    fetchBusinessDetails: `/v1/api/business/details`,
    addBusinessAdmin: `/v1/api/business/register_n_invite`,
    inviteTeamMember: `/v1/api/business/invite_team_member`,
    fetchBusinessUserList: `/v1/api/business/active_users`,
    pageinatedAllBusinessUserList: `/v1/api/business/all_business_users`,
    // recruiter urls
    createRecruiter: `/v1/api/recruiter/create`,
    statusRecruiter: `/v1/api/recruiter/manage_status`,
    fetchRecruiter: `/v1/api/recruiter/get_recruiters`,
    // department url
    fetchDepartmentList: `/v1/api/business/department/departments`,
    addDepartmentDetails: `/v1/api/business/department/add_department`,
    updateDepartmentDetails: `/v1/api/business/department/update_department`,
    manageDepartmentStatus: `/v1/api/business/department/manage_department`,
    // jobtemplate url
    fetchJobTemplateList: `/v1/api/job/template/templates`,
    fetchJobTemplateInternal: `/v1/api/job/template/listinternal`,
    addJobTemplate: `/v1/api/job/template/create`,
    updateJobTemplate: `/v1/api/job/template/update`,
    manageJobTemplateStatus: `/v1/api/job/template/manage_status`,
    serchJobtemplate: `/v1/api/job/template/search`,
    jobWorkflowList: `/v1/api/business/job/listing`,
    activeJobsList: `/v1/api/business/job/active_listing`,
    fetchJobWorkflowData: `/v1/api/business/job/wizard_data`,

    // New Job Wizards
    jobWizardOneApi: `/v1/api/business/job/job_wizard_one`,
    jobWizardTwoApi: `/v1/api/business/job/job_wizard_two`,
    jobWizardThreeApi: `/v1/api/business/job/job_wizard_three`,
    jobWizardFourApi: `/v1/api/business/job/job_wizard_four`,
    jobWizardPdfData: `/v1/api/business/job/job_pdf_data`,
    getTextFromDocument: `/v1/api/business/job/get_text_from_document`,

    jobTitleWorkflow: `/v1/api/business/job/job_wizard_1`,
    jobTitleEditWorkflow: `/v1/api/business/job/job_wizard_1e`,
    jobSynopsisWorkflow: `/v1/api/business/job/job_wizard_2`,
    jobBenifitsWorkflow: `/v1/api/business/job/job_wizard_3`,
    jobLocationWorkflow: `/v1/api/business/job/job_wizard_4`,
    employeeTypeWorkflow: `/v1/api/business/job/job_wizard_5`,
    experienceWorkflow: `/v1/api/business/job/job_wizard_6`,
    employeeEducationWorkFlow: `/v1/api/business/job/job_wizard_7`,
    employeeQuotedAmount: `/v1/api/business/job/job_wizard_8_9`,
    employeeRateWorkflow: `/v1/api/business/job/job_wizard_10`,
    employeeSuplementPayWorkflow: `/v1/api/business/job/job_wizard_11`,
    submitSurveryWorkflow: `/v1/api/business/job/job_wizard_12`,
    viewJobData: `/v1/api/business/job/job_full_data`,
    viewJobDataPublic: `/v1/api/business/job/job_full_data_public`,
    submitJobApplication: `/v1/api/business/job/apply`,
    submitCandidateByResume: `/v1/api/job/candidate/add-by-resume`,
    submitCandidateByForm: `/v1/api/job/candidate/add-by-form`,
    submitCandidateProfileDetails: `/v1/api/job/candidate/edit-profile`,
    updateCandidateSkills: `/v1/api/job/candidate/update-skills`,
    generateMatchingSkillsWithAI: `/v1/api/job/candidate/generate-skills-with-ai`,
    searchCandidate: `/v1/api/job/candidate/search`,
    qualifyCandidate: `/v1/api/job/candidate/qualify`,
    allCandidates: '/v1/api/admin/job/all_candidates',
    getRecentPublicJobs: `/v1/api/business/job/recent`,
    getAllJobs: `/v1/api/business/job/all`,
    sendBulkEmail: `/v1/api/job/candidate/send-bulk-email`,
    uploadFile: `/v1/api/business/job/upload_file`,
    assignCandidate: `/v1/api/job/candidate/assign`,
    getCandidateDetails: `/v1/api/admin/job/candidate/details`,
    deleteCandidate: `/v1/api/job/candidate/delete`,
    automatedInformation: `/v1/api/business/job/update/automated_info`,
    additionalInformation: `/v1/api/business/job/update/additional_info`,
    updateJobDepartment: `/v1/api/business/job/update/department_info`,
    updateJobSpecification: `/v1/api/business/job/update/specifics`,
    jobWorflowStatus: `/v1/api/business/job/update/job_status`,
    fetchJobWorkFlowCount: `/v1/api/job/workflow/various_counts`,
    jobWorkflowStart: `/v1/api/job/workflow/start`,


    findCandidate: `/v1/api/job/candidate/find_candidates`,
    shortListCandidate: `/v1/api/job/workflow/shortlist_candidate`,
    fetchShortListedCandidate: `/v1/api/job/workflow/shortlisted_candidates`,

    // screeningCandidate: `/v1/api/job/workflow/start_screening`,

    rejectCandidate: `/v1/api/job/workflow/candidate_rejected`,
    fetchJobCandidateDetails: `/v1/api/job/candidate/full_info`,
    updateCandidateDetails: `/v1/api/job/workflow/update_candidate_details`,
    rejectedCandidate: `/v1/api/job/workflow/rejected_candidates`,

    inInterviewCandidate: `/v1/api/job/workflow/in_interview_candidates`,
    rejectedDetails: `/v1/api/job/workflow/why_rejection`,
    jobCompanyList: `/v1/api/admin/job/companies`,
    companyCandidateList: `/v1/api/admin/job/candidates`,
    companyLogosByDomain: `/v1/api/job/experience/get_companies_logo`,
    updateCompanyLogo: `/v1/api/job/experience/update_company_logo`,
    addCandidateExperience: `/v1/api/job/experience/add`,
    addCandidateEducation: `/v1/api/job/experience/education/add`,
    fetchJobByBusiness: `v1/api/admin/job/job_list`,
    partialUpdate: `/v1/api/business/job/partial_update`,
    createInterview: `/v1/api/job/workflow/create_interview`,
    fetchInterviewDetails: `/v1/api/job/workflow/get_interview_details`,
    // interviewStageUpdate: `/v1/api/job/workflow/interview_stage_update`,
    updateInterviewFields: `/v1/api/job/workflow/update_interview`,
    finalizeCandidateList: `/v1/api/job/workflow/finalized_candidates`,

    // hireCandidateStage: `/v1/api/job/workflow/finalized_stage_update`,    
    // hireCandidateList: `/v1/api/job/workflow/hired_candidates`,

    payment: `/v1/api/payment/customer`,
    paymentCustomer: `/v1/api/payment/customer`,
    paymentGetCustomer: `/v1/api/payment/get_customer`,
    completePayment: `/v1/api/payment/complete_payment`,
    completePaymentChargebee: `/v1/api/payment/complete_payment_chargebee`,
    saveCardDetails: `/v1/api/payment/save_card`,
    makePayment: `/v1/api/payment/make_payment`,
    paymentCompletion: `/v1/api/payment/payment_completed`,
    cancelPlan: `/v1/api/payment/cancelplan`,
    modifyPlan: `/v1/api/payment/modifyplan`,
    modifyPlanChargebee: `/v1/api/payment/modifyplan_chargebee`,
    updateSubscriptionChargebee: `/v1/api/payment/update_subscription_chargebee`,
    getPortalSession: `/v1/api/payment/generate_portal_page_chargebee`,

    // Integration
    integrationCreate: `/v1/api/integration/create`,
    integrationUpdate: `/v1/api/integration/update`,
    integrationManage: `/v1/api/integration/manage`,
    createMergeToken: `/v1/api/integration/create-merge-token`,
    saveMergeToken: `/v1/api/integration/save-merge-token`,
    deleteMergeIntegration: `/v1/api/integration/delete-merge-integration`,

    //adminShortlistCandidate: `/v1/api/admin/job/shortlist_candidate`,
    //adminStartScreeningCandidate: `/v1/api/admin/job/start_screening`,
    // adminShortListedCandidate: `/v1/api/admin/job/shortlisted_candidates`,

    adminUploadedCandidatesList: `/v1/api/admin/job/uploaded_candidates_all`,    
    admin2SelectCandidate: `/v1/api/admin/job/select_candidate`,
    admin2SourceCandidate: `/v1/api/admin/job/source_candidate`,
    admin2SelectedSourcedCandidates: `/v1/api/admin/job/selected_sourced_candidates`,

    // adminInProcessCandidate: `/v1/api/admin/job/in_process_candidates`,
    // inProcessCandidate: `/v1/api/job/workflow/in_process_candidates`,
    fullKanbanCandidatesList: `/v1/api/job/workflow/kanban_candidates`,
    kanbanUpdateStage: `/v1/api/job/workflow/kanban_stage_update`,
    kanbanClickActionUpdate: `/v1/api/job/workflow/kanban_click_action`,
    applicantJobsList: `/v1/api/business/job/applicant_job_listing`,
    applicantCandidatesList: `/v1/api/business/job/applicant_candidate_listing`,
    interviewReadyCandidatesApi: `/v1/api/business/job/interview_ready_candidates`,
    calendarJobsList: `/v1/api/business/job/calendar_job_listing`,
    allActiveJobsList: `/v1/api/business/job/active_job_listing`,
    onboardingJobsList: `/v1/api/business/job/onboarding_job_listing`,
    scheduledCandidatesList: `/v1/api/business/job/calendar_candidates`,
    onboardingCandidatesList: `/v1/api/business/job/onboarding_candidates`,
    calenderEventSetupApi: `/v1/api/business/job/calendar_create_event`,
    calenderAllEvents: `/v1/api/business/job/calendar_all_events`,
    
    
    adminUploadCandidateData: `/v1/api/admin/job/upload_candidate_csv`,
    adminSaveCandidateData: `/v1/api/admin/job/save_candidate_data`,
    adminInterviewProcessCandidate: `/v1/api/admin/job/in_interview_candidates`,
    adminFinalizeCandidate: `/v1/api/admin/job/finalized_candidates`,
    adminHiredCandidate: `/v1/api/admin/job/hired_candidates`,
    adminJobFullData: `/v1/api/admin/job/job_full_data`,
    adminRejectedCandidate: `/v1/api/admin/job/rejected_candidates`,
    adminShowCandiateFullInfo: `/v1/api/admin/job/candidate/full_info`,
    adminUpdateCandidate: `/v1/api/admin/job/update_candidate_details`,
    updateSkillsWorkTags: '/v1/api/admin/job/update_candidate_tags',
    adminJobArchival: '/v1/api/admin/job/archived',
    editJob: '/v1/api/admin/job/edit-job',
    updateSourcedProfileData: '/v1/api/admin/job/upload_candidate_data',
    updateCandidatePInfo: '/v1/api/admin/job/update_candidate_pinfo',
    enrichmentCandidate: '/v1/api/admin/job/candidate_enrichment',
    defaultQuestions: '/v1/api/admin/questions/get-default-questions',
    jobQuestions: '/v1/api/admin/questions/get-job-questions',
    submitScreeningQuestions: '/v1/api/admin/questions/create',    


    userEstimate: `/v1/api/business/usage_estimate`,
    validatePurchase: `/v1/api/business/validate_purchase`,
    validateTeamPurchase: `/v1/api/business/validate_team_purchase`,
    getPlan: `/v1/api/business/plans`,
    getPlanChargebee: `/v1/api/business/plans_chargebee`,
    cancellationPlan: `/v1/api/business/cancellation_plan`,
    selectedPlan: `/v1/api/business/selected_plan`,
    selectPlan: `/v1/api/business/select_plan`,
    updatePlan: `/v1/api/business/update_plan`,

    changeProcessingHeadings: `/v1/api/utils/design/create`,
    fetchProcessingHeadings: `/v1/api/utils/design/get`,
    startInfo: `/v1/api/business/start_info`,
    uploadBusinessLogo: `/v1/api/business/upload_business_logo`,
    updateBusinessSurvey: `/v1/api/business/update_business_survey`,
    getBusinessLogo: `/v1/api/business/get_business_logo`,
    googleLogin: `/v1/api/user/login_google`,
    googleRegister: `/v1/api/business/register_with_google`,
    microsoftLogin: `/v1/api/user/login_microsoft`,
    microsoftRegister: `/v1/api/business/register_with_microsoft`,
    businessUserStatus: `/v1/api/business/manage_status`,
    businessUserLevel: `/v1/api/business/business_level`,
    verifyEmail: 'v1/api/business/verify_email_code',
    regenerateVerifiedCode: `v1/api/business/regenerate_email_code`,
    calendarIntegrationUrl: '/v1/api/calendar/authorize',
    partialUpdateNew: '/v1/api/business/job/partial_update_new',

    // Common API
    candidatePanelDetails: '/v1/api/business/job/candidate_panel',
    candidateUpdateRank: `/v1/api/job/candidate/candidate_rank`,
    candidateUpdateFeedback: `/v1/api/job/candidate/feedback_save`,
    candidateCollectFeedback: `/v1/api/job/candidate/feedback_get`,
    candidateCollectTimeline: `/v1/api/job/candidate/timeline`,
    candidateScheduleInterview: `/v1/api/job/candidate/schedule_interview`,

    // Nylas API
    nylasBuildAuthUrl: '/nylas/generate-auth-url',
    nylasDisconnectService: '/v1/api/nylas/disconnect-service',
    nylasExchangeToken: '/nylas/exchange-mailbox-token',
    nylasReadCalender: 'v1/api/nylas/read-calendars',
    nylasReadCalenderEvents: 'v1/api/nylas/read-events',
    nylasDisconnectCalenders: 'v1/api/nylas/disconnect-calendars',
    nylasCreateCalenderEvent: 'v1/api/nylas/create-events',

    // emails
    getEmailTemplateList: `/v1/api/email/template/list`,
    updateEmailTemplate: `/v1/api/email/template/update`,
    createEmailTemplate: `/v1/api/email/template/create`,
    deleteEmailTemplate: `/v1/api/email/template/delete`,
  };
};
