import { Box, Flex, Image } from '@chakra-ui/react';
import DummyHeadshot from '../../../src/image/headshot.png';

export default function CandidateHeadshot (props) {
    const {candidate, height='60px', width='60px', mTop, mLeft, mRight='10px', mBottom, borderRadius='60px'} = props
    return (
        <Flex h={height} w={width} mr={mRight} ml={mLeft} mt={mTop} mb={mBottom}>
            <Box h={height} w={width}>
                <Image h="100%" w="100%" objectFit="cover" 
                    src={candidate?.headshot ? candidate?.headshot : DummyHeadshot} borderRadius={borderRadius} 
                    onError={({ currentTarget }) => {
                        currentTarget.onerror = null
                        currentTarget.src = DummyHeadshot
                      }}
                />
            </Box>
        </Flex>
    )
  }
