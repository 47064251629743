import React, { useEffect, useState } from 'react';
import {  useToast, Tooltip, IconButton, useDisclosure, Drawer, DrawerContent, DrawerHeader, DrawerCloseButton, Flex, Select, Text, Button, useColorModeValue, Spinner } from '@chakra-ui/react';
import { MdAssignmentAdd } from 'react-icons/md';
import { getEmailId } from '../../../../utils/localStorageIndex';
import { fetchJobByBusiness } from '../../../../services/companyServices';
import { assignCandidate } from '../../../../services/recruiterServices';
import { fetchBusinessesListPaginated } from '../../../../services/businessServices';
import { isObjectEmpty } from '../../../../utils/common';


export default function AssignCandidateToJob(props) {
  const {adminSettings, bulkSelectedCandidates, postAssignActions} = props;
  let candidate = {}
  if (bulkSelectedCandidates?.length > 0){
    candidate = bulkSelectedCandidates[0]
  }
  const toast = useToast()
  const [isAssigningCandidate, setIsAssigningCandidate] = useState(false);
  const { isOpen: isAssignPanelOpen, onOpen: onAssignPanelOpen, onClose: onAssignPanelClose } = useDisclosure();
  const [jobList, setJobList] = useState([])
  const [jobListLoading, setJobListLoading] = useState(false)
  const [selectedTargetJob, setSelectedTargetJob] = useState(null)
  const [clientList, setClientList] = useState([])
  const [clientListLoading, setClientListLoading] = useState(false)
  const [selectedTargetClient, setSelectedTargetClient] = useState(null)
  const [selectedTargetStage, setSelectedTargetStage] = useState('candidate_sourced')
  const brandColor = useColorModeValue('brand.500', 'white');

  useEffect(() => getClientList(), [])
  useEffect(() => getJobList(), [selectedTargetClient])
  
  const getClientList = () => {
    setClientListLoading(true);
    let requestBody = {
      email: getEmailId(),
      records_per_page: 500,
      page_id: 0,
      records_length: 0,
    };
    fetchBusinessesListPaginated(requestBody)
      .then(res => {
        setClientListLoading(false);
        if (res?.data[0].total_records > 0) {
          setClientList(res?.data[0]?.records);
          setSelectedTargetClient(res?.data[0]?.records[0]?.business_uuid)
        } else {
          setClientList([]);
        }
      })
      .catch(err => {
        console.log(err)
        setClientListLoading(false);
      });
  }

  const getJobList = () => {
    if (!selectedTargetClient){
      return
    }

    setJobListLoading(true);
    let objData = {
      email: getEmailId(),
      business_uuid: selectedTargetClient,
      searchString: '',
      filterEnabled: '',
      field_name: '',
      records_per_page: 100,
      page_id: 1,
    };
    fetchJobByBusiness(objData)
      .then(res => {
        setJobListLoading(false);
        if (res?.result) {
          if (res?.data?.length > 0){
            const openJobs = res?.data[0]?.records.filter(j => (j?.job_status?.toLowerCase() == 'open'))
            setJobList(openJobs);
            if (openJobs?.length > 0){
              setSelectedTargetJob(openJobs[0]?.job_uuid)
            }
          }
        }else{
          toast({
            title: 'Unable to get client list',
            description: res?.message,
            status: 'error',
            duration: 5000,
          });
        }
      })
      .catch(err => {
        setJobListLoading(false);
        toast({
          title: err?.toString(),
          status: 'error',
          duration: 5000,
        });
      });
  }

  const onSubmitAssignCandidate = () => {
    const data = {
      email: getEmailId(),
      candidate_uuids: bulkSelectedCandidates?.map(candidate => candidate?.candidate_uuid),
      target_business_uuid: selectedTargetClient,
      target_job_uuid: selectedTargetJob,      
      target_stage: selectedTargetStage
    }
    setIsAssigningCandidate(true);
    assignCandidate(data)
    .then( res => {
        if (res.result){
          // call any post assign functions
          for (const [fnName, fn] of Object.entries(postAssignActions)) {
            fn()
          }
          toast({
            title: 'Candidates assigned succesfully to target job.',
            status: 'success',
            duration: 3000
          });
        } else {
          toast({
            title: 'Unable to assign candidate.',
            description: res?.message,
            status: 'error',
            duration: 5000
          });
        }
        setIsAssigningCandidate(false);   
    })
    .catch( err =>  {
      setIsAssigningCandidate(false);
        toast({
            title: 'Unable to assign candidate.', 
            description: err?.toString(),
            status: 'error',
            duration: 5000
        });
    })
  }

  const AssignButtonLabel = () => {
    if (isAssigningCandidate){
      if (bulkSelectedCandidates?.length > 1){
        return 'Assigning Candidates...'
      }else{
        return 'Assigning Candidate...'
      }
    }
    
    if (bulkSelectedCandidates?.length > 1){
      return 'Assign Candidates to other job'
    }

    return 'Assign Candidate to other job'
  }

  return (
    <>
    {adminSettings &&
      <Tooltip label={<AssignButtonLabel />} fontSize='md'>
          <span>
            <IconButton isDisabled={bulkSelectedCandidates?.length == 0 && isObjectEmpty(candidate)} fontSize={'18px'} bg={'none'} _hover={{'bg': 'none'}} icon={<MdAssignmentAdd />} color={'gray'} 
              onClick={onAssignPanelOpen} isLoading={isAssigningCandidate}
            />
          </span>
      </Tooltip>
    }

    <Drawer size={'lg'} isOpen={isAssignPanelOpen} placement="right" onClose={onAssignPanelClose}>
        <DrawerContent p={10} borderLeftWidth="1px" borderLeftColor="gray.200">
          <DrawerHeader p={0}>
            {bulkSelectedCandidates?.length > 1 ?
              "Assign Candidates to other Job" :
              "Assign Candidate to other Job"
            }
          </DrawerHeader>
          <DrawerCloseButton />
          <Flex direction={'column'} mt={5}>
          <Flex mt={5} alignItems={'center'}>
              <Text as='b' width={'120px'}>Candidates</Text>
              <Flex direction={'column'}>
                {bulkSelectedCandidates?.map(candidate => <Text mt={2}>{candidate?.first_name} {candidate?.last_name}</Text>)}
              </Flex>              
            </Flex>

            <Flex mt={5} alignItems={'center'}>
              <Text as='b' width={'120px'}>Target Client</Text>
                <Select ml={5} onChange={(e) => setSelectedTargetClient(e.target.value)}>
                  <option disabled>{clientList?.length == 0 ? 'No target client found' : 'Select target client'}</option>
                  {clientList?.map(targetClient => {
                    return <option value={targetClient?.business_uuid}>{targetClient?.business_name}</option>
                  })}
                </Select>
                <Spinner hidden={!clientListLoading} ml={2} size={'sm'} />
            </Flex>

            <Flex mt={5} alignItems={'center'}>
              <Text as='b' width={'115px'}>Target Job</Text>
              <Flex direction={'column'} flexGrow={1}>            
                <Select onChange={(e) => setSelectedTargetJob(e.target.value)}>
                  {jobList?.length == 1 && <option disabled>No target job found</option>}
                  {jobList?.map(targetJob => {
                    return <option value={targetJob?.job_uuid}>{targetJob?.job_title}</option>
                  })}
                </Select>
                <Text mt={1} ml={1}>Closed and Draft jobs are not listed</Text>
              </Flex>  
              <Spinner hidden={!jobListLoading} ml={2} size={'sm'} />
            </Flex>
            <Flex mt={8} alignItems={'center'}>
              <Text as='b' width={'120px'}>Target Stage</Text>
              <Select ml={5} onChange={(e) => setSelectedTargetStage(e.target.value)}>
                <option value={'candidate_sourced'}>Sourcing</option>
                <option value={'candidate_screening'}>Screening</option>
                <option value={'ready_to_interview'}>Ready To Interview</option>
                <option value={'scheduled_for_interview'}>Interviewing</option>
                <option value={'candidate_hired'}>Offered</option>
                <option value={'candidate_rejected'}>Rejected</option>
              </Select>
            </Flex>

            <Button variant='brand' width={'200px'} mt={10} _hover={{'bg': brandColor}} onClick={onSubmitAssignCandidate} isLoading={isAssigningCandidate} loadingText={'Assigning...'} >Assign</Button>
          </Flex>
        </DrawerContent>
      </Drawer>
    </>
  );
}
