import React, { useEffect, useState } from 'react';
import {Flex, Heading, Text, Button, useToast, Textarea, Spinner, Input, Link,
  Radio, RadioGroup, Stack, Spacer} from '@chakra-ui/react';
import JobApplyLayout from '../../layouts/auth/types/JobApplyLayout';
import { fetchJobQuestions } from '../../services/questionServices';
import { fetchJobDetailedPublicData, submitJobApplication } from '../../services/jobTemplateService';
import { Editor } from "@tinymce/tinymce-react";
import { isObjectEmpty, jobApplyStandardFields } from '../../utils/common';

function JobApply() {
  const toast = useToast();
  const [jobPublicUrl, setJobPublicUrl] = useState(null)
  const [isJobDetailsLoading, setIsJobDetailsLoading] = useState(true);
  const [formQuestions, setFormQuestions] = useState(
    {
      'standard': jobApplyStandardFields,
      'custom': [] // coming from API in loadJobQuestions()
    }
  )
  const [isFormSubmitting, setIsFormSubmitting] = useState(false);
  const [isApplied, setIsApplied] = useState(false);
  const [selectedJob, setSelectedJob] = useState({});
  const [errorMessage, setErrorMessage] = useState(null);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    if(urlParams.has('id')){
      setJobPublicUrl(urlParams.get('id'))
    }
  }, [])

  useEffect(() => {
     if (jobPublicUrl){
        loadJobDetails();
     }
  }, [jobPublicUrl])

  useEffect(() => {
    if (!isObjectEmpty(selectedJob)){
       loadJobQuestions();
    }
 }, [selectedJob])

  useEffect(() => {
    if (window.location.hash){
      const hash = window.location.hash.split('#')[1]
      const newUrl = window.location.href.split('#')[0]
      window.history.replaceState({}, document.title, newUrl);
      if (document.getElementById(hash)) {
          document.getElementById(hash).scrollIntoView({behavior: "smooth"})
      }
    }
  }, [window.location.hash])

  const loadJobDetails = () => {
    setIsJobDetailsLoading(true);
    fetchJobDetailedPublicData({job_public_url: jobPublicUrl})
      .then(res => {
        setIsJobDetailsLoading(false);
        if (res.result && res.data[0]) {
          setSelectedJob(res.data[0]);
        }
      })
      .catch(err => {
        toast({
          title: 'Unable to get job details', 
          description: err.toString(),
          status: 'error', 
          isClosable: true, 
          duration: 3000
      });
        setIsJobDetailsLoading(false);
      });
  };

  const loadJobQuestions = () => {
    fetchJobQuestions({'job_uuid': selectedJob.job_uuid})
    .then( res => {
        setFormQuestions({...formQuestions, custom: res});
    })
    .catch( err =>  {
        toast({
            title: 'Unable to get job questions', 
            description: err.toString(),
            status: 'error', 
            isClosable: true, 
            duration: 3000
        });
    })
  }

  const renderStandardQuestions = () => {
    return formQuestions.standard.map( (question, index) => {
          return (
            <Flex
              width={'100%'}
              key={index}
              p={'15px 10px'}
              direction={'column'}
              mt={5}
            >
              <Flex>
                {question.is_required && <Text color={'red'} mr={1}>*</Text> }
                <Text as='b' mb={5} fontSize={'18px'}>{question.title}</Text>
              </Flex>

              {question.type == 'text' ?
                <Flex alignItems={'center'}>
                    <Input isRequired={true} name={question.name} width={'400px'} />
                </Flex>
                : null 
              }

              {question.type == 'file' ?
                <Flex alignItems={'center'}>
                    <Input
                      borderRadius={0}
                      pl={0}
                      border={0}
                      isRequired={true}
                      name={question.name}
                      type={'file'}
                    />
                </Flex>
                : null 
            }
            
            </Flex>
          )
        })  
  }

  const renderCustomQuestions = () => {
    return formQuestions.custom.map( (question, index) => {
      return (
        <Flex
          width={'100%'}
          key={index}
          p={'15px 10px'}
          direction={'column'}
          mt={5}
        >           
            <Flex>
              {question.question_is_mandatory && <Text color={'red'} mr={1}>*</Text>}
              <Text as='b' mb={5} fontSize={'18px'}>{question.question_text}</Text>
            </Flex>

            {question.question_type.code == 'boolean' ?
                <Flex alignItems={'center'}>
                    <RadioGroup                    
                      data-is-custom-field={true}
                    >
                      <Stack>
                        {['true', 'false'].map(option => {
                          return (
                            <Radio size='lg' isRequired={question.question_is_mandatory} value={option} name={question.question_text}>
                                {option == 'true' ? 'Yes' : 'No'}
                            </Radio>
                          )
                        })}
                      </Stack>
                    </RadioGroup>
                </Flex>
                : null 
            }

            {question.question_type.code == 'file' ?
                <Flex alignItems={'center'}>
                    <Input
                      borderRadius={0}
                      pl={0}
                      border={0}
                      type={'file'}
                      isRequired={question.question_is_mandatory}
                      data-is-custom-field={true}
                      name={question.question_text}/>
                </Flex>
                : null 
            }
                
            {question.question_type.code == 'subjective' ?
                <Flex alignItems={'center'}>
                    <Textarea
                      rows={8}
                      isRequired={question.question_is_mandatory}
                      data-is-custom-field={true}
                      name={question.question_text}/>
                </Flex>
                : null 
            }

            {question.question_type.code == 'number' ?
                <Flex alignItems={'center'}>
                    <Input
                      type='number'
                      width={'400px'} 
                      isRequired={question.question_is_mandatory}
                      data-is-custom-field={true}
                      name={question.question_text}/>
                </Flex>
                : null 
            }

            {question.question_type.code == 'multiplechoice' ?
                  <RadioGroup                    
                    data-is-custom-field={true}
                  >
                    <Stack>
                      {question.question_options.map(option => {
                        return (
                          <Radio size='lg' isRequired={question.question_is_mandatory} value={option} name={question.question_text}>
                              {option}
                          </Radio>
                        )
                      })}
                    </Stack>
                  </RadioGroup>
                : null 
            }
        </Flex> 
      )
    })
  }

  const renderFormQuestions = () => {

    if (isApplied){
      return null
    }

    return (
        <Flex direction={'column'} id={'apply'}>
          <Text ml={15}>Questions marked with asterisk are required.</Text>
          {renderStandardQuestions()}
          {renderCustomQuestions()}
        </Flex>
    )
  }

  const renderFormActionButtons = () => {
    if (isApplied){
      return null
    }

    return (
        <Flex mt={15}>
            <Button
                type={'submit'} size={'lg'} colorScheme='blue'
                isLoading={isFormSubmitting} loadingText='Submitting...'
            >
                Submit
            </Button>
        </Flex>
    )
  }

  const renderJobDetails = () => {
    if (isApplied){
      return null
    }

    if(isJobDetailsLoading){
      return (
        <Flex direction={'column'} justifyContent={'center'} alignItems={'center'}>
            <Spinner />
            <Text>Loading job details</Text>
        </Flex>
      )
    }

    return (
      <Flex width={'100%'} direction={'column'}>
        <Flex mb={'100px'}>
          <Heading fontSize="30px" mb="10px">
            {selectedJob.job_title}
          </Heading>
          <Spacer />
          <Link
            color={'white'} p={'6px 30px'} borderRadius={10}
            bg={'blue.500'} fontSize={'xl'} href={`${window.location.href}#apply`}
          >
            Apply
          </Link>          
        </Flex>

        <Editor
          tinymceScriptSrc={'/tinymce/tinymce.min.js'}
          value={selectedJob.job_description || ""}
          disabled={true}
          init={{
              branding: false,
              statusbar: false,
              toolbar: false,
              menubar: false,
              plugins: 'autoresize',
              content_style: "body {margin: 0; padding: 0}"
          }}
        />
      </Flex>
    )
  }

  const validateRequiredQuestions = (form) => {
    let isRequiredFieldsFilled = true
    formQuestions.standard.forEach(question => {
      if (question.type == 'file' && !form.get(question.name).name){
        isRequiredFieldsFilled = false
      }
      else if (!form.get(question.name)){
        isRequiredFieldsFilled = false
      }
    })

    const customRequiredQuestions = formQuestions.custom.filter(question => question.question_is_mandatory)
    customRequiredQuestions.forEach(question => {
      if (question.question_type.code == 'file' && !form.get(question.question_text).name){
        isRequiredFieldsFilled = false
      }
      else if (!form.get(question.question_text)){
        isRequiredFieldsFilled = false
      }
    })
 
    if (!isRequiredFieldsFilled){
      setErrorMessage('Please fill out all of the required questions')
      return false
    }

    return true
  }

  const submitForm = (e) => {
    e.preventDefault()
    const form = new FormData(e.target)

    // get all custom fields and attach it to a single field as strigified JSON
    const customFields = []
    formQuestions.custom.map(question => {
      for (const [fieldName, fieldValue] of form.entries()) {

        // handle file fields
        if(fieldName == question.question_text && question.question_type.code == 'file'){
          customFields.push({name: fieldName, value: fieldValue.name, type: question.question_type.code, filename: fieldValue.name})

          // attach all files into a files field
          form.append('files', fieldValue)
          
        // non file fields
        }else if (fieldName == question.question_text){
          customFields.push({name: fieldName, value: fieldValue, type: question.question_type.code})
        }
      }
    })

    form.set('custom_fields', JSON.stringify(customFields))
    form.set('job_uuid', selectedJob.job_uuid)

    // do manual validation
    if(!validateRequiredQuestions(form)){
      return false
    }

    setIsFormSubmitting(true);

    submitJobApplication(form)
    .then( res => {
        if (res.result){
          toast({
            title: 'You have applied succesfully.',
            status: 'success', 
            isClosable: true, 
            duration: 3000
          });
          setIsApplied(true);
        } else {
          toast({
            title: 'Unable to submit your job application.',
            description: res.message,
            status: 'error', 
            isClosable: true, 
            duration: 3000
          });
        }
        setIsFormSubmitting(false);        
    })
    .catch( err =>  {
        setIsFormSubmitting(false);
        toast({
            title: 'Unable to submit your job application', 
            description: err.toString(),
            status: 'error', 
            isClosable: true, 
            duration: 3000
        });
    })

    setErrorMessage(null);
    return false
  }

  const renderApplied = () => {
    if (!isApplied){
      return null
    }

    return (
      <Flex justifyContent={'center'} direction={'column'}>
        <Heading mb={10}>Thank You for your application</Heading>
        <Text fontSize={'18px'}>
          Your application is submitted for the position of {selectedJob.job_title}.
        </Text>
      </Flex>
    )
  }

  return (
    <JobApplyLayout 
        cardTop={{ base: '10px', md: '14vh' }}
        cardBottom={{ base: '50px', lg: 'auto' }}
        mx="0px"
    >
        <Flex
          zIndex="2"
          w={{ base: '100%', md: '800px' }}
          borderRadius="15px"
          direction={'column'}
        >

          {renderApplied()}
          {renderJobDetails()}

          <form onSubmit={submitForm}>
            {renderFormQuestions()}
            {errorMessage && <Text fontSize={'16px'} mt={10}>{errorMessage}</Text> }
            {renderFormActionButtons()}
          </form>

        </Flex>
    </JobApplyLayout>
  );
}

export default JobApply;
