import React, { useEffect, useState } from 'react';
import { Button, Text,
  useColorModeValue, Flex, Box, FormControl, Input, Stack, Icon, Drawer, DrawerContent, DrawerHeader, DrawerCloseButton,
  useToast, Table, Thead, Tbody, Td, Tr, Th, TableContainer} from '@chakra-ui/react';
import { MdUpload } from 'react-icons/md';
import { submitCandidateByResume } from '../../../../services/recruiterServices';
import { getEmailId } from '../../../../utils/localStorageIndex';


export default function AddCandidateByResume(props) {
  const toast = useToast()
  const brandColor = useColorModeValue('brand.500', 'white');
  const [isFormSubmitting, setIsFormSubmitting] = useState(false)
  const [successMessage, setSuccessMessage] = useState([])
  const [selectedResumes, setSelectedResumes] = useState([])

  useEffect(() => {
    setSelectedResumes([])
    setSuccessMessage([])
  }, [])

  const submitForm = (e) => {
    e.preventDefault()
    const form = new FormData(e.target)
    form.set('job_uuid', props?.job?.job_uuid)
    form.set('email', getEmailId())
    setIsFormSubmitting(true);
    submitCandidateByResume(form)
    .then( res => {
        if (res.result){
          props.getAllCandidatesForJob()
          setSuccessMessage(res?.data)
          toast({
            title: res?.message,
            status: 'success', 
            isClosable: true, 
            duration: 8000
          });
        } else {
          toast({
            title: 'Unable to add candidate.',
            description: res.message,
            status: 'error', 
            isClosable: true, 
            duration: 5000
          });
        }
        setIsFormSubmitting(false);        
    })
    .catch( err =>  {
        setIsFormSubmitting(false);
        toast({
            title: 'Unable to add candidate.', 
            description: err.toString(),
            status: 'error', 
            isClosable: true, 
            duration: 5000
        });
    })
    return false
  }

  const ResumeSuccessMessage = () => {
    if (!successMessage.length){
      return null
    }

    return <>
      <TableContainer direction={'column'} mt={'20px'}>
        <Table fontSize={'16px'} colorScheme='teal'>
          <Thead>
            <Tr>
              <Th p={2}>Saved?</Th>
              <Th p={2}>Reason</Th>
              <Th p={2} width={'100px'}>Name</Th>
              <Th p={2} width={'150px'}>Email</Th>
              <Th p={2} w={'200px'}>Linkedin</Th>
              <Th p={2} w={'200px'}>Resume</Th>              
            </Tr>
          </Thead>

          <Tbody>
            {successMessage?.map(message => {
              return <>
                <Tr>               
                  <Td p={2}><Text as={'b'} color={!message.saved ? 'red.500' : 'inherit'}>{message.saved ? "Yes" : "No"}</Text></Td>
                  <Td p={2}><Text style={{'textWrap': 'wrap'}} color={!message.saved ? 'red.500' : 'inherit'}>{message.reason}</Text></Td>
                  <Td p={2}><Text>{message.first_name} {message.last_name}</Text></Td>
                  <Td p={2}><Text>{message.email}</Text></Td>
                  <Td p={2}><Text style={{'textWrap': 'wrap'}}>{message.linkedin}</Text></Td>
                  <Td p={2}><Text style={{'textWrap': 'wrap'}}as='b'>{message.resume}</Text></Td>                  
                </Tr>
              </>
            })}
          </Tbody>
        </Table>
      </TableContainer>
    </>
  }

  const onSelectResumeFiles = (e) => {
    const resumes = Object.values(e.target.files).map(file => file.name)
    setSelectedResumes(resumes)
    if (resumes?.length > 5){
      toast({
        title: "Max limit exceed",
        description: `Please select not more than 5 resumes at once. You have selected ${resumes?.length}`,
        status: 'error',
        duration: 5000
      })
    }
  }

  const SelectedResumesName = () => {
    return <>
      <Flex direction={'column'} mt={5} mb={5} fontSize={'16px'}>
        <Text hidden={selectedResumes.length == 0 || isFormSubmitting} mb={8} pl={5}>You've selected these resumes. Please confirm and click Submit</Text>
        <Text hidden={!isFormSubmitting} mb={8} pl={5}>Adding selected resumes.</Text>
        {selectedResumes.map((resume, index) => <Text as={'b'} fontSize={'14px'} mb={4} pl={5} borderBottom={'1px solid'} borderBottomColor={'gray.200'}>{index+1} - {resume}</Text>)}
      </Flex>
    </>
  }

  return (
    <Drawer size={'xl'} isOpen={props.isAddCandidateByResumePanelOpen} placement="right" onClose={() => {
      setSuccessMessage([]);
      setSelectedResumes([])
      props.onAddCandidateByResumePanelClose()
    }}>
      <DrawerContent pl={5}  overflow={'auto'}>
        <DrawerHeader>Add Candidates by Resume</DrawerHeader>
        <DrawerCloseButton />
          <form onSubmit={submitForm}>
            <Flex direction={'column'} alignItems={'center'} p="10px">
              <FormControl cursor="pointer">
                <Box position="relative" height="200px" width="100%" borderWidth="2px"
                  borderColor={brandColor} borderRadius={'10px'} cursor="pointer" >
                  <Box position="absolute" top="0" left="0" height="100%" width="100%" display="flex" flexDirection="column" cursor="pointer">
                    <Stack height="100%" width="100%" display="flex" alignItems="center"
                      justify="center" spacing="4" cursor="pointer"
                    >
                      <Box textAlign="center">
                        <Icon as={MdUpload} w="80px" h="80px" color={brandColor} />
                        <Flex justify="center" mx="auto" mb="12px">
                          <Text fontSize="xl" fontWeight="700" color={brandColor}>
                            Select a single or multiple resumes
                          </Text>
                        </Flex>
                        <Text
                          fontSize="sm"
                          fontWeight="500"
                          color="secondaryGray.500"
                        >
                          Only PDF and Docx files are allowed
                        </Text>
                      </Box>
                    </Stack>
                  </Box>
                  <Input
                    required multiple type="file" height="100%" width="100%" position="absolute"
                    top="0" left="0" opacity="0" aria-hidden="true" accept={'.pdf, .docx'}
                    cursor="pointer" name='resumes' onChange={onSelectResumeFiles}
                  />
                </Box>
              </FormControl>              

              <Button width={'200px'} mt={10} variant="brand" fontWeight="500" type='submit'
               isLoading={isFormSubmitting} loadingText={'Uploading...'} _hover={{'bg': brandColor}}
              >
                Submit
              </Button>

              <SelectedResumesName />

              <ResumeSuccessMessage />
            </Flex>
          </form>
      </DrawerContent>
    </Drawer>    
  );
}
