import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import 'react-tagsinput/react-tagsinput.css';
import { getEmailId } from '../../../../../utils/localStorageIndex';
import {
  fetchBusinessInformation,
  updateBusinessSurvey,
} from '../../../../../services/businessServices';
import Card from '../../../../../components/card/Card';
import TagsInput from 'react-tagsinput';
import InputField from '../../../../../components/fields/InputField';
import { setWizardLevel } from '../../../../../store/actions/topNotificationActions';
import {
  Box,
  Button,
  Flex,
  SimpleGrid,
  useToast,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  Checkbox,
  useColorModeValue,
} from '@chakra-ui/react';

export default function BusinessSurveyWizard(props) {
  useEffect(() => {
    fetchBusinessDetails();
  }, []);

  const dispatch = useDispatch();
  const toast = useToast();
  const [loading, isLoading] = useState(false);

  const textColor = useColorModeValue('secondaryGray.900', 'white');
  const brandColor = useColorModeValue('brand.500', 'white');
  const [howToReachOut, setHowToReachOut] = useState([]);
  const [roleArr, setRoleArr] = useState([
    { label: 'Full-time and Part-Time', isChecked: false, isDisabled: false },
    { label: 'Contract or Temp', isChecked: false, isDisabled: false },
    { label: 'Internship', isChecked: false, isDisabled: false },
    { label: 'Other', isChecked: false, isDisabled: false },
  ]);
  const [typeArr, setTypeArr] = useState([
    { label: 'Right To Work (U.S. Citizens)', isChecked: false },
    { label: 'Green Card', isChecked: false },
    { label: 'Visa', isChecked: false },
    { label: '3rd Party Staffed (Staff Augmentation)', isChecked: false },
    { label: 'Other', isChecked: false },
  ]);
  const [perksArr, setPerksArr] = useState([
    { label: 'Health Insurance', isChecked: false },
    { label: 'Paid Time-Off', isChecked: false },
    { label: 'Dental Insurance', isChecked: false },
    { label: '401 (K)', isChecked: false },
    { label: 'Vision Insurance', isChecked: false },
    { label: 'Flexible Schedule', isChecked: false },
    { label: 'Tuition Reimbursement', isChecked: false },
    { label: 'Life Insurance', isChecked: false },
    { label: '401 (k) matching', isChecked: false },
    { label: 'Retirement Plan', isChecked: false },
    { label: 'Referral Program', isChecked: false },
    { label: 'Employee Discount', isChecked: false },
    { label: 'Flexible Spending', isChecked: false },
    { label: 'Health Savings Account', isChecked: false },
    { label: 'Relocation Assistance', isChecked: false },
    { label: 'Parental Leave', isChecked: false },
    { label: 'Professional Development Assistance', isChecked: false },
    { label: 'Employee Assistance Program', isChecked: false },
    { label: 'None', isChecked: false },
    { label: 'Other', isChecked: false },
  ]);
  const [roleOtherValue, setRoleOtherValue] = useState('');
  const [typeOtherValue, setTypeOtherValue] = useState('');
  const [perksOtherValue, setPerksOtherValue] = useState('');
  const [activeBullets, setActiveBullets] = useState({
    types: true,
    role: false,
    perks: false,
    reachOut: false,
    sisterCompany: false,
  });
  const [companyNames, setCompanyNames] = useState([]);

  const fetchBusinessDetails = () => {
    var apiData = { email: getEmailId() };
    fetchBusinessInformation(apiData)
      .then(res => {
        if (res.result && res.data[0] !== null && res.data.length) {
          let surveyData = JSON.parse(res?.data[0].survey);
          let roleOther = '';
          let typeOther = '';
          let perksOther = '';
          if (surveyData?.sections) {
            surveyData.sections.forEach(surveyOptionData => {              
              if (surveyOptionData.id === 1) {
                setTypeArr(
                  typeArr.map(cloneData => {
                    surveyOptionData.options.forEach(subData => {
                      if (cloneData.label === subData) {
                        cloneData.isChecked = true;
                        typeOther = surveyOptionData?.other_value;
                      }
                    });
                    return cloneData; // Return the modified/cloned object
                  })
                );
              }
              if (surveyOptionData.id === 2) {
                setRoleArr(
                  roleArr.map(cloneData => {
                    surveyOptionData.options.forEach((subData, index) => {
                      if (cloneData.label === subData) {
                        cloneData.isChecked = true;
                        roleOther = surveyOptionData?.other_value;
                      }
                    });
                    return cloneData; // Return the modified/cloned object
                  })
                );
              }
              if (surveyOptionData.id === 3) {
                setPerksArr(
                  perksArr.map(cloneData => {
                    surveyOptionData.options.forEach(subData => {
                      if (cloneData.label === subData) {
                        cloneData.isChecked = true;
                        perksOther = surveyOptionData?.other_value;
                      }
                    });
                    return cloneData; // Return the modified/cloned object
                  })
                );
              }
              if (surveyOptionData.id === 4) {
                setHowToReachOut(surveyOptionData?.options);
              }
              if (surveyOptionData.id === 5) {
                setCompanyNames(surveyOptionData?.options);
              }
              setRoleOtherValue(roleOther);
              setTypeOtherValue(typeOther);
              setPerksOtherValue(perksOther);
            });
          }
        }
      })
      .catch(err => {
        isLoading(false);
      });
  };

  const roleTab = React.useRef();
  const typesTab = React.useRef();
  const perksTab = React.useRef();
  const reachOutTab = React.useRef();
  const sisterCompanyTab = React.useRef();

  // Todo:Set Tags data
  // const onTagChange = tags => {
  //   setJobKeywords(tags);
  // };

  // Todo:Set Tags data
  const onCompanyNameChange = tags => {
    setCompanyNames(tags);
  };

  // Todo:onChange Role Value

  const handleRadioChange = value => {
    let newArr = roleArr.map(data => {
      if (data.label === value) {
        return { ...data, isChecked: !data.isChecked };
      }
      return data;
    });

    // If "Both" is checked, ensure that "Full-time and Part-Time" and "Contract" are also checked and disabled
    if (value === 'Both') {
      const bothCheckbox = newArr.find(data => data.label === 'Both');
      if (bothCheckbox.isChecked) {
        newArr = newArr.map(data => {
          if (
            data.label === 'Full-time and Part-Time' ||
            data.label === 'Contract'
          ) {
            return { ...data, isChecked: true, isDisabled: true };
          }
          return data;
        });
      } else {
        // If "Both" is unchecked, enable the "Full-time and Part-Time" and "Contract" checkboxes
        newArr = newArr.map(data => {
          if (
            data.label === 'Full-time and Part-Time' ||
            data.label === 'Contract'
          ) {
            return { ...data, isDisabled: false };
          }
          return data;
        });
      }
    }

    setRoleArr(newArr);
  };

  // Todo:onChange Type Value
  const handleTypeRadioChange = (value, arrValue, setterArrValue) => {
    let newArr = [...arrValue];
    newArr.map(data => {
      if (data.label === value) {
        data.isChecked = !data.isChecked;
      }
    });
    setterArrValue(newArr);
  };

  const updateHowToReachOut = newValue => {
    let newArr = [];
    newArr.push(newValue);
    setHowToReachOut(newArr);
  };

  // Todo:Return Filtered Data
  const filteredData = arrData => {
    return arrData.filter(data => data?.isChecked).map(data => data.label);
  };

  // Todo:SubmitVizardValue
  const submitWizardValue = () => {
    let objBody = {
      sections: [        
        {
          id: 1,
          questions: 'Select all types of employment your company will accept?',
          options: filteredData(typeArr),
          selected_option: 'Other',
          other_value: typeOtherValue,
        },
        {
          id: 2,
          questions: 'What type of roles will your company be hiring for?',
          options: filteredData(roleArr),
          selected_option: 'Other',
          other_value: roleOtherValue,
        },
        {
          id: 3,
          questions: 'Select all the benefits and perks your company is providing',
          options: filteredData(perksArr),
          selected_option: 'Other',
          other_value: 'entered when choose other',
        },
        {
          id: 4,
          questions: 'How would you like us to reach out to candidates?',
          options: howToReachOut,
          selected_option: 'Other',
          other_value: '',
        },
        {
          id: 5,
          questions:
            'Do you have any sister companies or partners we should not be recruiting talent from? (Applicants that apply will be considered)',
          options: companyNames,
          selected_option: 'Other',
          other_value: '',
        },
      ],
    };
    storeSurveyData(objBody);
  };

  const storeSurveyData = surveyJson => {
    isLoading(false);
    let objBody = {
      email: getEmailId(),
      survey_data: JSON.stringify(surveyJson),
    };
    updateBusinessSurvey(objBody)
      .then(res => {
        isLoading(false);
        if (res?.result) {
          toast({
            title: 'Business Survey is Saved..',
            status: 'success',
            isClosable: true,
            duration: 3000,
          });
          if (Object.keys(props).length) {
            props?.resentJobFlag(true);
          }
        } else {
          toast({
            title: 'Unable to save the business survey..',
            status: 'error',
            isClosable: true,
            duration: 3000,
          });
        }
      })
      .catch(err => {
        isLoading(false);
        toast({
          title: 'Unable to save the business survey..',
          status: 'error',
          isClosable: true,
          duration: 3000,
        });
      });
  };

  return (
    <Flex
      direction="column"
      minH={props?.minH ? props?.minH : '100vh'}
      align="center"
      position="relative"
    >
      <Box
        h="45vh"
        bgGradient="linear(to-b, brand.400, brand.600)"
        position="absolute"
        w="100%"
        borderRadius="20px"
      />

      <Tabs
        variant="unstyled"
        zIndex="0"
        mt={{ base: '60px', md: '165px' }}
        display="flex"
        flexDirection="column"
      >
        <TabList
          display="flex"
          alignItems="center"
          alignSelf="center"
          justifySelf="center"
        >          
          <Tab
            _focus={{ border: '0px', boxShadow: 'unset' }}
            ref={typesTab}
            w={{ sm: '120px', md: '250px', lg: '150px' }}
            onClick={() => {
              setActiveBullets({                
                types: true,
                role: false,
                perks: false,
                reachOut: false,
                sisterCompany: false,
              });
              dispatch(setWizardLevel(1));
            }}
          >
            <Flex
              direction="column"
              justify="center"
              align="center"
              position="relative"
              _before={{
                content: "''",
                width: { sm: '120px', md: '250px', lg: '150px' },
                height: '3px',
                bg: activeBullets.role ? 'white' : 'brand.400',
                left: { sm: '12px', md: '40px' },
                top: {
                  sm: activeBullets.types ? '6px' : '4px',
                  md: null,
                },
                position: 'absolute',
                bottom: activeBullets.types ? '40px' : '38px',
                transition: 'all .3s ease',
              }}
            >
              <Box
                zIndex="1"
                border="2px solid"
                borderColor={activeBullets.types ? 'white' : 'brand.400'}
                bgGradient="linear(to-b, brand.400, brand.600)"
                w="16px"
                h="16px"
                mb="8px"
                borderRadius="50%"
              />
              <Text
                color={activeBullets.types ? 'white' : 'gray.300'}
                fontWeight={activeBullets.types ? 'bold' : 'normal'}
                display={{ sm: 'none', md: 'block' }}
              >
                Employment
              </Text>
            </Flex>
          </Tab>
          <Tab
            _focus={{ border: '0px', boxShadow: 'unset' }}
            ref={roleTab}
            w={{ sm: '120px', md: '250px', lg: '150px' }}
            onClick={() => {
              setActiveBullets({                
                types: true,
                role: true,
                perks: false,
                reachOut: false,
                sisterCompany: false,
              });
              dispatch(setWizardLevel(2));
            }}
          >
            <Flex
              direction="column"
              justify="center"
              align="center"
              position="relative"
              _before={{
                content: "''",
                width: { sm: '120px', md: '250px', lg: '150px' },
                height: '3px',
                bg: activeBullets.perks ? 'white' : 'brand.400',
                left: { sm: '12px', md: '30px' },
                top: '6px',
                position: 'absolute',
                bottom: activeBullets.types ? '40px' : '38px',
                transition: 'all .3s ease',
              }}
            >
              <Box
                zIndex="1"
                border="2px solid"
                borderColor={activeBullets.role ? 'white' : 'brand.400'}
                bgGradient="linear(to-b, brand.400, brand.600)"
                w="16px"
                h="16px"
                mb="8px"
                borderRadius="50%"
              />
              <Text
                color={activeBullets.role ? 'white' : 'gray.300'}
                fontWeight={activeBullets.role ? 'bold' : 'normal'}
                display={{ sm: 'none', md: 'block' }}
              >
                Work Status
              </Text>
            </Flex>
          </Tab>
          <Tab
            _focus={{ border: '0px', boxShadow: 'unset' }}
            ref={perksTab}
            w={{ sm: '120px', md: '250px', lg: '150px' }}
            onClick={() => {
              setActiveBullets({                
                types: true,
                role: true,
                perks: true,
                reachOut: false,
                sisterCompany: false,
              });
              dispatch(setWizardLevel(3));
            }}
          >
            <Flex
              direction="column"
              justify="center"
              align="center"
              position="relative"
              _before={{
                content: "''",
                width: { sm: '120px', md: '250px', lg: '150px' },
                height: '3px',
                bg: activeBullets.reachOut ? 'white' : 'brand.400',
                left: { sm: '12px', md: '32px' },
                top: '6px',
                position: 'absolute',
                bottom: activeBullets.types ? '40px' : '38px',

                transition: 'all .3s ease',
              }}
            >
              <Box
                zIndex="1"
                border="2px solid"
                borderColor={activeBullets.perks ? 'white' : 'brand.400'}
                bgGradient="linear(to-b, brand.400, brand.600)"
                w="16px"
                h="16px"
                mb="8px"
                borderRadius="50%"
              />
              <Text
                color={activeBullets.perks ? 'white' : 'gray.300'}
                fontWeight={activeBullets.perks ? 'bold' : 'normal'}
                display={{ sm: 'none', md: 'block' }}
              >
                Benefits
              </Text>
            </Flex>
          </Tab>
          <Tab
            _focus={{ border: '0px', boxShadow: 'unset' }}
            ref={reachOutTab}
            w={{ sm: '120px', md: '250px', lg: '150px' }}
            onClick={() => {
              setActiveBullets({
                types: true,
                role: true,                
                perks: true,
                reachOut: true,
                sisterCompany: false,
              });
              dispatch(setWizardLevel(4));
            }}
          >
            <Flex
              direction="column"
              justify="center"
              align="center"
              position="relative"
              _before={{
                content: "''",
                width: { sm: '120px', md: '250px', lg: '150px' },
                height: '3px',
                bg: activeBullets.sisterCompany ? 'white' : 'brand.400',
                left: { sm: '12px', md: '32px' },
                top: '6px',
                position: 'absolute',
                bottom: activeBullets.types ? '40px' : '38px',

                transition: 'all .3s ease',
              }}
            >
              <Box
                zIndex="1"
                border="2px solid"
                borderColor={activeBullets.reachOut ? 'white' : 'brand.400'}
                bgGradient="linear(to-b, brand.400, brand.600)"
                w="16px"
                h="16px"
                mb="8px"
                borderRadius="50%"
              />
              <Text
                color={activeBullets.reachOut ? 'white' : 'gray.300'}
                fontWeight={activeBullets.reachOut ? 'bold' : 'normal'}
                display={{ sm: 'none', md: 'block' }}
              >
                Outreach
              </Text>
            </Flex>
          </Tab>
          <Tab
            _focus={{ border: '0px', boxShadow: 'unset' }}
            ref={sisterCompanyTab}
            w={{ sm: '120px', md: '100px', lg: '150px' }}
            onClick={() => {
              setActiveBullets({
                types: true,
                role: true,                
                perks: true,
                reachOut: true,
                sisterCompany: true,
              });
              dispatch(setWizardLevel(5));
            }}
          >
            <Flex
              direction="column"
              justify="center"
              align="center"
              position="relative"
            >
              <Box
                zIndex="1"
                border="2px solid"
                borderColor={
                  activeBullets.sisterCompany ? 'white' : 'brand.400'
                }
                bgGradient="linear(to-b, brand.400, brand.600)"
                w="16px"
                h="16px"
                mb="8px"
                borderRadius="50%"
              />
              <Text
                color={activeBullets.sisterCompany ? 'white' : 'gray.300'}
                fontWeight={activeBullets.sisterCompany ? 'bold' : 'normal'}
                display={{ sm: 'none', md: 'block' }}
              >
                Partners
              </Text>
            </Flex>
          </Tab>
        </TabList>
        <TabPanels mt="24px" maxW={{ md: '90%', lg: '100%' }} mx="auto">          
          <TabPanel
            w={{ sm: '330px', md: '700px', lg: '700px' }}
            p="0px"
            mx="auto"
          >
            <Card p="30px">
              <Text color={textColor} fontSize="2xl" fontWeight="700" mb="20px">
                Select all types of employment your company will accept?
              </Text>
              <Flex direction="column" w="100%">
                <Stack direction="column" spacing="20px">
                  <SimpleGrid columns={{ base: 1, md: 2 }} gap="20px">
                    {typeArr?.map((typeData, index) => (
                      <>
                        <Checkbox
                          key={typeData?.label}
                          value={typeData?.value}
                          colorScheme="brand"
                          isChecked={typeData?.isChecked}
                          onChange={value =>
                            handleTypeRadioChange(
                              typeData?.label,
                              typeArr,
                              setTypeArr
                            )
                          }
                        >
                          {typeData?.label}
                        </Checkbox>
                        {typeData?.label === 'Other' &&
                        typeData?.isChecked === true ? (
                          <InputField
                            mb="0px"
                            id="typeId"
                            placeholder="Enter Other Value"
                            label="Other"
                            value={typeOtherValue}
                            onChange={e => {
                              setTypeOtherValue(e.target.value);
                            }}
                          />
                        ) : null}
                      </>
                    ))}
                  </SimpleGrid>
                </Stack>
                <Flex justify="space-between">                 
                  <Button
                    variant="darkBrand"
                    fontSize="sm"
                    borderRadius="16px"
                    w={{ base: '128px', md: '148px' }}
                    h="46px"
                    ms="auto"
                    mt="3"
                    onClick={() => {
                      roleTab.current.click();
                      dispatch(setWizardLevel(2));
                    }}
                  >
                    Next
                  </Button>
                </Flex>
              </Flex>
            </Card>
          </TabPanel>  
          
          <TabPanel
            w={{ sm: '330px', md: '700px', lg: '700px' }}
            p="0px"
            mx="auto"
          >
            <Card p="30px">
              <Text color={textColor} fontSize="2xl" fontWeight="700" mb="20px">
                What type of roles will your company be hiring for?
              </Text>
              <Flex direction="column" w="100%">
                <Stack direction="column" spacing="20px">
                  <SimpleGrid columns={{ base: 1, md: 2 }} gap="20px">
                    {roleArr?.map((roleData, index) => (
                      <>
                        <Checkbox
                          key={roleData?.label}
                          value={roleData?.value}
                          colorScheme="brand"
                          isChecked={roleData?.isChecked}
                          onChange={value => handleRadioChange(roleData?.label)}
                          isDisabled={roleData?.isDisabled}
                        >
                          {roleData?.label}
                        </Checkbox>
                        {roleData?.label === 'Other' &&
                        roleData?.isChecked === true ? (
                          <InputField
                            mb="0px"
                            id="roleId"
                            placeholder="Enter Other Value"
                            label="Other"
                            value={roleOtherValue}
                            onChange={e => {
                              setRoleOtherValue(e.target.value);
                            }}
                          />
                        ) : null}
                      </>
                    ))}
                  </SimpleGrid>
                </Stack>
                <Flex justify="space-between" mt="24px">
                <Button
                    variant="light"
                    fontSize="sm"
                    borderRadius="16px"
                    w={{ base: '128px', md: '148px' }}
                    h="46px"
                    onClick={() => {
                      typesTab.current.click();
                      dispatch(setWizardLevel(1));
                    }}
                  >
                    Prev
                  </Button>         
                  <Button
                    variant="darkBrand"
                    fontSize="sm"
                    borderRadius="16px"
                    w={{ base: '128px', md: '148px' }}
                    h="46px"
                    ms="auto"
                    onClick={() => {
                      perksTab.current.click();
                      dispatch(setWizardLevel(3));
                    }}
                  >
                    Next
                  </Button>
                </Flex>
              </Flex>
            </Card>
          </TabPanel>
          
          
          <TabPanel
            w={{ sm: '330px', md: '700px', lg: '700px' }}
            p="0px"
            mx="auto"
          >
            <Card p="30px">
              <Text color={textColor} fontSize="2xl" fontWeight="700" mb="20px">
                Select all the benefits and perks your company is providing
              </Text>
              <Flex direction="column" w="100%">
                <Stack direction="column" spacing="20px">
                  <SimpleGrid columns={{ base: 1, md: 3 }} gap="20px">
                    {perksArr?.map((perksData, index) => (
                      <>
                        <Checkbox
                          key={perksData?.label}
                          value={perksData?.value}
                          colorScheme="brand"
                          isChecked={perksData?.isChecked}
                          onChange={value =>
                            handleTypeRadioChange(
                              perksData?.label,
                              perksArr,
                              setPerksArr
                            )
                          }
                        >
                          {perksData?.label}
                        </Checkbox>
                        {perksData?.label === 'Other' &&
                        perksData?.isChecked === true ? (
                          <InputField
                            mb="0px"
                            id="perksId"
                            placeholder="Enter Other Value"
                            label="Other"
                            value={perksOtherValue}
                            onChange={e => {
                              setPerksOtherValue(e.target.value);
                            }}
                          />
                        ) : null}
                      </>
                    ))}
                  </SimpleGrid>
                </Stack>
                <Flex justify="space-between" mt="24px">
                  <Button
                    variant="light"
                    fontSize="sm"
                    borderRadius="16px"
                    w={{ base: '128px', md: '148px' }}
                    h="46px"
                    onClick={() => {
                      dispatch(setWizardLevel(2));
                      roleTab.current.click();
                    }}
                  >
                    Prev
                  </Button>
                  <Button
                    variant="darkBrand"
                    fontSize="sm"
                    borderRadius="16px"
                    w={{ base: '128px', md: '148px' }}
                    h="46px"
                    onClick={() => {
                      dispatch(setWizardLevel(4));
                      reachOutTab.current.click();
                    }}
                  >
                    Next
                  </Button>
                </Flex>
              </Flex>
            </Card>
          </TabPanel>
          <TabPanel
            w={{ sm: '330px', md: '700px', lg: '700px' }}
            p="0px"
            mx="auto"
          >
            <Card p="30px">
              <Text color={textColor} fontSize="2xl" fontWeight="700" mb="20px">
                How would you like us to reach out to candidates?
              </Text>
              {/* <Text color={textColor} fontSize="xs" fontWeight="700" mb="20px">
                Please write multiple emails by using Enter at the end of every
                email..
              </Text> */}
              <Flex direction="column" w="100%">
                <Stack direction="column" spacing="20px">
                  {/* <TagsInput
                    value={howToReachOut}
                    onChange={onTagChange}
                    inputProps={{ placeholder: 'Enter Email Id' }}
                  /> */}
                  <Flex w={'100%'} h={'30px'} align={'center'}>
                    <Button
                      // onClick={() => setHowToReachOut('Choice1: Company Email- You will create us an email- john.doe@yourcompany.com')}
                      onClick={() =>
                        updateHowToReachOut(
                          'Choice1: Company Email- You will create us an email- john.doe@yourcompany.com'
                        )
                      }
                      bg={
                        howToReachOut[0] ===
                        'Choice1: Company Email- You will create us an email- john.doe@yourcompany.com'
                          ? 'gray.500'
                          : brandColor
                      }
                      size="sm"
                      color={'#fff'}
                      _hover={{'bg': brandColor}}
                    >
                      Choice 1:
                    </Button>
                    <Text ml={3}>
                      Company Email- You will create us an email- john.doe@yourcompany.com
                    </Text>
                  </Flex>
                  <Flex w={'100%'} h={'30px'} align={'center'}>
                    <Button
                      // onClick={() => setHowToReachOut('Choice2: Sub-domain- We create a domain email- john.doe@yourcompanyhire.com')}
                      onClick={() =>
                        updateHowToReachOut(
                          'Choice2: Sub-domain- We create a domain email- john.doe@yourcompanyhire.com'
                        )
                      }
                      bg={
                        howToReachOut[0] ===
                        'Choice2: Sub-domain- We create a domain email- john.doe@yourcompanyhire.com'
                          ? 'gray.500'
                          : brandColor
                      }
                      color={'#fff'}
                      size="sm"
                      _hover={{'bg': brandColor}}
                    >
                      Choice 2:
                    </Button>
                    <Text ml={3}>
                      Sub-domain- We create a domain email- john.doe@yourcompanyhire.com
                    </Text>
                  </Flex>
                </Stack>
                <Flex justify="space-between" mt="24px">
                  <Button
                    variant="light"
                    fontSize="sm"
                    borderRadius="16px"
                    w={{ base: '128px', md: '148px' }}
                    h="46px"
                    onClick={() => {
                      if (howToReachOut.length > 0) {
                        perksTab.current.click();
                        dispatch(setWizardLevel(3));
                      } else {
                        toast({
                          title:
                            'Please select either one choice for the above list to proceed.',
                          status: 'error',
                          isClosable: true,
                          duration: 3000,
                        });
                      }
                    }}
                  >
                    Prev
                  </Button>
                  <Button
                    variant="darkBrand"
                    fontSize="sm"
                    borderRadius="16px"
                    w={{ base: '128px', md: '148px' }}
                    h="46px"
                    onClick={() => {
                      sisterCompanyTab.current.click();
                      dispatch(setWizardLevel(5));
                    }}
                  >
                    Next
                  </Button>
                </Flex>
              </Flex>
            </Card>
          </TabPanel>
          <TabPanel
            w={{ sm: '330px', md: '700px', lg: '700px' }}
            p="0px"
            mx="auto"
          >
            <Card p="30px">
              <Text color={textColor} fontSize="2xl" fontWeight="700" mb="20px">
              Do you have any partners we should not be recruiting talent from (Applicants that apply could be considered)
              </Text>
              <Flex direction="column" w="100%">
                <Stack direction="column" spacing="20px">
                  <TagsInput
                  // tab, enter, comma 
                    addKeys={[9, 13, 188, 186]}
                    value={companyNames}
                    onChange={onCompanyNameChange}
                    inputProps={{ placeholder: 'Press Enter, Tab or Comma(,) after entering a company name' }}
                  />
                </Stack>
                <Flex justify="space-between" mt="24px">
                  <Button
                    variant="light"
                    fontSize="sm"
                    borderRadius="16px"
                    w={{ base: '128px', md: '148px' }}
                    h="46px"
                    onClick={() => {
                      reachOutTab.current.click();
                      dispatch(setWizardLevel(4));
                    }}
                  >
                    Prev
                  </Button>
                  <Button
                    variant="darkBrand"
                    fontSize="sm"
                    borderRadius="16px"
                    w={{ base: '128px', md: '148px' }}
                    h="46px"
                    onClick={() => {
                      submitWizardValue();
                    }}
                    isDisabled={loading ? true : false}
                    isLoading={loading ? true : false}
                  >
                    Submit
                  </Button>
                </Flex>
              </Flex>
            </Card>
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Flex>
  );
}
