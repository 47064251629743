import React, { useState } from 'react';
import { Flex, Box, Text, useColorModeValue, useDisclosure, IconButton, Tooltip} from '@chakra-ui/react';
import { BiCheck, BiPencil } from 'react-icons/bi';
import ResumePdfView from '../../../business/jobWizard/jobWorkflow/Workflow/CandidateSearch/ResumePDFView';
import EditCandidateSummary from './EditCandidateSummary';
import CandidateExperience from './CandidateExperience';
import CandidateEducation from './CandidateEducation';
import CandidateSummary from '../../../common/CandidateSummary';
import CandidateSkills from './CandidateSkills';


const CandidateProfile = (props) => {
    const SchemeTextColor = useColorModeValue('secondaryGray.900', 'gray.800');
    const [candidate, setCandidate] = useState(props.candidate)
    const {job, isEditCandidate, getAllCandidatesForJob} = props;
    const [isEditSummary, setIsEditSummary] = useState(false)
    const { isOpen: isEditSummaryPanelOpen, onOpen: onEditSummaryPanelOpen, onClose: onEditSummaryPanelClose } = useDisclosure();

    const CandidateResume = () => {
        return <>
            <Flex mt={10} direction={'column'}>
                <Text color={SchemeTextColor} fontSize={'16px'} fontWeight={'600'}>Resume</Text>

                { candidate?.resume_url ?
                <Flex height={'70vh'} width={'100%'} overflowY={'auto'}
                    css={{
                    '&::-webkit-scrollbar': {
                        width: '8px',
                    },
                    '&::-webkit-scrollbar-track': {
                        width: '12px',
                    },
                    '&::-webkit-scrollbar-thumb': {
                        borderRadius: '100px',
                        background: 'gray.500',
                        border: '6px solid rgba(0, 0, 0, 0.2)'
                    },
                    }}
                >
                    <ResumePdfView pdfUrl={candidate?.resume_url} />
                </Flex>
                  : 
                  <Text as={'b'}>N/A</Text> 
                }        
            </Flex>
        </>
    }
    
    const CandidateSummaryEditButton = () => {
        return <>
            {isEditCandidate && !isEditSummary &&
            <Tooltip label={'Edit Summary'} fontSize='md'>
                <span>
                    <IconButton fontSize={'20px'} bg={'none'} _hover={{'bg': 'none'}} icon={<BiPencil />} color={'gray'}
                        onClick={() => {
                            setIsEditSummary(true)
                            onEditSummaryPanelOpen()
                        }}
                    />
                </span>
            </Tooltip>
            }
            {isEditCandidate && isEditSummary &&
            <Tooltip label={'Finish Editing Summary'} fontSize='md'>
                <span><IconButton fontSize={'24px'} bg={'none'} _hover={{'bg': 'none'}} icon={<BiCheck />} color={'gray'}
                        onClick={() => {
                            setIsEditSummary(false)
                            onEditSummaryPanelClose()
                        }}
                    />
                </span>
            </Tooltip>
            }
        </>
    }

    return (
        <Box>                
            <Flex w={'100%'} direction={'column'}>
                <Flex direction={'column'}>
                    <Text color={SchemeTextColor} fontSize={'16px'} mb={2} fontWeight={'600'}>Summary</Text>
                    <Flex>
                        <CandidateSummary summary={candidate?.summary} />
                        <CandidateSummaryEditButton />
                    </Flex>
                </Flex>
                <CandidateExperience 
                    candidate={candidate} job={job}
                    isEditCandidate={isEditCandidate}
                    getAllCandidatesForJob={getAllCandidatesForJob} 
                />
                <CandidateEducation 
                    candidate={candidate} job={job}
                    isEditCandidate={isEditCandidate}
                    getAllCandidatesForJob={getAllCandidatesForJob} 
                />
                <CandidateResume />
                <CandidateSkills
                    candidate={candidate} job={job}
                    isEditCandidate={isEditCandidate}
                    getAllCandidatesForJob={getAllCandidatesForJob}
                />
            </Flex> 

            <EditCandidateSummary
                candidate={candidate}
                job={job}
                onEditSummaryPanelOpen={onEditSummaryPanelOpen}
                onEditSummaryPanelClose={onEditSummaryPanelClose}
                isEditSummaryPanelOpen={isEditSummaryPanelOpen}
                getAllCandidatesForJob={getAllCandidatesForJob}
            />        
        </Box>
    );
}

export default CandidateProfile;
