import React, { useState } from 'react';
// import 'react-quill/dist/quill.snow.css';
import { useLocation } from 'react-router-dom';
import { borderBottomColor } from '../../../../../../constants/index';
import { getEmailId } from '../../../../../../utils/localStorageIndex';
import { Box, Text, Button, Switch, Flex, useToast, Textarea } from '@chakra-ui/react';
import { updateCandidateDetails } from '../../../../../../services/jobTemplateService';
function CandidateDetails(props) {


  const toast = useToast();
  const emailId = getEmailId();
  const { state } = useLocation();
  const adminSettings = state?.adminSettings;
  const { candidate_uuid, job_uuId, details } = props;
  const [content, setContent] = useState(details);
  const [checked, isChecked] = useState(false);
  const [loading, isLoading] = useState(false);

  const updateDetails = () => {
    isLoading(true);
    let obj = {
      email: emailId,
      job_uuid: job_uuId,
      candidate_uuid: candidate_uuid,
      details: content,
    };
    updateCandidateDetails(obj, adminSettings)
      .then(res => {
        isLoading(false);
        if (res.result) {
          toastFunction(res.message, 'success');
          props?.sendDataToParent('candidate-state-changed');
        } else {
          toastFunction(res.message, 'error');
        }
      })
      .catch(err => {
        isLoading(false);
        toastFunction(err.message, 'error');
      });
  };
  const onUpdateField = e => {
    setContent(e.target.value);
  };

  const toastFunction = (message, status) => {
    return toast({
      title: message,
      status: status,
      isClosable: true,
      duration: 3000,
    });
  };
  return (
    <Box>
      <Text
        fontSize={20}
        p={5}
        borderBottomWidth="0.5px"
        borderBottomColor="grey.400"
      >
        Candidate Details
      </Text>
      <Box mb="2" borderBottomWidth="0.5px" borderBottomColor="grey.400">
        <Box p="20px">
          <Flex>
            <Switch
              size="lg"
              mb="20px"
              onChange={() => {
                isChecked(!checked);
              }}
              isChecked={checked}
              colorScheme="green"
            />
            <Text ml="3">
              {checked ? 'View Candidate Details' : 'Edit Candidate Details'}
            </Text>
          </Flex>
          {checked ? (
            <>
              <Textarea
                style={{ margin: '10px', background: 'white' }}
                value={content}
                rows={20}
                onChange={onUpdateField}
              />
            </>
          ) : (
            <Text
              fontSize={18}
              h={'50vh'}
              overflow="auto"
              css={{
                '&::-webkit-scrollbar': {
                  width: '4px',
                },
                '&::-webkit-scrollbar-track': {
                  width: '6px',
                },
                '&::-webkit-scrollbar-thumb': {
                  background: '#fff',
                  borderRadius: '24px',
                },
              }}
            >
              {details.replace(/<[^>]*>/g, '').length
                ? details.replace(/<[^>]*>/g, '')
                : 'No Details Found'}
            </Text>
          )}
        </Box>
      </Box>
      <Box p={3}>
        <Button
          size="sm"
          colorScheme="red"
          borderRadius="5px"
          mr={3}
          onClick={() => {
            props?.sendDataToParent('close');
          }}
        >
          Cancel
        </Button>
        {checked ? (
          <Button
            size="sm"
            borderRadius="5px"
            colorScheme="green"
            onClick={() => {
              updateDetails();
            }}
            isDisabled={loading ? true : false}
            isLoading={loading ? true : false}
          >
            Save Candidate Details
          </Button>
        ) : null}
      </Box>
    </Box>
  );
}

export default CandidateDetails;
