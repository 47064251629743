import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {components} from 'react-select';
import AsyncSelect from 'react-select/async';
import { Flex, IconButton, Text, useToast } from '@chakra-ui/react';
import { fetchAllCandidates } from '../../services/recruiterServices';
import { getEmailId } from '../../utils/localStorageIndex';
import CandidateHeadshot from '../../tscomponents/common/CandidateHeadshot'
import { workflowStagesMapping } from '../../utils/common'
import { MdSearch } from 'react-icons/md';

export default function CandidateSearch (props) {

    const toast = useToast()
    const navigate = useNavigate()
    const [searchQuery, setSearchQuery] = useState(null)
    const [searchBarOn, setSearchBarOn] = useState(false)
    const searchBarDropDownStyles = {
        control: (baseStyles, state) => ({
          ...baseStyles,
          width: '350px',
          marginRight: '10px',
          padding: '0px',
          cursor: 'text',
        }),
        menu: styles => ({ 
          ...styles,
          zIndex: 999,
          width: '350px',
          padding: '0px',
          border: '1px solid',
          // height: '500px !important',
         }),
        option: (styles, state) => ({
          ...styles,
          cursor: 'pointer',
          padding: '0px'
        }),
      }

    const Option = (props) => {
      const candidate = props.data.value
        return (
          <components.Option {...props}>
            <span>
              <Flex padding={'5px 10px'}>                
                <CandidateHeadshot candidate={candidate} width={'40px'} height={'40px'} />
                <Flex direction={'column'}>
                  <Text as='b'>{candidate?.first_name} {candidate?.last_name}</Text>
                  <Text>in {candidate?.job?.job_title} - {workflowStagesMapping[candidate?.workflow?.workflow_position]}</Text>
              </Flex>
              </Flex>            
            </span>
          </components.Option>
        );
    };

    const loadCandidates = async (searchQuery) => {
        const searchPayload = {
            email: getEmailId(),
            q: searchQuery,
        }
        return fetchAllCandidates(searchPayload).then(res => {
          if (res?.result){
            return res?.data?.records?.map(candidate => {
              return {label: `${candidate?.first_name} ${candidate?.last_name}`, value: candidate}
            })
          }else{
            toast({
              title: 'Unable to search candidate',
              description: res?.message,
              status: 'error',
              duration: 5000
          })
          }         
        }).catch(err => {
            toast({
                title: 'Unable to search candidate',
                description: err?.toString(),
                status: 'error',
                duration: 5000
            })
        })
      };

    const onChangeSearchQuery = (newValue) => {
      const inputValue = newValue.replace(/\\\\W/g, '');
      setSearchQuery(inputValue);
      return inputValue;
    };

    const onSelectCandidate = (selected) => {
      const candidate = selected?.value
      navigate('/admin/searchedcandidate', {
        state: {
            candidate: candidate,
            adminSettings: true
        },
      });
    }
  
    return <>
        <Flex mr={5} alignItems={'center'}>        
        {searchBarOn &&
        <AsyncSelect
          maxMenuHeight={'370px'}
          onInputChange={onChangeSearchQuery}
          onChange={onSelectCandidate}
          inputValue={searchQuery}
          value={null}
          styles={searchBarDropDownStyles}
          placeholder= 'Search candidate'
          noOptionsMessage={() => "Please try a keyword to match candidate name, email, phone, current job, highest education, current company or linked profile URL."}
          loadOptions={loadCandidates}
          components={{ Option }}
        />
        }
        <IconButton size={'sm'} as={MdSearch} _hover={{'cursor': 'pointer'}} onClick={() => setSearchBarOn(!searchBarOn)} />
        </Flex>
    </>
}