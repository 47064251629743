import { Button } from "@chakra-ui/button";
import { Flex } from "@chakra-ui/layout";
import React, { useState, useEffect } from "react";


const Pagination = ({ pageChangeHandler, totalRows, rowsPerPage, page }) => {
  const noOfPages = Math.ceil(totalRows / rowsPerPage);
  const pagesArr = [...new Array(noOfPages)];
  const [currentPage, setCurrentPage] = useState(page);
  const [canGoBack, setCanGoBack] = useState(false);
  const [canGoNext, setCanGoNext] = useState(true);
  const onNextPage = () => setCurrentPage(currentPage + 1);
  const onPrevPage = () => setCurrentPage(currentPage - 1);
  const onPageSelect = (pageNo) => setCurrentPage(pageNo);

  useEffect(() => {
    if (noOfPages === currentPage) {
      setCanGoNext(false);
    } else {
      setCanGoNext(true);
    }
    if (currentPage === 1) {
      setCanGoBack(false);
    } else {
      setCanGoBack(true);
    }
  }, [noOfPages, currentPage]);


  useEffect(() => {
    pageChangeHandler(currentPage);
  }, [currentPage]);

  return (
    <>
      {noOfPages > 1 ? (
        <Flex justifyContent={'center'} mt={6}>
            <Button
              width={'40px'}
              height={6}
              mr={1}
              onClick={canGoBack ? onPrevPage : null}
              _hover={{bg: canGoBack ? 'gray.200' : 'gray.100'}}
            >
              &#8249;
            </Button>
            {pagesArr.map((num, index) => (
              <Button
                mr={1}
                width={'40px'}
                height={6}
                bg={currentPage === index+1 ? 'gray.200' : 'gray.100'}
                onClick={() => currentPage !== index+1 ? onPageSelect(index + 1) : null}
              >
                {index + 1}
              </Button>
            ))}
            <Button
              width={'40px'}
              height={6}
              _hover={{bg: canGoNext ? 'gray.200' : 'gray.100'}}
              onClick={canGoNext ? onNextPage : null}
            >
              &#8250;
            </Button>
        </Flex>
      ) : null}
    </>
  );
};

export default Pagination;