// https://www.computerhope.com/htmcolor.htm
export const themeColor = '#3780c2';
export const errorColor = '#e6474a';
export const borderBottomColor = '#494552';
export const defaultThemeColor = '#3780c2'; // #c552ff
export const themeFontSizeXlHeading = '16px';
export const themeFontSizeLargeHeading = '14px';
export const themeFontSizeMediumHeading = '12px';
export const themeFontSizeOddHeading = '11px';
export const themeFontSizeSmallHeading = '10px';
export const themeFontSizeXSHeading = '8px';

export const roleAccess = [
  { roleUser: 'Admin', groupId: '100' },
  { roleUser: 'Recruiter', groupId: '200' },
  { roleUser: 'Business', groupId: '300' },
  { roleUser: 'HR', groupId: '400' },
];

export const userRole = [
  {
    roleName: 'Home',
    group: ['100', '200', '300', '400'],
  },
  {
    roleName: 'Recruiter',
    group: ['100', '200'],
  },
  {
    roleName: 'Business',
    group: ['300'],
  },
  {
    roleName: 'HR',
    group: ['400'],
  },
  {
    roleName: 'Admin',
    group: ['100'],
  },
];
