export const columnsDataUsersOverview = [
  {
    Header: "USER NAME",
    accessor: "name",
  },
  {
    Header: "EMAIL",
    accessor: "email",
  },
  // {
  //   Header: "DISABLED STATUS",
  //   accessor: "status",
  // },
  {
    Header: "ROLE",
    accessor: "given_role",
  },
  // {
  //   Header: "JOIN DATE",
  //   accessor: "date",
  // },
  {
    Header: "DEPARTMENT",
    accessor: "department_name",
  },
  {
    Header: "USER TYPE",
    accessor: "type",
  },
  // {
  //   Header: "ACTIONS",
  //   accessor: "actions",
  // },
];
