import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import React, { useEffect, useMemo, useState } from 'react';
import { setJobUUId } from '../../../../store/actions/topNotificationActions';
import { SearchBar } from '../../../../components/navbar/searchBar/SearchBar';
import { AiOutlineEdit, AiOutlineSync, AiOutlineUser, AiOutlineUserAdd } from 'react-icons/ai';
import { useGlobalFilter, usePagination, useSortBy, useTable} from 'react-table';
import { Box, Spacer, Modal, VStack, Select, ModalBody, ModalContent, ModalCloseButton, ModalHeader, ModalFooter,
  ModalOverlay, Button, Flex, Icon, Badge, Table, Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  Alert,
  Switch,
  AlertIcon,
  AlertTitle,
  useToast,
  useDisclosure,
  AlertDescription,
  useColorModeValue,
} from '@chakra-ui/react';
import { MdOutlineLocationOn} from 'react-icons/md';
import { NavLink } from 'react-router-dom';
import { jobWorkflowStarted } from '../../../../services/jobTemplateService';
import { getEmailId, saveJob } from '../../../../utils/localStorageIndex';
import { jobWorkflowStatus } from '../../../../services/jobTemplateService';
import { startInformation, fetchBusinessInformation } from '../../../../services/businessServices';
import BusinessSurveyWizard from '../../settings/configuration/components/BusinessSurveyWizard';
import Completion from '../../settings/configuration/components/Completion';
import { customNotification } from '../../../../utils/customNotification';
import { toTitleCase } from '../../../../utils/common';
import { render } from 'preact/compat';

function JobTableList(props) {
  const toast = useToast();
  const dispatch = useDispatch();

  const { columnsData, tableData } = props;
  const jobsOpenCount = props?.openJobsCount;
  const jobsTotalCount = props?.totalJobsCount;
  const jobsSlotCount = props?.totalSlotCount;
  const archivedCount = props?.archivedCount;

  const navigate = useNavigate();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [currentPage, setCurrentPage] = useState('Open');
  const [businessUserData, setBusinessUserData] = useState(tableData);
  const columns = useMemo(() => columnsData, [columnsData]);
  const data = useMemo(() => businessUserData, [businessUserData]);
  const [jobId, setJobId] = React.useState('');
  const [jobTitle, setJobTitle] = React.useState(null);
  const [workflowLoading, setWorkfloLoading] = React.useState(false);
  const [loading, isLoading] = useState(false);
  const [addJobStatus, setAddJobStatus] = useState(false);
  const [modalFlag, setModalFlag] = useState('');
  const [workflowStatus, setWorkflowStatus] = useState(null);
  const [selectedCandidateId, setSelectedCandidateId] = useState(null);
  const [businessSurvery, setBusinessSurvey] = useState(false);

  const [currentJobStatus, setCurrentJobStatus] = useState('Draft');
  const [currentJobBoxColor, setCurrentJobBoxColor] = useState('orange');
  const [launchTeamBilling, setLaunchTeamBilling] = useState(false);

  const schemeTextColor = useColorModeValue('secondaryGray.900', 'gray.400');
  const schemeBgColor = useColorModeValue('gray.500', 'gray.200');
  const schemeHoverTextColor = useColorModeValue('gray.200', 'gray.800');
  const schemeBorderColor = useColorModeValue('gray.200', 'gray.600');

  const [modalDialogInfo, setModalDialogInfo] = useState({
    header: 'Missing Business Plan',
    message:
      'You must select a business plan and purchase recruitment slots to add new jobs.',
    button: true,
    buttonTitle: 'Buy Now',
  });
  useEffect(() => {
    dispatch(setJobUUId(''));
  }, []);

  // const updateJobData = (currentSetting) => {
  //     let cloneArr = [...masterJobdata];
  //     setBusinessUserData( cloneArr.filter(jobItem => jobItem.job_status === currentSetting));
  // }

  const tableInstance = useTable(
    {
      columns,
      data,
      manualPagination: true
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    setGlobalFilter,
    state,
  } = tableInstance;

  const enableDisableJobWorkflow = (uuId, status) => {
    const updatedArr = businessUserData.map(singleRowObj =>
      singleRowObj.job_uuid === uuId
        ? { ...singleRowObj, job_enabled: !status }
        : singleRowObj
    );
    setBusinessUserData(updatedArr);
    let objBody = {
      email: props.emailId,
      job_uuid: uuId, // Please add the select row UUID
      job_status: status ? 'false' : 'true', // please add switch state
    };
    jobWorkflowStatus(objBody)
      .then(res => {
        if (res.result) {
          toast({
            title: res?.message,
            status: 'success',
            isClosable: true,
            duration: 3000,
          });
          props.onDataReceived();
          props.handleCallBack();
          let notification = {
            priority: 'critical',
            message: `The following position is now closed: ${jobTitle}. Please let us know if you have any quesitons. Thank you!`,
            action: 'OK',
          };
          customNotification(dispatch, notification);
        } else {
          toast({
            title: res?.message,
            status: 'error',
            isClosable: true,
            duration: 3000,
          });
        }
      })
      .catch(err => {
        toast({
          title: err?.message,
          status: 'error',
          isClosable: true,
          duration: 3000,
        });
      });
  };

  const editJobDetails = (job_uuId, e) => {
    e.preventDefault();
    dispatch(setJobUUId(job_uuId));
    navigate('/business/editJobs', {
      state: {
        job_uuid: job_uuId,
      },
    });
  };

  const viewJobDetails = (job_uuId, e) => {
    e.preventDefault();
    dispatch(setJobUUId(job_uuId));
    navigate('/business/viewJob', {
      state: {
        job_uuid: job_uuId,
      },
    });
  };

  const navigateToView = (navigateUrl, data) => {
    saveJob(data);
    navigate(navigateUrl, {
      state: {
        job_uuid: data?.job_uuid,
        job_title: data?.job_title,
        job_title_other: data?.job_title_other,
      },
    });
  };

  const saveData = () => {
    const updatedArr = businessUserData.map(singleRowObj =>
      singleRowObj.job_uuid === jobId
        ? { ...singleRowObj, job_workflow_started: true }
        : singleRowObj
    );
    setBusinessUserData(updatedArr);
    setWorkfloLoading(true);
    let obj = {
      email: getEmailId(),
      job_uuid: jobId,
    };
    jobWorkflowStarted(obj)
      .then(res => {
        setWorkfloLoading(false);
        if (res.result) {
          onCloseModal();
          toast({
            title: res.message,
            status: 'success',
            isClosable: true,
            duration: 3000,
          });
          props.onDataReceived();
          props.handleCallBack();
        } else {
          setWorkfloLoading(false);
          onCloseModal();
          toast({
            title: res.message,
            status: 'error',
            isClosable: true,
            duration: 3000,
          });
        }
      })
      .catch(err => {
        setWorkfloLoading(false);
        toast({
          title: err.message,
          status: 'error',
          isClosable: true,
          duration: 3000,
        });
        onCloseModal();
      });
  };

  const checkSlotValidation = () => {
    let obj = {
      email: getEmailId(),
    };
    isLoading(true);
    setLaunchTeamBilling(false);
    startInformation(obj)
      .then(res => {
        isLoading(false);
        if (res?.result) {
          if (res?.data?.total_slots) {
            if (res?.data?.total_slots > jobsOpenCount) {
              validateSurveyDetails();
            } else {
              if (res?.data?.limited) {
                let dialogJson;
                if (res?.data?.do_again) {
                  if (res?.data?.can_do) {
                    if (res?.data?.slot_mgmt) {
                      setLaunchTeamBilling(true);
                      dialogJson = {
                        header: 'Add More Slots',
                        message:
                          'All purchased slots are in use. Close an existing job slot to free up space or use your permissions to buy additional slots. ',
                        button: true,
                        buttonTitle: 'Add More Slots',
                      };
                    } else {
                      dialogJson = {
                        header: 'Add More Slots',
                        message:
                          'All slots are currently in use. Please add more slots or contact your Admin to proceed.',
                        button: false,
                        buttonTitle: '.......',
                      };
                    }
                  } else {
                    dialogJson = {
                      header: 'Add More Slots',
                      message:
                        'All slots are currently in use. Please add more slots or contact your Admin to proceed.',
                      button: false,
                      buttonTitle: '.....',
                    };
                  }
                } else {
                  dialogJson = {
                    header: 'Add More Slots',
                    message:
                      'All slots are currently in use. Please add more slots or contact your Admin to proceed.',
                    button: false,
                    buttonTitle: '....',
                  };
                }
                setModalDialogInfo(dialogJson);
              } else {
                let dialogJson;
                if (res?.data?.do_again) {
                  dialogJson = {
                    header: 'No More Slots Left',
                    message:
                      'All purchased slots are currently in use by your team. Please free up existing slots or buy additional ones.',
                    button: true,
                    buttonTitle: 'Add More Slots',
                  };
                } else {
                  dialogJson = {
                    header: 'Getting Started',
                    message:
                      'To begin, please upgrade to a Business Plan by adding slots.',
                    button: true,
                    buttonTitle: 'Add Slots',
                  };
                }
                setModalDialogInfo(dialogJson);
              }
              setAddJobStatus(true);
              onOpen();
            }
          } else {
            let dialogJson;
            if (res?.data?.limited) {
              dialogJson = {
                header: 'Business Plan Required',
                message:
                  'Please add slots to get started. If you do not have access to add slots, you will need to request support from an Admin to proceed.',
                button: false,
                buttonTitle: '.....',
              };
            } else {
              dialogJson = {
                header: 'Getting Started',
                message:
                  'To begin, please upgrade to a Business Plan by adding slots.',
                button: true,
                buttonTitle: 'Add Slots',
              };
            }
            setModalDialogInfo(dialogJson);
            setAddJobStatus(true);
            onOpen();
          }
        }
      })
      .catch(err => {
        isLoading(false);
        setAddJobStatus(false);
        toastFunction(err?.message, 'error');
      });
  };

  // Todo:Validate If Survey has been completed
  const validateSurveyDetails = () => {
    let emailId = { email: getEmailId() };
    fetchBusinessInformation(emailId)
      .then(res => {
        let surveyData = JSON.parse(res?.data[0].survey);
        let limitedUser = res?.data[0].limited;
        if (surveyData != null && surveyData != undefined) {
          const firstThreeQuestionsArr = surveyData?.sections.slice(0, 3);
          for (const item of firstThreeQuestionsArr) {
            if (item.options.length === 0) {
              onOpen();
              if (limitedUser) {
                setModalFlag('survey_warning_limited');
                setBusinessSurvey(false);
              } else {
                setModalFlag('survey_warning');
                setBusinessSurvey(true);
              }
              return false;
            }
          }
          setBusinessSurvey(false);
          navigate('/business/addJobWizard');
          return true;
        } else {
          onOpen();
          if (limitedUser) {
            setModalFlag('survey_warning_limited');
            setBusinessSurvey(false);
          } else {
            setModalFlag('survey_warning');
            setBusinessSurvey(true);
          }
          return false;
        }
      })
      .catch(err => {
        toastFunction(err?.message, 'error');
      });
  };
  // Todo:Check Survey details
  const handleModalConfiguration = data => {
    if (data === true) {
      onClose();
      // checkSlotValidation();
      // setModalFlag('add_new_job');
    }
  };

  const verifySlotStatus = (uuId, status) => {
    onOpen();
    setWorkflowStatus(status);
    setSelectedCandidateId(uuId);
  };

  const onCloseModal = () => {
    onClose();
    setAddJobStatus(false);
    setModalFlag('');
    setWorkflowStatus(null);
    setSelectedCandidateId(null);
  };

  const handleJobStatusChange = (value, tableDataRow) => {
    if (jobsSlotCount - jobsOpenCount <= 0 && value === 'Open'){
      let dialogJson = {
            header: 'No More Slots Left',
            message: 'You do not have any available slots. You will need to close an open job or upgrade your subscription to add more slots.',
            button: false
          };
      setModalDialogInfo(dialogJson);
      setAddJobStatus(true);
      onOpen();
      // toast({
      //   title: "You do not have any open slots to open a closed job. Please buy new slots or close other open slots to create room for open jobs.",
      //   status: 'error',
      //   isClosable: true,
      //   duration: 5000,
      // });
    } else {
      setCurrentJobStatus(value);
      setJobTitle(tableDataRow.job_title)
      if (value === 'Draft') {
        // Do Nothing
      } else if (value === 'Open') {
        onOpen();
        setModalFlag('start_sourcing');
        setJobId(tableDataRow.job_uuid);
        setAddJobStatus(false);
        setCurrentJobBoxColor('green.500');
      } else if (value === 'Closed') {
        onOpen();
        setModalFlag('enable_disable_workflow');
        setWorkflowStatus(tableDataRow.job_status);
        setSelectedCandidateId(tableDataRow.job_uuid);
      } else {
        setCurrentJobBoxColor('orange');
      }
    }
  };

  const displayStatus = statusValue => {
    if (statusValue === 'Draft') {
      return (
        <Text
          rounded={'md'}
          px={2}
          bg={'orange'}
          color={'gray.600'}
          fontSize="md"
          fontWeight="500"
        >
          {statusValue}
        </Text>
      );
    } else if (statusValue === 'Open') {
      return (
        <Text
          rounded={'md'}
          px={2}
          bg={'green'}
          color={'gray.300'}
          fontSize="md"
          fontWeight="500"
        >
          {statusValue}
        </Text>
      );
    } else if (statusValue === 'Closed') {
      return (
        <Text
          rounded={'md'}
          px={2}
          bg={'red.300'}
          color={'gray.600'}
          fontSize="md"
          fontWeight="500"
        >
          {statusValue}
        </Text>
      );
    } else {
      return (
        <Text
          rounded={'md'}
          px={2}
          bg={'gray.200'}
          color={'gray.700'}
          fontSize="md"
          fontWeight="500"
        >
          {statusValue}
        </Text>
      );
    }
  };

  const launchTeamBillingPage = () => {
    navigate('/business/billing/addmoreslots', {
      state: {
        sourcePage: 'teamaddslots',
        customerEmail: getEmailId(),
      },
    });
  };

  const toastFunction = (message, status) => {
    return toast({
      title: message,
      status: status,
      isClosable: true,
      duration: 3000,
    });
  };

  const renderJobLocation = job => {
    const location = job.job_location_ex
    
    if (!location){
      return null;
    }

    let renderedLocation = toTitleCase(job.job_location_ex.replaceAll('_', ' '))

    if (location == 'general_location' && job?.job_location){
      renderedLocation = `${renderedLocation} - ${job?.job_location}`
    }

    return renderedLocation;
  }

  return (
    <>
      <Flex
        direction="column"
        w="100%"
        overflowX={{ sm: 'scroll', lg: 'hidden' }}
      >
        <Flex
          align={{ sm: 'flex-start', lg: 'flex-start' }}
          justify="space-between"
          w="100%"
          px="22px"
          mb="36px"
        >
          <Text fontWeight={'bold'} fontSize={'18px'}>
            All Jobs
          </Text>
          <Text fontSize={'18px'} ml={1}>
            {/* (Open/Draft Jobs: {jobsOpenCount}, Total Jobs: {jobsTotalCount}, Purchase Slots: {jobsSlotCount}) */}
            (Available Slots: {Math.max(0, jobsSlotCount - jobsOpenCount)})
          </Text>
          <Spacer />
          <Flex>
            {' '}
            <SearchBar
              onChange={e => setGlobalFilter(e.target.value)}
              h="44px"
              w={{ lg: '390px' }}
              borderRadius="16px"
            />
            <Button
              variant="action"
              px="24px"
              bg={'#1E90FF'}
              // colorScheme="blue"
              color={'white'}
              fontSize="sm"
              fontWeight="700"
              borderRadius="0px"
              ml="2"
              rounded={'md'}
              isDisabled={loading ? true : false}
              isLoading={loading ? true : false}
              onClick={() => {
                checkSlotValidation();
                setModalFlag('add_new_job');
              }}
            >
              Add New Job
            </Button>
            {/* {archivedCount > 0 ? (
              <Button
                variant="action"
                px="24px"
                bg={'red.200'}
                // colorScheme="blue"
                color={'gray.600'}
                fontSize="sm"
                fontWeight="700"
                borderRadius="0px"
                ml="2"
                rounded={'md'}
                isDisabled={loading ? true : false}
                isLoading={loading ? true : false}
                onClick={() => {
                  props.handleCallBack('arc');
                }}
              >
                Archived ({archivedCount})
              </Button>
            ) : null} */}
            <Button
              variant="action"
              px="12px"
              fontSize="sm"
              fontWeight="700"
              borderRadius="0px"
              ml="2"
              rounded={'md'}
              isDisabled={loading ? true : false}
              isLoading={loading ? true : false}
              onClick={() => {
                props.handleCallBack('all');
              }}
            >
              {' '}
              <Icon as={AiOutlineSync} />
            </Button>
            {businessSurvery ? (
              <Button
                variant="action"
                colorScheme="blue"
                px="24px"
                fontSize="sm"
                fontWeight="700"
                borderRadius="0px"
                ml="2"
                rounded={'md'}
                onClick={() => {
                  onOpen();
                  setModalFlag('complete_survey');
                }}
              >
                Complete Business Survey
              </Button>
            ) : null}
          </Flex>
        </Flex>
        {/* <Flex w={'100%'} py={2}>
          <Flex w={"100%"} justifyContent={"end"} pr={4}>
              <Flex borderWidth={"1px"} borderRadius={"10px"}>
              <HStack
                  w={"100px"}
                  borderTopLeftRadius={"10px"}
                  borderBottomLeftRadius={"10px"}
                  _hover={{ cursor: "pointer" }}
                  onClick={() => {
                      setCurrentPage("Open");
                      updateJobData("Open");
                  }}
                  bg={currentPage === "Open" ? "green.200" : "gray.200"}
                  boxShadow={currentPage === "Open" ? "md" : ""}
                  h={"30px"}
                  m={0}
                  px={2}
                  fontSize={"14px"}
              >
                  <Text w="100%" textAlign="center">
                    {currentPage === 'Open' ?<Icon as={MdCheckBox} mr={2} /> : null}
                    Open
                  </Text>
              </HStack>
              <HStack
                  w={"100px"}
                  bg={currentPage === "Draft" ? "yellow.300" : "gray.200"}
                  _hover={{ cursor: "pointer" }}
                  onClick={() => {
                      setCurrentPage("Draft");
                      updateJobData("Draft");
                    }
                  }
                  boxShadow={currentPage === "Draft" ? "md" : ""}
                  h={"30px"}
                  px={2}
                  fontSize={"14px"}
              >
                  <Text w="100%" textAlign="center">
                    {currentPage === 'Draft' ?<Icon as={MdCheckBox}  mr={2} /> : null}
                    Draft
                  </Text>
              </HStack>
              <HStack
                  w={"100px"}
                  bg={currentPage === "Closed" ? "red.300" : "gray.200"}
                  borderTopRightRadius={"10px"}
                  borderBottomRightRadius={"10px"}
                  _hover={{ cursor: "pointer" }}
                  onClick={() => {
                    setCurrentPage("Closed");
                    updateJobData("Closed");
                  }}
                  boxShadow={currentPage === "Closed" ? "md" : ""}
                  h={"30px"}
                  px={2}
                  fontSize={"14px"}
              >
                  <Text w="100%" textAlign="center">
                    {currentPage === 'Closed' ?<Icon as={MdCheckBox}  mr={2} /> : null}
                    Closed
                  </Text>
              </HStack>
              </Flex>
          </Flex>          
        </Flex> */}
        {jobsTotalCount ? (
          <>
            <Table {...getTableProps()} variant="simple" color="gray.500">
              <Thead>
                {headerGroups.map((headerGroup, index) => (
                  <Tr {...headerGroup.getHeaderGroupProps()} key={index}>
                    {headerGroup.headers.map((column, index) => (
                      <Th
                        // Header OnClick event disabled as it is not working
                        // {...column.getHeaderProps(column.getSortByToggleProps())}
                        pe="10px"
                        key={index}
                        borderColor={schemeBorderColor}
                      >
                        <Flex
                          justify="space-between"
                          align="center"
                          fontSize={{ sm: '10px', lg: '12px' }}
                          color="gray.400"
                        >
                          {column.render('Header')}
                        </Flex>
                      </Th>
                    ))}
                  </Tr>
                ))}
              </Thead>
              {page.length == 0 ? (
                <Text ml={5} mt={5}>
                  There are no jobs{' '}
                </Text>
              ) : null}
              <Tbody {...getTableBodyProps()}>
                {page.map((row, index) => {
                  prepareRow(row);
                  //
                  return (
                    <Tr {...row.getRowProps()} key={index}>
                      {row.cells.map((cell, subIndex) => {
                        let data = '';
                        let cell_bg_color = null;
                        if (cell.column.Header === 'Job Details') {
                          data = (
                            <Flex minWidth={'600px'} direction={'column'}>
                              <Flex
                                w={'100%'}
                                justify="space-between"
                                align="start"
                                py={2}
                              >
                                <Text
                                  minWidth={'200px'}
                                  _hover={{
                                    bg: schemeBgColor,
                                    color: schemeHoverTextColor,
                                    cursor: 'pointer',
                                    rounded: 'lg',
                                    p: 1,
                                  }}
                                  onClick={e => {
                                    navigateToView(
                                      '/business/jobsourcing',
                                      tableData[index]
                                    );
                                  }}
                                  p={1}
                                  color={schemeTextColor}
                                  fontSize="lg"
                                  fontWeight="500"
                                >
                                  {cell.value}
                                </Text>
                                {/* <Flex>
                                  <Text mr={2}>Current Sourcing Status:</Text>
                                  {tableData[index].job_workflow_started ? (
                                    <Badge px={1} py={1} color={'white'} bg={'green.500'}>
                                      {' '}
                                      YES
                                    </Badge>
                                  ) : (
                                    <Badge px={1} py={1} color={'white'} bg={'red.500'}>
                                      {' '}
                                      NO
                                    </Badge>)}
                                </Flex> */}
                              </Flex>
                              <Flex
                                minWidth={'600px'}
                                p={2}
                                borderWidth={'0.5px'}
                                borderColor={schemeBorderColor}
                                rounded={'lg'}
                                justify="space-between"
                                align="center"
                                fontSize={{ sm: '10px', lg: '12px' }}
                                color="gray.500"
                              >
                                <VStack>
                                  <Text>Sourcing</Text>
                                  {tableData[index].count[0]
                                    .candidate_sourced === 0 ? (
                                    <Badge>
                                      {
                                        tableData[index].count[0]
                                          .candidate_sourced
                                      }
                                    </Badge>
                                  ) : (
                                    <Badge
                                      bg={'green.100'}
                                      color={'gray.700'}
                                    >
                                      {
                                        tableData[index].count[0]
                                          .candidate_sourced
                                      }
                                    </Badge>
                                  )}
                                </VStack>
                                <VStack ml={1} mr={1}>
                                  <Text>Screening</Text>
                                  {tableData[index].count[1]
                                    .candidate_screening === 0 ? (
                                    <Badge>
                                      {
                                        tableData[index].count[1]
                                          .candidate_screening
                                      }
                                    </Badge>
                                  ) : (
                                    <Badge
                                      bg={'green.100'}
                                      color={'gray.700'}
                                      cursor={'pointer'}
                                      onClick={e => {
                                        navigateToView(
                                          '/business/jobsourcing',
                                          tableData[index]
                                        );
                                      }}
                                    >
                                      {
                                        tableData[index].count[1]
                                          .candidate_screening
                                      }
                                    </Badge>
                                  )}
                                </VStack>
                                <VStack ml={1} mr={1}>
                                  <Text>Ready To Interview</Text>
                                  {tableData[index].count[2]
                                    .ready_to_interview === 0 ? (
                                    <Badge>
                                      {
                                        tableData[index].count[2]
                                          .ready_to_interview
                                      }
                                    </Badge>
                                  ) : (
                                    <Badge
                                      bg={'green.100'}
                                      color={'gray.700'}
                                      cursor={'pointer'}
                                      onClick={e => {
                                        navigateToView(
                                          '/business/jobsourcing',
                                          tableData[index]
                                        );
                                      }}
                                    >
                                      {
                                        tableData[index].count[2]
                                          .ready_to_interview
                                      }
                                    </Badge>
                                  )}
                                </VStack>
                                <VStack ml={1}>
                                  <Text>Interviewing</Text>
                                  {tableData[index].count[4]
                                    .scheduled_for_interview === 0 ? (
                                    <Badge>
                                      {
                                        tableData[index].count[4]
                                          .scheduled_for_interview
                                      }
                                    </Badge>
                                  ) : (
                                    <Badge
                                      bg={'green.100'}
                                      color={'gray.700'}
                                      cursor={'pointer'}
                                      onClick={e => {
                                        navigateToView(
                                          '/business/jobsourcing',
                                          tableData[index]
                                        );
                                      }}
                                    >
                                      {
                                        tableData[index].count[4]
                                          .scheduled_for_interview
                                      }
                                    </Badge>
                                  )}
                                </VStack>
                                <VStack ml={1}>
                                  <Text>Offered</Text>
                                  {tableData[index].count[7].candidate_hired ===
                                  0 ? (
                                    <Badge>
                                      {
                                        tableData[index].count[7]
                                          .candidate_hired
                                      }
                                    </Badge>
                                  ) : (
                                    <Badge
                                      bg={'green.100'}
                                      color={'gray.700'}
                                      cursor={'pointer'}
                                      onClick={e => {
                                        navigateToView(
                                          '/business/jobsourcing',
                                          tableData[index]
                                        );
                                      }}
                                    >
                                      {
                                        tableData[index].count[7]
                                          .candidate_hired
                                      }
                                    </Badge>
                                  )}
                                </VStack>
                                <VStack ml={1}>
                                  <Text>Rejected</Text>
                                  {tableData[index].count[6]
                                    .candidate_rejected === 0 ? (
                                    <Badge>
                                      {
                                        tableData[index].count[6]
                                          .candidate_rejected
                                      }
                                    </Badge>
                                  ) : (
                                    <Badge
                                      bg={'green.100'}
                                      color={'gray.700'}
                                      cursor={'pointer'}
                                      onClick={e => {
                                        navigateToView(
                                          '/business/jobsourcing',
                                          tableData[index]
                                        );
                                      }}
                                    >
                                      {
                                        tableData[index].count[6]
                                          .candidate_rejected
                                      }
                                    </Badge>
                                  )}
                                </VStack>
                              </Flex>
                              <Flex w={'100%'} direction={'row'} p={4}>
                                <Flex w={'100%'} alignItems={'center'}>
                                  <Icon as={AiOutlineUser} mr={1}></Icon>
                                  <Text>{tableData[index].hr_manager}</Text>
                                </Flex>
                                <Flex w={'100%'} alignItems={'center'}>
                                  <Icon as={MdOutlineLocationOn} mr={1}></Icon>
                                  {renderJobLocation(tableData[index])}
                                </Flex>
                                <Flex w={'100%'} alignItems={'center'}>
                                  <Icon as={AiOutlineUserAdd} mr={1}></Icon>
                                  <Text>
                                    Hire Count:{' '}
                                    <b>{tableData[index].job_heads}</b>
                                  </Text>
                                </Flex>
                                <Flex w={'100%'}alignItems={'center'}>
                                  <Icon as={AiOutlineUserAdd} mr={1}></Icon>
                                  <Text>
                                    Job Code:{' '}
                                    <b>
                                      {tableData[index].job_code
                                        ? tableData[index].job_code
                                        : '..'}
                                    </b>
                                  </Text>
                                </Flex>
                              </Flex>
                            </Flex>
                          );
                        } else if (cell.column.Header === 'Stagesx') {
                          // data = (
                          //   <Flex w={'600px'}
                          //     p={2}
                          //     borderWidth={'0.5px'} borderColor={'gray.200'} rounded={'lg'}
                          //     justify="space-between"
                          //     align="center"
                          //     fontSize={{ sm: '10px', lg: '12px' }}
                          //     color="gray.500">
                          //       <VStack>
                          //         <Text>Sourced</Text>
                          //         {tableData[index].count[0].candidate_sourced === 0
                          //         ?<Badge>{tableData[index].count[0].candidate_sourced}</Badge>
                          //         :<Badge bg={'green.100'} color={'gray.700'}>{tableData[index].count[0].candidate_sourced}</Badge>}
                          //       </VStack>
                          //       <VStack ml={1} mr={1}>
                          //         <Text>Screened</Text>
                          //         {tableData[index].count[1].candidate_screening === 0
                          //         ?<Badge>{tableData[index].count[1].candidate_screening}</Badge>
                          //         :<Badge bg={'green.100'} color={'gray.700'}>{tableData[index].count[1].candidate_screening}</Badge>}
                          //       </VStack>
                          //       <VStack ml={1} mr={1}>
                          //         <Text>Ready To Interview</Text>
                          //         {tableData[index].count[2].ready_to_interview === 0
                          //         ?<Badge>{tableData[index].count[2].ready_to_interview}</Badge>
                          //         :<Badge bg={'green.100'} color={'gray.700'}>{tableData[index].count[2].ready_to_interview}</Badge>}
                          //       </VStack>
                          //       <VStack ml={1}>
                          //         <Text>Shortlisted</Text>
                          //         {tableData[index].count[3].candidate_shortlisted === 0
                          //         ?<Badge>{tableData[index].count[3].candidate_shortlisted}</Badge>
                          //         :<Badge bg={'green.100'} color={'gray.700'}>{tableData[index].count[3].candidate_shortlisted}</Badge>}
                          //       </VStack>
                          //       <VStack ml={1}>
                          //         <Text>Scheduled For Inteview</Text>
                          //         {tableData[index].count[4].scheduled_for_interview === 0
                          //         ?<Badge>{tableData[index].count[4].scheduled_for_interview}</Badge>
                          //         :<Badge bg={'green.100'} color={'gray.700'}>{tableData[index].count[4].scheduled_for_interview}</Badge>}
                          //       </VStack>
                          //       <VStack ml={1}>
                          //         <Text>Interviewing</Text>
                          //         {tableData[index].count[5].currently_in_interview === 0
                          //         ?<Badge>{tableData[index].count[5].currently_in_interview}</Badge>
                          //         :<Badge bg={'green.100'} color={'gray.700'}>{tableData[index].count[5].currently_in_interview}</Badge>}
                          //       </VStack>
                          //       <VStack ml={1}>
                          //         <Text>Rejected</Text>
                          //         {tableData[index].count[6].candidate_rejected === 0
                          //         ?<Badge>{tableData[index].count[6].candidate_rejected}</Badge>
                          //         :<Badge bg={'green.100'} color={'gray.700'}>{tableData[index].count[6].candidate_rejected}</Badge>}
                          //       </VStack>
                          //       <VStack ml={1}>
                          //         <Text>Hired</Text>
                          //         {tableData[index].count[7].candidate_hired === 0
                          //         ?<Badge>{tableData[index].count[7].candidate_hired}</Badge>
                          //         :<Badge bg={'green.100'} color={'gray.700'}>{tableData[index].count[7].candidate_hired}</Badge>}
                          //       </VStack>
                          //   </Flex>
                          // );
                        } else if (cell.column.Header === 'Title Others') {
                          data = (
                            <Text
                              color={schemeTextColor}
                              fontSize="md"
                              fontWeight="500"
                              onClick={e => {
                                navigateToView(
                                  '/business/viewJob',
                                  tableData[index]
                                );
                              }}
                            >
                              {cell.value ? <Text> {cell.value}</Text> : '-'}
                            </Text>
                          );
                        } else if (cell.column.Header === 'Status') {
                          data = (
                            <Flex>
                              {tableData[index].archived ? (
                                <Text color={'red.400'}>Archived</Text>
                              ) : (
                                <Box>
                                  <Text>{tableData[index].job_status}</Text>
                                  <Select
                                    fontSize={'14px'}
                                    color={'gray.600'}
                                    size="md"
                                    bg={tableData[index].job_status_color}
                                    borderColor={schemeBorderColor}
                                    value={tableData[index].job_status}
                                    mr="3"
                                    w="100px"
                                    onChange={e => {
                                      handleJobStatusChange(
                                        e.target.value,
                                        tableData[index]
                                      );
                                    }}
                                  >
                                    {tableData[index].job_status != 'Open' &&
                                    tableData[index].job_status != 'Closed' ? (
                                      <option
                                        value="Draft"
                                        style={{ color: 'black' }}
                                      >
                                        Draft
                                      </option>
                                    ) : null}
                                    <option
                                      value="Open"
                                      style={{ color: 'black' }}
                                    >
                                      Open
                                    </option>
                                    <option
                                      value="Closed"
                                      style={{ color: 'black' }}
                                    >
                                      Closed
                                    </option>
                                  </Select>
                                </Box>
                              )}
                            </Flex>
                          );
                        } else if (cell.column.Header === 'Department Name') {
                          data = (
                            <Text
                              color={schemeTextColor}
                              fontSize="md"
                              fontWeight="500"
                              onClick={e => {
                                navigateToView(
                                  '/business/viewJob',
                                  tableData[index]
                                );
                              }}
                            >
                              {cell.value}
                            </Text>
                          );
                        } else if (cell.column.Header === 'Sourcing') {
                          data = (
                            <Text
                              color={schemeTextColor}
                              fontSize="md"
                              fontWeight="500"
                              onClick={e => {
                                navigateToView(
                                  '/business/viewJob',
                                  tableData[index]
                                );
                              }}
                            >
                              {cell.value ? (
                                <Badge px={2} color={'white'} bg={'green.500'}>
                                  {' '}
                                  YES
                                </Badge>
                              ) : (
                                <Badge px={2} color={'white'} bg={'red.500'}>
                                  {' '}
                                  NO
                                </Badge>
                              )}
                            </Text>
                          );
                        } else if (cell.column.Header === 'Start Sourcing') {
                          data = !tableData[index].job_workflow_started ? (
                            <Button
                              size="sm"
                              colorScheme="green"
                              onClick={() => {
                                onOpen();
                                setJobId(tableData[index].job_uuid);
                                setAddJobStatus(false);
                              }}
                            >
                              Start Sourcing
                            </Button>
                          ) : (
                            <Text rounded="lg" pl={5}>
                              Sourcing Started
                            </Text>
                          );
                        } else if (cell.column.Header === 'Slot Status') {
                          data = (
                            <Text
                              color={schemeTextColor}
                              fontSize="md"
                              fontWeight="500"
                            >
                              <Switch
                                colorScheme="green"
                                isChecked={cell.value}
                                name="enable_disable_workflow"
                                onChange={e => {
                                  verifySlotStatus(
                                    tableData[index].job_uuid,
                                    cell.value
                                  );
                                  setModalFlag('enable_disable_workflow');
                                }}
                                size="md"
                              />
                            </Text>
                          );
                        } else if (cell.column.Header === 'Edit Job') {
                          data = (
                            <Flex>
                              {tableData[index].archived ? (
                                <Text color={'red.400'}>Archived</Text>
                              ) : (
                                <Icon
                                  w={5}
                                  h={5}
                                  mr="3"
                                  cursor="pointer"
                                  as={AiOutlineEdit}
                                  onClick={e => {
                                    editJobDetails(cell.value, e);
                                  }}
                                ></Icon>
                              )}
                              {/* <Icon
                                w={5}
                                h={5}
                                cursor="pointer"
                                as={AiOutlineEye}
                                onClick={e => {
                                  viewJobDetails(cell.value, e);
                                }}
                              ></Icon> */}
                            </Flex>
                          );
                        }
                        return (
                          <Td
                            {...cell.getCellProps()}
                            key={index}
                            fontSize={{ sm: '14px' }}
                            minW={{ sm: '150px', md: '200px', lg: 'auto' }}
                            borderColor={schemeBorderColor}
                          >
                            {data}
                          </Td>
                        );
                      })}
                    </Tr>
                  );
                })}
              </Tbody>
            </Table>
          </>
        ) : (
          <Alert
            borderRadius="8px"
            status="error"
            variant="subtle"
            w="95%"
            mx="auto"
          >
            <Flex>
              <AlertIcon />
              <Flex direction="column">
                <AlertTitle>Empty Jobs List</AlertTitle>
                <AlertDescription>There are no jobs!!</AlertDescription>
              </Flex>
            </Flex>
          </Alert>
        )}
      </Flex>
      {/* Sourcing Popup-Modal */}
      <Modal
        onClose={() => {
          onCloseModal();
        }}
        isOpen={isOpen}
        isCentered
      >
        <ModalOverlay />
        {modalFlag === 'enable_disable_workflow' ? (
          <ModalContent>
            <ModalCloseButton mt="1" mr="-1" />
            <ModalHeader>Complete Recruitement</ModalHeader>
            <ModalBody p={'15px'}>
              <Text textAlign={'center'} fontWeight={'normal'}>
                Are you sure you would like to stop sourcing for this role? If
                so, you will have an open slot to add another job.
                {/* Do you really want to{' '}
                <Text as="span" fontWeight={'bold'}>
                  {workflowStatus ? 'stop' : 'start'}{' '}
                </Text>{' '}
                the job recruitement? Once you select this option the job
                recruitement will{' '}
                <Text as="span" fontWeight={'bold'}>
                  {workflowStatus ? 'stop' : 'start'}{' '}
                </Text>{' '}
                and the slot will be open for another now job. Please make sure
                you really want to{' '}
                <Text as="span" fontWeight={'bold'}>
                  {workflowStatus ? 'close' : 'open'}{' '}
                </Text>{' '}
                this job recruitement before selecting this option. */}
              </Text>
              <Flex alignItems={'center'} p="2" mt={10}>
                <Button
                  colorScheme="green"
                  mr="3"
                  onClick={() => {
                    enableDisableJobWorkflow(
                      selectedCandidateId,
                      workflowStatus
                    );
                    onCloseModal();
                  }}
                >
                  Yes
                </Button>
                <Button
                  colorScheme="red"
                  onClick={() => {
                    onCloseModal();
                  }}
                >
                  No
                </Button>
              </Flex>
            </ModalBody>
          </ModalContent>
        ) : null}
        {addJobStatus ? (
          <ModalContent>
            <ModalCloseButton mt="1" mr="-1" />
            <ModalHeader>{modalDialogInfo.header}</ModalHeader>
            <ModalBody p={'15px'}>
              <Text textAlign={'center'} fontWeight={'normal'}>
                {modalDialogInfo.message}
              </Text>
              <Box textAlign="center" p="2" mt={10}>
                {launchTeamBilling ? (
                  <Box>
                    {modalDialogInfo.button ? (
                      <Button
                        onClick={() => launchTeamBillingPage()}
                        colorScheme="green"
                      >
                        {modalDialogInfo.buttonTitle}
                      </Button>
                    ) : null}
                  </Box>
                ) : (
                  <Box>
                    {modalDialogInfo.button ? (
                      <NavLink to="/business/billing">
                        <Button colorScheme="green">
                          {modalDialogInfo.buttonTitle}
                        </Button>
                      </NavLink>
                    ) : null}
                  </Box>
                )}
              </Box>
            </ModalBody>
          </ModalContent>
        ) : null}
        {modalFlag === 'start_sourcing' && !addJobStatus ? (
          <ModalContent>
            <ModalHeader>Start Sourcing Candidates</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <Text>
                Choosing this option will start to match candidates and do outreach for this position. 
                <br/><br/>
                Please ensure all job details are correct by pressing "Cancel" otherwise click "Start Sourcing" to proceed.  
              </Text>
            </ModalBody>
            <ModalFooter>
              <Button
                colorScheme="green"
                onClick={() => {
                  saveData();
                }}
                isDisabled={workflowLoading ? true : false}
                isLoading={workflowLoading ? true : false}
                mr="2"
              >
                Start Sourcing
              </Button>
              <Button
                colorScheme="red"
                onClick={() => {
                  onCloseModal();
                }}
              >
                Cancel
              </Button>
            </ModalFooter>
          </ModalContent>
        ) : null}
        {modalFlag === 'complete_survey' ? (
          <ModalContent maxW="80%">
            <ModalHeader>Complete Survey Details</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <Flex justifyContent="space-between" p="3">
                <Box w="78%">
                  <BusinessSurveyWizard
                    minH={'auto'}
                    resentJobFlag={handleModalConfiguration}
                  />
                </Box>
                <Box w="20%">
                  <Completion />
                </Box>
              </Flex>
            </ModalBody>
          </ModalContent>
        ) : null}
        {modalFlag === 'survey_warning' ? (
          <ModalContent>
            <ModalHeader>
              <Text>Missing Business Details</Text>
            </ModalHeader>
            <ModalBody>
              <Text
                color={schemeTextColor}
                fontSize={'16px'}
                mr="5"
                textAlign={'start'}
              >
                You must complete the business survey before creating a new job.
              </Text>
            </ModalBody>
            <ModalFooter>
              <Button variant={'ghost'} mr={3} onClick={onCloseModal}>
                Cancel
              </Button>
              <Button colorScheme='blue'
                  onClick={() => {
                    onOpen();
                    setModalFlag('complete_survey');
                  }}
              >
                  Complete Survey</Button>
            </ModalFooter>
            <ModalCloseButton mt="2" />
          </ModalContent>
        ) : null}
        {modalFlag === 'survey_warning_limited' ? (
          <ModalContent>
            <ModalHeader>
              <Text>Missing Business Survey</Text>
            </ModalHeader>
            <ModalBody p={10}>
              <Text
                color={schemeTextColor}
                fontSize={'16px'}
                mr="5"
                textAlign={'start'}
              >
                To add new job the business survey must be completed by business
                leader. Please reach out to your leadership team to complete the
                business survey so you can get started.
              </Text>
            </ModalBody>
            <ModalCloseButton mt="2" />
          </ModalContent>
        ) : null}
      </Modal>
    </>
  );
}

export default JobTableList;
