import { Flex, Icon, Text, Tooltip } from "@chakra-ui/react"
import { MdWork } from "react-icons/md"

export default function CandidateWorkStatus (props) {
    const {candidate, fontWeight, fontSize, fontColor, mTop, mLeft, mRight, mBottom} = props
    let work_status = []
    if (candidate?.workflow?.tags_work_status){
        work_status = JSON.parse(candidate?.workflow?.tags_work_status || "[]")
    }

    if (!work_status ||work_status.length == 0){
        work_status = "N/A"
    }
    else{
        work_status = work_status?.join(', ')
    }

    return (
        <Flex ml={mLeft ? mLeft : null} mr={mRight ? mRight : null}
                mt={mTop ? mTop : null} mb={mBottom ? mBottom : null}
                alignItems={'center'}
            >
            <Tooltip label={'Work Status'} fontSize='md'>
                <Flex fontSize={fontSize ? fontSize : null}
                    fontWeight={fontWeight ? fontWeight : null}
                    color={fontColor ? fontColor : null} alignItems={'center'}
                >
                    <Icon mr={2} fontWeight={'bold'} fontSize={'16px'} as={MdWork} />
                    <Text>{work_status}</Text>
                </Flex>
            </Tooltip>
            
        </Flex>        
    )
  }
