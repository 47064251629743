import React from 'react';
import { Box, Heading, Text, Flex } from '@chakra-ui/react';
import Footer from '../../constants/Footer';
import { defaultThemeColor } from '../../constants';
import Header from '../../constants/Header';

function TermsCondition() {
  const tableContent = [
    { label: 'AGREEMENT TO TERMS' },
    { label: 'INTELLECTUAL PROPERTY RIGHTS' },
    { label: 'USER REPRESENTATIONS' },
    { label: 'USER REGISTRATION' },
    { label: 'PROHIBITED ACTIVITIES' },
    { label: 'USER GENERATED CONTRIBUTIONS' },
    { label: 'CONTRIBUTION LICENSE' },
    { label: 'SUBMISSIONS' },
    { label: 'THIRD-PARTY WEBSITE AND CONTENT' },
    { label: 'SITE MANAGEMENT' },
    { label: 'PRIVACY POLICY' },
    { label: 'TERM AND TERMINATION' },
    { label: 'MODIFICATIONS AND INTERRUPTIONS' },
    { label: 'GOVERNING LAW' },
    { label: 'DISPUTE RESOLUTION' },
    { label: 'CORRECTIONS' },
    { label: 'DISCLAIMER' },
    { label: 'LIMITATIONS OF LIABILITY' },
    { label: 'INDEMNIFICATION' },
    { label: 'USER DATA' },
    { label: 'ELECTRONIC COMMUNICATIONS, TRANSACTIONS, AND SIGNATURES' },
    { label: 'CALIFORNIA USERS AND RESIDENTS' },
    { label: 'MISCELLANEOUS' },
    { label: 'CONTACT US' },
  ];

  //   Todo:Render Table Of Content
  const renderTableContent = tableContent?.map((data, index) => (
    <Flex
      key={index}
      w="100%"
      mb="5px"
      flexWrap="wrap"
      color={defaultThemeColor}
    >
      <Text as="span" mr="2">
        {index + 1}
      </Text>
      <Text>{data?.label}</Text>
    </Flex>
  ));

  return (
    <Box >
      <Header />
      <Box maxW="800px" mx="auto" my={8} p={4}>
        <Heading>TERMS OF SERVICES</Heading>
        <Heading mt="10px" fontSize="18px">
          TABLE OF CONTENTS
        </Heading>
        <Box mt="5">{renderTableContent}</Box>
      </Box>
      <Footer />
    </Box>
  );
}

export default TermsCondition;
