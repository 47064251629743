// Chakra imports
import {
  Badge,
  List,
  ListItem,
  ListIcon,
  Text,
  Button,
  useColorModeValue,
} from '@chakra-ui/react';
// Custom components
import Card from '../../../../../../components/card/Card';
import React from 'react';
// Assets
import { BsCircleFill } from 'react-icons/bs';

export default function Pack(props) {
  const { title, desc, button, price, details, benefits, highlighted } = props;
  const textColor = useColorModeValue('secondaryGray.900', 'white');
  return (
    <Card
      p="20px"
      pb="45px"
      pt={highlighted ? '30px' : '30px'}
      w={{ sm: '300px', md: '650px', lg: '650px' }}
      mr="5"
      alignItems="flex-start"
      justifyContent="flex-start"
      position="relative"
      overflow="unset !important"
    >
      <Text fontSize="30px" color={textColor} fontWeight="700">
        {title}
      </Text>
      <Text mb="30px" fontSize="md" color="secondaryGray.600" fontWeight="500">
        {desc}
      </Text>

      {price}
      <Text fontSize="md" color="secondaryGray.600" fontWeight="500">
        {details}
      </Text>
      <List spacing={3} justifyContent="flex-start">
        {benefits.map((benefit, index) => (
          <ListItem
            key={index}
            display="flex"
            textAlign="start"
            fontSize="md"
            fontWeight="500"
            color={textColor}
            align="center"
            lineHeight="100%"
            mt="30px !important"
          >
            <ListIcon
              w="10px"
              h="10px"
              as={BsCircleFill}
              my="auto"
              color={textColor}
            />
            {benefit}
          </ListItem>
        ))}{' '}
      </List>
    </Card>
  );
}
