import { BiSave } from 'react-icons/bi';
import { useLocation } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import { getEmailId } from '../../../utils/localStorageIndex';
import { Box, Flex, Select, Text, IconButton } from '@chakra-ui/react';
import { saveJobDepartment } from '../../../services/jobTemplateService';
import { fetchDepartmentServices } from '../../../services/departmentServices';
import {
  borderBottomColor,
  themeFontSizeMediumHeading,
} from '../../../constants/index';

function UpdateJobDepartment(props) {
  const email = getEmailId();
  const { state } = useLocation();
  const job_uuid = state.job_uuid; //Todo:Fetching job_uuid from state
  const [saveButton, setSaveButton] = useState('');
  const [loading, isLoading] = useState(false);
  const [departmentList, setDepartmentList] = useState([]);
  const [selectedJobDepartment, setSelectedJobDepartment] = useState('');

  useEffect(() => {
    fetchDepartmentDetails();
  }, []);

  useEffect(() => {
    if (props?.jobDepartmentName && props?.jobDepartmentName?.length) {
      setSelectedJobDepartment(props?.jobDepartmentName);
    }
  }, [props]);

  const fetchDepartmentDetails = () => {
    let objBody = {
      email: email,
      searchString: '',
      filterEnabled: 'false',
      records_per_page: 0,
      page_id: 0,
    };
    isLoading(true);
    fetchDepartmentServices(objBody)
      .then(res => {
        isLoading(false);
        if (res.result && res.data[0].total_records > 0) {
          setDepartmentList(res.data[0].records);
        } else {
          isLoading(false);
          setDepartmentList([]);
        }
      })
      .catch(err => {
        console.log(err);
      });
  };

  //   render departmentData
  const renderDepartmentData = departmentList?.map((data, index) => (
    <option
      key={data?.department_Name}
      value={data?.department_Name}
      style={{ color: 'black' }}
      name={data?.department_Name}
    >
      {data?.department_Name}
    </option>
  ));

  // Todo:Save Job Details Data
  const saveDepartmentData = () => {
    const departmentData = departmentList?.filter(data => {
      return data?.department_Name == selectedJobDepartment;
    });
    let objBody = {
      email: email,
      job_uuid: job_uuid,
      job_department_name: departmentData?.[0].department_Name,
      job_department_uuid: departmentData?.[0].department_uuid,
    };
    isLoading(true);
    saveJobDepartment(objBody)
      .then(res => {
        isLoading(false);
        if (res.result) {
          props.reRenderEditor(true);
        } else {
          isLoading(false);
        }
      })
      .catch(err => {
        isLoading(false);
      });
  };

  return (
    <>
      <Flex>
        <Box
          w="25%"
          display="flex"
          alignItems="end"
          justifyContent="end"
          borderBottom="2px dotted #d7d7d7"
          p="15px"
          borderRight="1px"
          borderRightColor="#d7d7d7"
        >
          <Text
            borderStyle={'dotted'}
            color={'#666666'}
            borderColor={borderBottomColor}
          >
            Department Type:
          </Text>
        </Box>
        <Flex
          alignItems={'center'}
          w="70%"
          p="15px"
          borderBottom="2px dotted #d7d7d7"
        >
          <Box w="100%">
            <Select
              fontSize={themeFontSizeMediumHeading}
              size="md"
              borderColor="#d7d7d7"
              onChange={e => {
                setSaveButton('updateDepartment');
                setSelectedJobDepartment(e.target.value);
              }}
              value={selectedJobDepartment}
            >
              <option>Please Select Department</option>
              {renderDepartmentData}
            </Select>
          </Box>
          {saveButton === 'updateDepartment' ? (
            <IconButton
              size="md"
              fontSize="lg"
              variant="ghost"
              color="current"
              marginLeft="2"
              icon={<BiSave />}
              isDisabled={loading ? true : false}
              isLoading={loading ? true : false}
              onClick={() => {
                saveDepartmentData();
              }}
            ></IconButton>
          ) : null}
        </Flex>
      </Flex>
    </>
  );
}

export default UpdateJobDepartment;
