import React, { useState } from 'react';
import axios from '../../../services/axios';
import {
  Button,
  Text,
  useColorModeValue,
  Flex,
  Box,
  FormControl,
  Input,
  Stack,
  Icon,
  useToast
} from '@chakra-ui/react';
import { MdUpload } from 'react-icons/md';
import { useDispatch } from 'react-redux';
import Card from '../../../components/card/Card.js';
import { getEmailId } from '../../../utils/localStorageIndex';
import { enrichCandidateProfile, updateCandidateProfileData } from '../../../services/jobTemplateService';
import { setProfileLogoFlag } from '../../../store/actions/topNotificationActions';

export default function HeadshotUpload(props) {
  const { used, total, ...rest } = props;
  // Chakra Color Mode
  const emaiId = getEmailId();
  const dispatch = useDispatch();
  const textColorSecondary = 'gray.400';
  const [loading, isLoading] = useState(false);
  const [uploadFile, setUploadFile] = useState();
  const [uploadFileUrl, setUploadFileUrl] = useState();
  const [uploadStatus, setUploadStatus] = useState();
  const [uploadStatusLoading, setUploadStatusLoading] = useState(false);
  const brandColor = useColorModeValue('brand.500', 'white');
  const textColorPrimary = useColorModeValue('secondaryGray.900', 'white');
  const toast = useToast()

  function postObjectData(s3Data, selectedFile) {
    dispatch(setProfileLogoFlag(false));
    const { url, fields } = s3Data;
    const payload = new FormData();

    // add all the other fields
    Object.entries(fields).forEach(([key, val]) => {
      payload.append(key, val);
    });
    // payload.append("Content-Type", "image/jpeg");
    // payload.append("Content-Type", "multipart/form-data");
    // This must be the last line in the payload otherwise 412 error may occur
    // this is the file blob, eg from <input type="file">
    payload.append('file', selectedFile);

    // No need to manually set content-type header, your browser knows what to do
    // const { data: result } = axios.post(url, payload);
    // https://stackoverflow.com/questions/46656474/axios-remove-headers-authorization-in-1-call-only
    var ax_instance = axios.create();
    delete ax_instance.defaults.headers.common['Authorization'];
    isLoading(true)
    const { data: result } = ax_instance
      .post(url, payload)
      .then(res => {
        isLoading(false);
        if (res?.data?.length > 0) {
          toast({
            title: 'File is uploaded successfully',
            status: 'success',
            duration: 3000,
          })
          dispatch(setProfileLogoFlag(true));
          props?.closeDrawer(); //Todo:Call function in screen candidate to close drawer once headshot uploaded.
        } else {
          toast({
            title: 'File is uploaded successfully',
            status: 'success',
            duration: 3000,
          })
          props?.closeDrawer();
          dispatch(setProfileLogoFlag(true));
        }
        if (props.dataType == 'resume'){
          toast({
            title: 'Candidate will be enriched in the background. Please refresh candidate list in 20 seconds.',
            status: 'success',
            duration: 4000,
          })
        }
      })
      .catch(err => {
        isLoading(false);
        setUploadStatus('Upload failed with Error :' + err);
        toast({
          title: 'Unable to upload file',
          description: err?.toString(),
          status: 'error',
          duration: 5000,
        })
      });
  }

  const handleFileChange = e => {
    if (e.target.files) {
      setUploadFile(e.target.files[0]);
      setUploadFileUrl(URL.createObjectURL(e.target.files[0]));
    }
  };
  const handleSubmitNew = async () => {
    setUploadStatusLoading(true);
    if (uploadFile) {
      let objData = {
        email: emaiId,
        candidate_uuid: props.candidate_uuid,
        job_uuid: props.job_uuid,
        content_choice: props.dataType,
        file_name: uploadFile.name.toLowerCase(),
        file_type: uploadFile.type,
      };
      setUploadStatus('Getting upload url for the selected file..');
      const response = await updateCandidateProfileData(objData);
      setUploadStatusLoading(false);
      if (response && response.data && response.data.length > 0 && response.data[0].signedPackage) {
        setUploadStatusLoading(false);
        const finalUrl = response.data[0].signedPackage.signedUrl;
        console.log(finalUrl)
        setUploadStatus('Hold on! Uploading selected file...');
        postObjectData(finalUrl, uploadFile);       
      } else {
        console.log(response)
        setUploadStatusLoading(false);
        setUploadStatus(null)
        toast({
          title: `Unable to upload ${props.dataType}`,
          description: response?.message,
          status: 'error',
          duration: 5000
        })
      }
    }
  };

  return (
    <Card {...rest} mb="20px" align="center" p="20px">
      <Box w={'100%'}>
        <FormControl cursor="pointer">
          <Text fontSize={'12px'}>{uploadFileUrl}</Text>
          <Text mb={4} fontSize={'12px'}>
            {uploadFile && `${uploadFile.name} - ${uploadFile.type}`}
          </Text>
          <Box
            position="relative"
            height="200px"
            width="100%"
            borderWidth="2px"
            borderColor={brandColor}
            borderRadius={'10px'}
            cursor="pointer"
          >
            <Box
              position="absolute"
              top="0"
              left="0"
              height="100%"
              width="100%"
              display="flex"
              flexDirection="column"
              cursor="pointer"
            >
              <Stack
                height="100%"
                width="100%"
                display="flex"
                alignItems="center"
                justify="center"
                spacing="4"
                cursor="pointer"
              >
                <Box textAlign="center">
                  <Icon as={MdUpload} w="80px" h="80px" color={brandColor} />
                  <Flex justify="center" mx="auto" mb="12px">
                    <Text fontSize="xl" fontWeight="700" color={brandColor}>
                      Upload {props.dataType === 'headshot' ? "Headshot" : "Resume"}
                    </Text>
                  </Flex>
                  <Text
                    fontSize="sm"
                    fontWeight="500"
                    color="secondaryGray.500"
                  >
                    {props.dataType === 'headshot'
                      ? 'PNG, JPG and GIF files are supported'
                      : 'Only PDF and Docx files are allowed'}
                  </Text>
                </Box>
              </Stack>
            </Box>
            <Input
              type="file"
              height="100%"
              width="100%"
              position="absolute"
              top="0"
              left="0"
              opacity="0"
              aria-hidden="true"
              accept={
                props.dataType === 'headshot' ? '.png, .jpg, .jpeg' : '.pdf, .docx'
              } // Include .pdf here to accept PDF files
              cursor="pointer"
              onChange={handleFileChange}
            />
          </Box>
        </FormControl>
      </Box>

      <Flex direction="column">
        <Text
          color={textColorPrimary}
          fontWeight="bold"
          fontSize="2xl"
          mt={{ base: '20px', '2xl': '50px' }}
        >
          {props.dataType === 'headshot' ? 'Update candidate headshot' : 'Add Candidate Resume'}
        </Text>
        <Text
          color={textColorSecondary}
          fontSize="md"
        >
          {props.dataType === 'headshot'
            ? 'Candidate profile headshot adds presence with the business user.'
            : 'Adding Candidate Resume helps AI to match candidate better.'}
          {/* <br/>
          {props.job_uuid} - {props.candidate_uuid} */}
        </Text>
      </Flex>
      {uploadFile ? (
        <Flex
          mt={{ base: '20px', '2xl': 'auto' }}
          direction={'column'}
          alignItems={'center'}
        >
          <Button
            mt={10}
            width={'200px'}
            variant="brand"
            fontWeight="500"
            onClick={handleSubmitNew}
            isLoading={loading}
            loadingText={'Adding...'}
          >
            {props.dataType === 'headshot' ?
            "Add Headshot"
            :
            "Add Resume"
            }
          </Button>
          <Text mt={10} color="green" fontSize={'18px'}>{uploadStatus}</Text>
        </Flex>
      ) : null}
    </Card>
  );
}
