import React, { useEffect, useState } from 'react';
import { Link as ReactRouterLink, Route } from 'react-router-dom';
import {
  Link as ChakraLink,
  DrawerHeader,
  Input,
  LinkProps,
  List,
  ListIcon,
  ListItem,
  Spinner,
  Textarea,
} from '@chakra-ui/react';
import { useLocation, useNavigate, location } from 'react-router-dom';
import { Box, Button, Flex, Image, Icon, Text, useDisclosure, useToast, Drawer, DrawerContent, DrawerCloseButton} from '@chakra-ui/react';
import { useNylas } from '@nylas/nylas-react';
import NylasLogo from '../../nylas/components/icons/Nylas-Logo_Horizontal-Blue.png';
import Card from '../../../components/card/Card';
import CalendarJobListing from './components/CalendarJobListing';
import { getEmailId, getJob } from '../../../utils/localStorageIndex';
import { fetchScheduledCandidateList, fetchCalenderEvents } from '../../../services/jobTemplateService.js';
import { fetchPersonalInfo } from '../../../services/sharedServices.js';
import { isObjectEmpty, toTitleCase } from '../../../utils/common.js';
import DraftJob from '../applicants/listingbyjob/components/DraftJob.js';
import { getCompanyDetailsByName } from '../../../services/companyServices.js';


function BusinessScheduling() {
  const toast = useToast();
  const emailId = getEmailId();
  const navigate = useNavigate();
  const { state } = useLocation();
  const business = state?.business
  const btnRef = React.useRef();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [apiLoading, isApiLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const adminSettings = state?.adminSettings ? state?.adminSettings : null; 

  const [scheduledCandidateArr, setScheduledCandidateArr] = useState([]);
  const [allJobs, setAllJobs] = useState([]);
  const [alertMessage, setAlertMessage] = useState('No Candidate Shortlisted');
  const [selectedCandidateUuid, setSelectedCandidateUuid] = useState(null);
  const [selectedCandidate, setSelectedCandidate] = useState(null);
  const [selectedJobUuid, setSelectedJobUuid] = useState(null);
  const [selectedJobTitle, setSelectedJobTitle] = useState(null);
  const [selectedJob, setSelectedJob] = useState(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const nylasAuthCode = urlParams.has('code') ? urlParams.get('code') : null;
    if (nylasAuthCode){
        return getJob();
    }

    return {}
  });

  const [selectedTab, setSelectedTab] = useState(null);
  const [candidateData, setCandidateData] = useState([]);
  const [candidateProfile, setCandidateProfile] = useState([]);
  const [userDetails, setUserDetails] = useState({});
  const [selectedJobsType, setSelectedJobsType] = useState('open')
  const [selectedDraftJob, setSelectedDraftJob] = useState({})
  const [companiesLogo, setCompaniesLogo] = useState({})

  const [calendarEvents, setCalendarEvents] = useState([]);

  const nylas = useNylas();
  const [userId, setUserId] = useState('');
  const [userEmail, setUserEmail] = useState('');
  const [nylasAuthUrl, setNylasAuthUrl] = useState(null);
  const [seconds, setSeconds] = useState(5);
  const [timerActive, setTimerActive] = useState(false);
  const toastFunction = (message, status) => {
    return toast({
        title: message,
        status: status,
        isClosable: true,
        duration: 3000,
    });
};

  useEffect(() => {
    const userIdString = sessionStorage.getItem('userId');
    const userEmail = sessionStorage.getItem('userEmail');

    if (userIdString) {
      setUserId(userIdString);
    }
    if (userEmail) {
      setUserEmail(userEmail);
    }
    if (userIdString) {
      setUserId(userIdString);
    }

    getBusinessUserDetails();

  }, []);

  useEffect(() => {
    if (nylasAuthUrl != null) {
      let newRedirectUrl = nylasAuthUrl.substring(1, nylasAuthUrl.length - 1);
      window.location.replace(newRedirectUrl);
      navigate(newRedirectUrl, { replace: true });
    }
  }, [nylasAuthUrl]);

  useEffect(() => {
    let timer = null;
    if (timerActive) {
      timer = setInterval(() => {
        setSeconds(seconds => seconds - 1);
      }, 1000);
    }
    if (seconds == 0) {
      setTimerActive(false);
      setSeconds(6);
      ConnectRemoteCalendar();
    }
    return () => {
      clearInterval(timer);
    };
  });

  const getBusinessUserDetails = () => {
    let objData = {
      email: emailId,
    };
    fetchPersonalInfo(objData)
      .then(res => {
        if (res.result) {
          const businessUser = res.data[0]
          setUserDetails(businessUser);
        }
    })
  }

  const ConnectRemoteCalendar = async () => {
    setIsLoading(true);
    let redirectUrl = await nylas.buildAuthUrl({
      emailAddress: emailId,
      successRedirectUrl: 'http://localhost:3000/business/calendar',
    });
    setNylasAuthUrl(redirectUrl);
    return redirectUrl;
  };

  const fetchReadyToIntervieCandidate = job_uuid => {
    isApiLoading(true);
    let objBody = {
      email: emailId,
      business_uuid: business?.business_uuid || null,
      job_uuid: job_uuid,
    };
    fetchScheduledCandidateList(objBody)
      .then(res => {
        isApiLoading(false);
        if (res.result && res.data.length) {
          setScheduledCandidateArr(res.data);
          setAllJobs(res.data);
        } else {
          isApiLoading(false);
          setScheduledCandidateArr([]);
          setAllJobs([]);
          setAlertMessage('No Candidate in Interviewing List');
        }
      })
      .catch(err => {
        isApiLoading(false);
        setScheduledCandidateArr([]);
        setAlertMessage('Oops Something Went Wrong !!!');
      });
  };

  const getInterviewScheduledCandidates = (job) => {
    setSelectedJobUuid(job.job_uuid);
    setSelectedJobTitle(job.job_title);
    setSelectedJob(job);
    fetchReadyToIntervieCandidate(job.job_uuid);
    setSelectedCandidateUuid(null);
  };

  const setupSelectedCandidate = data => {
    setSelectedCandidateUuid(data.candidate_uuid);
    setSelectedCandidate(data);
    if(data.panel_data){
      setCandidateData(data?.panel_data.data[0].details);
      setCandidateProfile(data?.panel_data.data[0].profile);
    }
    setSelectedTab('calenderevents');
    getCandidateEvents();
  };

  const getCandidateEvents = () => {   
    if (selectedCandidateUuid != null && emailId != null && selectedJobUuid != null){
      let objBody = {
        email: emailId,
        job_uuid: selectedJobUuid,
        candidate_uuid: selectedCandidateUuid,
      };
      fetchCalenderEvents(objBody)
        .then(res => {
          if (res.result && res.data.length > 0) {
            setCalendarEvents(res.data);
          } else {
            setCalendarEvents([]);
          }
        })
        .catch(err => {
          setCalendarEvents([]);
        });
      }
  }

const renderDraftJob = () => {
  if (selectedJobsType == 'draft' && !isObjectEmpty(selectedDraftJob)){
    return <DraftJob job={selectedDraftJob} />
  }
}

  return (
    <Box w={'100%'} pt={{ base: '180px', md: '80px', xl: '80px' }}>
            <Flex w={'100%'} direction={'column'}>
              <Flex>
                <Box
                  minWidth={'300px'} maxW={'300px'} 
                  p={1}
                  overflow="auto"
                  css={{
                    '&::-webkit-scrollbar': {
                      width: '4px',
                    },
                    '&::-webkit-scrollbar-track': {
                      width: '6px',
                    },
                    '&::-webkit-scrollbar-thumb': {
                      borderRadius: '24px',
                    },
                  }}
                >

                  {/* jobs list */}
                  <CalendarJobListing
                    gridArea={{ base: '2 / 1 / 3 / 2', lg: '1 / 2 / 2 / 3' }}
                    pe="2px"
                    scheduledCandidateArr={scheduledCandidateArr}
                    handleCallback={getInterviewScheduledCandidates}
                    setupCandidateUuid={setupSelectedCandidate}
                    setSelectedJobTitle={setSelectedJobTitle}
                    selectedCandidateUuid={selectedCandidateUuid}
                    setSelectedCandidateUuid={setSelectedCandidateUuid}
                    setSelectedJobUuid={setSelectedJobUuid}
                    setSelectedJobsType={setSelectedJobsType}
                    setSelectedDraftJob={setSelectedDraftJob}
                    selectedJob={selectedJob}
                  />
                </Box>

                {/* draft job detail view */}
                <Box
                  w={'100%'}
                  h={'100%'}
                  alignContent={'start'}
                  alignItems={'start'}
                  flexDirection="column"
                  verticalAlign={'top'}
                  borderRadius={'10px'}
                  mb="2"
                >
                  <Card w={'100%'} p={5}>

                    <Text fontSize={14}>
                    {selectedCandidateUuid == null && selectedJobsType == 'open' ?
                      "Please select a job and candidate to view profile"
                      :
                      null
                    }

                    {isObjectEmpty(selectedDraftJob) && selectedJobsType == 'draft' ?
                      "Please select a job from Drafts"
                      :
                      null
                    }
                    </Text>

                    {renderDraftJob()}

                  </Card>
                </Box>
              </Flex>


            <Drawer
              size={'md'}
              isOpen={isOpen}
              placement="right"
              onClose={onClose}
              finalFocusRef={btnRef}
            >
              <DrawerContent p={'10'}>
                <DrawerCloseButton color="black" />
                <Box w={'100%'} p={5}>
                  <Text>We are using Nylas API to connect with Google Calendar.</Text>
                  {timerActive ? (
                    <Flex mt={10} w={'100%'}>
                      <Spinner color={'red.300'} />
                      <Text ml={4}>
                        In next {seconds} seconds you will be redirected to nylas
                        based url to authenticate your google calender.....
                      </Text>
                    </Flex>
                  ) : null}

                  <Button
                    mt={5}
                    id="remoteConnect"
                    name="remoteConnect"
                    borderWidth={'0.5px'}
                    onClick={
                      e => setTimerActive(true)
                      // ConnectRemoteCalendar()
                    }
                  >
                    Access Google Calendar
                  </Button>
                  <Text mt={20}>GOOGLE CONNECTION API POWERED BY</Text>
                  <ChakraLink
                    as={ReactRouterLink}
                    isExternal
                    to={'https://www.nylas.com/products/calendar-api/'}
                  >
                    <Image src={NylasLogo} alt="Nylas Logo" w={'100px'} />
                  </ChakraLink>
                </Box>
              </DrawerContent>
            </Drawer>

          </Flex>
    </Box>
  );
}

export default BusinessScheduling;
