import axios from './axios';
import { serviceUrls } from './serviceUrls';

export const getEmailTemplateList = (
  data,
  config = null,
  axiosInstance = axios
) => {
  return axiosInstance.post(serviceUrls().getEmailTemplateList, data, config);
};

export const updateEmailTemplate = (
  data,
  config = null,
  axiosInstance = axios
) => {
  return axiosInstance.post(serviceUrls().updateEmailTemplate, data, config);
};


export const createEmailTemplate = (
  data,
  config = null,
  axiosInstance = axios
) => {
  return axiosInstance.post(serviceUrls().createEmailTemplate, data, config);
};


export const deleteEmailTemplate = (
  data,
  config = null,
  axiosInstance = axios
) => {
  return axiosInstance.post(serviceUrls().deleteEmailTemplate, data, config);
};