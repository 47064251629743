import React, { useState } from 'react';
import { Box, Flex, Text, Icon, Button, Input, FormControl, Stack, Heading, useToast, useColorModeValue, Spinner} from '@chakra-ui/react';// import ViewResume from './ViewResume';
import { BiUpload } from 'react-icons/bi';
import { getTextFromDocument} from '../../../services/jobTemplateService';
import { Editor } from '@tinymce/tinymce-react';
import { useEffect } from 'react';

  
function PDfJobDocPanel(props) {
    const toast = useToast();
    const emailId = props.emailId;
    const borderColor = useColorModeValue('gray.200', 'gray.600');
    const [uploadFile, setUploadFile] = useState();
    const [uploadFileUrl, setUploadFileUrl] = useState();
    const [jobDescription, setJobDescription] = useState(null);
    const [jobDescriptionLoading, setJobDescriptionLoading] = useState(false);

    useEffect(() => {
      if (uploadFile && uploadFileUrl){
        getJobDescriptionFromPdf()
      }
    }, [uploadFile])
  
    const handleFileChange = e => {
        if (e.target.files.length) {
          setUploadFile(e.target.files[0]);
          setUploadFileUrl(URL.createObjectURL(e.target.files[0]));
        }
    };

    const getJobDescriptionFromPdf = () => {
      setJobDescriptionLoading(true)
      const form = new FormData()
      form.set('email', emailId)
      form.set('job_uuid', 'job_uuid')
      form.set('file', uploadFile)
      getTextFromDocument(form)
        .then(res => {
          if (res.result && res.data.length > 0) {
            setJobDescription(res.data[0].text_from_pdf)
          }else{
            toast({
              title: 'Unable to process uploaded document',
              description: res?.message,
              status: 'error',
              duration: 6000,
            });
          }
          setJobDescriptionLoading(false)
        })
        .catch(err => {
          toast({
            title: 'Unable to process the PDF content from the selected pdf file',
            description: err.toString(),
            status: 'error',
            duration: 5000,
          });
          setJobDescriptionLoading(false)
        });
    }

    return (
        <Flex direction={'column'} w={'100%'} p={3} alignItems={'center'}>
            <Flex w={'100%'} p={5} direction={'column'}>
                <Box  w={'100%'} p={1} display="flex" justify="center" rounded={'lg'}
                    alignItems="center" borderWidth="1px" borderColor={borderColor}
                >
                  <FormControl cursor="pointer">
                    <Box position="relative" minHeight="180px" width="100%" cursor="pointer" mx="auto">
                        <Box position="absolute" top="0" left="0" height="100%" width="100%"
                        display="flex" flexDirection="column" cursor="pointer"
                        >
                        <Stack height="100%"  width="100%" display="flex" alignItems="center"
                            justify="center" spacing="4" cursor="pointer"
                        >
                            <Stack p="8" textAlign="center" spacing="1" cursor="pointer">
                            <Box textAlign="center">
                                <Icon fontSize={40} as={BiUpload} mb={2}></Icon>
                            </Box>
                            <Heading fontSize="lg" fontWeight="bold">
                                Drag and Drop Your PDF or Doc
                            </Heading>
                            <Text m="2" as={'b'}>
                                or click here to upload
                            </Text>
                            <Text hidden={!uploadFile} as={'b'} mt={12}>Selected Document: {`${uploadFile?.name}`}</Text>
                            </Stack>
                        </Stack>
                        </Box>
                        <Input type="file" height="100%" width="100%" position="absolute" top="0"
                            left="0" opacity="0" max aria-hidden="true" accept=".pdf, .docx"cursor="pointer"
                            onChange={handleFileChange}
                        />
                    </Box>
                  </FormControl>          
                </Box>
            </Flex>
            
            <Spinner hidden={!jobDescriptionLoading} />

            <Flex w={'100%'} p={1} direction={'column'} alignItems={'center'} hidden={!uploadFile && !jobDescription}>
              <Flex border={'1px solid'} borderColor={'gray.200'} width={'100%'}>
                <Editor
                    tinymceScriptSrc={'/tinymce/tinymce.min.js'}
                    value={jobDescription || ""}
                    onEditorChange={(content) => setJobDescription(content)}
                    init={{
                        min_height: 600,
                        branding: false,
                        statusbar: false,
                        menubar: 'edit insert view format table tools',
                        promotion: false,
                        plugins: ['advlist', 'anchor', 'autolink', 'autoresize', 'autosave', 'charmap', 'code',
                                  'codesample', 'directionality', 'emoticons', 'help', 'importcss', 'insertdatetime',
                                  'link', 'lists', 'nonbreaking', 'pagebreak', 'save', 'searchreplace', 'table', 
                                  'template', 'visualblocks', 'visualchars', 'wordcount'
                                ],
                        toolbar: 'undo redo | bold italic | alignleft aligncenter alignright alignjustify | indent outdent',
                        contextmenu: false,
                    }}
                    />
              </Flex>
              <Button  width={'200px'} variant="brand" fontWeight="500" mt={10} 
                onClick={ () => { 
                  props.jobDescriptionText(jobDescription)
                  props.onJobDescriptionUploadClose()
                  }}
                >
                  Apply Job Description
              </Button>
            </Flex>
        </Flex>
  );
}

export default PDfJobDocPanel;