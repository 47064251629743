import axios from './axios';
import { serviceUrls } from './serviceUrls';

export const fetchRecruiterData = (
  data,
  config = null,
  axiosInstance = axios
) => {
  return axiosInstance.get(serviceUrls().fetchRecruiter, config);
};


export const submitCandidateByResume = (
  form,
  config = null,
  axiosInstance = axios
) => {
  return axiosInstance.postForm(serviceUrls().submitCandidateByResume, form, config);
};

export const submitCandidateByForm = (
  form,
  config = null,
  axiosInstance = axios
) => {
  return axiosInstance.postForm(serviceUrls().submitCandidateByForm, form, config);
};

export const submitCandidateProfileDetails = (
  form,
  config = null,
  axiosInstance = axios
) => {
  return axiosInstance.post(serviceUrls().submitCandidateProfileDetails, form, config);
};

export const qualifyCandidate = (
  form,
  config = null,
  axiosInstance = axios
) => {
  return axiosInstance.post(serviceUrls().qualifyCandidate, form, config);
};

export const sendBulkEmail = (
  form,
  config = null,
  axiosInstance = axios
) => {
  return axiosInstance.post(serviceUrls().sendBulkEmail, form, config);
};

export const uploadFile = (
  form,
  config = null,
  axiosInstance = axios
) => {
  return axiosInstance.postForm(serviceUrls().uploadFile, form, config);
};

export const deleteCandidate = (
  form,
  config = null,
  axiosInstance = axios
) => {
  return axiosInstance.post(serviceUrls().deleteCandidate, form, config);
};

export const assignCandidate = (
  form,
  config = null,
  axiosInstance = axios
) => {
  return axiosInstance.post(serviceUrls().assignCandidate, form, config);
};

export const updateCandidateSkills = (
  form,
  config = null,
  axiosInstance = axios
) => {
  return axiosInstance.post(serviceUrls().updateCandidateSkills, form, config);
};

export const generateMatchingSkillsWithAI = (
  form,
  config = null,
  axiosInstance = axios
) => {
  return axiosInstance.post(serviceUrls().generateMatchingSkillsWithAI, form, config);
};


export const searchCandidate = (
  form,
  config = null,
  axiosInstance = axios
) => {
  return axiosInstance.post(serviceUrls().searchCandidate, form, config);
};

export const fetchAllCandidates = (
  form,
  config = null,
  axiosInstance = axios
) => {
  return axiosInstance.post(serviceUrls().allCandidates, form, config);
};

export const fetchAllJobs = (
  data,
  config = null,
  axiosInstance = axios
) => {
  return axiosInstance.post(serviceUrls().getAllJobs, data, config);
};

export const getCandidateDetails = (
  data,
  config = null,
  axiosInstance = axios
) => {
  return axiosInstance.post(serviceUrls().getCandidateDetails, data, config);
};