import { useNavigate } from 'react-router-dom';
import React, { useEffect, useMemo, useState } from 'react';
import { useGlobalFilter, usePagination, useSortBy, useTable} from 'react-table';
import {Flex, Table, Tbody, Td, Text, Tr, Alert, AlertIcon, AlertTitle, AlertDescription, useColorModeValue,} from '@chakra-ui/react';
import { saveJob } from '../../../../utils/localStorageIndex';
import { toTitleCase } from '../../../../utils/common';

function JobTableList(props) {
  const { columnsData, tableData } = props;
  const {business} = props;
  const jobsTotalCount = props?.totalJobsCount;
  const navigate = useNavigate();
  const [businessUserData, setBusinessUserData] = useState(tableData);
  const columns = useMemo(() => columnsData, [columnsData]);
  const data = useMemo(() => businessUserData, [businessUserData]);
  const cardBgColorStyle = useColorModeValue('gray.100', 'gray.800'); // {light, dark}
  const borderColorStyle = useColorModeValue('gray.200', 'gray.400'); // {light, dark}
  const schemeTextColor = useColorModeValue('secondaryGray.900', 'gray.400');
  const schemeBorderColor = useColorModeValue('gray.200', 'gray.600');

  const tableInstance = useTable(
    {columns, data, manualPagination: true},
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    page,
    prepareRow,
  } = tableInstance;

  const routeToJobCandidateList = job => {
    navigate('/admin/jobcandidatelist', {
      state: {
        job: job,
        business: business,
        adminSettings: 'true',        
      },
    });
  };

  return (
    <>
      <Flex direction="column" width={'90%'}>

        {jobsTotalCount ? (
          <>
            <Table {...getTableProps()} variant="simple" color="gray.500">
              {page.length == 0 ? (
                <Text ml={5} mt={5}>There are no jobs</Text>
              ) : null}
              <Tbody {...getTableBodyProps()}>
                {page.map((row, index) => {
                  prepareRow(row);
                  return (
                    <Tr {...row.getRowProps()} key={index} border={0}>
                      {row.cells.map((cell, subIndex) => {
                        let data = '';
                        if (cell.column.Header === 'Job Details') {
                          data = (
                            <Flex direction={'column'}
                              padding={'30px'}
                              bg={cardBgColorStyle}
                              borderColor={borderColorStyle} mb={5} borderRadius={'6px'}
                              boxShadow={'0px 3px 3px 0 lightgray, 0px 1px 2px 1px lightgray'}
                            >
                              <Flex alignItems={'center'}>
                                <Text
                                  _hover={{
                                    cursor: 'pointer',
                                  }}
                                  onClick={() => routeToJobCandidateList(tableData[index])}
                                  color={schemeTextColor}
                                  fontSize={'20px'}
                                  fontWeight={'bold'}
                                >
                                  {cell.value}
                                </Text>

                                <Text ml={5} pt={1} fontSize={'16px'}>
                                  {tableData[index]?.job_location_extended && toTitleCase(tableData[index]?.job_location_extended.replaceAll('_', ' '))}
                                  {tableData[index]?.job_location_extended && <span> - </span>}
                                  {tableData[index]?.job_location}
                                </Text>
                                
                              </Flex>
                              <Flex
                                p={4}
                                mt={3}
                                justify="space-evenly"
                                color="gray.500"
                                fontWeight={'bold'}
                                fontSize={'16px'}
                              >
                                <Flex width={'100%'} direction={'column'} alignItems={'center'} borderRight={'1px solid'}
                                      borderColor={borderColorStyle} _hover={{'cursor': 'pointer'}}
                                      onClick={() => routeToJobCandidateList(tableData[index])}
                                >
                                  <Text>{tableData[index].count[0]?.candidate_sourced ? 
                                   tableData[index].count[0]?.candidate_sourced :
                                   "-"  
                                  }
                                  </Text>
                                  <Text>Sourcing</Text>                                  
                                </Flex>

                                <Flex width={'100%'} direction={'column'} alignItems={'center'} borderRight={'1px solid'}
                                      borderColor={borderColorStyle} _hover={{'cursor': 'pointer'}}
                                      onClick={() => routeToJobCandidateList(tableData[index])}
                                >
                                  <Text>{tableData[index].count[1]?.candidate_screening ? 
                                   tableData[index].count[1]?.candidate_screening :
                                   "-"  
                                  }
                                  </Text>
                                  <Text>Screening</Text>                                  
                                </Flex>

                                <Flex width={'100%'} direction={'column'} alignItems={'center'} borderRight={'1px solid'}
                                      borderColor={borderColorStyle} _hover={{'cursor': 'pointer'}}
                                      onClick={() => routeToJobCandidateList(tableData[index])}
                                >
                                  <Text>{tableData[index].count[2]?.ready_to_interview ? 
                                   tableData[index].count[2]?.ready_to_interview :
                                   "-"  
                                  }
                                  </Text>
                                  <Text>Ready To Interview</Text>                                  
                                </Flex>

                                <Flex width={'100%'} direction={'column'} alignItems={'center'} borderRight={'1px solid'}
                                      borderColor={borderColorStyle} _hover={{'cursor': 'pointer'}}
                                      onClick={() => routeToJobCandidateList(tableData[index])}
                                >
                                  <Text>{tableData[index].count[4]?.scheduled_for_interview ? 
                                   tableData[index].count[4]?.scheduled_for_interview :
                                   "-"  
                                  }
                                  </Text>
                                  <Text>Interviewing</Text>                                  
                                </Flex>

                                <Flex width={'100%'} direction={'column'} alignItems={'center'} borderRight={'1px solid'}
                                      borderColor={borderColorStyle} _hover={{'cursor': 'pointer'}}
                                      onClick={() => routeToJobCandidateList(tableData[index])}
                                >
                                  <Text>{tableData[index].count[7]?.candidate_hired ? 
                                   tableData[index].count[7]?.candidate_hired :
                                   "-"  
                                  }
                                  </Text>
                                  <Text>Offered</Text>                                  
                                </Flex>

                                <Flex width={'100%'} direction={'column'} alignItems={'center'} _hover={{'cursor': 'pointer'}}
                                      onClick={() => routeToJobCandidateList(tableData[index])}
                                >
                                  <Text>{tableData[index].count[6]?.candidate_rejected ? 
                                   tableData[index].count[6]?.candidate_rejected :
                                   "-"  
                                  }
                                  </Text>
                                  <Text>Rejected</Text>                                  
                                </Flex>
                              </Flex>
                              <Flex mt={4}>
                                  <Text>Status: {toTitleCase(tableData[index].job_status)}</Text>
                              </Flex>
                            </Flex>
                          );
                        }
                        return (
                          <Td
                            {...cell.getCellProps()}
                            key={index}
                            fontSize={{ sm: '14px' }}
                            minW={{ sm: '150px', md: '200px', lg: 'auto' }}
                          >
                            {data}
                          </Td>
                        );
                      })}
                    </Tr>
                  );
                })}
              </Tbody>
            </Table>
          </>
        ) : (
          <Alert
            borderRadius="8px"
            status="error"
            variant="subtle"
            w="95%"
            mx="auto"
          >
            <Flex>
              <AlertIcon />
              <Flex direction="column">
                <AlertTitle>Empty Jobs List</AlertTitle>
                <AlertDescription>There are no jobs!!</AlertDescription>
              </Flex>
            </Flex>
          </Alert>
        )}
      </Flex>
    </>
  );
}

export default JobTableList;
