import { Flex, Text } from "@chakra-ui/react"
import { useState } from "react";
import { Link } from "react-router-dom";

export default function CandidateSummary (props) {
    const {summary, slice=400, returnNull=true, fontWeight, fontSize, fontColor, mTop, mLeft, mRight, mBottom} = props
    const [readMore, setReadMore] = useState({summary: false});

    const ReadMoreButton = ({targetName, targetValue, style}) => {
        // targetName: summary, skills, experiences
        // targetValue: true, false
        return <>
            <Link to={'javascript:void(0)'} style={{'margin-left': '5px', 'color': 'blue', 'width': '100px', ...style}} 
                  onClick={() => {
                    const target = {}
                    target[targetName] = targetValue
                    setReadMore({...readMore, ...target})
                  }}>
                read more
            </Link>
        </>
    }

    const ReadLessButton = ({targetName, targetValue, style}) => {
        return <>
            <Link to={'javascript:void(0)'} style={{'margin-left': '5px', 'color': 'blue', 'width': '100px', ...style}}
                  onClick={() => {
                    const target = {}
                    target[targetName] = targetValue
                    setReadMore({...readMore, ...target})
                  }}>
                read less
            </Link>
        </>
    }

    const Summary = () => {
        if (summary){
            return <>
                <Text style={{'text-wrap': 'wrap'}} color={'black'} hidden={readMore.summary}>
                <span>{summary?.slice(0, slice)}</span>
                    {summary?.length > slice && <ReadMoreButton targetName={'summary'} targetValue={true} />}
                </Text>
                <Text style={{'text-wrap': 'wrap'}} color={'black'} hidden={!readMore.summary}>
                    <span>{summary}</span>
                    <ReadLessButton  targetName={'summary'} targetValue={false} />
                </Text>
            </>
        }
        if (returnNull){
            return <Text as={'b'}>N/A</Text>
        }
    }

    return (
        <Flex ml={mLeft ? mLeft : null} mr={mRight ? mRight : null}
                mt={mTop ? mTop : null} mb={mBottom ? mBottom : null}
                fontSize={fontSize ? fontSize : null}
                fontWeight={fontWeight ? fontWeight : null}
                color={fontColor ? fontColor : null} alignItems={'center'}
            >
            <Summary />
        </Flex>        
    )
  }
