import React, { useEffect, useState } from 'react';
import { Link as ReactRouterLink } from 'react-router-dom';
import { useLocation, useNavigate } from 'react-router-dom';
import { Link as ChakraLink, Box, Drawer, DrawerContent, DrawerHeader, Text, Flex, Spinner, Badge, Icon, Button, 
    useColorModeValue, useDisclosure, DrawerCloseButton, Divider, Input, Tooltip, useToast,
} from '@chakra-ui/react';
import {AiFillLinkedin,AiOutlineMail} from 'react-icons/ai';
import { getEmailId, getJob } from '../../../../utils/localStorageIndex';
import Card from '../../../../components/card/Card';
import SocialIcon from '../../../common/SocialIcon';
import ResumePdfView from '../../../business/jobWizard/jobWorkflow/Workflow/CandidateSearch/ResumePDFView';
import { fetchPersonalInfo } from '../../../../services/sharedServices';
import NewInterviewSchedule from './components/NewInterviewSchedule.js';
import {fetchInterviewReadyCandidates,candidateKanbanStageUpdate} from '../../../../services/jobTemplateService.js';
import { MdPhoneInTalk } from 'react-icons/md';
import { isObjectEmpty, toTitleCase } from '../../../../utils/common.js';
import DraftJob from './components/DraftJob.js';
import { BsFileEarmarkPdf } from 'react-icons/bs';
import CandidateSalaryExpectations from '../../../common/CandidateSalaryExpectations.js';
import CandidateHighestEducation from '../../../common/CandidateHighestEducation.js';
import CandidateLocation from '../../../common/CandidateLocation.js';
import ScheduleInterviewYesButton from '../../../common/buttons/ScheduleInterviewYesButton.js';
import CandidateLatestJob from '../../../common/CandidateLatestJob.js';
import CandidateWorkStatus from '../../../common/CandidateWorkStatus.js';
import CandidateHeadshot from '../../../common/CandidateHeadshot.js';
import RejectCandidate from '../../jobWizard/jobWorkflow/Workflow/CandidateSearch/RejectCandidate.js';


function ReadyToInterviewCandidates() {
  const { state } = useLocation();
  const emaiId = getEmailId();
  const business = state?.business
  const adminSettings = state?.adminSettings ? state.adminSettings : false
  const navigate = useNavigate();
  const btnRef = React.useRef();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast();

  const { isOpen: isRejectionPanelOpen, onOpen: onRejectionPanelOpen, onClose: onRejectionPanelClose } = useDisclosure();
  const [loading, isLoading] = useState(false);
  const [selectedJobUuid, setSelectedJobUuid] = useState(state?.job_uuid ?state?.job_uuid: null);
  const [selectedJobTitle, setSelectedJobTitle] = useState(null);
  const [selectedJob, setSelectedJob] = useState(() => {

    if (state && !isObjectEmpty(state.job)){
        return state.job
    }

    const urlParams = new URLSearchParams(window.location.search);
    const nylasAuthCode = urlParams.has('code') ? urlParams.get('code') : null;
    if (nylasAuthCode){
        return getJob();
    }

    return {}
  });
  
  const [componentSelected, setComponentSelected] = useState(null);
  const [selectedCandidate, setSelectedCandidate] = useState({});
  const [selectedCandidateId, setSelectedCandidateId] = useState('');

  const [userDetails, setUserDetails] = useState({});
  const [candidateForInterview, setCandidateForInterview] = useState({});
  const { isOpen: isInterviewPanelOpen, onOpen: onInterviewPanelOpen, onClose: onInterviewPanelClose } = useDisclosure();
  const [jobsAndCandidates, setJobsAndCandidates] = useState([]);
  const [allJobs, setAllJobs] = useState([]);
  const [openJobs, setOpenJobs] = useState([]);
  const [draftJobs, setDraftJobs] = useState([]);
  const [selectedJobs, setSelectedJobs] = useState([]);
  const [selectedJobsType, setSelectedJobsType] = useState('open')
  const [selectedDraftJob, setSelectedDraftJob] = useState({})
  const [searchQuery, setSearchQuery] = useState(null);
  const [candidateResumeUrl, setCandidateResumeUrl] = useState(null);
  const [alertMessage, setAlertMessage] = useState('No Candidate Shortlisted');

  const textColorPrimary = useColorModeValue('secondaryGray.900', 'white');
  const cardBgColorStyle = useColorModeValue('gray.100', 'gray.800'); // {light, dark}
  const borderColorStyle = useColorModeValue('gray.200', 'gray.400'); // {light, dark}
  const bgItem = useColorModeValue("white", "navy.700");
  const textColorBadge = "gray.700";
  const themeButtonTextColor = useColorModeValue(
    'secondaryGray.900',
    'gray.700'
  );

  useEffect( () => {
    filterJobsBySearchQuery();
  }, [searchQuery])

  useEffect(() => {
    fetchJobsAndCandidate();
    let objData = {
        email: emaiId,
    };
    fetchPersonalInfo(objData)
      .then(res => {
        if (res.result) {
          setUserDetails(res.data[0]);
        }
      })
  }, []);

  useEffect(() => {
    const openJobs = jobsAndCandidates.filter( job => job.job_status.toLowerCase() == 'open');
    setOpenJobs(openJobs);

    const draftJobs = jobsAndCandidates.filter( job => job.job_status.toLowerCase() == 'draft');
    setDraftJobs(draftJobs);

    setSelectedJobs(selectedJobsType == 'open' ? openJobs : draftJobs);

  }, [jobsAndCandidates])

  const showOpenJobs = () => {
    if (selectedJobsType == 'open'){
        return
    }

    setSelectedJobsType('open');
    setSelectedJobs(openJobs);
    setSelectedDraftJob({});
  }

  const showDraftJobs = () => {
    if (selectedJobsType == 'draft'){
        return
    }
    setSelectedJobsType('draft');
    setSelectedJobs(draftJobs);
    setSelectedDraftJob({});
    setSelectedJobUuid(null);
    setSelectedJobTitle(null);
    setSelectedJob({});
  }

  const fetchJobsAndCandidate = () => {
    let objBody = {
      email: emaiId,
      business_uuid: business?.business_uuid || null
    };
    isLoading(true);
    fetchInterviewReadyCandidates(objBody)
      .then(res => {
        isLoading(false);
        if (res.result && res.data.length) {
            setJobsAndCandidates(res.data);
            setAllJobs(res.data);
            if (isObjectEmpty(selectedJob)){
                setSelectedJob(res.data[0])
            }
        } else {
          isLoading(false);
          setJobsAndCandidates([]);
          setAllJobs([]);
          setAlertMessage('No Candidate in Ready to Interview List');
        }
      })
      .catch(err => {
        isLoading(false);
        setJobsAndCandidates([]);
        setAlertMessage('Oops Something Went Wrong !!!');
      });
  };

  const handleJobSelection = (job) => {

    if (job.job_status.toLowerCase() == 'draft'){
        setSelectedDraftJob(job);
        return
    }

    setSelectedJobUuid(job?.job_uuid);
    setSelectedJobTitle(job?.job_title);
    setSelectedJob(job);
  }

  // update stage proecessing
  const updateCandidateStage = (candidateId, jobUuid, new_position) => {
    let obj = {
      email: emaiId,
      job_uuid: jobUuid,
      candidate_uuid: candidateId,
      job_workflow_position: new_position,
    };
    candidateKanbanStageUpdate(obj)
      .then(res => {
        if (res?.result) {
            fetchJobsAndCandidate();
        }
      })
      .catch(err => {
        console.log(err);
      });
  };

  const launchCandidateProfilePage = (candidate, job) => {
    const route = adminSettings ? '/admin/jobworkflow/candidateview' : '/business/jobsourcing/candidateview'
    navigate(route, {
        state: {
            candidate: candidate,
            job: job,
            business: business,
            sourcePage:'interview_ready',
            adminSettings: adminSettings
        },
    });
  };

  const setCandidateInterviewPanel = (candidate) => {
    setCandidateForInterview({...candidate, email: candidate?.candidate_email_address});
    onInterviewPanelOpen();
  }

  const onChangeSearchQuery = (q) => {
    setSearchQuery(q);
  }

  const filterJobsBySearchQuery = () => {
    if (searchQuery){
        const filteredJobsAndCandidates = allJobs?.filter(job => job?.job_title.toLowerCase().search(searchQuery.toLowerCase()) != -1);
        setJobsAndCandidates(filteredJobsAndCandidates);

        if (filteredJobsAndCandidates.length > 0){
            setSelectedJob(filteredJobsAndCandidates[0]);
        }
    }
    else {
        setJobsAndCandidates(allJobs);
    }
  }

  async function copyTextToClipboard(text) {
    return await navigator.clipboard.writeText(text);
  }

  const renderAllApplicants = (applicantsObj, jobTitle) => {
    if (applicantsObj.length > 0){
        return(
            <Flex  
                direction={'column'}
                overflow="auto"
                borderTopWidth="0px"
                css={{
                '&::-webkit-scrollbar': {
                    width: '4px',
                },
                '&::-webkit-scrollbar-track': {
                    width: '6px',
                },
                '&::-webkit-scrollbar-thumb': {
                    borderRadius: '24px',
                },
                }}            
                >

                {applicantsObj.map( (data, index) => {

                    let exp_years = Math.floor(data?.experience_month/12);
                    let exp_months = data?.experience_month % 12;
                    if(exp_years != 0){
                        exp_years = `${exp_years} yrs `
                    }else{
                        exp_years = null
                    }

                    if(exp_months != 0){
                        exp_months = `${exp_months} months`
                    }else{
                        exp_months = null
                    }

                    return(
                        <Card
                            p={'20px 25px 2px'}
                            key={index}
                            bg={cardBgColorStyle}
                            borderWidth={'0.1px'}
                            borderColor={borderColorStyle}
                            mb={5}
                            borderRadius={'6px'}
                            boxShadow={'0 3px 3px 0 lightgray, 0 1px 2px 0 lightgray'}
                            width={{'base': '100%', '2xl': '1000px'}}
                        >                            
                            <Flex
                                direction={'column'}
                                borderBottom={'1px solid #d7d7d7'}
                            >                                
                                <Flex direction={{'base': 'column', 'xl': 'row'}}>
                                    
                                  <CandidateHeadshot candidate={data} height={'80px'} width={'80px'} borderRadius={'80px'}  mRight={'15px'}/>                          
    
                                    {/* bio data */}
                                    <Box ml={{'base': 0, 'xl': 0}} pb={4} mt={{'base': 4, 'xl': 0}} pl={{'base': 0, 'xl': 0}} fontSize={'16px'} fontWeight={500}>
                                        <Text
                                            fontSize={'20px'}
                                            fontWeight={'bold'}
                                            cursor={'pointer'}
                                            onClick={() => {
                                                launchCandidateProfilePage(data, selectedJob);
                                            }}
                                            color={'blue.400'}>{`${data?.first_name} ${data?.last_name}`}</Text>
                                        
                                        <CandidateLocation candidate={data} />
                                        <CandidateLatestJob candidate={data} rawData={data?.raw_data} mTop={1} />                                        
                                        <CandidateHighestEducation candidate={data} rawData={data?.raw_data} mTop={1} />
                                        <CandidateSalaryExpectations salary={JSON.parse(data?.expectation || "{}")?.salary} mTop={1} fontWeight={'500'} />                                       
                                        {adminSettings && <CandidateWorkStatus candidate={data} mTop={1}/> }
                                    </Box>
                                </Flex>
                                
                            </Flex>
                       
                            {/* social and interview interaction */}
                            <Flex direction={{'base': 'column', 'xl': 'row'}} justifyContent={'space-between'} alignItems={'center'}
                                borderTop={'0.5px solid gray.300'}
                            >
                                
                                {/* social */}
                                <Flex justifyContent={'center'} alignItems={'center'}>
                                    {data?.candidate_email_address
                                    ?<ChakraLink to={'javascript:void(0)'} mt={2} onClick={ () => {
                                        copyTextToClipboard(data?.candidate_email_address).then(
                                            toast({
                                                title: "Email copied to clipboard!",
                                                status: "success",
                                                duration: 3000
                                            })
                                        )
                                    }}>
                                        <Tooltip label={`${data?.candidate_email_address}`} fontSize='md'>
                                            <span><Icon as={AiOutlineMail} fontSize={'22px'} /></span>
                                        </Tooltip>                        
                                    </ChakraLink>:null}
                                    {data?.candidate_phone_number && 
                                        <ChakraLink to={'javascript:void(0)'} mt={2} ml={1} onClick={ () => {
                                            copyTextToClipboard(data?.candidate_phone_number).then(
                                                toast({
                                                    title: "Phone copied to clipboard!",
                                                    status: "success",
                                                    duration: 3000
                                                })
                                            )
                                        }}>
                                            <Tooltip label={`${data?.candidate_phone_number}`} fontSize='md'>
                                                <span><Icon as={MdPhoneInTalk} fontSize={'20px'} /></span>
                                            </Tooltip>
                                        </ChakraLink>
                                    }
                                    {data?.resume ? (
                                        <Button
                                            size="sm"
                                            rounded={'md'}
                                            bg={'none'}
                                            width={'4px'}
                                            _hover={{'bg': 'none'}}
                                            mt={1}
                                            mr={-1}
                                            onClick={() => {
                                                setComponentSelected('resume');
                                                setCandidateResumeUrl(data?.resume);
                                                onOpen();
                                            }}
                                        >
                                            <Tooltip label={'view Resume'} fontSize='md'>
                                                <span><Icon as={BsFileEarmarkPdf} fontSize={'18px'} /></span>
                                            </Tooltip>
                                        </Button>
                                        ) : null}
                                    {data?.linkedin ? (
                                    <ChakraLink
                                        as={ReactRouterLink}
                                        isExternal
                                        to={data?.linkedin}
                                        mr={1} mt={2}
                                    >
                                        <Icon
                                        fontSize={'22px'}
                                        fontWeight={'bold'}
                                        color={'blue.400'}
                                        as={AiFillLinkedin}
                                        ></Icon>
                                    </ChakraLink>
                                    ) : null}
                                    <SocialIcon socialData={JSON.parse(data?.social)} mt={2}/>
                                </Flex>
                                
                                {/* interview interactions */}
                                {!adminSettings &&
                                <Flex mt={{'base': 4, 'xl': 0}} mb={{'base': 4, 'xl': 0}}>
                                    <Text fontSize={13} fontWeight={'600'} mr={'10px'}>
                                            Schedule For Interview
                                    </Text>

                                    <ScheduleInterviewYesButton onClickHandler={setCandidateInterviewPanel} onClickHandlerArgs={[data]} />
                                    <Button
                                        onClick={() => {
                                        setComponentSelected('reject');
                                        setSelectedCandidate(data);
                                        setSelectedJobUuid(data?.job_uuid);
                                        setSelectedCandidateId(data?.candidate_uuid);
                                        onRejectionPanelOpen();
                                        }}
                                        _hover={{'bg': 'red.600', 'color': 'white'}}
                                        bg={'gray.200'}
                                        ml={2}
                                        size={'xs'}
                                        color={themeButtonTextColor}
                                        width={'60px'}
                                    >
                                        No
                                    </Button>
                                </Flex>
                                }
                            </Flex>
                        </Card>
                    )
                })}
            </Flex>
        )
    } else {
        return(
            <Flex p={5}>
                <Text>No Candidates..</Text>
            </Flex>
        )
    }
  }

  const filteredApplicants = () => {
    if (selectedJobs.length > 0){

        if (selectedJobsType == 'draft' && !isObjectEmpty(selectedDraftJob)){
            return <DraftJob job={selectedDraftJob} />
        }

        if (selectedJobsType == 'draft'){
            return  <Flex>
                        <Text fontWeight={'bold'}>Select a job from Drafts</Text>
                    </Flex>
        }

         // otherwise handle open job and candidates

        let filteredData = selectedJobs?.filter(data => {
            return data?.job_uuid === selectedJob.job_uuid;
        });
        if (filteredData.length > 0){
            filteredData = filteredData[0];
        }

        if (filteredData?.applicants &&  filteredData?.applicants?.length > 0){
                return(
                    <Flex 
                        direction={'column'} 
                        w={'100%'}
                    >
                        <Text 
                            fontSize={'22px'} 
                            fontWeight={'800'}
                            mb={'10px'}
                            color={'gray.500'}
                        >
                            {selectedJobTitle}
                        </Text>

                        <Divider orientation='horizontal' mb={'20px'} />
                        
                        {renderAllApplicants(filteredData?.applicants, filteredData.job_title)}
                    </Flex>
                )
        } else {
            return(
                <Flex w={'100%'} p={5}>
                    <Text>Selected <b>{filteredData.job_title}</b> job has no candidate in "Ready to Interview" category..</Text>
                </Flex>
            )
        }
    } else {
        return(
            <Flex w={'100%'} p={5}>
                <Text>
                    { selectedJobsType == 'open' ?
                      "Please select a job from your left to select candidates.." :
                      "Please select a job from your left to edit.."  
                    }
                </Text>
            </Flex>
        )
    }
  }

  const renderAllJobs = () => {
    if (allJobs.length > 0 ){
        return(
            <Box>
                <Button
                variant="action"
                mb={'5px'}
                px="24px"
                bg={'#1E90FF'}
                color={'white'}
                fontSize="sm"
                fontWeight="700"
                borderRadius={'6px'}
                onClick={() => navigate('/business/addJobWizard')}
                >
                    Add New Job
                </Button>

                <Input borderRadius={'25px'} mb={'10px'} placeholder='Search job'
                  onChange={e => onChangeSearchQuery(e.target.value)}
                />

                <Flex 
                    textAlign={'center'}
                    justifyContent={'space-between'}
                    alignItems={'center'}
                    borderWidth={'0.1px 0.1px 0px 0.1px'}
                    borderTopRadius={'6px'}
                    height={'40px'}
                    color={'gray.500'}
                    borderBottomWidth={selectedJobs.length > 0 ? '0px' : '0.1px'}            
                >
                    <Button w={'50%'} fontWeight={'300'} variant={'ghost'} onClick={showOpenJobs}
                            color={selectedJobsType == 'open' ? 'blue.500' : null}
                            _hover={{'cursor': selectedJobsType == 'open' ? 'default' : null}}
                    >
                        Open ({openJobs.length})
                    </Button>
                    <Button w={'50%'} fontWeight={'300'} variant={'ghost'} onClick={showDraftJobs}
                            color={selectedJobsType == 'draft' ? 'blue.500' : null}
                            _hover={{'cursor': selectedJobsType == 'draft' ? 'default' : null}}
                    >
                        Drafts ({draftJobs.length})
                    </Button>
                </Flex>
            {
                selectedJobs.map((jobItem, index) => {
                    const selectedJobBgColor = jobItem?.job_uuid == selectedJob.job_uuid ? 'blue.100' : '';
                    return(
                        <Flex 
                            key={index}
                            _hover={{ cursor:'pointer'}}
                            w={'100%'}
                            bg={bgItem}
                            borderWidth={'0.1px 0.1px 0px 0.1px'}
                            borderBottomRadius={index == selectedJobs.length - 1 ? '6px' : '0px'}
                            borderBottomWidth={index == selectedJobs.length - 1 ? '0.1px' : '0px'}
                            color={textColorPrimary}
                        >
                            <Flex w={'100%'}
                                p={4}                                
                                direction={'column'}
                                bg={selectedJobBgColor}
                                onClick={() => handleJobSelection(jobItem)}>                            
                                <Text fontWeight='500' fontSize={'lg'}>{jobItem.job_title}</Text>
                                {jobItem?.applicants && jobItem?.applicants?.length ?
                                    <Text color={textColorPrimary} fontWeight='500' fontSize='xs'>
                                        Candidates    
                                        <Badge bg={'green.100'} ml={10} color={textColorBadge}>{jobItem?.applicants?.length}</Badge>
                                    </Text>
                                    :
                                    <Text color={textColorPrimary} fontWeight='500' fontSize='xs'>
                                        Candidates    
                                        <Badge bg={'red.100'} ml={10} color={textColorBadge}>{'0'}</Badge>
                                    </Text>
                                }
                            </Flex>
                        </Flex>
                    )
                })
            }
            </Box>
        )
    } 
  }
  
  return(
    <Box w={'100%'} pt={{ base: '180px', md: '80px', xl: '80px' }}>
        <NewInterviewSchedule
        {
            ...{
                onInterviewPanelOpen,
                onInterviewPanelClose,
                isInterviewPanelOpen,
                candidateForInterview,
                selectedJob,
                userDetails,
                updateCandidateStage,
                fetchJobsAndCandidate,
            }
        }
        />

        {loading ? (
            <>
            <Flex alignItems="center" direction={'column'} justifyContent="center" mt="10" h={'40vh'}>
                <Text fontSize={'lg'}>Loading ready to interview applicants...</Text>
                <Spinner size="xl" />
            </Flex>
            </>
        ) : (
            <Flex w={'100%'} direction={'column'}>
                {
                
                allJobs?.length > 0
                
                ?
                
                <Flex w={'100%'} direction={'row'}>
                    <Flex minWidth={'300px'} maxW={'300px'} 
                        overflow="auto"
                        borderTopWidth="0px"
                        css={{
                        '&::-webkit-scrollbar': {
                            width: '4px',
                        },
                        '&::-webkit-scrollbar-track': {
                            width: '6px',
                        },
                        '&::-webkit-scrollbar-thumb': {
                            borderRadius: '24px',
                        },
                        }}                    
                        direction={'column'}>
                        {renderAllJobs()}
                    </Flex>
                    <Flex w={'100%'} h={'100%'}  direction={'column'} padding={'15px 0px 15px 50px'}>
                        {filteredApplicants()}
                    </Flex>
                </Flex>
                
                :
                
                <Flex w={'100%'} p={4} borderWidth={'0.1px'} borderColor={'gray.200'} rounded={'md'}>
                    <Text fontSize={'md'}>You don't have any job within this selected criteria..</Text>
                </Flex>
                }
                
                <Drawer
                    size={'xl'}
                    isOpen={isOpen}
                    placement="right"
                    onClose={onClose}
                    finalFocusRef={btnRef}
                >
                    <DrawerContent overflow={'auto'} pl={5}>
                    <DrawerHeader>
                        {componentSelected === 'resume' ? 'Candidate Resume' : null}
                    </DrawerHeader>
                    <DrawerCloseButton />
                    {componentSelected === 'resume' ? (
                        <ResumePdfView pdfUrl={candidateResumeUrl} />
                    ) : null}
                    </DrawerContent>
                </Drawer>                           
            </Flex>
        )}

        <RejectCandidate
            selectedCandidates={[{...selectedCandidate, email: selectedCandidate?.candidate_email_address}]}
            isRejectionPanelOpen={isRejectionPanelOpen}
            onRejectionPanelClose={onRejectionPanelClose}
            postRejectActions={{
                fetchJobsAndCandidate: () => fetchJobsAndCandidate()
            }}
        /> 
    </Box>
  )
}

export default ReadyToInterviewCandidates;
