import React, {useEffect, useRef, useState } from 'react';
import {Box, Text, Input, Button, useToast, Select, Switch, Flex, Tooltip, DrawerBody, DrawerFooter, Drawer, DrawerContent, DrawerCloseButton, DrawerHeader, TableContainer, Table, Thead, Tr, Th, Tbody, Td} from '@chakra-ui/react';
import { getEmailId } from '../../../../../../utils/localStorageIndex';
import { rejectCandidateFromJobWorkflow } from '../../../../../../services/jobTemplateService';
import { Editor } from "@tinymce/tinymce-react";
import { CkeditorUtil } from '../../../../../../utils/ckeditor';
import EmailTemplateSelection from '../../../../../common/EmailTemplateSelection';
import EmailTemplateVariablesMenu from '../../../../../common/EmailTemplateVariablesMenu';
import TagsInput from 'react-tagsinput';
import { uploadFile } from '../../../../../../services/recruiterServices';

function RejectCandidate(props) {
  const {postRejectActions} = props;
  const [selectedCandidates, setSelectedCandidates] = useState([])
  const toast = useToast();
  const emailId = getEmailId();
  const [loading, isLoading] = useState(false);
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const editorRef = useRef(null);
  const [rejectionNotify, setRejectionNotify] = useState(false)
  const [rejectionNotifyDays, setRejectionNotifyDays] = useState(3)
  const [rejectionCustomReason, setRejectionCustomReason] = useState('')
  const [emailForm, setEmailForm] = useState({
    email: emailId,
    subject: null,
    message: null
  });
  const [toEmails, setToEmails] = useState([])
  const rejectedReasonCode = [
    { denial_detail: 'Overqualified', denial_code: '1', isChecked: false },
    { denial_detail: 'Lacks Years of Experience', denial_code: '2', isChecked: false },
    { denial_detail: 'Lacks Required Skills', denial_code: '3', isChecked: false },
    { denial_detail: 'Experience Not Relevant', denial_code: '4', isChecked: false },
    { denial_detail: 'Other', denial_code: '5', isChecked: false },
  ];
  const [rejectedReasonUI, setRejectedReasonUI] = useState(rejectedReasonCode);
  const [rejectionOtherHidden, setRejectionOtherHidden]  = useState(true)
  const [successMessage, setSuccessMessage] = useState([])

  useEffect(() => {
    setSelectedCandidates(props.selectedCandidates)
  }, [props.selectedCandidates])

  useEffect(() => {
    const selectedCandidateEmails = {}
    const emails = []
    selectedCandidates.map(candidate => {
      if (candidate?.email){
        emails.push(candidate?.email)
        if (candidate?.job_uuid in selectedCandidateEmails){
          selectedCandidateEmails[candidate?.job?.job_uuid].push(candidate?.email)
        }else{
          selectedCandidateEmails[candidate?.job?.job_uuid] = [candidate?.email]
        }
      }
    })

    const uniqueToEmails = [...new Set(emails)]
    setToEmails(uniqueToEmails)
  }, [selectedCandidates])

  useEffect(() => {
    if (selectedTemplate){
      setEmailForm({...emailForm, subject: selectedTemplate?.subject, message: selectedTemplate?.content})
    }
  }, [selectedTemplate])

  const insertVariableIntoEmailBody = (content) => {
    editorRef.current.insertContent(content)
  }

  const validateEmailForm = () => {
    if (!rejectionNotify){
      return true
    }

    if (toEmails?.length == 0){
      toast({
        title: "Recipient email address is required",
        status: 'error',
        duration: 3000
      })
      return false
    }
    if (!emailForm.subject){
      toast({
        title: "Email subject is required",
        status: 'error',
        duration: 3000
      })
      return false
    }
    if (!emailForm.message){
      toast({
        title: "Email message is required",
        status: 'error',
        duration: 3000
      })
      return false
    }

    return true
  }

  const onRejectCandidates = () => {
    if (!validateEmailForm()){
      return
    }

    isLoading(true);
    let denialCodeArr = [];
    let denialDetailArr = [];
    rejectedReasonUI.map(data => {
      if (data.isChecked === true) {
        denialCodeArr.push(data.denial_code);
        denialDetailArr.push(data?.denial_detail);
      }
    });
    if (rejectionCustomReason){
      denialCodeArr.push(5)
      denialDetailArr.push(rejectionCustomReason)
    }
    let objBody = {
      email: emailId,
      candidate_uuids: selectedCandidates?.map(candidate => candidate?.candidate_uuid),
      denial_codes: denialCodeArr?.length ? denialCodeArr.join() : '',
      denial_details: denialDetailArr?.length ? denialDetailArr.join(', ') : '',
      rejection_notify: rejectionNotify,
      rejection_notify_days: rejectionNotifyDays,
      rejection_email_message: CkeditorUtil.getContentWithLineStyles(emailForm?.message),
      rejection_email_subject: emailForm?.subject
    };
    rejectCandidateFromJobWorkflow(objBody)
      .then(res => {
        isLoading(false);
        if (res.result) {          
          setSuccessMessage(res?.data)
          toast({
            description: res?.message,
            status: 'success',
            duration: 5000,
          })
        } else {
          toast({
            title: 'Unable to reject candidates',
            description: res?.message,
            status: 'error',
            duration: 5000,
          })
        }
      })
      .catch(err => {
        isLoading(false);
        toast({
          title: 'Unable to reject candidate',
          description: err?.toString(),
          status: 'error',
          duration: 5000,
        })
      });
  };

  const renderReasonCode = rejectedReasonUI?.map((data, index) => (
    <Button width={'300px'} bg={data.isChecked ? 'green.200' : 'gray.200'} mb={5} mr={2}
      _hover={{bg: 'green.200'}} fontWeight={'100'} height={8} borderRadius={10}
      onClick={() => {
        updateRejectionReason(data);
      }}
    >
      {data?.denial_detail}
    </Button>
  ));

  const RejectionSuccessMessage = () => {
    if (successMessage.length == 0){
      return null
    }

    return <>
      <TableContainer direction={'column'} mt={'20px'} mb={10}>
        <Table fontSize={'16px'} colorScheme='teal'>
          <Thead>
            <Tr>
              <Th p={2} width={'100px'}>Name</Th>
              <Th p={2}>Rejected?</Th>
              <Th p={2}>Notify via Email</Th>
              <Th p={2}>Reason</Th>              
              <Th p={2} width={'150px'}>Email</Th>           
            </Tr>
          </Thead>

          <Tbody>
            {successMessage?.map(message => {
              return <>
                <Tr>    
                  <Td p={2}><Text>{message.candidate}</Text></Td>           
                  <Td p={2}><Text as={'b'} color={!message.rejected ? 'red.500' : 'inherit'}>{message.rejected ? "Yes" : "No"}</Text></Td>
                  <Td p={2}><Text as='b'>{message.notified_via_email ? "Yes" : "No" }</Text></Td>
                  <Td p={2}><Text style={{'textWrap': 'wrap'}} color={!message.rejected ? 'red.500' : 'inherit'}>{message.reason}</Text></Td>
                  <Td p={2}><Text>{message?.email ? message.email : "N/A"}</Text></Td>                                    
                </Tr>
              </>
            })}
          </Tbody>
        </Table>
      </TableContainer>
    </>
  }

  const updateRejectionReason = data => {
    let cloneRejectionArr = [...rejectedReasonUI];
    cloneRejectionArr?.map((value, idx) => {
      if (data.denial_detail === value.denial_detail) {
        value.isChecked = !value.isChecked;
      }
      setRejectedReasonUI(cloneRejectionArr);
      if (data.denial_detail == 'Other'){
        setRejectionOtherHidden(!value.isChecked);
      }
    });
  };

  const NotifyButtonLabel = () => {
    return toEmails?.length > 0 ?
    'Send rejection notification via email to candidate' : 
    'Candidates does not have an email address. Cannot notify via Email.'
  }

  const onRejectionPanelClose = () => {
    // call any post reject functions
    for (const [fnName, fn] of Object.entries(postRejectActions)) {
      fn()
    }
    setRejectionNotify(false)
    setSuccessMessage([])
    setRejectedReasonUI(rejectedReasonCode)
    setRejectionOtherHidden(true)
    props?.onRejectionPanelClose()
  }

  const handleFileUpload = (file, progress) => {
    const form = new FormData();
    form.append('file', file.blob());
    form.append('email', emailId)
    const promise = new Promise((resolve, reject) => {
      uploadFile(form)
      .then(res => {
        if (res?.result && res?.data?.file_url){
          resolve(res?.data?.file_url)
        }else{
          reject({message: res?.message, remove: true})
        }
      })
      .catch(err => {
        reject({message: err?.toString(), remove: true})
      })
    })

    return promise
  }

  return (
    <Drawer trapFocus={false} disableEnforceFocus={true}  size={'xl'} isOpen={props.isRejectionPanelOpen} placement="right" onClose={onRejectionPanelClose}>                        
      <DrawerContent overflow={'auto'} fontSize={'16px'}>
        <DrawerCloseButton />
        <DrawerHeader>
            Reject Candidate{selectedCandidates?.length > 0 ? "s" : null}
        </DrawerHeader>
        <DrawerBody>
        <Flex p={5} fontSize={'16px'} pr={10} direction={'column'}>
          <Flex mb={5}>
            <Text as={'b'}>Reject</Text>
            <Flex ml={10} direction={'column'}>
              {selectedCandidates?.map(candidate => <Text mb={2}>{candidate?.first_name} {candidate?.last_name}</Text>)}
            </Flex>
          </Flex>
          <Text fontSize={'14px'}>Rejection reason are private you and will not be shared with the candidate.</Text>
          <Box mt={2}>{renderReasonCode}</Box>
          <Input hidden={rejectionOtherHidden} mt={5} as="textarea" p="10px" rows={3} placeholder="Candidate Rejection Reason"
            maxHeight={'200px'} size="xl" borderRadius="0px" px="10px" mb={5}
            borderColor="grey.400" onChange={(e) => setRejectionCustomReason(e.target.value)}
          />

          <Flex mt={'40px'} mb={10}>
            <Text width={'200px'}>Send Rejection Notification</Text>
            <Tooltip label={<NotifyButtonLabel />} fontSize='md'>
                <span>
                  <Flex>
                  <Text mr={1} ml={'10px'}>{rejectionNotify ? "ON" : "OFF"} {rejectionNotify}</Text>
                  <Switch disabled={toEmails?.length == 0} mt={'1px'} onChange={e => setRejectionNotify(e.target.checked)} isChecked={rejectionNotify} />
                  </Flex>
                </span>
            </Tooltip>
          </Flex>

          <RejectionSuccessMessage />

          <Flex hidden={toEmails?.length == 0 || !rejectionNotify} direction={'column'} mb={8}>
            <Flex alignItems={'center'} mb={5} width={'75%'}>
              <Text mr={10}>Delay Notification</Text>
              <Select width={'150px'} height={'30px'} mr={2} mt={'0 !important'} value={rejectionNotifyDays} onChange={e => setRejectionNotifyDays(e.target.value)}>
                <option value={0}>No Delay</option>
                {Array.from(Array(10).keys()).map(days => <option value={days+1}>{days+1}</option>)}
              </Select>
              <Text>business days</Text>
            </Flex>

            <Flex direction={'column'} width={'60%'} mb={5}>
                <Text as='b'>To</Text>
                <TagsInput value={toEmails} maxTags={toEmails?.length}
                    disabled
                    renderLayout={(tagElements, inputElement) => <span> {tagElements} </span> }
                />
                <Text>Candidates without email address will not be notified via email.</Text>
                <Text hidden={toEmails?.length > 0} as={'i'} mt={1}>Cannot notify candidates without an email address</Text>
            </Flex>

            <Flex direction={'column'} mt={5} width={'60%'} mb={5}>
                <Text as='b'>Subject</Text>
                <Input value={emailForm.subject || ""} placeholder={'Thank you for applying'} onChange={e => setEmailForm({...emailForm, subject: e.target.value})}/>
            </Flex>

            <EmailTemplateSelection
              subject={emailForm.subject}
              emailBody={emailForm.message} // for saving current email body as template
              setSelectedTemplate={setSelectedTemplate} // to use template content as email body
            />

            <Flex direction={'column'} border={'1px solid'} borderColor={'gray.200'} mt={3}>
              <Editor
                tinymceScriptSrc={'/tinymce/tinymce.min.js'}
                value={emailForm.message || ""}
                onEditorChange={content => setEmailForm({...emailForm, message: content})}
                onInit={(evt, editor) => (editorRef.current = editor)}
                init={{
                  placeholder: 'Enter your email message here',
                  min_height: 500,
                  branding: false,
                  statusbar: false,
                  menubar: 'edit insert view format table tools',
                  promotion: false,
                  link_default_target: '_blank',
                  link_title: false,
                  plugins: ['advlist', 'image', 'link', 'anchor', 'autolink', 'autoresize', 'autosave', 'charmap', 'code',
                                'codesample', 'directionality', 'emoticons', 'help', 'importcss', 'insertdatetime',
                                'link', 'lists', 'nonbreaking', 'pagebreak', 'save', 'searchreplace', 'table', 
                                'template', 'visualblocks', 'visualchars', 'wordcount'
                              ],
                  toolbar: 'undo redo | bold italic underline blocks fontsize fontfamily | image link | alignleft aligncenter alignright alignjustify | indent outdent | table',
                  contextmenu: false,
                  images_file_types: 'jpeg,jpg,jpe,jfi,jif,jfif,png,gif,bmp,webp,svg',
                  file_picker_types: 'file',
                  images_upload_handler: handleFileUpload,
                  file_picker_callback: function(callback, value, meta) {
                    if (meta.filetype == 'file'){
                      const input = document.createElement('input');
                      input.setAttribute('type', 'file');
                      input.addEventListener('change', (e) => {
                        const file = e.target.files[0];
              
                        const reader = new FileReader();
                        reader.addEventListener('load', () => {

                          const id = 'blobid' + (new Date()).getTime();
                          const blobCache =  editorRef.current.editorUpload.blobCache;
                          const base64 = reader.result.split(',')[1];
                          const blobInfo = blobCache.create(id, file, base64);
                          blobCache.add(blobInfo);
                          handleFileUpload(blobInfo, null)
                          .then(res => callback(res, { title: file.name, textToDisplay: file.name }))
                          .catch(err => {
                            toast({
                              title: 'Unable to upload file',
                              description: err?.toString(),
                              status: 'error',
                              duration: 5000,
                            })
                          })
                        })

                        reader.readAsDataURL(file);

                      })

                      input.click();
                    }
                  }
                }}
                />
            </Flex>
            <EmailTemplateVariablesMenu
              insertVariableIntoEmailBody={insertVariableIntoEmailBody}
            />
            <Text fontSize={'14px'} mt={3}>Candidates will be notified by email.</Text>
          </Flex>                
        </Flex>
        </DrawerBody>
        <DrawerFooter>
          <Button size="sm" colorScheme="yellow" rounded={'md'} borderRadius="2px" mr={3} onClick={onRejectionPanelClose}>
            Cancel
          </Button>
          <Button size="sm" borderRadius="5px" colorScheme="red" onClick={onRejectCandidates}
            isDisabled={loading ? true : false} isLoading={loading ? true : false}
          >
            Reject {rejectionNotify ? "and notify" : "without notifying"}
          </Button>
        </DrawerFooter>
      </DrawerContent>
  </Drawer>    
  );
}

export default RejectCandidate;
