import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Text,
  Badge,
  Button,
  SimpleGrid,
  FormControl,
  Flex,
  HStack,
  Icon,
  Switch,
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Spinner,
  IconButton,
  useDisclosure,
  Grid,
  useColorModeValue,
  useToast,
  VStack,
} from '@chakra-ui/react';
import { AiOutlineMail, AiOutlineUser } from 'react-icons/ai';
import { getEmailId, getGroupId } from '../../../../utils/localStorageIndex';
import { fetchRecruiterData } from '../../../../services/recruiterServices';
import { enableDisableRecruiters } from '../../../../services/sharedServices';
import Card from '../../../../components/card/Card';
import SearchTableUsers from './components/SearchTableUsersOverivew';
import { columnsDataUsersOverview } from './components/columnsDataUsersOverview';
import InputField from '../../../../components/fields/InputField.js';
import { createRecruiter } from '../../../../services/sharedServices';

/* given role
 * Literal['Sourcer', 'Recruiter', 'Coordinator', 'Hiring Manager', 'Executive', 'Other']
 * API - /v1/api/recruiter/manage_given_role'
 * "
 */

function RecruitersManage() {
  const toast = useToast();
  const navigate = useNavigate();
  const [emailId, setEmailId] = useState(getEmailId());
  const [loading, isLoading] = useState(true);
  const [recruiterData, setRecruiterData] = useState([]);
  const brandColor = useColorModeValue('gray.100', 'white');

  const [recruiterForm, setRecruiterForm] = useState({
    email: getEmailId(),
    first_name: '',
    last_name: '',
    recruiter_email: '',
  });
  const [retype_recruitermail, setRetype_recruitermail] = useState('');
  const [retype_recruitermailErr, setRetype_recruitermailErr] = useState('');
  const [error, setError] = useState({});
  const errorColor = 'red';

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = () => {
    isLoading(true);
    fetchRecruiterData()
      .then(res => {
        isLoading(false);
        if (res.result && res.data.length) {
          setRecruiterData(res.data[0]);
        } else {
          isLoading(false);
          toast({
            title: res?.message,
            status: 'error',
            isClosable: true,
            duration: 3000,
          });
        }
      })
      .catch(err => {
        isLoading(false);
        if (err) {
          toast({
            title: err?.response?.message,
            status: 'error',
            isClosable: true,
            duration: 3000,
          });
        }
      });
  };

  const reRenderTableData = data => {
    fetchData();
  };

  const onUpdateField = e => {
    const nextFormState = {
      ...recruiterForm,
      [e.target.name]: e.target.value,
    };
    setRecruiterForm(nextFormState);
  };

  const validateFieldForm = () => {
    let fields = recruiterForm;
    let errors = {};
    let formIsValid = true;
    let emailPattern = new RegExp(
      /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/
    );
    if (!fields['first_name']) {
      formIsValid = false;
      errors['first_name'] = '*First Name is required.';
    }
    if (!fields['last_name']) {
      formIsValid = false;
      errors['last_name'] = '*Last Name is required.';
    }
    if (!fields['recruiter_email']) {
      formIsValid = false;
      errors['recruiter_email'] = '*Recruiter Email is required.';
    } else if (!emailPattern.test(fields['recruiter_email'])) {
      formIsValid = false;
      errors['recruiter_email'] = '*Please enter valid email address.';
    }
    if (!retype_recruitermail) {
      formIsValid = false;
      setRetype_recruitermailErr('*Email Cannot Be Empty.');
    } else if (fields['recruiter_email'] !== retype_recruitermail) {
      formIsValid = false;
      setRetype_recruitermailErr("*Email didn't match.");
    }
    setError(errors);
    return formIsValid;
  };

  const submitRecruiterData = event => {
    console.log('recuriterForm', recruiterForm);
    event.preventDefault();
    if (validateFieldForm()) {
      isLoading(true);
      createRecruiter(recruiterForm)
        .then(res => {
          isLoading(false);
          if (res.result) {
            toast({
              title: res.message,
              status: 'success',
              isClosable: true,
              duration: 3000,
            });
            navigate('/admin/team');
          } else {
            isLoading(false);
            toast({
              title: res.message,
              status: 'error',
              isClosable: true,
              duration: 3000,
            });
          }
          setRetype_recruitermailErr('');
        })
        .catch(err => {
          isLoading(false);
          if (err)
            toast({
              title: err?.response?.data?.detail,
              status: 'error',
              isClosable: true,
              duration: 3000,
            });
        });
    }
  };

  const InviteRecruiterWidget = () => {
    return (
      <Card w={'100%'}>
        <VStack w={'100%'}>
          <Flex w={'100%'}>
            <Text fontSize={'3xl'}>Invite Recruiter</Text>
          </Flex>
          <Flex w={'100%'}>
            <FormControl>
              <Card mb="20px" p="20px">
                <SimpleGrid
                  columns={{ sm: 1, md: 2 }}
                  spacing={{ base: '20px', xl: '20px' }}
                >
                  <Box>
                    <InputField
                      mb="0"
                      id="first_name"
                      label="First Name"
                      placeholder="Enter First Name"
                      value={recruiterForm.first_name}
                      name="first_name"
                      onChange={onUpdateField}
                    />
                    <Text fontSize="sm" mb="20px" color={errorColor}>
                      {error?.first_name}
                    </Text>
                  </Box>
                  <Box>
                    <InputField
                      mb="0"
                      id="last_name"
                      label="Last Name"
                      placeholder="Enter Last Name"
                      value={recruiterForm.last_name}
                      name="last_name"
                      onChange={onUpdateField}
                    />
                    <Text fontSize="sm" mb="20px" color={errorColor}>
                      {error?.last_name}
                    </Text>
                  </Box>
                </SimpleGrid>
                <InputField
                  mb="0"
                  id="recruiter_email"
                  label="Recruiter Email"
                  placeholder="Enter Recruiter Email Id"
                  value={recruiterForm.recruiter_email}
                  name="recruiter_email"
                  onChange={onUpdateField}
                />
                <Text fontSize="sm" mb="20px" color={errorColor} mt="1">
                  {error?.recruiter_email}
                </Text>
                <InputField
                  mb="0"
                  id="retype_recruitermail"
                  label="Re-Enter Email Id"
                  placeholder="Enter Recruiter Email Id"
                  value={retype_recruitermail}
                  name="retype_recruitermail"
                  onChange={e => {
                    setRetype_recruitermail(e.target.value);
                  }}
                />
                <Text fontSize="sm" color={errorColor} mt="1">
                  {retype_recruitermailErr}
                </Text>
                <Button
                  variant="brand"
                  minW="183px"
                  fontSize="sm"
                  fontWeight="500"
                  ms="auto"
                  mt="3"
                  onClick={e => {
                    submitRecruiterData(e);
                  }}
                  isDisabled={loading ? true : false}
                  isLoading={loading ? true : false}
                >
                  Save changes
                </Button>
              </Card>
            </FormControl>
          </Flex>
        </VStack>
      </Card>
    );
  };

  return (
    <Box pt={{ base: '130px', md: '80px', xl: '80px' }}>
      <Flex w={'100%'}>{InviteRecruiterWidget()}</Flex>
      <Flex w={'100%'} mt="20px">
        <Card px="0px">
          {loading ? (
            <Flex alignItems="center" justifyContent="center" mt="10" h="50vh">
              <Spinner size="xl" />
            </Flex>
          ) : recruiterData && recruiterData.length > 0 ? (
            <SearchTableUsers
              emailId={emailId}
              tableData={recruiterData}
              columnsData={columnsDataUsersOverview}
              onDataReceived={reRenderTableData}
            />
          ) : (
            <Alert borderRadius="8px" status="error" variant="subtle">
              <Flex>
                <AlertIcon />
                <Flex direction="column">
                  <AlertTitle mr="12px">Empty Recruiters List</AlertTitle>
                  <AlertDescription>
                    There are no team members!!
                  </AlertDescription>
                </Flex>
              </Flex>
            </Alert>
          )}
        </Card>
      </Flex>
    </Box>
  );
}
export default RecruitersManage;
