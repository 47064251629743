import React, { useState } from 'react';
import {Button, useToast, Popover, PopoverTrigger, PopoverContent,
  PopoverArrow, PopoverCloseButton, PopoverBody, PopoverFooter, ButtonGroup, Tooltip, IconButton, useDisclosure,
} from '@chakra-ui/react';
import { MdBackHand } from 'react-icons/md';
import { getEmailId } from '../../../../utils/localStorageIndex';
import { qualifyCandidate } from '../../../../services/recruiterServices';
import { isObjectEmpty } from '../../../../utils/common';


export default function DisqualifyCandidate(props) {
  const toast = useToast()
  const {bulkSelectedCandidates, postDisqualifyActions} = props
  let candidate = {}
  if (bulkSelectedCandidates?.length > 0){
    candidate = bulkSelectedCandidates[0]
  }
  const { onOpen, onClose, isOpen } = useDisclosure()
  const [isDisqualifyingCandidate, setIsDisqualifyingCandidate] = useState(false);

  const onDisqualifyCandidate = () => {    
    const data = {
                  candidate_uuids: bulkSelectedCandidates?.map(candidate => candidate?.candidate_uuid),
                  email: getEmailId(),
                  action: 'disqualify'
                }

    setIsDisqualifyingCandidate(true);
    qualifyCandidate(data)
    .then(res => {
        if (res.result) {
            onClose();
            // call any post disqualify functions
            for (const [fnName, fn] of Object.entries(postDisqualifyActions)) {
              fn()
            }
            toast({
              title: 'Candidate disqualified successfully.', 
              status: 'success',
              isClosable: true,
              duration: 3000,
            });
        } else{
          toast({
            title: 'Unable to disqualify candidate',
            description: res.message,
            status: 'error',
            duration: 3000,
          });
        }
        
        setIsDisqualifyingCandidate(false);        
    })
    .catch( err => {
      setIsDisqualifyingCandidate(false);
      console.log(err)
      toast({
          title: 'Unable to disqualify candidate', 
          description: err.toString(),
          status: 'error',
          duration: 3000,
      });
    })
}

  const ConfirmationLabel = () => {
     if (bulkSelectedCandidates.length > 1){
        return "Disqualify Candidates"
     }

     return 'Disqualify Candidate'
  }

  const ConfirmationMessage = () => {
    if (bulkSelectedCandidates.length > 1){
      return `Are you sure you want to disqualify these ${bulkSelectedCandidates?.length} selected candidates?`
    }
    return "Are you sure you want to disqualify this candidate?"
  }

  return (
        <Popover placement='bottom' isOpen={isOpen} onClose={onClose}>
              <Tooltip label={<ConfirmationLabel />} fontSize='md'>
                <span>
                  <PopoverTrigger>
                    <IconButton isDisabled={bulkSelectedCandidates?.length == 0 || isObjectEmpty(candidate)} onClick={onOpen} bg={'none'} _hover={{'bg': 'none'}} icon={<MdBackHand />} color={'gray'} />
                  </PopoverTrigger>
                </span>
              </Tooltip>
            
            <PopoverContent width={'350px'} color='white' bg='blue.800' borderColor='blue.800'>
                <PopoverArrow bg='blue.800' />
                <PopoverCloseButton />
                <PopoverBody mt={5}><ConfirmationMessage /></PopoverBody>
                <PopoverFooter border='0' display='flex' alignItems='center' justifyContent='space-between' pb={4}>
                <ButtonGroup size='sm'>
                    <Button colorScheme='blue' onClick={onDisqualifyCandidate}
                        isLoading={isDisqualifyingCandidate}
                        loadingText={'Disqualifiying...'}
                    >Yes</Button>
                </ButtonGroup>
                </PopoverFooter>
            </PopoverContent>
        </Popover>
    )
}
