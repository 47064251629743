import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, location } from 'react-router-dom';
import { defaultThemeColor } from '../../../../constants/index';
import { fetchPlanDetails } from '../../../../services/paymentServices';
import Pack from '../../../../views/admin/main/others/pricing/components/Pack';
import Card from '../../../../components/card/Card';
import {
    AiFillEdit,
    AiOutlineCheckCircle,
    AiFillFilePdf,
    AiFillInfoCircle,
    AiOutlineLinkedin,
    AiOutlineFilePdf,
    AiOutlineInfoCircle,
    AiOutlineArrowLeft,
    AiOutlineCaretLeft,
  } from 'react-icons/ai';
import {
  Box, Icon,
  HStack,
  Text,
  Spacer,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  CloseButton,
  UnorderedList,
  List,
  ListItem,
  ListIcon,
  Center,
  Flex,
  Button,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  Slider,
  Link,
  TabPanel,
  useToast,
  SliderFilledTrack,
  SliderThumb,
  SliderMark,
  SliderTrack,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  AlertDialogCloseButton,

  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerContent,
  useDisclosure,
  DrawerCloseButton,
  Spinner,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  ModalBody,
  useColorModeValue,
} from '@chakra-ui/react';
import { fetchTeamValidatePurchase, modifySelectedPlan } from '../../../../services/paymentServices';
import { getEmailId } from '../../../../utils/localStorageIndex';


function TeamAddMoreSlots() {
    const toast = useToast();
    const navigate = useNavigate();
    const { state } = useLocation();

    const btnRef = React.useRef();
    const { isOpen, onOpen, onClose } = useDisclosure()
    const cancelRef = React.useRef()

    const [loading, isLoading] = useState(false);

    const textColorPrimary = useColorModeValue('secondaryGray.900', 'gray.600');

    const subKey = state?.subKey ? state?.subKey : null;
    const [previousSlotCount, setPreviousSlotCount] = useState(0);    
    const [subscriptionData, setSubscriptionData] = useState([]);
    const [subscriptionKey, setSubscriptionKey] = useState(null);
    const [targetSlotCount, setTargetSlotCount] = useState(0);
    const [sliderValue, setSliderValue] = useState(1);

    const [validationPassed, isValidationPassed] = useState(false);

    const [alertVisible, setAlertVisible] = useState(false);
    const [alertMessage, setAlertMessage] = useState(
      {
        title:'Bad Setting',
        message:"You do not have proper access to this page.. Please go back to contact Talent Strap support!!",
        type:'error'
      });

    const shadow = useColorModeValue(
        '14px 17px 40px 4px rgba(112, 144, 176, 0.18)',
        '14px 17px 40px 4px rgba(112, 144, 176, 0.06)'
    );

    useEffect(() => {
        validatePurchaseActivity();
    }, []);

    const validatePurchaseActivity = () => {
            let objBody = {
                email: getEmailId()
            };
            fetchTeamValidatePurchase(objBody)
            .then(res => {
                isLoading(false);
                if (res?.result && res?.data?.length === 1 && res?.data[0]?.validation === true 
                    && res?.data[0]?.subscriptions?.length > 0) {
                    isValidationPassed(true);
                    setSubscriptionData(res?.data[0]?.subscriptions);
                    if (res?.data[0]?.subscriptions[0]?.total_slots >= 1){
                        setPreviousSlotCount(res?.data[0]?.subscriptions[0]?.total_slots);
                    }
                    // navigate('/business/billing', {
                    //     state: {
                    //         sourcePage:'cancelplan',
                    //     },
                    // });
                } 
            })
            .catch(err => {
            navigate('/business/billing', {
                state: {
                    sourcePage:'cancelplan',
                },
            });
            });
    };

    const startModificationProcess = () => {
        if (subscriptionKey != null){
            let objBody = {
                email: getEmailId(),
                sid: subscriptionKey,
                key: 'add',
                slots: targetSlotCount
            };
            modifySelectedPlan(objBody)
            .then(res => {
                isLoading(false);
                if (res?.result && res?.data?.length === 1 && res?.data[0]?.status) {
                    onClose();
                    toastFunction('Subscription Modified Successfully', 'success');
                    navigate('/business/joblist', {
                        state: {
                            sourcePage:'cancelplan',
                        },
                    });
                } else{
                    toastFunction('Unable to modify the subscription. Please contact Talent Strap support.', 'error');
                    onClose();
                }
            })
            .catch(err => {
                toastFunction('Unable to modify the subscription. Please contact Talent Strap support.', 'error');
                console.log(err);
                onClose();
            });      
        }
    }


    const renderSpinner = () => {
        return (
        <Flex h="50vh" alignItems="center" justifyContent="center" mt="10">
            <Spinner size="xl" />
        </Flex>
        );
    };

    const toastFunction = (message, status) => {
        return toast({
        title: message,
        status: status,
        isClosable: true,
        duration: 3000,
        });
    };

    const backToJobsPage = () => {
        navigate('/business/joblist', {
            state: {
                sourcePage:'purchaseplan',
                adminSettings: false
            },
        });
    };

    return (
        <>
        {loading ? (
            <>{renderSpinner()}</>
        ) : (
            <Flex direction="column" pt={{ sm: '125px', lg: '75px' }}>
            <Flex
                direction="column"
            >
                <Flex w={'100%'} direction={'column'} align={'center'}>
                    {validationPassed === false
                    ?<Flex w={'100%'} p={2} direction={'column'}>
                        <Alert status={alertMessage.type} >
                            <AlertIcon />
                            <Box>
                                <AlertTitle>{alertMessage.title}</AlertTitle>
                                <AlertDescription>{alertMessage.message}</AlertDescription>
                            </Box>
                            <Spacer />
                            <CloseButton
                                alignSelf='flex-start'
                                position='relative'
                                right={-1}
                                top={-1}
                            />                    
                        </Alert>
                    </Flex>
                    :<Flex w={'100%'} direction={'column'}>
                    <Card w={'100%'} p={1}>
                        <Flex w={'100%'} p={2}>
                            <Text mt={4} fontSize={'xl'}>{'Add more slots (Team)'}</Text>
                            <Spacer />
                            <Button 
                                onClick={() => backToJobsPage()}
                                mt={4} color={textColorPrimary} bg={'red.200'}>
                                <Icon as={AiOutlineCaretLeft} mr={2} />Cancel
                            </Button>
                        </Flex>
                    </Card>
                    <Card w={'100%'} mt={1}>
                        <HStack w={'100%'}>
                        <Flex w={'30%'}>
                            <Card w={'100%'} h={'400px'} p={2}>
                                <Text fontSize={'xl'}>Subscription upgrade is done on the following criteria:</Text>
                                <List spacing={3} mt={5}>
                                    <ListItem>
                                        <ListIcon as={AiOutlineCheckCircle} color='green.500' />
                                        You can add as many as 99 slots maximum.
                                    </ListItem>
                                    <ListItem>
                                        <ListIcon as={AiOutlineCheckCircle} color='green.500' />
                                        To reduce your slots to 0, please use <b>"Cancel Subscription"</b> option.
                                    </ListItem>
                                    <ListItem>
                                        <ListIcon as={AiOutlineCheckCircle} color='green.500' />
                                        This page does not help you reducing or canceling slots. 
                                    </ListItem>
                                    <ListItem>
                                        <ListIcon as={AiOutlineCheckCircle} color='green.500' />
                                        You will be prorated for the new slots you are adding here. 
                                    </ListItem>

                                </List>
                            </Card>
                        </Flex>
                        <Flex w={'20%'} direction={'column'}  h={'400px'}>
                            <Flex w={'100%'} direction={'column'} align={'start'}>
                            {validationPassed === true 
                            ?<Card align={'start'}  h={'400px'}  p={2}>
                                {subscriptionData?.length > 0
                                ?<Flex w={'100%'} direction={'column'}>
                                    <Text fontSize={'lg'}>{'Plan Info'}</Text>
                                    <Text mt={2}  fontSize={'md'}><b>{subscriptionData[0].plan_type}</b></Text>
                                    <Text>{subscriptionData[0].plan_info}</Text>
                                    <Flex direction={'column'}
                                        rounded={'md'} borderWidth={'0.5px'} borderColor={'gray.200'} p={2}>
                                        <Text fontSize={'3xl'}><b>{subscriptionData[0].total_slots}</b></Text>
                                        <Text>Slots in this Subscription</Text>
                                    </Flex>
                                    <Text>{subscriptionData[0].is_annual ? 'Annual': 'Monthly'} Plan</Text>
                                    <Text>Subscription Started: </Text>
                                    <Text><b>{new Date(subscriptionData[0].start_date).toLocaleDateString('en-us', 
                                    { weekday:"short", year:"numeric", month:"short", day:"numeric"})}</b></Text>
                                </Flex>
                                :<Text color={'red.400'}>Unable to get the subscription details!!</Text>}
                            </Card>
                            :<Flex w={'100%'} p={2} direction={'column'}>
                                <Text color={'red.200'}>Something is not right to subscribe business plan, please contact Talent Strap Support.</Text>
                            </Flex>}
                            </Flex>
                        </Flex>
                        <Flex w={'50%'} direction={'column'}  h={'400px'}>
                            <Flex w={'100%'} direction={'column'} align={'start'}>
                            {validationPassed === true
                            ?<Card align={'start'}  h={'400px'} p={2}>
                                {subscriptionData?.length > 0
                                ?<Flex w={'100%'} direction={'column'}>
                                    <Flex w={'100%'} direction={'column'} p={2} borderWidth={'0.5px'} rounded={'lg'} borderColor={'gray.200'}>
                                        <Text><b>Step 1:</b>{' '}Please use the slider below to add more slots in existing subscription:</Text>
                                        <Flex w={'100%'}>
                                            <Flex p={2} w={'130px'} align={'center'}>
                                                <Text>Minimum <b>{'1'}</b></Text>
                                            </Flex>
                                            <Flex p={2} w={'100%'}>
                                                <Slider aria-label='slider-ex-1' 
                                                    onChange={(val) => {
                                                        setSliderValue(val);
                                                        setTargetSlotCount(val);
                                                    }}
                                                    defaultValue={1} min={1} max={99} step={1}>
                                                    <SliderTrack>
                                                        <SliderFilledTrack />
                                                    </SliderTrack>
                                                    <SliderThumb />
                                                </Slider>                                                
                                            </Flex>
                                            <Flex p={2} w={'130px'} align={'center'}>
                                                <Text>Maximum <b>{99}</b></Text>
                                            </Flex>
                                        </Flex>
                                    </Flex>
                                    <Flex w={'100%'} p={2} borderWidth={'0.5px'} rounded={'lg'} borderColor={'gray.200'}>
                                        <Flex w={'50px'} align={'center'} pl={4}>
                                            <Text color={sliderValue === 0 ? 'red' : 'green'} fontSize={'2xl'}>{sliderValue}</Text>
                                        </Flex>
                                        <Flex w={'100%'} align={'center'}>
                                            {sliderValue > 0
                                            ?<Flex w={'100%'} direction={'column'}>
                                                <Text>You have selected{' '}{sliderValue}{' '}slot to add into your subscription.</Text>
                                            </Flex>
                                            :<Flex w={'100%'} direction={'column'}>
                                                <Text>You have not selected any slot yet.</Text>
                                            </Flex>}
                                        </Flex>
                                    </Flex>                                 
                                    {/* <Text color={'gray.600'} mb={1} pl={1}><b>Step 1:</b>{' '}Please close some of the open jobs to free up slots to cancel.</Text> */}
                                    {/* {renderJobsList()} */}
                                    <Flex w={'100%'} direction={'column'}>
                                        <Text mt={5} fontSize={'sm'}><b>Step 2:</b>{' '}Please use button below to upgrade slot count.</Text>
                                        <Flex p={2} borderWidth={'0.5px'} rounded={'lg'} borderColor={'red.200'}
                                            w={'100%'} mt={2}>
                                                {/* <Flex w={'30%'}>
                                                    <Button borderWidth={'0.5px'} rounded={'lg'} borderColor={'red.200'}
                                                        onClick={() => {
                                                            updateSlotCount();
                                                        }}
                                                    >Reduce 1 Slots</Button>
                                                    <Button ml={1} borderWidth={'0.5px'} rounded={'lg'} borderColor={'red.200'}
                                                        onClick={() => {
                                                            resetSlotCount();
                                                        }}
                                                    >Reset</Button>
                                                </Flex> */}
                                                <Flex w={'70%'}>
                                                    <Button  
                                                        isDisabled={targetSlotCount === 0 ? true : false}
                                                        onClick={() => 
                                                            {
                                                                setSubscriptionKey(subscriptionData[0]?.sub_key);
                                                                onOpen();
                                                            }}
                                                        ml={1} size={'md'} px={10} borderWidth={'0.5px'} rounded={'lg'} borderColor={'red.200'}>
                                                            Update Subscription with {targetSlotCount} additional (Total: {previousSlotCount + targetSlotCount}){' '}slots
                                                    </Button>                                                    
                                                </Flex>
                                        </Flex>
                                    </Flex>
                                </Flex>
                                :<Text color={'red.400'}>Unable to get the subscription details!!</Text>}
                            </Card>
                            :<Flex w={'100%'} p={2} direction={'column'}>
                                <Text color={'red.200'}>Something is not right to subscribe business plan, please contact Talent Strap Support.</Text>
                            </Flex>}
                            </Flex>
                        </Flex>
                        </HStack>
                        </Card>
                    </Flex>}
                </Flex>
            </Flex>
            <AlertDialog
                isOpen={isOpen}
                leastDestructiveRef={cancelRef}
                onClose={onClose}
            >
                <AlertDialogOverlay>
                <AlertDialogContent>
                    <AlertDialogHeader fontSize='lg' fontWeight='bold'>
                    Upgrade Subscription to total {targetSlotCount + previousSlotCount} Slots
                    </AlertDialogHeader>
                    <AlertDialogBody p={8}>
                    Are you sure to upgrade the subscription with {targetSlotCount} new slots? 
                    <br/>
                    </AlertDialogBody>

                    <AlertDialogFooter>
                    <Button ref={cancelRef} onClick={onClose}>
                        Cancel
                    </Button>
                    <Button colorScheme='green' onClick={() => startModificationProcess()} ml={3}>
                        Upgrade Subscription
                    </Button>
                    </AlertDialogFooter>
                </AlertDialogContent>
                </AlertDialogOverlay>
            </AlertDialog>
            </Flex>
        )}
        </>
    );
}

export default TeamAddMoreSlots;
