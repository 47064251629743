// chakra imports
import {
  Flex,
  Text,
  useColorModeValue,
  Box,
  Badge,
  Tooltip,
  Button,
} from '@chakra-ui/react';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { isObjectEmpty } from '../../utils/common';

export function ItemContent(props) {
  const notification = props?.info
  const textColor = useColorModeValue('navy.700', 'white');
  const navigate = useNavigate();

  const navigateToRoute = (notification) => {
    const action = JSON.parse(notification?.on_click || "{}")
    if (!isObjectEmpty(action) && action?.path){
      navigate(action?.path, {state: action?.params?.state});
    }
    props.markNotificationAsRead(notification)
  };

  return (
    <Flex flexDirection="column" w="100%">
        <Tooltip label={'Click to view'}>
          <span>
            <Text
              fontSize={'md'} pl={3} mt={1} lineHeight="100%"
              color={textColor} w="100%"
              dangerouslySetInnerHTML={{__html: notification?.message?.length > 200
                ? notification?.message?.substring(0, 200) + '...'
                : notification?.message}}
              onClick={() => navigateToRoute(notification)}
              cursor={'pointer'}
              padding={'10px 0px 10px 10px'}
            >
            </Text>
          </span>
        </Tooltip>
        <Flex justifyContent={'space-between'} alignItems={'center'}>
          <Box>
            {notification?.group ? (
              <Badge variant="subtle" colorScheme="green" size="sm">
                {notification?.group}
              </Badge>
            ) : null}
              <Button variant="ghost" color={'green'} size="sm" fontWeight={'normal'}
                onClick={() => props.markNotificationAsRead(notification)}>
                Mark as Read
              </Button>
          </Box>
          {/* {notification?.action ? (
            <Box>
              <Button
                colorScheme="green"
                size="xs"
                borderRadius={'10px'}
                onClick={() => {
                  props.markNotificationAsRead(props.notificationIndex);
                }}
              >
                {notification?.action}
              </Button>
            </Box>
          ) : null} */}
        </Flex>
    </Flex>
  );
}
