import React, { useEffect, useState } from 'react';
import {useToast, Text, Button, useDisclosure, Flex, Input, Menu, MenuButton, MenuList, MenuItem,
    Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, ModalFooter,
} from '@chakra-ui/react';
import { ChevronDownIcon } from '@chakra-ui/icons';
import { getEmailId } from '../../utils/localStorageIndex';
import { fetchPersonalInfo, updateUserInfo } from '../../services/sharedServices';


export default function EmailTemplateVariablesMenu(props) {
  const {containerWidth='60%', containerMarginTop='10px'} = props;
  const toast = useToast()
  const emailId = getEmailId()
  const {onOpen: onAddCalendarPanelOpen, onClose: onAddCalendarPanelClose, isOpen: isAddCalendarPanelOpen} = useDisclosure()
  const [isCalendarUrlAdding, setIsCalendarUrlAdding] = useState(false)
  const [calendarUrl, setCalendarUrl] = useState(null)
  const [userDetails, setUserDetails] = useState({});

  useEffect(() => {
    getUserDetails()
  }, [])

  const getUserDetails = () => {
    fetchPersonalInfo({ email: emailId }).then(res => {
        if (res.result && res?.data?.length > 0) {
          setUserDetails(res?.data[0]);
          setCalendarUrl(res?.data[0]?.calendar_url)
        }else{
          toast({
            title: 'Unable to get user details',
            description: res?.message,
            state: 'error',
            duration: 5000
          })
        }
      }).catch(err => {
        toast({
          title: 'Unable to get user details',
          description: err?.toString(),
          state: 'error',
          duration: 5000
        })
      })
  }

  const onAddCalendar = () => {        
    setIsCalendarUrlAdding(true)
    updateUserInfo({...userDetails, calendar_url: calendarUrl}).then(res => {
        if (res?.result && res?.data?.length > 0){
            onAddCalendarPanelClose()
            setIsCalendarUrlAdding(false)
            props.insertVariableIntoEmailBody('{calendarUrl}')
            toast({
                title: "Calendar URL updated",
                status: 'success',
                duration: 3000
            })
        }else{
            toast({
                title: "Unable to update calendar url",
                description: res?.message,
                status: 'error',
                duration: 5000
            })
        }         
    }).catch(err => {
        setIsCalendarUrlAdding(false)
        toast({
            title: "Unable to update calendar url",
            description: err?.toString(),
            status: 'error',
            duration: 5000
        })
    })        
  }

  const VariablesMenu = () => {
    return <Flex mt={3}>
      <Menu>
        <MenuButton as={Button} height={8} variant={'outline'} fontWeight={'normal'} rightIcon={<ChevronDownIcon />}>
          Variables {"{}"}
        </MenuButton>
        <MenuList width={'150px'}>
          <MenuItem _hover={{'bg': 'gray.200'}} onClick={() => {props.insertVariableIntoEmailBody('{candidateFirstName}')}}>
            <Flex direction={'column'}>
              <Text>Candidate First Name</Text>
            </Flex>
          </MenuItem>
          <MenuItem _hover={{'bg': 'gray.200'}} onClick={() => {props.insertVariableIntoEmailBody('{candidateLastName}')}}>
            <Flex direction={'column'}>
              <Text>Candidate Last Name</Text>
            </Flex>
          </MenuItem>
          <MenuItem _hover={{'bg': 'gray.200'}} onClick={() => {props.insertVariableIntoEmailBody('{candidateFullName}')}}>
            <Flex direction={'column'}>
              <Text>Candidate Full Name</Text>
            </Flex>
          </MenuItem>
          <MenuItem _hover={{'bg': 'gray.200'}} onClick={() => {props.insertVariableIntoEmailBody('{job}')}}>
            <Flex direction={'column'}>
              <Text>Job Title</Text>
            </Flex>
          </MenuItem>
          <MenuItem _hover={{'bg': 'gray.200'}} onClick={() => {props.insertVariableIntoEmailBody('{company}')}}>
            <Flex direction={'column'}>
              <Text>Company Name</Text>
            </Flex>
          </MenuItem>
          <MenuItem _hover={{'bg': 'gray.200'}} onClick={() => {props.insertVariableIntoEmailBody('{jobLocation}')}}>
            <Flex direction={'column'}>
              <Text>Job Location</Text>
            </Flex>
          </MenuItem>
          <MenuItem _hover={{'bg': 'gray.200'}} onClick={() => {props.insertVariableIntoEmailBody('{senderFullName}')}}>
            <Flex direction={'column'}>
              <Text>Sender Full Name</Text>
            </Flex>
          </MenuItem>
          <MenuItem _hover={{'bg': 'gray.200'}} onClick={onAddCalendarPanelOpen}>
            <Flex direction={'column'}>
              <Text>Calendar URL</Text>
            </Flex>
          </MenuItem>    
        </MenuList>
      </Menu>
    </Flex>
  }


  return (
        <Flex width={containerWidth} mt={containerMarginTop}>

          <VariablesMenu />
          
          {/* add calendar url modal */}
          <Modal isOpen={isAddCalendarPanelOpen} onClose={onAddCalendarPanelClose}>
              <ModalOverlay />
              <ModalContent>
                  <ModalHeader>Calendar URL</ModalHeader>
                  <ModalCloseButton />
                  <ModalBody>
                  <Flex direction={'column'}>
                      <Text as={'b'}>Provide your availability calendar</Text>
                      <Input value={calendarUrl} onChange={e => setCalendarUrl(e.target.value)} />
                  </Flex>
                  </ModalBody>
                  <ModalFooter>                
                  <Button colorScheme='teal' onClick={onAddCalendarPanelClose}>Close</Button>
                  <Button ml={'5'} colorScheme='blue' onClick={onAddCalendar} isLoading={isCalendarUrlAdding} loadingText={calendarUrl ? "Updating" : "Adding"}>
                      {calendarUrl ? "Update" : "Add"}
                  </Button>
                  </ModalFooter>
              </ModalContent>
          </Modal>
        </Flex>
    )
}
