import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Text,
  Button,
  SimpleGrid,
  Flex,
  HStack,
  VStack,
  Icon,
  Spinner,
  Badge,
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  CloseButton,
  IconButton,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  useToast,
  ModalBody,
  Grid,
  useColorModeValue,
} from '@chakra-ui/react';
import { getEmailId, getGroupId } from '../../../../utils/localStorageIndex';
import Card from '../../../../components/card/Card';

// Components
import { columnsDataUsersOverview } from './components/columnsDataUsersOverview';
import TeamInviteWidget from './components/TeamInviteWidget';
import TeamInviteForm from './components/TeamInviteForm';
import DepartmentManagement from '../departments/DepartmentManagement';
import BusinessSurveyWizard from '../../settings/configuration/components/BusinessSurveyWizard';
import ViewOnlyBusinessUsers from './components/SearchTableUsersOverivew';
import Completion from '../../settings/configuration/components/Completion';

// Import Store Data
import { setNotificationData } from '../../../../store/actions/topNotificationActions';

// API
import {
  fetchBusinessInformation,
  fetchBusinesseAdminStatus,
  fetchAllHRMangerListPaginated,
} from '../../../../services/businessServices';
import { useDispatch } from 'react-redux';
import { customNotification } from '../../../../utils/customNotification';

function BusinessTeam() {
  const toast = useToast();
  const email = getEmailId();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [loading, isLoading] = useState(true);
  const [tableData, setTableData] = useState([]);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [errorMessage, setErrorMessage] = useState(
    'There are no HR Managers in your business. Please invite'
  );
  const [noRecordFlag, setNoRecordFlag] = useState(true);
  const [businessSurvery, setBusinessSurvey] = useState(false);
  const [modalFlag, setModalFlag] = useState('');

  const brandColor = useColorModeValue('brand.500', 'white');
  const bgBadge = useColorModeValue('brand.50', 'whiteAlpha.100');
  const bgContrast = useColorModeValue('secondaryGray.300', 'navy.900');

  useEffect(() => {
    fetchBusinessHRMgrsDetails();
    validateUserAdmin();
  }, []);

  const fetchBusinessHRMgrsDetails = () => {
    let objBody = {
      email: getEmailId(),
      records_per_page: 0,
      page_id: 0,
      records_length: 0,
    };
    fetchAllHRMangerListPaginated(objBody)
      .then(res => {
        isLoading(false);
        if (res.data.length > 0 && res.result == true) {
          setNoRecordFlag(false);
          setTableData(res.data[0].records);
        } else if (res.result == false) {
          toast({
            title: res?.message,
            status: 'error',
            isClosable: true,
            duration: 3000,
          });
        } else {
          setNoRecordFlag(true);
          toast({
            title: res?.message,
            status: 'error',
            isClosable: true,
            duration: 3000,
          });
        }
      })
      .catch(err => {
        isLoading(false);
        setNoRecordFlag(true);
        toast({
          title: err?.response?.message,
          status: 'error',
          isClosable: true,
          duration: 3000,
        });
      });
  };

  //Validate If use is Admin
  const validateUserAdmin = () => {
    let emailId = { email: getEmailId() };
    fetchBusinesseAdminStatus(emailId)
      .then(res => {
        if (res?.data && res?.data.length > 0) {
          if (res?.data[0].status) {
            validateSurveyDetails();
          }
        }
      })
      .catch(err => {
        console.log(err);
      });
  };

  //Validate If Survey has been completed
  const validateSurveyDetails = () => {
    let emailId = { email: getEmailId() };
    fetchBusinessInformation(emailId)
      .then(res => {
        let surveyData = JSON.parse(res?.data[0].survey);
        if (surveyData != null && surveyData != undefined) {
          const firstThreeQuestionsArr = surveyData?.sections.slice(0, 3);
          for (const item of firstThreeQuestionsArr) {
            if (item.options.length === 0) {
              onOpen();
              setModalFlag('complete_survey');
              setBusinessSurvey(true);
              return false;
            }
          }
          setBusinessSurvey(false);
          return true;
        } else {
          onOpen();
          setModalFlag('complete_survey');
          setBusinessSurvey(true);
          let notification = {
              priority: 'critical',
              message: 'Must complete Business Survey details in order to create a job.',
              action: 'OK',
            };
          customNotification(dispatch, notification);
          return false;
        }
      })
      .catch(err => {
        console.log(err);
      });
  };
  // Todo:Check Survey details
  const handleModalConfiguration = data => {
    if (data === true) {
      onClose();
      // checkSlotValidation();
      // setModalFlag('add_new_job');
    }
  };

  const onCloseModal = () => {
    onClose();
    setModalFlag('');
  };

  return (
    <Flex direction="column" pt={{ sm: '125px', lg: '75px' }}>
      <Grid w="100%" mb="20px" gap="20px" templateColumns="2.1fr 1fr">
        <TeamInviteWidget
          minWidth={'800px'}
          me="20px"
          gridArea={{ base: '1 / 1 / 2 / 3', '2xl': '1 / 1 / 2 / 2' }}
        />
        <TeamInviteForm
          minWidth={'200px'}
          referralCode="simmmple.com/referral=9421738401"
          fbLink="#"
          twtLink="#"
          gridArea={{ base: '2 / 1 / 3 / 3', '2xl': '1 / 2 / 2 / 3' }}
        />
      </Grid>
      <Box w={'100%'}>
        {loading ? (
          <Flex alignItems="center" justifyContent="center" mt="10" h="50vh">
            <Spinner size="xl" />
          </Flex>
        ) : (
          <>
            <VStack w={'100%'}>
              <Card px="0px">
                {loading ? (
                  <Flex
                    alignItems="center"
                    justifyContent="center"
                    mt="10"
                    h="50vh"
                  >
                    <Spinner size="xl" />
                  </Flex>
                ) : tableData && tableData.length > 0 ? (
                  <>
                    <ViewOnlyBusinessUsers
                      tableData={tableData}
                      columnsData={columnsDataUsersOverview}
                    />
                  </>
                ) : (
                  <Box w={'100%'} p={5}>
                    <Alert borderRadius="8px" status="error" variant="subtle">
                      <Flex>
                        <AlertIcon />
                        <Flex direction="column">
                          <AlertTitle mr="12px">
                            Empty Business Team List
                          </AlertTitle>
                          <AlertDescription>
                            There are no team members!!
                          </AlertDescription>
                        </Flex>
                      </Flex>
                    </Alert>
                  </Box>
                )}
              </Card>
              {/* Commented for MVP Avkash
                <Box w={'100%'}>
                <DepartmentManagement />
              </Box> */}
            </VStack>
          </>
        )}
      </Box>
      <Modal
        onClose={() => {
          onCloseModal();
        }}
        isOpen={isOpen}
        isCentered
      >
        <ModalOverlay />
        {modalFlag === 'complete_survey' ? (
          <ModalContent maxW="80%">
            <ModalHeader>Complete Business Survey Details</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <Flex justifyContent="space-between" p="3">
                <Box w="78%">
                  <BusinessSurveyWizard
                    minH={'auto'}
                    resentJobFlag={handleModalConfiguration}
                  />
                </Box>
                <Box w="20%">
                  <Completion />
                </Box>
              </Flex>
            </ModalBody>
          </ModalContent>
        ) : null}
      </Modal>
    </Flex>
  );
}

export default BusinessTeam;
