import React, { useEffect, useState } from 'react';
// Chakra imports
import {
    Button,
    Box,
    Select,
    Flex,
    FormControl,
    Text,
    useColorModeValue,
    useToast,
  } from "@chakra-ui/react";
  import Card from "../../../../../components/card/Card.js";
  import InputField from "../../../../../components/fields/InputField.js";
  
  // Api
  import { getEmailId } from '../../../../../utils/localStorageIndex';
  import { addIntegrationApi } from '../../../../../services/IntegrationServices.js';

  export default function HrGustoWidget(props) {
    const toast = useToast();

    const hrKey = props.hrKey ? props.hrKey : null;
    const [atsSystem, setAtsSystem] = useState(null);

    // Chakra Color Mode
    const textColorPrimary = useColorModeValue("secondaryGray.900", "white");
    const textColorSecondary = "secondaryGray.600";

    const [configObj, setConfigObj] = useState({
        gusto_client_id: '',
        gusto_client_secret: '',
        gusto_redirect_url: '',
        type:'hr',
        hrKey: hrKey,
    });

    const onUpdateField = e => {
        const nextFormState = {
          ...configObj,
          [e.target.name]: e.target.value,
        };
        setConfigObj(nextFormState);
    };
    
    const toastFunction = (message, status) => {
        return toast({
            title: message,
            status: status,
            isClosable: true,
            duration: 3000,
        });
    };

    const validateConfig = () => {
        if (configObj.gusto_client_id.length > 0 && 
            configObj.gusto_client_secret.length > 0 &&
            configObj.gusto_redirect_url.length > 0){
            return true;
        } else {
            return false;
        }
    }

    const saveIntegrationData = () => {
        if (validateConfig()  === true) {
            let objBody = {
                email: getEmailId(),
                config: JSON.stringify(configObj)
            };
            addIntegrationApi(objBody)
            .then(res => {
                if (res?.result && res?.data?.length === 1 && res?.data[0]?.status) {
                    // setSubscriptionData(res?.data[0]?.subscriptions);
                    // if (res?.data[0]?.subscriptions[0]?.total_slots > 1){
                    //     setTargetSlotCount(res?.data[0]?.subscriptions[0]?.total_slots-1);
                    // }
                    toastFunction('Gusto Integration is successfully completed..', 'success');
                } 
            })
            .catch(err => {
                toastFunction('Gusto Integration failed to complete..', 'error');
            });            
        } else {
            toastFunction('Required data is missing...', 'error');
        }
    }

    return (
      <FormControl>
          <Flex direction='column' mb='40px' ms='10px'>
            <Text fontSize='xl' color={textColorPrimary} fontWeight='bold'>
            Gusto integration with Talent Strap
            </Text>
            <Text fontSize='sm' color={textColorSecondary}>
              You must have enabled API integration with your own Gusto HR Platform
            </Text>
          </Flex>
          {hrKey != null
          ?<Flex w={'100%'} direction='column' rounded={'md'} m={2} p={2} h={'200px'}>
            <InputField
                    mb='25px'
                    id='gusto_client_id'
                    name='gusto_client_id'
                    label='OAuth Client Id'
                    value={configObj.gusto_client_id}
                    onChange={onUpdateField}
                    placeholder='Please enter your Gusto OAuth Client Id'
                />
                <InputField
                    mb='25px'
                    id='gusto_client_secret'
                    label='OAuth Client Secret'
                    name='gusto_client_secret'
                    value={configObj.gusto_client_secret}
                    onChange={onUpdateField}
                    placeholder='Please enter your Gusto OAuth Client Secret'
                />
                <InputField
                    mb='25px'
                    id='gusto_redirect_url'
                    label='OAuth Redirect Uri'
                    name='gusto_redirect_url'
                    value={configObj.gusto_redirect_url}
                    onChange={onUpdateField}
                    placeholder='Please enter your Gusto OAuth Redirect Uri'
                />
                <Flex direction='row' mb='40px' ms='10px'>
                <Button
                    onClick={() => saveIntegrationData()}
                    variant='brand'
                    minW='183px'
                    fontSize='sm'
                    fontWeight='500'
                    ms='auto'>
                    Save changes
                </Button>
                </Flex>
          </Flex>:null}
      </FormControl>
    );
  }
  