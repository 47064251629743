import axios from './axios';
import { serviceUrls } from './serviceUrls';

export const addIntegrationApi = (data, config = null, axiosInstance = axios) => {
  return axiosInstance.post(serviceUrls().integrationCreate, data, config);
};

export const updateIntegrationApi = (data, config = null, axiosInstance = axios) => {
    return axiosInstance.post(serviceUrls().integrationUpdate, data, config);
};

export const manageIntegrationApi = (data, config = null, axiosInstance = axios) => {
    return axiosInstance.post(serviceUrls().integrationManage, data, config);
};


export const createMergeToken = (data, config = null, axiosInstance = axios) => {
  return axiosInstance.post(serviceUrls().createMergeToken, data, config);
};

export const saveMergeToken = (data, config = null, axiosInstance = axios) => {
  return axiosInstance.post(serviceUrls().saveMergeToken, data, config);
};

export const deleteMergeIntegration = (data, config = null, axiosInstance = axios) => {
  return axiosInstance.post(serviceUrls().deleteMergeIntegration, data, config);
};