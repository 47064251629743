import React, { useEffect, useState } from 'react';
import { 
  Box,
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  Text,
  Badge,
  Button,
  SimpleGrid,
  Flex,
  HStack,
  Icon,
  Switch,
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Spinner,
  IconButton,
  useDisclosure,
  Grid,
  useColorModeValue,
  useToast, 
  CardHeader,
  CardBody
} from '@chakra-ui/react';
import Card from '../../../../components/card/Card';
import Upload from './components/Upload';
import BusinessUpdateWidget from '../../../../views/admin/main/users/newUser/BusinessUpdateWidget';
import BusinessSurveyWizard from './components/BusinessSurveyWizard';
import Indeed from '../integration/components/Indeed';
import ATSIntegration from '../integration/components/AtsIntegrationWidget';
import LinkedInIntegration from '../integration/components/LinkedInIntegration';
import Completion from './components/Completion';
import { AiOutlineMail, AiOutlineUser } from 'react-icons/ai';
import { getEmailId, getGroupId } from '../../../../utils/localStorageIndex';
import {
  fetchAllHRMangerListPaginated,
  changeBusinessUserStatus,
} from '../../../../services/businessServices';
import EditableBusinessUsers from './components/EditableBusinessUsers';
import { TeamManagementDataHeader } from './components/TeamManagementHeader';

function BusinessConfiguration() {
  const toast = useToast();
  const email = getEmailId();
  const [loading, isLoading] = useState(true);
  const [tableData, setTableData] = useState([]);
  const [noRecordFlag, setNoRecordFlag] = useState(true);
  const brandColor = useColorModeValue('gray.100', 'white');

  useEffect(() => {
    fetchBusinessHRMgrsDetails();
  }, []);

  const fetchBusinessHRMgrsDetails = () => {
    isLoading(true);
    let objBody = {
      email: getEmailId(),
      team_only: true,
      records_per_page: 0,
      page_id: 0,
      records_length: 0,
    };
    fetchAllHRMangerListPaginated(objBody)
      .then(res => {
        isLoading(false);
        if (res.data.length > 0 && res.result == true) {
          setNoRecordFlag(false);
          setTableData(res.data[0].records);
        } else if (res.result == false) {
          toast({
            title: res?.message,
            status: 'error',
            isClosable: true,
            duration: 3000,
          });
        } else {
          setNoRecordFlag(true);
          toast({
            title: res?.message,
            status: 'error',
            isClosable: true,
            duration: 3000,
          });
        }
      })
      .catch(err => {
        isLoading(false);
        setNoRecordFlag(true);
        toast({
          title: err?.message,
          status: 'error',
          isClosable: true,
          duration: 3000,
        });
      });
  };

  const reRenderTableData = data => {
    fetchBusinessHRMgrsDetails();
  };

  return (
    <Flex direction="column" pt={{ sm: '125px', lg: '100px' }}>
      {/* <SimpleGrid
        mb="1px"
        columns={{ sm: 1, lg: 2 }}
        spacing={{ base: '20px', xl: '20px' }}
        alignItems="center"
      >
        <Flex direction="column">
          <Upload
            gridArea={{
              base: '3 / 1 / 4 / 2',
              lg: '1 / 3 / 2 / 4',
            }}
            minH={{ base: 'auto', lg: '420px', '2xl': '365px' }}
            pe="20px"
            pb={{ base: '100px', lg: '20px' }}
          />
        </Flex>
        <Flex direction="column">
          <NewUser />
        </Flex>
      </SimpleGrid> */}
      <Flex w={'100%'}>
        <Upload
            gridArea={{
              base: '3 / 1 / 4 / 2',
              lg: '1 / 3 / 2 / 4',
            }}
            minH={{ base: 'auto', lg: '420px', '2xl': '365px' }}
            pe="20px"
            pb={{ base: '100px', lg: '20px' }}
          />
      </Flex>
      <Flex w={'100%'}>
        <BusinessUpdateWidget />
      </Flex>
      <Flex justifyContent="space-between" p="3" mb={20}>
        <Flex w="100%">
          <Card px="0px" w={'100%'}>
            <Box w={'100%'} pl={10}>
              <Text fontSize={'xl'}>
                Team Members Administration
              </Text>
            </Box>
            <Box>
              {tableData && tableData.length > 0 ? (
                <EditableBusinessUsers
                  emailId={email}
                  tableData={tableData}
                  columnsData={TeamManagementDataHeader}
                  onDataReceived={reRenderTableData}
                />
              ) : (
                <Box p={10}>
                  <Alert borderRadius="8px" status="error" variant="subtle">
                    <Flex>
                      <AlertIcon />
                      <Flex direction="column">
                        <AlertTitle mr="12px">
                          {noRecordFlag
                            ? 'Empty Team List'
                            : 'Unauthorized access..'}
                        </AlertTitle>
                        <AlertDescription>
                          There are no team members!!
                        </AlertDescription>
                      </Flex>
                    </Flex>
                  </Alert>
                </Box>
              )}
            </Box>
          </Card>
        </Flex>
      </Flex>
      <Flex mt={'30px'} justifyContent="space-between" p="3">
        <Box w="78%">
          <BusinessSurveyWizard />
        </Box>
        <Box w="20%">
          <Completion />
        </Box>
      </Flex>
      {/* <Flex direction="column">
        <SimpleGrid
          mb="20px"
          columns={{ sm: 1, lg: 2 }}
          spacing={{ base: '20px', xl: '20px' }}
        >
          <Flex direction="column">
            <LinkedInIntegration />
            <Indeed />
          </Flex>
          <Flex direction="column">
            <ATSIntegration />
          </Flex>
        </SimpleGrid>
      </Flex> */}
    </Flex>
  );
}

export default BusinessConfiguration;
