import React, { useState } from 'react';
// Chakra imports
import {
  Button,
  Text,
  useColorModeValue,
  Flex,
  Box,
  Image,
  FormControl,
  Input,
  VStack,
  Stack,
  Heading,
  Icon,
} from '@chakra-ui/react';
import { BiUpload } from 'react-icons/bi';
// Custom components
import Card from '../../../../../components/card/Card.js';
// Assets
import { MdUpload } from 'react-icons/md';
import Dropzone from './Dropzone';
import { useDispatch } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import { getEmailId } from '../../../../../utils/localStorageIndex';
import { fetchHeadshotUrl } from '../../../../../services/sharedServices';
import axios from '../../../../../services/axios';
import { setProfileLogoFlag } from '../../../../../store/actions/topNotificationActions';

export default function Upload(props) {
  const { used, total, ...rest } = props;
  // Chakra Color Mode
  const textColorPrimary = useColorModeValue('secondaryGray.900', 'white');
  const brandColor = useColorModeValue('brand.500', 'white');
  const textColorSecondary = 'gray.400';

  const dispatch = useDispatch();
  const [loading, isLoading] = useState(true);
  const [uploadFile, setUploadFile] = useState();
  const [uploadFileUrl, setUploadFileUrl] = useState();
  const emaiId = getEmailId();
  const navigate = useNavigate();
  const { state } = useLocation();
  const [uploadStatus, setUploadStatus] = useState();
  const [uploadStatusLoading, setUploadStatusLoading] = useState(false);

  function postObjectData(s3Data, selectedFile) {
    dispatch(setProfileLogoFlag(false));
    const { url, fields } = s3Data;
    const payload = new FormData();

    // add all the other fields
    Object.entries(fields).forEach(([key, val]) => {
      payload.append(key, val);
    });
    // payload.append("Content-Type", "image/jpeg");
    // This must be the last line in the payload otherwise 412 error may occur
    // this is the file blob, eg from <input type="file">
    payload.append('file', selectedFile);

    // No need to manually set content-type header, your browser knows what to do
    // const { data: result } = axios.post(url, payload);
    // https://stackoverflow.com/questions/46656474/axios-remove-headers-authorization-in-1-call-only
    var ax_instance = axios.create();
    delete ax_instance.defaults.headers.common['Authorization'];
    const { data: result } = ax_instance
      .post(url, payload)
      .then(res => {
        isLoading(false);
        if (res?.data?.length > 0) {
          setUploadStatus('File is uploaded successfully');
          dispatch(setProfileLogoFlag(true));
        } else {
          setUploadStatus('File is uploaded successfully');
          dispatch(setProfileLogoFlag(true));
        }
      })
      .catch(err => {
        isLoading(false);
        setUploadStatus('Upload failed with Error :' + err);
      });
  }

  const handleFileChange = e => {
    if (e.target.files) {
      setUploadFile(e.target.files[0]);
      setUploadFileUrl(URL.createObjectURL(e.target.files[0]));
    }
  };
  const handleSubmitNew = async () => {
    setUploadStatusLoading(true);
    if (uploadFile) {
      let objData = {
        email: emaiId,
        content_choice: 'headshot',
        file_name: uploadFile.name,
        file_type: uploadFile.type,
      };
      setUploadStatus('Getting upload url for the selected file..');
      const response = await fetchHeadshotUrl(objData);
      setUploadStatusLoading(false);
      if (response && response.data && response.data.length > 0) {
        setUploadStatusLoading(false);
        const finalUrl = response.data[0].signedPackage.signedUrl;
        setUploadStatus('Trying to upload the selected file..');
        const s3UploadResult = await postObjectData(finalUrl, uploadFile);
      } else {
        setUploadStatusLoading(false);
        setUploadStatus('Unable to get the upload signed url..');
      }
    }
  };

  return (
    // <Card {...rest} mb="20px" align="center" p="20px">
    //   <Flex h="100%" direction={{ base: 'column', '2xl': 'row' }}>
    //     <Dropzone
    //       w={{ base: '100%', '2xl': '268px' }}
    //       me="36px"
    //       maxH={{ base: '60%', lg: '50%', '2xl': '100%' }}
    //       minH={{ base: '60%', lg: '50%', '2xl': '100%' }}
    //       content={
    //         <Box>
    //           <Icon as={MdUpload} w="80px" h="80px" color={brandColor} />
    //           <Flex justify="center" mx="auto" mb="12px">
    //             <Text fontSize="xl" fontWeight="700" color={brandColor}>
    //               Upload Files
    //             </Text>
    //           </Flex>
    //           <Text fontSize="sm" fontWeight="500" color="secondaryGray.500">
    //             PNG, JPG and GIF files are allowed
    //           </Text>
    //         </Box>
    //       }
    //     />
    //     <Flex direction="column" pe="34px">
    //       <Text
    //         color={textColorPrimary}
    //         fontWeight="bold"
    //         textAlign="start"
    //         fontSize="2xl"
    //         mt={{ base: '20px', '2xl': '50px' }}
    //       >
    //         Update your headshot
    //       </Text>
    //       <Text
    //         color={textColorSecondary}
    //         fontSize="md"
    //         my={{ base: 'auto', '2xl': '10px' }}
    //         mx="auto"
    //         textAlign="start"
    //       >
    //         Your profile headshot adds character to your profile and presence
    //         with the team.
    //       </Text>
    //       <Flex w="100%" mb="50px" mt={{ base: '20px', '2xl': 'auto' }}>
    //         <Button
    //           me="auto"
    //           w="140px"
    //           variant="brand"
    //           fontWeight="500"
    //           onClick={handleFileChange}
    //         >
    //           Add Headshot
    //         </Button>
    //       </Flex>
    //     </Flex>
    //   </Flex>
    // </Card>
    <Card {...rest} mb="20px" align="center" p="20px">
      <Box w={'100%'}>
        <FormControl cursor="pointer">
          <Text fontSize={'12px'}>{uploadFileUrl}</Text>
          <Text mb={4} fontSize={'12px'}>
            {uploadFile && `${uploadFile.name} - ${uploadFile.type}`}
          </Text>
          {/* <Input type="file" onChange={handleFileChange}></Input> */}
          <Box
            position="relative"
            height="200px"
            width="100%"
            borderWidth="2px"
            borderColor={brandColor}
            borderRadius={'10px'}
            cursor="pointer"
          >
            <Box
              position="absolute"
              top="0"
              left="0"
              height="100%"
              width="100%"
              display="flex"
              flexDirection="column"
              cursor="pointer"
            >
              <Stack
                height="100%"
                width="100%"
                display="flex"
                alignItems="center"
                justify="center"
                spacing="4"
                cursor="pointer"
              >
                <Box textAlign="center">
                  <Icon as={MdUpload} w="80px" h="80px" color={brandColor} />
                  <Flex justify="center" mx="auto" mb="12px">
                    <Text fontSize="xl" fontWeight="700" color={brandColor}>
                      Upload Files
                    </Text>
                  </Flex>
                  <Text
                    fontSize="sm"
                    fontWeight="500"
                    color="secondaryGray.500"
                  >
                    PNG, JPG and GIF files are allowed
                  </Text>
                </Box>
              </Stack>
            </Box>
            <Input
              type="file"
              height="100%"
              width="100%"
              position="absolute"
              top="0"
              left="0"
              opacity="0"
              aria-hidden="true"
              accept=".png, .jpg, .jpeg, .pdf" // Include .pdf here to accept PDF files
              cursor="pointer"
              onChange={handleFileChange}
            />
          </Box>
        </FormControl>
      </Box>

      <Flex direction="column" pe="34px">
        <Text
          color={textColorPrimary}
          fontWeight="bold"
          textAlign="start"
          fontSize="2xl"
          mt={{ base: '20px', '2xl': '50px' }}
        >
          Update your headshot
        </Text>
        <Text
          color={textColorSecondary}
          fontSize="md"
          my={{ base: 'auto', '2xl': '10px' }}
          mx="auto"
          textAlign="start"
        >
          Your profile headshot adds character to your profile and presence with
          the team.
        </Text>
      </Flex>
      {uploadFile ? (
        <Flex
          w="100%"
          mb="30px"
          mt={{ base: '20px', '2xl': 'auto' }}
          alignItems="center"
        >
          <Button
            me="auto"
            mb="2"
            w="60%"
            variant="brand"
            fontWeight="500"
            onClick={handleSubmitNew}
          >
            Add Headshot
          </Button>
          <Box w={'100%'}>
            <Text color="green">{uploadStatus}</Text>
          </Box>
        </Flex>
      ) : null}
    </Card>
  );
}
