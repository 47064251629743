import React, { useEffect, useState } from 'react';
import { AiOutlineCodeSandbox } from 'react-icons/ai';
import { Outlet, useNavigate, useLocation, useParams } from 'react-router-dom';
import {
  themeColor,
  borderBottomColor,
  themeFontSizeXlHeading,
  themeFontSizeOddHeading,
} from '../../constants/index';
import {
  Flex,
  Box,
  Text,
  Checkbox,
  HStack,
  Button,
  Icon,
  useToast,
  useColorMode,
  Spacer,
} from '@chakra-ui/react';
import { getEmailId, getGroupId, getJob, getJobUuid } from '../../utils/localStorageIndex';

import {
  jobWorkflowStarted,
  fetchJobDetailedData,
} from '../../services/jobTemplateService';

// Save It
// import CandidateProcessing from './CandidateProcessing/CandidateProcessing';
// import InterviewProcessing from './InterviewProcessing/InterviewProcessing';
// import HiringProcess from './HiringProcess';
// import WorkflowFinalization from './WorkflowFinalization';
// import RejectedCandidate from './RejectedCandidate/RejectedCandidate';

import StageOneSourcedCandidates from './components/StageOneSourced';
import { MdRefresh } from 'react-icons/md';
import { isObjectEmpty } from '../../utils/common';

function JobWorkflowCommon() {
  const email = getEmailId();
  const groupId = getGroupId();
  const navigate = useNavigate();
  const toast = useToast();
  const location = useLocation();
  const { state } = useLocation();

  const urlParams = new URLSearchParams(window.location.search);
  const nylasAuthCode = urlParams.has('code') ? urlParams.get('code') : null;
  const selectedJob = state && !isObjectEmpty(state.job) ? state.job : getJob();
  const adminSettings = state?.adminSettings ? state?.adminSettings : false; //Todo:Fetching job_title from state

  const { colorMode } = useColorMode();
  const job_title_other = state?.job_title_other; //Todo:Fetching job_uuid from state
  const page_id = state?.page_id; //Todo:Fetching job_uuid from state
  const [workflowStatusFlag, setWorkflowStatusFlag] = useState(false);
  const [workFlowReloadFlag, setWorkflowReloadFlag] = useState(false);
  const [workfloReloadFlagCounter, setWorkflowFlagCounter] = useState(0);
  // const [reloadNow, setReloadNow] = useState(true);

  const workflowStatusData = [];

  useEffect(() => {
    setWorkflowReloadFlag(false);
    setWorkflowFlagCounter(0);
  }, [workfloReloadFlagCounter]);

  useEffect(() => {
    workflowStatusData?.map(data => {
      if (data.routeKey === location?.pathname.split('/')[2]) {
        data.isSelected = true;
      } else {
        data.isSelected = false;
      }
    });
  }, [location.pathname]);

  useEffect(() => {
    fetchJobStatusData();
  }, []);

  const fetchJobStatusData = () => {
    let objBody = {
      email: email,
      job_uuid: selectedJob.job_uuid,
    };
    fetchJobDetailedData(objBody)
      .then(res => {
        if (res.result) {
          setWorkflowStatusFlag(res.data[0].job_workflow_started);
          // setReloadNow(false);
        }
      })
      .catch(err => {
        console.log(err);
      });
  };
  const [workflowList, setWorkflowList] = useState(workflowStatusData);

  // const navigateToRoutes = data => {
  //   if (groupId === '100') {
  //     navigate(data?.adminRoute, {
  //       state: {
  //         job_uuid: job_uuid,
  //         job_title: job_title,
  //         job_title_other: job_title_other,
  //         page_id: page_id,
  //         adminSettings: 'true',
  //       },
  //     });
  //   } else {
  //     navigate(data?.route, {
  //       state: {
  //         job_uuid: job_uuid,
  //         job_title: job_title,
  //         job_title_other: job_title_other,
  //         page_id: page_id,
  //         adminSettings: 'false',
  //       },
  //     });
  //   }
  //   let newArr = [...workflowList];
  //   newArr.map(subValue => {
  //     if (subValue.label === data.label) {
  //       subValue.isSelected = true;
  //     } else {
  //       subValue.isSelected = false;
  //     }
  //   });
  //   setWorkflowList(newArr);
  // };

  const routeToJobsList = () => {
    if (groupId === '100') {
      navigate('/admin/recruiterWorkflow', {
        state: {
          page_id: page_id,
        },
      });
    } else {
      navigate('/business/joblist', {
        state: {
          page_id: page_id,
        },
      });
    }
  };

  const setSelectedValueTrue = e => {
    setWorkflowStatusFlag(e.target.checked);
    let obj = {
      email: email,
      job_uuid: selectedJob.job_uuid,
    };
    jobWorkflowStarted(obj)
      .then(res => {
        if (res.result) {
          toast({
            title: res.message,
            status: 'success',
            isClosable: true,
            duration: 3000,
          });
        } else {
          toast({
            title: res.message,
            status: 'error',
            isClosable: true,
            duration: 3000,
          });
        }
      })
      .catch(err => {
        console.log(err);
      });
  };

  const handleReload = action => {
    if (action === 'reload') {
      fetchJobStatusData();
    }
  };
  return (
    <Box pt={{ sm: '125px', lg: '75px' }}>
      <Box pt="3">
        <Flex
          mr="6"
          pb="3"
          justifyContent="space-between"
          alignItems="center"
          position="fixed"
          w="-webkit-fill-available"
          borderBottomWidth={'1px'}
          bg={colorMode === 'dark' ? '#111c44' : '#fff'}
        >
          <HStack>
            <Text mr="2" fontSize={themeFontSizeXlHeading}>
              <Text
                as="span"
                ml="2"
                fontSize={18}
                color={themeColor}
                fontWeight="bold"
              >
                {selectedJob.job_title}
              </Text>
              {/* <Text
                as="span"
                ml="2"
                fontSize={14}
                color={themeColor}
                fontWeight="bold"
              >
                {`(${job_title_other})`}
              </Text> */}
            </Text>
          </HStack>
          <Spacer />
          {!adminSettings ? (
            <Button
              borderRadius="0px"
              variant="outline"
              fontWeight="normal"
              rounded={'md'}
              // _hover={{ bg: 'none' }}
              fontSize={themeFontSizeOddHeading}
              onClick={() => {
                routeToJobsList();
              }}
            >
              Back to Jobs List
            </Button>
          ) : null}
          <Button
            ml={1}
            borderRadius="0px"
            variant="outline"
            fontWeight="normal"
            rounded={'md'}
            // _hover={{ bg: 'none' }}
            fontSize={themeFontSizeOddHeading}
            onClick={() => {
              setWorkflowReloadFlag(true);
              setWorkflowFlagCounter(1);
            }}
            isDisabled={workFlowReloadFlag ? true : false}
            isLoading={workFlowReloadFlag ? true : false}
          >
            <Icon as={MdRefresh} /> Reload
          </Button>
        </Flex>
      </Box>
      <Flex mt="16">
        {!isObjectEmpty(selectedJob)
        ?<StageOneSourcedCandidates
          job={selectedJob}
          handleReload={handleReload()}
          workFlowReloadFlag={workFlowReloadFlag}
        />:null}
        {/* <InterviewProcessing />
        <HiringProcess />
        <WorkflowFinalization />
        <RejectedCandidate /> */}
      </Flex>
    </Box>
  );
}

export default JobWorkflowCommon;
