import * as actionType from '../actionTypes';

const initialState = {
  headerName: '',
  statusColor: '',
  businessInfoStatus: false,
  sideMenuFlag: true,
  bannerFlag: false,
  profileLogoFlag: false,
  notificationData: [],
};

const notificationHeader = (state = initialState, action) => {
  switch (action.type) {
    case actionType.SET_NOTIFICATION_MESSAGE: {
      return {
        ...state,
        headerName: action.value,
      };
    }
    case actionType.SET_NOTIFICATION_STATUS_COLOR: {
      return {
        ...state,
        statusColor: action.value,
      };
    }
    case actionType.SET_BUSINESS_INFO_STATUS: {
      return {
        ...state,
        businessInfoStatus: action.value,
      };
    }
    case actionType.SET_EXPAND_MIN_SIDEMENU: {
      return {
        ...state,
        sideMenuFlag: action.value,
      };
    }
    case actionType.SET_BANNER_LOGO: {
      return {
        ...state,
        bannerFlag: action.value,
      };
    }
    case actionType.SET_PROFILE_LOGO: {
      return {
        ...state,
        profileLogoFlag: action.value,
      };
    }
    case actionType.SET_NOTIFICATION_DATA: {
      const notifications = [...state.notificationData, action.value]
      const distinctNotifications = [...new Map(notifications.map(item => [item['notification_uuid'], item])).values()];
      return {
        ...state,
        notificationData:
          action.value.length === 0
            ? []
            : [...distinctNotifications],
      };
    }
    case actionType.UNSET_NOTIFICATION_DATA: {
      const notification = action?.value
      notification['is_read'] = true    
      // const unreadNotifications = state.notificationData.filter((unreadNotification, index) => {
      //   if (unreadNotification?.notification_uuid != action?.value?.notification_uuid){
      //     return unreadNotification
      //   }
      // })
      return {
        ...state,
        notificationData:
          action.value.length === 0
            ? []
            : [...state.notificationData, notification],
      };
    }

    case actionType.UNSET_ALL_NOTIFICATION_DATA: {      
      return {
        ...state,
        notificationData: [],
      };
    }

    default:
      return state;
  }
};
export default notificationHeader;
