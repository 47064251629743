import { setNotificationData, unsetAllNotificationData, unsetNotificationData } from '../store/actions/topNotificationActions';
export const customNotification = (dispatch, data) => {
  dispatch(setNotificationData(data));
};

export const unsetCustomNotification = (dispatch, data) => {
  dispatch(unsetNotificationData(data));
};

export const unsetAllCustomNotification = (dispatch) => {
  dispatch(unsetAllNotificationData());
};
