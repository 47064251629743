import React, { useState, useEffect } from 'react';
import { Flex, Box, Text, useToast, Spinner, Textarea, Button, Drawer, DrawerContent, DrawerCloseButton} from '@chakra-ui/react';
import { fetchCandidateFeedback, saveCandidateFeedback} from '../../../../services/jobTemplateService';
import { getEmailId } from '../../../../utils/localStorageIndex';
import { isObjectEmpty } from '../../../../utils/common';


const CandidateFeedback = (props) => {
    const emailId = getEmailId();
    const {candidate, job, adminSettings=false} = props;
    const toast = useToast();
    const [candidateFeedback, setCandidateFeedback] = useState([]);        
    const [userFeedback, setUserFeedback] = useState('');
    const [candidateRejected, setCandidateRejected] = useState({})
    const [isFeedbackLoading, setIsFeedbackLoading] = useState(false)
    const toastFunction = (message, status) => {
        return toast({
            title: message,
            status: status,
            isClosable: true,
            duration: 3000,
        });
    };

    useEffect(() => {
        if (!isObjectEmpty(candidate)){
            getCandidateFeedback('all');
        }
    }, [candidate])

    const getCandidateFeedback = (feedbackRange) => {
        setIsFeedbackLoading(true);
        let obj = {
            email: emailId,
            candidate_uuid: candidate.candidate_uuid,
            job_uuid: job.job_uuid,
            range: feedbackRange
        };
        fetchCandidateFeedback(obj)
        .then(res => {
            setIsFeedbackLoading(false);
            if (res.result && res?.data?.length > 0) {
                setCandidateFeedback(res?.data[0]?.feedback);
                setCandidateRejected(res?.data[0]?.rejected)
            }else{
                toast({
                    title: res?.message,
                    description: res?.error,
                    status: 'error',
                    duration: 3000,
                });
            }
        })
        .catch(err => {
            console.log(err);
            setIsFeedbackLoading(false);
            toast({
                title: "Unable to add feedback",
                description: err.toString(),
                status: 'error',
                duration: 3000,
            });
        });
    };

    const CandidateRejected = () => {
        if (!isObjectEmpty(candidateRejected)){
            return <>
                <Flex direction={'column'} 
                    color={'gray.600'} mb={4} px={2} pb={3}
                    borderBottomWidth={'0.1px'} borderBottomColor={'gray.200'}
                >
                    <Text as={'b'}>Rejected</Text>
                    <Text>Reason: {candidateRejected?.denial_details ? candidateRejected?.denial_details : "N/A"}</Text>
                    <Text fontSize={'xs'} align={'end'}>
                        {
                            candidateRejected?.datetime_created && 
                            
                            new Date(candidateRejected?.datetime_created).toLocaleDateString('en-us', 
                            { weekday:"long", year:"numeric", month:"short", 
                            day:"numeric", hour:'2-digit', 'minute': '2-digit'})
                        }
                    </Text>
                </Flex>
            </>
        }
    }

    const FeedbackAuthor = ({feedback}) => {
        if (adminSettings){
            return feedback.source_name
        }
        if (![1, 2].includes(feedback.user_role)){
            return feedback.source_name
        }
        if ([1, 2].includes(feedback.user_role)){
            return 'Internal Recruiter'
        }
    }

    const renderCandidateFeedback = () => {
        if (candidateFeedback.length > 0 || !isObjectEmpty(candidateRejected)){
            return(
                <Box
                overflowY="auto"
                maxH={'50vh'}
                css={{
                '&::-webkit-scrollbar': {
                    width: '8px',
                },
                '&::-webkit-scrollbar-track': {
                    width: '12px',
                },
                '&::-webkit-scrollbar-thumb': {
                    borderRadius: '100px',
                    background: 'gray.500',
                    border: '6px solid rgba(0, 0, 0, 0.2)'
                },
                }}
                >
                <CandidateRejected />
                {candidateFeedback.map((item, key) => {
                    return(
                        <Flex direction={'column'} 
                            color={'gray.600'} mb={4} px={2} pb={3}
                            borderBottomWidth={'0.1px'} borderBottomColor={'gray.200'}
                        >
                            <Text as={'b'}>{<FeedbackAuthor feedback={item} />}</Text>
                            <Text><pre style={{'fontFamily': 'inherit', 'textWrap': 'wrap'}} >{item.text}</pre></Text>
                            <Text fontSize={'xs'} align={'end'}>
                                {new Date(item.time_at).toLocaleDateString('en-us', 
                                    { weekday:"long", year:"numeric", month:"short", 
                                    day:"numeric", hour:'2-digit', 'minute': '2-digit'})}
                            </Text>
                        </Flex>
                    )
                })}
                </Box>
            )
        } return(
            <Flex w={'100%'} direction={'column'} p={2}>
                <Text fontSize={'16px'}>No feedback is available for this candidate.</Text>
            </Flex>
        )
    }

    const CandidateFeedbackAction = () => {
        if (userFeedback.trim().length > 0){
            setIsFeedbackLoading(true);
            let obj = {
                email: emailId,
                candidate_uuid: candidate.candidate_uuid,
                job_uuid: job.job_uuid,
                feedback_text: userFeedback
            };
            saveCandidateFeedback(obj)
            .then(res => {
                setIsFeedbackLoading(false);
                if (res.result && res?.data?.length > 0) {
                    if (res?.data[0]?.saved){
                        toastFunction('Your feedback is submitted successfully.', 'success');
                        setUserFeedback('');
                        getCandidateFeedback('top');
                        props.onFeedbackPanelClose()                        
                    } else {
                        toastFunction('Unable to submit your feedback', 'error');
                    }
                } else {
                    toastFunction('Unable to submit your feedback...', 'error');
                }
            })
            .catch(err => {
                console.log(err);
                setIsFeedbackLoading(false);
                toast({
                    title: 'Unable to submit your feedback',
                    desciption: err.toString(),
                    status: 'error',
                    duration: 3000,
                });
            });
        } else {
            toastFunction('Please provide some feedback', 'error');
        }
    };

    const CandidateFeedback = () => {
        return <>
            <Flex w={'100%'} direction={'column'}>
                {isFeedbackLoading ? (
                    <>
                    <Flex alignItems="center" direction={'column'} justifyContent="center" mt="10" h={'50vh'}>
                        <Text fontSize={'lg'}>Loading comments...</Text>
                        <Spinner size="xl" />
                    </Flex>
                    </>
                ) : (
                    renderCandidateFeedback()
                )}
            </Flex>
        </>
    }

    return (
        <Box>                
            <Flex w={'100%'} direction={'column'}>
                <CandidateFeedback />
            </Flex>

            {/* feedback form */}
            <Drawer
                size={'lg'} isOpen={props.isFeedbackPanelOpen}
                placement="right" onClose={props.onFeedbackPanelClose}
            >
                <DrawerContent pl={5} >
                <DrawerCloseButton />
                <Flex direction={'column'} padding={10}>
                    <Text as={'b'} mb={4}>Feedback for {candidate?.first_name} {candidate?.last_name}</Text>
                    <Textarea
                        p="5px" rows={7} placeholder="Write your feedback"
                        size="xl" resize={'none'} borderRadius="0px"
                        px="10px" value={userFeedback}
                        onChange={e => {
                            setUserFeedback(e.target.value);
                        }}
                        name="user_feedback"  _placeholder={{ color: 'gray.500' }}
                    />
                    
                    <Button
                        width={'50%'} alignSelf={'center'}
                        onClick={() => CandidateFeedbackAction()}
                        mt={2} colorScheme='blue' size={'sm'}>
                        Submit
                    </Button>
                </Flex>
                </DrawerContent>
            </Drawer>

        </Box>
    );
}

export default CandidateFeedback;
