import { 
    Flex, Text, Button, List, ListItem, ListIcon
} from "@chakra-ui/react";
import { MdCheckCircle } from "react-icons/md";
import { toTitleCase } from "../../../../../utils/common";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setJobUUId } from '../../../../../store/actions/topNotificationActions';
import { Editor } from "@tinymce/tinymce-react";


const DraftJob = (props) => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    
    const job = props.job;

    const navigateToEditJob = () => {
        dispatch(setJobUUId(job.job_uuid));
        navigate('/business/editJobs', {
            state: {
                job_uuid: job.job_uuid,
            },
        });
    }

    return (
        <Flex w={'100%'} direction={'column'}>
            <Flex justifyContent={'space-between'} mb={'10px'}>
                <Text 
                    fontSize={'22px'} 
                    fontWeight={'800'}
                    mb={'10px'}
                    color={'gray.500'}
                >
                    {job.job_title}
                </Text>
                <Button mr={'10px'} height={'25px'} fontSize={'12px'} 
                        onClick={navigateToEditJob}
                        bg={'gray.200'}
                        >
                        Edit Job
                </Button>
            </Flex>
            <Flex p={'10px'} fontSize={'16px'} mr={'5px'} borderWidth={'1px'} borderRadius={'4px'}>
                <Flex width={'30%'} direction={'column'}>
                    <Text mb={'10px'}><b>Location: </b>{job.job_location_extended && toTitleCase(job.job_location_extended.replaceAll('_', ' '))}</Text>
                    <Text mb={'10px'}><b>Employment Type: </b>{job.job_employment_type}</Text>
                    <Text mb={'10px'}><b>Education: </b>{job.highest_education}</Text>
                    <Text mb={'10px'}><b>Experience Level: </b>{job.experience_level}</Text>
                    <Text><b>Benefits: </b></Text>
                    <List  mb={'20px'}>
                        {
                           job.benefits && JSON.parse(job.benefits).map( benefit => {
                            return (
                                <ListItem>
                                    <ListIcon as={MdCheckCircle} color='green.500' />
                                    {benefit.name}
                                </ListItem>
                                )
                           })
                        }                        
                    </List>
                    <Text><b>Supplemental Benefits: </b>
                    <List  mb={'10px'}>
                        {
                           job.supplemental_benefits && JSON.parse(job.supplemental_benefits).map( benefit => {
                            return (
                                <ListItem>
                                    <ListIcon as={MdCheckCircle} color='green.500' />
                                    {benefit.name}
                                </ListItem>
                                )
                           })
                        }                        
                    </List>
                    </Text>
                    
                </Flex>
                <Flex width={'70%'} direction={'column'} ml={'20px'}>
                    <Text mb={'5px'}><b>Description: </b></Text>
                    <Editor
                        tinymceScriptSrc={'/tinymce/tinymce.min.js'}
                        value={job.description || ""}
                        disabled={true}
                        init={{
                            branding: false,
                            statusbar: false,
                            toolbar: false,
                            menubar: false,
                            plugins: 'autoresize',
                            content_style: "body {margin: 0; padding: 0}"
                        }}
                />

                </Flex>
            </Flex> 
        </Flex>
    )
}

export default DraftJob