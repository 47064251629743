import { RiGraduationCapFill } from "react-icons/ri"
import { isObjectEmpty } from "../../utils/common"
import { Flex, Icon, Text, Tooltip } from "@chakra-ui/react"

export default function CandidateHighestEducation (props) {
    const {candidate, rawData, fontWeight, fontSize, fontColor, mTop, mLeft, mRight, mBottom} = props
    let educationText = ''
    if (candidate?.highest_education){
        educationText = candidate?.highest_education
    }

    else if (rawData?.education?.length > 0 && rawData?.education[0]?.school?.name && rawData?.education[0]?.degrees?.length > 0){
        educationText = `${rawData?.education[0]?.degrees[0]} at ${rawData?.education[0]?.school?.name}`
    }

    else if (rawData?.education?.length > 0 && rawData?.education[0]?.degrees?.length > 0) {
        educationText = rawData?.education[0]?.degrees[0]
    }

    else if (rawData?.education?.length > 0 && rawData?.education[0]?.school?.name) {
        educationText = rawData?.education[0]?.school?.name
    }

    else {
        educationText = "N/A"
    }
    return (
        <Flex ml={mLeft ? mLeft : null} mr={mRight ? mRight : null}
                mt={mTop ? mTop : null} mb={mBottom ? mBottom : null}
                alignItems={'center'}
            >
                <Tooltip label={'Highest Education'} fontSize='md'>
                    <Flex fontSize={fontSize ? fontSize : null}
                            fontWeight={fontWeight ? fontWeight : null}
                            color={fontColor ? fontColor : null} alignItems={'center'}
                    >
                        <Icon mr={2} fontWeight={'bold'} as={RiGraduationCapFill} />
                        <Text>{educationText}</Text>
                    </Flex>
                </Tooltip>
        </Flex>        
    )
  }
