import React, { useState } from 'react';
import { Button, Text, Flex, Drawer, DrawerContent, DrawerHeader, DrawerCloseButton, useToast, useColorModeValue, Textarea,} from '@chakra-ui/react';
import { getEmailId } from '../../../../utils/localStorageIndex';
import { submitCandidateProfileDetails } from '../../../../services/recruiterServices';


export default function EditCandidateSummary(props) {
  const {candidate, job} = props;
  const toast = useToast()
  const [isFormSubmitting, setIsFormSubmitting] = useState(false)
  const brandColor = useColorModeValue('brand.500', 'white');
  const [form, setForm] = useState(() => {
    return {
      job_uuid: job?.job_uuid,
      email: getEmailId(),
      candidate_uuid: candidate?.candidate_uuid,
      
      summary: candidate?.summary,
      // required by update API
      first_name: candidate?.first_name,
      last_name: candidate?.last_name,      
    }
  })

  const onSubmitForm = () => {    
    setIsFormSubmitting(true);
    submitCandidateProfileDetails(form)
    .then( res => {
        if (res.result){
          props.getAllCandidatesForJob()
          toast({
            title: 'Candidate summary updated succesfully.',
            status: 'success', 
            isClosable: true, 
            duration: 3000
          });
        } else {
          toast({
            title: 'Unable to update candidate summary.',
            description: res.message,
            status: 'error', 
            isClosable: true, 
            duration: 3000
          });
        }
        setIsFormSubmitting(false);        
    })
    .catch( err =>  {
        setIsFormSubmitting(false);
        toast({
            title: 'Unable to update candidate summary.', 
            description: err.toString(),
            status: 'error', 
            isClosable: true, 
            duration: 3000
        });
    })
  }

  return (
    <Drawer size={'lg'} isOpen={props.isEditSummaryPanelOpen} onClose={props.onEditSummaryPanelClose}>
      <DrawerContent pl={5} >
        <DrawerHeader>Edit Summary for {candidate?.first_name} {candidate?.last_name}</DrawerHeader>
        <DrawerCloseButton />
          <Flex pl={'30px'} direction={'column'} mt={5} p={5}>
              <Flex direction={'column'}>
                <Flex>
                  <Text color={'red'} mr={1}>*</Text>
                  <Text as='b' mb={1} fontSize={'16px'}>Summary</Text>
                </Flex>
                <Textarea value={form.summary} onChange={(e) => setForm({...form, summary: e.target.value})} rows={20}/>
              </Flex>
            <Button onClick={onSubmitForm} width={'200px'} mt={10} variant="brand" _hover={{'bg': brandColor}}
                fontWeight="500" isLoading={isFormSubmitting} loadingText={'Updating...'}
            >
              Update
            </Button>
          </Flex>
      </DrawerContent>
    </Drawer>    
  );
}
