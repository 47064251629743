import React, { useState } from 'react';
import { Flex, Text, Tooltip, Icon, useDisclosure} from '@chakra-ui/react';
import { BsFileEarmarkPdf } from 'react-icons/bs';
import DocumentViewer from '../../../common/DocumentViewer';
import { toTitleCase } from '../../../../utils/common'


const CandidateScreeningQuestions = (props) => {
    const candidate = props.candidate
    const screeningQuestions = JSON.parse(candidate?.job_application?.custom_fields || "[]")
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [selectedDocument, setSelectedDocument] = useState(null)
    const [documentTitle, setDocumentTitle] = useState('')
    
    const renderCandidateQuestionAnswer = (answer) => {
        if (answer?.type == 'subjective'){
            return <Text mt={1} fontWeight={'normal'}>{answer?.value ? answer?.value : "N/A"}</Text>
        }
   
        if (answer?.type == 'boolean' && answer?.value){
            const disqualifyFilters = JSON.parse(answer?.question?.question_disqualify_filters || '[]')
            return <Flex direction={'column'}>
                        <Text mt={1} fontWeight={'normal'}>Candidate Answer: {answer?.value == 'true' ? 'Yes' : 'No'}</Text>
                        <Text mt={1} hidden={disqualifyFilters?.length == 0}>Ideal Answer: {toTitleCase(disqualifyFilters?.join(', '))}</Text>
                    </Flex>
        }
 
        if (answer?.type == 'multiplechoice'){
            const disqualifyFilters = JSON.parse(answer?.question?.question_disqualify_filters || '[]')
            return <Flex direction={'column'}>
                    <Text mt={1} fontWeight={'normal'}>Candidate Answer: {answer?.value ? answer?.value : "N/A"}</Text>
                    <Text mt={1} hidden={disqualifyFilters?.length == 0}>Ideal Answer: {toTitleCase(disqualifyFilters?.join(', '))}</Text>
                </Flex>
        }

        if (answer?.type == 'number'){
            const disqualifyFilters = JSON.parse(answer?.question?.question_disqualify_filters || '{}')
            return <Flex direction={'column'}>
                    <Text mt={1} fontWeight={'normal'}>Candidate Answer: {answer?.value ? answer?.value : "N/A"}</Text>
                    <Text mt={2}>Ideal Answer: </Text>
                    <Flex hidden={disqualifyFilters?.length == 0} direction={'column'}>
                        {Object.keys(disqualifyFilters)?.map(key => {
                            return <Flex mt={2} hidden={!disqualifyFilters[key]?.isChecked}>
                                        <Text mr={2}>{toTitleCase(key)}</Text>
                                        <Text>{toTitleCase(disqualifyFilters[key]?.value)}</Text>
                                    </Flex>
                        })}
                    </Flex>
                </Flex>
        }
    
        if (answer?.type == 'file' && answer?.value){
          return (
            <Flex _hover={{'cursor': 'pointer'}} width={'30px'}
            onClick={() => {
                onOpen()
                setDocumentTitle(answer?.name)
                setSelectedDocument(answer?.value)
            }}
            >
                <Tooltip label={'View attached file'} fontSize='md'>
                    <span><Icon as={BsFileEarmarkPdf} fontSize={'18px'} /></span>
                </Tooltip>
            </Flex>
          )
        }
      }

    return (               
        <Flex w={'100%'} direction={'column'} fontSize={'16px'}>
            
            <Text textAlign={'center'} hidden={screeningQuestions?.length > 0}>
                No screening questions found for this candidate. <br/>
                Any question answered by candidate through job apply page will be listed here.
            </Text>
            {screeningQuestions.map(answer => {
                return (
                    <Flex direction={'column'} mt={8}>
                        <Flex>
                            <Text as='b'>{answer.name}</Text>
                            <Text ml={2} hidden={!answer?.question?.question_is_mandatory}>[Must-have question]</Text>
                        </Flex>
                        {renderCandidateQuestionAnswer(answer)}
                    </Flex>
                )
                })
            }

            <DocumentViewer
                isOpen={isOpen}
                onClose={onClose}
                document={selectedDocument}
                title={documentTitle}
            />
        </Flex>
    );
}

export default CandidateScreeningQuestions;
