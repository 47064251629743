import React, { useEffect, useState } from 'react';
import {
  Box,
  Text,
  Button,
  Flex,
  HStack,
  Icon,
  Spinner,
  IconButton,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
} from '@chakra-ui/react';
import AddBusinessForm from '../Business/Information/AddBusiness/AddBusinessForm/AddBusinessForm';
import { BsGrid1X2Fill } from 'react-icons/bs';
import { AiOutlineCodeSandbox } from 'react-icons/ai';
import {
  defaultThemeColor,
  themeFontSizeSmallHeading,
  themeFontSizeXlHeading,
} from '../../constants/index';
import { getEmailId, getGroupId } from '../../utils/localStorageIndex';
import { AiOutlineUserAdd } from 'react-icons/ai';
import { startInformation } from '../../services/businessServices';

function Home() {
  const groupId = getGroupId();
  const { isOpen, onOpen } = useDisclosure();
  const [loading, isLoading] = useState(false);
  const [businessInfo, setBusinessInfo] = useState({});
  const [businessInfoStatus, setBusinessInfoStatus] = useState(false);

  useEffect(() => {
    // Avkash - Business User Details are needed for Business Admin only
    if (groupId === '300' || groupId === '400') {
      businessUserDetails();
    }
  }, []);

  const businessUserDetails = () => {
    let obj = {
      email: getEmailId(),
    };
    isLoading(true);
    startInformation(obj)
      .then(res => {
        isLoading(false);
        if (res?.result) {
          if (res?.data?.Business_info_added) {
            setBusinessInfoStatus(false);
            setBusinessInfo(res?.data);
          } else {
            setBusinessInfoStatus(true);
          }
        }
      })
      .catch(err => {
        isLoading(false);
        console.log(err);
      });
  };

  const renderBusinessInfo = () => {
    return (
      <Flex alignItems="center" flexWrap={'wrap'} p="5px" my="3">
        <Flex
          w="30%"
          mr="2%"
          mb="20px"
          alignItems="center"
          borderWidth="1px"
          borderRadius="md"
          cursor="pointer"
          color="white"
          _hover={{
            bg: '#494552',
            borderColor: '#fff',
            borderRightColor: '#fff',
          }}
          className="gradient-border"
        >
          <HStack
            w="75%"
            h="60px"
            borderRight={'1px'}
            borderRightColor="#3780c2"
            p={2}
          >
            <Icon
              w={5}
              h={5}
              as={AiOutlineUserAdd}
              mr="2"
              color={defaultThemeColor}
            ></Icon>
            <Box>
              <Text fontSize={themeFontSizeXlHeading}>Plan Selected</Text>
              <Text color="#d7d7d7" fontSize={themeFontSizeSmallHeading}>
                Selected
              </Text>
            </Box>
          </HStack>
          <Flex w="25%" alignItems="center" justifyContent="center">
          </Flex>
        </Flex>
        <Flex
          w="30%"
          mr="2%"
          mb="20px"
          alignItems="center"
          className="gradient-border"
          cursor="pointer"
          color="white"
          _hover={{
            bg: '#494552',
            borderColor: '#fff',
            borderRightColor: '#fff',
          }}
        >
          <HStack
            w="75%"
            h="60px"
            borderRight={'1px'}
            borderRightColor="#3780c2"
            p={2}
          >
            <Icon
              w={5}
              h={5}
              as={AiOutlineUserAdd}
              mr="2"
              color={defaultThemeColor}
            ></Icon>
            <Box>
              <Text fontSize={themeFontSizeXlHeading}>Job Created</Text>
              <Text color="#d7d7d7" fontSize={themeFontSizeSmallHeading}>
                Jobs
              </Text>
            </Box>
          </HStack>
          <Flex w="25%" alignItems="center" justifyContent="center">
            <Text textAlign="center" fontWeight="bold" fontSize="18px" mr="2">
              {businessInfo?.Job_created?.length ? 'Yes' : 'No'}
            </Text>
          </Flex>
        </Flex>
        <Flex
          w="30%"
          mr="2%"
          mb="20px"
          alignItems="center"
          className="gradient-border"
          cursor="pointer"
          color="white"
          _hover={{
            bg: '#494552',
            borderColor: '#fff',
            borderRightColor: '#fff',
          }}
        >
          <HStack
            w="75%"
            h="60px"
            borderRight={'1px'}
            borderRightColor="#3780c2"
            p={2}
          >
            <Icon
              w={5}
              h={5}
              as={AiOutlineUserAdd}
              mr="2"
              color={defaultThemeColor}
            ></Icon>
            <Box>
              <Text fontSize={themeFontSizeXlHeading}>Total Slots</Text>
              <Text color="#d7d7d7" fontSize={themeFontSizeSmallHeading}>
                Slots
              </Text>
            </Box>
          </HStack>
          <Flex w="25%" alignItems="center" justifyContent="center">
            <Text textAlign="center" fontWeight="bold" fontSize="18px" mr="2">
              {businessInfo?.total_slots ? businessInfo?.total_slots : '-'}
            </Text>
          </Flex>
        </Flex>
        <Flex
          w="30%"
          mr="2%"
          alignItems="center"
          className="gradient-border"
          cursor="pointer"
          color="white"
          _hover={{
            bg: '#494552',
            borderColor: '#fff',
            borderRightColor: '#fff',
          }}
        >
          <HStack
            w="75%"
            h="60px"
            borderRight={'1px'}
            borderRightColor="#3780c2"
            p={2}
          >
            <Icon
              w={5}
              h={5}
              as={AiOutlineUserAdd}
              mr="2"
              color={defaultThemeColor}
            ></Icon>
            <Box>
              <Text fontSize={themeFontSizeXlHeading}>Total Jobs</Text>
              <Text color="#d7d7d7" fontSize={themeFontSizeSmallHeading}>
                Jobs
              </Text>
            </Box>
          </HStack>
          <Flex w="25%" alignItems="center" justifyContent="center">
            <Text textAlign="center" fontWeight="bold" fontSize="18px" mr="2">
              {businessInfo?.total_jobs}
            </Text>
          </Flex>
        </Flex>
        <Flex
          w="30%"
          mr="2%"
          alignItems="center"
          className="gradient-border"
          cursor="pointer"
          color="white"
          _hover={{
            bg: '#494552',
            borderColor: '#fff',
            borderRightColor: '#fff',
          }}
        >
          <HStack
            w="75%"
            h="60px"
            borderRight={'1px'}
            borderRightColor="#3780c2"
            p={2}
          >
            <Icon
              w={5}
              h={5}
              as={AiOutlineUserAdd}
              mr="2"
              color={defaultThemeColor}
            ></Icon>
            <Box>
              <Text fontSize={themeFontSizeXlHeading}>Active Jobs</Text>
              <Text color="#d7d7d7" fontSize={themeFontSizeSmallHeading}>
                Jobs
              </Text>
            </Box>
          </HStack>
          <Flex w="25%" alignItems="center" justifyContent="center">
            <Text textAlign="center" fontWeight="bold" fontSize="18px" mr="2">
              {businessInfo?.active_job}
            </Text>
          </Flex>
        </Flex>
      </Flex>
    );
  };

  return (
    <>
      <Box>
        <Flex
          p="10px"
          color="white"
          borderBottomWidth="0.5px"
          borderColor="#494552"
          justifyContent="space-between"
          alignItems="center"
        >
          <Flex alignItems="center">
            <Icon
              w={8}
              h={8}
              color={'#ee7733'}
              as={AiOutlineCodeSandbox}
              mr="10px"
            ></Icon>
            <Text fontSize={themeFontSizeXlHeading}>Your Dashboard</Text>
            {/* <Text
              ml="10px"
              fontSize={'10px'}
              p="3px"
              bg="#241a52"
              borderRadius={'5px'}
              color="#947ff5"
            >
              Business User | Admin | Recruiter
            </Text>
            <Text
              ml="10px"
              fontSize={'10px'}
              p="3px"
              bg="#183828"
              color="#36ad73"
              borderRadius={'5px'}
            >
              FREE TRIAL | Power User
            </Text> */}
          </Flex>
          <Flex alignItems="center">
            {/* <Text mr="10px" color="#d7d7d7">
              Last Login: 12:29:47PM
            </Text>
            <Button
              color="#d7d7d7"
              variant="outline"
              borderRadius="0px"
              borderColor="#6b6b6b"
              fontSize={themeFontSizeSmallHeading}
              size="xs"
              p="10px"
              mr="10px"
            >
              Contact US
            </Button>
            <IconButton
              color="#d7d7d7"
              variant="outline"
              borderRadius="0px"
              borderColor="#6b6b6b"
              fontSize={themeFontSizeSmallHeading}
              size="xs"
              p="10px"
              icon={<BsGrid1X2Fill />}
            /> */}
          </Flex>
        </Flex>
        {groupId === '300' ? (
          loading ? (
            <Flex h="50vh" alignItems="center" justifyContent="center" mt="10">
              <Spinner color="#fff" size="xl" />
            </Flex>
          ) : businessInfoStatus ? (
            <Modal
              size="xl"
              closeOnOverlayClick={false}
              isOpen={true}
              border={'1px'}
              borderColor={'gray.200'}
            >
              <ModalOverlay />
              <ModalContent
                bg="#0b090c"
                borderWidth="0.5px"
                borderColor="#494552"
                p={2}
              >
                <ModalHeader color="#fff" p={5}>
                  Add Business Details
                  <Text fontSize={'xs'}>
                    You must provide business informatiom to continue using
                    TalentStrap platform
                  </Text>
                </ModalHeader>
                <ModalBody bg="#0b090c" color="#fff" p="0px">
                  <AddBusinessForm renderUI="true" />
                </ModalBody>
              </ModalContent>
            </Modal>
          ) : Object.keys(businessInfo).length ? (
            <Box padding="25px">
              <Text color="#fff" fontSize={'24px'}>
                Business Details
              </Text>
              <Box> {renderBusinessInfo()}</Box>
            </Box>
          ) : (
            <Text fontSize={20} color="white" p="4">
              No Data Found
            </Text>
          )
        ) : null}
        {groupId === '400' ? (
          Object.keys(businessInfo).length ? (
            <Box padding="25px">
              <Text color="#fff" fontSize={'24px'}>
                Business Details
              </Text>
              <Box> {renderBusinessInfo()}</Box>
            </Box>
          ) : (
            <Text fontSize={20} color="white" p="4">
              No Data Found
            </Text>
          )
        ) : null}
        {groupId === '100' || groupId === '200' ? (
          <Box padding="25px">
            <Text color="#fff" fontSize={'24px'}>
              Talentstrap Admins and Recruiter Page
            </Text>
          </Box>
        ) : null}
      </Box>
    </>
  );
}

export default Home;
