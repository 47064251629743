import React from 'react';
import {
  Box,
  Text,
  Button,
  SimpleGrid,
  Flex,
  HStack,
  Icon,
  Spinner,
  Badge,
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  CloseButton,
  IconButton,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  useToast,
  ModalBody,
  Card,
  useColorModeValue,
} from '@chakra-ui/react';
import JobStepper from './jobStepper/JobStepper';
import JobCompletion from './jobStepper/JobCompletion';

function JobWizard() {
  return (
    <Flex direction="column" pt={{ sm: '125px', lg: '100px' }}>
      <Flex justifyContent="space-between" p="3">
        <Box w="100%" overflow="hidden">
          <JobStepper />
        </Box>
        {/* <Box w="20%">
          <JobCompletion />
        </Box> */}
      </Flex>
    </Flex>
  );
}

export default JobWizard;
