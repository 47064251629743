import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  VStack,
  Text,
} from '@chakra-ui/react';

const MailChimp = () => {
  return (
    <Box p={4} bg="white" w={600}>
      <VStack
        as="form"
        action="https://talentstrap.us8.list-manage.com/subscribe/post?u=2d778907dac2516831eb6e6be&amp;id=ab608fdf53&amp;f_id=003b72e0f0"
        method="post"
        id="mc-embedded-subscribe-form"
        name="mc-embedded-subscribe-form"
        spacing={4}
        align="left"
        target="_blank"
      >
        <FormControl>
          <FormLabel>Email Address</FormLabel>
          <Input
            type="email"
            name="EMAIL"
            required
            placeholder="Your email address"
          />
          <Text id="mce-EMAIL-HELPERTEXT" className="helper_text"></Text>
        </FormControl>
        <FormControl>
          <FormLabel>First Name</FormLabel>
          <Input
            type="text"
            name="FNAME"
            required
            placeholder="Your first name"
          />
        </FormControl>
        <FormControl>
          <FormLabel>Last Name</FormLabel>
          <Input
            type="text"
            name="LNAME"
            required
            placeholder="Your last name"
          />
        </FormControl>
        <Box id="mce-responses" className="clear foot">
          <Box
            className="response"
            id="mce-error-response"
            display="none"
          ></Box>
          <Box
            className="response"
            id="mce-success-response"
            display="none"
          ></Box>
        </Box>
        <Box
          aria-hidden="true"
          style={{ position: 'absolute', left: '-5000px' }}
        >
          <Input type="text" />
        </Box>
        <Button type="submit" name="subscribe">
          Subscribe
        </Button>
        <Box className="brandingLogo" margin="0px auto">
          <a
            href="http://eepurl.com/iwqgT6"
            title="Mailchimp - email marketing made easy and fun"
          >
            <img
              src="https://eep.io/mc-cdn-images/template_images/branding_logo_text_dark_dtp.svg"
              alt="referral badge"
            />
          </a>
        </Box>
      </VStack>
    </Box>
  );
};

export default MailChimp;
