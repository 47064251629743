import React from 'react';
import { Box } from '@chakra-ui/react';
import Header from '../../constants/Header';
import Footer from '../../constants/Footer';

function CookiePolicy() {
  return (
    <Box>
      <Header />
      <Box maxW="800px" mx="auto" my={8} p={4}>
        CookiePolicy
      </Box>
      <Footer />
    </Box>
  );
}

export default CookiePolicy;
