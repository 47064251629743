import { Flex, Icon, Tooltip, Text } from "@chakra-ui/react"
import { formatCurrency } from "../../utils/common"
import { BiMoney } from "react-icons/bi"

export default function CandidateSalaryExpectations(props){
    
    /* 
    
    needs a salary object from a candidate in this format.
    {"pay_type":"Range", "rate_type":"per hour", "min":"10", "max":"15", "amount":"15", "is_negotiable":true}
    
    */ 
    const {fontSize, fontWeight, fontColor, mTop} = props;
    let salaryText = ''
    const salary = props?.salary
    if (salary?.pay_type?.toLowerCase() == 'range' && salary?.min && salary?.max){
        salaryText = `$${formatCurrency(salary?.min)} - $${formatCurrency(salary?.max)} ${salary?.rate_type}`
    }
    else if (salary?.pay_type?.toLowerCase() == 'range' && salary?.min){
        salaryText = `Minimum $${formatCurrency(salary.min)} ${salary?.rate_type}`
    }
    else if (salary?.pay_type?.toLowerCase() == 'range' && salary?.max){
        salaryText = `Maximum $${formatCurrency(salary.max)} ${salary?.rate_type}`
    }
    if (salary?.pay_type?.toLowerCase() == 'starting amount' && salary?.min){
        salaryText = `Minimum $${formatCurrency(salary?.min)} ${salary?.rate_type}`
    }
    if (salary?.pay_type?.toLowerCase() == 'maximum amount' && salary?.max){
        salaryText = `Maximum $${formatCurrency(salary.max)} ${salary?.rate_type}`
    }
    if (salary?.pay_type?.toLowerCase() == 'exact amount' && salary?.amount){
        salaryText = `$${formatCurrency(salary?.amount)} ${salary?.rate_type}`
    }
    return <>
        <Flex mt={mTop ? mTop : null} alignItems={'center'}>
            <Tooltip label={'Salary Expectations'} fontSize='md'>
                <Flex alignItems={'center'} color={fontColor ? fontColor : null} fontSize={fontSize ? fontSize : null} fontWeight={fontWeight ? fontWeight : 'normal'}>
                    <Icon mr={2} as={BiMoney} />
                    <Text>{ salaryText ? salaryText : "N/A"} {salary?.is_negotiable ? '(Negotiable)' : null}</Text>
                </Flex>
            </Tooltip>
        </Flex>      
    </>
}
