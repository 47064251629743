import { useLocation } from 'react-router-dom';
import { useState, useEffect } from 'react';
import './assets/css/App.css';
import { Provider } from 'react-redux';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import store from './store/index';
import './style.css';
import { Elements } from '@stripe/react-stripe-js';
import { ChakraProvider, extendTheme } from '@chakra-ui/react';
import initialTheme from './theme/theme'; //  { themeGreen }
import { loadStripe } from '@stripe/stripe-js';

// This layout is related to the previous main TS design
import RouteComponent from './routes/index';

// This layout is related to the new Horizon UI based design
import MainRouteComponent from './routes/mainRoutes';

// This layout is called from tslayout (New Layout created for TalentStrap)
import AdminLayout from './tslayouts/admin/index';
import BusinessLayout from './tslayouts/business/index';
import BusinessTeamLayout from './tslayouts/businessteam/index';

// Nylas Provider
import { NylasProvider } from '@nylas/nylas-react';
import TsChangePassword from './views/auth/changePassword/TsChangePassword';
import RegisterBusinessUser from './views/auth/registerBusinessUser/RegisterBusinessUser';
// import RegisterBusinessUser from './components/Register/RegisterBusinessUser';
import TsVerficationMail from './views/auth/verification/TsVerificationMail';
import ScheduleDemo from './components/ScheduleDemo/ScheduleDemo';
import TermsCondition from './components/Terms&Condition/TermsCondition';
import PrivacyPolicy from './components/PrivacyPolicy/PrivacyPolicy';
import CookiePolicy from './components/CookiePolicy/CookiePolicy';
import TsSignIn from './views/auth/signIn/TsSignIn';
import TsForgotPassword from './views/auth/forgotPassword/tsForgotPassword';
import OthersError from './views/admin/main/others/404';

import BusinessProtectedLayout from './routes/BusinessProtectedLayout';
import BusinessTeamProtectedLayout from './routes/BusinessTeamProtectedLayout';
import { getGroupId } from './utils/localStorageIndex';
import { userRole } from './constants/index';
import JobApply from './tscomponents/common/JobApply';


// Chakra imports
export default function Main() {

  const [currentTheme, setCurrentTheme] = useState(initialTheme);
  const publishKey = process.env.REACT_APP_STRIPE_PUBLISH_KEY;
  const stripePromise = loadStripe(publishKey);

  const group_id = getGroupId();
  const location = useLocation();
  const [isBusinessLeader, setIsBusinessLeader] = useState(false);
  const [isBusinessUser, setIsBusinessUser] = useState(false);

  useEffect(() => {
    userRole.map(subData => {
      if (subData.roleName === 'Business') {
        if (subData.group.includes(group_id)) {
          setIsBusinessLeader(true);
        }
      } else if (subData.roleName === 'HR') {
        if (subData.group.includes(group_id)) {
          setIsBusinessUser(true);
        }
      }

    });
  }, [location]);

  // Added for Nylas
  // const SERVER_URI = 'http://localhost:8010/v1/api';
  const SERVER_URI = 'https://talentstrap.ai/v1/api';

  return (
    <Elements stripe={stripePromise}>
      <Provider store={store}>
        <NylasProvider serverBaseUrl={SERVER_URI}>
          <ChakraProvider theme={currentTheme}>
            {/* <RouteComponent /> */}
            {/* <MainRouteComponent /> */}
            <Routes>
              {/* This section will load if group id is 100 or 200 */}
              <Route
                path="admin/*"
                element={
                  <AdminLayout
                    theme={currentTheme}
                    setTheme={setCurrentTheme}
                  />
                }
              />

              {/* <Route element={<BusinessProtectedLayout />}> */}
                  {isBusinessLeader
                  ?<Route
                    path="business/*"
                    element={
                      <BusinessLayout
                        level={'aa'}
                        theme={currentTheme}
                        setTheme={setCurrentTheme}
                      />
                    }
                  />:null}
                  {isBusinessUser
                  ?<Route
                  path="business/*"
                  element={
                    <BusinessTeamLayout
                      level={'bb'}
                      theme={currentTheme}
                      setTheme={setCurrentTheme}
                    />
                    }
                  />:null}
              {/* </Route> */}

              {/* Common Pages */}
              <Route path="/changeDefaultPassword" element={<TsChangePassword />}/>
              <Route path="/registerUser" element={<RegisterBusinessUser />} />
              <Route path="/forgot-password" element={<TsForgotPassword />} />
              <Route path="/verifyEmail" element={<TsVerficationMail />} />
              <Route path="/login" element={<TsSignIn />} />
              <Route path="/job" element={<JobApply></JobApply>} />
              {/* <Route index path="/scheduleDemo" element={<ScheduleDemo />} /> */}
              <Route index path="/terms-of-use" element={<TermsCondition />} />
              <Route index path="/privacy-policy" element={<PrivacyPolicy />} />
              <Route index path="/cookie-policy" element={<CookiePolicy />} />
              {/* This section will change to /admin or /business depending on group id */}
              {/* <Route path="/login" element={<SignInDefault></SignInDefault>} /> */}
              {/* For now LoginPage is the landing page for https://talentstrap.ai/
              <Route path="/" index element={<LoginHome />} /> */}
              <Route path="/" index element={<TsSignIn />} />
              <Route path="*" element={<OthersError />} />
            </Routes>
          </ChakraProvider>
        </NylasProvider>
      </Provider>
    </Elements>
  );
}
