import React, { useState } from 'react';
import {Button, useToast, Popover, PopoverTrigger, PopoverContent,
  PopoverArrow, PopoverCloseButton, PopoverBody, PopoverFooter, ButtonGroup, Tooltip, IconButton, useDisclosure,
} from '@chakra-ui/react';
import { MdDelete } from 'react-icons/md';
import { getEmailId } from '../../../../utils/localStorageIndex';
import { deleteCandidate } from '../../../../services/recruiterServices';
import { isObjectEmpty } from '../../../../utils/common';


export default function DeleteCandidate(props) {
  const toast = useToast()
  const {bulkSelectedCandidates, postDeleteActions} = props
  let candidate = {}
  if (bulkSelectedCandidates?.length > 0){
    candidate = bulkSelectedCandidates[0]
  }
  const { onOpen, onClose, isOpen } = useDisclosure()
  const [isDeletingCandidate, setIsDeletingCandidate] = useState(false);

  const onDeleteCandidate = () => {    
    const data = {
                candidate_uuids: bulkSelectedCandidates?.map(candidate => candidate?.candidate_uuid),
                email: getEmailId()
              }

    setIsDeletingCandidate(true);
    deleteCandidate(data)
    .then(res => {
        if (res.result) {
            onClose();
            // call any post delete functions
            for (const [fnName, fn] of Object.entries(postDeleteActions)) {
              fn()
            }
            toast({
              title: 'Candidate deleted successfully.', 
              status: 'success',
              isClosable: true,
              duration: 3000,
            });
        }else{
          toast({
            title: 'Unable to delete candidate',
            description: res.message,
            status: 'error',
            duration: 3000,
          });
        }
        
        setIsDeletingCandidate(false);
    })
    .catch( err => {
      setIsDeletingCandidate(false);
      console.log(err)
      toast({
          title: 'Unable to delete candidate', 
          description: err.toString(),
          status: 'error',
          duration: 3000,
      });
    })
}

  const ConfirmationLabel = () => {
     if (bulkSelectedCandidates?.length > 1){
        return "Delete Candidates! Once deleted, there is no recovery"
     }

     return 'Delete Candidate! Once deleted, there is no recovery'
  }

  const ConfirmationMessage = () => {
    if (bulkSelectedCandidates?.length > 1){
      return `Are you sure you want to delete these ${bulkSelectedCandidates?.length} selected candidates?`
    }
    return "Are you sure you want to delete this candidate?"
  }

  return (
        <Popover placement='bottom' isOpen={isOpen} onClose={onClose}>
              <Tooltip label={<ConfirmationLabel />} fontSize='md'>
                <span>
                  <PopoverTrigger>
                    <IconButton isDisabled={bulkSelectedCandidates?.length == 0 || isObjectEmpty(candidate)} onClick={onOpen} bg={'none'} _hover={{'bg': 'none'}} icon={<MdDelete />} color={'gray'} />
                  </PopoverTrigger>
                </span>
              </Tooltip>
            
            <PopoverContent width={'350px'} color='white' bg='blue.800' borderColor='blue.800'>
                <PopoverArrow bg='blue.800' />
                <PopoverCloseButton />
                <PopoverBody mt={5}><ConfirmationMessage /></PopoverBody>
                <PopoverFooter border='0' display='flex' alignItems='center' justifyContent='space-between' pb={4}>
                <ButtonGroup size='sm'>
                    <Button colorScheme='blue' onClick={onDeleteCandidate}
                        isLoading={isDeletingCandidate}
                        loadingText={'Deleting...'}
                    >Yes</Button>
                </ButtonGroup>
                </PopoverFooter>
            </PopoverContent>
        </Popover>
    )
}
