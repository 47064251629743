import { Drawer, DrawerCloseButton, DrawerContent, DrawerHeader, Flex } from "@chakra-ui/react";
import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";

const DocumentViewer = (props) => {
    const {title, document, size='xl', isOpen, onClose} = props;
    const fileNameArray = document?.split('.')
    const documentType = fileNameArray ? fileNameArray[fileNameArray?.length - 1] : null

    return <>
        <Drawer size={size} isOpen={isOpen}  placement="right" onClose={onClose}>
            <DrawerContent pl={5}  overflow={'auto'}>
            <DrawerHeader>{title}</DrawerHeader>
            <DrawerCloseButton />
              <Flex>
                <DocViewer
                      pluginRenderers={DocViewerRenderers} 
                      documents={[{uri: document, fileName: document, fileType:documentType}]}
                      style={{'height': '100vh'}}
                      config={{
                        theme:{
                          'pdf-download':false
                        },
                        header: {
                          disableHeader: true,
                          disableFileName: false,
                          retainURLParams: true,
                        },
                        csvDelimiter: ",", // "," as default,
                        pdfZoom: {
                          defaultZoom: 1, // 1 as default,
                          zoomJump: 0.2, // 0.1 as default,
                        },
                        pdfDownload: false,
                        pdfVerticalScrollByDefault: false, // false as default
                      }}
                    />
              </Flex>                
            </DrawerContent>
        </Drawer>
    </>
}


export default DocumentViewer