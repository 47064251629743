import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// Chakra Imports
import {
  Spinner,
  Box,
  Flex,
  Icon,
  Image,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  useColorModeValue,
  useColorMode,
  useToast,
} from '@chakra-ui/react';
import { NavLink } from 'react-router-dom';
// Custom Components
import { ItemContent } from '../../components/menu/ItemContent';
import { SidebarResponsive } from '../../components/sidebar/Sidebar';
import avatar4 from '../../assets/img/avatars/dummy-avatar.jpg';
import { fetchPersonalInfo, setNotificationAsRead } from '../../services/sharedServices';
import Logout from '../defaultLayout/header/Logout/Logout';
// Assets
import { MdNotificationsNone, MdDarkMode, MdLightMode } from 'react-icons/md';
import Configurator from '../../components/navbar/Configurator';
import routes from '../../routes/horizonRoutes';
import { getEmailId } from '../../utils/localStorageIndex';
import CandidateSearch from './CandidateSearch';
import { customNotification, unsetAllCustomNotification, unsetCustomNotification } from '../../utils/customNotification';

export default function HeaderLinks(props) {
  const toast = useToast()
  const emailId = getEmailId()
  const dispatch = useDispatch()
  const { secondary, theme, setTheme } = props;
  // Chakra Color Mode
  const navbarIcon = useColorModeValue('gray.400', 'white');
  let menuBg = useColorModeValue('white', 'navy.800');
  const textColor = useColorModeValue('secondaryGray.900', 'white');
  const textColorBrand = useColorModeValue('brand.700', 'brand.400');
  const borderColor = useColorModeValue('#E6ECFA', 'rgba(135, 140, 189, 0.3)');

  const shadow = useColorModeValue(
    '14px 17px 40px 4px rgba(112, 144, 176, 0.18)',
    '14px 17px 40px 4px rgba(112, 144, 176, 0.06)'
  );

  const { colorMode, toggleColorMode } = useColorMode();
  const [mini, setMini] = useState(false);
  const [loading, isLoading] = useState(true);
  const [userDetails, setUserDetails] = useState({});
  const sideMenuStatus = useSelector(store => store.notificationHeader);
  const { sideMenuFlag, profileLogoFlag, notificationData } = sideMenuStatus;

  const [notificationDataArr, setNotificationDataArr] = useState([]);
  const [notificationCount, setNotificationCount] = useState(0);
  const [markingAsRead, setMarkingAsRead] = useState(false);
  const bgColor = useColorModeValue('#f7f9fb !important', '#0b1437 !important');

  const [socket, setSocket] = useState(null);

  const connectWebsocket = () => {
    const API_HOST = process.env.REACT_APP_TALENT_STRAP_API_URL?.replace('https', 'wss')?.replace('http', 'ws')
    const socket = new WebSocket(`${API_HOST}/v1/api/notifications/list/${userDetails?.user_uuid}`);
    setSocket(socket);
  }

  useEffect(() => {
    // connect websocket
    console.log('connecting websocket for ', userDetails?.email)
    connectWebsocket()
  }, [userDetails]);

  useEffect(() => {
    if (!socket) return;

    socket.onopen = function (event) {
        console.log("WebSocket connected for", userDetails?.email);
    };

    socket.onmessage = function (event) {
        const data = JSON.parse(event.data)
        if (!data?.read_all){
          customNotification(dispatch, data);
        }else{
          console.log('no more notifications for ', userDetails?.email)
          socket.close()
        }
    };

    socket.onclose = function (event) {
        console.log("WebSocket closed for ", userDetails?.email);
        setTimeout(function() {
          connectWebsocket();
        }, 2000);
    };

    return () => {
      if (socket.readyState === 1){
        socket.close();
      }        
    };
  }, [socket]);


  useEffect(() => {
    const distinctNotifications = [...new Map(notificationData.map(item => [item['notification_uuid'], item])).values()];
    // distinctNotifications.reverse()
    setNotificationDataArr(distinctNotifications);
    const unreadNotificationsCount = distinctNotifications?.filter(n => n.is_read == false)?.length
    setNotificationCount(unreadNotificationsCount || 0);
  }, [notificationData]);

  useEffect(() => {
    isLoading(true);
    let objData = {
      email: emailId,
    };
    fetchPersonalInfo(objData)
      .then(res => {
        isLoading(false);
        if (res.result) {
          setUserDetails(res.data[0]);
        }
      })
      .catch(err => {
        isLoading(false);
      });
  }, [profileLogoFlag]);

  const markAllNotificationsAsRead = () => {
    markNotificationAsRead({}, true)
  }

  const markNotificationAsRead = (notification, mark_all_as_read=false) => {

    // handle local notification by just unsetting in redux store.
    if (!notification?.on_click && !mark_all_as_read){
      unsetCustomNotification(dispatch, notification)
      return
    }

    // handle notifications coming from database
    setMarkingAsRead(true);
    let objData = {
      email: emailId,
      user_uuid: userDetails?.user_uuid,
      notification_uuid: notification?.notification_uuid,
      mark_all_as_read: mark_all_as_read
    };
    setNotificationAsRead(objData)
      .then(res => {
        setMarkingAsRead(false);
        if (res?.result) {
          if (mark_all_as_read){
            unsetAllCustomNotification(dispatch)
          }else{
            unsetCustomNotification(dispatch, notification)
          }          
        }else{
          toast({
            title: 'Unable to mark notification as read',
            description: res?.message,
            status: 'error',
            duration: 5000,
          });
        }
      })
      .catch(err => {
        setMarkingAsRead(false);
        toast({
          title: 'Unable to mark notification as read',
          description: err?.toString(),
          status: 'error',
          duration: 5000,
        });
      });
  }

  const renderNotificationData = () => {
    if (notificationDataArr?.length) {
      return notificationDataArr?.slice(0, 20).map((data, index) => (
        <MenuItem
          key={index}
          bg={data?.is_read == true ? 'none' : 'lightyellow' } cursor={'default'}
          _hover={{'bg': 'none'}} borderBottom={'0.1px solid gray'}
        >
          <ItemContent 
            info={data}
            notificationIndex={index}
            markNotificationAsRead={markNotificationAsRead}
          />
        
        </MenuItem>
      ));
    } else {
      // You might want to return some default content or null when there's no data.
      return null;
    }
  };

  return (
    <Flex
      w={{ sm: '100%', md: 'auto' }}
      alignItems="center"
      flexDirection="row"
      bg={menuBg}
      flexWrap={secondary ? { base: 'wrap', md: 'nowrap' } : 'unset'}
      p="10px"
      pl="20px"
      borderRadius="999px"
      boxShadow={shadow}
    >
      <SidebarResponsive routes={routes} />
      <Menu>
        <CandidateSearch />
        <MenuButton p="0px" mr={5} position="relative">
          <Icon
            mt="6px"
            as={MdNotificationsNone}
            color={navbarIcon}
            w="25px"
            h="25px"
            me="10px"
          />
          <Box
            position="absolute"
            w="30px"
            h="20px"
            borderRadius={'10px'}
            left="-5px"
            top="-5px"
            bg={'red.200'}
            fontWeight={'600'}
          >
            {notificationCount}
          </Box>
        </MenuButton>
        <MenuList
          boxShadow={shadow}          
          borderRadius="20px"
          bg={menuBg}
          border="none"
          mt="22px"
          me={{ base: '30px', md: 'unset' }}
          w="400px"
          // minW={{ base: 'unset', md: '400px', xl: '450px' }}
          // maxW={{ base: '360px', md: 'unset' }}
        >          
          <Flex w="100%" mb="20px" p={'20px'}>
            <Text fontSize="md" fontWeight="600" color={textColor}>
              Notifications
            </Text>
            <Text
              fontSize="sm"
              fontWeight="500"
              color={textColorBrand}
              ms="auto"
              cursor="pointer"
              onClick={markAllNotificationsAsRead}
            >
              Mark all read
            </Text>
          </Flex>
          <Flex
            flexDirection="column"
            h="350px"
            overflow="auto"
            css={{
            '&::-webkit-scrollbar': {
                width: '8px',
            },
            '&::-webkit-scrollbar-track': {
                width: '12px',
            },
            '&::-webkit-scrollbar-thumb': {
                borderRadius: '100px',
                background: 'gray.500',
                border: '6px solid rgba(0, 0, 0, 0.2)'
            },
            }}
          >
            {renderNotificationData()}
          </Flex>
        </MenuList>
      </Menu>

      <Box p="0px" mr={5}>
        <Icon
          mt="6px"
          onClick={() => toggleColorMode()}
          as={colorMode === 'dark' ? MdDarkMode : MdLightMode}
          color={navbarIcon}
          w="25px"
          h="25px"
          me="10px"
        />
      </Box>

      <Configurator
        mini={props.mini}
        setMini={props.setMini}
        theme={theme}
        setTheme={setTheme}
      />

      <Menu>
        <MenuButton p="0px">
          {loading ? (
            <Flex
              alignItems="center"
              justifyContent="center"
              h="50px"
              color="#fff"
            >
              <Spinner size="sm" />
            </Flex>
          ) : (
            <Image
              borderRadius="40px"
              me={mini === false ? '20px' : mini === true ? '20px' : '0px'}
              src={userDetails?.photo_url ? userDetails?.photo_url : avatar4}
              h="40px"
              w="40px"
            ></Image>
          )}
        </MenuButton>
        <MenuList
          boxShadow={shadow}
          p="0px"
          mt="10px"
          borderRadius="20px"
          bg={menuBg}
          border="none"
        >
          <Flex w="100%" mb="0px">
            <Text
              ps="20px"
              pt="16px"
              pb="10px"
              w="100%"
              borderBottom="1px solid"
              borderColor={borderColor}
              fontSize="sm"
              fontWeight="700"
              color={textColor}
            >
              👋&nbsp; Hey, {userDetails?.first_name} {userDetails?.last_name}
            </Text>
          </Flex>
          <Flex flexDirection="column" p="10px">
            <MenuItem
              _hover={{ bg: 'none' }}
              _focus={{ bg: 'none' }}
              borderRadius="8px"
              px="14px"
            >
              {userDetails?.group === 100 || userDetails?.group === 200 ? (
                <NavLink to="/admin/profile">
                  <Text fontSize="sm">Profile</Text>
                </NavLink>
              ) : null}
              {userDetails?.group === 300 || userDetails?.group === 400 ? (
                <NavLink to="/business/profile">
                  <Text fontSize="sm">Profile</Text>
                </NavLink>
              ) : null}
            </MenuItem>
            <MenuItem
              _hover={{ bg: 'none' }}
              _focus={{ bg: 'none' }}
              color="red.400"
              borderRadius="8px"
            >
              <Logout></Logout>
            </MenuItem>
          </Flex>
        </MenuList>
      </Menu>
    </Flex>
  );
}
