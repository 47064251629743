import axios from './axios';
import { serviceUrls } from './serviceUrls';

export const paymentService = (data, config = null, axiosInstance = axios) => {
  return axiosInstance.post(serviceUrls().payment, data, config);
};

export const paymentCustomerApi = (data, config = null, axiosInstance = axios) => {
  return axiosInstance.post(serviceUrls().paymentCustomer, data, config);
};
export const paymentCustomerTeamApi = (data, config = null, axiosInstance = axios) => {
  return axiosInstance.post(serviceUrls().paymentGetCustomer, data, config);
};

export const completePaymentApi = (data, config = null, axiosInstance = axios) => {
  return axiosInstance.post(serviceUrls().completePayment, data, config);
};

export const completePaymentApiChargebee = (data, config = null, axiosInstance = axios) => {
  return axiosInstance.post(serviceUrls().completePaymentChargebee, data, config);
};

export const storeCardData = (data, config = null, axiosInstance = axios) => {
  return axiosInstance.post(serviceUrls().saveCardDetails, data, config);
};

export const finalizePayemnts = (
  data,
  config = null,
  axiosInstance = axios
) => {
  return axiosInstance.post(serviceUrls().makePayment, data, config);
};

export const paymentCompletion = (
  data,
  config = null,
  axiosInstance = axios
) => {
  return axiosInstance.post(serviceUrls().paymentCompletion, data, config);
};

export const fetchValidatePurchase = (
  data,
  config = null,
  axiosInstance = axios
) => {
  return axiosInstance.post(serviceUrls().validatePurchase, data, config);
};

export const fetchTeamValidatePurchase = (
  data,
  config = null,
  axiosInstance = axios
) => {
  return axiosInstance.post(serviceUrls().validateTeamPurchase, data, config);
};

export const fetchPlanDetails = (
  data,
  config = null,
  axiosInstance = axios
) => {
  return axiosInstance.post(serviceUrls().getPlan, data, config);
};

export const fetchPlanDetailsChargebee = (
  data,
  config = null,
  axiosInstance = axios
) => {
  return axiosInstance.post(serviceUrls().getPlanChargebee, data, config);
};

export const fetchSelectedPlanDetails = (
  data,
  config = null,
  axiosInstance = axios
) => {
  return axiosInstance.post(serviceUrls().selectedPlan, data, config);
};


export const fetchCancellationPlanDetails = (
  data,
  config = null,
  axiosInstance = axios
) => {
  return axiosInstance.post(serviceUrls().cancellationPlan, data, config);
};


export const cancelSelectedPlan = (
  data,
  config = null,
  axiosInstance = axios
) => {
  return axiosInstance.post(serviceUrls().cancelPlan, data, config);
};

export const modifySelectedPlan = (
  data,
  config = null,
  axiosInstance = axios
) => {
  return axiosInstance.post(serviceUrls().modifyPlan, data, config);
};

export const allotmentPlan = (data, config = null, axiosInstance = axios) => {
  return axiosInstance.post(serviceUrls().selectPlan, data, config);
};

export const modifyPlan = (data, config = null, axiosInstance = axios) => {
  return axiosInstance.post(serviceUrls().updatePlan, data, config);
};


export const modifySubscriptionChargebee = (data, config = null, axiosInstance = axios) => {
  return axiosInstance.post(serviceUrls().modifyPlanChargebee, data, config);
};

export const updateSubscriptionChargebee = (data, config = null, axiosInstance = axios) => {
  return axiosInstance.post(serviceUrls().updateSubscriptionChargebee, data, config);
};


export const getPortalSession = (data, config = null, axiosInstance = axios) => {
  return axiosInstance.post(serviceUrls().getPortalSession, data, config);
};
