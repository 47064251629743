import { useLocation } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import {
  Box,
  Flex,
  Text,
  Badge,
  Spinner,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
} from '@chakra-ui/react';
import {
  borderBottomColor,
  defaultThemeColor,
} from '../../../../../../constants/index';
import DocViewer, { DocViewerRenderers} from "@cyntler/react-doc-viewer";

function ResumePdfView(props) {
    const [loading, isLoading] = useState(false);
    const [numPages, setNumPages] = useState(0);
    const [pageNumber, setPageNumber] = useState(1);
    const fileNameArray = props.pdfUrl.split('.')
    const fileType = fileNameArray[fileNameArray.length - 1]
    return (
      
        <>
          {loading ? (
            <Flex h="70vh" alignItems="center" justifyContent="center">
              {/* <Text>Loading {props.pdfUrl ? props.pdfUrl : 'no url'}</Text>  */}
              <Spinner size="xl" />
            </Flex>
          ) : (
            
            <Box w={'100%'} h={'100%'}>
                <DocViewer 
                  pluginRenderers={DocViewerRenderers} 
                  documents={[{uri: props.pdfUrl, fileName:props.pdfUrl, fileType: fileType}]}
                  style={{'height': '100vh'}}
                  config={{
                    theme:{
                      'pdf-download':false
                    },
                    header: {
                      disableHeader: true,
                      disableFileName: false,
                      retainURLParams: true,
                    },
                    csvDelimiter: ",", // "," as default,
                    pdfZoom: {
                      defaultZoom: 1, // 1 as default,
                      zoomJump: 0.2, // 0.1 as default,
                    },
                    pdfDownload: false,
                    pdfVerticalScrollByDefault: false, // false as default
                  }}
                />
                {/* <Box w={'100%'}>
                    Page {pageNumber} of {numPages}
                </Box> */}
            </Box>
          )}
        </>
      );
}
export default ResumePdfView;