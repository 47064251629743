import React, { useEffect } from 'react';
import { NavLink } from 'react-router-dom';
// Chakra imports
import {
  Box,
  Button,
  Checkbox, Spacer,
  Flex,
  FormControl,
  FormLabel, Image,
  Heading,
  Icon,
  Input,
  InputGroup,
  InputRightElement,
  Text,
  useColorModeValue,
  useToast,
  HStack,
} from '@chakra-ui/react';
// Custom components
import { HSeparator } from '../../../components/separator/Separator';
import DefaultAuth from '../../../layouts/auth/types/Default';
import CenteredAuth from '../../../layouts/auth/types/Centered';

// Assets
import illustration from '../../../assets/img/auth/auth.png';
import { MdOutlineRemoveRedEye } from 'react-icons/md';
import { RiEyeCloseLine } from 'react-icons/ri';
import { useNavigate } from 'react-router-dom';
import {
  saveSessionId,
  saveEmailId,
  saveAuthToken,
  savegroupId,
} from '../../../utils/localStorageIndex';
import tsLogo from '../../../media/images/TalentStrap-Final.png';
import { login, getToken } from '../../../services/sharedServices';
import GoogleSignInButton from '../../../components/Login/GoogleSignInButton';
import MicrosoftSignInButton from '../../../components/Login/MicrosoftSignInButton';

function SignIn() {
  // Chakra color mode
  const navigate = useNavigate();
  const textColor = useColorModeValue('navy.700', 'white');
  const textColorSecondary = 'gray.400';
  const textColorDetails = useColorModeValue('navy.700', 'secondaryGray.600');
  const textColorBrand = useColorModeValue('brand.500', 'white');
  const brandStars = useColorModeValue('brand.500', 'brand.400');
  const [show, setShow] = React.useState(false);
  const handleClick = () => setShow(!show);
  const toast = useToast();
  const [error, setError] = React.useState({});
  const [loading, isLoading] = React.useState(false);
  const [loginForm, setLoginForm] = React.useState({
    email: '',
    password: '',
  });

  // Todo:Set form value
  const onUpdateField = e => {
    const nextFormState = {
      ...loginForm,
      [e.target.name]: e.target.value,
    };
    setLoginForm(nextFormState);
  };

  // Todo:Validation Function
  const validateFieldForm = () => {
    let errors = {};
    let formIsValid = true;
    let fields = loginForm;
    let emailPattern = new RegExp(
      /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/
    );
    if (!fields['email']) {
      formIsValid = false;
      errors['email'] = '*Please enter email.';
    } else if (!emailPattern.test(fields['email'])) {
      formIsValid = false;
      errors['email'] = '*Please enter valid email address.';
    }
    if (!fields['password']) {
      formIsValid = false;
      errors['password'] = '*Please enter password.';
    }
    setError(errors);
    return formIsValid;
  };

  const handleKeyPress = event => {
    if (event.key === 'Enter') {
      handleSubmit();
    }
  };

  const handleSubmit = async () => {
    if (validateFieldForm()) {
      isLoading(true);
      try {
        const res = await login(loginForm);
        isLoading(false);
        if (res?.result) {
          if (res?.data[0].email_verified === false) {
            const emailId = res?.data[0].email;
            toast({
              title:
                'Sorry, the email verification is not completed.. Please check your email for the email verification code or you can resend the email verification code also.',
              status: 'success',
              isClosable: true,
              duration: 3000,
            });
            navigate('/verifyEmail', {
              state: {
                user_emailId: emailId,
              },
            });
          } else if (res?.data[0].is_dummy_password === false) {
            const emailId = res?.data[0].email;
            saveEmailId(emailId);

            // Move the token retrieval logic here and await the token
            const formData = new FormData();
            formData.append('grant_type', '');
            formData.append('username', loginForm.email);
            formData.append('password', loginForm.password);
            formData.append('scope', '');
            formData.append('client_id', '');
            formData.append('client_secret', '');
            const tokenRes = await getToken(formData);
            if (
              tokenRes.access_token != null &&
              tokenRes.access_token.length > 0
            ) {
              saveAuthToken(tokenRes.access_token);
            }

            savegroupId(res?.data[0].group);
            saveSessionId(res?.data[0].user_uuid);
            toast({
              title: 'Logged In Successfully',
              status: 'success',
              isClosable: true,
              duration: 3000,
            });
            if (res?.data[0].group === '300' || res?.data[0].group === '400') {
              // navigate('/business/dashboard');
              navigate('/business/team',{
                state: {
                  groupId: res?.data[0].group,
                },
              });
            }
            if (res?.data[0].group === '100' || res?.data[0].group === '200') {
              // navigate('/admin/dashboard');
              navigate('/admin/team');
            }
          } else if (res?.data[0].is_dummy_password === true) {
            const emailId = res?.data[0].email;
            toast({
              title:
                'You have logged in successfully using default password. Please change default password with your own password',
              status: 'success',
              isClosable: true,
              duration: 3000,
            });
            navigate('/changeDefaultPassword', {
              state: {
                email: emailId,
              },
            });
          }
        } else {
          toast({
            title: res?.message,
            status: 'error',
            isClosable: true,
            duration: 3000,
          });
        }
      } catch (err) {
        isLoading(false);
        if (err?.response?.data?.detail?.message) {
          toast({
            title: err?.response?.data?.detail?.message,
            status: 'error',
            isClosable: true,
            duration: 3000,
          });
        } else {
          toast({
            title: 'An error occurred',
            status: 'error',
            isClosable: true,
            duration: 3000,
          });
        }
      }
    }
  };
  return (
    <CenteredAuth 
        cardTop={{ base: '10px', md: '14vh' }}
        cardBottom={{ base: '50px', lg: 'auto' }}
        mx="0px">
      <Flex
        maxW={{ base: '100%', md: 'max-content' }}
        w="100%"
        mx={{ base: 'auto', lg: '0px' }}
        me="auto"
        h="100%"
        alignItems="start"
        justifyContent="center"
        mb={{ base: '30px', md: '60px' }}
        px={{ base: '25px', md: '0px' }}
        mt={{ base: '1px', md: '1vh' }}
        flexDirection="column"
      >
        <Box me="auto">
          <HStack w={'100%'}>
            {/* <Image src={tsLogo} h={'70px'} /> */}
          </HStack>
            <Heading color={textColor} fontSize="30px" mb="10px">
              Sign In
            </Heading>
          <Text
            mb="36px"
            ms="4px"
            color={textColorSecondary}
            fontWeight="400"
            fontSize="md"
          >
            Enter your email and password to sign in!
          </Text>
        </Box>
        <Flex
          zIndex="2"
          direction="column"
          w={{ base: '100%', md: '420px' }}
          maxW="100%"
          background="transparent"
          borderRadius="15px"
          mx={{ base: 'auto', lg: 'unset' }}
          me="auto"
          mb={{ base: '20px', md: 'auto' }}
        >
          {/* <Button
            fontSize="sm"
            me="0px"
            mb="26px"
            py="15px"
            h="50px"
            borderRadius="16px"
            bg={googleBg}
            color={googleText}
            fontWeight="500"
            _hover={googleHover}
            _active={googleActive}
            _focus={googleActive}
          >
            <Icon as={FcGoogle} w="20px" h="20px" me="10px" />
            Sign in with Google
          </Button> */}
          <Flex w={'100%'}>
            <GoogleSignInButton selectedButton={'login'} />
            <Spacer />
            <MicrosoftSignInButton selectedButton={'login'}/>
          </Flex>
          <Flex align="center" mb="25px" mt="25px">
            <HSeparator />
            <Text color="gray.400" mx="14px">
              or
            </Text>
            <HSeparator />
          </Flex>
          <FormControl>
            <FormLabel
              display="flex"
              ms="4px"
              fontSize="sm"
              fontWeight="500"
              color={textColor}
              mb="8px"
            >
              Business Email <Text color={brandStars}>*</Text>
            </FormLabel>
            <Input
              isRequired={true}
              variant="auth"
              fontSize="sm"
              ms={{ base: '0px', md: '0px' }}
              type="email"
              placeholder="john.doe@yourcompany.com"
              mb="15px"
              fontWeight="500"
              size="lg"
              name="email"
              onChange={onUpdateField}
              value={loginForm.email}
            />
            <Text color="red" my="2">
              {error?.email}
            </Text>
            <FormLabel
              ms="4px"
              fontSize="sm"
              fontWeight="500"
              color={textColor}
              isRequired={true}
              display="flex"
            >
              Password<Text color={brandStars}>*</Text>
            </FormLabel>

            <InputGroup size="md">
              <Input
                isRequired={true}
                fontSize="sm"
                placeholder="Min. 8 Characters"
                mb="15px"
                size="lg"
                type={show ? 'text' : 'password'}
                variant="auth"
                name="password"
                value={loginForm.password}
                onChange={onUpdateField}
                onKeyPress={handleKeyPress}
              />

              <InputRightElement display="flex" alignItems="center" mt="4px">
                <Icon
                  color={textColorSecondary}
                  _hover={{ cursor: 'pointer' }}
                  as={show ? RiEyeCloseLine : MdOutlineRemoveRedEye}
                  onClick={handleClick}
                />
              </InputRightElement>
            </InputGroup>
            <Text color="red" my="2">
              {error?.password}
            </Text>
            <Flex justifyContent="space-between" align="center" mb="24px">
              {/* <FormControl display="flex" alignItems="center">
                <Checkbox id="remember-login" colorScheme="brand" me="10px" />
                <FormLabel
                  htmlFor="remember-login"
                  mb="0"
                  fontWeight="normal"
                  color={textColor}
                  fontSize="sm"
                >
                  Keep me logged in
                </FormLabel>
              </FormControl> */}
              <Spacer />
              <NavLink to="/forgot-password">
                <Text
                  color={textColorBrand}
                  fontSize="sm"
                  w="124px"
                  fontWeight="500"
                >
                  Forgot password?
                </Text>
              </NavLink>
            </Flex>
            <Button
              fontSize="sm"
              variant="brand"
              fontWeight="500"
              w="100%"
              h="50"
              mb="24px"
              onClick={() => {
                handleSubmit();
              }}
              isDisabled={loading ? true : false}
              isLoading={loading ? true : false}
            >
              Sign In
            </Button>
          </FormControl>
          <Flex
            flexDirection="column"
            justifyContent="center"
            alignItems="start"
            maxW="100%"
            mt="0px"
          >
            <Text color={textColorDetails} fontWeight="400" fontSize="14px">
              Not registered yet?
              <NavLink to="/registerUser">
                <Text
                  color={textColorBrand}
                  as="span"
                  ms="5px"
                  fontWeight="500"
                >
                  Register as Business
                </Text>
              </NavLink>
            </Text>
          </Flex>
        </Flex>
      </Flex>
    </CenteredAuth>
  );
}

export default SignIn;
