import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { BiLogOutCircle } from 'react-icons/bi';
import { Text, useToast } from '@chakra-ui/react';
import { logout } from '../../../../services/sharedServices';
import { setJobSourcingBusinessId } from '../../../../store/actions/topNotificationActions';

import {
  getEmailId,
  clearLocalStorage,
} from '../../../../utils/localStorageIndex';
import { customNotification } from '../../../../utils/customNotification';

function Logout() {
  const toast = useToast();
  const emailId = getEmailId();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, isLoading] = useState(false);

  // logout function
  const systemLogout = () => {
    var apiData = { email: emailId };
    isLoading(true);
    logout(apiData)
      .then(res => {
        isLoading(false);
        if (res.result) {
          toast({
            title: res?.message,
            status: 'success',
            isClosable: true,
            duration: 3000,
          });
          dispatch(setJobSourcingBusinessId(''));
          customNotification(dispatch, []);
          navigate('/');
          clearLocalStorage();
        } else {
          isLoading(false);
          setJobSourcingBusinessId('');
        }
      })
      .catch(err => {
        isLoading(false);
        setJobSourcingBusinessId('');
        toast({
          title: 'Error',
          status: 'error',
          isClosable: true,
          duration: 3000,
        });
      });
  };
  return (
    <Text
      m="0"
      onClick={() => {
        systemLogout();
      }}
      size="sm"
      color="red"
      cursor={'pointer'}
      borderRadius="2px"
      leftIcon={<BiLogOutCircle mr="1" />}
      isDisabled={loading ? true : false}
      isLoading={loading ? true : false}
    >
      Logout
    </Text>
  );
}

export default Logout;
