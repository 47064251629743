import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, location } from 'react-router-dom';
// Chakra imports
import {
    Button,
    Box,
    Select,
    Spacer,
    Flex,
    FormControl,
    Text,
    useToast,
    useColorModeValue,
  } from "@chakra-ui/react";
  import Card from "../../../../components/card/Card.js";
  import InputField from "../../../../components/fields/InputField.js";
  
  function HrIntegrationVerify() {
    const toast = useToast();
    const navigate = useNavigate();
    const { state } = useLocation();

    const hrSystemUuid = state?.hrSystemUuid ? state?.hrSystemUuid : null;

    const backToIntegrationPage = () => {
        navigate('/business/integration', {
            state: {
                sourcePage:'hrintegration',
            },
        });
    };
  
  
    return (
        <Flex direction='column' pt={{ sm: "125px", lg: "75px" }}>
            {hrSystemUuid === null
            ?<Card w={'100%'} p={2}>
                <Flex w={'100%'} p={2}>
                    <Text fontSize={'lg'} color={'red.400'}>Unable to complete the given request..</Text>
                    <Spacer />
                    <Button 
                        onClick={() => backToIntegrationPage()}
                        size={'sm'}>
                        Back to Integration
                    </Button>
                </Flex>
            </Card>
            :<Flex w={'100%'} direction='column'>
                <Card w={'100%'} p={2}>
                    <Flex w={'100%'} p={2}>
                        <Text fontSize={'lg'}>{hrSystemUuid} HR Integration Verification</Text>
                        <Spacer />
                        <Button 
                            onClick={() => backToIntegrationPage()}
                            size={'sm'}>
                            Back to Integration
                        </Button>
                    </Flex>
                </Card>
                <Card mt={2} w={'100%'} p={2} h={'calc(100vh - 350px)'}>

                </Card>
            </Flex>}
        </Flex>
    )
}

export default HrIntegrationVerify;
