import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, location } from 'react-router-dom';
import { defaultThemeColor } from '../../../../constants/index';
import { fetchPlanDetails } from '../../../../services/paymentServices';
import Pack from '../../../../views/admin/main/others/pricing/components/Pack';
import Card from '../../../../components/card/Card';
import {
    AiFillEdit,
    AiOutlineCheckCircle,
    AiFillFilePdf,
    AiFillInfoCircle,
    AiOutlineLinkedin,
    AiOutlineFilePdf,
    AiOutlineInfoCircle,
    AiOutlineArrowLeft,
    AiOutlineCaretLeft,
  } from 'react-icons/ai';
import {
  Box, Icon,
  HStack,
  Text,
  Spacer,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  CloseButton,
  UnorderedList,
  List,
  ListItem,
  ListIcon,
  Center,
  Flex,
  Button,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  Slider,
  Link,
  TabPanel,
  useToast,
  SliderFilledTrack,
  SliderThumb,
  SliderMark,
  SliderTrack,
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerContent,
  useDisclosure,
  DrawerCloseButton,
  Spinner,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  ModalBody,
  useColorModeValue,
} from '@chakra-ui/react';
import { fetchValidatePurchase } from '../../../../services/paymentServices';
import StripePayment from './StripePayment';
import { getEmailId } from '../../../../utils/localStorageIndex';
import Pricify from '@chargebee/atomicpricing';


function PurchasePage() {
    const toast = useToast();
    const navigate = useNavigate();
    const { state } = useLocation();

    const stripePricingTable = process.env.REACT_APP_STRIPE_PRICING_TABLE;
    const stripePublishKey = process.env.REACT_APP_STRIPE_PUBLISH_KEY;
    const btnRef = React.useRef();
    const customerEmail = state?.customerEmail ? state?.customerEmail : null;
    const customerId = state?.customerId ? state?.customerId : null;
    const [loading, isLoading] = useState(false);

    const textColorPrimary = useColorModeValue('secondaryGray.900', 'gray.600');

    const [paymentLoading, isPaymentLoading] = useState(false);
    const [validationPassed, isValidationPassed] = useState(false);

    // const [isPopupOpen, setPopupOpen] = useState(false);
    // const {isOpen: isVisible, onClose, onOpen, } = useDisclosure({ defaultIsOpen: true })
    
    // const [selectedPlanRes, setSelectedPlanRes] = useState({});
    // const [selectedPlanStatus, setSelectedPlanStatus] = useState(false);
    // const [planSelected, setPlanSelected] = useState(null);
    // const [noBusinessPlan, setNoBusinessPlan] = useState(true);
    
    const [alertVisible, setAlertVisible] = useState(false);
    const [alertMessage, setAlertMessage] = useState(
      {
        title:'Bad Setting',
        message:"You do not have proper access to this page.. Please go back to contact Talent Strap support!!",
        type:'error'
      });

  const shadow = useColorModeValue(
    '14px 17px 40px 4px rgba(112, 144, 176, 0.18)',
    '14px 17px 40px 4px rgba(112, 144, 176, 0.06)'
  );

  useEffect(() => {
    Pricify.init();
    Pricify.setVisitor({
      email: customerEmail,
    });
  }, []);

  useEffect(() => {
      validatePurchaseActivity();
  }, []);

  const validatePurchaseActivity = () => {
        let objBody = {
            email: getEmailId()
        };
        fetchValidatePurchase(objBody)
        .then(res => {
            isLoading(false);
            if (res?.result && res?.data?.length === 1 && res?.data[0]?.validation === false) {
              navigate('/business/billing', {
                state: {
                    sourcePage:'cancelplan',
                },
              });
            } else {
              isValidationPassed(true);
            }
        })
        .catch(err => {
          navigate('/business/billing', {
            state: {
                sourcePage:'cancelplan',
            },
          });
        });
  };

  const renderSpinner = () => {
    return (
      <Flex h="50vh" alignItems="center" justifyContent="center" mt="10">
        <Spinner size="xl" />
      </Flex>
    );
  };

  const toastFunction = (message, status) => {
    return toast({
      title: message,
      status: status,
      isClosable: true,
      duration: 3000,
    });
  };

  const backToBillingPage = () => {
    navigate('/business/billing', {
        state: {
            sourcePage:'purchaseplan',
            adminSettings: false
        },
    });
  };

  return (
    <>
      {loading ? (
        <>{renderSpinner()}</>
      ) : (
        <Flex direction="column" pt={{ sm: '125px', lg: '75px' }}>
          <Flex
            direction="column"
          >
            <Flex w={'100%'} direction={'column'} align={'center'}>
                {customerEmail === null || customerId === null
                ?<Flex w={'100%'} p={2} direction={'column'}>
                    <Alert status={alertMessage.type} >
                        <AlertIcon />
                        <Box>
                            <AlertTitle>{alertMessage.title}</AlertTitle>
                            <AlertDescription>{alertMessage.message}</AlertDescription>
                        </Box>
                        <Spacer />
                        <CloseButton
                            alignSelf='flex-start'
                            position='relative'
                            right={-1}
                            top={-1}
                        />                    
                    </Alert>
                </Flex>
                :<Flex w={'100%'} direction={'column'}>
                  <Card w={'100%'} p={1}>
                    <Flex w={'100%'} p={2}>
                      <Text mt={4} fontSize={'xl'}>{'Purchase Subscription'}</Text>
                      <Spacer />
                      <Button 
                          onClick={() => backToBillingPage()}
                          mt={4} color={textColorPrimary} bg={'red.200'}>
                          <Icon as={AiOutlineCaretLeft} mr={2} />Cancel
                      </Button>
                    </Flex>
                  </Card>
                  <Card w={'100%'} mt={1}>
                    <HStack w={'100%'}>
                      <Flex w={'40%'}>
                        <Card w={'100%'}>
                            <Text fontSize={'xl'}>Pricing is set based on the following criteria:</Text>
                            <List spacing={3} mt={5}>
                                <ListItem>
                                    <ListIcon as={AiOutlineCheckCircle} color='green.500' />
                                    <b>$700</b> per slot/mo (1-2 Slots)
                                </ListItem>
                                <ListItem>
                                    <ListIcon as={AiOutlineCheckCircle} color='green.500' />
                                    <b>$600</b> per slot/mo (3-7 Slots)
                                </ListItem>
                                <ListItem>
                                    <ListIcon as={AiOutlineCheckCircle} color='green.500' />
                                    <b>$500</b> per slot/mo (8+ Slots)
                                </ListItem>
                                <ListItem>
                                    <ListIcon as={AiOutlineCheckCircle} color='green.500' />
                                    <b>10% discount</b> per slot/mo if paid <b>anually</b>
                                </ListItem>
                            </List>
                        </Card>
                      </Flex>
                      <Flex w={'60%'} direction={'column'}>
                        <Flex w={'100%'} direction={'column'} align={'start'}>
                          {customerEmail != null && customerId != null
                          ?<Card align={'center'}>
                            <div
                              id="pricify-hosted-pricing-page"
                              data-pricify-site="01HXYRCWZYA6STS3WPZTPVFRDR"
                              data-pricify-pricingpage="01HXYRCXJZD482MF1F1RN5876Y"
                              data-pricify-viewport-defaultheight="876px"
                            ></div>
                          <Text><b>Note:</b> You can add number of slots in when subscribing the plan..</Text>
                          </Card>
                          :<Flex w={'100%'} p={2} direction={'column'}>
                            <Text color={'red.200'}>Something is not right to subscribe business plan, please contact Talent Strap Support.</Text>
                          </Flex>}
                        </Flex>
                      </Flex>
                      </HStack>
                    </Card>
                  </Flex>}
            </Flex>
          </Flex>
        </Flex>
      )}
    </>
  );
}

export default PurchasePage;
