import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
// Chakra imports
import {
  Portal,
  Icon,
  Box,
  useDisclosure,
  useColorModeValue,
} from '@chakra-ui/react';
import Footer from '../../components/footer/FooterAdmin';
// Layout components
import Navbar from '../../components/navbar/NavbarAdmin';
import Sidebar from '../../components/sidebar/Sidebar';
import { SidebarContext } from '../../contexts/SidebarContext';
import { Route, Routes, Navigate } from 'react-router-dom';
import { BiCaretRightCircle, BiCaretLeftCircle } from 'react-icons/bi';

// Avkash I have replaced this with the Admin Routes
import { getGroupId } from '../../utils/localStorageIndex';
import routes from '../../routes/tsBusinessRoutes';
import teamRoutes from '../../routes/tsBusinessMemberRoute';

import { userRole } from '../../constants/index';

// Custom Chakra theme
export default function BusinessDashboard(props) {
  const { ...rest } = props;

  const { state } = useLocation();
  const group_id = getGroupId();
  const groupId = state?.groupId ? state?.groupId : 300;
  const location = useLocation();
  // states and functions
  const [fixed] = useState(false);
  const [toggleSidebar, setToggleSidebar] = useState(false);
  const [mini, setMini] = useState(false);
  const [hovered, setHovered] = useState(false);

  const [selectedRoute, setSelectedRoute] = useState(null);
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  // functions for changing the states from components
  const getRoute = () => {
    return window.location.pathname !== '/business/full-screen-maps';
  };

  let selectedBusinessRoute = routes;
  // if (groupId === "300" || groupId === 300){
  //   selectedBusinessRoute = teamRoutes;
  // } else {
  //   selectedBusinessRoute = teamRoutes;
  // }

  // useEffect(() => {
  //   getActiveRoute(selectedBusinessRoute);
  // }, [location]);

  const getActiveRoute = localRoutes => {
    let activeRoute = 'Business Dashboard';
    for (let i = 0; i < localRoutes.length; i++) {
      if (localRoutes[i].collapse) {
        let collapseActiveRoute = getActiveRoute(localRoutes[i].items);
        if (collapseActiveRoute !== activeRoute) {
          return collapseActiveRoute;
        }
      } else {
        if (
          window.location.href.indexOf(
            localRoutes[i].layout + localRoutes[i].path
          ) !== -1
        ) {
          return localRoutes[i].name;
        }
      }
    }
    return activeRoute;
  };

  const getActiveNavbar = localRoutes => {
    let activeNavbar = false;
    for (let i = 0; i < localRoutes.length; i++) {
      if (localRoutes[i].collapse) {
        let collapseActiveNavbar = getActiveNavbar(localRoutes[i].items);
        if (collapseActiveNavbar !== activeNavbar) {
          return collapseActiveNavbar;
        }
      } else {
        if (
          window.location.href.indexOf(
            localRoutes[i].layout + localRoutes[i].path
          ) !== -1
        ) {
          return localRoutes[i].secondary;
        }
      }
    }
    return activeNavbar;
  };

  const getRoutes = localRoutes => {
    return localRoutes.map((localRoutes, key) => {
      if (localRoutes.layout === '/business') {
        return (
          <Route
            path={`${localRoutes.path}`}
            element={localRoutes.component}
            key={key}
          />
        );
      }
      if (localRoutes.collapse) {
        return getRoutes(localRoutes.items);
      } else {
        return null;
      }
    });
  };

  document.documentElement.dir = 'ltr';
  const { onOpen } = useDisclosure();
  const bg = useColorModeValue('background.100', 'background.900');

  //const selectedBusinessRoute = routes;
  return (
    <Box bg={bg} h="100vh" w="100vw">
      <SidebarContext.Provider
        value={{
          toggleSidebar,
          setToggleSidebar,
        }}
      >
        <Sidebar
          hovered={hovered}
          setHovered={setHovered}
          mini={mini}
          routes={selectedBusinessRoute} // A0
          display="none"
          {...rest}
        />
        <Box
          // border={'1px'} borderColor={'red'}
          float="right"
          minHeight="100vh"
          height="100%"
          overflow="auto"
          position="relative"
          maxHeight="100%"
          // w={
          //   mini === false
          //     ? { base: '100%', xl: 'calc( 100% - 230px )' }
          //     : mini === true && hovered === true
          //     ? { base: '100%', xl: 'calc( 100% - 230px )' }
          //     : { base: '100%', xl: 'calc( 100% - 100px )' }
          // }
          w={mini === false ? 'calc( 100% - 230px )' : 'calc( 100% - 100px )'}
          maxWidth={
            mini === false ? 'calc( 100% - 230px )' : 'calc( 100% - 100px )'
          }
          // maxWidth={
          //   mini === false
          //     ? { base: '100%', xl: 'calc( 100% - 230px )' }
          //     : mini === true && hovered === true
          //     ? { base: '100%', xl: 'calc( 100% - 230px )' }
          //     : { base: '100%', xl: 'calc( 100% - 100px )' }
          // }
          transition="all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1)"
          transitionDuration=".2s, .2s, .35s"
          transitionProperty="top, bottom, width"
          transitionTimingFunction="linear, linear, ease"
        >
          <Portal>
            <Box>
              <Navbar
                hovered={hovered}
                setMini={setMini}
                mini={mini}
                onOpen={onOpen}
                logoText={'Horizon UI Dashboard PRO'}
                brandText={getActiveRoute(selectedBusinessRoute)} // A1
                secondary={getActiveNavbar(selectedBusinessRoute)} // A2
                theme={props.theme}
                setTheme={props.setTheme}
                fixed={fixed}
                {...rest}
              />
            </Box>
          </Portal>

          {getRoute() ? (
            <Box
              mx="auto"
              p={{ base: '20px', md: '30px' }}
              pe="20px"
              minH="100vh"
              pt="50px"
              // color={'red'}
            >
              <Routes>
                {getRoutes(selectedBusinessRoute)} {/* A3 */}
                <Route
                  path="/"
                  element={<Navigate to="/business/dashboard" replace />}
                />
              </Routes>
            </Box>
          ) : null}
          <Box>
            <Footer />
          </Box>
        </Box>
      </SidebarContext.Provider>
      <Icon
        onClick={() => {
          // This section enables sidebar minimize action for Admin
          // 000010000
          setMini(!mini);
        }}
        fontSize="30px"
        cursor={'pointer'}
        color="gray.400"
        position="absolute"
        as={mini === true ? BiCaretRightCircle : BiCaretLeftCircle}
        zIndex={'999'}
        bottom="2%"
        left={mini === true ? '100px' : '220px'}
      ></Icon>
    </Box>
  );
}
