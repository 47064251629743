import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Text, Flex, Spinner, Alert, AlertDescription, AlertIcon, AlertTitle, useColorModeValue, Badge,} from '@chakra-ui/react';
import { BiLocationPlus } from 'react-icons/bi';
import { getEmailId } from '../../../utils/localStorageIndex';
import { fetchBusinessesListPaginated } from '../../../services/businessServices';
import { MdPhoneInTalk } from 'react-icons/md';

function RecruiterDashboard() {
    const navigate = useNavigate();
    const [loading, isLoading] = useState(true);
    const [bizListing, setBizListing] = useState([]);
    const [noRecordFlag, setNoRecordFlag] = useState(true);  
    const cardBgColorStyle = useColorModeValue('gray.100', 'gray.800'); // {light, dark}
    const borderColorStyle = useColorModeValue('gray.200', 'gray.400'); // {light, dark}
  
    useEffect(() => {
        let requestBody = {
          email: getEmailId(),
          records_per_page: 500,
          page_id: 0,
          records_length: 0,
        };
        fetchBusinessesListPaginated(requestBody)
          .then(res => {
            isLoading(false);
            if (res.data[0].total_records > 0) {
              setBizListing(res.data[0]?.records);
              setNoRecordFlag(false);
            } else {
              setBizListing([]);
              setNoRecordFlag(true);
            }
          })
          .catch(err => {
            isLoading(false);
            setNoRecordFlag(true);
          });
      }, []);

    const navigateToJobList = (business) => {
      navigate('/admin/joblist', {
          state: {
              business: business,
          },
      });
  }

    const BusinessLocation = ({business}) => {
      if (!business.city && !business.state && !business.zip_code && !business.country){
        return <Text ml="2">N/A</Text>
      }

      return (
        <Text ml="2">
          {business.city && `${business.city}, `}{business.state && `${business.state}, `}
          {business.zip_code && `${business.zip_code}, `}
          {business.country && business.country}
        </Text>
      )
    }

    const renderBusinessDataListMain = bizListing.map((data, index) => (
        <Box p="20px" key={index} cursor="pointer"
          _hover={{borderWidth:'0.5px', borderColor:'gray.400'}}
          onClick={() => navigateToJobList(data)}
          bg={cardBgColorStyle} borderWidth={'0.1px'}
          borderColor={borderColorStyle} mb={5} borderRadius={'6px'}
          boxShadow={'0 3px 3px 0 lightgray, 0 1px 2px 0 lightgray'}
        >
          <Flex>
            <Flex direction={'column'} width={'70%'}>
              <Text as={'b'} mb={3} fontSize={20}>{data.business_name ? data.business_name : 'N/A'}</Text>
              <Flex>
                <Flex direction={'column'}>
                  <Text>Active Jobs<Badge ml={1}>({data?.active_jobs_count})</Badge></Text>
                  <Text>Draft Jobs <Badge ml={2}>({data?.draft_jobs_count})</Badge></Text>
                  <Text>Closed Jobs<Badge>({data?.closed_jobs_count})</Badge></Text>
                </Flex>
              </Flex>         
            </Flex>

            <Flex>
                          
              <Flex direction={'column'}>
          
                <Flex mb={2}>
                  <BiLocationPlus />
                  <BusinessLocation business={data} /> 
                </Flex>              

                <Flex mb={2}>
                  <MdPhoneInTalk />
                  <Text ml="2">{data.phone ? data.phone : 'N/A'}</Text>
                </Flex>

                <Text mb={2} fontSize={14}>Added By {data.added_by ? data.added_by : 'N/A'}</Text>

                <Flex flexWrap="wrap">
                  {data?.admin_list !== null &&
                  data?.admin_list !== undefined &&
                  data?.admin_list.length ? (
                    <>
                          <Text fontSize={'14px'} mr="5px">
                            Administrators:
                          </Text>
                          {JSON.parse(data.admin_list).map((adminListData, index) => (
                            <Text
                              mr="2px"
                              mb="2px"
                              key={adminListData.uuid}
                              fontSize={'14px'}
                            >
                              {adminListData.email}
                            </Text>
                          ))}
                        </>
                  ) : null}
                </Flex>
              </Flex>
            </Flex>          

          </Flex>
        
        </Box>
    ));
    
    return (
      <Flex direction="row" pt={{ sm: '125px', lg: '75px' }} justifyContent={'center'}>
          <Flex width={'60%'} direction={'column'}>
              {loading ? (
              <Flex alignItems="center" justifyContent="center" mt="10" h="50vh">
                  <Spinner size="md" />
              </Flex>
              ) : bizListing && bizListing.length > 0 ? (
                  renderBusinessDataListMain
              ) : (
              <Alert borderRadius="8px" status="error" variant="subtle">
                  <Flex>
                  <AlertIcon />
                  <Flex direction="column">
                      <AlertTitle mr="12px">
                      {noRecordFlag ? 'Empty Team List' : 'Unauthorized access..'}
                      </AlertTitle>
                      <AlertDescription>There are no businesses!!</AlertDescription>
                  </Flex>
                  </Flex>
              </Alert>
              )}
          </Flex>
      </Flex>
    );
}
    
export default RecruiterDashboard;
