// chakra imports
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import {
  Spinner,
  Box,
  Flex,
  Stack,
  Text,
  Image,
  useColorModeValue,
} from '@chakra-ui/react';
import logo from '../../../media/images/ts-logo-200x80.png';

//   Custom components
import Links from '../../../components/sidebar/components/Links';
import avatar4 from '../../../assets/img/avatars/dummy-avatar.jpg';
import { getEmailId } from '../../../utils/localStorageIndex';
import { fetchPersonalInfo } from '../../../services/sharedServices';
import { fetchBusinessInformation } from '../../../services/businessServices';
import Logout from '../../defaultLayout/header/Logout/Logout';

// FUNCTIONS

function SidebarContent(props) {
  const emaiId = getEmailId();
  const [loading, isLoading] = useState(true);
  const [businessLogo, setBusinessLogo] = useState('');
  const [userDetails, setUserDetails] = useState({});
  const sideMenuStatus = useSelector(store => store.notificationHeader);
  const { profileLogoFlag, bannerFlag } = sideMenuStatus;

  useEffect(() => {
    isLoading(true);
    let objData = {
      email: emaiId,
    };
    fetchPersonalInfo(objData)
      .then(res => {
        isLoading(false);
        if (res.result) {
          setUserDetails(res.data[0]);
        }
      })
      .catch(err => {
        isLoading(false);
      });
  }, [profileLogoFlag]);

  useEffect(() => {
    let objData = {
      email: emaiId,
    };
    fetchBusinessInformation(objData)
      .then(res => {
        if (res?.result) {
          // console.log('res?.data[0]?.business_logo_url', res?.data[0]?.business_logo_url);
          if (res?.data[0]?.business_logo_url != null) {
            if (res?.data[0]?.business_logo_url.length > 0) {
              setBusinessLogo(res?.data[0]?.business_logo_url);
            } else {
              setBusinessLogo(logo);
            }
          } else {
            setBusinessLogo(logo);
          }
        } else {
          setBusinessLogo(logo);
        }
      })
      .catch(err => {
        console.log(err);
      });
  }, [bannerFlag]);

  const { routes, mini, hovered } = props;
  const textColor = useColorModeValue('navy.700', 'white');
  // SIDEBAR
  return (
    <Flex
      direction="column"
      height="100%"
      pt="25px"
      borderRadius="30px"
      mb="66px"
      w="100%"
    >
      {businessLogo ? (
        <Image
          h="60px"
          w="80%"
          mb="20px"
          src={businessLogo}
          objectFit={'contain'}
        />
      ) : (
        <Text h="60px" w="80%" mb="20px"></Text>
      )}
      <Stack
        direction="column"
        mb="auto"
        mt="8px"
        alignItems={mini ? 'center' : 'normal'}
      >
        <Box
          pe={{ md: '12px', '2xl': '1px' }}
          color={'red'}
          ms={mini && hovered === false ? '-16px' : 'unset'}
        >
          <Links mini={mini} hovered={hovered} routes={routes} />
        </Box>
      </Stack>

      <Flex mt="50px" px="20px" alignItems="center">
        {loading ? (
          <Flex
            alignItems="center"
            justifyContent="center"
            h="50px"
            color="#fff"
          >
            <Spinner size="sm" />
          </Flex>
        ) : (
          <Image
            borderRadius="50px"
            me={
              mini === false
                ? '20px'
                : mini === true && hovered === true
                ? '20px'
                : '0px'
            }
            src={userDetails?.photo_url ? userDetails?.photo_url : avatar4}
            h="48px"
            w="48px"
          ></Image>
        )}
        <Box
          display={
            mini === false
              ? 'block'
              : mini === true && hovered === true
              ? 'block'
              : 'none'
          }
          mb="4"
        >
          <Text color={textColor} fontSize="sm" fontWeight="700">
            {userDetails?.first_name} {userDetails?.last_name}
          </Text>
          <Text color="secondaryGray.600" fontSize="sm" fontWeight="400">
            {userDetails?.group === '100' ? 'Talentstrap Admin' : null}
            {userDetails?.group === '200' ? 'Talentstrap Team' : null}
            {userDetails?.group === '300' ? 'Business Admin' : null}
            {userDetails?.group === '400' ? 'Business Admin' : null}
          </Text>
          <Logout />
        </Box>
      </Flex>
      <Box w={'100%'} bg={'gray.100'}>
        {/* <Text fontSize={'12px'}>Talent Strap Inc.</Text> */}
      </Box>
    </Flex>
  );
}

export default SidebarContent;
