import React, { useEffect, useState } from 'react';
import { Text, Box, Flex, Spinner } from '@chakra-ui/react';
import { getEmailId } from '../../../../../../utils/localStorageIndex';
import { rejectedCandidateInfo } from '../../../../../../services/jobTemplateService';

function RejectionInfo(props) {
  const emailId = getEmailId();
  const { candidate_uuid, job_uuId } = props;
  const [rejectionLoading, setRejectionLoading] = useState(false);
  const [rejectedData, setRejectedData] = useState([]);

  useEffect(() => {
    rejectionInfoDetails();
  }, []);

  const rejectionInfoDetails = () => {
    setRejectionLoading(true);
    let obj = {
      email: emailId,
      job_uuid: job_uuId,
      candidate_uuid: candidate_uuid,
    };

    rejectedCandidateInfo(obj)
      .then(res => {
        setRejectionLoading(false);
        if (res.result) {
          setRejectedData(res?.data[0]);
        } else {
          setRejectedData([]);
        }
      })
      .catch(err => {
        setRejectionLoading(false);
        console.log(err);
      });
  };

  return (
    <Box>
      {rejectionLoading ? (
        <Flex alignItems="center" justifyContent="center" mt="10" h="70vh">
          <Spinner size="xl" />
        </Flex>
      ) : (
        <>
          <Box borderBottom="1px dotted #d7d7d7">
            <Text p="10px" fontSize={16}>
              Rejection Info
            </Text>
          </Box>
          <Box>
            <Flex
              borderBottom="1px dotted #d7d7d7"
              p="10px"
              alignItems="center"
              mt="10px"
              pb="15px"
            >
              <Text mr="10px">Rejection Reason :-</Text>
              <Text>{rejectedData?.candidate_uuid}</Text>
            </Flex>
            <Flex
              borderBottom="1px dotted #d7d7d7"
              p="10px"
              alignItems="center"
              pb="15px"
            >
              <Text mr="10px">Denial Code :-</Text>
              <Text>{rejectedData?.denied_uuid}</Text>
            </Flex>
          </Box>
        </>
      )}
    </Box>
  );
}

export default RejectionInfo;
