export const experienceLevelOptions = [
  { id: 'Not applicable', name: 'Not applicable', isChecked: false },
  { id: 'Internship', name: 'Internship', isChecked: false },
  { id: 'Entry level', name: 'Entry level', isChecked: false },
  { id: 'Associate', name: 'Associate', isChecked: false },
  { id: 'Mid-Senior level', name: 'Mid-Senior level', isChecked: false },
  { id: 'Director', name: 'Director', isChecked: false },
  { id: 'Executive', name: 'Executive', isChecked: false },
];

export const educationRequiredOptions = [
  { id: 'Not Applicable', name: 'Not Applicable' },
  {
    id: 'High-school Diploma or Equivalent',
    name: 'High-school Diploma or Equivalent',
    isChecked: false,
  },
  { id: 'Some College', name: 'Some College', isChecked: false },
  {
    id: 'Associates Degree',
    name: 'Associates Degree',
    isChecked: false,
  },
  { id: 'Bachelors Degree', name: 'Bachelors Degree', isChecked: false },
  { id: 'Masters Degree', name: 'Masters Degree', isChecked: false },
  { id: 'Doctorate', name: 'Doctorate', isChecked: false },
];

export const supplimentalChoicesOptions = [
  { id: 'Commission Pay', name: 'Commission Pay' },
  {
    id: 'Bonus Pay',
    name: 'Bonus Pay',
  },
  { id: 'Signing Bonus', name: 'Signing Bonus' },
  {
    id: 'Monthly Bonus',
    name: 'Monthly Bonus',
  },
];

export const benifitsDropdownOptions = [
  { id: 'Health Insurance', name: 'Health Insurance', isChecked: false },
  { id: 'Life Insurance', name: 'Life Insurance', isChecked: false },
  {
    id: 'Relocation Assistance',
    name: 'Relocation Assistance',
    isChecked: false,
  },
  { id: 'Paid Time-Off', name: 'Paid Time-Off', isChecked: false },
  { id: 'Parental Leave', name: 'Parental Leave', isChecked: false },
  { id: 'Dental Insurance', name: 'Dental Insurance', isChecked: false },
  { id: 'Retirement Plan', name: 'Retirement Plan', isChecked: false },
  {
    id: 'Professional Development Assistance',
    name: 'Professional Development Assistance',
    isChecked: false,
  },
  { id: 'Vision Insurance', name: 'Vision Insurance', isChecked: false },
  {
    id: 'Employee Discount',
    name: 'Employee Discount',
    isChecked: false,
  },
  {
    id: 'Employee Assistance Program',
    name: 'Employee Assistance Program',
    isChecked: false,
  },
  {
    id: 'Flexible Schedule',
    name: 'Flexible Schedule',
    isChecked: false,
  },
  {
    id: 'Flexible Spending',
    name: 'Flexible Spending',
    isChecked: false,
  },
  {
    id: 'Tuition Reimbursement',
    name: 'Tuition Reimbursement',
    isChecked: false,
  },
  {
    id: 'Health Savings Account',
    name: 'Health Savings Account',
    isChecked: false,
  },
  { id: 'Other', name: 'Other', isChecked: false },
];

export const employementTypeOptions = [
  { id: 'Full-Time', value: 'Full-Time' },
  { id: 'Part-Time', value: 'Part-Time' },
  { id: 'Contract', value: 'Contract' },
  { id: 'Temporary', value: 'Temporary' },
  { id: 'Internship', value: 'Internship' },
  // { id: 'Volunteer', value: 'Volunteer' },
];

export const employeeSalaryRate = [
  { id: 'per hour', value: 'per hour' },
  {
    id: 'per day',
    value: 'per day',
  },
  {
    id: 'per week',
    value: 'per week',
  },
  { id: 'per month', value: 'per month' },
  {
    id: 'per year',
    value: 'per year',
  },
];
