import axios from 'axios';
import {
  getSessionId,
  getEmailId,
  getAuthToken,
  clearLocalStorage,
} from '../utils/localStorageIndex';
// import { getSessionId, getEmailId, getAuthToken, clearAppSessionData } from '../utils/localStorageIndex';

function setBaseUrl() {
  var baseURL;
  console.log(process.env.REACT_APP_TALENT_STRAP_DEPLOYENT_TYPE);
  if (process.env.REACT_APP_TALENT_STRAP_DEPLOYENT_TYPE === 'aws') {
    console.log(process.env.REACT_APP_BASE_URL_AWS);
    baseURL = process.env.REACT_APP_BASE_URL_AWS;
  } else {
    console.log(process.env.REACT_APP_BASE_URL_LOCAL);
    baseURL = process.env.REACT_APP_BASE_URL_LOCAL;
  }
  if (!baseURL) {
    // baseURL = 'http://ec2-35-172-199-112.compute-1.amazonaws.com:8010/';
    baseURL = 'http://localhost:8010/';
  }
  return baseURL;
}

function setBaseUrlFixed() {
  var baseURL = 'http://ec2-35-172-199-112.compute-1.amazonaws.com:8010/';
  return baseURL;
}

// Generate axios instance
const instance = axios.create({
  // Change 1: Also chage app.js to reflect Nylas Server URL
  // Change 2: Change below baseurl
  // baseURL: "https://talentstrap.ai",
  baseURL: process.env.REACT_APP_TALENT_STRAP_API_URL,
  // baseURL: setBaseUrlFixed(),
});

instance.interceptors.request.use(
  async requestConfig => {
    let token = await getAuthToken(); // Retrieve the token
    if (token && token.trim().length > 0) {
      requestConfig.headers['Authorization'] = `Bearer ${token}`; // Use the token in the request headers
    }
    return requestConfig;
  },
  error => {
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  response => {
    return response.data;
  },
  error => {
    if (error.response.status === 401) {
      clearLocalStorage();
      window.location = '/';
    } else if (error.response.status === 404) {
      window.location = '/';
    }else if (error.response.status === 422) { // pydantic validation faild
     return {status: error?.response?.status, message: `${error?.message}. ${JSON.stringify(error?.response?.data?.detail)}`}
    }

    throw error;
    // return Promise.reject(error.response);
  }
);

// clearbit.com for companies logo
export const clearbitAxios = axios.create({
  baseURL: 'https://company.clearbit.com/v1/domains/',
});
clearbitAxios.interceptors.request.use(
  async requestConfig => {
    requestConfig.headers['Authorization'] = `Bearer ${process.env.REACT_APP_CLEARBIT_API_KEY}`;
    return requestConfig;
  },
  error => {
    return Promise.reject(error);
  }
);

export default instance;
