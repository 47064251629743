import React from 'react';
import { AiFillCaretDown } from 'react-icons/ai';
import {
  themeFontSizeLargeHeading,
  themeFontSizeOddHeading,
} from '../../../../constants/index';
import {
  Th,
  Td,
  Tr,
  Menu,
  Text,
  Thead,
  Tbody,
  Table,
  MenuItem,
  MenuList,
  IconButton,
  MenuButton,
  TableContainer,
} from '@chakra-ui/react';

function ClientList() {
  const headerTitleData = [
    { label: 'First Name', value: 'first_name' },
    { label: 'Last Name', value: 'last_name' },
    { label: 'Email', value: 'email' },
  ];

  const tableData = [
    { first_name: 'John', last_name: 'Doe', email: 'johnDoe@talentstrap.com' },
    { first_name: 'Jane', last_name: 'Doe', email: 'janeDoe@talentstrap.com' },
    {
      first_name: 'Jimmy',
      last_name: 'Jackson',
      email: 'jimmy@talentstrap.com',
    },
    {
      first_name: 'Eric',
      last_name: 'Stantske',
      email: 'Eric@talentstrap.com',
    },
    {
      first_name: 'Stephen',
      last_name: 'Johnson',
      email: 'stepJohnson@talentstrap.com',
    },
    {
      first_name: 'Michael',
      last_name: 'Clark',
      email: 'Michael@talentstrap.com',
    },
  ];

  const renderTableHeader = headerTitleData?.map((data, index) => (
    <Th
      color="#b0b0b0"
      fontWeight="normal"
      fontSize={themeFontSizeOddHeading}
      borderBottomWidth="0.5px"
      borderBottomColor="#494552"
      borderRightWidth="1px"
      borderRightColor="#494552"
      key={index}
    >
      {data.label}
    </Th>
  ));

  const renderTableData = tableData.map((data, index) => (
    <Tr bg="#1c181e">
      <Td
        color="#b0b0b0"
        borderBottomWidth="0.5px"
        borderBottomColor="#494552"
        fontWeight="normal"
        fontSize={themeFontSizeOddHeading}
      >
        {index + 1}
      </Td>
      {headerTitleData?.map((headerData, idx) => (
        <Td
          color="#b0b0b0"
          borderBottomWidth="0.5px"
          borderBottomColor="#494552"
          fontWeight="normal"
          fontSize={themeFontSizeOddHeading}
          key={idx * 2}
        >
          {data[headerData.value] ? data[headerData.value] : '-'}
        </Td>
      ))}
      <Td
        color="#b0b0b0"
        borderBottomWidth="0.5px"
        borderBottomColor="#494552"
        fontWeight="normal"
        fontSize={themeFontSizeOddHeading}
      >
        <Menu size="sm">
          <MenuButton
            as={IconButton}
            aria-label="Options"
            icon={<AiFillCaretDown fontSize={themeFontSizeLargeHeading} />}
            variant="normal"
            height="15px"
          />
          <MenuList>
            <MenuItem>Connect Directly</MenuItem>
            <MenuItem>SQL Editor</MenuItem>
            <MenuItem>Detach/Attach Database</MenuItem>
            <MenuItem>View Workspace Details</MenuItem>
            <MenuItem>Resize Workspace Details</MenuItem>
            <MenuItem>Suspend Workspace Details</MenuItem>
            <MenuItem>
              <Text color="red">Suspend Workspace Details</Text>
            </MenuItem>
          </MenuList>
        </Menu>
      </Td>
    </Tr>
  ));

  return (
    <TableContainer>
      <Table size="sm">
        <Thead>
          <Tr>
            <Th
              color="#b0b0b0"
              fontWeight="normal"
              fontSize={themeFontSizeOddHeading}
              borderRightWidth="1px"
              borderRightColor="#494552"
              borderBottomWidth="0.5px"
              borderBottomColor="#494552"
              p="10px"
            >
              Sr No
            </Th>
            {renderTableHeader}
            <Th
              color="#b0b0b0"
              fontWeight="normal"
              fontSize={themeFontSizeOddHeading}
              borderRightWidth="1px"
              borderRightColor="#494552"
              borderBottomWidth="0.5px"
              borderBottomColor="#494552"
              p="10px"
            >
              Actions
            </Th>
          </Tr>
        </Thead>
        <Tbody>{renderTableData}</Tbody>
      </Table>
    </TableContainer>
  );
}

export default ClientList;
