import React from 'react';
import Autocomplete from 'react-google-autocomplete';

function GoogleLocation(props) {
  const googleLocationKey = process.env.REACT_APP_GOOGLE_LOCATION_KEY;

  // types: ['(cities)'] -> City level API
  // types: ["establishment", "geocode"] -> Street Level API

  return (
    <Autocomplete
      apiKey={googleLocationKey}
      value={props?.locationValue}
      placeholder={props?.placeholder ? props?.placeholder : 'Street Address'}
      onPlaceSelected={place => {
        props.sendSelectedLocation(place.formatted_address);
      }}
      options={{
        types: props?.types, //["establishment", "geocode"],
        componentRestrictions: { country: 'usa' },
      }}
      onChange={e => {
        props.sendSelectedLocation(e.target.value);
      }}
      style={{
        width: props?.width ? props?.width : '60%',
        border: props?.border ? props?.border : '1px solid #e2e8f0',
        padding: '10px 15px',
        borderRadius: props?.borderRadius ? props?.borderRadius : '3px',
        background: props?.bg ? props?.bg : 'none',
        fontSize: props?.fontSize ? props?.fontSize : '12px',
        color: props?.color ? props?.color : '#aeb8d6',
        marginTop: props?.marginTop ? props?.marginTop : '10px',
      }}
    />
  );
}

export default GoogleLocation;
