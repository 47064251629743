import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {Drawer,DrawerContent,Text,Flex, Spinner,
  useColorModeValue,useDisclosure, useToast, Checkbox, DrawerHeader, DrawerCloseButton, Button, Tooltip
} from '@chakra-ui/react';
import { getEmailId } from '../../../utils/localStorageIndex';
import { workflowStagesMapping } from '../../../utils/common.js';
import moment from 'moment';
import DisqualifyCandidate from './components/DisqualifyCandidate.js';
import EnrichCandidate from './components/EnrichCandidate.js';
import UploadResumeForCandidate from './components/UploadResumeForCandidate.js';
import DeleteCandidate from './components/DeleteCandidate.js';
import AssignCandidateToJob from './components/AssignCandidateToJob.js';
import CandidateApplySource from '../../common/CandidateApplySource.js';
import CandidateHeadshot from '../../common/CandidateHeadshot.js';
import SendEmail from './components/SendEmail.js';
import UploadHeadshotForCandidate from './components/UploadHeadshotForCandidate.js';
import { fetchAllCandidates, fetchAllJobs } from '../../../services/recruiterServices.js';
import Pagination from './components/pagination.js';
import {components} from 'react-select';
import AsyncSelect from 'react-select/async';
import Select from 'react-select';
import MoveCandidateToOtherStage from './components/MoveCandidateToOtherStage.js';
import CandidateHeadlines from './components/CandidateHeadlines.js';
import RejectCandidate from '../../business/jobWizard/jobWorkflow/Workflow/CandidateSearch/RejectCandidate.js';
import QualifyCandidate from './components/QualifyCandidate.js';


function CandidateList() {
  const emailId = getEmailId();
  const navigate = useNavigate();
  const { state } = useLocation();
  const adminSettings = true // state?.adminSettings ? state?.adminSettings : false;
  const toast = useToast();
  const [candidatesLoading, setcandidatesLoading] = useState(false);
  const [allCandidates, setAllCandidates] = useState([]);
  const [selectedJob, setSelectedJob] = useState({})
  const selectedBusiness = state?.business
  const [selectedCandidate, setSelectedCandidate] = useState({});
  const [bulkSelectedCandidates, setBulkSelectedCandidates] = useState([])
  const [selectedCandidateObjects, setSelectedCandidateObjects] = useState([])
  const { isOpen: isRejectionPanelOpen, onOpen: onRejectionPanelOpen, onClose: onRejectionPanelClose } = useDisclosure();
  const [searchQuery, setSearchQuery] = useState(null);
  const recordsPerPage = 10;
  const [totalRecords, setTotalRecords] = useState(null)
  const [currentPage, setCurrentPage] = useState(1);
  const [jobSelectOptions, setJobSelectOptions] = useState([])
  const [selectedJobsUuid, setselectedJobsUuid] = useState([])
  const [jobFunctionOptions, setJobFunctionOptions] = useState([])
  const [selectedJobFunctions, setSelectedJobFunctions] = useState([])
  const [candidateStageOptions, setCandidateStageOptions] = useState(() => {
    const stages = []
    for (const [stageKey, stageLabel] of Object.entries(workflowStagesMapping)){
      stages.push({label: stageLabel, value: stageKey })
    }
    return stages
  })
  const [candidateSelectedStages, setCandidateSelectedStages] = useState([])
  const [considerationOptions, setConsiderationOptions] = useState(() => {
    return [
      {label: 'Qualified', value: 'qualified'},
      {label: 'Disqualified', value: 'disqualified'}
    ]
  })
  const [selectedConsiderations, setSelectedConsiderations] = useState([])
  const borderColorStyle = useColorModeValue('gray.200', 'gray.400'); // {light, dark}

  const searchBarDropDownStyles = {
    control: (baseStyles, state) => ({
      ...baseStyles,
      width: '500px',
      marginRight: '20px',
      padding: '2px',
      cursor: 'text'
    }),
    menu: styles => ({ 
      ...styles,
      zIndex: 99999,
      width: '500px'
     }),
    option: (styles, state) => ({
      ...styles,
      cursor: 'pointer',
    }),
  }

  const dropdownDefaultStyles = {
    control: (baseStyles, state) => ({
      ...baseStyles,
      width: '280px',
      marginRight: '10px'
    }),
    menu: styles => ({ ...styles, zIndex: 999 }),
    option: (styles, state) => ({
      ...styles,
      cursor: 'pointer',
    }),
  }

  useEffect(() => {
    getAllCandidates()
    getAllJobs()
 }, [])

 useEffect(() => {
   getAllCandidates();
   onBulkCandidateSelectAll(false)
 }, [currentPage]);

 useEffect(() => {
  getAllCandidates()
}, [selectedJobsUuid, selectedJobFunctions, candidateSelectedStages, selectedConsiderations])

  useEffect(() => {
    setSelectedCandidateObjects(getCandidateListFromCandidateUUIDs(bulkSelectedCandidates))
  }, [bulkSelectedCandidates])

  const CandidateOptionComponent = (props) => {
  const candidate = props.data.value
    return (
      <components.Option {...props}>
        <span>
          <Flex>                
            <CandidateHeadshot candidate={candidate} width={'40px'} height={'40px'} />
            <Flex direction={'column'}>
              <Text as='b'>{candidate?.first_name} {candidate?.last_name}</Text>
              <Text>in {candidate?.job?.job_title} - {workflowStagesMapping[candidate?.workflow?.workflow_position]}</Text>
          </Flex>
          </Flex>            
        </span>
      </components.Option>
    );
  };
  
  const JobOptionComponent = (props) => {
    const job = props.data.value
      return (
        <components.Option {...props}>
          <span>
            <Flex>                
              <Flex direction={'column'}>
                <Text as={'b'}>{job?.job_title}</Text>
                <JobFunctionAndLocation job={job} />
            </Flex>
            </Flex>            
          </span>
        </components.Option>
      );
  };

  const searchCandidates = async (searchQuery) => {
    const searchPayload = {
        email: emailId,
        q: searchQuery,
    }
    return fetchAllCandidates(searchPayload).then(res => {
      if (res?.result){
        return res?.data?.records?.map(candidate => {
          return {label: `${candidate?.first_name} ${candidate?.last_name}`, value: candidate}
        })
      }else{
        toast({
          title: 'Unable to search candidate',
          description: res?.message,
          status: 'error',
          duration: 5000
      })
      }         
    }).catch(err => {
        toast({
            title: 'Unable to search candidate',
            description: err?.toString(),
            status: 'error',
            duration: 5000
        })
    })
  };

  const onChangeSearchQuery = (value, action) => {
    const inputValue = value ? value.replace(/\\\\W/g, '') : '';    
    if (action.action === "input-change"){
      setSearchQuery(value)
    }
    return inputValue;
  };

  const onSelectCandidate = (selected) => {
    const candidate = selected?.value
    routeToSelectedCandidate(candidate)
  }

  const getAllCandidates = () => {
    setcandidatesLoading(true);
    const objBody = {
      email: emailId,
      q: searchQuery,
      filter_by_job: selectedJobsUuid,
      filter_by_job_function: selectedJobFunctions,
      filter_by_stage: candidateSelectedStages,
      filter_by_consideration: selectedConsiderations,
      records_per_page: recordsPerPage,
      page_id: currentPage,
    };
    fetchAllCandidates(objBody)
      .then(res => {        
        if (res?.result) {
          setAllCandidates([...res?.data?.records || []]);
          setTotalRecords(res?.data?.total_records)
        }else{
          toast({
            title: 'Unable to load candidates for this job',
            description: res?.message,
            status: 'error',
            duration: 3000,
          });
        }
        setcandidatesLoading(false);
      })
      .catch(err => {
        setcandidatesLoading(false);
        toast({
          title: 'Unable to load candidates for this job',
          description: err.toString(),
          status: 'error',
          duration: 3000,
        });
      });
  };

  const getAllJobs = () => {
    let objData = {
      records_per_page: 100,
      page_id: 1,
    };
    fetchAllJobs(objData)
      .then(res => {
        if (res?.data?.records?.length > 0 && res?.result) {
          const jobSelectOptions = res?.data?.records?.map(job => {
            return {label: job?.job_title, value: job}
          })

          let jobFunctions = []
          res?.data?.records?.map(job => {
            if (job?.job_function){
              jobFunctions.push(job?.job_function)
            }
          })
          
          jobFunctions = [...new Set(jobFunctions)]
          
          const functionOptions = jobFunctions?.map(jobFunction => {
            return {label: jobFunction, value: jobFunction}
          })
          functionOptions.sort((a, b) => a.value.localeCompare(b.value))

          setJobSelectOptions(jobSelectOptions)
          setJobFunctionOptions(functionOptions)

        }
        else{
          toast({
            title: 'Unable to get jobs',
            description: res?.message,
            status: 'error',
            duration: 3000,
          });
        }
      })
      .catch(err => {
        toast({
          title: 'unable to get jobs',
          description: err?.toString(),
          status: 'error',
          duration: 5000,
        });
      });
  };

  const onBulkCandidateSelect = (candidate, isChecked) => {
    const updatedBulkCandidates = bulkSelectedCandidates
    if (isChecked && !updatedBulkCandidates.includes(candidate?.candidate_uuid)){
      updatedBulkCandidates.push(candidate?.candidate_uuid)
    }

    if (!isChecked && updatedBulkCandidates.includes(candidate?.candidate_uuid)){
      updatedBulkCandidates.splice(updatedBulkCandidates.indexOf(candidate?.candidate_uuid), 1)
    }

    setBulkSelectedCandidates([...updatedBulkCandidates])
  }

  const onBulkCandidateSelectAll = (isChecked) => {
    if (isChecked){
      const allSelectedCandidates = allCandidates.map(candidate => candidate?.candidate_uuid)
      setBulkSelectedCandidates([...allSelectedCandidates])
    }

    if (!isChecked){
      setBulkSelectedCandidates([])
    }
  }

  const unsetSelectedCandidate = () => {
    setSelectedCandidate({})
  }

  const getCandidateListFromCandidateUUIDs = (candidateUuids) => {
    return allCandidates.filter(candidate => candidateUuids.includes(candidate?.candidate_uuid))
  }

  const routeToSelectedCandidate = (candidate) => {
    navigate('/admin/searchedcandidate', {
      state: {
          candidate: candidate,
          adminSettings: true
      },
    });
  }

  const CandidateCurrentStage = ({candidate}) => {
    let currentStage = ""
    if (candidate?.workflow?.workflow_position){
      currentStage = candidate?.workflow?.workflow_position
    }

    return currentStage ? <Text>At <b>{workflowStagesMapping[currentStage]}</b> stage {candidate?.workflow?.is_disqualified ? ' - Disqualified' : null}</Text> : null
  }

  const JobFunctionAndLocation = ({job}) => {
    let text = ""
    if (job?.job_function && job?.job_location){
      text = `${job?.job_function} - ${job?.job_location}`
    }
    else if (job?.job_function){
      text = job?.job_function
    }

    else if (job?.job_location){
      text = job?.job_location
    }
    
    return <Text mt={2} mb={2}>{text}</Text>
  }

  const CandidateJobDetails = ({candidate, index}) => {
    const job = candidate?.job
    return <>
      <Flex direction={'column'} p={5} borderLeft={'0.1px solid'} borderLeftColor={borderColorStyle}>
        <Text as={'b'}>{job?.job_title}</Text>
        <JobFunctionAndLocation job={job} />
        <CandidateCurrentStage candidate={candidate} />
        <Flex mt={2}>
          <CandidateApplySource candidate={candidate} /> - <Text>{moment.utc(candidate?.created_at).fromNow()}</Text>
        </Flex>        
      </Flex>
    </>
  }

  const candidateList = () => {
    if (candidatesLoading){
        return <>
          <Flex alignItems={'center'} justifyContent={'center'}><Spinner /></Flex>
        </>
    }

    return <Flex direction={'column'} overflowY="auto">
      {allCandidates.map((candidate, index) => {
          return <>
            <Flex _hover={{'bg': '#fffadf', 'cursor': 'pointer'}} borderBottom={'0.1px solid'} borderBottomColor={borderColorStyle} pl={4}>
              <Checkbox mr={5} isChecked={bulkSelectedCandidates.includes(candidate?.candidate_uuid)} onChange={(e) => onBulkCandidateSelect(candidate, e.target.checked)} size={'lg'} />
              <Flex width={'100%'} justifyItems={'center'} alignItems={'center'} onClick={() => routeToSelectedCandidate(candidate)} key={index}>
                <Flex width={'60%'}>
                <CandidateHeadlines candidate={candidate} applyShadow={false} headshotWidth={'60px'} headshotHeight={'60px'}
                  headshotRadius={'60px'} mTop={0} candidateNameFontSize={'18px'}/>
                </Flex>
                <CandidateJobDetails candidate={candidate} index={index} />
              </Flex>
            </Flex>
          </>
      })} 
    </Flex>
  }

  const CandidateActions = () => {
    const selectedCandidates = getCandidateListFromCandidateUUIDs([...bulkSelectedCandidates])
    let job = {}
    if (selectedCandidates?.length > 0){
      job = selectedCandidates[0]?.job
    }
    return <>
      <Flex justifyContent={'right'} p={'3px 8px'} borderWidth={0} borderColor={borderColorStyle}
        borderRadius={'2px'} boxShadow={'0 0px 0px 0 lightgray,0 0px 5px 0 lightgray'} alignItems={'center'}
      >

        <UploadResumeForCandidate
         job={job}
         adminSettings={adminSettings}
         bulkSelectedCandidates={selectedCandidates}
         postUploadActions={{
          getAllCandidates: () => getAllCandidates()
        }}
        />

        <UploadHeadshotForCandidate
         job={job}
         adminSettings={adminSettings}
         refreshCandidateList={getAllCandidates}
         bulkSelectedCandidates={selectedCandidates}
         postUploadActions={{
          getAllCandidates: () => getAllCandidates()
        }}
        />

        <EnrichCandidate
         adminSettings={adminSettings}
         bulkSelectedCandidates={selectedCandidates}
         postEnrichActions={{
          getAllCandidates: () => getAllCandidates()
        }}
        />

        <AssignCandidateToJob
         job={job}
         adminSettings={adminSettings}
         bulkSelectedCandidates={selectedCandidates}
         postAssignActions={{
          getAllCandidates: () => getAllCandidates(),
          onBulkCandidateSelectAll: () => onBulkCandidateSelectAll(false)
        }}
        />

        {adminSettings &&
        <SendEmail
          selectedCandidates={selectedCandidates}
          postEmailActions={{
                              getAllCandidates: () => getAllCandidates(),
                              onBulkCandidateSelectAll: () => onBulkCandidateSelectAll(false)
                            }}
        />
        }
      

        <DisqualifyCandidate
          bulkSelectedCandidates={selectedCandidates}
          postDisqualifyActions={{
            getAllCandidates: () => getAllCandidates(),
            onBulkCandidateSelectAll: () => onBulkCandidateSelectAll(false)
          }}
        />

        <QualifyCandidate
          bulkSelectedCandidates={selectedCandidates}
          postQualifyActions={{
            getAllCandidates: () => getAllCandidates(),
            onBulkCandidateSelectAll: () => onBulkCandidateSelectAll(false)
          }}
        />

        <DeleteCandidate
          bulkSelectedCandidates={selectedCandidates}
          postDeleteActions={{
            getAllCandidates: () => getAllCandidates(),
            onBulkCandidateSelectAll: () => onBulkCandidateSelectAll(false),
            unsetSelectedCandidate: () => unsetSelectedCandidate(false)
          }}
        />

        <MoveCandidateToOtherStage
          job={job}
          bulkSelectedCandidates={selectedCandidates}
          onRejectionPanelOpen={onRejectionPanelOpen}
          postMoveActions={{
            getAllCandidates: () => getAllCandidates(),
            onBulkCandidateSelectAll: () => onBulkCandidateSelectAll(false),
            unsetSelectedCandidate: () => unsetSelectedCandidate(false)
          }}
        />
      </Flex>
    </>
  }

  const onCandidateRejectionSuccess = () => {
      getAllCandidates();
      onRejectionPanelClose();
  };

  const onSelectJob = (selected) => {
    const selecedJobs = selected
    const jobsUuid = selecedJobs?.map(selectedJob => selectedJob?.value?.job_uuid) || []
    setselectedJobsUuid([...jobsUuid])
  }

  const onSelectJobFunction = (selected) => {
    const jobFunctions = selected?.map(jobFunction => jobFunction?.value)
    setSelectedJobFunctions(jobFunctions)
  }

  const onSelectCandidateStage = (selected) => {
    const candidateStages = selected?.map(stage => stage?.value)
    setCandidateSelectedStages(candidateStages)
  }

  const onSelectConsideration= (selected) => {
    const considerations = selected?.map(consideration => consideration?.value)
    setSelectedConsiderations(considerations)
  }

  const SearchFilters = () => {
    return <>
      <Flex direction={'column'} mt={10} mb={10}>
        <Flex>
          <AsyncSelect
              onInputChange={(value, action) => onChangeSearchQuery(value, action)}
              onChange={onSelectCandidate}
              inputValue={searchQuery}
              value={searchQuery}
              styles={searchBarDropDownStyles}
              placeholder= 'Search all candidates using keywords'
              noOptionsMessage={() => "Please try a keyword to match candidate name, email, phone, current job, highest education, current company or linked profile URL."}
              loadOptions={searchCandidates}
              components={{ Option: CandidateOptionComponent }}
          />
          <Button onClick={getAllCandidates} variant={'brand'} width={'150px'} borderRadius={'10px'}>Search</Button>
        </Flex>          
        <Flex mt={5}>
          <Select
              placeholder= 'Select job'
              onChange={onSelectJob}
              components={{ Option: JobOptionComponent }}
              options={jobSelectOptions}
              styles={dropdownDefaultStyles}
              isMulti
              closeMenuOnSelect={false}
          />
          <Select
              placeholder= 'Select job function'
              onChange={onSelectJobFunction}
              options={jobFunctionOptions}
              styles={dropdownDefaultStyles}
              isMulti
              closeMenuOnSelect={false}
          />
          <Select
              placeholder= 'Select candidate stage'
              onChange={onSelectCandidateStage}
              options={candidateStageOptions}
              styles={dropdownDefaultStyles}
              isMulti
              closeMenuOnSelect={false}
          />
          <Select
              placeholder= 'Select consideration'
              onChange={onSelectConsideration}
              options={considerationOptions}
              styles={dropdownDefaultStyles}
              isMulti
              closeMenuOnSelect={false}
          />
        </Flex>
      </Flex>
    </>
    
  }

  return(
    <Flex direction={'column'} pt={{ base: '180px', md: '80px', xl: '80px' }} width={{'base': '400px', 'sm': '600px', 'md': '800px', 'lg': '900px', '2xl': '1200px', '3xl': '1400px'}}>

      {SearchFilters()}

      {CandidateActions()}

      <Text as={'b'} ml={1} mt={8} fontSize={'16px'}>{totalRecords} Candidates</Text>

      <Flex width={'100%'} mt={3} direction={'column'} borderRadius={'6px'} borderColor={borderColorStyle} boxShadow={'0 0px 0px 0 lightgray,0 1px 4px 0 lightgray'}>        

        {/* candidate list headers */}
        <Flex alignItems={'center'} pt={5} pb={5} pl={4}>
          <Checkbox mr={5} isChecked={bulkSelectedCandidates.length > 0 && bulkSelectedCandidates.length == allCandidates.length} onChange={(e) => onBulkCandidateSelectAll(e.target.checked)} size={'lg'} />
          <Text as={'b'} width={'60%'} ml={'25px'}>Candidate Information</Text>
          <Text as={'b'}>Job Status</Text>
        </Flex>

        {candidateList()}
        
        <Pagination totalRows={totalRecords} pageChangeHandler={setCurrentPage} page={currentPage} rowsPerPage={recordsPerPage} />
      </Flex>

    {/* Rejection panel */}
        <RejectCandidate
          selectedCandidates={selectedCandidateObjects}
          isRejectionPanelOpen={isRejectionPanelOpen}
          onRejectionPanelClose={onRejectionPanelClose}
          postRejectActions={{
            onCandidateRejectionSuccess: () => onCandidateRejectionSuccess()
          }}
        />
    </Flex>
  )
}

export default CandidateList;
