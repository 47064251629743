import React, { useState, useEffect } from 'react';
import { Icon, Flex, Link as ChakraLink, Box, Image } from '@chakra-ui/react';
import { Link as ReactRouterLink } from 'react-router-dom';
import {
  AiFillGithub,
  AiOutlineInstagram,
  AiFillBehanceCircle,
  AiFillDribbbleCircle,
} from 'react-icons/ai';

import { BsDribbble, BsPinterest, BsStackOverflow, BsTwitterX } from 'react-icons/bs';
import { FaKaggle, FaAngellist, FaPinterestSquare, FaDribbble } from 'react-icons/fa';
import { BiLink, BiLogoDribbble, BiLogoFacebookCircle, BiLogoPinterest } from 'react-icons/bi';
import CrunchbaseLogo from '../../../src/image/crunchbase_logo.jpeg'
import { RiDribbbleFill, RiPinterestFill } from 'react-icons/ri';

function SocialIcon(props) {
  const [showTooltip, setShowTooltip] = useState(false);

  const socialData = props?.socialData ? props?.socialData : null;

  const FixSocialLinks = (linkUrl) => {
    if (linkUrl.startsWith('http') === false){
      linkUrl = "https://" + linkUrl;
      return linkUrl;
    } else {
      return linkUrl;
    }
  }
  return (
    <Flex alignItems="center" mt={props?.mt ? props?.mt : null}>
      {socialData?.github?.length && socialData?.github?.includes('github') ? (
        <ChakraLink as={ReactRouterLink} isExternal to={FixSocialLinks(socialData?.github)}>
          {/* <Tooltip placement='bottom' isOpen={showTooltip} hasArrow fontSize='md' label={socialData?.github}> */}
            <Icon as={AiFillGithub} fontSize="20px" cursor="pointer" mr="1" />
          {/* </Tooltip> */}
        </ChakraLink>
      ) : null}

      {socialData?.twitter?.length && socialData?.twitter?.includes('twitter') ? (
        <ChakraLink as={ReactRouterLink} isExternal to={FixSocialLinks(socialData?.twitter)}>
          {/* <Tooltip placement="top" isOpen={showTooltip} hasArrow fontSize='md' label={socialData?.twitter}> */}
          <Icon
            as={BsTwitterX}
            onMouseEnter={() => {
              setShowTooltip(true);
            }}
            onMouseLeave={() => {
              setShowTooltip(false);
            }}
            fontSize={'14px'}
            cursor="pointer"
            mr="1"
          />
          {/* </Tooltip> */}
        </ChakraLink>
      ) : null}

      {socialData?.facebook?.length && socialData?.facebook?.includes('facebook') ? (
        <ChakraLink as={ReactRouterLink} isExternal to={FixSocialLinks(socialData?.facebook)}>
          <Icon
            as={BiLogoFacebookCircle}
            color="blue.400"
            fontSize={'20'}
            cursor="pointer"
            mr="1"
          />
        </ChakraLink>
      ) : null}

      {socialData?.instagram?.length && socialData?.instagram?.includes('instagram') ? (
        <ChakraLink as={ReactRouterLink} isExternal to={FixSocialLinks(socialData?.instagram)}>
          <Icon
            as={AiOutlineInstagram}
            color="pink.500"
            fontSize={'20px'}
            cursor="pointer"
            mr="1"
          />
        </ChakraLink>
      ) : null}

      {socialData?.kaggle?.length && socialData?.kaggle?.includes('http') ? (
        <ChakraLink as={ReactRouterLink} isExternal to={FixSocialLinks(socialData?.kaggle)}>
          <Icon as={FaKaggle} fontSize={'16px'} color={'#20BEFF'} cursor="pointer" mr="1" />
        </ChakraLink>
      ) : null}

      {socialData?.stackoverflow?.length  && socialData?.stackoverflow?.includes('stackoverflow') ? (
        <ChakraLink
          as={ReactRouterLink}
          isExternal
          to={FixSocialLinks(socialData?.stackoverflow)}
        >
          <Icon
            as={BsStackOverflow}
            fontSize={'18px'}
            cursor="pointer"
            mr="1"
            color="orange.600"
          />
        </ChakraLink>
      ) : null}

      {socialData?.angellist?.length && socialData?.angellist?.includes('angellist') ? (
        <ChakraLink as={ReactRouterLink} isExternal to={FixSocialLinks(socialData?.angellist)}>
          <Icon as={FaAngellist} fontSize={'18px'} cursor="pointer" mr="1" />
        </ChakraLink>
      ) : null}

      {socialData?.website?.length && socialData?.website?.includes('website') ? (
        <ChakraLink as={ReactRouterLink} isExternal to={FixSocialLinks(socialData?.website)}>
          <Icon as={BiLink} fontSize={'22px'} cursor="pointer" mr="1" />
        </ChakraLink>
      ) : null}

      {socialData?.behance?.length && socialData?.behance?.includes('behance') ? (
        <ChakraLink as={ReactRouterLink} isExternal to={FixSocialLinks(socialData?.behance)}>
          <Icon as={AiFillBehanceCircle} fontSize={'22px'} color={'#0057FF'} cursor="pointer" mr="1" />
        </ChakraLink>
      ) : null}

      {socialData?.crunchbase?.length && socialData?.crunchbase?.includes('crunchbase') ? (
        <ChakraLink mr={1} as={ReactRouterLink} isExternal to={FixSocialLinks(socialData?.crunchbase)}>
          <Flex width={'20px'} height={'20px'} alignItems={'center'} mt={-1.5}>
            <Image
                borderRadius='full'
                boxSize='20px'
                objectFit={'contain'}
                bgColor={'gray.200'}
                src={CrunchbaseLogo}
                />
            </Flex>
        </ChakraLink>
      ) : null}

      {socialData?.pinterest?.length && socialData?.pinterest?.includes('pinterest') ? (
        <ChakraLink as={ReactRouterLink} isExternal to={FixSocialLinks(socialData?.pinterest)}>
          <Icon as={RiPinterestFill} fontSize={'24px'} color={'#B7081B'} cursor="pointer" mr="1" />
        </ChakraLink>
      ) : null}

      {socialData?.dribbble?.length && socialData?.dribbble?.includes('dribbble') ? (
        <ChakraLink as={ReactRouterLink} isExternal to={FixSocialLinks(socialData?.dribbble)}>
          <Icon as={AiFillDribbbleCircle} color={'#FFABE7'} fontSize={'24px'} cursor="pointer" />
        </ChakraLink>
      ) : null}
    </Flex>
  );
}

export default SocialIcon;
