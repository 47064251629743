import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation, Link as ReactRouterLink, Link } from 'react-router-dom';
import { Flex, Box, Image, Spinner, Text, useColorModeValue, Button, Icon, useToast, Link as ChakraLink,
         Spacer, useDisclosure, Drawer, DrawerContent, ListItem, List, ListIcon, Divider, DrawerHeader, DrawerCloseButton, Textarea, IconButton, Tooltip, Checkbox,
} from '@chakra-ui/react';
import { getCandidateForInterview, getEmailId, getJob, getSourcePage} from '../../utils/localStorageIndex';
import { BiArrowBack, BiPencil, BiUserCircle } from 'react-icons/bi';
import dummyEmployer from '../../../src/image/dummy_employer.jpg';
import dummySchool from '../../../src/image/dummy_school.png';
import { MdCheck, MdPhoneInTalk } from "react-icons/md";
import { fetchCandidatePanelDetails, fetchCandidateFeedback, saveCandidateFeedback, candidateKanbanStageUpdate,
} from '../../services/jobTemplateService';
import SocialIcon from './SocialIcon';
import { isObjectEmpty } from '../../utils/common';
import { fetchPersonalInfo } from '../../services/sharedServices';
import NewInterviewSchedule from '../business/applicants/listingbyjob/components/NewInterviewSchedule';
import ResumePdfView from '../business/jobWizard/jobWorkflow/Workflow/CandidateSearch/ResumePDFView';
import { BsFileEarmarkPdf, BsLinkedin, BsPlusCircleFill } from 'react-icons/bs';
import { AiOutlineMail } from 'react-icons/ai';
import AddExperience from './AddExperience';
import AddEducation from './AddEducation';
import UpdateLogo from './UpdateLogo';
import ScheduleInterviewYesButton from './buttons/ScheduleInterviewYesButton';
import ScheduleInterviewNoButton from './buttons/ScheduleInterviewNoButton';
import ScheduleInterviewButton from './buttons/ScheduleInterviewButton';
import OfferCandidateButton from './buttons/OfferCandidateButton';
import RejectCandidateButton from './buttons/RejectCandidateButton';
import ReadyToInterviewYesButton from './buttons/ReadToInterviewYesButton';
import CandidateSalaryExpectations from './CandidateSalaryExpectations';
import CandidateWorkStatus from './CandidateWorkStatus';
import CandidateHeadshot from './CandidateHeadshot';
import RejectCandidate from '../business/jobWizard/jobWorkflow/Workflow/CandidateSearch/RejectCandidate';


function CandidateProfileView(props) {
    const emailId = getEmailId();
    const navigate = useNavigate();
    const toast = useToast();
    const { state } = useLocation();
    const business = state?.business
    const [isPanelDataLoading, setIsPanelDataLoading] = useState(true);
    const btnRef = React.useRef();
    const { isOpen: isRejectionPanelOpen, onOpen: onRejectionPanelOpen, onClose: onRejectionPanelClose } = useDisclosure();
    const { isOpen: isResumePanelOpen, onOpen: onResumePanelOpen, onClose: onResumePanelClose } = useDisclosure();
    const { isOpen: isAddExperiencePanelOpen, onOpen: onAddExperiencePanelOpen, onClose: onAddExperiencePanelClose } = useDisclosure();
    const { isOpen: isAddEducationPanelOpen, onOpen: onAddEducationPanelOpen, onClose: onAddEducationPanelClose } = useDisclosure();
    const [candidateResumeUrl, setCandidateResumeUrl] = useState(null);
    const urlParams = new URLSearchParams(window.location.search);
    const nylasAuthCode = urlParams.has('code') ? urlParams.get('code') : null;
    
    const [candidate, setCandidate] = useState(() => {
        if (state){
            return state.candidate
        }
        if (nylasAuthCode){
            return getCandidateForInterview()
        }
    })

    const [job, setJob] = useState(() => {
        if (state){
            return state.job
        }
        if (nylasAuthCode){
            return getJob()
        }
    })

    const [sourcePage, setSourcePage] = useState(() => {
        if (state){
            return state.sourcePage
        }
        if (nylasAuthCode){
            return getSourcePage();
        }
    })

    const [candidatePanelData, setCandidatePanelData] = useState({})
    const [candidateFeedback, setCandidateFeedback] = useState([]);        
    const [userFeedback, setUserFeedback] = useState('');
    const [isFeedbackLoading, setIsFeedbackLoading] = useState(false)
    const { isOpen: isInterviewPanelOpen, onOpen: onInterviewPanelOpen, onClose: onInterviewPanelClose } = useDisclosure();
    const { isOpen: isCompanyLogoPanelOpen, onOpen: onCompanyLogoPanelOpen, onClose: onCompanyLogoPanelClose } = useDisclosure();
    const [companyForLogoChanges, setCompanyForLogoChanges] = useState(); 
    const [userDetails, setUserDetails] = useState({});
    const adminSettings = state?.adminSettings ? state?.adminSettings : null;   
    const SchemeTextColor = useColorModeValue('secondaryGray.900', 'gray.800');
    const [readMore, setReadMore] = useState({summary: false, skills: false, experience: false, experienceSummary: {}});
    const [candidateRejected, setCandidateRejected] = useState({})
    const toastFunction = (message, status) => {
        return toast({
            title: message,
            status: status,
            isClosable: true,
            duration: 3000,
        });
    };

    useEffect(() => {
        getCandidatePanelData(candidate);
        getCandidateFeedback('all');
    }, [candidate])

    useEffect(() => getBusinessUserDetails(), []);

    async function copyTextToClipboard(text) {
        return await navigator.clipboard.writeText(text);
      }

    const getBusinessUserDetails = () => {
        let objData = {
          email: emailId,
        };
        fetchPersonalInfo(objData)
          .then(res => {
            if (res.result) {
              const businessUser = res.data[0]
              setUserDetails(businessUser);
            }
        })
      }

    const getCandidatePanelData = (candidate) => {
        setIsPanelDataLoading(true);
        let obj = {
            email: emailId,
            candidate_uuid: candidate.candidate_uuid,
            job_uuid: job.job_uuid
        };
        fetchCandidatePanelDetails(obj)
        .then(res => {
            setIsPanelDataLoading(false);
            if (res.result && res?.data?.length > 0) {
                const panelData = {}
                if (res.data[0]?.details.length){
                    panelData['details'] = res.data[0]?.details[0]
                }
                if (res.data[0]?.profile.length){
                    panelData['profile'] = res.data[0]?.profile[0]
                }
                setCandidatePanelData(panelData);
            }
        })
        .catch(err => {
            setIsPanelDataLoading(false);
        });
    };

    const getCandidateFeedback = (feedbackRange) => {
        setIsFeedbackLoading(true);
        let obj = {
            email: emailId,
            candidate_uuid: candidate.candidate_uuid,
            job_uuid: job.job_uuid,
            range: feedbackRange
        };
        fetchCandidateFeedback(obj)
        .then(res => {
            setIsFeedbackLoading(false);
            if (res.result && res?.data?.length > 0) {
                setCandidateFeedback(res?.data[0]?.feedback);
                setCandidateRejected(res?.data[0]?.rejected)
            }
        })
        .catch(err => {
            console.log(err);
            setIsFeedbackLoading(false);
        });
    };

    const CandidateFeedbackAction = () => {
        if (userFeedback.trim().length > 0){
            setIsFeedbackLoading(true);
            let obj = {
                email: emailId,
                candidate_uuid: candidate.candidate_uuid,
                job_uuid: job.job_uuid,
                feedback_text: userFeedback
            };
            saveCandidateFeedback(obj)
            .then(res => {
                setIsFeedbackLoading(false);
                if (res.result && res?.data?.length > 0) {
                    if (res?.data[0]?.saved){
                        toastFunction('Your feedback is saved successfully..', 'success');
                        setUserFeedback('');
                        getCandidateFeedback('top');
                    } else {
                        toastFunction('Unable to save your feedback...', 'error');
                    }
                } else {
                    toastFunction('Unable to save your feedback...', 'error');
                }
            })
            .catch(err => {
                console.log(err);
                setIsFeedbackLoading(false);
            });
        } else {
            toastFunction('Please provide proper feedback', 'error');
        }
    };

    const updateCandidateStage = (candidateUuid, jobUuid, new_position) => {
        let obj = {
          email: emailId,
          job_uuid: jobUuid,
          candidate_uuid: candidateUuid,
          job_workflow_position: new_position,
        };
        candidateKanbanStageUpdate(obj)
          .then(res => {
            if (res?.result) {
                getCandidatePanelData(candidate);
                toastFunction('The candidate status is updated..', 'success');
            }
          })
          .catch(err => {
            console.log(err);
            toastFunction('Unable to complete the action', 'error');
          });
      };

    const formatDate = (value1, value2) => {
        let dateString1 = null;
        if (value1 != null && value1 != undefined){
            let date = new Date(value1);
            const month = date.toLocaleString('default', { month: 'short' });
            const year = date.toLocaleString('default', { year: 'numeric' });
            dateString1 = month + ', ' + year;
        }
        let dateString2 = null;
        if (value2 != null && value2 != undefined && !['present', 'current'].includes(value2?.toLowerCase())){
            let date = new Date(value2);
            const month = date.toLocaleString('default', { month: 'short' });
            const year = date.toLocaleString('default', { year: 'numeric' });
            dateString2 = month + ', ' + year;
        }
        if (dateString1 != null && dateString2 != null) {
            return dateString1 + ' - ' + dateString2; 
        } else if (dateString1 != null && dateString2 == null){
            return dateString1 + ' - ' + 'present'; 
        } else if (dateString1 === null && dateString2 ==- null){
            return '';
        } else {
            return '';
        } 
    }

    const sortByDate = (expData) => {
        return expData.sort (({end_date: a}, {end_date: b}) => a < b ? -1 : a > b ? 1 : 0)
    }

    const changeCompanyLogo = (company) => {
        setCompanyForLogoChanges(company)
        onCompanyLogoPanelOpen()
    }   

    const CandidateSkills = () => {
        const experienceExtra = candidatePanelData?.details?.ex_data
        const matching_skills = JSON.parse(candidatePanelData?.profile?.workflow?.tags_skill_set || "[]")

        let skillList = []

        if (typeof experienceExtra?.all_skills == 'string'){
            experienceExtra.all_skills = experienceExtra?.all_skills?.split(',')
        }

        if (matching_skills){
            skillList = [...new Set(matching_skills?.concat(experienceExtra?.all_skills))]
        }else{
            skillList = experienceExtra?.all_skills
        }

        const skillListLess = skillList?.slice(0, 20)

        
        return <>
            <Flex w={'100%'} color={'gray.600'} mt={5} rounded={'md'} bg={'gray.200'} direction={'column'} p={1} pb={5}
                overflowY="auto"
                maxH={'40vh'}
                css={{
                '&::-webkit-scrollbar': {
                    width: '8px',
                },
                '&::-webkit-scrollbar-track': {
                    width: '12px',
                },
                '&::-webkit-scrollbar-thumb': {
                    borderRadius: '100px',
                    background: 'gray.500',
                    border: '6px solid rgba(0, 0, 0, 0.2)'
                },
                }}
            >
                <Text fontWeight={'bold'}>Skills</Text>
                {!skillList?.length && <Text>N/A</Text>}
                <Flex direction={'column'} hidden={readMore.skills}>
                    <List p={'5px'}>
                        {
                        skillListLess && skillListLess?.map( skill => {
                            if (matching_skills && matching_skills.includes(skill)){
                                return <ListItem width={'50%'} float={'left'} mb={2}>
                                            <ListIcon as={MdCheck} color='green.500' />
                                            {skill}
                                        </ListItem>
                            }else{
                                return (
                                    <ListItem width={'50%'} float={'left'} pl={6} mb={2}>
                                        {skill}
                                    </ListItem>
                                    )
                            }              
                        })
                        }
                    </List>
                    {skillList?.length > 20 && <ReadMoreButton targetName={'skills'} targetValue={true} style={{'margin-left': '30px'}} />}
                </Flex>
                <Flex direction={'column'} hidden={!readMore.skills}>
                    <List p={'5px'}>
                        {
                        skillList && skillList?.map( skill => {
                            if (matching_skills && matching_skills.includes(skill)){
                                return <ListItem width={'50%'} float={'left'} mb={2}>
                                            <ListIcon as={MdCheck} color='green.500' />
                                            {skill}
                                        </ListItem>
                            }else{
                                return (
                                    <ListItem width={'50%'} float={'left'} pl={6} mb={2}>
                                        {skill}
                                    </ListItem>
                                    )
                            }              
                        })
                        }
                    </List>
                    <ReadLessButton targetName={'skills'} targetValue={false} style={{'margin-left': '30px'}} />
                </Flex>
            </Flex>
        </>
    }

    const ReadMoreButton = ({targetName, targetValue, style}) => {
        // targetName: summary, skills, experiences
        // targetValue: true, false
        return <>
            <Link to={'javascript:void(0)'} style={{'margin-left': '5px', 'color': 'blue', 'width': '70px', ...style}} 
                  onClick={() => {
                    const target = {}
                    target[targetName] = targetValue
                    setReadMore({...readMore, ...target})
                  }}>
                read more
            </Link>
        </>
    }

    const ReadLessButton = ({targetName, targetValue, style}) => {
        return <>
            <Link to={'javascript:void(0)'} style={{'margin-left': '5px', 'color': 'blue', 'width': '60px', ...style}}
                  onClick={() => {
                    const target = {}
                    target[targetName] = targetValue
                    setReadMore({...readMore, ...target})
                  }}>
                read less
            </Link>
        </>
    }

    const CandidateSummary = () => {
        return <>
            <Flex w={'100%'} mb={4} pl={2} 
                overflowY="auto"
                maxH={'15vh'}
                css={{
                '&::-webkit-scrollbar': {
                    width: '8px',
                },
                '&::-webkit-scrollbar-track': {
                    width: '12px',
                },
                '&::-webkit-scrollbar-thumb': {
                    borderRadius: '100px',
                    background: 'gray.500',
                    border: '6px solid rgba(0, 0, 0, 0.2)'
                },
                }}
            >
                <Text color={'black.600'} hidden={readMore.summary}>
                    <span>{candidatePanelData?.profile?.summary?.slice(0, 400)}</span>
                    {candidatePanelData?.profile?.summary?.length > 400 && <ReadMoreButton targetName={'summary'} targetValue={true} />}
                </Text>
                <Text color={'black.600'} hidden={!readMore.summary}>
                    <span>{candidatePanelData?.profile?.summary}</span>
                    <ReadLessButton  targetName={'summary'} targetValue={false} />
                </Text>
            </Flex>
        </>
    }

    const renderEducation = () => {

        if (isPanelDataLoading){
            return null
        }

        if (isObjectEmpty(candidatePanelData) || !candidatePanelData?.details?.raw_data?.education){
            return (
                <Flex alignItems="center" justifyContent="center" mt="10" >
                    <Text fontSize={'lg'}>No data found for candidate education</Text>
                </Flex>
            )
        }

        const edData = candidatePanelData?.details?.raw_data?.education || [];
        return(
            <Box
                overflowY="auto"
                maxH={'20vh'}
                css={{
                '&::-webkit-scrollbar': {
                    width: '8px',
                },
                '&::-webkit-scrollbar-track': {
                    width: '12px',
                },
                '&::-webkit-scrollbar-thumb': {
                    borderRadius: '100px',
                    background: 'gray.500',
                    border: '6px solid rgba(0, 0, 0, 0.2)'
                },
                }}
            >
            {edData.map( (edItem, index) => {
                return(
                    <Flex w={'100%'} textTransform={'capitalize'} mb={4} pl={2}>
                        <Flex mr={'10px'} direction={'column'}>
                            <Box width={'60px'} height={'60px'}>
                            <Image 
                                borderRadius='full'
                                boxSize='60px'
                                objectFit={'contain'}
                                border={'1px solid'}
                                bgColor={'gray.200'}
                                borderColor={'gray.200'}
                                src={edItem?.school?.logo ? edItem?.school?.logo : dummyEmployer}
                                onError={e => e.target.src = dummySchool}
                                />
                            </Box>
                            {adminSettings && 
                            <IconButton ml={2} variant={'ghost'} isRound={true}
                                _hover={{'bg': 'None'}}
                                width={5} height={5} icon={<BiPencil />}
                                onClick={() => changeCompanyLogo(edItem?.school)} 
                            />
                            } 
                        </Flex>
                        <Flex direction={'column'} >
                            <Text fontWeight={'bold'}>{edItem?.degrees?.join(' ')}</Text>

                            {!edItem?.school?.name && !edItem?.degrees?.join(' ') &&
                             <Text fontWeight={'bold'}>N/A</Text>
                            }
                            
                            <Box mb={'2px'}>
                                <Text color={'black.400'} fontWeight={'500'}>
                                    {edItem?.school?.name && edItem?.school?.name}
                                </Text>
                            </Box>
                            <Text  color={'gray'} fontWeight={'500'} float={'left'}>
                                {edItem?.start_date && formatDate(edItem?.start_date, edItem?.end_date)}
                            </Text>
                        </Flex>                           
                    </Flex>
                )
            })}
            </Box>
        )        
    }

    const renderLocation = (exItem) => {
        if (exItem?.location_names?.length > 0) {
            return(
                    <Text textTransform={'capitalize'} color={'gray'} fontWeight={'500'} float={'left'}>
                        {exItem?.location_names?.join('. ')}
                    </Text>
            )
        } else if (exItem?.company?.location?.country){
            return(
                <Text textTransform={'capitalize'} color={'gray'} fontWeight={'500'} float={'left'}>
                    {exItem?.company?.location?.country}
                </Text>
            )
        } else {
            return(
                <Text textTransform={'capitalize'} color={'gray'} fontWeight={'500'} float={'left'}>
                    {exItem?.company?.location?.country}
                </Text>
        )
        }
    }

    const ExperienceItems = ({experiences}) => {
        return experiences.map( (exItem, index) => {
            const experienceSummaryReadMore = {...readMore.experienceSummary}
            experienceSummaryReadMore[exItem?.start_date] = true
            const experienceSummaryReadLess = {...readMore.experienceSummary}
            experienceSummaryReadLess[exItem?.start_date] = false
            return(
                <Flex key={index} w={'100%'} color={'gray.600'} mb={4} pl={2}> 
                    <Flex mr={'10px'} direction={'column'}>
                        <Box width={'60px'} height={'60px'}>
                            <Image 
                                borderRadius='full'
                                boxSize='60px'
                                objectFit={'contain'}
                                border={'1px solid'}
                                bgColor={'gray.200'}
                                borderColor={'gray.200'}
                                src={exItem?.company?.logo ? exItem?.company?.logo : dummyEmployer}
                                onError={e => e.target.src = dummyEmployer}
                            />
                        </Box>
                        {adminSettings && 
                        <IconButton ml={2} variant={'ghost'} isRound={true}
                            _hover={{'bg': 'None'}}
                            width={5} height={5} icon={<BiPencil />}
                            onClick={() => changeCompanyLogo(exItem?.company)} 
                        />
                        } 
                    </Flex>
                    <Flex direction={'column'} width={'80%'}>                             
                        <Box mb={'2px'}>
                            <Text textTransform={'capitalize'} fontWeight={'bold'}>{exItem?.title?.name}</Text>
                        </Box>
                        <Box mb={'2px'}>
                            <Text textTransform={'capitalize'} color={'black.400'} fontWeight={'500'}>{exItem?.company?.name}</Text>
                        </Box>
                        <Box>
                            <Text textTransform={'capitalize'} color={'gray'} fontWeight={'500'} float={'left'}>{formatDate(exItem?.start_date, exItem?.end_date)}</Text>
                        </Box>

                        {renderLocation(exItem)}
                        <Box mt={2} hidden={readMore.experienceSummary[exItem?.start_date]}>
                            <Text>
                                <span>{exItem?.summary?.slice(0, 200)}</span>
                                {exItem?.summary?.length > 200 && <ReadMoreButton targetName={'experienceSummary'} targetValue={experienceSummaryReadMore} style={{'margin-left': '5px'}} />}
                            </Text>                            
                        </Box>
                        <Box mt={2} hidden={!readMore.experienceSummary[exItem?.start_date]}>
                            <Text>
                                <span>{exItem?.summary}</span>
                                <ReadLessButton targetName={'experienceSummary'} targetValue={experienceSummaryReadLess} style={{'margin-left': '5px'}} />
                            </Text>
                        </Box>
                    </Flex>                          
                </Flex>
            )
        })
    }

    const renderExperience = () => {
        if (isObjectEmpty(candidatePanelData)  || !candidatePanelData?.details?.raw_data?.experience){
            return (
                <Flex alignItems="center" justifyContent="center" mt="10" >
                    <Text fontSize={'lg'}>No data found for candidate experience</Text>
                </Flex>
            )
        }
        let expData = candidatePanelData?.details?.raw_data?.experience || [];
        const expDataLess = expData.slice(0, 4)      
        return(
            <Box pb={5}
            overflowY="auto"
            maxH={'40vh'}
            css={{
            '&::-webkit-scrollbar': {
                width: '8px',
            },
            '&::-webkit-scrollbar-track': {
                width: '12px',
            },
            '&::-webkit-scrollbar-thumb': {
                borderRadius: '100px',
                background: 'gray.500',
                border: '6px solid rgba(0, 0, 0, 0.2)'
            },
            }}>                 
                <Box hidden={readMore.experience}>
                    <ExperienceItems experiences={expDataLess} />
                    {expData.length > 4 && <ReadMoreButton targetName={'experience'} targetValue={true} style={{'margin-left': '80px'}} />}
                </Box>
                <Box hidden={!readMore.experience}
                >
                    <ExperienceItems experiences={expData} />
                    <ReadLessButton targetName={'experience'} targetValue={false} style={{'margin-left': '80px'}} />
                </Box>
            </Box>
        )
    }

    const processSalaryExpectation = (expData) => {
        let expResult = "None";
        if (expData != null && expData != undefined){
          let expObj = JSON.parse(expData);
          let expAmount =  expObj.expectedSalary;
          let expType =  expObj.expSalaryType;
          if (expAmount > 0 && expType != null){
            expResult = "$" + expAmount + " " + expType
          }
        }
        return expResult;
    }

    const renderPersonalData = () => {
        if (isObjectEmpty(candidatePanelData)){
            return (
                <Flex width={'100%'} alignItems="center" justifyContent="center" mt="10" >
                    <Text fontSize={'lg'}>No data found for candidate profile</Text>
                </Flex>
            )
        }
    
        let canObj = candidatePanelData?.profile || {}
        return(
            <Flex w={'100%'} h={'100%'} align={'center'} direction={'column'} p={2}>
                <CandidateHeadshot candidate={canObj} height={'120px'} width={'120px'} borderRadius={'120px'} mBottom={'10px'}/>
                <Text
                    color={'blue.400'}
                    fontWeight="bold"
                    fontSize={'18px'}
                    >
                    {`${canObj?.first_name} ${canObj?.last_name}`}
                </Text>
                <Text
                    color={'gray.700'}
                    fontWeight="bold"
                    fontSize={'15px'}
                    >
                    {canObj?.latest_title}
                </Text>

                <Flex justifyContent={'center'} alignItems={'center'} mt={2}>
                    {canObj?.email
                    ?<ChakraLink to={'javascript:void(0)'} mt={2} onClick={ () => {
                        copyTextToClipboard(canObj?.email).then(
                            toast({
                                title: "Email copied to clipboard!",
                                status: "success",
                                duration: 3000
                            })
                        )
                    }}>
                        <Tooltip label={`${canObj?.email}`} fontSize='md'>
                            <span><Icon as={AiOutlineMail} fontSize={'22px'} /></span>
                        </Tooltip>                        
                    </ChakraLink>:null}
                    {canObj?.phone && 
                        <ChakraLink to={'javascript:void(0)'} mt={2} ml={1} onClick={ () => {
                            copyTextToClipboard(canObj?.phone).then(
                                toast({
                                    title: "Phone copied to clipboard!",
                                    status: "success",
                                    duration: 3000
                                })
                            )
                        }}>
                            <Tooltip label={`${canObj?.phone}`} fontSize='md'>
                                <span><Icon as={MdPhoneInTalk} fontSize={'20px'} /></span>
                            </Tooltip>
                        </ChakraLink>
                    }
                    {canObj?.resume ? 
                        <Button
                            size="sm"
                            rounded={'md'}
                            bg={'none'}
                            width={'4px'}
                            _hover={{'bg': 'none'}}
                            mt={1}
                            onClick={() => {
                                setCandidateResumeUrl(canObj?.resume);
                                onResumePanelOpen();
                            }}
                        >
                            <Tooltip label={'view Resume'} fontSize='md'>
                                <span><Icon as={BsFileEarmarkPdf} fontSize={'18px'} /></span>
                            </Tooltip>
                        </Button>
                    : null
                    }
                    {canObj?.linkedin && 
                    <ChakraLink as={ReactRouterLink} isExternal to={canObj?.linkedin} mr={2} ml={1} mt={2}>
                        <Icon as={BsLinkedin} fontSize={'16px'} color={'blue.400'}/>
                    </ChakraLink>
                    }
                    
                    {renderSocialIcons()}
                </Flex>
                                
                <Box w={'100%'} mt={6}></Box>
                {canObj?.expectation
                ?<Flex w={'100%'} color={'gray.600'} mt={5} rounded={'md'} bg={'gray.200'} direction={'column'} p={1}>
                    <CandidateSalaryExpectations salary={JSON.parse(canObj?.expectation || "{}")?.salary} mTop={1} fontWeight={'500'} />
                </Flex>:null}

                {adminSettings &&    
                <Flex w={'100%'} color={'gray.600'} mt={5} rounded={'md'} bg={'gray.200'} direction={'column'} p={1}>
                    <CandidateWorkStatus candidate={canObj} mTop={1}/>
                </Flex>
                }      

                <CandidateSkills />
            </Flex>
        )
    }    

    const renderSocialIcons = () => {
        if (!isObjectEmpty(candidatePanelData.profile)){
            let canObj = candidatePanelData.profile
            return(
                <Flex>
                    {canObj?.social ? <SocialIcon mt={'2'} socialData={JSON.parse(canObj?.social)}/> :null}
                </Flex>
            )
        }      
    }

    const renderActionButtons = () => {
        if (!isObjectEmpty(candidatePanelData.profile)){
            let canObj = candidatePanelData.profile
            if (canObj.workflow_position === 'ready_to_interview' && !adminSettings){
                return(
                    <Flex>
                        <Spacer/>
                        <Text p={1} mr={2}>Schedule For Interview</Text>
                        <ScheduleInterviewYesButton onClickHandler={onInterviewPanelOpen} onClickHandlerArgs={[]} />
                        <ScheduleInterviewNoButton onClickHandler={onRejectionPanelOpen} onClickHandlerArgs={[]} />
                    </Flex>
                )
            }
            if (canObj.workflow_position === 'scheduled_for_interview'){
                return(
                    <Flex>
                        <Spacer/>
                        {!adminSettings && <ScheduleInterviewButton onClickHandler={onInterviewPanelOpen} onClickHandlerArgs={[]} /> } 
                        <OfferCandidateButton onClickHandler={updateCandidateStage} onClickHandlerArgs={[candidate.candidate_uuid, job.job_uuid, 'candidate_hired']} />
                        <RejectCandidateButton onClickHandler={onRejectionPanelOpen} onClickHandlerArgs={[]} />
                    </Flex>
                )
            }
            if (canObj.workflow_position === 'candidate_screening' && adminSettings){
                return(
                    <Flex>
                        <Spacer/>
                        <Text p={1} mr={2}>Ready to interview</Text>
                        <ReadyToInterviewYesButton onClickHandler={updateCandidateStage} onClickHandlerArgs={[candidate.candidate_uuid, job.job_uuid, 'ready_to_interview']} />
                    </Flex>
                )
            }
            if (canObj.workflow_position === 'candidate_screening' && !adminSettings){
                return(
                    <Flex>
                        <Spacer/>
                        <RejectCandidateButton onClickHandler={onRejectionPanelOpen} onClickHandlerArgs={[]} />
                    </Flex>
                )
            }
            if (canObj.workflow_position === 'candidate_hired'){
                return(
                    <Flex bg={'gray.200'} justifyContent={'center'} width={'100%'} p={1}>
                        <Text fontSize={'14px'} mr={1}>Candidate Hired</Text>
                    </Flex>
                )
            }
            if (canObj.workflow_position === 'candidate_rejected'){
                return(
                    <Flex bg={'gray.200'} justifyContent={'center'} width={'100%'} p={1}>
                        <Text fontSize={'14px'} mr={1}>Candidate Rejected</Text>
                    </Flex>
                )
            }
        }
    }

    const FeedbackAuthor = ({feedback}) => {
        if (adminSettings){
            return feedback.source_name
        }
        if (![1, 2].includes(feedback.user_role)){
            return feedback.source_name
        }
        if ([1, 2].includes(feedback.user_role)){
            return 'Internal Recruiter'
        }
    }

    const CandidateRejected = () => {
        if (!isObjectEmpty(candidateRejected)){
            return <>
                <Flex direction={'column'} 
                    color={'gray.600'} mb={4} px={2} pb={3}
                    borderBottomWidth={'0.1px'} borderBottomColor={'gray.200'}
                >
                    <Text as={'b'}>Rejected</Text>
                    <Text>Reason: {candidateRejected?.denial_details ? candidateRejected?.denial_details : "N/A"}</Text>
                    <Text fontSize={'xs'} align={'end'}>
                        {
                            candidateRejected?.datetime_created && 
                            
                            new Date(candidateRejected?.datetime_created).toLocaleDateString('en-us', 
                            { weekday:"long", year:"numeric", month:"short", 
                            day:"numeric", hour:'2-digit', 'minute': '2-digit'})
                        }
                    </Text>
                </Flex>
            </>
        }
    }

    const renderCandidateFeedback = () => {
        if (candidateFeedback.length > 0 || !isObjectEmpty(candidateRejected)){
            return(
                <Box
                overflowY="auto"
                maxH={'50vh'}
                css={{
                '&::-webkit-scrollbar': {
                    width: '8px',
                },
                '&::-webkit-scrollbar-track': {
                    width: '12px',
                },
                '&::-webkit-scrollbar-thumb': {
                    borderRadius: '100px',
                    background: 'gray.500',
                    border: '6px solid rgba(0, 0, 0, 0.2)'
                },
                }}
                >
                <CandidateRejected />
                {candidateFeedback.map((item, key) => {
                    return(
                        <Flex w={'100%'} direction={'column'} 
                            color={'gray.600'}
                            borderBottomWidth={'1px'} borderBottomColor={'gray.200'}
                            mb={4} px={2} pb={3}>
                            <Text><b>Feedback by</b>{' '}<FeedbackAuthor feedback={item}h /></Text>
                            <Text><pre style={{'fontFamily': 'inherit', 'textWrap': 'wrap'}} >{item.text}</pre></Text>
                            <Text fontSize={'xs'} align={'end'}>
                                {new Date(item.time_at).toLocaleDateString('en-us', 
                                    { weekday:"long", year:"numeric", month:"short", 
                                    day:"numeric", hour:'2-digit', 'minute': '2-digit'})}
                            </Text>
                        </Flex>
                    )
                })}
                </Box>
            )
        } return(
            <Flex w={'100%'} direction={'column'} p={2}>
                <Text fontSize={'16px'}>No feedback is available for this candidate.</Text>
            </Flex>
        )
    }

    const backToJobSourcingView = () => {
        if (sourcePage === 'kanban'){
            const route = adminSettings ? '/admin/jobworkflow' : '/business/jobsourcing'
            navigate(route, {
                state: {
                    job: job,
                    job_uuid: job.job_uuid,
                    job_title: job.job_title,
                    business: business,
                    adminSettings: adminSettings
                },
            });
        } else if (sourcePage === 'interview_ready'){
            navigate(`/${adminSettings ? 'admin' : 'business'}/interview_ready_candidates`, {
                state: {
                    job: job,
                    candidate: candidate,
                    business: business,
                    adminSettings: adminSettings
                },
            });
        }
        else if (sourcePage === 'interviewing'){
            navigate(`/${adminSettings ? 'admin' : 'business'}/calendar`, {
                state: {
                    job_uuid: job.job_uuid,
                    job_title: job.job_title,
                    candidate_uuid: candidate.candidate_uuid,
                    business: business,
                    adminSettings: adminSettings
                },
            });
        }
    };
    
    const handleDataFromChild = data => {
        getCandidatePanelData(candidate);
        onRejectionPanelClose();
    };

    return (
        <Box>
            <Flex direction={'column'} mt={{ sm: '125px', lg: '75px' }} >
                <Flex p={2} pr={0} mr={0}>
                    <Flex>
                        <Icon as={BiUserCircle} h={6} w={6} mr={2} />
                        <Text fontSize={'lg'}>Candidate Profile for {job.job_title}</Text>
                    </Flex>
                    <Spacer />
                    
                    <Button height={8} color={'gray.600'} bg={'blue.100'} onClick={() => backToJobSourcingView()}>
                        <Icon as={BiArrowBack} mr={2} />
                        {sourcePage === 'interview_ready' ? 'Back to Ready To Interview Page' : ''}
                        {sourcePage === 'interviewing' ? 'Back to Interviewing Page' : ''}
                        {sourcePage === 'kanban' ? 'Back to Job Sourcing' : ''}
                    </Button>
                </Flex>
                
                <Flex>

                    {/* left sidebar */}
                    <Flex w={'25%'} 
                        borderWidth={'1px'} borderColor={'gray.200'}
                        rounded={'md'}
                        direction={'column'}>
                        <Text color={SchemeTextColor} fontSize={'lg'} bg={'gray.200'} p={1} pl={2} mb={3}>
                            Candidate Profile
                        </Text>
                        <Flex w={'100%'}
                            bg={'white'}
                            overflow="auto"
                            css={{
                            '&::-webkit-scrollbar': {
                                width: '4px',
                            },
                            '&::-webkit-scrollbar-track': {
                                width: '6px',
                            },
                            '&::-webkit-scrollbar-thumb': {
                                background: '#fff',
                                borderRadius: '24px',
                            },
                            }}>
                                {isPanelDataLoading ? (
                                    <>
                                    <Flex alignItems="center" direction={'column'} justifyContent="center" mt="10" h={'40vh'}>
                                        <Text fontSize={'lg'}>Loading personal profile....</Text>
                                        <Spinner size="xl" />
                                    </Flex>
                                    </>
                                ) : null }

                                {renderPersonalData()}
                        </Flex>
                    </Flex>

                    {/* middle section */}
                    <Flex w={'50%'}  ml={1} mr={1} borderWidth={'1px'} borderColor={'gray.200'}
                        rounded={'md'} direction={'column'}>
                        {isPanelDataLoading ? (
                            <>
                            <Flex alignItems="center" direction={'column'} justifyContent="center" mt="10" h={'40vh'}>
                                <Text fontSize={'lg'}>Loading candidate education and experience....</Text>
                                <Spinner size="xl" />
                            </Flex>
                            </>
                        ) : null }


                        <Flex w={'100%'} direction={'column'} bg={'white'}>
                            <Text color={SchemeTextColor} fontSize={'lg'} bg={'gray.200'} p={1} pl={2} mb={3}>Summary</Text>
                            <CandidateSummary />

                            <Flex bg={'gray.200'} p={1} pl={2} mb={3}>
                                <Text color={SchemeTextColor} fontSize={'lg'}>Education</Text>
                                <Spacer />
                                {adminSettings && 
                                    <IconButton _hover={{'cursor': 'pointer'}} as={BsPlusCircleFill} color={'gray.400'} size={'xs'} 
                                        onClick={onAddEducationPanelOpen}
                                    />
                                }
                            </Flex>
                            {renderEducation()}

                            <Flex bg={'gray.200'} p={1} pl={2} mb={3}>
                                <Text color={SchemeTextColor} fontSize={'lg'}>Experience</Text>
                                <Spacer />
                                {adminSettings && 
                                    <IconButton _hover={{'cursor': 'pointer'}} as={BsPlusCircleFill} color={'gray.400'} size={'xs'} 
                                        onClick={onAddExperiencePanelOpen}
                                    />   
                                }
                            </Flex>
                            {renderExperience()}

                            <Divider />
                            <Flex p={2} color={'gray.800'} >
                                <Spacer />
                                {renderActionButtons()}
                            </Flex>
                        </Flex>
 
                    </Flex>

                    {/* right sidebar */}
                    <Flex w={'25%'}
                        borderWidth={'1px'} borderColor={'gray.200'}
                        rounded={'md'}
                        direction={'column'}>
                        <Text color={SchemeTextColor} fontSize={'lg'} bg={'gray.200'} p={1} pl={2} mb={3}>Feedback</Text>
                        <Flex direction={'column'} w={'100%'} mb={5}>
                            <Textarea
                                p="5px"
                                rows={7}
                                placeholder="Write your feedback"
                                size="xl"
                                resize={'none'}
                                borderRadius="0px"
                                px="10px"
                                value={userFeedback}
                                onChange={e => {
                                    setUserFeedback(e.target.value);
                                }}
                                name="user_feedback"
                                _placeholder={{ color: 'gray.500' }}
                            />
                            
                            <Button 
                                onClick={() => CandidateFeedbackAction()}
                                mt={1} mb={1} mx={1} color={'gray.600'} bg={'blue.100'} size={'sm'}>
                                Submit Feedback
                            </Button>
                        </Flex>

                        <Text color={SchemeTextColor} fontSize={'lg'} bg={'gray.200'} p={1} pl={2} mb={3}> Feedback
                        </Text>
                        <Flex w={'100%'} 
                            direction={'column'}
                            rounded={'lg'}
                            bg={'white'}
                            css={{
                            '&::-webkit-scrollbar': {
                                width: '4px',
                            },
                            '&::-webkit-scrollbar-track': {
                                width: '6px',
                            },
                            '&::-webkit-scrollbar-thumb': {
                                background: '#fff',
                                borderRadius: '24px',
                            },
                            }}
                        >
                            {isFeedbackLoading ? (
                                <>
                                <Flex alignItems="center" direction={'column'} justifyContent="center" mt="10" h={'40vh'}>
                                    <Text fontSize={'lg'}>Loading feedback...</Text>
                                    <Spinner size="xl" />
                                </Flex>
                                </>
                            ) : (
                                renderCandidateFeedback()
                            )}
                        </Flex>
                    </Flex>
                </Flex>
            </Flex>

            {/* rejection prompt */}
            <RejectCandidate
                selectedCandidates={[candidate]}
                isRejectionPanelOpen={isRejectionPanelOpen}
                onRejectionPanelClose={onRejectionPanelClose}
                postRejectActions={{
                    getCandidatePanelData: () => getCandidatePanelData(candidate)
                }}
            />

            {/* resume prompt */}
            <Drawer
                    size={'xl'}
                    isOpen={isResumePanelOpen}
                    placement="right"
                    onClose={onResumePanelClose}
                    finalFocusRef={btnRef}
                >
                    <DrawerContent pl={5} >
                    <DrawerHeader>
                        Candidate Resume
                    </DrawerHeader>
                    <DrawerCloseButton />
                        <ResumePdfView pdfUrl={candidateResumeUrl} />
                    </DrawerContent>
            </Drawer>
            
            <UpdateLogo
                onCompanyLogoPanelOpen={onCompanyLogoPanelOpen}
                onCompanyLogoPanelClose={onCompanyLogoPanelClose}
                isCompanyLogoPanelOpen={isCompanyLogoPanelOpen}
                getCandidatePanelData={getCandidatePanelData}
                company={companyForLogoChanges}
                candidate={candidatePanelData?.profile}
            />

            <AddExperience
              onAddExperiencePanelOpen={onAddExperiencePanelOpen}
              onAddExperiencePanelClose={onAddExperiencePanelClose}
              isAddExperiencePanelOpen={isAddExperiencePanelOpen}
              candidate={candidatePanelData?.profile}
              getCandidatePanelData={getCandidatePanelData}
            />

            <AddEducation
              onAddEducationPanelOpen={onAddEducationPanelOpen}
              onAddEducationPanelClose={onAddEducationPanelClose}
              isAddEducationPanelOpen={isAddEducationPanelOpen}
              candidate={candidatePanelData?.profile}
              getCandidatePanelData={getCandidatePanelData}
            />
  
            <NewInterviewSchedule
              {
                ...{
                    onInterviewPanelOpen,
                    onInterviewPanelClose,
                    isInterviewPanelOpen,
                    updateCandidateStage,
                    candidateForInterview: candidatePanelData?.profile,
                    selectedJob:job,
                    userDetails,
                    sourcePage
                }
              }
            />
        </Box>
    );
}

export default CandidateProfileView;
