import React, { useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { useToast, HStack } from '@chakra-ui/react';
import {
  saveEmailId,
  savegroupId,
  saveAuthToken,
  saveSessionId,
} from '../../utils/localStorageIndex';
import { googleSignOn, googleRegister } from '../../services/sharedServices';

const GoogleSignInButton = props => {
  const toast = useToast();
  const navigate = useNavigate();
  const googleButton = useRef(null);
  const googleSignInKey = process.env.REACT_APP_GOOGLE_SIGN_ON;

  useEffect(() => {
    const src = 'https://accounts.google.com/gsi/client';
    const clientId = googleSignInKey;

    if (!document.querySelector(`script[src="${src}"]`)) {
      const script = document.createElement('script');
      script.src = src;
      script.async = true;
      script.defer = true;
      script.onload = () => {
        /*global google*/
        google.accounts.id.initialize({
          client_id: clientId,
          callback: handleCredentialResponse,
        });
        google.accounts.id.renderButton(googleButton.current, {
          theme: 'outline',
          size: 'large',
        });
      };
      document.body.appendChild(script);
    }

    return () => {
      const scriptTag = document.querySelector(`script[src="${src}"]`);
      if (scriptTag) document.body.removeChild(scriptTag);
    };
  }, []);

  function handleCredentialResponse(response) {
    if (props?.selectedButton === 'register') {
      googleRegister(response.credential)
        .then(res => {
          if (res.result == true) {
            navigate('/login');
            toast({
              title: res.message,
              status: 'success',
              isClosable: true,
              duration: 3000,
            });
          } else {
            toast({
              title: res.message,
              status: 'error',
              isClosable: true,
              duration: 3000,
            });
          }
          console.log(res);
        })
        .catch(err => {
          console.log(err);
        });
    }
    if (props?.selectedButton === 'login') {
      googleSignOn(response.credential)
        .then(res => {
          if (res.result == true) {
            toast({
              title: res.message,
              status: 'success',
              isClosable: true,
              duration: 3000,
            });
            if (res?.data?.token != null && res?.data?.token.length > 0) {
              saveAuthToken(res?.data?.token);
              savegroupId(res?.data?.group);
              saveSessionId(res?.data?.user_uuId);
              saveEmailId(res?.data?.email);
              // navigate('/home-dashboard');
              // MVP - After login jumping directly to Team Page
              // navigate('/business/dashboard');
              if (res?.data?.group === "100" || res?.data?.group === "200"){
                navigate('/admin/team');
              } else if (res?.data?.group === "300" || res?.data?.group === "400"){
                navigate('/business/team', {
                  state: {
                    groupId: res?.data?.group,
                  },
                });
              }
            }
          } else {
            toast({
              title: res.message,
              status: 'error',
              isClosable: true,
              duration: 3000,
            });
          }
        })
        .catch(err => {
          console.log(err);
        });
    }
  }

  return (
    <div w="100%" textAlign="center">
      <div w="100%" id="signInDiv" ref={googleButton}></div>
    </div>
  );
};

export default GoogleSignInButton;
