import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useToast, HStack } from '@chakra-ui/react';
import {
  saveEmailId,
  savegroupId,
  saveAuthToken,
  saveSessionId,
} from '../../utils/localStorageIndex';
import { microsoftSignOn, microsoftRegister } from '../../services/sharedServices';
import MicrosoftLogin from "react-microsoft-login";

// Quick Assistance
// https://learn.microsoft.com/en-us/entra/external-id/customers/tutorial-single-page-app-react-sign-in-sign-out

const MicrosoftSignInButton = props => {
    const actionType = props.selectedButton ? props.selectedButton : null;
    const [msalInstance, onMsalInstanceChange] = useState();
    const toast = useToast();
    const navigate = useNavigate();
    const msftButton = useRef(null);
    const clientId = process.env.REACT_APP_MICROSOFT_SIGNON_KEY;

    function handleCredentialResponse(response) {
        if (actionType === null) {
            toast({
                title: 'There is some problem will accessing your Microsoft service, please try again later.',
                status: 'error',
                isClosable: true,
                duration: 3000,
            });
        } else if (props?.selectedButton === 'register') {
            let token = {token: JSON.stringify(response)};
            microsoftRegister(token)
                .then(res => {
                    if (res.result == true) {
                        navigate('/login');
                        toast({
                            title: res.message,
                            status: 'success',
                            isClosable: true,
                            duration: 3000,
                        });
                    } else {
                        toast({
                            title: res.message,
                            status: 'error',
                            isClosable: true,
                            duration: 3000,
                        });
                    }
                    console.log(res);
                })
                .catch(err => {
                    console.log(err);
                });
        } else if (props?.selectedButton === 'login') {
            let token = {token: JSON.stringify(response)};
            microsoftSignOn(token)
                .then(res => {
                if (res.result == true) {
                    toast({
                        title: res.message,
                        status: 'success',
                        isClosable: true,
                        duration: 3000,
                    });
                    if (res?.data?.token != null && res?.data?.token.length > 0) {
                        saveAuthToken(res?.data?.token);
                        savegroupId(res?.data?.group);
                        saveSessionId(res?.data?.user_uuId);
                        saveEmailId(res?.data?.email);
                        // navigate('/home-dashboard');
                        // MVP - After login jumping directly to Team Page
                        // navigate('/business/dashboard');
                        if (res?.data?.group === "100" || res?.data?.group === "200"){
                            navigate('/admin/team');
                        } else if (res?.data?.group === "300" || res?.data?.group === "400"){
                            navigate('/business/team', {
                            state: {
                                groupId: res?.data?.group,
                            },
                            });
                        }
                    }
                } else {
                    toast({
                    title: res.message,
                    status: 'error',
                    isClosable: true,
                    duration: 3000,
                    });
                }
                })
                .catch(err => {
                console.log(err);
                });
        }
    }

    const loginHandler = (err, data, msal) => {
        console.log(err, data);
        if (err){
            if (err.errorMessage.includes('Error opening popup window')) {
                toast({
                    title: "Please enable to Popup window so you can successfull login with your Microsoft account.",
                    status: 'error',
                    isClosable: true,
                    duration: 3000,
                    });
            }
            else {
                toast({
                    title: err.errorMessage,
                    status: 'error',
                    isClosable: true,
                    duration: 3000,
                    });
            }
        } else if (!err && data) {
            onMsalInstanceChange(msal);
            handleCredentialResponse(data);
        }
    };

    return (
        <div w="100%" textAlign="center">
            <MicrosoftLogin 
                rounded={'md'}
                buttonTheme={'light'}
                clientId={clientId} 
                authCallback={loginHandler} />
        </div>
    );
};

export default MicrosoftSignInButton;
