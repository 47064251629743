import React, { useEffect, useRef, useState } from 'react';
import {Drawer, DrawerContent, DrawerHeader, Text, Flex, Button, DrawerCloseButton, 
        DrawerOverlay, DrawerBody, DrawerFooter, Input, useToast, Icon,
} from '@chakra-ui/react';
import TagsInput from 'react-tagsinput';
import OpenAI from 'openai';
import 'react-tagsinput/react-tagsinput.css';
import { useNylas } from '@nylas/nylas-react';
import { useNavigate } from 'react-router-dom';
import { CkeditorUtil } from '../../../../../utils/ckeditor';
import { scheduleCandidateForInterview } from '../../../../../services/jobTemplateService';
import { serviceUrls } from '../../../../../services/serviceUrls';
import { MdMarkEmailRead, MdMarkEmailUnread, MdStar } from 'react-icons/md';
import { RiSparkling2Fill } from 'react-icons/ri'
import { saveCandidateForInterview, getCandidateForInterview, saveJob, getJob, saveSourcePage, getEmailId } from '../../../../../utils/localStorageIndex';
import { nylasDisconnectService } from '../../../../../services/nylasApiService';
import { isObjectEmpty } from '../../../../../utils/common';
import { Editor } from '@tinymce/tinymce-react';
import EmailTemplateSelection from '../../../../common/EmailTemplateSelection';
import EmailTemplateVariablesMenu from '../../../../common/EmailTemplateVariablesMenu';
import { uploadFile } from '../../../../../services/recruiterServices';

const openai = new OpenAI({
    apiKey: process.env.REACT_APP_OPENAI_API_KEY,
    dangerouslyAllowBrowser: true
  });


export default function NewInterviewSchedule(props) {

    const emailId = getEmailId()
    const [interviewForm, setInterviewForm] = useState({
        subject: null,
        message: null,
        invitees_email: []
    });
    const [AIGeneratedEmailBody, setAIGeneratedEmailBody] = useState('');
    const [interviewFormErrors, setInterviewFormErrors] = useState({});
    const [isEmailSending, setIsEmailSending] = useState(false);
    const [isEmailConnecting, setIsEmailConnecting] = useState(false);
    const toast = useToast();
    const updateCandidateStage = props.updateCandidateStage
    const fetchJobsAndCandidate = props.fetchJobsAndCandidate
    const nylas = useNylas();
    const navigate = useNavigate();
	const [selectedJob, setSelectedJob] = useState({});
    const [hiringManager, setHiringManager ] = useState({});
    const [candidateForInterview, setCandidateForInterview] = useState({});
    const [isEmailConnected, setIsEmailConnected] = useState(false);
    const [isEmailBodyGenerating, setIsEmailBodyGenerating] = useState(false);
    const [sourcePage, setSourcePage] = useState({});
    const [selectedTemplate, setSelectedTemplate] = useState(null);
    const editorRef = useRef(null);

    useEffect(() => {
		setSourcePage(props.sourcePage);
    }, [props.sourcePage]);

	useEffect(() => {
		setSelectedJob(props.selectedJob);
    }, [props.selectedJob]);

    useEffect(() => {
		setCandidateForInterview(props.candidateForInterview);
    }, [props.candidateForInterview]);

    useEffect(() => {
        if (props.isInterviewPanelOpen && !AIGeneratedEmailBody && !isEmailBodyGenerating){
            generateEmailWithOpenAI()
        }
    }, [props.isInterviewPanelOpen])

    useEffect(() => {
		setHiringManager(props.userDetails);
		const isHiringManagerEmailConnected = props.userDetails.nylas_email_access_token ? true : false
		setIsEmailConnected(isHiringManagerEmailConnected);
    }, [props.userDetails]);

    useEffect(() => {       
        generateMessageTemplate();
    }, [AIGeneratedEmailBody]);

    useEffect(() => {
        if (!isObjectEmpty(candidateForInterview) && !isObjectEmpty(hiringManager)){
            setInterviewFormErrors({});
            setAIGeneratedEmailBody('');
        }
    }, [candidateForInterview, hiringManager]);

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const nylasAuthCode = urlParams.has('code') ? urlParams.get('code') : null;

        // Handle the code that is passed in the query params from Nylas after a successful login
        if (nylasAuthCode) {
            // Nylas: remove ?code=xxx from URL to avoid resending code for access token exchange
            const newUrl = window.location.href.split('?')[0]
            window.history.replaceState({}, document.title, newUrl);

            const job = getJob()
            const candidate = getCandidateForInterview()
            setCandidateForInterview(candidate);
            setSelectedJob(job);

            const payload = {exchangeCodeForTokenEndpoint: `${serviceUrls().nylasExchangeToken}?service_name=email&user_email=${emailId}`}
            nylas.exchangeCodeForToken(nylasAuthCode, payload).then(res => {
                res = JSON.parse(res || "{}")
                console.log(res)    
                if (res?.result && res?.data?.access_token){
                    setIsEmailConnected(true);
                    toast({
                        title: `Your email is connected`,
                        status: 'success',
                        duration: 3000,
                    });
                    props.onInterviewPanelOpen();
                }else{
                    toast({
                        title: `Unable to connect email`,
                        description: res?.message,
                        status: 'error',
                        duration: 5000,
                    });
                }
            })
        }
    }, [])

    useEffect(() => {
        if (selectedTemplate){
            setInterviewForm({...interviewForm, subject: selectedTemplate?.subject, message: selectedTemplate?.content})
        }
      }, [selectedTemplate])

    const insertVariableIntoEmailBody = (content) => {
        editorRef.current.insertContent(content)
    }

    const generateEmailWithOpenAI = async () => {
        const candidate = !isObjectEmpty(candidateForInterview) ? candidateForInterview : props.candidateForInterview
        const manager = !isObjectEmpty(hiringManager) ? hiringManager : props.userDetails
        setIsEmailBodyGenerating(() => true);
        let prompt = `
                    create a professional single paragraph HTML embedded email for inviting a candidate to a job interview. The job title is ${selectedJob.job_title}
                    The email should not include email subject.
                    candidate name for the interview is ${candidate.first_name}.
                    email sender for this interview is ${manager.first_name}.
                    `

        const response = await openai.chat.completions.create({
          model: 'gpt-4',
          messages: [{ role: 'user', content: prompt }],
        });

        setAIGeneratedEmailBody(response.choices[0].message.content);
        setIsEmailBodyGenerating(() => false);
    }

    const generateMessageTemplate = () => {
        setInterviewForm({...interviewForm, message: AIGeneratedEmailBody})
    }

    const onInviteesEmailChange = (emails) => {
        setInterviewForm({...interviewForm, invitees_email: emails})
    }

    const onUpdateInterviewField = (e) => {
        const nextFormState = {
            ...interviewForm,
            [e.target.name]: e.target.value,
        };
        setInterviewForm(nextFormState);
    };
    
    const validateInterviewForm = () => {
        let errors = {};
        let formIsValid = true;
        let fields = interviewForm;

        if (!candidateForInterview?.email) {
            toast({
                title: 'Unable to schedule interview',
                description: 'Candidate does not have an email address.',
                status: 'error',
                duration: 4000
            })
            return false
        }

        console.log(interviewForm)

        if (!fields['subject']) {
            formIsValid = false;
            errors['subject'] = '*Please enter email subject.';
        }

        if (!fields['message']) {
            formIsValid = false;
            errors['message'] = '*Please enter email message.';
        }        

        setInterviewFormErrors(errors);
        return formIsValid;
    };
    
    const submitInterviewForm = async () => {
        if (!validateInterviewForm()){
            return false;
        }

        setIsEmailSending(true);
        setInterviewFormErrors({});

        try {
            const interviewPayload = {...interviewForm}
            interviewPayload.message = CkeditorUtil.getContentWithLineStyles(interviewForm.message);
            interviewPayload.candidate_uuid = candidateForInterview.candidate_uuid
            interviewPayload.job_uuid = selectedJob.job_uuid
            interviewPayload.email = emailId
            interviewPayload.invitees_email = interviewForm.invitees_email.join(',')
            const res = await scheduleCandidateForInterview(interviewPayload);
            setIsEmailSending(false);
            if (!res?.result){
                toast({
                    title: res?.message,
                    status: 'error',
                    isClosable: true,
                    duration: 3000,
                });

                return
            }

            toast({
                title: `Interview scheduled successfully with ${candidateForInterview.first_name}`,
                status: 'success',
                isClosable: true,
                duration: 3000,
            });
            if (updateCandidateStage){
                updateCandidateStage(candidateForInterview.candidate_uuid, selectedJob.job_uuid, 'scheduled_for_interview')
            }
            
            if (fetchJobsAndCandidate){
                fetchJobsAndCandidate();
            }
            
			props.onInterviewPanelClose();
        } catch (err) {
            setIsEmailSending(false);
            if (err?.response?.data?.detail?.message) {
                toast({
                title: err?.response?.data?.detail?.message,
                status: 'error',
                isClosable: true,
                duration: 3000,
                });
                
                return

            }

            toast({
            title: `An error occurred. ${JSON.stringify(err)}`,
            status: 'error',
            isClosable: true,
            duration: 3000,
            });
        }
    };

    const connectEmail = async () => {
        setIsEmailConnecting(true);

        // required during reload after login with nylas.
        saveCandidateForInterview(candidateForInterview);
		saveJob(selectedJob)
        saveSourcePage(sourcePage);

        let nylasAuthUrl = await nylas.buildAuthUrl({
            emailAddress: hiringManager.email,
            // scopes=email.send,email.read etc
            buildAuthUrlEndpoint: `${serviceUrls().nylasBuildAuthUrl}?service_name=email&scopes=email.send`,
            successRedirectUrl: window.location.href,
        });

        // remove quotes from string url
        nylasAuthUrl = nylasAuthUrl.substring(1, nylasAuthUrl.length - 1);
        window.location.replace(nylasAuthUrl);
        navigate(nylasAuthUrl, { replace: true });
    }

    const disconnectEmail = () => {
        setIsEmailConnecting(true);
        const payload = {service_name: 'email', user_email: emailId}
        nylasDisconnectService(payload).then(res => {
            setIsEmailConnecting(false);
            if (!res.access_token){
                setIsEmailConnected(false);
                toast({
                    title: `Your email is disconnected`,
                    status: 'success',
                    isClosable: true,
                    duration: 3000,
                });
            }
        }).catch(err => {
            console.log('error while disconnectin nylas email service', err);
        })
    }

    const handleFileUpload = (file, progress) => {
        const form = new FormData();
        form.append('file', file.blob());
        form.append('email', emailId)
        const promise = new Promise((resolve, reject) => {
          uploadFile(form)
          .then(res => {
            if (res?.result && res?.data?.file_url){
              resolve(res?.data?.file_url)
            }else{
              reject({message: res?.message, remove: true})
            }
          })
          .catch(err => {
            reject({message: err?.toString(), remove: true})
          })
        })
    
        return promise
      }

    if (!candidateForInterview){
        return <Flex></Flex>
    }

    return (
        <>
        <Drawer trapFocus={false} disableEnforceFocus={true} onClose={props.onInterviewPanelClose} isOpen={props.isInterviewPanelOpen} size='xl'>
            <DrawerOverlay />
            <DrawerContent overflow={'auto'} pl={5}>
                <DrawerCloseButton />
                <DrawerHeader>
                    Schedule Interview with {candidateForInterview.first_name} {candidateForInterview.last_name}
                </DrawerHeader>
                <DrawerBody>
                    <Flex justifyContent={'right'}>
                        <Button mr={4} isLoading={isEmailBodyGenerating} loadingText='Generating email...' variant={'outline'} 
                                height={'30px'} fontWeight={'400'} onClick={() => generateEmailWithOpenAI()}
                            >
                            Regenerate Email
                            <Icon ml={2} as={RiSparkling2Fill} fontSize={'20px'} color={'blue.500'} />
                        </Button>

                        {
                        isEmailConnected
                        ?
                        <Flex>
                            <Button isLoading={isEmailConnecting} loadingText='Disconnecting...' borderWidth={'0.2px'}
                                height={'30px'} fontWeight={'500'} onClick={() => disconnectEmail()}>
                                <Icon as={MdMarkEmailRead} color={'gray.500'} mr={2} />
                                Disconnect Email
                            </Button>
                        </Flex>
                        :
                        <Button isLoading={isEmailConnecting} loadingText='Connecting...' borderWidth={'0.5px'}
                            height={'30px'} fontWeight={'500'} onClick={() => connectEmail()}>
                            <Icon as={MdMarkEmailUnread} color={'gray.500'} mr={2} />
                            Connect Email
                        </Button>
                        }
                    </Flex>

                    <Flex direction={'column'} width={'60%'}>
                        <Text as='b'>To</Text>
                        <TagsInput value={candidateForInterview?.email ? [candidateForInterview?.email] : []}
                            disabled
                            renderLayout={(tagElements, inputElement) => <span> {tagElements} </span> }
                        />
                        <Text hidden={candidateForInterview?.email} as={'i'} mt={1}>Cannot schedule interview with candidate without an email address</Text>
                    </Flex>

                    <Flex direction={'column'} mt={5} width={'60%'}>
                        <Text as='b'>Subject</Text>
                        <Input name='subject' placeholder={'Invitation to Interview'} onChange={onUpdateInterviewField} value={interviewForm.subject || ""} />
                        <Text color="red" my="2">{interviewFormErrors?.subject}</Text>
                    </Flex>

                    <Flex direction={'column'} mt={5} width={'60%'}>
                        <Text as='b'>Teammate Emails</Text>
                        <TagsInput value={interviewForm.invitees_email} onChange={onInviteesEmailChange}
                            // tab, enter, comma 
                            addKeys={[9, 13, 188, 186]}
                            inputProps={{
                                placeholder:
                                interviewForm.invitees_email?.length == 0
                                    ? 'Enter comma separated emails. Press Enter, Tab or Comma(,) after entering email'
                                    : null,
                        }}
                        />
                        <Text color="red" my="2">{interviewFormErrors?.invitees_email}</Text>
                    </Flex>

                    <EmailTemplateSelection
                        subject={interviewForm.subject}
                        emailBody={interviewForm.message} // for saving current email body as template
                        setSelectedTemplate={setSelectedTemplate} // to use template content as email body
                    />

                    <Flex direction={'column'} border={'1px solid'} borderColor={'gray.200'} mt={5}>
                    <Editor
                        tinymceScriptSrc={'/tinymce/tinymce.min.js'}
                        name='message' value={interviewForm.message || ""}
                        onInit={(evt, editor) => (editorRef.current = editor)}
                        onEditorChange={(content) => {                        
                            const e = {
                                target: { name: 'message', value: content}
                            }
                            onUpdateInterviewField(e)
                        }}
                        init={{
                            placeholder: 'Enter your email message here',
                            min_height: 500,
                            branding: false,
                            statusbar: false,
                            menubar: 'edit insert view format table tools',
                            promotion: false,
                            link_default_target: '_blank',
                            link_title: false,
                            plugins: ['advlist', 'image', 'link', 'anchor', 'autolink', 'autoresize', 'autosave', 'charmap', 'code',
                                'codesample', 'directionality', 'emoticons', 'help', 'importcss', 'insertdatetime',
                                'link', 'lists', 'nonbreaking', 'pagebreak', 'save', 'searchreplace', 'table', 
                                'template', 'visualblocks', 'visualchars', 'wordcount'
                              ],
                            toolbar: 'undo redo | bold italic underline blocks fontsize fontfamily | image link | alignleft aligncenter alignright alignjustify | indent outdent | table',
                            contextmenu: false,
                            images_file_types: 'jpeg,jpg,jpe,jfi,jif,jfif,png,gif,bmp,webp,svg',
                            file_picker_types: 'file',
                            images_upload_handler: handleFileUpload,
                            file_picker_callback: function(callback, value, meta) {
                                if (meta.filetype == 'file'){
                                const input = document.createElement('input');
                                input.setAttribute('type', 'file');
                                input.addEventListener('change', (e) => {
                                    const file = e.target.files[0];
                        
                                    const reader = new FileReader();
                                    reader.addEventListener('load', () => {

                                    const id = 'blobid' + (new Date()).getTime();
                                    const blobCache =  editorRef.current.editorUpload.blobCache;
                                    const base64 = reader.result.split(',')[1];
                                    const blobInfo = blobCache.create(id, file, base64);
                                    blobCache.add(blobInfo);
                                    handleFileUpload(blobInfo, null)
                                    .then(res => callback(res, { title: file.name, textToDisplay: file.name }))
                                    .catch(err => {
                                        toast({
                                        title: 'Unable to upload file',
                                        description: err?.toString(),
                                        status: 'error',
                                        duration: 5000,
                                        })
                                    })
                                    })

                                    reader.readAsDataURL(file);

                                })

                                input.click();
                                }
                            }
                        }}
                        />
                        <Text color="red" my="2">{interviewFormErrors?.message}</Text>
                    </Flex>

                    <EmailTemplateVariablesMenu
                        insertVariableIntoEmailBody={insertVariableIntoEmailBody}
                    />
                    
                </DrawerBody>

                <DrawerFooter>
                    <Button colorScheme='teal' onClick={props.onInterviewPanelClose}>Cancel</Button>
                    <Button
                        isLoading={isEmailSending}
                        loadingText='Sending...'
                        ml={'5'} 
                        colorScheme='blue'
                        onClick={() => submitInterviewForm()}
                    >
                        Accept and send message
                    </Button>
                </DrawerFooter>
            </DrawerContent>
        </Drawer>
    </>
    )
}