import React, { useState } from 'react';
import {  Tooltip, IconButton, useDisclosure, Drawer, DrawerContent, DrawerHeader, DrawerCloseButton } from '@chakra-ui/react';
import { MdCameraAlt } from 'react-icons/md';
import HeadshotUpload from '../HeadshotUpload';
import { isObjectEmpty } from '../../../../utils/common';


export default function UploadHeadshotForCandidate(props) {
  const {adminSettings, job, refreshCandidateList, bulkSelectedCandidates, postUploadActions} = props;
  let candidate = {}
  if (bulkSelectedCandidates?.length > 0){
    candidate = bulkSelectedCandidates[0]
  }
  const [isUploadingHeadshot, setIsUploadingHeadshot] = useState({loading: false, candidate_uuid: null});
  const { isOpen: isUploadPanelOpen, onOpen: onUploadPanelOpen, onClose: onUploadPanelClose } = useDisclosure();
  

  const UploadHeadshotButtonLabel = () => {
    if (isUploadingHeadshot.loading && isUploadingHeadshot.candidate_uuid == candidate.candidate_uuid){
      return 'Uploading Headshot...'
    }
      
    return 'Upload Headshot. Replaces existing Headshot (if available) for the selected candidate'
  }

  const getUploadHeadshotLoading = () => {
    return isUploadingHeadshot.loading && isUploadingHeadshot.candidate_uuid == candidate.candidate_uuid
  }

  const closeUploadPanel = () => {
    // call any post upload functions
    for (const [fnName, fn] of Object.entries(postUploadActions)) {
      fn()
    }
    onUploadPanelClose()
  }

  return (
    <>
    {adminSettings &&
      <Tooltip label={<UploadHeadshotButtonLabel />} fontSize='md'>
          <span>
            <IconButton isDisabled={bulkSelectedCandidates.length > 1 || isObjectEmpty(candidate)} fontSize={'18px'} bg={'none'} _hover={{'bg': 'none'}} 
              icon={<MdCameraAlt />} color={'gray'} onClick={onUploadPanelOpen} isLoading={getUploadHeadshotLoading()}
            />
          </span>
      </Tooltip>
    }

    <Drawer size={'lg'} isOpen={isUploadPanelOpen} placement="right" onClose={onUploadPanelClose}>
        <DrawerContent pl={5}  borderLeftWidth="1px" borderLeftColor="gray.200">
          <DrawerHeader>Upload Headshot for {candidate?.first_name} {candidate?.last_name}</DrawerHeader>
          <DrawerCloseButton />
            <HeadshotUpload
              job_uuid={job?.job_uuid}
              candidate_uuid={candidate?.candidate_uuid}
              dataType={'headshot'}
              closeDrawer={closeUploadPanel}
            />
        </DrawerContent>
      </Drawer>
    </>
  );
}
