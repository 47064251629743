import React, { useEffect, useState } from 'react';
import {
  Box,
  Text,
  Button, SimpleGrid,
  Flex,
  HStack,
  Icon,
  Spinner, Badge, Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  CloseButton,
  IconButton,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader, useToast,
  ModalBody,  Grid, useColorModeValue
} from '@chakra-ui/react';
import { getEmailId, getGroupId } from '../../../../utils/localStorageIndex';
import Card from "../../../../components/card/Card";

// Custom components
import Indeed from "./components/Indeed";
import AtsIntegrationWidget from "./components/AtsIntegrationWidget";
import HrIntegrationWidget from './components/HrIntegrationWidget';

function BusinessIntegration() {

    return (
        <Flex direction='column' pt={{ sm: "125px", lg: "75px" }}>
            <SimpleGrid
                mb='20px'
                columns={{ sm: 1, lg: 2 }}
                spacing={{ base: "20px", xl: "20px" }}>
                {/* Column Left */}
                <Flex direction='column'>
                    <HrIntegrationWidget />
                </Flex>
                {/* Column Right */}
                <Flex direction='column'>
                    <AtsIntegrationWidget />
                </Flex>
            </SimpleGrid>
        </Flex>
    )
}

export default BusinessIntegration;
