import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
// Chakra imports
import {
  Box,
  Text,
  Button,
  SimpleGrid,
  Flex,
  HStack,
  Icon,
  Spinner,
  Badge,
  IconButton,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  Input,
  ModalBody,
  Grid,
  useColorModeValue,
  useToast,
} from '@chakra-ui/react';
import { CgAsterisk } from 'react-icons/cg';
import { getEmailId, getGroupId } from '../../../../utils/localStorageIndex';
import {
  updateUserInfo,
  fetchPersonalInfo,
} from '../../../../services/sharedServices';

// Assets
import banner from '../../../../assets/img/auth/banner.png';
import profile from '../../../../assets/img/crm/vbz.png';
// Custom components
import Info from './components/Info';
import Password from './components/Password';
import Profile from './components/Profile';
import Socials from './components/Socials';
import Upload from './components/Upload';

function ProfileSettings(props) {
  const navigate = useNavigate();
  const emaiId = getEmailId();
  const dispatch = useDispatch();
  const [error, setError] = useState({});
  const [loading, isLoading] = useState(false);
  // const [updateInfoObj, setUpdateInfoObj] = useState({
  //   first_name: '',
  //   last_name: '',
  //   phone_number: '',
  //   about_me: '',
  //   social_twitter: '',
  //   social_github: '',
  //   social_linkedin: '',
  //   department_name: '',
  //   email: emaiId,
  //   group: ''
  // });

  // useEffect(() => {
  //   let objData = {
  //     email: emaiId,
  //   };
  //   fetchPersonalInfo(objData)
  //     .then(res => {
  //       if (res.result) {
  //         setUpdateInfoObj(res.data[0]);
  //         isLoading(false);
  //       } else {
  //         isLoading(false);
  //       }
  //     })
  //     .catch(err => {
  //       isLoading(false);
  //       if (err) {
  //       }
  //     });
  // }, []);

  return (
    <Box pt="20px">
      <SimpleGrid
        mb="20px"
        columns={{ sm: 1, lg: 2 }}
        spacing={{ base: '20px', xl: '20px' }}
      >
        {/* Column Left */}
        <Flex direction="column">
          <Upload
            gridArea={{
              base: '3 / 1 / 4 / 2',
              lg: '1 / 3 / 2 / 4',
            }}
            minH={{ base: 'auto', lg: '420px', '2xl': '365px' }}
            pe="20px"
            pb={{ base: '100px', lg: '20px' }}
          />
          {/* <Profile name='Vlad Mihalache' avatar={profile} banner={banner} /> */}
          <Password />
        </Flex>
        {/* Column Right */}
        <Flex direction="column">
          {/* <Socials /> */}
          <Info departmentList={props.departmentList} />
        </Flex>
      </SimpleGrid>
    </Box>
  );
}

export default ProfileSettings;
