import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { NavLink } from 'react-router-dom';
// Chakra imports
import {
  Box,
  Button,
  Checkbox,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Icon,
  Input,
  InputGroup,
  InputRightElement,
  Text,
  useColorModeValue,
  useToast,
} from '@chakra-ui/react';
import { HSeparator } from '../../../components/separator/Separator';
import DefaultAuth from '../../../layouts/auth/types/Default';
import CenteredAuth from '../../../layouts/auth/types/Centered';

import illustration from '../../../assets/img/auth/auth.png';
import { MdOutlineRemoveRedEye } from 'react-icons/md';
import { RiEyeCloseLine } from 'react-icons/ri';
import { updateDefaultPassword } from '../../../services/sharedServices';

function TsChangePassword() {
  // Chakra color mode
  const textColor = useColorModeValue('navy.700', 'white');
  const textColorSecondary = 'gray.400';
  const textColorDetails = useColorModeValue('navy.700', 'secondaryGray.600');
  const textColorBrand = useColorModeValue('brand.500', 'white');
  const brandStars = useColorModeValue('brand.500', 'brand.400');

  //  Todo:State Declarations
  const toast = useToast();
  const navigate = useNavigate();
  const { state } = useLocation();
  const [error, setError] = useState({});
  const [loading, isLoading] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
  const [showRetypePassword, setShowRetypePassword] = useState(false);
  const [changePasswordObj, setChangePasswordObj] = useState({
    email: state.email,
    current_password: '',
    new_password: '',
  });
  const [retype_password, setRetypePassword] = useState('');
  const [retypePasswordErrMsg, setRetypePasswordErrMsg] = useState('');

  const onUpdateField = e => {
    const nextFormState = {
      ...changePasswordObj,
      [e.target.name]: e.target.value,
    };
    setChangePasswordObj(nextFormState);
  };

  const validateFieldForm = () => {
    let fields = changePasswordObj;
    let errors = {};
    let formIsValid = true;
    const checkWhiteSpace = /^\S*$/;
    const checkLoerCase = /^(?=.*[a-z]).*$/;
    const checkUpperCase = /^(?=.*[A-Z]).*$/;
    const checkNumber = /.*[0-9].*/;
    const isValidLength = /^.{8,}$/;

    if (!fields['current_password']) {
      formIsValid = false;
      errors['current_password'] = '*Current Password Is Reqired.';
    }
    if (!fields['new_password']) {
      formIsValid = false;
      errors['new_password'] = '*New Password Cannot Be Empty.';
    } else if (!checkWhiteSpace.test(fields['new_password'])) {
      formIsValid = false;
      errors['new_password'] = '*Password must not contain Whitespaces';
    } else if (!checkLoerCase.test(fields['new_password'])) {
      formIsValid = false;
      errors['new_password'] =
        '*Password must have at least one Lowercase Character';
    } else if (!checkUpperCase.test(fields['new_password'])) {
      formIsValid = false;
      errors['new_password'] =
        '*Password must have at least one Uppercase Character';
    } else if (!checkNumber.test(fields['new_password'])) {
      formIsValid = false;
      errors['new_password'] = '*Password must have at least one Number';
    } else if (!isValidLength.test(fields['new_password'])) {
      formIsValid = false;
      errors['new_password'] = '*Password must be minimum 8 characters long';
    }

    if (!retype_password) {
      formIsValid = false;
      setRetypePasswordErrMsg('*Retype Password Cannot Be Empty.');
    } else if (fields['new_password'] !== retype_password) {
      formIsValid = false;
      setRetypePasswordErrMsg("*New Password didn't match.");
    }
    setError(errors);
    return formIsValid;
  };

  const changePasswordDetails = event => {
    event.preventDefault();
    if (validateFieldForm()) {
      isLoading(true);
      setRetypePasswordErrMsg('');
      updateDefaultPassword(changePasswordObj)
        .then(res => {
          isLoading(false);
          if (res.result) {
            toast({
              title: res?.message,
              status: 'success',
              isClosable: true,
              duration: 3000,
            });
            navigate('/login');
          } else {
            toast({
              title: res?.message,
              status: 'error',
              isClosable: true,
              duration: 3000,
            });
          }
        })
        .catch(err => {
          isLoading(false);
          if (err)
            toast({
              title: err?.response?.data?.detail,
              status: 'error',
              isClosable: true,
              duration: 3000,
            });
        });
    }
  };

  return (
    <CenteredAuth 
        cardTop={{ base: '140px', md: '14vh' }}
        cardBottom={{ base: '50px', lg: 'auto' }}
        mx="0px">
    {/* <DefaultAuth illustrationBackground={illustration} image={illustration}> */}
      <Flex
        maxW={{ base: '100%', md: 'max-content' }}
        w="100%"
        mx={{ base: 'auto', lg: '0px' }}
        me="auto"
        h="100%"
        alignItems="start"
        justifyContent="center"
        mb={{ base: '30px', md: '60px' }}
        px={{ base: '25px', md: '0px' }}
        mt={{ base: '40px', md: '5vh' }}
        flexDirection="column"
      >
        <Box me="auto" mb="40px">
          <Heading color={textColor} fontSize="36px" mb="10px">
            Change Password
          </Heading>
        </Box>
        <Flex
          zIndex="2"
          direction="column"
          w={{ base: '100%', md: '420px' }}
          maxW="100%"
          background="transparent"
          borderRadius="15px"
          mx={{ base: 'auto', lg: 'unset' }}
          me="auto"
          mb={{ base: '20px', md: 'auto' }}
        >
          <FormControl>
            <FormLabel
              ms="4px"
              fontSize="sm"
              fontWeight="500"
              color={textColor}
              isRequired={true}
              display="flex"
            >
              Current Password<Text color={brandStars}>*</Text>
            </FormLabel>
            <InputGroup size="md">
              <Input
                isRequired={true}
                fontSize="sm"
                mb="15px"
                size="lg"
                variant="auth"
                value={changePasswordObj.current_password}
                name="current_password"
                onChange={onUpdateField}
                placeholder="Enter Current Password"
                type={showCurrentPassword ? 'text' : 'password'}
              />

              <InputRightElement display="flex" alignItems="center" mt="4px">
                <Icon
                  color={textColorSecondary}
                  _hover={{ cursor: 'pointer' }}
                  as={
                    showCurrentPassword ? RiEyeCloseLine : MdOutlineRemoveRedEye
                  }
                  onClick={() => {
                    setShowCurrentPassword(!showCurrentPassword);
                  }}
                />
              </InputRightElement>
            </InputGroup>
            <Text color="red" my="2">
              {error?.current_password}
            </Text>

            <FormLabel
              ms="4px"
              fontSize="sm"
              fontWeight="500"
              color={textColor}
              isRequired={true}
              display="flex"
            >
              New Password<Text color={brandStars}>*</Text>
            </FormLabel>
            <InputGroup size="md">
              <Input
                isRequired={true}
                fontSize="sm"
                mb="15px"
                size="lg"
                variant="auth"
                value={changePasswordObj.new_password}
                name="new_password"
                onChange={onUpdateField}
                type={showNewPassword ? 'text' : 'password'}
                placeholder="Enter New Password"
              />

              <InputRightElement display="flex" alignItems="center" mt="4px">
                <Icon
                  color={textColorSecondary}
                  _hover={{ cursor: 'pointer' }}
                  as={showNewPassword ? RiEyeCloseLine : MdOutlineRemoveRedEye}
                  onClick={() => {
                    setShowNewPassword(!showNewPassword);
                  }}
                />
              </InputRightElement>
            </InputGroup>
            <Text color="red" my="2">
              {error?.new_password}
            </Text>

            <FormLabel
              ms="4px"
              fontSize="sm"
              fontWeight="500"
              color={textColor}
              isRequired={true}
              display="flex"
            >
              Password<Text color={brandStars}>*</Text>
            </FormLabel>
            <InputGroup size="md">
              <Input
                isRequired={true}
                fontSize="sm"
                mb="15px"
                size="lg"
                variant="auth"
                value={retype_password}
                name="retype_password"
                onChange={e => {
                  setRetypePassword(e.target.value);
                }}
                placeholder="Retype same password"
                type={showRetypePassword ? 'text' : 'password'}
              />

              <InputRightElement display="flex" alignItems="center" mt="4px">
                <Icon
                  color={textColorSecondary}
                  _hover={{ cursor: 'pointer' }}
                  as={
                    showRetypePassword ? RiEyeCloseLine : MdOutlineRemoveRedEye
                  }
                  onClick={() => {
                    setShowRetypePassword(!showRetypePassword);
                  }}
                />
              </InputRightElement>
            </InputGroup>
            <Text color="red" my="2">
              {retypePasswordErrMsg}
              {/* {error?.retypePasswordErrMsg} */}
            </Text>

            <Flex
              justifyContent="space-between"
              align="center"
              mb="24px"
            ></Flex>
            <Button
              fontSize="sm"
              variant="brand"
              fontWeight="500"
              w="100%"
              h="50"
              mb="24px"
              onClick={e => {
                changePasswordDetails(e);
              }}
              isDisabled={loading ? true : false}
              isLoading={loading ? true : false}
            >
              Change Password
            </Button>
          </FormControl>
          <Flex
            flexDirection="column"
            justifyContent="center"
            alignItems="start"
            maxW="100%"
            mt="0px"
          >
            <Text color={textColorDetails} fontWeight="400" fontSize="14px">
              Not registered yet?
              <NavLink to="/registerUser">
                <Text
                  color={textColorBrand}
                  as="span"
                  ms="5px"
                  fontWeight="500"
                >
                  Register
                </Text>
              </NavLink>
            </Text>
          </Flex>
        </Flex>
      </Flex>
    </CenteredAuth>
  );
}

export default TsChangePassword;
