import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Flex,
  Select,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useToast,
  useColorModeValue,
} from '@chakra-ui/react';
import Card from '../../../../../components/card/Card';
import InputField from '../../../../../components/fields/InputField';
import { useDispatch } from 'react-redux';
import { getEmailId } from '../../../../../utils/localStorageIndex';
import { fetchBusinessInformation } from '../../../../../services/businessServices';
import {
  addBusinessData,
  updateBusinessData,
} from '../../../../../services/businessServices';
import GoogleLocation from '../../../../../constants/GoogleLocation';
import {
  setTopNotificationMessage,
  setTopNotoficationStatusColor,
  setBusinessInfoStatus,
} from '../../../../../store/actions/topNotificationActions';
import { stateList } from '../../../../../constants/State';
import { countryList } from '../../../../../constants/Country';

export default function BusinessUpdateWidget(props) {
  const textColor = useColorModeValue('secondaryGray.900', 'white');
  const [activeBullets, setActiveBullets] = useState({
    user: true,
    address: false,
  });

  const userTab = React.useRef();
  const addressTab = React.useRef();

  // business logic
  const toast = useToast();
  const dispatch = useDispatch();
  const [error, setError] = useState({});
  const [loading, isLoading] = useState(false);
  const defaultStateChangeValue = 'United States';
  const [stateValue, setStateValue] = useState(false);
  const [updateAction, setUpdateAction] = useState(false);
  const [businessInfo, setBusinessInfo] = useState({
    business_name: '',
    business_url: '',
    address_line1: '',
    address_line2: '',
    city: '',
    state: '',
    country: '',
    zip_code: 0,
    phone: '',
    business_email: '',
    email: getEmailId(),
  });

  //   Todo:Fetch Business Details
  useEffect(() => {
    fetchBusinessDetails();
  }, []);

  const fetchBusinessDetails = () => {
    var apiData = { email: getEmailId() };
    fetchBusinessInformation(apiData)
      .then(res => {
        if (res.result && res.data[0] !== null && res.data.length) {
          let responseData = {
            business_uuid: res.data[0].business_uuid
              ? res.data[0].business_uuid
              : null,
            business_name: res.data[0].business_name,
            business_url: res.data[0].business_url,
            Address_line1: res.data[0].Address_line1,
            Address_line2: res.data[0].Address_line2,
            city: res.data[0].city,
            state: res.data[0].state,
            country: res.data[0].country,
            zip_code: res.data[0].zip_code,
            phone: res.data[0].phone,
            business_email: res.data[0].business_email,
            email: getEmailId(),
          };
          if (
            res.data[0].country !== undefined &&
            res.data[0].country &&
            res.data[0].country === defaultStateChangeValue
          ) {
            setStateValue(true);
          }
          res.data[0].business_uuid
            ? setUpdateAction(true)
            : setUpdateAction(false);
          isLoading(false);
          dispatch(setTopNotificationMessage(res?.message));
          dispatch(setTopNotoficationStatusColor('green'));
          setBusinessInfo(responseData);
          // setBusinessInfo(res.data[0]);
        } else {
          isLoading(false);
          dispatch(setTopNotificationMessage(res?.message));
          dispatch(setBusinessInfoStatus(false));
          dispatch(setTopNotoficationStatusColor('red'));
        }
      })
      .catch(err => {
        isLoading(false);
        if (err) {
          dispatch(setTopNotificationMessage(err?.message));
          dispatch(setTopNotoficationStatusColor('red'));
        }
      });
  };

  //   Todo:Set Form Values
  const onUpdateField = e => {
    const nextFormState = {
      ...businessInfo,
      [e.target.name]: e.target.value,
    };
    setBusinessInfo(nextFormState);
  };

  const validateFieldForm = () => {
    let fields = businessInfo;
    let errors = {};
    let formIsValid = true;
    if (!fields['business_name']) {
      formIsValid = false;
      errors['business_name'] = '*Business Name is required.';
      userTab.current.click();
    }
    setError(errors);
    return formIsValid;
  };

  //   Todo:Render Country list Data
  const renderCountryListData = countryList?.map((countryData, index) => (
    <option
      key={countryData.code}
      value={countryData.name}
      style={{ color: 'black' }}
    >
      {countryData.name}
    </option>
  ));

  //   Todo:Render State list Data
  const renderStateListData = stateList?.map((state, index) => (
    <option key={state.code} value={state.name} style={{ color: 'black' }}>
      {state.name}
    </option>
  ));

  // Todo:receive selected data from google location
  const handleSelectedLocation = data => {
    setBusinessInfo(prevBusinessInfo => ({
      ...prevBusinessInfo,
      Address_line1: data,
    }));
  };

  const resetStateValue = e => {
    if (e.target.value === defaultStateChangeValue) {
      setStateValue(true);
    } else {
      setStateValue(false);
    }
  };

  const submitBusinessData = event => {
    event.preventDefault();
    if (validateFieldForm()) {
      isLoading(true);
      if (businessInfo && businessInfo.business_uuid !== null && updateAction) {
        updateBusinessData(businessInfo)
          .then(res => {
            isLoading(false);
            if (res.result) {
              dispatch(setTopNotificationMessage(res?.message));
              dispatch(setTopNotoficationStatusColor('green'));
              toast({
                title: res.message,
                status: 'success',
                isClosable: true,
                duration: 3000,
              });
              fetchBusinessDetails();
              // props.onDataUpdate();
              // navigate('/business/information/BusinessDetails');
            } else {
              dispatch(setTopNotificationMessage(res?.message));
              dispatch(setTopNotoficationStatusColor('red'));
              toast({
                title: res.message,
                status: 'error',
                isClosable: true,
                duration: 3000,
              });
            }
          })
          .catch(err => {
            isLoading(false);
            if (err)
              dispatch(setTopNotificationMessage(err?.response?.data?.detail));
            dispatch(setTopNotoficationStatusColor('red'));
          });
      } else {
        addBusinessData(businessInfo)
          .then(res => {
            isLoading(false);
            if (res.result) {
              toast({
                title: res.message,
                status: 'success',
                isClosable: true,
                duration: 3000,
              });
              dispatch(setTopNotificationMessage(res?.message));
              dispatch(setTopNotoficationStatusColor('green'));
              // navigate('/business/information/BusinessDetails');
              // [Suraj] Please close the dialog and then moved the page back to
              // > '/business/dashboard/'
            } else {
              dispatch(setTopNotificationMessage(res?.message));
              dispatch(setTopNotoficationStatusColor('red'));
              toast({
                title: res.message,
                status: 'error',
                isClosable: true,
                duration: 3000,
              });
            }
          })
          .catch(err => {
            isLoading(false);
            if (err)
              dispatch(setTopNotificationMessage(err?.response?.data?.detail));
            dispatch(setTopNotoficationStatusColor('red'));
          });
      }
    }
  };

  return (
    <Flex
      w={'100%'}
      direction="column"
      minH="100vh"
      align="center"
      position="relative"
      overflow="hidden"
    >
      <Box
        h="40vh"
        bgGradient="linear(to-b, brand.400, brand.600)"
        position="absolute"
        w="100%"
        borderRadius="20px"
      />

      <Tabs
        variant="unstyled"
        zIndex="0"
        mt={{ base: '60px', md: '65px' }}
        display="flex"
        flexDirection="column"
      >
        <TabList
          display="flex"
          alignItems="center"
          alignSelf="center"
          justifySelf="center"
        >
          <Tab
            _focus={{ border: '0px', boxShadow: 'unset' }}
            ref={userTab}
            w={{ sm: '400px', md: '400px', lg: '400px' }}
            onClick={() =>
              setActiveBullets({
                user: true,
                address: false,
                // profile: false,
              })
            }
          >
            <Flex
              direction="column"
              justify="center"
              align="center"
              position="relative"
              _before={{
                content: "''",
                width: { sm: '400px', md: '400px', lg: '400px' },
                height: '3px',
                bg: activeBullets.address ? 'white' : 'brand.400',
                left: { sm: '12px', md: '30px' },
                top: {
                  sm: activeBullets.user ? '6px' : '4px',
                  md: null,
                },
                position: 'absolute',
                bottom: activeBullets.user ? '40px' : '38px',

                transition: 'all .3s ease',
              }}
            >
              <Box
                zIndex="1"
                border="2px solid"
                borderColor={activeBullets.user ? 'white' : 'brand.400'}
                bgGradient="linear(to-b, brand.400, brand.600)"
                w="16px"
                h="16px"
                mb="8px"
                borderRadius="50%"
              />
              <Text
                color={activeBullets.user ? 'white' : 'gray.300'}
                fontWeight={activeBullets.user ? 'bold' : 'normal'}
                display={{ sm: 'none', md: 'block' }}
              >
                User Info
              </Text>
            </Flex>
          </Tab>
          <Tab
            _focus={{ border: '0px', boxShadow: 'unset' }}
            ref={addressTab}
            w={{ sm: '400px', md: '400px', lg: '400px' }}
            onClick={() =>
              setActiveBullets({
                user: true,
                address: true,
                // profile: false,
              })
            }
          >
            <Flex
              direction="column"
              justify="center"
              align="center"
              position="relative"
              _before={{
                content: "''",
                // Do not enable below as it adds up next tab line
                // width: { sm: '300px', md: '300px', lg: '300px' },
                height: '3px',
                bg: activeBullets.profile ? 'white' : 'brand.400',
                left: { sm: '12px', md: '32px' },
                top: '6px',
                position: 'absolute',
                bottom: activeBullets.address ? '40px' : '38px',
                transition: 'all .3s ease',
              }}
            >
              <Box
                zIndex="1"
                border="2px solid"
                borderColor={activeBullets.address ? 'white' : 'brand.400'}
                bgGradient="linear(to-b, brand.400, brand.600)"
                w="16px"
                h="16px"
                mb="8px"
                borderRadius="50%"
              />
              <Text
                color={activeBullets.address ? 'white' : 'gray.300'}
                fontWeight={activeBullets.address ? 'bold' : 'normal'}
                display={{ sm: 'none', md: 'block' }}
              >
                Address
              </Text>
            </Flex>
          </Tab>
        </TabList>
        <TabPanels
          mt="24px"
          maxW={{ md: '90%', lg: '100%' }}
          mx="auto"
          mb="25px"
          borderRadius="20px"
        >
          <TabPanel
            w={{ sm: '800px', md: '800px', lg: '800px' }}
            p="0px"
            mx="auto"
          >
            <Card p="30px">
              <Text color={textColor} fontSize="2xl" fontWeight="700" mb="20px">
                Business Details
              </Text>
              <Flex direction="column" w="100%">
                <Stack direction="column" spacing="20px">
                  {/* <SimpleGrid columns={{ base: 1, md: 2 }} gap="20px"> */}
                  <InputField
                    w="100%"
                    mb="0px"
                    id="business_name"
                    placeholder="Enter Business Name"
                    label="Business Name"
                    value={businessInfo.business_name}
                    name="business_name"
                    onChange={onUpdateField}
                  />
                  {error?.business_name ? (
                    <Text fontSize="sm" color="red" mb="10px">
                      {error?.business_name}
                    </Text>
                  ) : null}
                  <InputField
                    mb="0px"
                    id="business_url"
                    placeholder="Enter Business Url"
                    label="Business Url"
                    value={businessInfo.business_url}
                    name="business_url"
                    onChange={onUpdateField}
                  />
                  <InputField
                    mb="0px"
                    id="business_email_id"
                    placeholder="Enter Business Email Id"
                    label="Business Email Id"
                    value={businessInfo.business_email}
                    name="business_email"
                    onChange={onUpdateField}
                  />
                  <InputField
                    mb="0px"
                    id="phone_number"
                    placeholder="Enter Phone Number"
                    label="Phone Number:"
                    value={businessInfo.phone}
                    name="phone"
                    onChange={onUpdateField}
                  />
                  {/* </SimpleGrid> */}
                </Stack>
                <Flex justify="space-between" mt="24px">
                  <Button
                    variant="darkBrand"
                    fontSize="sm"
                    borderRadius="16px"
                    w={{ base: '128px', md: '148px' }}
                    h="46px"
                    ms="auto"
                    onClick={() => addressTab.current.click()}
                  >
                    Next
                  </Button>
                </Flex>
              </Flex>
            </Card>
          </TabPanel>
          <TabPanel
            w={{ sm: '800px', md: '800px', lg: '800px' }}
            p="0px"
            mx="auto"
          >
            <Card p="30px">
              <Text color={textColor} fontSize="2xl" fontWeight="700" mb="20px">
                Address
              </Text>
              <Flex direction="column" w="100%">
                <Stack direction="column" spacing="20px" mb="20px">
                  <Text
                    fontSize="sm"
                    color={textColor}
                    fontWeight="bold"
                    _hover={{ cursor: 'pointer' }}
                  >
                    Address Line 1
                  </Text>
                  <GoogleLocation
                    types={["establishment", "geocode"]}  // Street Level API
                    sendSelectedLocation={handleSelectedLocation}
                    locationValue={businessInfo.Address_line1}
                    width={'100%'}
                    borderRadius={'16px'}
                    placeholder={'Enter Address Line 1'}
                    border={'1px solid #e0e5f2'}
                    bg="none"
                  ></GoogleLocation>

                  <InputField
                    mb="0px"
                    id="add2"
                    placeholder="eg. Apartment, Floor"
                    label="Address Line 2"
                    value={businessInfo.Address_line2}
                    name="Address_line2"
                    onChange={onUpdateField}
                  />
                  <Text
                    fontSize="sm"
                    color={textColor}
                    fontWeight="bold"
                    _hover={{ cursor: 'pointer' }}
                    mb="2"
                  >
                    Country
                  </Text>

                  <Select
                    value={businessInfo.country}
                    name="country"
                    placeholder="Select Country"
                    size="md"
                    color={textColor}
                    borderRadius="16px"
                    onChange={e => {
                      onUpdateField(e);
                      resetStateValue(e);
                    }}
                  >
                    {renderCountryListData}
                  </Select>
                  <Text
                    fontSize="sm"
                    color={textColor}
                    fontWeight="bold"
                    _hover={{ cursor: 'pointer' }}
                    mb="2"
                  >
                    State
                  </Text>

                  {stateValue ? (
                    <Select
                      value={businessInfo.state}
                      name="state"
                      placeholder="Select State"
                      size="md"
                      color={textColor}
                      borderRadius="16px"
                      onChange={onUpdateField}
                    >
                      {renderStateListData}
                    </Select>
                  ) : (
                    <InputField
                      placeholder="Enter State"
                      value={businessInfo.state}
                      name="state"
                      onChange={onUpdateField}
                      _placeholder={{ color: 'white' }}
                    />
                  )}

                  <InputField
                    mb="0px"
                    id="city"
                    placeholder="City"
                    label="City"
                    value={businessInfo.city}
                    name="city"
                    onChange={onUpdateField}
                  />
                  <InputField
                    mb="0px"
                    id="zip_code"
                    placeholder="Zipcode"
                    label="Zipcode"
                    value={businessInfo.zip_code}
                    name="zip_code"
                    type={'number'}
                    onChange={onUpdateField}
                  />
                </Stack>
                <Flex justify="space-between">
                  <Button
                    variant="light"
                    fontSize="sm"
                    borderRadius="16px"
                    w={{ base: '128px', md: '148px' }}
                    h="46px"
                    onClick={() => userTab.current.click()}
                  >
                    Prev
                  </Button>
                  <Button
                    variant="darkBrand"
                    fontSize="sm"
                    borderRadius="16px"
                    w={{ base: '128px', md: '148px' }}
                    h="46px"
                    ms="auto"
                    onClick={e => {
                      submitBusinessData(e);
                    }}
                    isDisabled={loading ? true : false}
                    isLoading={loading ? true : false}
                    // onClick={() => userTab.current.click()}
                  >
                    Update Business
                  </Button>
                </Flex>
              </Flex>
            </Card>
          </TabPanel>
          {/* <TabPanel
            w={{ sm: '330px', md: '700px', lg: '850px' }}
            p="0px"
            mx="auto"
          >
            <Card p="30px">
              <Text color={textColor} fontSize="2xl" fontWeight="700" mb="20px">
                Profile
              </Text>
              <Flex direction="column" w="100%">
                <Stack direction="column" spacing="20px">
                  <InputField
                    id="profile email"
                    placeholder="Your primary email address"
                    label="Profile Email"
                    mb="0px"
                  />
                  <TextField
                    minH="150px"
                    id="bio"
                    placeholder="Enter a few words about you"
                    label="Bio"
                  />
                </Stack>
                <Flex justify="space-between" mt="24px">
                  <Button
                    variant="light"
                    fontSize="sm"
                    borderRadius="16px"
                    w={{ base: '128px', md: '148px' }}
                    h="46px"
                    onClick={() => addressTab.current.click()}
                  >
                    Prev
                  </Button>
                  <Button
                    variant="darkBrand"
                    fontSize="sm"
                    borderRadius="16px"
                    w={{ base: '128px', md: '148px' }}
                    h="46px"
                  >
                    Submit
                  </Button>
                </Flex>
              </Flex>
            </Card>
          </TabPanel> */}
        </TabPanels>
      </Tabs>
    </Flex>
  );
}
