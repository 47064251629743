import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import TagsInput from 'react-tagsinput';
import React, { useEffect, useState } from 'react';
import Multiselect from 'multiselect-react-dropdown';
import { getEmailId } from '../../../../utils/localStorageIndex';
import { currencyList } from '../../../../constants/Currency';
import {
  errorColor,
  themeFontSizeMediumHeading,
} from '../../../../constants/index';
import {
  partiallyUpdateFields,
  fetchJobDetailedData,
  fetchAutomatedAdditionalInfo,
} from '../../../../services/jobTemplateService';
import PDfJobDocPanel from '../PDFJobDocPanel';
import { BiUpload } from 'react-icons/bi';
import { RiSparkling2Fill } from 'react-icons/ri'

import {
  Box,
  Text,
  Flex,
  SimpleGrid,
  Spacer,
  Stack,
  Heading,
  Checkbox,
  NumberInput,
  NumberInputField,
  Button,
  Spinner,
  FormLabel,
  Input,
  Select,
  Modal,
  HStack,
  VStack,
  ModalBody,
  ModalContent,
  ModalCloseButton,
  ModalHeader,
  ModalFooter,
  ModalOverlay,
  Slider,
  SliderTrack,
  SliderFilledTrack,
  SliderThumb,
  SliderMark,
  Tooltip,
  Icon,
  useToast,
  useDisclosure,
  useColorModeValue,
  Drawer,
  DrawerBody,
  DrawerHeader,
  DrawerContent,
  DrawerCloseButton,
  Badge,
} from '@chakra-ui/react';
import {
  employeeSalaryRate,
  experienceLevelOptions,
  employementTypeOptions,
  benifitsDropdownOptions,
  educationRequiredOptions,
  supplimentalChoicesOptions,
} from '../../../../constants/jobFunctionRequirements';
import { fetchBusinessInformation } from '../../../../services/businessServices';

import { BsPlus, BsCheck } from 'react-icons/bs';
import UpdateJobDepartment from './UpdateJobDepartment';
import Autocomplete from 'react-google-autocomplete';

import InputField from '../../../../components/fields/InputField.js';
import { jobFunctionJsonList } from '../../../../constants/JobFunction';
import { jobIndustriesJsonList } from '../../../../constants/JobIndustries';
import { CgAsterisk } from 'react-icons/cg';
import {
  AiFillPlusSquare,
  AiOutlineCaretDown,
  AiOutlineCaretRight,
  AiOutlinePlus,
} from 'react-icons/ai';
import { Editor } from '@tinymce/tinymce-react';
import { CkeditorUtil } from '../../../../utils/ckeditor.js';
import JobApplicationForm from '../../../common/JobApplicationForm.js';
import { submitScreeningQuestions } from '../../../../services/questionServices.js';
import { isObjectEmpty, validateScreeningQuestions } from '../../../../utils/common.js';

function EditJobDetails() {
  const email = getEmailId();
  const { state } = useLocation();
  const {adminSettings=false, business={}} = state
  const navigate = useNavigate();
  const btnPdfRef = React.useRef();

  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { isOpen: isJobDescriptionUploadOpen, onOpen: onJobDescriptionUploadOpen, onClose: onJobDescriptionUploadClose } = useDisclosure();

  const pillTextColor = useColorModeValue('navy.700', 'gray.200');
  const pillHighlightColor = useColorModeValue('navy.700', 'gray.200');
  const schemeBorderColor = useColorModeValue('gray.200', 'gray.600');
  const schemeTextColor = useColorModeValue('secondaryGray.900', 'gray.200');
  const textColorPrimary = useColorModeValue('secondaryGray.900', 'white');

  const googleLocationKey = process.env.REACT_APP_GOOGLE_LOCATION_KEY;

  const [showAdvance, setShowAdvance] = useState(true);
  const [questionErrors, setQuestionErrors] =  useState({})
  const [screeningQuestions, setScreeningQuestions] = useState([])
  const [showScreening, setShowScreening] = useState(true);

  const [surveyHealthBenefits, setSurveyHealthBenefits] = useState([]);

  const job_uuid = state.job_uuid; //Todo:Fetching job_uuid from state
  const [jobTitle, setJobTitle] = useState('');
  const [jobSaveStage, setJobSaveStage] = useState('draft');
  const [jobTitleOthers, setJobTitleOthers] = useState('');
  const [jobCode, setJobCode] = useState('');
  const [loading, isLoading] = useState(true);
  const [saveButtonLoading, setSaveButtonLoading] = useState(false);
  const [employementType, setEmployementType] = useState('Full-Time');
  const [educationRequired, setEducationRequired] = useState('Not Applicable');
  const [experienceRequired, setExperienceRequired] =
    useState('Not Applicable');
  const [jobDescription, setJobDescription] = useState('');
  const [jobRequirement, setJobRequirement] = useState('');
  const [job_benefits, setJobBenifits] = useState(benifitsDropdownOptions);
  const [selectedExperienceLevel, setSelectedExperienceLevel] = useState(
    experienceLevelOptions
  );

  const [selectedEducationRequired, setSelectedEducationRequired] = useState(
    educationRequiredOptions
  );
  const [jobRequirementLoading, setJobRequirementLoading] = useState(false);
  const [jobDescriptionLoading, setJobDescriptionLoading] = useState(false);
  const [generatingDescriptionWithAI, setGeneratingDescriptionWithAI] = useState(false);
  const [selectedOptionValue, setSelectedOptionValue] = useState('');
  const [selectedLocation, setSelectedLocation] = useState('');
  const [jobLocationValue, setJobLocationValue] = useState('');

  const [jobSalaryOption, setJobSalaryOption] = useState('');
  const [jobLocationExtended, setJobLocationExtended] = useState();
  const supplementalPayArr = [
    { name: 'Bonus Opportunities', isChecked: false },
    { name: 'Commission Pay', isChecked: false },
    { name: 'Company Stock', isChecked: false },
    { name: 'Others', isChecked: false },
  ];
  const [renderPayArr, setRenderPayArr] = useState(supplementalPayArr);
  const [payOtherValue, setPayOtherValue] = useState(''); //job_pay_other

  // job salary
  const [fromSalary, setFromSalary] = useState();
  const [toSalary, setToSalary] = useState();
  const [currency, setCurrency] = useState('');
  const [salaryRate, setSalaryRate] = useState('');
  const [jobDepartmentData, setJobDepartmentData] = useState('');
  const [triggerSaveChange, setTriggerSaveChange] = useState(false);
  const [saveAdditionalJobDetails, setSaveAdditionaljobDetails] = useState([]);

  const [jobWorkflowStarted, setJobWorkflowStarted] = useState(false);

  // job specification
  const [jobFunction, setJobFunction] = useState('');
  const [jobIndustry, setJobIndustry] = useState('');
  const [workFromHomeState, setWorkFromHomeStatus] = useState(false);
  const [jobKeywods, setJobKeywords] = useState([]);
  const [sliderValue, setSliderValue] = useState(5);
  const [showTooltip, setShowTooltip] = useState(false);
  const [job, setJob] = useState({})

  //Business Survey
  const [businessSurveyData, setBusinessSurveyData] = useState([
    { name: 'Health Insurance', isChecked: false },
    { name: 'Paid Time-Off', isChecked: false },
    { name: 'Dental Insurance', isChecked: false },
    { name: '401 (K)', isChecked: false },
    { name: 'Vision Insurance', isChecked: false },
    { name: 'Flexible Schedule', isChecked: false },
    { name: 'Tuition Reimbursement', isChecked: false },
    { name: 'Life Insurance', isChecked: false },
    { name: '401 (k) matching', isChecked: false },
    { name: 'Retirement Plan', isChecked: false },
    { name: 'Referral Program', isChecked: false },
    { name: 'Employee Discount', isChecked: false },
    { name: 'Flexible Spending', isChecked: false },
    { name: 'Health Savings Account', isChecked: false },
    { name: 'Relocation Assistance', isChecked: false },
    { name: 'Parental Leave', isChecked: false },
    { name: 'Professional Development Assistance', isChecked: false },
    { name: 'Employee Assistance Program', isChecked: false },
    // { name: 'Others', isChecked: false },
  ]);
  const [savedJobBenefits, setSavedJobBenefits] = useState([]);
  const [reloadData, setReloadData] = useState(false);

  // const formatCurrency = (val) => `$` + val
  function formatCurrency(num) {

    if (!num || num == '-9007199254740991'){
      return '$'
    }

    let numStr = num.toString();
    return '$' + numStr.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
  }

  const parseCurrency = val => val.replace(/^\$/, '');

  const updateLocalBusinessSurveyData = () => {
    let cloneData = [...businessSurveyData];
    cloneData?.map((subValue, index) => {

      if (surveyHealthBenefits?.length > 0 && surveyHealthBenefits.includes(subValue?.name)) {
        subValue.isChecked = true;
      }
      
      // !reloadData means "reload from survey" button is not clicked
      if (savedJobBenefits?.length > 0 && !reloadData) {
        savedJobBenefits.map((savedItem, index) => {          
          if (savedItem.name === subValue?.name && savedItem.isChecked === true) {
            subValue.isChecked = true;
          } else if (savedItem.name === subValue?.name && savedItem.isChecked === false){
            subValue.isChecked = false;
          }
        });
      }
  
    });
    
    setBusinessSurveyData(cloneData);
  
  };

  useEffect(() => {
    fetchJobDetails();
    setTriggerSaveChange(false);
    collectBusinessSurvey();
  }, []);

  useEffect( () => {
    updateLocalBusinessSurveyData()
  }, [surveyHealthBenefits, savedJobBenefits])

  const updateSavedJobBenefitArr = saved_benefits => {
    if (saved_benefits.trim().length > 0) {
      let a = 19;
      let savedJobBenefits = JSON.parse(saved_benefits);
      if (savedJobBenefits.length > 0) {
        setSavedJobBenefits(savedJobBenefits);
      }
    }
  };
  const fetchJobDetails = () => {
    isLoading(true);
    let objBody = {
      email: email,
      job_uuid: job_uuid,
    };
    fetchJobDetailedData(objBody)
      .then(res => {
        isLoading(false);
        if (res.result && res.data[0]) {
          setJob(res?.data[0])
          setJobTitle(res.data[0]?.job_title);
          setJobTitleOthers(res.data[0]?.job_title_other);
          setJobCode(res?.data[0]?.job_code);
          setJobDescription(res.data[0]?.job_description);
          setJobRequirement(res?.data[0]?.job_requirements);
          setEmployementType(res.data[0].job_employment_type);
          setEducationRequired(res.data[0].job_highest_education);
          setJobDepartmentData(res?.data[0].job_department_name);
          setExperienceRequired(res?.data[0]?.job_experience_level);
          console.log(res.data[0]?.job_location_extended, jobLocationExtended)
          setJobWorkflowStarted(res.data[0]?.job_workflow_started);
          if (res.data[0]?.job_supplemental_choices?.length) {
            setRenderPayArr(JSON.parse(res.data[0]?.job_supplemental_choices));
          }
          setPayOtherValue(
            res.data[0]?.job_pay_other?.length ? res.data[0]?.job_pay_other : ''
          );

          setSelectedLocation(
            res.data[0]?.job_is_remote && res.data[0]?.job_is_remote === true
          );
          setJobSalaryOption(
            res.data[0]?.job_salary_option
              ? res.data[0]?.job_salary_option
              : 'Range'
          );

          setJobLocationValue(
            res.data[0]?.job_location ? res.data[0]?.job_location : ''
          );
          setJobLocationExtended(
            res.data[0]?.job_location_extended
              ? res.data[0]?.job_location_extended
              : ''
          );

          setFromSalary(
            res?.data[0]?.job_salary_min ? res?.data[0]?.job_salary_min : null
          );
          setToSalary(
            res?.data[0]?.job_salary_max ? res?.data[0]?.job_salary_max : null
          );
          setCurrency(
            res?.data[0]?.job_salary_currency
              ? res?.data[0]?.job_salary_currency
              : 'US Dollar'
          );
          setSalaryRate(
            res?.data[0]?.job_salary_rate ? res?.data[0]?.job_salary_rate : 'per hour'
          );
          setJobCode(res.data[0]?.job_code);
          setJobFunction(res.data[0]?.job_function);
          setJobIndustry(res.data[0]?.job_industry);
          setWorkFromHomeStatus(res.data[0]?.job_work_from_home_ok);
          setSliderValue(parseInt(res.data[0]?.job_work_from_home_days));
          setJobKeywords(
            res.data[0]?.job_keywords && res.data[0]?.job_keywords.length
              ? res.data[0]?.job_keywords.split(',')
              : []
          );
        }
        setSelectedOptionValue('');
        setSaveAdditionaljobDetails(res?.data[0]);
        updateSavedJobBenefitArr(res.data[0]?.job_benefits);
      })
      .catch(err => {
        isLoading(false);
      });
  };

  const collectBusinessSurvey = () => {
    let emailId = { email: getEmailId() };
    fetchBusinessInformation(emailId)
      .then(res => {
        let surveyData = JSON.parse(res?.data[0].survey);
        if (surveyData != null && surveyData != undefined) {
          setSurveyHealthBenefits(surveyData.sections[2].options);
        }
      })
      .catch(err => {
        console.log(err);
      });
  };

  const jobDescriptionData = data => {
    if (data) {
      setJobDescription(data);
      onClose();
    }
  };

  // Todo:Generate Automated Description and Requirements
  const generateAutomatedRequirement = parseData => {
    let objBody = {
      email: email,
      job_uuid: job_uuid,
      info_type: parseData,
      additional_info: jobKeywods.join(','),
      job_title: jobTitle,
      job_benefits: businessSurveyData,
      job_supplemental_pay: renderPayArr,
      job_experience_level: experienceRequired,
      job_highest_education: educationRequired,
      job_salary_option: jobSalaryOption,
      job_salary_min: fromSalary,
      job_salary_max: toSalary,
      job_salary_rate: salaryRate,
      job_location_extended: jobLocationExtended,
      job_employment_type: employementType,
    };
    if (parseData === 'jd') {
      setGeneratingDescriptionWithAI(true);
    }
    if (parseData === 'req') {
      setJobRequirementLoading(true);
    }
    fetchAutomatedAdditionalInfo(objBody)
      .then(res => {
        setGeneratingDescriptionWithAI(false);
        setJobRequirementLoading(false);
        if (res.result) {
          if (parseData === 'jd') {
            setJobDescription(res.data[0].info);
          }
          if (parseData === 'req') {
            setJobRequirement(res.data[0].info);
          }
        }
      })
      .catch(err => {
        setJobRequirementLoading(false);
        setGeneratingDescriptionWithAI(false);
      });
  };

  //   Todo:Render Employement Type List Data
  const renderSalaryRate = employeeSalaryRate?.map((data, index) => (
    <option key={data?.id} value={data?.value} style={{ color: 'black' }}>
      {data?.value}
    </option>
  ));

  // Store data to db
  const storeDataPartially = (updatedKey, selectedData) => {
    let storedData = '';
    let convertedData = [];

    if (selectedData.length) {
      selectedData?.map(data => {
        convertedData.push(data?.name);
      });
      storedData = convertedData.toString();
    }
    updatedKey(storedData);
  };

  //   Todo:Render Currency list Data
  const renderCurrencyList = currencyList?.map((countryData, index) => (
    <option key={countryData.code} value={countryData.name}>
      {countryData.name}
    </option>
  ));

  //   Todo:Render Salary Rate List Data
  const renderEmployementData = employementTypeOptions?.map((data, index) => (
    <option key={data?.id} value={data?.value}>
      {data?.value}
    </option>
  ));

  // Todo:function to select cities
  const handlePlaceSelected = place => {
    setJobLocationValue(place.formatted_address);
  };

  //   Todo:Render Function List Data
  const renderFunctionList = jobFunctionJsonList.map((data, index) => {
    return (
      <option
        key={data?.descriptor}
        value={data?.descriptor}
        style={{ color: 'black' }}
      >
        {data?.descriptor}
      </option>
    );
  });

  //   Todo:Render Industry List Data
  const renderIndustryList = jobIndustriesJsonList?.sort((a, b) => a?.Occupation.localeCompare(b?.Occupation)).map((data, index) => (
    <option
      key={data?.Code}
      value={data?.Occupation}
      style={{ color: 'black' }}
    >
      {data?.Occupation}
    </option>
  ));

  // Todo:Set Tags data
  const onTagChange = tags => {
    setJobKeywords(tags);
  };

  // Todo:fetch job specification data
  const fetchDepartmentDetails = saveState => {
    setTriggerSaveChange(false);
    let obj = {
      email: email,
      job_uuid: job_uuid,
      job_title: jobTitle,
      job_save_stage: saveState,
      job_title_other: jobTitleOthers,
      // job_benefits: surveyHealthBenefits?.length
      //   ? surveyHealthBenefits?.toString()
      //   : '',
      job_benefits: JSON.stringify(businessSurveyData),
      job_is_remote: selectedLocation ? 'true' : 'false',
      job_location: jobLocationValue,
      job_location_extended: jobLocationExtended,
      job_employment_type: employementType,
      // job_experience_level: returnStringArr(selectedExperienceLevel),
      // job_highest_education: returnStringArr(selectedEducationRequired),
      job_experience_level: experienceRequired,
      job_highest_education: educationRequired,
      job_salary_option: jobSalaryOption,
      job_salary_min: fromSalary || null,
      job_salary_max: toSalary || null,
      job_salary_currency: currency,
      job_salary_rate: salaryRate,
      job_supplemental_pay: saveAdditionalJobDetails[0]?.job_supplemental_pay,
      job_supplemental_choices: JSON.stringify(renderPayArr),
      job_pay_other: payOtherValue,
      job_description_other: '',
      job_code: jobCode,
      job_description: CkeditorUtil.getContentWithLineStyles(jobDescription),
      job_requirements: jobRequirement,
      job_keywords: jobKeywods.length ? jobKeywods.toString() : '',
      job_work_from_home_ok: workFromHomeState ? 'true' : 'false',
      job_work_from_home_days: sliderValue ? sliderValue : null,
      job_function: jobFunction,
      job_industry: jobIndustry,
      // job_department: data?.job_department_name,
      job_workflow_readiness:
        saveAdditionalJobDetails[0]?.job_workflow_readiness,
    };
    partiallyUpdateFields(obj)
      .then(res => {
        isLoading(false);
        setSaveButtonLoading(false);
        if (res.status) {
          if (res.data.length == 0) {
            toast({
              title: res?.message,
              status: 'error',
              duration: 3000,
            });
            return
          }
        }

        saveScreeningQuestions()
      })
      .catch(err => {
        setSaveButtonLoading(false);
        console.log(err);
      });
  };

  // Todo:High-Order Fuction to reset check buttons
  const setCheckedValue = (data, arrValue, setterFunction) => {
    let cloneData = [...arrValue];
    cloneData?.map((subValue, index) => {
      if (subValue?.name === data) {
        subValue.isChecked = !subValue.isChecked;
      }
    });
    setterFunction(cloneData);
  };

  // Todo:Function to convert string data and render to UI
  const resetCheckedValues = (data, arrValue, setterFunction) => {
    let fetchedData = data.split(',').map(item => item.trim());
    let cloneArr = [...arrValue];
    fetchedData?.map(outerValue => {
      cloneArr?.map(innerValue => {
        try {
          if (outerValue === innerValue?.id) {
            innerValue.isChecked = true;
          }
        } catch (error) {
          console.error('Error:', error);
        }
      });
    });
    setterFunction(cloneArr);
  };

  const returnStringArr = data => {
    return data
      .filter(item => item.isChecked)
      .map(item => item.name)
      .join(', ');
  };

  // Todo:Render Education Required UI
  const renderEducationOption = selectedEducationRequired?.map(
    (data, index) => (
      <option key={data?.id} value={data?.name}>
        {data?.name}
      </option>
    )
  );

  // Todo:Render Experiece Required UI
  const renderExperienceRequired = selectedExperienceLevel?.map(
    (data, index) => (
      <option key={data?.id} value={data?.name}>
        {data?.name}
      </option>
    )
  );

  const renderSupplementalPayData = renderPayArr?.map((data, index) => (
    <>
      <Button
        key={index}
        color={data?.isChecked ? 'gray.600' : pillTextColor}
        bg={data?.isChecked ? 'gray.300' : ''}
        borderWidth={'1px'}
        borderRadius={'20px'}
        size="sm"
        mr="2"
        mb="2"
        onClick={() => {
          setCheckedValue(data.name, renderPayArr, setRenderPayArr);
        }}
      >
        <Icon h="24px" w="24px" as={data?.isChecked ? BsCheck : BsPlus}></Icon>{' '}
        {data?.name}
      </Button>
      {data?.name === 'Others' && data?.isChecked ? (
        <InputField
          borderRadius="5px"
          mb="0px"
          width="100%"
          placeholder="Enter Others Value"
          label="Enter Others Value"
          value={payOtherValue}
          onChange={e => {
            setPayOtherValue(e.target.value);
          }}
          color="black"
        />
      ) : null}
    </>
  ));

  const renderBusinessSurveyData = businessSurveyData?.map((data, index) => (
    <>
      <Button
        key={index}
        color={data?.isChecked ? 'gray.600' : pillTextColor}
        bg={data?.isChecked ? 'gray.300' : ''}
        borderWidth={'1px'}
        borderRadius={'20px'}
        size="sm"
        mr="2"
        mb="2"
        onClick={() => {
          setCheckedValue(data.name, businessSurveyData, setBusinessSurveyData);
        }}
      >
        <Icon h="24px" w="24px" as={data?.isChecked ? BsCheck : BsPlus}></Icon>{' '}
        {data?.name}
      </Button>
    </>
  ));

  // const renderJobBenifitsOption = surveyHealthBenefits?.map((data, index) => {
  //   return (
  //     <Button
  //       key={index}
  //       borderWidth={'1px'}
  //       borderRadius={'20px'}
  //       color={data?.isChecked ? 'gray.600' : pillTextColor}
  //       bg={data?.isChecked ? 'gray.300' : ''}
  //       size="sm"
  //       mr="2"
  //       mb="2"
  //       onClick={() => {
  //         // setCheckedValue(data.name, job_benefits, setJobBenifits);
  //         setCheckedValue(data.name, surveyHealthBenefits, setSurveyHealthBenefits);
  //       }}
  //     >
  //       <Icon h="24px" w="24px" as={data?.isChecked ? BsCheck : BsPlus}></Icon>{' '}
  //       {data}
  //     </Button>
  //   );
  // });

  // const renderEducationOption = selectedEducationRequired?.map(
  //   (data, index) => {
  //     return (
  //       <Button
  //         key={index}
  //         cursor="pointer"
  //         color={data?.isChecked ? 'gray.600' : pillTextColor}
  //         bg={data?.isChecked ? 'gray.300' : ''}
  //         borderWidth={'1px'}
  //         borderRadius={'20px'}
  //         size="sm"
  //         mr="2"
  //         mb="2"
  //         onClick={() => {
  //           setCheckedValue(
  //             data.name,
  //             selectedEducationRequired,
  //             setSelectedEducationRequired
  //           );
  //         }}
  //       >
  //         <Icon
  //           h="24px"
  //           w="24px"
  //           as={data?.isChecked ? BsCheck : BsPlus}
  //         ></Icon>{' '}
  //         {data?.name}
  //       </Button>
  //     );
  //   }
  // );

  // Todo:Render Education Required UI
  // const renderExperienceOption = selectedExperienceLevel?.map((data, index) => {
  //   return (
  //     <Button
  //       key={index}
  //       cursor="pointer"
  //       color={data?.isChecked ? 'gray.600' : pillTextColor}
  //       bg={data?.isChecked ? 'gray.300' : ''}
  //       borderWidth={'1px'}
  //       borderRadius={'20px'}
  //       size="sm"
  //       mr="2"
  //       mb="2"
  //       onClick={() => {
  //         setCheckedValue(
  //           data.name,
  //           selectedExperienceLevel,
  //           setSelectedExperienceLevel
  //         );
  //       }}
  //     >
  //       <Icon h="24px" w="24px" as={data?.isChecked ? BsCheck : BsPlus}></Icon>{' '}
  //       {data?.name}
  //     </Button>
  //   );
  // });

  // Todo:Render Job Benifits UI

  const saveData = saveState => {
    if (
      jobSalaryOption === 'Range' &&
      parseFloat(fromSalary) > 0 &&
      parseFloat(fromSalary) >= parseFloat(toSalary)
    ) {
      toast({
        title: 'To Salary must be greater than From Salary',
        status: 'error',
        isClosable: true,
        duration: 3000,
      });
    } else {
      setTriggerSaveChange(true);
      setSaveButtonLoading(true);
      setJobSaveStage(saveState);
      fetchDepartmentDetails(saveState);
    }
  };

  const saveScreeningQuestions = () => {

    const errors = validateScreeningQuestions(screeningQuestions)
      if (!isObjectEmpty(errors)){
        setQuestionErrors({...questionErrors, ...errors})
        toast({
            title: "Please fix errors",
            status: 'error',
            duration: 3000
        })
        return false
      }

    const questionsToSubmit = []
    screeningQuestions.map((question, index) => {
      const q = {
          'question_uuid': question.question_uuid,
          'question_type': question.question_type.code,
          'question_text': question.question_text,

          // clean any unset option values
          'question_options': question.question_options.filter(option => option ? option : false),
          'question_disqualify_filters': question.question_disqualify_filters,
          'question_is_mandatory': question.question_is_mandatory,
          'is_default': question.is_default,
      }
      questionsToSubmit.push(q)
    })

    setQuestionErrors({})
    const formData = {
        'job_uuid': job.job_uuid,
        'user_email': getEmailId(),
        'questions': questionsToSubmit
    }

    submitScreeningQuestions(formData)
    .then(res => {
        if (res?.result){
            setQuestionErrors({})
            
            toast({
                title: 'Job details saved successfully.',
                status: 'success',
                duration: 3000,
            });

            if (adminSettings){
              navigate('/admin/jobcandidatelist', {
                state: {
                  job: job,
                  business: business,
                  adminSettings: true,        
                },
              });
            }else{
              navigate('/business/joblist');
            }
            return
        }
        const errors = questionErrors
        if (res.data[0].error.question_uuid){
            errors[res.data[0].error.question_uuid] = res.message
        }else if (res.data[0].error.question_text){
            errors[res.data[0].error.question_text] = res.message
        }
        setQuestionErrors(errors)
        toast({
          title: 'Unable to submit screening questions', 
          description: res?.message,
          status: 'error',
          duration: 5000
      });
    })
    .catch(err => {
        toast({
            title: 'Unable to submit screening questions', 
            description: err.toString(),
            status: 'error',
            duration: 5000
        });
    })
  }

  const onCloseModal = () => {
    onClose();
  };

  const renderJobDetailsData = () => {
    return (
      <Flex direction="column" pt={{ sm: '125px', lg: '75px' }}>
        <Box borderRadius="10px">
          <Box
            overflow="auto"
            overflowX="hidden"
            css={{
              '&::-webkit-scrollbar': {
                width: '4px',
              },
              '&::-webkit-scrollbar-track': {
                width: '6px',
              },
              '&::-webkit-scrollbar-thumb': {
                borderRadius: '24px',
                background: '#fff',
              },
            }}
          >
            <InputField
              mb="0"
              me="30px"
              id="first_name"
              label="Job Title"
              value={jobTitle ? jobTitle : ''}
              onChange={e => {
                setJobTitle(e.target.value);
              }}
              name="first_name"
              w="100%"
              required={'true'}
              borderRadius="5px"
            />

            <Flex mt="5">
              {/* 
              BUG: TODO: The department code is not fixed.
              Suraj - Please rewrite the logic. Saving time calling the Get Department API is very bad
              <Box w="30%" mr="5">
                <UpdateJobDepartment
                  jobDepartmentName={jobDepartmentData}
                  triggerDepartmentSave={triggerSaveChange}
                  sendDepartmentDetails={fetchDepartmentDetails}
                ></UpdateJobDepartment>
              </Box> */}

              <Box w="30%" alignItems="center">
                <InputField
                  mb="0"
                  me="30px"
                  id="jobCode"
                  label="Internal Code"
                  value={jobCode}
                  name="jobCode"
                  w="100%"
                  borderRadius="5px"
                  onChange={e => {
                    setJobCode(e.target.value);
                  }}
                />
              </Box>
            </Flex>
            <Text
              my="5"
              fontSize="18px"
              fontWeight="bold"
              padding="10px"
              bg={schemeBorderColor}
              color={schemeTextColor}
            >
              Location
            </Text>
            {/* location */}
            <Flex alignItems="center" justifyContent={'space-between'}>
              <Flex w={'200px'}>
                <Select
                  value={jobLocationExtended}
                  onChange={e => {
                    setJobLocationExtended(e.target.value);
                  }}
                >
                  <option selected disabled>
                    Please Select
                  </option>
                  <option value="on_site">On-site</option>
                  <option value="hybrid">Hybrid</option>
                  <option value="on_the_road">On the road</option>
                  <option value="general_location">
                    General location, within a limited area
                  </option>
                  <option value="remote">Remote</option>
                </Select>
              </Flex>
              {jobLocationExtended != 'remote' ? (
                <Box w="80% !important">
                  <Text fontSize={'14px'} fontWeight={'bold'} mb="2">
                    Job Location
                  </Text>
                  <Box
                    w={'100%'}
                    borderWidth={'0.5px'}
                    rounded={'md'}
                    borderColor={'gray.400'}
                  >
                    <Autocomplete
                      apiKey={googleLocationKey}
                      value={jobLocationValue}
                      color={'red.200'}
                      // TODO: Avkash

                      onPlaceSelected={handlePlaceSelected}
                      options={{
                        types: ['establishment', 'geocode'], //['(cities)'],
                        componentRestrictions: { country: 'usa' },
                      }}
                      onChange={e => {
                        setJobLocationValue(e.target.value);
                      }}
                      placeholder="Example:New York,NY 10019, United States"
                      style={{
                        width: '100%',
                        border: `1px solid ${schemeBorderColor}`,
                        padding: '8px',
                        borderRadius: '5px',
                        background: 'none',
                      }}
                    />
                  </Box>
                  <Text as="span" fontSize="12px" color="gray.400">
                    Include the city for office-based or hybrid jobs.Fully
                    remote jobs needs at least a country
                  </Text>
                </Box>
              ) : null}
              {/* ) : null}
               */}
              {/* <Box w="18%" ml={5}>
                <Checkbox
                  fontSize={themeFontSizeMediumHeading}
                  size="md"
                  borderColor={schemeBorderColor}
                  mr="3"
                  isChecked={selectedLocation}
                  onChange={e => {
                    setSelectedLocation(!selectedLocation);
                  }}
                >
                  <Text
                    as="span"
                    p="1"
                    px="3"
                    borderRadius={'10px'}
                    bg="gray.200"
                    fontSize={'14px'}
                    fontWeight="bold"
                    color="#000"
                  >
                    {' '}
                    Fully Remote
                  </Text>
                </Checkbox>{' '}
              </Box> */}
            </Flex>
            <Text
              my="5"
              fontSize="18px"
              fontWeight="bold"
              padding="10px"
              bg={schemeBorderColor}
              color={schemeTextColor}
            >
              Description
            </Text>
            {/* description */}
            <Box>
              <Flex
                alignItems={'center'}
                justifyContent={'space-between'}
                mb="2"
              >
                <Flex>
                  <CgAsterisk color={errorColor} />
                  <Text fontSize={'14px'} fontWeight={'bold'}>
                    {' '}
                    About the role
                  </Text>
                </Flex>
                <Spacer />
                {/* <Button
                  variant="outline"
                  borderRadius="5px"
                  borderColor={schemeBorderColor}
                  fontSize={'14px'}
                  size="sm"
                  mr="5px"
                  fontWeight="normal"
                  onClick={() => {
                    onOpen();
                  }}
                  isDisabled={jobDescriptionLoading ? true : false}
                  isLoading={jobDescriptionLoading ? true : false}
                >
                  Get Job Description from PDF
                </Button> */}
                <Button h={'30px'} variant="outline" borderRadius="5px" borderColor={schemeBorderColor}
                  fontSize={'14px'} size="sm" fontWeight="normal"
                  onClick={e => {
                    generateAutomatedRequirement('jd');
                  }}
                  isDisabled={generatingDescriptionWithAI} isLoading={generatingDescriptionWithAI}
                >
                  Generate with AI
                  <Icon ml={2} as={RiSparkling2Fill} fontSize={'20px'} color={'blue.500'} />
                </Button>
                <Button ml={2} h={'30px'} variant="outline" borderRadius="5px" borderColor={schemeBorderColor}
                  fontSize={'14px'} size="sm" fontWeight="normal"
                  onClick={() => {
                    onJobDescriptionUploadOpen();
                  }}
                  isDisabled={jobDescriptionLoading} isLoading={jobDescriptionLoading}
                >
                  Upload Job Description
                </Button>
              </Flex>
              <Box borderWidth={'1px'} borderRadius={'5px'} p="3">
                <Text fontWeight={'bold'} mb="2" fontSize={'16px'} ml="4">
                  Description
                </Text>
                <Editor
                  tinymceScriptSrc={'/tinymce/tinymce.min.js'}
                  name='job_template_description'
                  value={jobDescription || ""}
                  onEditorChange={(content) => setJobDescription(content)}
                  init={{
                    min_height: 800,
                    branding: false,
                    statusbar: false,
                    menubar: 'edit insert view format table tools',
                    promotion: false,
                    plugins: ['advlist', 'anchor', 'autolink', 'autoresize', 'autosave', 'charmap', 'code',
                              'codesample', 'directionality', 'emoticons', 'help', 'importcss', 'insertdatetime',
                              'link', 'lists', 'nonbreaking', 'pagebreak', 'save', 'searchreplace', 'table', 
                              'template', 'visualblocks', 'visualchars', 'wordcount'
                            ],
                    toolbar: 'undo redo | bold italic | alignleft aligncenter alignright alignjustify | indent outdent',
                    contextmenu: false,  
                  }}
                />
                <Box mt={5}>
                  <Flex w={'100%'}>
                    <Text fontWeight={'bold'} mb="2" fontSize={'16px'} ml="4">
                      Benefits
                    </Text>
                    <Spacer />
                    <Button
                      h={'30px'}
                      variant="outline"
                      borderRadius="5px"
                      borderColor={schemeBorderColor}
                      fontSize={'14px'}
                      size="sm"
                      mr="5px"
                      fontWeight="normal"
                      onClick={() => {
                        collectBusinessSurvey();
                        setReloadData(true)
                      }}
                    >
                      Reload from Survey
                    </Button>
                  </Flex>
                  <Flex justifyContent={'space-between'} mb="3">
                    <SimpleGrid mt="1">
                      <Flex mt="2" flexWrap={'wrap'}>
                        {renderBusinessSurveyData}
                      </Flex>
                    </SimpleGrid>
                  </Flex>
                </Box>
                <Box mt={5}>
                  <Text fontWeight={'bold'} mb="2" fontSize={'16px'} ml="4">
                    Supplemental Pay
                  </Text>
                  <Flex justifyContent={'space-between'} mb="3">
                    <SimpleGrid mt="1">
                      <Flex mt="2" flexWrap={'wrap'}>
                        {renderSupplementalPayData}
                      </Flex>
                    </SimpleGrid>
                  </Flex>
                </Box>
              </Box>
            </Box>
            {/* requirement */}
            {/* <Box>
              <Flex
                alignItems={'center'}
                justifyContent={'space-between'}
                my="2"
              >
                <Text fontSize={'14px'} fontWeight={'bold'}>
                  Requirement
                </Text>
                <Button
                  variant="outline"
                  borderRadius="5px"
                  borderColor={schemeBorderColor}
                  fontSize={'14px'}
                  size="sm"
                  p="5px"
                  mr="5px"
                  fontWeight="normal"
                  onClick={e => {
                    generateAutomatedRequirement('req');
                  }}
                  isDisabled={jobRequirementLoading ? true : false}
                  isLoading={jobRequirementLoading ? true : false}
                >
                  {' '}
                  Generate Automated Job Requirement
                </Button>
              </Flex>
              <Box w="100%">
                <Input
                  fontWeight="500"
                  rows={jobRequirement?.length ? 20 : 5}
                  h={'auto'}
                  p="5px"
                  mt="1"
                  as="textarea"
                  overflow="auto"
                  placeholder="Enter Job Requirement"
                  borderWidth={'0.5px'}
                  borderRadius={'5px'}
                  borderColor={schemeBorderColor}
                  fontSize={14}
                  value={jobRequirement}
                  isDisabled={jobRequirementLoading ? true : false}
                  onChange={e => {
                    setJobRequirement(e.target.value);
                  }}
                ></Input>
              </Box>
            </Box> */}
            {/* benifits */}

            <Text
              my="5"
              fontSize="18px"
              fontWeight="bold"
              padding="10px"
              bg={schemeBorderColor}
              color={schemeTextColor}
            >
              Employment Details
            </Text>
            <Box>
              <Box>
                {/* Job Department */}
                <Flex
                  flexWrap={'wrap'}
                  justifyContent={'space-between'}
                  alignItems={'center'}
                >
                  {/* education */}
                  <Box w="48%">
                    <Text fontSize={'14px'} fontWeight={'bold'} mb="2">
                      Education:
                    </Text>

                    <Select
                      fontSize={themeFontSizeMediumHeading}
                      size="md"
                      borderColor={schemeBorderColor}
                      value={educationRequired}
                      onChange={e => {
                        setEducationRequired(e.target.value);
                      }}
                    >
                      {renderEducationOption}
                    </Select>
                  </Box>

                  {/* Experience */}
                  <Box w="48%">
                    <Text fontSize={'14px'} fontWeight={'bold'} mb="2">
                      Experience Level Required:
                    </Text>

                    <Select
                      fontSize={themeFontSizeMediumHeading}
                      size="md"
                      borderColor={schemeBorderColor}
                      value={experienceRequired}
                      onChange={e => {
                        setExperienceRequired(e.target.value);
                      }}
                    >
                      {renderExperienceRequired}
                    </Select>

                    {/* <Box w="100%">{renderExperienceOption}</Box> */}
                  </Box>
                  
                  {/* employement type */}
                  <Box w="48%" mt="5">
                    <Text fontSize={'14px'} fontWeight={'bold'}>
                      Employment Type:
                    </Text>

                    <Box w="100%">
                      <Select
                        fontSize={themeFontSizeMediumHeading}
                        size="md"
                        borderColor={schemeBorderColor}
                        value={employementType}
                        onChange={e => {
                          setEmployementType(e.target.value);
                        }}
                      >
                        {renderEmployementData}
                      </Select>
                    </Box>
                  </Box>

                  <Box w="48%" mt="5">
                    <Text fontSize={'14px'} fontWeight={'bold'} mb={2}>
                      Top Job Skills (3):
                    </Text>
                    <Flex alignItems="center" w="100%">
                      <TagsInput
                        // tab, enter, comma 
                        addKeys={[9, 13, 188, 186]}
                        value={jobKeywods}
                        onChange={onTagChange}
                        borderRadius={'md'}
                        inputProps={{
                          placeholder:
                            jobKeywods?.length === 0
                              ? 'Enter Important Job Skills'
                              : '',
                        }}
                      />
                    </Flex>
                  </Box>
                </Flex>
              </Box>

              {/* salary details */}
              {/* Range From */}
              <Text
                my="5"
                fontSize="18px"
                fontWeight="bold"
                padding="10px"
                bg={schemeBorderColor}
                color={schemeTextColor}
              >
                Payment Details
              </Text>
              <Box borderBottom={'1px'} borderColor={schemeBorderColor} pb="10">
                <Flex
                  w="100%"
                  justifyContent={'space-between'}
                  alignItems="center"
                  flexWrap="wrap"
                >
                  <Flex w={'15%'}>
                    <Box>
                      <Text
                        color={textColorPrimary}
                        fontSize="sm"
                        fontWeight="bold"
                        _hover={{ cursor: 'pointer' }}
                      >
                        Show pay by
                      </Text>
                      <Select
                        size="sm"
                        value={jobSalaryOption}
                        onChange={e => {
                          setJobSalaryOption(e.target.value);
                        }}
                      >
                        <option disabled value="">
                          Please Select
                        </option>
                        <option value="Range">Range</option>
                        <option value="Starting Amount" selected>
                          Starting Amount
                        </option>
                        <option value="Maximum Amount">Maximum Amount</option>
                        <option value="Exact Amount">Exact Amount</option>
                      </Select>
                    </Box>
                  </Flex>
                  {jobSalaryOption === 'Range' ? (
                    <Flex w={'45%'}>
                      <Box w="50%">
                        <FormLabel fontSize={'14px'} fontWeight={'bold'}>
                          From
                        </FormLabel>
                        <NumberInput
                          onChange={valueString => {
                            setFromSalary(valueString);
                          }}
                          value={formatCurrency(fromSalary)}
                          max={1000000}
                        >
                          <NumberInputField />
                        </NumberInput>
                      </Box>
                      <Box w="50%">
                        <FormLabel fontSize={'14px'} fontWeight={'bold'}>
                          To
                        </FormLabel>
                        <NumberInput
                          onChange={valueString => {
                            setToSalary(valueString);
                          }}
                          value={formatCurrency(toSalary)}
                          max={1000000}
                        >
                          <NumberInputField />
                        </NumberInput>
                      </Box>
                    </Flex>
                  ) : null}
                  {jobSalaryOption === 'Starting Amount' ? (
                    <Flex w={'45%'}>
                      <Box w="100%">
                        <FormLabel fontSize={'14px'} fontWeight={'bold'}>
                          Starting Amount
                        </FormLabel>
                        <NumberInput
                          onChange={valueString => {
                            setFromSalary(valueString);
                          }}
                          value={formatCurrency(fromSalary)}
                          max={1000000}
                        >
                          <NumberInputField />
                        </NumberInput>
                      </Box>
                    </Flex>
                  ) : null}
                  {jobSalaryOption === 'Maximum Amount' ? (
                    <Flex w={'45%'}>
                      <Box w="100%">
                        <FormLabel fontSize={'14px'} fontWeight={'bold'}>
                          Maximum Amount
                        </FormLabel>
                        <NumberInput
                          onChange={valueString => {
                            setToSalary(valueString);
                          }}
                          value={formatCurrency(toSalary)}
                          max={1000000}
                        >
                          <NumberInputField />
                        </NumberInput>
                      </Box>
                    </Flex>
                  ) : null}
                  {jobSalaryOption === 'Exact Amount' ? (
                    <Flex w={'45%'}>
                      <Box w="100%">
                        <FormLabel fontSize={'14px'} fontWeight={'bold'}>
                          Exact Amount
                        </FormLabel>
                        <NumberInput
                          onChange={valueString => {
                            setToSalary(valueString);
                          }}
                          value={formatCurrency(toSalary)}
                          max={1000000}
                        >
                          <NumberInputField />
                        </NumberInput>
                      </Box>
                    </Flex>
                  ) : null}
                  <Box w="20%">
                    <FormLabel fontSize={'14px'} fontWeight={'bold'}>
                      Salary Rate
                    </FormLabel>

                    <Select
                      fontSize={'14px'}
                      size="md"
                      borderColor={schemeBorderColor}
                      value={salaryRate}
                      onChangfontSize={'14px'}
                      onChange={e => {
                        setSalaryRate(e.target.value);
                      }}
                    >
                      {renderSalaryRate}
                    </Select>
                  </Box>
                  <Box w="18%">
                    <FormLabel fontSize={'14px'} fontWeight={'bold'}>
                      Currency
                    </FormLabel>

                    <Select
                      fontSize={'14px'}
                      borderColor={schemeBorderColor}
                      value={currency}
                      onChange={e => {
                        setCurrency(e.target.value);
                      }}
                    >
                      {renderCurrencyList}
                    </Select>
                  </Box>
                </Flex>
              </Box>
              <Flex w={'100%'} my="5" bg={schemeBorderColor}>
                <Text
                  fontSize="18px"
                  fontWeight="bold"
                  padding="10px"
                  color={schemeTextColor}
                >
                  Company industry and Job function
                </Text>
                <Spacer />
                <Icon
                  _hover={{'cursor': 'pointer'}}
                  onClick={() => setShowAdvance(!showAdvance)}
                  mt={2}
                  h={8}
                  w={8}
                  as={showAdvance ? AiOutlineCaretRight : AiOutlineCaretDown}
                />
              </Flex>
              <Box hidden={showAdvance}>
                <Flex
                  w="100%"
                  alignItems="center"
                  justifyContent={'space-between'}
                  mb="5"
                  flexWrap={'wrap'}
                >
                  <Box w="49%">
                    <Text fontSize={'14px'} fontWeight={'bold'}>
                      Job Function:
                    </Text>
                    <Flex alignItems="center" w="100%">
                      <Select
                        fontSize={themeFontSizeMediumHeading}
                        size="md"
                        borderColor={schemeBorderColor}
                        value={jobFunction}
                        name="jobFunction"
                        placeholder="Please Select Job Function"
                        onChange={e => {
                          setJobFunction(e.target.value);
                        }}
                      >
                        {renderFunctionList}
                      </Select>
                    </Flex>
                  </Box>

                  <Box w="49%">
                    <Text fontSize={'14px'} fontWeight={'bold'}>
                      Company Industry:
                    </Text>
                    <Flex alignItems="center" w="100%">
                      <Select
                        fontSize={themeFontSizeMediumHeading}
                        size="md"
                        borderColor={schemeBorderColor}
                        placeholder="Please Select Job Industry"
                        value={jobIndustry}
                        name="jobIndustry"
                        onChange={e => {
                          setJobIndustry(e.target.value);
                        }}
                      >
                        {renderIndustryList}
                      </Select>
                    </Flex>
                  </Box>

                  {/* <Box w="49%" mr="3" mt="5">
                      <Text fontSize={'14px'} fontWeight={'bold'}>
                        Work from home supported?
                      </Text>

                      <Flex alignItems="center" mt="3">
                        <Checkbox
                          mr="10"
                          defaultChecked
                          colorScheme="blue"
                          isChecked={workFromHomeState}
                          onChange={e => {
                            setWorkFromHomeStatus(e.target.checked);
                          }}
                        ></Checkbox>
                        
                        {workFromHomeState ? (
                          <Box>
                            <Slider
                              // color="#000"
                              id="slider"
                              defaultValue={sliderValue}
                              min={1}
                              max={5}
                              w="200px"
                              colorScheme="blue"
                              onChange={v => {
                                setSliderValue(v);
                              }}
                              onMouseEnter={() => {
                                setShowTooltip(true);
                              }}
                              onMouseLeave={() => {
                                setShowTooltip(false);
                              }}
                            >
                              <SliderMark value={1} mt="1" ml="-2.5" fontSize="sm">
                                1
                              </SliderMark>
                              <SliderMark value={2} mt="1" ml="-2.5" fontSize="sm">
                                2
                              </SliderMark>
                              <SliderMark value={3} mt="1" ml="-2.5" fontSize="sm">
                                3
                              </SliderMark>
                              <SliderMark value={4} mt="1" ml="-2.5" fontSize="sm">
                                4
                              </SliderMark>
                              <SliderMark value={5} mt="1" ml="-2.5" fontSize="sm">
                                5
                              </SliderMark>
                              <SliderTrack>
                                <SliderFilledTrack />
                              </SliderTrack>
                              <Tooltip
                                hasArrow
                                bg="blue.500"
                                // color="black"
                                placement="top"
                                isOpen={showTooltip}
                                label={`${sliderValue}`}
                              >
                                <SliderThumb />
                              </Tooltip>
                            </Slider>
                          </Box> 
                        ) : null}
                      </Flex>
                    </Box> */}
                </Flex>
              </Box>

              <Flex w={'100%'} my="5" bg={schemeBorderColor}>
                <Text fontSize="18px" fontWeight="bold" padding="10px" color={schemeTextColor} >
                  Screening Questions
                </Text>
                <Spacer />
                <Icon _hover={{'cursor': 'pointer'}} onClick={() => setShowScreening(!showScreening)}
                  mt={2} h={8} w={8} as={showScreening ? AiOutlineCaretRight : AiOutlineCaretDown}
                />
              </Flex>
              <Box hidden={showScreening}>
                <JobApplicationForm
                  job={job} 
                  setQuestionErrors={setQuestionErrors}
                  setScreeningQuestions={setScreeningQuestions} 
                  questionErrors={questionErrors}
                />
              </Box>

              <Button
                mt="10px"
                size="md"
                colorScheme="yellow"
                onClick={() => {
                  setJobSaveStage('draft');
                  saveData('draft');
                }}
                isDisabled={saveButtonLoading ? true : false}
                isLoading={saveButtonLoading ? true : false}
              >
                Save Draft
              </Button>
              {jobWorkflowStarted === false ? (
                <Button
                  ml={5}
                  mt="10px"
                  size="md"
                  colorScheme="green"
                  onClick={() => {
                    onOpen();
                  }}
                  isDisabled={saveButtonLoading ? true : false}
                  isLoading={saveButtonLoading ? true : false}
                >
                  Start Sourcing
                </Button>
              ) : null}
            </Box>
          </Box>
        </Box>
        <Modal
          onClose={() => {
            onCloseModal();
          }}
          isOpen={isOpen}
          isCentered
        >
          <ModalContent>
            <ModalHeader>Start Sourcing Candidates</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <Text>
                Choosing this option will start to match candidates and do outreach for this position. 
                <br/><br/>
                Please ensure all job details are correct by pressing "Cancel" otherwise click "Start Sourcing" to proceed.  
              </Text>
            </ModalBody>
            <ModalFooter>
              <Button
                colorScheme="green"
                onClick={() => {
                  setJobSaveStage('final');
                  saveData('final');
                  onCloseModal();
                }}
                isDisabled={saveButtonLoading ? true : false}
                isLoading={saveButtonLoading ? true : false}
                mr="2"
              >
                Start Sourcing
              </Button>
              <Button
                colorScheme="red"
                onClick={() => {
                  onCloseModal();
                }}
              >
                Cancel
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
        <Drawer size="xl"
          isOpen={isJobDescriptionUploadOpen}
          placement="right"
          onClose={onJobDescriptionUploadClose}
          finalFocusRef={btnPdfRef}
        >
          <DrawerContent pl={5}  overflow={'auto'} p={2} borderLeftWidth="0.5px" borderLeftColor="gray.200">
            <DrawerHeader>Select a Document for Job Description</DrawerHeader>
            <DrawerCloseButton />
            <PDfJobDocPanel
              emailId={email}
              job_uuid={job_uuid}
              jobDescriptionText={jobDescriptionData}
              onJobDescriptionUploadClose={onJobDescriptionUploadClose}
            />
          </DrawerContent>
        </Drawer>
      </Flex>
    );
  };

  return (
    <>
      {loading ? (
        <Flex alignItems="center" justifyContent="center" mt="10" h="70vh">
          <Spinner size="xl" />
        </Flex>
      ) : (
        renderJobDetailsData()
      )}
    </>
  );
}

export default EditJobDetails;
