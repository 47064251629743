import React, { useEffect, useState } from 'react';
import { Button, Text, Flex, Input, Drawer, DrawerContent, DrawerHeader, DrawerCloseButton, useToast, useColorModeValue, NumberInput, NumberInputField, Select, Checkbox} from '@chakra-ui/react';
import { getEmailId } from '../../../../utils/localStorageIndex';
import { submitCandidateProfileDetails } from '../../../../services/recruiterServices';
import Multiselect from 'multiselect-react-dropdown';


export default function EditCandidateHeadlines(props) {
  const {candidate, job} = props;
  const toast = useToast()
  const expectation = JSON.parse(candidate?.expectation || "{}")
  const expectedSalary = expectation?.salary || {}
  const [form, setForm] = useState(() => {
    return {
      ...candidate,
      job_uuid: job?.job_uuid,
      email: getEmailId(),
      candidate_email_address: candidate?.email,
      candidate_phone_number: candidate?.phone,
    }
  })
  const [isFormSubmitting, setIsFormSubmitting] = useState(false)
  const brandColor = useColorModeValue('brand.500', 'white');
  const [salaryNegotiable, setSalaryNegotiable] = useState(expectedSalary?.is_negotiable || false)
  const [selectedPayType, setSelectedPayType] = useState(expectedSalary?.pay_type || 'Range');
  const [selectedRateType, setSelectedRateType] = useState(expectedSalary?.rate_type || 'per hour');
  const [minAmount, setMinAmount] = useState(expectedSalary?.min);
  const [maxAmount, setMaxAmount] = useState(expectedSalary?.max);
  const [amount, setAmount] = useState(expectedSalary?.amount);
  const workStatusOptions = [
    { id: 'RTW', name: 'RTW' },
    { id: 'Green Card', name: 'Green Card' },
    { id: 'Visa', name: 'Visa'},
  ];

  const [workStatus, setWorkStatus] = useState(() => {
     const existingWorkStatus = []
     workStatusOptions.map(status => {
       if (JSON.parse(candidate?.workflow?.tags_work_status || "[]")?.includes(status?.name)){
        existingWorkStatus.push(status)
       }
     })
     return existingWorkStatus
  })

  useEffect(() => {
    if (minAmount == '-9007199254740991'){
      setMinAmount('')
    }
    if (maxAmount == '-9007199254740991'){
      setMaxAmount('')
    }
    if (amount == '-9007199254740991'){
      setAmount('')
    }
  }, [minAmount, maxAmount, amount])

  const formatCurrency = (num) => {
    if (!num || num == '-9007199254740991'){
      return '$'
    }

    let numStr = num.toString();
    return '$' + numStr.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
  }

  const setFormField = (fieldName, fieldValue) => {
    const updatedForm = form
    updatedForm[fieldName] = fieldValue
    setForm({...updatedForm})
  }

  const onSubmitForm = () => {
    const updatedForm = form
    const updatedExpectation = expectation
    updatedExpectation['salary'] = {
      pay_type: selectedPayType,
      rate_type: selectedRateType,
      min: minAmount,
      max: maxAmount,
      amount: amount,
      is_negotiable: salaryNegotiable,      
    }
    updatedForm['candidate_expectation'] = JSON.stringify(updatedExpectation)
    updatedForm['work_status'] = workStatus?.map(status => status?.name)

    setIsFormSubmitting(true);
    submitCandidateProfileDetails(updatedForm)
    .then( res => {
        if (res.result){
          props.getAllCandidatesForJob()
          toast({
            title: 'Candidate updated succesfully.',
            status: 'success', 
            isClosable: true, 
            duration: 3000
          });
        } else {
          toast({
            title: 'Unable to update candidate.',
            description: res.message,
            status: 'error', 
            isClosable: true, 
            duration: 3000
          });
        }
        setIsFormSubmitting(false);        
    })
    .catch( err =>  {
        setIsFormSubmitting(false);
        toast({
            title: 'Unable to update candidate.', 
            description: err.toString(),
            status: 'error', 
            isClosable: true, 
            duration: 3000
        });
    })
  }

  return (
    <Drawer size={'xl'} isOpen={props.isEditHeadlinesPanelOpen} onClose={props.onEditHeadlinesPanelClose}>
      <DrawerContent pl={5} >
        <DrawerHeader>Edit Candidate</DrawerHeader>
        <DrawerCloseButton />
          <Flex pl={'30px'} direction={'column'} mt={5}>

            <Flex>
              <Flex direction={'column'}>
                <Flex>
                  <Text color={'red'} mr={1}>*</Text>
                  <Text as='b' mb={1} fontSize={'16px'}>First Name</Text>
                </Flex>
                <Input defaultValue={form?.first_name} isRequired={true} onChange={(e) => setFormField('first_name', e.target.value)} width={'350px'} />
              </Flex>

              <Flex direction={'column'} ml={5}>
                <Flex>
                  <Text color={'red'} mr={1}>*</Text>
                  <Text as='b' mb={1} fontSize={'16px'}>Last Name</Text>
                </Flex>
                <Input defaultValue={form?.last_name} isRequired={true} onChange={(e) => setFormField('last_name', e.target.value)} name={'last_name'} width={'350px'} />
              </Flex>
            </Flex>

            <Flex mt={10}>
              <Flex direction={'column'}>
                <Text as='b' mb={1} fontSize={'16px'}>Email</Text>
                <Input  defaultValue={form?.candidate_email_address} onChange={(e) => setFormField('candidate_email_address', e.target.value)} width={'350px'} />
              </Flex>

              <Flex direction={'column'} ml={5}>
                <Text as='b' mb={1} fontSize={'16px'}>Phone</Text>
                <Input defaultValue={form?.candidate_phone_number} onChange={(e) => setFormField('candidate_phone_number', e.target.value)} width={'350px'} />
              </Flex>
            </Flex>  

            <Flex mt={10}>
              <Flex direction={'column'}>
                <Text as='b' mb={1} fontSize={'16px'}>Latest Title</Text>
                <Input defaultValue={form?.latest_title} onChange={(e) => setFormField('latest_title', e.target.value)} width={'350px'} />
              </Flex>

              <Flex direction={'column'} ml={5}>
                <Text as='b' mb={1} fontSize={'16px'}>Current Location</Text>
                <Input defaultValue={form?.current_location} onChange={(e) => setFormField('current_location', e.target.value)} width={'350px'} />
              </Flex>
            </Flex>

            <Flex mt={10}>
              <Flex direction={'column'}>
                <Text as='b' mb={1} fontSize={'16px'}>Current Company</Text>
                <Input defaultValue={form?.current_company}onChange={(e) => setFormField('current_company', e.target.value)} width={'350px'} />
              </Flex>

              <Flex direction={'column'} ml={5}>
                <Text as='b' mb={1} fontSize={'16px'}>Experience in Months</Text>
                <Input defaultValue={form?.experience_month} onChange={(e) => setFormField('experience_month', e.target.value)} width={'350px'} />
              </Flex>
            </Flex>

            <Flex mt={10}>
              <Flex direction={'column'}>
                  <Text as='b' mb={1} fontSize={'16px'}>Highest Education</Text>
                  <Input defaultValue={form?.highest_education} onChange={(e) => setFormField('highest_education', e.target.value)} width={'350px'} />
              </Flex>

              <Flex direction={'column'} ml={5}>
                  <Text as='b' mb={1} fontSize={'16px'}>Work Status</Text>
                  <Multiselect options={workStatusOptions} style={{multiselectContainer: {'width': '350px', 'border-color': 'inherit'}, inputField: {'height': '24px'}}}
                  onSelect={selectedWorkStatus => setWorkStatus(selectedWorkStatus)}
                  onRemove={selectedWorkStatus => setWorkStatus(selectedWorkStatus)}
                  selectedValues={workStatus} placeholder='select work status'
                  displayValue="name"
                />
              </Flex>
            </Flex>

            <Flex direction={'column'} mt={12}>
              <Text as='b' mb={1} fontSize={'16px'}>Expected Salary</Text>
              <Flex alignItems={'center'}>

                <Flex alignItems={'center'}>
                  <Text as={'b'} mt={1}>Pay Type</Text>
                  <Select value={selectedPayType} ml={2} width={'190px'} onChange={e => setSelectedPayType(e.target.value)}>
                    <option selected={selectedPayType == 'Range'} value="Range">Range</option>
                    <option selected={selectedPayType == 'Starting Amount'} value="Starting Amount">Starting Amount</option>
                    <option selected={selectedPayType == 'Maximum Amount'} value="Maximum Amount">Maximum Amount</option>
                    <option selected={selectedPayType == 'Exact Amount'} value="Exact Amount">Exact Amount</option>
                  </Select>
                </Flex>

                {selectedPayType === 'Range' ? (
                  <>
                    <Flex alignItems={'center'} ml={5} mt={3} >
                      <Text as={'b'}>Minimum</Text>
                      <NumberInput ml={2} width={'95px'} max={1000000} value={formatCurrency(minAmount)} onChange={value => setMinAmount(value)}>
                        <NumberInputField pl={1} pr={1} />
                      </NumberInput>
                    </Flex>
                    <Flex alignItems={'center'} ml={5} mt={3} >
                      <Text as={'b'}>Maximum</Text>
                      <NumberInput ml={2} width={'95px'} max={1000000} value={formatCurrency(maxAmount)} onChange={value => setMaxAmount(value)} >
                        <NumberInputField pl={1} pr={1} />
                      </NumberInput>
                    </Flex>
                  </>
                ) : null}
                {selectedPayType === 'Starting Amount' ? (
                  <Flex alignItems={'center'} ml={5} mt={3} >
                    <Text as={'b'}>Amount</Text>
                    <NumberInput ml={2} width={'150px'} max={1000000} value={formatCurrency(minAmount)} onChange={value => setMinAmount(value)}>
                      <NumberInputField />
                    </NumberInput>
                  </Flex>
                ) : null}
                {selectedPayType === 'Maximum Amount' ? (
                  <Flex alignItems={'center'} ml={5} mt={3} >
                    <Text as={'b'}>Amount</Text>
                    <NumberInput ml={2} width={'150px'} max={1000000} value={formatCurrency(maxAmount)} onChange={value => setMaxAmount(value)}>
                      <NumberInputField />
                    </NumberInput>
                  </Flex>
                ) : null}
                {selectedPayType === 'Exact Amount' ? (
                  <Flex alignItems={'center'} ml={5} mt={3} >
                    <Text as={'b'}>Amount</Text>
                    <NumberInput ml={2} width={'150px'} max={1000000} value={formatCurrency(amount)} onChange={value => setAmount(value)}>
                      <NumberInputField />
                    </NumberInput>
                  </Flex>
                ) : null}

                <Flex ml={5} alignItems={'center'}>
                  <Text as={'b'} mt={1}>Rate</Text>
                  <Select ml={2} width={'150px'} value={selectedRateType} onChange={e => setSelectedRateType(e.target.value)}>
                    <option selected={selectedRateType == 'per hour'} value="per hour">per hour</option>
                    <option selected={selectedRateType == 'per day'} value="per day">per day</option>
                    <option selected={selectedRateType == 'per week'} value="per week">per week</option>
                    <option selected={selectedRateType == 'per month'} value="per month">per month</option>
                    <option selected={selectedRateType == 'per year'} value="per year">per year</option>
                  </Select>
                </Flex>             
              </Flex>        

              <Flex alignItems={'center'} mt={10}>
                  <Text as='b' fontSize={'16px'}>is Salary Negotiable?</Text>
                  <Checkbox ml={5} isChecked={salaryNegotiable}
                    onChange={(e) => setSalaryNegotiable(e.target.checked)}
                  />
                </Flex>        
            </Flex>

            <Button onClick={onSubmitForm} width={'200px'} mt={12} variant="brand" _hover={{'bg': brandColor}}
                fontWeight="500" isLoading={isFormSubmitting} loadingText={'Updating...'}
            >
              Update
            </Button>
          </Flex>
      </DrawerContent>
    </Drawer>    
  );
}
