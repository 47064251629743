import {
  Avatar,
  Button,
  Flex,
  Icon,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Stack,
  Badge,
  Table,
  Tbody,
  Td,
  Text,
  useToast,
  Th,
  Thead,
  Tr,
  Switch,
  useColorModeValue,
} from '@chakra-ui/react';
import { SearchBar } from '../../../../../components/navbar/searchBar/SearchBar';
import React, { useMemo, useState } from 'react';
import { MdChevronRight, MdChevronLeft } from 'react-icons/md';
import {
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from 'react-table';
import moment from 'moment';
import { enableDisableRecruiters } from '../../../../../services/sharedServices';
import { fetchRecruiterData } from '../../../../../services/recruiterServices';

function SearchTable2(props) {
  const { columnsData, tableData } = props;
  const [recruiterData, setRecruiterData] = useState(tableData);
  const toast = useToast();
  const columns = useMemo(() => columnsData, [columnsData]);
  const data = useMemo(() => recruiterData, [recruiterData]);
  const [loading, isLoading] = useState(true);

  const tableInstance = useTable(
    {
      columns,
      data,
      initialState: {
        pageSize: recruiterData.length,
      },
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    gotoPage,
    pageCount,
    prepareRow,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    setGlobalFilter,
    state,
  } = tableInstance;

  const createPages = count => {
    let arrPageCount = [];

    for (let i = 1; i <= count; i++) {
      arrPageCount.push(i);
    }

    return arrPageCount;
  };

  const { pageIndex, pageSize } = state;
  const textColor = useColorModeValue('secondaryGray.900', 'white');
  const borderColor = useColorModeValue('gray.200', 'whiteAlpha.100');
  const brandColor = useColorModeValue('brand.500', 'brand.400');
  const badgeBgRedColor = useColorModeValue('red.100', 'red.800');
  const badgeBgBlueColor = useColorModeValue('blue.100', 'blue.800');

  // fetch data

  const changeCurrentUserStatus = (uuId, status) => {
    const updatedArr = recruiterData.map(singleRowObj =>
      singleRowObj.uuid === uuId
        ? { ...singleRowObj, is_disabled: !status }
        : singleRowObj
    );
    setRecruiterData(updatedArr);
    let objBody = {
      email: props.emailId,
      user_uuid: uuId, // Please add the select row UUID
      disabled: !status, // please add switch state
    };
    enableDisableRecruiters(objBody)
      .then(res => {
        if (res.result) {
          toast({
            title: res?.message,
            status: 'success',
            isClosable: true,
            duration: 3000,
          });
          props.onDataReceived();
        } else {
          toast({
            title: res?.message,
            status: 'error',
            isClosable: true,
            duration: 3000,
          });
        }
      })
      .catch(err => {
        toast({
          title: err?.message,
          status: 'error',
          isClosable: true,
          duration: 3000,
        });
      });
  };

  return (
    <>
      <Flex
        direction="column"
        w="100%"
        overflowX={{ sm: 'scroll', lg: 'hidden' }}
      >
        <Flex
          align={{ sm: 'flex-start', lg: 'flex-start' }}
          justify={{ sm: 'flex-start', lg: 'flex-start' }}
          w="100%"
          px="22px"
          mb="36px"
        >
          <SearchBar
            onChange={e => setGlobalFilter(e.target.value)}
            h="44px"
            w={{ lg: '390px' }}
            borderRadius="16px"
          />
        </Flex>
        <Table {...getTableProps()} variant="simple" color="gray.500" mb="24px">
          <Thead>
            {headerGroups.map((headerGroup, index) => (
              <Tr {...headerGroup.getHeaderGroupProps()} key={index}>
                {headerGroup.headers.map((column, index) => (
                  <Th
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    pe="10px"
                    key={index}
                    borderColor={borderColor}
                  >
                    <Flex
                      justify="space-between"
                      align="center"
                      fontSize={{ sm: '10px', lg: '12px' }}
                      color="gray.400"
                    >
                      {column.render('Header')}
                    </Flex>
                  </Th>
                ))}
              </Tr>
            ))}
          </Thead>
          <Tbody {...getTableBodyProps()}>
            {page.map((row, index) => {
              prepareRow(row);
              return (
                <Tr {...row.getRowProps()} key={index}>
                  {row.cells.map((cell, subIndex) => {
                    let data = '';
                    if (cell.column.Header === 'USER NAME') {
                      data = (
                        <Flex align="center">
                          <Avatar
                            src={cell.value[1]}
                            h="60px"
                            w="60px"
                            me="10px"
                          />
                          <Text
                            color={textColor}
                            fontSize="md"
                            fontWeight="500"
                          >
                            {cell.value[0]}
                          </Text>
                        </Flex>
                      );
                    } else if (cell.column.Header === 'EMAIL') {
                      data = (
                        <Text color={textColor} fontSize="md" fontWeight="500">
                          {cell.value}
                        </Text>
                      );
                    } else if (cell.column.Header === 'DISABLED STATUS') {
                      data = (
                        <Text color={textColor} fontSize="md" fontWeight="500">
                          {cell.value ? (
                            <Badge p={1} color={'white'} bg={'green.500'}>
                              {' '}
                              Yes
                            </Badge>
                          ) : (
                            <Badge p={1} color={'white'} bg={'red.500'}>
                              {' '}
                              No
                            </Badge>
                          )}
                        </Text>
                      );
                    } else if (cell.column.Header === 'JOIN DATE') {
                      data = (
                        <Text color={textColor} fontSize="md" fontWeight="500">
                          {moment(cell.value).format('MMM DD,YYYY,HH:mm a')}
                        </Text>
                      );
                    } else if (cell.column.Header === 'DISABLE ACTION') {
                      data = (
                        <Switch
                          onChange={() => {
                            changeCurrentUserStatus(
                              tableData[index]?.uuid,
                              cell.value
                            );
                          }}
                          isChecked={cell.value ? true : false}
                          colorScheme="green"
                        />
                      );
                    }
                    return (
                      <Td
                        {...cell.getCellProps()}
                        key={index}
                        fontSize={{ sm: '14px' }}
                        minW={{ sm: '150px', md: '200px', lg: 'auto' }}
                        borderColor={borderColor}
                      >
                        {data}
                      </Td>
                    );
                  })}
                </Tr>
              );
            })}
          </Tbody>
        </Table>
        {/* <Flex
          direction={{ sm: 'column', md: 'row' }}
          justify="space-between"
          align="center"
          w="100%"
          px={{ md: '22px' }}
        >
          <Text
            fontSize="sm"
            color="gray.500"
            fontWeight="normal"
            mb={{ sm: '24px', md: '0px' }}
          >
            Showing {pageSize * pageIndex + 1} to{' '}
            {pageSize * (pageIndex + 1) <= tableData.length
              ? pageSize * (pageIndex + 1)
              : tableData.length}{' '}
            of {tableData.length} entries
          </Text>
          <Stack direction="row" alignSelf="flex-end" spacing="4px" ms="auto">
            <Button
              variant="no-effects"
              onClick={() => previousPage()}
              transition="all .5s ease"
              w="40px"
              h="40px"
              borderRadius="50%"
              bg="transparent"
              border="1px solid"
              borderColor={useColorModeValue('gray.200', 'white')}
              display={
                pageSize === 5 ? 'none' : canPreviousPage ? 'flex' : 'none'
              }
              _hover={{
                bg: 'whiteAlpha.100',
                opacity: '0.7',
              }}
            >
              <Icon as={MdChevronLeft} w="16px" h="16px" color={textColor} />
            </Button>
            {pageSize === 5 ? (
              <NumberInput
                max={pageCount - 1}
                min={1}
                w="75px"
                mx="6px"
                defaultValue="1"
                onChange={e => gotoPage(e)}
              >
                <NumberInputField />
                <NumberInputStepper>
                  <NumberIncrementStepper onClick={() => nextPage()} />
                  <NumberDecrementStepper onClick={() => previousPage()} />
                </NumberInputStepper>
              </NumberInput>
            ) : (
              createPages(pageCount).map((pageNumber, index) => {
                return (
                  <Button
                    variant="no-effects"
                    transition="all .5s ease"
                    onClick={() => gotoPage(pageNumber - 1)}
                    w="40px"
                    h="40px"
                    borderRadius="50%"
                    bg={
                      pageNumber === pageIndex + 1 ? brandColor : 'transparent'
                    }
                    border={
                      pageNumber === pageIndex + 1
                        ? 'none'
                        : '1px solid lightgray'
                    }
                    _hover={
                      pageNumber === pageIndex + 1
                        ? {
                            opacity: '0.7',
                          }
                        : {
                            bg: 'whiteAlpha.100',
                          }
                    }
                    key={index}
                  >
                    <Text
                      fontSize="sm"
                      color={pageNumber === pageIndex + 1 ? '#fff' : textColor}
                    >
                      {pageNumber}
                    </Text>
                  </Button>
                );
              })
            )}
            <Button
              variant="no-effects"
              onClick={() => nextPage()}
              transition="all .5s ease"
              w="40px"
              h="40px"
              borderRadius="50%"
              bg="transparent"
              border="1px solid"
              borderColor={useColorModeValue('gray.200', 'white')}
              display={pageSize === 5 ? 'none' : canNextPage ? 'flex' : 'none'}
              _hover={{
                bg: 'whiteAlpha.100',
                opacity: '0.7',
              }}
            >
              <Icon as={MdChevronRight} w="16px" h="16px" color={textColor} />
            </Button>
          </Stack>
        </Flex> */}
      </Flex>
    </>
  );
}

export default SearchTable2;
