import { AlertDialog, AlertDialogBody, AlertDialogContent, AlertDialogFooter, AlertDialogHeader, AlertDialogOverlay, Button, useToast } from "@chakra-ui/react";
import { updateJobArchiveStatus } from "../../../../services/jobTemplateService";
import { useState } from "react";
import { getEmailId } from "../../../../utils/localStorageIndex";

export default function CloseJob(props){

    const {job} = props;
    const toast = useToast()
    const [isJobClosing, setIsJobClosing] = useState(false);

    const onCloseJob = () => {
        setIsJobClosing(true)
        let body = {
            email: getEmailId(),
            job_uuid: job?.job_uuid,
            };
        updateJobArchiveStatus(body)
        .then(res => {
            if (res?.result && res?.data?.length > 0 && res.data[0].status) {
                toast({
                        title: 'Job closed successfully.', 
                        status: 'success',
                        duration: 3000
                    });
                props.updateJobDetails({job_status: 'Closed'})
                props.onCloseJobPanelClose();
            }
            else {
                toast({
                        title: 'Unable to close job.',
                        description: res?.message,
                        status: 'error',
                        duration: 3000
                    });
            }
            setIsJobClosing(false)
        })
        .catch(err => {
            toast({
                    title: 'Unable to close job.',
                    description: err.toString(),
                    status: 'error',
                    duration: 3000
                });
            setIsJobClosing(false)
        });
    }

    return <>
        <AlertDialog isOpen={props.isCloseJobPanelOpen} onClose={props.onCloseJobPanelClose}>
          <AlertDialogOverlay>
            <AlertDialogContent>
              <AlertDialogHeader fontSize='lg' fontWeight='bold'>
                Close Job
              </AlertDialogHeader>

              <AlertDialogBody fontSize={'lg'}>
                Are you sure you want to close this job? You can't undo this action afterwards.
              </AlertDialogBody>

              <AlertDialogFooter>
                <Button onClick={props.onCloseJobPanelClose}>
                  Cancel
                </Button>
                <Button isLoading={isJobClosing} loadingText={'Closing...'} colorScheme='red' onClick={onCloseJob} ml={3}>
                  Close It
                </Button>
              </AlertDialogFooter>
            </AlertDialogContent>
          </AlertDialogOverlay>
        </AlertDialog>
    </>
}
