import axios, { clearbitAxios } from './axios';
import { serviceUrls } from './serviceUrls';

export const fetchCompanyDetails = (
  data,
  config = null,
  axiosInstance = axios
) => {
  return axiosInstance.post(serviceUrls().jobCompanyList, data, config);
};

export const fetchCandidatesByCompany = (
  data,
  config = null,
  axiosInstance = axios
) => {
  return axiosInstance.post(serviceUrls().companyCandidateList, data, config);
};

export const fetchJobByBusiness = (
  data,
  config = null,
  axiosInstance = axios
) => {
  return axiosInstance.post(serviceUrls().fetchJobByBusiness, data, config);
};

export const getCompaniesLogosByDomain = (
  data,
  config = null,
  axiosInstance = axios
) => {
  return axiosInstance.post(serviceUrls().companyLogosByDomain, data, config);
};

export const getCompanyDetailsByName = (
  companyName,
  config = null,
  axiosInstance = clearbitAxios
) => {
  return axiosInstance.get(companyName, {}, config);
};

export const updateCompanyLogo = (
  data,
  config = null,
  axiosInstance = axios
) => {
  return axiosInstance.post(serviceUrls().updateCompanyLogo, data, config);
};

export const addCandidateExperience = (
  data,
  config = null,
  axiosInstance = axios
) => {
  return axiosInstance.post(serviceUrls().addCandidateExperience, data, config);
};


export const addCandidateEducation= (
  data,
  config = null,
  axiosInstance = axios
) => {
  return axiosInstance.post(serviceUrls().addCandidateEducation, data, config);
};
