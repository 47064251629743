import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import 'react-tagsinput/react-tagsinput.css';
import JobIntakeWizard1 from './component/JobIntakeWizard1';
import JobIntakeWizard2 from './component/JobIntakeWizard2';
import JobIntakeWizard3 from './component/JobIntakeWizard3';
import JobIntakeWizard4 from './component/JobIntakeWizard4';
import JobIntakeWizard5 from './component/JobIntakeWizard5';
import Card from '../../../../../src/components/card/Card';
// import EmployeeEducation from './component/EmployeeEducation';
// import EmployementWorkingRate from './component/EmployeeWorkingRate';
// import EmployeeSuplimentalPay from './component/EmployeeSupplimentPay';
import { setJobWizardStatusLevel } from '../../../../store/actions/topNotificationActions';
import { Box,Flex, SimpleGrid, Stack, Tab, TabList, TabPanel, TabPanels, Tabs, Text
} from '@chakra-ui/react';

export default function Wizard() {
  const dispatch = useDispatch();
  const [activeBullets, setActiveBullets] = useState({
    title: true,
    synopsis: false,
    employement: false,
    benifits: false,
    location: false,
    experience: false,
    education: false,
    quotedAmount: false,
    workingRate: false,
    supplimentalPayTab: false,
    surveyRequest: false,
  });

  const [jobData, setJobData] = useState({})

  const titleTab = React.useRef();
  const synopsisTab = React.useRef();
  const employmentType = React.useRef();
  const benifitsTab = React.useRef();
  const screeningTab = React.useRef()
  // const locationTab = React.useRef();
  // const experienceTab = React.useRef();
  // const educationTab = React.useRef();
  // const quotedAmountTab = React.useRef();
  // const workingRateTab = React.useRef();
  // const supplimentalPayTab = React.useRef();
  // const surveyRequestTab = React.useRef();

  const tabSelection = data => {
    switch (data) {
      case 'titleTab':
        return titleTab.current.click();
      case 'synopsisTab':
        return synopsisTab.current.click();
      case 'employmentType':
        return employmentType.current.click();
      case 'benifitsTab':
        return benifitsTab.current.click();
        case 'screeningTab':
          return screeningTab.current.click();
      // case 'locationTab':
      //   return locationTab.current.click();
      // case 'experienceTab':
      //   return experienceTab.current.click();
      // case 'educationTab':
      //   return educationTab.current.click();
      // case 'quotedAmountTab':
      //   return quotedAmountTab.current.click();
      // case 'workingRateTab':
      //   return workingRateTab.current.click();
      // case 'supplimentalPayTab':
      //   return supplimentalPayTab.current.click();
      // case 'surveyRequestTab':
      //   return surveyRequestTab.current.click();
      default:
        return titleTab.current.click();
    }
  };

  return (
    <Flex direction="column" minH="100vh" align="center" position="relative">
      <Box
        h="45vh"
        bgGradient="linear(to-b, brand.400, brand.600)"
        position="absolute"
        w="100%"
        borderRadius="20px"
      />

      <Tabs
        variant="unstyled"
        zIndex="0"
        // mt={{ base: '60px', md: '80px' }}
        mt="30px"
        display="flex"
        flexDirection="column"
        overflow="hidden"
      >
        <TabList
          display="flex"
          alignItems="center"
          alignSelf="center"
          justifySelf="center"
          flexWrap="wrap"
        >
          <Tab
            _focus={{ border: '0px', boxShadow: 'unset' }}
            ref={titleTab}
            w={{ sm: '200px', md: '200px', lg: '200px' }}
            fontSize={'13px'}
            onClick={() => {
              setActiveBullets({
                title: true,
                synopsis: false,
                employement: false,
                benifits: false,
                location: false,
                experience: false,
                education: false,
                quotedAmount: false,
                workingRate: false,
                supplimentalPayTab: false,
                surveyRequest: false,
                screening: false
              });
              dispatch(setJobWizardStatusLevel(1));
            }}
          >
            <Flex
              direction="column"
              justify="center"
              align="center"
              position="relative"
              _before={{
                content: "''",
                width: { sm: '200px', md: '200px', lg: '200px' },
                height: '3px',
                bg: activeBullets.synopsis ? 'white' : 'brand.400',
                left: { sm: '12px', md: '20px' },
                top: {
                  sm: activeBullets.title ? '6px' : '4px',
                  md: null,
                },
                position: 'absolute',
                bottom: activeBullets.title ? '40px' : '38px',
                transition: 'all .3s ease',
              }}
            >
              <Box
                zIndex="1"
                border="2px solid"
                borderColor={activeBullets.title ? 'white' : 'brand.400'}
                bgGradient="linear(to-b, brand.400, brand.600)"
                w="16px"
                h="16px"
                mb="8px"
                borderRadius="50%"
              />
              <Text
                color={activeBullets.title ? 'white' : 'gray.300'}
                fontWeight={activeBullets.title ? 'bold' : 'normal'}
                display={{ sm: 'none', md: 'block' }}
              >
                Title
              </Text>
            </Flex>
          </Tab>
          <Tab
            _focus={{ border: '0px', boxShadow: 'unset' }}
            ref={synopsisTab}
            w={{ sm: '200px', md: '200px', lg: '200px' }}
            fontSize={'13px'}
            onClick={() => {
              setActiveBullets({
                title: true,
                synopsis: true,
                employement: false,
                benifits: false,
                location: false,
                experience: false,
                education: false,
                quotedAmount: false,
                workingRate: false,
                supplimentalPayTab: false,
                surveyRequest: false,
                screening: false
              });
              dispatch(setJobWizardStatusLevel(2));
            }}
          >
            <Flex
              direction="column"
              justify="center"
              align="center"
              position="relative"
              _before={{
                content: "''",
                width: { sm: '200px', md: '200px', lg: '200px' },
                height: '3px',
                bg: activeBullets.employement ? 'white' : 'brand.400',
                left: { sm: '12px', md: '20px' },
                top: '6px',
                position: 'absolute',
                bottom: activeBullets.synopsis ? '40px' : '38px',
                transition: 'all .3s ease',
              }}
            >
              <Box
                zIndex="1"
                border="2px solid"
                borderColor={activeBullets.synopsis ? 'white' : 'brand.400'}
                bgGradient="linear(to-b, brand.400, brand.600)"
                w="16px"
                h="16px"
                mb="8px"
                borderRadius="50%"
              />
              <Text
                color={activeBullets.synopsis ? 'white' : 'gray.300'}
                fontWeight={activeBullets.synopsis ? 'bold' : 'normal'}
                display={{ sm: 'none', md: 'block' }}
              >
                Type
              </Text>
            </Flex>
          </Tab>
          <Tab
            _focus={{ border: '0px', boxShadow: 'unset' }}
            ref={employmentType}
            w={{ sm: '200px', md: '200px', lg: '200px' }}
            fontSize={'13px'}
            onClick={() => {
              setActiveBullets({
                title: true,
                synopsis: true,
                employement: true,
                benifits: false,
                location: false,
                experience: false,
                education: false,
                quotedAmount: false,
                workingRate: false,
                supplimentalPayTab: false,
                surveyRequest: false,
                screening: false
              });
              dispatch(setJobWizardStatusLevel(3));
            }}
          >
            <Flex
              direction="column"
              justify="center"
              align="center"
              position="relative"
              _before={{
                content: "''",
                width: { sm: '200px', md: '200px', lg: '200px' },
                height: '3px',
                bg: activeBullets.benifits ? 'white' : 'brand.400',
                left: { sm: '12px', md: '20px' },
                top: '6px',
                position: 'absolute',
                bottom: activeBullets.employement ? '40px' : '38px',
                transition: 'all .3s ease',
              }}
            >
              <Box
                zIndex="1"
                border="2px solid"
                borderColor={activeBullets.employement ? 'white' : 'brand.400'}
                bgGradient="linear(to-b, brand.400, brand.600)"
                w="16px"
                h="16px"
                mb="8px"
                borderRadius="50%"
              />
              <Text
                color={activeBullets.employement ? 'white' : 'gray.300'}
                fontWeight={activeBullets.employement ? 'bold' : 'normal'}
                display={{ sm: 'none', md: 'block' }}
              >
                Pay
              </Text>
            </Flex>
          </Tab>
          <Tab
            _focus={{ border: '0px', boxShadow: 'unset' }}
            ref={benifitsTab}
            w={{ sm: '200px', md: '200px', lg: '200px' }}
            fontSize={'13px'}
            onClick={() => {
              setActiveBullets({
                title: true,
                synopsis: true,
                employement: true,
                benifits: true,
                location: false,
                experience: false,
                education: false,
                quotedAmount: false,
                workingRate: false,
                supplimentalPayTab: false,
                surveyRequest: false,
                screening: false
              });
              dispatch(setJobWizardStatusLevel(4));
            }}
          >
            <Flex
              direction="column"
              justify="center"
              align="center"
              position="relative"
              _before={{
                content: "''",
                width: { sm: '200px', md: '200px', lg: '200px' },
                height: '3px',
                bg: activeBullets.screening ? 'white' : 'brand.400',
                left: { sm: '12px', md: '20px' },
                top: '6px',
                position: 'absolute',
                bottom: activeBullets.benifits ? '40px' : '38px',
                transition: 'all .3s ease',
              }}
            >
              <Box
                zIndex="1"
                border="2px solid"
                borderColor={activeBullets.benifits ? 'white' : 'brand.400'}
                bgGradient="linear(to-b, brand.400, brand.600)"
                w="16px"
                h="16px"
                mb="8px"
                borderRadius="50%"
              />
              <Text
                color={activeBullets.benifits ? 'white' : 'gray.300'}
                fontWeight={activeBullets.benifits ? 'bold' : 'normal'}
                display={{ sm: 'none', md: 'block' }}
              >
                Description
              </Text>
            </Flex>
          </Tab>
          <Tab
            _focus={{ border: '0px', boxShadow: 'unset' }}
            ref={screeningTab}
            w={{ sm: '200px', md: '200px', lg: '200px' }}
            fontSize={'13px'}
            onClick={() => {
              setActiveBullets({
                title: true,
                synopsis: true,
                employement: true,
                benifits: true,
                location: false,
                experience: false,
                education: false,
                quotedAmount: false,
                workingRate: false,
                supplimentalPayTab: false,
                surveyRequest: false,
                screening: true
              });
              dispatch(setJobWizardStatusLevel(5));
            }}
          >
            <Flex
              direction="column"
              justify="center"
              align="center"
              position="relative"
            >
              <Box
                zIndex="1"
                border="2px solid"
                borderColor={activeBullets.screening ? 'white' : 'brand.400'}
                bgGradient="linear(to-b, brand.400, brand.600)"
                w="16px"
                h="16px"
                mb="8px"
                borderRadius="50%"
              />
              <Text
                color={activeBullets.screening ? 'white' : 'gray.300'}
                fontWeight={activeBullets.screening ? 'bold' : 'normal'}
                display={{ sm: 'none', md: 'block' }}
              >
                Screening
              </Text>
            </Flex>
          </Tab>
        </TabList>
        <TabPanels mt="24px" maxW={{ md: '90%', lg: '100%' }} mx="auto">
          <TabPanel
            w={{ sm: '800px', md: '800px', lg: '800px' }}
            p="0px"
            mx="auto"
          >
            <Card p="30px">
              <Flex direction="column" w="100%">
                <Stack direction="column" spacing="20px">
                  <JobIntakeWizard1 onDataUpdate={tabSelection} />
                </Stack>
              </Flex>
            </Card>
          </TabPanel>
          <TabPanel
            w={{ sm: '800px', md: '800px', lg: '800px' }}
            p="0px"
            mx="auto"
          >
            <Card p="30px">
              <Flex direction="column" w="100%">
                <Stack direction="column" spacing="20px">
                  <SimpleGrid columns={{ base: 1, md: 1 }} gap="20px">
                    <JobIntakeWizard2 onDataUpdate={tabSelection} />
                  </SimpleGrid>
                </Stack>
              </Flex>
            </Card>
          </TabPanel>
          <TabPanel
            w={{ sm: '800px', md: '800px', lg: '800px' }}
            p="0px"
            mx="auto"
          >
            <Card p="30px">
              <Flex direction="column" w="100%">
                <Stack direction="column" spacing="20px">
                  <JobIntakeWizard3 onDataUpdate={tabSelection} />
                </Stack>
              </Flex>
            </Card>
          </TabPanel>
          <TabPanel
            w={{ sm: '800px', md: '800px', lg: '800px' }}
            p="0px"
            mx="auto"
          >
            <Card p="30px">
              <Flex direction="column" w="100%">
                <JobIntakeWizard4 onDataUpdate={tabSelection} setJobData={setJobData} />
              </Flex>
            </Card>
          </TabPanel>
          <TabPanel
            w={{ sm: '600px', md: '700px', lg: '800px', xl: '900px', '2xl': '1200px' }}
            p="0px"
            mx="auto"
          >
            <Card p="30px">
              <Flex direction="column" w="100%">
                <JobIntakeWizard5 onDataUpdate={tabSelection} job={jobData} />
              </Flex>
            </Card>
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Flex>
  );
}
