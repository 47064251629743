import React, { useEffect, useRef, useState } from 'react';
import {useToast, Tooltip, Text, Button, IconButton, useDisclosure, Flex, Drawer, DrawerContent, DrawerHeader,
  DrawerCloseButton, DrawerBody, DrawerFooter, Input
} from '@chakra-ui/react';
import { MdEmail } from 'react-icons/md';
import { sendBulkEmail, uploadFile } from '../../../../services/recruiterServices';
import { Editor } from '@tinymce/tinymce-react';
import { CkeditorUtil } from '../../../../utils/ckeditor';
import TagsInput from 'react-tagsinput';
import { getEmailId } from '../../../../utils/localStorageIndex';
import EmailTemplateSelection from '../../../common/EmailTemplateSelection';
import EmailTemplateVariablesMenu from '../../../common/EmailTemplateVariablesMenu';
import { isObjectEmpty } from '../../../../utils/common';


export default function SendEmail(props) {
  const toast = useToast()
  const emailId = getEmailId()
  const {job, selectedCandidates, postEmailActions} = props
  const {onOpen: onSendEmailPanelOpen, onClose: onSendEmailPanelClose, isOpen: isSendEmailPanelOpen} = useDisclosure()
  const [isEmailSending, setIsEmailSending] = useState(false);
  const [emailForm, setEmailForm] = useState({
      email: emailId,
      job_uuid: job?.job_uuid,
      subject: null,
      message: null,
      recipient_emails: []
  });
  const [toEmails, setToEmails] = useState([])
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const editorRef = useRef(null);

  useEffect(() => {
    const selectedCandidateEmails = {}
    const emails = []
    selectedCandidates.map(candidate => {
      if (candidate?.email){
        emails.push(candidate?.email)
        if (candidate?.job?.job_uuid in selectedCandidateEmails){
          selectedCandidateEmails[candidate?.job?.job_uuid].push(candidate?.email)
        }else{
          selectedCandidateEmails[candidate?.job?.job_uuid] = [candidate?.email]
        }
      }
    })

    setToEmails([...new Set(emails)])
    setEmailForm({...emailForm, recipient_emails: selectedCandidateEmails})
  }, [selectedCandidates])

  useEffect(() => {
    if (selectedTemplate){
      setEmailForm({...emailForm, subject: selectedTemplate?.subject, message: selectedTemplate?.content})
    }
  }, [selectedTemplate])

  const insertVariableIntoEmailBody = (content) => {
    editorRef.current.insertContent(content)
  }

  const validateEmailForm = () => {
    if (isObjectEmpty(emailForm?.recipient_emails)){
      toast({
        title: "Recipient email address is required",
        status: 'error',
        duration: 3000
      })
      return false
    }
    if (!emailForm.subject){
      toast({
        title: "Email subject is required",
        status: 'error',
        duration: 3000
      })
      return false
    }
    if (!emailForm.message){
      toast({
        title: "Email message is required",
        status: 'error',
        duration: 3000
      })
      return false
    }

    return true
  }

  const onSendEmail = () => { 
    if (!validateEmailForm()){
      return
    }

    setIsEmailSending(true);
    const data = emailForm
    data['message'] = CkeditorUtil.getContentWithLineStyles(data['message'])
    sendBulkEmail(data)
    .then(res => {
        if (res.result) {
            setIsEmailSending(false)
            toast({
              title: 'Email sent successfully.', 
              status: 'success',
              isClosable: true,
              duration: 3000,
            });

            // call any post send email functions
            for (const [fnName, fn] of Object.entries(postEmailActions)) {
              fn()
            }
            return
        }
        
        setIsEmailSending(false);
        toast({
          title: 'Unable to send email',
          description: res.message,
          status: 'error',
          duration: 3000,
        });

    })
    .catch( err => {
      setIsEmailSending(false);
      console.log(err)
      toast({
          title: 'Unable to send email', 
          description: err.toString(),
          status: 'error',
          duration: 3000,
      });
    })
  }

  const handleFileUpload = (file, progress) => {
    const form = new FormData();
    form.append('file', file.blob());
    form.append('email', emailId)
    const promise = new Promise((resolve, reject) => {
      uploadFile(form)
      .then(res => {
        if (res?.result && res?.data?.file_url){
          resolve(res?.data?.file_url)
        }else{
          reject({message: res?.message, remove: true})
        }
      })
      .catch(err => {
        reject({message: err?.toString(), remove: true})
      })
    })

    return promise
  }

  return (
        <Flex>
          <Tooltip label={'Send Email. You can select multiple candidates and send email in bulk.'} fontSize='md'>
            <span>
              <IconButton isDisabled={selectedCandidates.length == 0} onClick={onSendEmailPanelOpen} bg={'none'} fontSize={'19px'} _hover={{'bg': 'none'}} icon={<MdEmail />} color={'gray'} />
            </span>
          </Tooltip>

          {/* send email form */}
          <Drawer trapFocus={false} disableEnforceFocus={true} size={'xl'} isOpen={isSendEmailPanelOpen} onClose={onSendEmailPanelClose}>
            <DrawerContent overflow={'auto'} pl={5}>
              <DrawerHeader>Send email to selected {selectedCandidates?.length > 1 ? 'candidates' : 'candidate'}</DrawerHeader>
              <DrawerCloseButton />
              <DrawerBody>
                
                <Flex direction={'column'} width={'60%'}>
                    <Text as='b'>To</Text>
                    <TagsInput value={toEmails} maxTags={toEmails?.length}
                        disabled
                        renderLayout={(tagElements, inputElement) => <span> {tagElements} </span> }
                    />
                    <Text as={'i'} mt={1}>candidates without email will be removed from recipients</Text>
                </Flex>

                <Flex direction={'column'} mt={10} width={'60%'}>
                    <Text as='b'>Subject</Text>
                    <Input value={emailForm.subject || ""} placeholder={'Invitation to Interview'} onChange={e => setEmailForm({...emailForm, subject: e.target.value})}/>
                </Flex>

                <EmailTemplateSelection
                  subject={emailForm.subject}
                  emailBody={emailForm.message} // for saving current email body as template
                  setSelectedTemplate={setSelectedTemplate} // to use template content as email body
                  containerMarginTop={5}
                />

                <Flex direction={'column'} border={'1px solid'} borderColor={'gray.200'} mt={5}>
                <Editor
                    tinymceScriptSrc={'/tinymce/tinymce.min.js'}
                    name='message'
                    value={emailForm.message || ""}
                    onEditorChange={content => setEmailForm({...emailForm, message: content})}
                    onInit={(evt, editor) => (editorRef.current = editor)}                
                    init={{
                      placeholder: 'Enter your email message here',
                      min_height: 500,
                      branding: false,
                      statusbar: false,
                      menubar: 'edit insert view format table tools',
                      promotion: false,
                      link_default_target: '_blank',
                      link_title: false,
                      plugins: ['advlist', 'image', 'link', 'anchor', 'autolink', 'autoresize', 'autosave', 'charmap', 'code',
                                'codesample', 'directionality', 'emoticons', 'help', 'importcss', 'insertdatetime',
                                'link', 'lists', 'nonbreaking', 'pagebreak', 'save', 'searchreplace', 'table', 
                                'template', 'visualblocks', 'visualchars', 'wordcount'
                              ],
                      toolbar: 'undo redo | bold italic underline blocks fontsize fontfamily | image link | alignleft aligncenter alignright alignjustify | indent outdent | table',
                      contextmenu: false,
                      images_file_types: 'jpeg,jpg,jpe,jfi,jif,jfif,png,gif,bmp,webp,svg',
                      file_picker_types: 'file',
                      images_upload_handler: handleFileUpload,
                      file_picker_callback: function(callback, value, meta) {
                        if (meta.filetype == 'file'){
                          const input = document.createElement('input');
                          input.setAttribute('type', 'file');
                          input.addEventListener('change', (e) => {
                            const file = e.target.files[0];
                  
                            const reader = new FileReader();
                            reader.addEventListener('load', () => {

                              const id = 'blobid' + (new Date()).getTime();
                              const blobCache =  editorRef.current.editorUpload.blobCache;
                              const base64 = reader.result.split(',')[1];
                              const blobInfo = blobCache.create(id, file, base64);
                              blobCache.add(blobInfo);
                              handleFileUpload(blobInfo, null)
                              .then(res => callback(res, { title: file.name, textToDisplay: file.name }))
                              .catch(err => {
                                toast({
                                  title: 'Unable to upload file',
                                  description: err?.toString(),
                                  status: 'error',
                                  duration: 5000,
                                })
                              })
                            })

                            reader.readAsDataURL(file);

                          })

                          input.click();
                        }
                      }
                    }}
                    />
                </Flex>

                <EmailTemplateVariablesMenu 
                  insertVariableIntoEmailBody={insertVariableIntoEmailBody}
                />

              </DrawerBody>
              <DrawerFooter>
                <Button colorScheme='teal' onClick={onSendEmailPanelClose}>Cancel</Button>
                <Button isLoading={isEmailSending} loadingText='Sending...' ml={'5'}  colorScheme='blue' onClick={onSendEmail}>
                    Send Email
                </Button>
              </DrawerFooter>  
            </DrawerContent>
          </Drawer>
        </Flex>
    )
}
