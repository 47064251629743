import React from 'react';
import { Icon } from '@chakra-ui/react';
import { MdLockClock, MdSupervisorAccount, MdOutlineRunCircle,
    MdCalendarMonth,
     MdThumbUpOffAlt, 
     MdMarkEmailRead} from 'react-icons/md';
import {
  AiOutlineCalendar,
  AiOutlineSetting,
  AiTwotoneSkin,
} from 'react-icons/ai';
import { LiaTelegram, LiaTelegramPlane } from "react-icons/lia";
import { IoPersonCircleOutline } from "react-icons/io5";
import { LuClipboardCheck } from "react-icons/lu";
// Admin Imports
import JobList from '../tscomponents/business/jobWizard/JobList';
import JobWizard from '../tscomponents/business/jobWizard/JobWizard';
import ProfileView from '../tscomponents/business/profile/view/ProfileView';
import BusinessTeam from '../tscomponents/business/team/listing/BusinessTeam';

// Subscription API
// import TeamPurchasePage from '../tscomponents/business/settings/billing/TeamPurchasePage';
import TeamAddMoreSlots from '../tscomponents/business/settings/billing/TeamAddMoreSlots';
import TeamCompletePurchase from '../tscomponents/business/settings/billing/TeamCompletePurchase';

import BusinessScheduling from '../tscomponents/business/scheduling/BusinessScheduling';
import EditJobDetails from '../tscomponents/business/jobWizard/editJobs/EditJobDetails';

// import InterviewProcessing from '../tscomponents/business/jobWizard/jobWorkflow/Workflow/InterviewProcessing/InterviewProcessing';
import ReadyToInterviewCandidates from '../tscomponents/business/applicants/listingbyjob/ReadyToInterviewCandidates';

import BusinessConfiguration from '../tscomponents/business/settings/configuration/BusinessConfiguration';
import CandidateOnboarding from '../tscomponents/business/onboarding/CandidateOnboarding';

// import ReadyToInterviewList from '../tscomponents/business/applicants/listingbyjob/ReadyToInterviewList';
import ViewJobDetails from '../tscomponents/business/jobWizard/viewJobs/ViewJobDetails';
import JobWorkflowCommon from '../tscomponents/common/JobWorkflow';
import CandidateProfileView from '../tscomponents/common/CandidateProfileView';
import EmailTemplateList from '../tscomponents/common/EmailTemplateList';
import { BsFillPersonFill } from 'react-icons/bs';

const businessMemberRoutes = [
  {
    name: 'Team',
    path: '/team',
    layout: '/business',
    component: <BusinessTeam />,
    icon: (
      <Icon
        as={MdSupervisorAccount}
        width="20px"
        height="20px"
        color="inherit"
      />
    ),
    collapse: false,
    showToMenu: true,
  },
  {
    name: 'Jobs',
    layout: '/business',
    path: '/joblist',
    component: <JobList />,
    icon: (
      <Icon as={LiaTelegramPlane} width="20px" height="20px" color="inherit" />
    ),
    collapse: false,
    showToMenu: true,
  },
  {
    name: 'Job Intake Form',
    layout: '/business',
    path: '/addJobWizard',
    component: <JobWizard />,
    secondary: true,
    icon: (
      <Icon as={MdThumbUpOffAlt} width="20px" height="20px" color="inherit" />
    ),
    showToMenu: false,
    collapse: false,
  },
  {
    name: 'Edit Job Wizard',
    layout: '/business',
    path: '/editJobWizard',
    component: <JobWizard />,
    secondary: true,
    icon: (
      <Icon as={MdThumbUpOffAlt} width="20px" height="20px" color="inherit" />
    ),
    showToMenu: false,
    collapse: false,
  },
  {
    name: 'Edit Jobs',
    layout: '/business',
    path: '/editJobs',
    component: <EditJobDetails />,
    secondary: true,
    icon: (
      <Icon as={MdThumbUpOffAlt} width="20px" height="20px" color="inherit" />
    ),
    showToMenu: false,
    collapse: false,
  },
  {
    name: 'View Job',
    layout: '/business',
    path: '/viewJob',
    component: <ViewJobDetails />,
    secondary: true,
    icon: (
      <Icon as={MdThumbUpOffAlt} width="20px" height="20px" color="inherit" />
    ),
    showToMenu: false,
    collapse: false,
  },
  {
    name: 'Job Sourcing',
    layout: '/business',
    path: '/jobsourcing',
    component: <JobWorkflowCommon />,   //Business Kanban view
    secondary: true,
    icon: (
      <Icon as={MdThumbUpOffAlt} width="20px" height="20px" color="inherit" />
    ),
    showToMenu: false,
    collapse: false,
  },
  {
    name: 'Ready To Interview',
    layout: '/business',
    path: '/interview_ready_candidates',
    component: <ReadyToInterviewCandidates />,
    icon: (
      <Icon
        as={IoPersonCircleOutline}
        width="20px"
        height="20px"
        color="inherit"
      />
    ),
    collapse: false,
    showToMenu: true,
  },
  {
    name: 'Candidate Profile',
    layout: '/business',
    path: '/jobsourcing/candidateview',
    component: <CandidateProfileView />,   //Business Kanban view > Candidate View
    secondary: true,
    icon: (
      <Icon as={MdThumbUpOffAlt} width="20px" height="20px" color="inherit" />
    ),
    showToMenu: false,
    collapse: false,
  },
  // {
  //   name: 'Interview Processing',
  //   layout: '/business',
  //   path: '/interviewProcess',
  //   component: <InterviewProcessing />,
  //   secondary: true,
  //   icon: (
  //     <Icon as={MdThumbUpOffAlt} width="20px" height="20px" color="inherit" />
  //   ),
  //   showToMenu: false,
  //   collapse: false,
  // },
  // {
  //   name: 'Applicants',
  //   layout: '/business',
  //   path: '/applicants',
  //   component: <ReadyToInterviewList />,
  //   icon: (
  //     <Icon
  //       as={MdSupervisorAccount}
  //       width="20px"
  //       height="20px"
  //       color="inherit"
  //     />
  //   ),
  //   collapse: false,
  //   showToMenu: true,
  // },
  {
    name: 'Interviewing',
    layout: '/business',
    path: '/calendar',
    component: <BusinessScheduling />,
    icon: (
      <Icon
        as={MdCalendarMonth}
        width="20px"
        height="20px"
        color="inherit"
      />
    ),
    collapse: false,
    showToMenu: true,
  },
  // {
  //   name: 'Scheduling',
  //   layout: '/business',
  //   path: '/calendar',
  //   component: <BusinessScheduling />,
  //   icon: (
  //     <Icon
  //       as={MdCalendarMonth}
  //       width="20px"
  //       height="20px"
  //       color="inherit"
  //     />
  //   ),
  //   collapse: false,
  //   showToMenu: true,
  // },
  // {
  //   name: 'Onboard',
  //   layout: '/business',
  //   path: '/onboarding',
  //   component: <CandidateOnboarding />,
  //   icon: (
  //     <Icon
  //       as={LuClipboardCheck}
  //       width="20px"
  //       height="20px"
  //       color="inherit"
  //     />
  //   ),
  //   collapse: false,
  //   showToMenu: true,
  // },
  {
    name: 'Settings',
    path: '/business',
    icon: (
      <Icon as={AiOutlineSetting} width="20px" height="20px" color="inherit" />
    ),
    collapse: true,
    items: [
      {
        name: 'Templates',
        layout: '/business',
        path: '/templates',
        component: <EmailTemplateList />,
        secondary: true,
        showToMenu: true,
        icon: (
          <Icon as={MdMarkEmailRead} width="14px" height="14px" color="inherit" />
        ),
      },
      {
        name: 'Profile',
        layout: '/business',
        path: '/profile',
        component: <ProfileView />,
        secondary: true,
        showToMenu: true,
        icon: (
          <Icon
            as={BsFillPersonFill}
            width="15px"
            height="15px"
            color="inherit"
          />
        ),
      },
      {
        name: 'Add More Slots',
        layout: '/business',
        path: '/billing/addmoreslots',
        component: <TeamAddMoreSlots />,
        secondary: true,
        showToMenu: false,
      },
      // {
      //   name: 'Complete Purchase',
      //   layout: '/business',
      //   path: '/billing/completepurchase',
      //   component: <TeamCompletePurchase />,
      //   secondary: true,
      //   showToMenu: false,
      // },
    ],
  },
];

export default businessMemberRoutes;
