import {
  Box,
  Flex,
  Text,
  Icon,
  SimpleGrid,
  useColorModeValue,
} from '@chakra-ui/react';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import Card from '../../../../../components/card/Card.js';
import { MdFlag, MdChevronRight } from 'react-icons/md';
import { CircProgressMini } from '../../../../../components/charts/CircularProgress';

export default function Completion(props) {
  const { ...rest } = props;
  const wizardDetails = useSelector(store => store.wizardHeader);
  const wizardStepperLevel = wizardDetails?.wizardStepperLevel;
  const textColor = useColorModeValue('secondaryGray.900', 'white');
  const bgBar = useColorModeValue('secondaryGray.100', 'whiteAlpha.100');
  const textColorSecondary = useColorModeValue(
    'secondaryGray.600',
    'secondaryGray.500'
  );
  const textColorTertiary = useColorModeValue('secondaryGray.600', 'white');
  const stepperArr = [
    { name: 'Employment Accepted', id: 1 },
    { name: 'Roles for hiring.', id: 2 },
    { name: 'Benefits and Perks', id: 3 },
    { name: 'Reach out', id: 4 },
    { name: 'Sister Company Details', id: 5 },
  ];

  return (
    <Card {...rest} maxH="max-content">
      <Text color={textColor} fontSize="xl" fontWeight="700" mb="16px">
        Company Hiring Details
      </Text>
      <Flex alignItems="center" mb="20px">
        <Text color={textColorSecondary} fontSize="md" fontWeight="700">
          {wizardStepperLevel}/5 COMPLETED STATUS
        </Text>
        <Icon ms="auto" as={MdFlag} color="green.500" h="18px" w="18px" />
      </Flex>
      <SimpleGrid columns={5} gap="5px" alignItems="center" mb="20px">
        {stepperArr?.map((data, index) => (
          <Flex
            bg={data?.id <= wizardStepperLevel ? 'green.500' : bgBar}
            h="8px"
            borderRadius="36px"
          />
        ))}
      </SimpleGrid>
      {stepperArr?.map((data, index) => (
        <Flex mb="25px" align="center" cursor="pointer">
          <Box w="30px" me="12px">
            <CircProgressMini
              step={data?.id}
              percentage={data?.id <= wizardStepperLevel ? 100 : 0}
            />
          </Box>
          <Text
            color={data?.id === wizardStepperLevel ? 'green.500' : textColor}
            fontWeight="500"
            fontSize="md"
            me="5px"
          >
            {data?.name}
          </Text>

          <Icon
            as={MdChevronRight}
            ms="auto"
            h="22px"
            w="22px"
            color={textColorTertiary}
          />
        </Flex>
      ))}
    </Card>
  );
}
