export const jobTableColumnList = [
  {
    Header: 'Job Details',
    accessor: 'job_title',
  },
  // {
  //   Header: 'Title Others',
  //   accessor: 'job_title_other',
  // },
  // {
  //   Header: 'Department Name',
  //   accessor: 'job_department_name',
  // },
  // {
  //   Header: 'Stages',
  //   accessor: 'stages', 
  // },
  // {
  //   Header: 'Sourcing',
  //   accessor: 'job_workflow_started',
  // },
  // {
  //   Header: 'Start Sourcing',
  //   accessor: 'sourcing',
  // },
  {
    Header: 'Status',
    accessor: 'job_status',
  },
  // {
  //   Header: 'Slot Status',
  //   accessor: 'job_enabled',
  // },
  {
    Header: 'Edit Job',
    accessor: 'job_uuid',
  },
];
