import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, location } from 'react-router-dom';
import { useSearchParams } from "react-router-dom";
import { defaultThemeColor } from '../../../../constants/index';
import { fetchPlanDetails } from '../../../../services/paymentServices';
import Pack from '../../../../views/admin/main/others/pricing/components/Pack';
import Card from '../../../../components/card/Card';
import {
    AiFillEdit,
    AiOutlineStop,
    AiOutlineCheckCircle,
    AiFillFilePdf,
    AiFillInfoCircle,
    AiOutlineLinkedin,
    AiOutlineFilePdf,
    AiOutlineInfoCircle,
    AiOutlineArrowLeft,
    AiOutlineCaretLeft,
  } from 'react-icons/ai';
import {
  Box, Icon,
  HStack,
  Text,
  Spacer,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  CloseButton,
  UnorderedList,
  List,
  ListItem,
  ListIcon,
  Center,
  Flex,
  Button,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  Slider,
  Link,
  TabPanel,
  useToast,
  SliderFilledTrack,
  SliderThumb,
  SliderMark,
  SliderTrack,
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerContent,
  useDisclosure,
  DrawerCloseButton,
  Spinner,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  ModalBody,
  useColorModeValue,
} from '@chakra-ui/react';
import { completePaymentApi } from '../../../../services/paymentServices';
import { getEmailId } from '../../../../utils/localStorageIndex';


function TeamCompletePurchase() {
    const toast = useToast();
    const navigate = useNavigate();
    const { state } = useLocation();
    const btnRef = React.useRef();
    const customerEmail = state?.customerEmail ? state?.customerEmail : null;
    const customerId = state?.customerId ? state?.customerId : null;
    const [loading, isLoading] = useState(false);

    const textColorPrimary = useColorModeValue('secondaryGray.900', 'gray.600');

    let [purchaseId, setPurchaseId] = useState(null);
    const [searchParams] = useSearchParams();

    const [spinnerVisible, setSpinnerVisible] = useState(true);

    const [alertVisible, setAlertVisible] = useState(false);
    const [alertMessage, setAlertMessage] = useState(
      {
        title:'Bad Setting',
        message:"You do not have proper access to this page.. Please go back to contact Talent Strap support!!",
        type:'error'
      });

    useEffect(() => {
      let pid = searchParams.get("pid");
      if (pid != null && pid != undefined){
        setAlertVisible(true);
        setAlertMessage({
            title:' Thanks for completing the Talent Strap Subscription..',
            message:"Soon you will be redirected to the billing page.. Please wait..",
            type:'success'
        })
        setPurchaseId(pid);
        completePurchase(pid);
      } 
    }, []);

    const completePurchase = (pid) => {
      let objBody = {
        email: getEmailId(),
        pid: pid
      };
      completePaymentApi(objBody)
        .then(res => {
          if (res?.result && res?.data?.length > 0) {
              let a = 10;
              if (res?.data[0]?.payment_status){
                navigate('/business/joblist', {
                  state: {
                      sourcePage:'purchasecomplete',
                      purchaseCompleted: true
                  },
              });
            } else {
              setSpinnerVisible(false);
              setAlertVisible(true);
              setAlertMessage({
                  title:'Failed Business plan subscripton',
                  message:"Failed to setup business plan.. please contact Talent Strap Support!!",
                  type:'error'});
            }
          } else {
            setSpinnerVisible(false);
            setAlertVisible(true);
            setAlertMessage({
                title:'Failed Business plan subscripton',
                message:"Failed to setup business plan.. please contact Talent Strap Support!!",
                type:'error'});
          }
        })
        .catch(err => {
          setSpinnerVisible(false);
          setAlertVisible(true);
          setAlertMessage({
              title:'Failed Business plan subscripton',
              message:"Failed to setup business plan.. please contact Talent Strap Support!!",
              type:'error'});
        });
    };


    const renderSpinner = () => {
      return (
        <Flex h="50vh" alignItems="center" justifyContent="center" mt="10">
          <Spinner size="xl" />
        </Flex>
      );
    };

    return (
      <>
        {loading ? (
          <>{renderSpinner()}</>
        ) : (
          <Flex direction="column" pt={{ sm: '125px', lg: '75px' }}>
            {purchaseId === null
            ?<Flex w={'100%'} p={2} direction={'column'}>
                <Alert status={alertMessage.type} >
                    <AlertIcon />
                    <Box>
                        <AlertTitle>{alertMessage.title}</AlertTitle>
                        <AlertDescription>{alertMessage.message}</AlertDescription>
                    </Box>
                    <Spacer />
                    <CloseButton
                        alignSelf='flex-start'
                        position='relative'
                        right={-1}
                        top={-1}
                    />                    
                </Alert>
              </Flex>
              :<Flex w={'100%'}>
                <Card w={'100%'} p={5} direction={'row'}>
                  <Flex w={'100%'}>
                    <Flex w={'100px'} maxW={'100px'}>
                      {spinnerVisible
                      ?<Spinner mt={1} size="xl" />
                      :<Icon mt={3} as={AiOutlineStop} h={10} w={10} color={'red.300'} />}
                    </Flex>
                    <Flex w={'100%'} direction={'column'} align={'start'}>
                    <Alert status={alertMessage.type} >
                        <AlertIcon />
                        <Box>
                            <AlertTitle>{alertMessage.title}</AlertTitle>
                            <AlertDescription>{alertMessage.message}</AlertDescription>
                        </Box>
                        <Spacer />
                        <CloseButton
                            alignSelf='flex-start'
                            position='relative'
                            right={-1}
                            top={-1}
                        />                    
                    </Alert>
                        <Text fontSize={'xl'}></Text>
                        <Text fontSize={'lg'}></Text>
                    </Flex>
                  </Flex>
                </Card>
              </Flex>} 
          </Flex>
        )}
      </>
    );
}

export default TeamCompletePurchase;
