import React from 'react';

import { Icon } from '@chakra-ui/react';
import { MdCalendarMonth, MdMarkEmailRead, MdSupervisorAccount, MdThumbUpOffAlt,} from 'react-icons/md';
import {AiOutlineSetting} from 'react-icons/ai';
import AdminTeam from '../tscomponents/admin/team/listing/AdminTeam';
import RecruitersManage from '../tscomponents/admin/team/recruiters/RecruitersManage';
import ProfileView from '../tscomponents/admin/profile/view/ProfileView';
import AdminJobSourcing from '../tscomponents/admin/jobs/AdminJobSourcing';
import SourceByUpdate from '../tscomponents/admin/jobs/SourceByUpdate';
import ViewJobDetails from '../tscomponents/business/jobWizard/viewJobs/ViewJobDetails';
// import ScreenCandidates from '../tscomponents/admin/jobs/ScreenCandidates';

// Job Kanban View
import JobWorkflowCommon from '../tscomponents/common/JobWorkflow';
import CandidateProfileView from '../tscomponents/common/CandidateProfileView';
import { BsFillPersonFill, BsPerson, BsPersonFillGear, BsPersonGear } from 'react-icons/bs';
import RecruiterDashboard from '../tscomponents/admin/dashboard/RecruiterDashboard';
import BusinessAndJobs from '../tscomponents/admin/jobs/BusinessAndJobs';
import JobList from '../tscomponents/admin/jobs/JobList';
import JobCandidateList from '../tscomponents/admin/jobs/JobCandidateList';
import ReadyToInterviewCandidates from '../tscomponents/business/applicants/listingbyjob/ReadyToInterviewCandidates';
import BusinessScheduling from '../tscomponents/business/scheduling/BusinessScheduling';
import { IoPersonCircleOutline } from 'react-icons/io5';
import EmailTemplateList from '../tscomponents/common/EmailTemplateList';
import CandidateList from '../tscomponents/admin/jobs/CandidateList';
import SearchedCandidateDetailView from '../tscomponents/admin/jobs/SearchedCandidateDetailView';
import JobApplyPage from '../tscomponents/admin/jobs/jobViewTable/JobApplyPage';
import EditJobDetails from '../tscomponents/business/jobWizard/editJobs/EditJobDetails';

const adminRoutes = [
  // {
  //     name: 'Dashboard',
  //     path: '/admin',
  //     icon: <Icon as={MdHome} width="20px" height="20px" color="inherit" />,
  //     collapse: true,
  //     items: [
  //         {
  //         name: 'View',
  //         layout: '/admin',
  //         path: '/dashboard',
  //         component: <AdminDashboards />,
  //         secondary: true,

  //         },
  //     ]
  // },

  // {
  //   name: 'Jobs',
  //   path: '/admin',
  //   icon: (
  //     <Icon as={MdThumbUpOffAlt} width="20px" height="20px" color="inherit" />
  //   ),
  //   collapse: true,
  //   items: [
  //     {
  //       name: 'Job Sourcing',
  //       layout: '/admin',
  //       path: '/jobsourcing',
  //       component: <AdminJobSourcing />,
  //       secondary: true,
  //       showToMenu: true,
  //     },
  //     {
  //       name: 'View Jobs',
  //       layout: '/admin',
  //       path: '/jobDetailsView',
  //       component: <ViewJobDetails />,
  //       secondary: true,
  //       showToMenu: false,
  //     },
  //     {
  //       name: 'Job Application Form',
  //       layout: '/admin',
  //       path: '/jobApplicationForm',
  //       component: <JobApplicationForm />,
  //       secondary: true,
  //       showToMenu: false,
  //     },
  //     // {
  //     //   name: 'Fake Candidate',
  //     //   layout: '/admin',
  //     //   path: '/fakeCandidate',
  //     //   component: <FakeCandidatesProvider />,
  //     //   secondary: true,
  //     //   showToMenu: false,
  //     // },
  //     {
  //       name: 'Source Candidate',
  //       layout: '/admin',
  //       path: '/sourceCandidate',
  //       component: <SourceByUpdate />,
  //       secondary: true,
  //       showToMenu: false,
  //     },
  //     {
  //       name: 'Screen Candidate',
  //       layout: '/admin',
  //       path: '/screenCandidates',
  //       component: <ScreenCandidates />,
  //       secondary: true,
  //       showToMenu: false,
  //     },
  //     {
  //       name: 'Screen Candidate',
  //       layout: '/admin',
  //       path: '/jobworkflow',   // Access to Common Kanban View
  //       component: <JobWorkflowCommon />,
  //       secondary: true,
  //       showToMenu: false,
  //     },
  //     {
  //       name: 'Candidate Profile',
  //       layout: '/admin',
  //       path: '/jobworkflow/candidateview',
  //       component: <CandidateProfileView />,   //Business Kanban view > Candidate View
  //       secondary: true,
  //       showToMenu: false,
  //       collapse: false,
  //     },
  //     {
  //       name: 'Ready To Interview',
  //       layout: '/admin',
  //       path: '/interview_ready_candidates',
  //       component: <ReadyToInterviewCandidates />,
  //       icon: <Icon as={IoPersonCircleOutline} width="20px" height="20px" color="inherit" />,
  //       collapse: false,
  //       showToMenu: false,
  //     },
  //     {
  //       name: 'Interviewing',
  //       layout: '/admin',
  //       path: '/calendar',
  //       component: <BusinessScheduling />,
  //       icon: (
  //         <Icon as={MdCalendarMonth} width="20px" height="20px" color="inherit" />
  //       ),
  //       collapse: false,
  //       showToMenu: false,
  //     }
    
  //   ],
  // },
  

  {
    name: 'Recruiter',
    path: '/admin',
    icon: (
      <Icon as={BsPersonGear} width="20px" height="20px" color="inherit" />
    ),
    collapse: true,
    items: [
      {
        name: 'Dashboard',
        layout: '/admin',
        path: '/dashboard',
        component: <RecruiterDashboard />,
        secondary: true,
        showToMenu: true,
      },
      {
        name: 'Candidates',
        layout: '/admin',
        path: '/candidatelist',
        component: <CandidateList />,
        secondary: true,
        showToMenu: true,
      },
      {
        name: 'Searched Candidate',
        layout: '/admin',
        path: '/searchedcandidate',
        component: <SearchedCandidateDetailView />,
        secondary: true,
        showToMenu: false,
        },
      {
        name: 'Job List',
        layout: '/admin',
        path: '/joblist',
        component: <JobList />,
        secondary: true,
        showToMenu: false,
      },
      {
        name: 'Candidate List',
        layout: '/admin',
        path: '/jobcandidatelist',
        component: <JobCandidateList />,
        secondary: true,
        showToMenu: false,
      },
      {
        name: 'View Jobs',
        layout: '/admin',
        path: '/jobDetailsView',
        component: <ViewJobDetails />,
        secondary: true,
        showToMenu: false,
      },
      {
        name: 'Job Application Form',
        layout: '/admin',
        path: '/jobapplypage',
        component: <JobApplyPage />,
        secondary: true,
        showToMenu: false,
      },
      {
        name: 'Screen Candidate',
        layout: '/admin',
        path: '/jobworkflow',   // Access to Common Kanban View
        component: <JobWorkflowCommon />,
        secondary: true,
        showToMenu: false,
      },
      {
        name: 'Candidate Profile',
        layout: '/admin',
        path: '/jobworkflow/candidateview',
        component: <CandidateProfileView />,   //Business Kanban view > Candidate View
        secondary: true,
        showToMenu: false,
        collapse: false,
      },
      {
        name: 'Ready To Interview',
        layout: '/admin',
        path: '/interview_ready_candidates',
        component: <ReadyToInterviewCandidates />,
        icon: <Icon as={IoPersonCircleOutline} width="20px" height="20px" color="inherit" />,
        collapse: false,
        showToMenu: false,
      },
      {
        name: 'Interviewing',
        layout: '/admin',
        path: '/calendar',
        component: <BusinessScheduling />,
        icon: (
          <Icon as={MdCalendarMonth} width="20px" height="20px" color="inherit" />
        ),
        collapse: false,
        showToMenu: false,
      },
      {
        name: 'Edit Jobs',
        layout: '/admin',
        path: '/editJobs',
        component: <EditJobDetails />,
        secondary: true,
        icon: (
          <Icon as={MdThumbUpOffAlt} width="20px" height="20px" color="inherit" />
        ),
        showToMenu: false,
        collapse: false,
      },
    ],
  },

  {
    name: 'Team',
    path: '/admin',
    icon: (
      <Icon
        as={MdSupervisorAccount}
        width="20px"
        height="20px"
        color="inherit"
      />
    ),
    collapse: true,
    items: [
      {
        name: 'View',
        layout: '/admin',
        path: '/team',
        component: <AdminTeam />,
        secondary: true,
        showToMenu: true,
      },
    ],
  },

  {
    name: 'Settings',
    path: '/admin',
    icon: (
      <Icon as={AiOutlineSetting} width="20px" height="20px" color="inherit" />
    ),
    collapse: true,
    items: [
      {
        name: 'Team',
        layout: '/admin',
        path: '/manageteam',
        component: <RecruitersManage />,
        secondary: true,
        showToMenu: true,
        icon: (
          <Icon
            as={MdSupervisorAccount}
            width="20px"
            height="20px"
            color="inherit"
          />
        ),
      },
      // {
      //     name: 'Invite Team',
      //     layout: '/admin',
      //     path: '/team/invite',
      //     component: <InviteRecruiter />,
      //     secondary: true,

      // },
      {
        name: 'Templates',
        layout: '/admin',
        path: '/templates',
        component: <EmailTemplateList />,
        secondary: true,
        showToMenu: true,
        icon: (
          <Icon as={MdMarkEmailRead} width="14px" height="14px" color="inherit" />
        ),
      },
      {
        name: 'Profile',
        layout: '/admin',
        path: '/profile',
        component: <ProfileView />,
        secondary: true,
        showToMenu: true,
        icon: (
          <Icon
            as={BsFillPersonFill}
            width="15px"
            height="15px"
            color="inherit"
          />
        ),
      },
    ],
  },
];

export default adminRoutes;
