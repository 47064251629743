import { useNavigate } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getEmailId } from '../../../../../utils/localStorageIndex';
import { setJobWizardStatusLevel } from '../../../../../store/actions/topNotificationActions';
import {
  addjobBenifitsWorkflow,
  saveJobWizardFourData,
  fetchAutomatedAdditionalInfo,
  fetchJobWorkflowDetails,
} from '../../../../../services/jobTemplateService';
import InputField from '../../../../../components/fields/InputField';
import {
  themeFontSizeSmallHeading,
  themeFontSizeMediumHeading,
} from '../../../../../constants/index';
import { customNotification } from '../../../../../utils/customNotification';

import {
  Drawer,
  DrawerBody,
  DrawerHeader,
  DrawerContent, 
  DrawerCloseButton,
  Box,
  Text, Spacer,
  Flex,
  Stack,
  Input,
  Button,
  Switch,
  useToast,
  SimpleGrid,
  useDisclosure,
  useColorModeValue,
} from '@chakra-ui/react';
import PDfJobDocPanel from '../../PDFJobDocPanel';
import { CkeditorUtil } from '../../../../../utils/ckeditor';
import { Editor } from '@tinymce/tinymce-react';
import { RiSparkling2Fill } from 'react-icons/ri'
import { Icon } from '@chakra-ui/icons';


function JobIntakeWizard4(props) {
  const switchArr = [
    { label: 'Health Insurance', value: 'Health Insurance', isChecked: false },
    { label: 'Life Insurance', value: 'Life Insurance', isChecked: false },
    {
      label: 'Relocation Assistance',
      value: 'Relocation Assistance',
      isChecked: false,
    },
    { label: 'Paid Time-Off', value: 'Paid Time-Off', isChecked: false },
    { label: 'Parental Leave', value: 'Parental Leave', isChecked: false },
    { label: 'Dental Insurance', value: 'Dental Insurance', isChecked: false },
    { label: 'Retirement Plan', value: 'Retirement Plan', isChecked: false },
    {
      label: 'Professional Development Assistance',
      value: 'Professional Development Assistance',
      isChecked: false,
    },
    { label: 'Vision Insurance', value: 'Vision Insurance', isChecked: false },
    {
      label: 'Employee Discount',
      value: 'Employee Discount',
      isChecked: false,
    },
    {
      label: 'Employee Assistance Program',
      value: 'Employee Assistance Program',
      isChecked: false,
    },
    {
      label: 'Flexible Schedule',
      value: 'Flexible Schedule',
      isChecked: false,
    },
    {
      label: 'Flexible Spending',
      value: 'Flexible Spending',
      isChecked: false,
    },
    {
      label: 'Tuition Reimbursement',
      value: 'Tuition Reimbursement',
      isChecked: false,
    },
    {
      label: 'Health Savings Account',
      value: 'Health Savings Account',
      isChecked: false,
    },
    { label: 'Other', value: 'Other', isChecked: false },
  ];
  // Todo:Fetch Job Id from store
  const wizardDetails = useSelector(store => store.wizardHeader);
  const job_uuid = wizardDetails?.job_uuId;
  const toast = useToast();
  const btnPdfRef = React.useRef();
  const schemeBorderColor = useColorModeValue('gray.200', 'gray.600');
  const { isOpen: isJobDescriptionUploadOpen, onOpen: onJobDescriptionUploadOpen, onClose: onJobDescriptionUploadClose } = useDisclosure();

  const emailId = getEmailId();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [jobTitle, setJobTitle] = useState('');
  const [skillOne, setSkillOne] = useState('');
  const [skillTwo, setSkillTwo] = useState('');
  const [skillThree, setSkillThree] = useState('');
  const [loading, isLoading] = useState(false);
  const [jobDescription, setJobDescription] = useState('');
  const [jobDescriptionLoading, setJobDescriptionLoading] = useState(false);
  const [generatingDescriptionWithAI, setGeneratingDescriptionWithAI] = useState(false);
  const [saveCompleteFlag, setSaveCompleteFlag] = useState(false);
  const [selectedBenifits, setSelectedBenifits] = useState(switchArr);
  const textColor = useColorModeValue('secondaryGray.900', 'white');
  const pillTextColor = useColorModeValue('navy.700', 'gray.200');

  const { isOpen, onOpen, onClose } = useDisclosure();

  useEffect(() => {
    if (job_uuid.length) {
      // fetchJobFlowData();
    }
  }, []);

  // Todo:Save and completed trigger event
  useEffect(() => {
    if (saveCompleteFlag) {
      saveJobDescriptionAndSkills();
    }
  }, [saveCompleteFlag]);

  const fetchJobFlowData = () => {
    let objBody = {
      email: emailId,
      job_uuid: job_uuid,
    };
    isLoading(true);

    fetchJobWorkflowDetails(objBody)
      .then(res => {
        isLoading(false);
        if (res.result && res.data.length) {
          setJobTitle(res.data[0].job_title);
          if (res.data[0].job_benefits.length) {
            let jobBenfitsStoredData = res.data[0].job_benefits;
            setSelectedValues(jobBenfitsStoredData);
          }
        }
      })
      .catch(err => {
        console.log(err);
        isLoading(false);
      });
  };

  const jobDescriptionData = (data) => {
    if (data){
      setJobDescription(data);
      onClose();
    }
  }

  const setSelectedValues = data => {
    if (data.length) {
      let convertSelectedDataToArr = data.split(',');
      let clonedData = [...selectedBenifits];
      convertSelectedDataToArr?.map(data => {
        clonedData.map(subData => {
          if (data === subData.label) {
            subData.isChecked = true;
          }
        });
      });
      setSelectedBenifits(clonedData);
    }
  };

  // Todo:Render Switches
  const renderSwitchOffers = selectedBenifits?.map((data, index) => (
    <Stack align="center" direction="row" mb="2" key={index}>
      <Switch
        size="sm"
        onChange={() => {
          setBenifitsStatus(data);
        }}
        isChecked={data?.isChecked}
        colorScheme="green"
      />
      <Text w="70%">{data.label}</Text>
    </Stack>
  ));

  // Todo:change checked status of switch
  const setBenifitsStatus = selectedData => {
    let newArr = [...selectedBenifits];
    newArr.map(data => {
      if (data.label === selectedData.label) {
        data.isChecked = !data.isChecked;
      }
    });
    setSelectedBenifits(newArr);
  };

  const launchJobPdfPanel = () => {
    if (job_uuid == undefined || job_uuid === null || job_uuid.trim().length == 0){
      toastFunction("Please start from first wizard tab by entering Job Title..", 'error');
    } else {
      onOpen();
    }
  }

  // Todo:Api call to store data
  const saveJobDescriptionAndSkills = () => {
    if (job_uuid == undefined || job_uuid === null || job_uuid.trim().length == 0){
      toastFunction("Please start from first wizard tab by entering Job Title..", 'error');
    } else {
      let objBody = {
        email: emailId,
        job_uuid: job_uuid,
        job_description: CkeditorUtil.getContentWithLineStyles(jobDescription),
        skillOne: skillOne,
        skillTwo: skillTwo,
        skillThree: skillThree,
      };
      saveJobWizardFourData(objBody)
        .then(res => {
          isLoading(false);
          if (res.result) {
            console.log(res);
            toastFunction(res?.message, 'success');
            if (saveCompleteFlag) {
              // navigate(`/business/joblist`);
              navigate(`/business/editJobs`, {
                state: {
                  job_uuid: job_uuid,
                },
              });
            } else {
              props.setJobData(res?.data[0]?.job)
              dispatch(setJobWizardStatusLevel(5));
              props.onDataUpdate('screeningTab');
            }
          } else {
            isLoading(false);
            toastFunction(res?.message, 'error');
          }
        })
        .catch(err => {
          isLoading(false);
          toastFunction("Unable to submit your request, please contact TalentStrap support.", 'error');
        });
      }
  };

  const toastFunction = (message, status) => {
    return toast({
      title: message,
      status: status,
      isClosable: true,
      duration: 3000,
    });
  };

  const generateAutomatedRequirement = parseData => {
    if (job_uuid == undefined || job_uuid === null || job_uuid.trim().length == 0){
      toastFunction("Please start from first wizard tab by entering Job title..", 'error');
    } else {
      let allSkillsArr = [];
      if (skillOne.trim().length > 0){
        allSkillsArr.push(skillOne.trim());
      }
      if (skillTwo.trim().length > 0){
        allSkillsArr.push(skillTwo.trim());
      }
      if (skillThree.trim().length > 0){
        allSkillsArr.push(skillThree.trim());
      }
      let allSkillsStr = null;
      if (allSkillsArr.length > 0) {
        allSkillsStr = allSkillsArr.join(',');
      }
      let objBody = {
        email: getEmailId(),
        job_uuid: job_uuid,
        info_type: parseData,
        additional_info: allSkillsStr
      };
      if (parseData === 'jd') {
        setGeneratingDescriptionWithAI(true);
      }
      fetchAutomatedAdditionalInfo(objBody)
        .then(res => {
          setGeneratingDescriptionWithAI(false);

          setJobDescription(res.data[0].info);
        })
        .catch(err => {
          setGeneratingDescriptionWithAI(false);
        });
      }
  };

  return (
    <>
      {/* <Text color={textColor} fontSize="2xl" fontWeight="700" mb="20px">
        Please select all benefits being offered for this job:
      </Text> */}
      <Flex direction="column" w="100%">
        <Stack direction="column" spacing="20px">
          {/* Job Requirement  */}
          <Box mt="1">
            <Flex direction={'column'} w={'100%'} mt={5}>
              <Text as={'b'}>What are the Top 3 most important skills or tools for this role?</Text>
              {/* <Text fontSize={'xs'}>(If provided, these skills will be used to create job description with AI)</Text> */}
            </Flex>
              <Flex w={'100%'} direction={'column'}>
                <InputField
                  borderRadius="5px"
                  mb="0px"
                  width="100%"
                  placeholder="Enter first important skill"
                  value={skillOne}
                  onChange={e => {
                    setSkillOne(e.target.value);
                  }}
                  color="black"
                />
                <InputField
                  borderRadius="5px"
                  mb="0px"
                  width="100%"
                  placeholder="Enter second important skill"
                  value={skillTwo}
                  onChange={e => {
                    setSkillTwo(e.target.value);
                  }}
                  color="black"
                />
                <InputField
                  borderRadius="5px"
                  mb="0px"
                  width="100%"
                  placeholder="Enter third important skill"
                  value={skillThree}
                  onChange={e => {
                    setSkillThree(e.target.value);
                  }}
                  color="black"
                />
            </Flex>
            <Flex alignItems="center" mt={8} mb={2} justifyContent="space-between">
              <Text fontSize={16} color="" as={'b'}>
                Job Description
              </Text>
              <Spacer />
              <Button
                variant="outline"
                borderRadius="5px"
                borderColor="#d7d7d7"
                size="sm"
                fontSize={'14px'}
                fontWeight="normal"
                p="10px"
                _hover={{ bg: 'none' }}
                onClick={e => {
                  generateAutomatedRequirement('jd');
                }}
                isDisabled={generatingDescriptionWithAI}
                isLoading={generatingDescriptionWithAI}
                loadingText={'Generating...'}
              >
                Generate with AI
                <Icon ml={2} as={RiSparkling2Fill} fontSize={'20px'} color={'blue.500'} />
              </Button>
              <Button ml={2} h={'30px'} variant="outline" borderRadius="5px" borderColor={schemeBorderColor}
                fontSize={'14px'} size="sm" fontWeight="normal"
                onClick={() => {
                  onJobDescriptionUploadOpen();
                }}
                isDisabled={jobDescriptionLoading} isLoading={jobDescriptionLoading}
              >
                Upload Job Description
              </Button>
            </Flex>
            <Flex border={'1px solid'} borderColor={'gray.200'}>
              <Editor
                  tinymceScriptSrc={'/tinymce/tinymce.min.js'}
                  name='job_template_description'
                  value={jobDescription || ""}
                  onEditorChange={(content) => setJobDescription(content)}
                  init={{
                    placeholder: 'Enter Job Requirement',
                    min_height: 800,
                    branding: false,
                    statusbar: false,
                    menubar: 'edit insert view format table tools',
                    promotion: false,
                    plugins: ['advlist', 'anchor', 'autolink', 'autoresize', 'autosave', 'charmap', 'code',
                              'codesample', 'directionality', 'emoticons', 'help', 'importcss', 'insertdatetime',
                              'link', 'lists', 'nonbreaking', 'pagebreak', 'save', 'searchreplace', 'table', 
                              'template', 'visualblocks', 'visualchars', 'wordcount'
                            ],
                    toolbar: 'undo redo | bold italic | alignleft aligncenter alignright alignjustify | indent outdent',
                    contextmenu: false,  
                  }}
                />
            </Flex>
          </Box>
        </Stack>
        <Flex justify="space-between" mt="14px">
          <Button
            variant="light"
            fontSize="sm"
            borderRadius="16px"
            w={{ base: '128px', md: '148px' }}
            h="46px"
            onClick={() => {
              props.onDataUpdate('employmentType');
              dispatch(setJobWizardStatusLevel(3));
            }}
          >
            Prev
          </Button>
          <Box>
          <Button
              variant="outline"
              fontSize="sm"
              borderRadius="16px"
              w={{ base: '160px', md: '200px' }}
              h="46px"
              ms="auto"
              onClick={() => {
                setSaveCompleteFlag(!saveCompleteFlag);
              }}
              isDisabled={loading ? true : false}
              isLoading={loading ? true : false}
            >
              Save And Complete Later
            </Button>
            <Button
              variant="darkBrand"
              fontSize="sm"
              borderRadius="16px"
              w={{ base: '128px', md: '148px' }}
              h="46px"
              ml="10px"
              onClick={() => {
                saveJobDescriptionAndSkills();
              }}
              isDisabled={loading ? true : false}
              isLoading={loading ? true : false}
            >
              Next
            </Button>
          </Box>
        </Flex>
        <Drawer size="xl"
          isOpen={isJobDescriptionUploadOpen}
          placement="right"
          onClose={onJobDescriptionUploadClose}
          finalFocusRef={btnPdfRef}
        >
          <DrawerContent pl={5}  overflow={'auto'} p={2} borderLeftWidth="0.5px" borderLeftColor="gray.200">
            <DrawerHeader>Select a Document for Job Description</DrawerHeader>
            <DrawerCloseButton />
            <PDfJobDocPanel
              emailId={emailId}
              job_uuid={job_uuid}
              jobDescriptionText={jobDescriptionData}
              onJobDescriptionUploadClose={onJobDescriptionUploadClose}
            />
          </DrawerContent>
        </Drawer>
      </Flex>
    </>
  );
}

export default JobIntakeWizard4;
