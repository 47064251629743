import React, { useEffect, useState } from 'react';
// Chakra imports
import {
    Button,
    Box,
    Select,
    Flex,
    FormControl,
    Text,
    useColorModeValue,
  } from "@chakra-ui/react";
  import Card from "../../../../../components/card/Card.js";
  import InputField from "../../../../../components/fields/InputField.js";
  
  export default function AtsIntegrationWidget() {
    // Chakra Color Mode

    const [atsSystem, setAtsSystem] = useState(null);

    const textColorPrimary = useColorModeValue("secondaryGray.900", "white");
    const textColorSecondary = "secondaryGray.600";
    return (
      <FormControl>
        <Card mb='20px' pb='50px'>
          <Flex direction='column' mb='40px' ms='10px'>
            <Text fontSize='xl' color={textColorPrimary} fontWeight='bold'>
            ATS Services integration
            </Text>
            <Text fontSize='sm' color={textColorSecondary}>
              Here you can integrate your ATS system with Talent Strap
            </Text>
          </Flex>
          <Flex w={'100%'} direction='column' rounded={'md'} m={2} p={2} h={'200px'}>
            <Text fontSize='lg' color={textColorPrimary} fontWeight='bold'>Currently Configured ATS Systems:</Text>
            <Flex w={'100%'} mb={1} p={2} align={'center'} borderWidth ={'0.5px'} borderColor={'gray.200'} rounded="md">
                <Box w={'100%'} >
                    <Text fontSize={'lg'}>Gusto</Text>
                </Box>
                <Box w={'450px'} >
                    <Button size={'sm'} mr={1} colorScheme='blue'>Test</Button>
                    <Button size={'sm'} mr={1} colorScheme='green'>Verify</Button>
                    <Button size={'sm'} mr={1} colorScheme='red'>Disable</Button>
                </Box>
            </Flex>
            <Flex w={'100%'} p={2} align={'center'} borderWidth ={'0.5px'} borderColor={'gray.200'} rounded="md">
                <Box w={'100%'} >
                    <Text fontSize={'lg'}>Bamboo HR</Text>
                </Box>
                <Box w={'450px'} >
                    <Button size={'sm'} mr={1} colorScheme='blue'>Test</Button>
                    <Button size={'sm'} mr={1} colorScheme='green'>Verify</Button>
                    <Button size={'sm'} mr={1} colorScheme='red'>Disable</Button>
                </Box>
            </Flex>

          </Flex>
          <Flex mt={4} w={'100%'} direction='column' rounded={'md'} m={2} p={2} >
            <Text fontSize='lg' color={textColorPrimary} fontWeight='bold'>Configure an ATS System:</Text>
            <Flex direction='column' w={'100%'} p={2}>
                <Text>Please select an ATS system from the list below to configure it:</Text>
                <Select
                    size="sm"
                    value={atsSystem}
                    onChange={e => {
                        setAtsSystem(e.target.value);
                    }}
                    >
                    <option disabled value="">
                        Please Select
                    </option>
                    <option value="Gusto">Gusto</option>
                    {/* <option value="Gusto" selected>
                        Gusto
                    </option> */}
                    <option value="BambooHR">Bamboo HR</option>
                </Select>
            </Flex>
            <Button
                variant='brand'
                minW='183px'
                fontSize='sm'
                fontWeight='500'
                ms='auto'>
                Start ATS Integration
            </Button>
        </Flex>
        </Card>
      </FormControl>
    );
  }
  