// Chakra imports
import React, { useEffect, useState } from 'react';
import Card from '../../../../../components/card/Card.js';
import InputField from '../../../../../components/fields/InputField';
import TextField from '../../../../../components/fields/TextField';
import { getEmailId } from '../../../../../utils/localStorageIndex';
import {
  updateUserInfo,
  fetchPersonalInfo,
} from '../../../../../services/sharedServices';
import {
  Button,
  Text,   FormLabel, Select,
  Flex,
  useToast,
  FormControl,
  SimpleGrid,
  useColorModeValue,
} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';

export default function Settings() {
  // Chakra Color Mode
  const textColorPrimary = useColorModeValue('secondaryGray.900', 'white');
  const textColorSecondary = 'secondaryGray.600';

  // Todo:Defined States
  const toast = useToast();
  const emaiId = getEmailId();
  const navigate = useNavigate();
  const [error, setError] = useState({});
  const [loading, isLoading] = useState(false);
  const [updateInfoObj, setUpdateInfoObj] = useState({
    first_name: '',
    last_name: '',
    phone_number: '',
    social_about: '',
    email: emaiId,
  });

  // Todo:Fetch User Details
  useEffect(() => {
    fetchUserDetails();
  }, []);

  const fetchUserDetails = () => {
    let objData = {
      email: emaiId,
    };
    fetchPersonalInfo(objData)
      .then(res => {
        if (res.result) {
          setUpdateInfoObj(res.data[0]);
          isLoading(false);
        } else {
          isLoading(false);
        }
      })
      .catch(err => {
        isLoading(false);
        if (err) {
        }
      });
  };

  // on change update form value fields
  const onUpdateField = e => {
    const nextFormState = {
      ...updateInfoObj,
      [e.target.name]: e.target.value,
    };
    setUpdateInfoObj(nextFormState);
  };

  // form validation function
  const validateFieldForm = () => {
    let fields = updateInfoObj;
    let errors = {};
    let formIsValid = true;

    if (!fields['first_name']) {
      formIsValid = false;
      errors['first_name'] = '*First Name is required.';
    }
    if (!fields['last_name']) {
      formIsValid = false;
      errors['last_name'] = '*Last Name is required.';
    }

    if (!fields['phone_number']) {
      formIsValid = false;
      errors['phone_number'] = '*Phone Number is required.';
    }

    // if (!fields['social_about']) {
    //   formIsValid = false;
    //   errors['social_about'] = '*Phone Number is required.';
    // }

    setError(errors);
    return formIsValid;
  };

  // Todo:Save User Details
  const submitUserData = event => {
    event.preventDefault();
    if (validateFieldForm()) {
      updateInfoObj.email = emaiId;
      isLoading(true);
      updateUserInfo(updateInfoObj)
        .then(res => {
          if (res.result) {
            isLoading(false);
            if (res?.data?.length > 0){
              if (res?.data[0]?.group === '100' || res?.data[0]?.group === '200'){
                navigate('/admin/profile');
              } else  if (res?.data[0]?.group === '300' || res?.data[0]?.group === '400'){
                navigate('/business/profile');
              }
            }
            toastFunction(res.message, 'success');
          } else {
            isLoading(false);
            toastFunction(res.error, 'success');
          }
        })
        .catch(err => {
          isLoading(false);
          toastFunction(err.message, 'success');
        });
    }
  };

  // Todo:Toaster Function
  const toastFunction = (message, status) => {
    return toast({
      title: message,
      status: status,
      isClosable: true,
      duration: 3000,
    });
  };

  return (
    <FormControl>
      <Card pb="100px">
        <Flex direction="column" mb="40px" ms="10px">
          <Text fontSize="xl" color={textColorPrimary} fontWeight="bold">
            Account Settings
          </Text>
          <Text fontSize="md" color={textColorSecondary}>
            Here you can change user account information
          </Text>
        </Flex>
        <SimpleGrid
          columns={{ sm: 1, md: 2 }}
          spacing={{ base: '20px', xl: '20px' }}
        >
          <Flex flexWrap="wrap">
            <InputField
              mb="0"
              me="30px"
              id="first_name"
              label="First Name"
              value={updateInfoObj.first_name}
              name="first_name"
              onChange={onUpdateField}
              placeholder="Enter First Name"
              w="100%"
            />
            <Text fontSize="sm" color={'red'} mb="5" mt="1">
              {error?.first_name}
            </Text>
          </Flex>
          <Flex flexWrap={'wrap'}>
            <InputField
              mb="0px"
              id="last_name"
              label="Last Name"
              value={updateInfoObj.last_name}
              name="last_name"
              onChange={onUpdateField}
              placeholder="Enter Last Name"
              w="100%"
            />
            <Text fontSize="sm" color="red" mb="5" mt="1">
              {error?.last_name}
            </Text>
          </Flex>
        </SimpleGrid>
        <SimpleGrid
          mt={5}
          columns={{ sm: 1, md: 1 }}
          spacing={{ base: '20px', xl: '20px' }}
        >
          <InputField
            mb="0"
            me="30px"
            id="phone_number"
            label="Phone Number"
            value={updateInfoObj.phone_number}
            name="phone_number"
            onChange={onUpdateField}
            placeholder="Enter Phone Number"
          />
          <Text fontSize="sm" color={'red'} mb="1" mt="1">
            {error?.phone_number}
          </Text>
        </SimpleGrid>

        <SimpleGrid
          mt={5}
          columns={{ sm: 1, md: 1 }}
          spacing={{ base: '20px', xl: '20px' }}
        >
          <InputField
            mb="0"
            me="30px"
            id="calendar_url"
            label="Public Calendar URL"
            value={updateInfoObj.calendar_url}
            name="calendar_url"
            onChange={onUpdateField}
            placeholder="Enter Public Calendar URL"
          />
          <Text fontSize="sm" color={'red'} mb="1" mt="1">
            {error?.calendar_url}
          </Text>
        </SimpleGrid>

        {/* <SimpleGrid
          mt={5}
          columns={{ sm: 1, md: 1 }}
          spacing={{ base: '20px', xl: '20px' }}
        >
          <TextField
            id="social_about"
            name="social_about"
            value={updateInfoObj.social_about}
            onChange={onUpdateField}
            label="About Me"
            h="100px"
            placeholder="Tell something about yourself in 250 characters!"
          />
        </SimpleGrid> */}

        <Button
          mt={10}
          variant="brand"
          minW="183px"
          fontSize="sm"
          fontWeight="500"
          ms="auto"
          onClick={e => {
            submitUserData(e);
          }}
          isDisabled={loading ? true : false}
          isLoading={loading ? true : false}
        >
          Save changes
        </Button>
      </Card>
    </FormControl>
  );
}
