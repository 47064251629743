import { useNavigate } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { BsPlus, BsCheck } from 'react-icons/bs';
import InputField from '../../../../../components/fields/InputField';
import { getEmailId } from '../../../../../utils/localStorageIndex';
import {
  // addEmployeeTypeWorkflow,
  saveJobWizardThreeData,
  fetchJobWorkflowDetails,
} from '../../../../../services/jobTemplateService';
import {
  Box,
  Icon,
  Text,
  Flex,
  Stack,
  Switch,
  Select,
  Button,
  useToast,
  SimpleGrid,
  useColorModeValue,
  NumberInput,
  NumberInputField,
  FormLabel,
} from '@chakra-ui/react';
import { setJobWizardStatusLevel } from '../../../../../store/actions/topNotificationActions';

function JobIntakeWizard3(props) {
  // Todo:Fetch Job Id from store
  const wizardDetails = useSelector(store => store.wizardHeader);
  const job_uuid = wizardDetails?.job_uuId;
  const toast = useToast();
  const dispatch = useDispatch();
  const emailId = getEmailId();
  const navigate = useNavigate();
  const [jobTitle, setJobTitle] = useState('');
  const [loading, isLoading] = useState(false);
  const [saveCompleteFlag, setSaveCompleteFlag] = useState(false);
  const textColor = useColorModeValue('secondaryGray.900', 'white');
  const textColorPrimary = useColorModeValue('secondaryGray.900', 'white');
  const pillTextColor = useColorModeValue('navy.700', 'gray.200');

  const payArr = [
    { name: 'Bonus Opportunities', isChecked: false },
    { name: 'Commission Pay', isChecked: false },
    { name: 'Company Stock', isChecked: false },
    { name: 'Others', isChecked: false },
  ];
  const [payOtherValue, setPayOtherValue] = useState('');
  const [renderPayArr, setRenderPayArr] = useState(payArr);
  const [selectedPayValue, setSelectedPayValue] = useState('');
  const [selectRateValue, setSelectRateValue] = useState('');
  const [minAmount, setMinAmount] = useState();
  const [maxAmount, setMaxAmount] = useState();
  const [amount, setAmount] = useState();

  const employeeTypeSwitchArr = [
    { label: 'Full-Time', value: 'Full-Time', isChecked: false },
    { label: 'Part-Time', value: 'Part-Time', isChecked: false },
    { label: 'Contract', value: 'Contract', isChecked: false },
    { label: 'Temporary', value: 'Temporary', isChecked: false },
    { label: 'Internship', value: 'Internship', isChecked: false },
    { label: 'Volunteer', value: 'Volunteer', isChecked: false },
  ];

  const [selectedEmployeeType, setSelectedEmployeeType] = useState(
    employeeTypeSwitchArr
  );

  useEffect(() => {
    fetchJobFlowData();
  }, []);

  // Todo:Save and completed trigger event
  useEffect(() => {
    if (saveCompleteFlag) {
      storeEmployementTypeData();
    }
  }, [saveCompleteFlag]);

  const fetchJobFlowData = () => {
    let objBody = {
      email: emailId,
      job_uuid: job_uuid,
    };
    isLoading(true);

    fetchJobWorkflowDetails(objBody)
      .then(res => {
        isLoading(false);
        if (res.result && res.data.length) {
          setJobTitle(res.data[0].job_title);
        }
        if (res.data[0].job_employment_type.length) {
          let newArr = [...selectedEmployeeType];
          newArr.map(data => {
            if (data.label === res.data[0].job_employment_type) {
              data.isChecked = true;
            }
          });
          setSelectedEmployeeType(newArr);
        }
      })
      .catch(err => {
        isLoading(false);
      });
  };

  // Todo:Render Switches
  const renderEmployementType = selectedEmployeeType?.map((data, index) => (
    <Stack align="center" direction="row" mb="5">
      <Switch
        size="sm"
        onChange={() => {
          setBenifitsStatus(data.label);
        }}
        isChecked={data?.isChecked}
        colorScheme="green"
      />
      <Text w="70%">{data.label}</Text>
    </Stack>
  ));

  // Todo:Manage Switch States
  const setBenifitsStatus = selectedData => {
    let newArr = [...selectedEmployeeType];
    newArr.map(data => {
      if (data.label === selectedData) {
        data.isChecked = !data.isChecked;
      } else {
        data.isChecked = false;
      }
    });
    setSelectedEmployeeType(newArr);
  };

  // Todo:Api call to store data
  const storeEmployementTypeData = () => {
    if (
      job_uuid === undefined ||
      job_uuid === null ||
      job_uuid.trim().length === 0
    ) {
      toastFunction(
        'Please start from first wizard tab by entering Job Title..',
        'error'
      );
    }
    if (
      selectedPayValue === 'Range' &&
      parseFloat(minAmount) > 0 &&
      parseFloat(maxAmount) <= parseFloat(minAmount)
    ) {
      toastFunction('Max amount must be greater than min amount', 'error');
    } else {
      let objBody = {
        email: emailId,
        job_uuid: job_uuid,
        payOtherValue: payOtherValue,
        selectedPayValue: selectedPayValue,
        supplementalOptions: JSON.stringify(renderPayArr),
        selectRateValue: selectRateValue,
        minAmount: minAmount || null,
        maxAmount: maxAmount || null,
        amount: amount || null,
      };
      saveJobWizardThreeData(objBody)
        .then(res => {
          isLoading(false);
          if (res.result) {
            toastFunction(res?.message, 'success');
            if (saveCompleteFlag) {
              // navigate(`/business/joblist`);
              navigate(`/business/editJobs`, {
                state: {
                  job_uuid: job_uuid,
                },
              });
            } else {
              dispatch(setJobWizardStatusLevel(4));
              props.onDataUpdate('benifitsTab');
            }
          } else {
            isLoading(false);
            toastFunction(res?.message, 'error');
          }
        })
        .catch(err => {
          isLoading(false);
          toastFunction('Invalid type of employment', 'error');
        });
    }
  };

  const toastFunction = (message, status) => {
    return toast({
      title: message,
      status: status,
      isClosable: true,
      duration: 3000,
    });
  };

  // Todo:High-Order Fuction to reset check buttons
  const setCheckedValue = (data, arrValue, setterFunction) => {
    let cloneData = [...arrValue];
    cloneData?.map((subValue, index) => {
      if (subValue?.name === data) {
        subValue.isChecked = !subValue.isChecked;
      }
    });
    setterFunction(cloneData);
  };

  // Todo:Render Pay Type UI
  const renderScheduleTypeData = renderPayArr?.map((data, index) => (
    <>
      <Button
        key={index}
        color={data?.isChecked ? 'gray.600' : pillTextColor}
        bg={data?.isChecked ? 'gray.300' : ''}
        borderWidth={'1px'}
        borderRadius={'20px'}
        size="sm"
        mr="2"
        mb="2"
        onClick={() => {
          setCheckedValue(data.name, renderPayArr, setRenderPayArr);
        }}
      >
        <Icon h="24px" w="24px" as={data?.isChecked ? BsCheck : BsPlus}></Icon>{' '}
        {data?.name}
      </Button>
      {data?.name === 'Others' && data?.isChecked ? (
        <InputField
          borderRadius="5px"
          mb="0px"
          width="100%"
          placeholder="Enter Others Value"
          label="Enter Others Value"
          value={payOtherValue}
          onChange={e => {
            setPayOtherValue(e.target.value);
          }}
          color="black"
        />
      ) : null}
    </>
  ));

  // Todo:Currency Format
  function formatCurrency(num) {

    if (!num || num == '-9007199254740991'){
      return '$'
    }

    let numStr = num.toString();
    return '$' + numStr.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
  }

  const parseCurrency = val => val.replace(/^\$/, '');

  return (
    <>
      {/* <Text color={textColor} fontSize="2xl" fontWeight="700" mb="20px">
        Please select the Supplemental Pay
      </Text> */}
      <Flex direction="column" w="100%">
        <SimpleGrid
          columns={selectedPayValue === 'Range' ? 4 : 3}
          spacingX="40px"
          spacingY="20px"
        >
          <Box>
            <Text
              color={textColorPrimary}
              fontSize="sm"
              fontWeight="bold"
              _hover={{ cursor: 'pointer' }}
            >
              Show pay by
            </Text>
            <Select
              size="sm"
              value={selectedPayValue}
              onChange={e => {
                setSelectedPayValue(e.target.value);
              }}
            >
              <option disabled value="">
                Please Select
              </option>
              <option value="Range">Range</option>
              <option value="Starting Amount">Starting Amount</option>
              <option value="Maximum Amount">Maximum Amount</option>
              <option value="Exact Amount">Exact Amount</option>
            </Select>
          </Box>
          {selectedPayValue === 'Range' ? (
            <>
              <Box>
                <FormLabel fontSize={'14px'} fontWeight={'bold'}>
                  Minimum
                </FormLabel>
                <NumberInput
                  onChange={valueString => {
                    setMinAmount(valueString);
                  }}
                  value={formatCurrency(minAmount)}
                  max={1000000}
                  size="sm"
                >
                  <NumberInputField />
                </NumberInput>
              </Box>
              <Box>
                <FormLabel fontSize={'14px'} fontWeight={'bold'}>
                  Maximum
                </FormLabel>
                <NumberInput
                  onChange={valueString => {
                    setMaxAmount(valueString);
                  }}
                  value={formatCurrency(maxAmount)}
                  max={1000000}
                  size="sm"
                >
                  <NumberInputField />
                </NumberInput>
              </Box>
            </>
          ) : null}
          {selectedPayValue === 'Starting Amount' ? (
            <Box>
              <FormLabel fontSize={'14px'} fontWeight={'bold'}>
                Amount
              </FormLabel>
              <NumberInput
                onChange={valueString => {
                  setMinAmount(valueString);
                }}
                value={formatCurrency(minAmount)}
                max={1000000}
                size="sm"
              >
                <NumberInputField />
              </NumberInput>
            </Box>
          ) : null}
          {selectedPayValue === 'Maximum Amount' ? (
            <Box>
              <FormLabel fontSize={'14px'} fontWeight={'bold'}>
                Amount
              </FormLabel>
              <NumberInput
                onChange={valueString => {
                  setMaxAmount(valueString);
                }}
                value={formatCurrency(maxAmount)}
                max={1000000}
                size="sm"
              >
                <NumberInputField />
              </NumberInput>
            </Box>
          ) : null}
          {selectedPayValue === 'Exact Amount' || selectedPayValue === '' ? (
            <Box>
              <FormLabel fontSize={'14px'} fontWeight={'bold'}>
                Amount
              </FormLabel>
              <NumberInput
                onChange={valueString => {
                  setAmount(valueString);
                }}
                value={formatCurrency(amount)}
                max={1000000}
                size="sm"
              >
                <NumberInputField />
              </NumberInput>
            </Box>
          ) : null}

          <Box>
            <Text
              color={textColorPrimary}
              fontSize="sm"
              fontWeight="bold"
              _hover={{ cursor: 'pointer' }}
            >
              Rate
            </Text>
            <Select
              size="sm"
              value={selectRateValue}
              onChange={e => {
                setSelectRateValue(e.target.value);
              }}
            >
              <option disabled value="">
                Please Select
              </option>
              <option value="per hour">per hour</option>
              <option value="per day">per day</option>
              <option value="per week">per week</option>
              <option value="per month">per month</option>
              <option value="per year">per year</option>
            </Select>
          </Box>
        </SimpleGrid>

        <SimpleGrid mt="8">
          <Text
            color={textColorPrimary}
            fontSize="sm"
            fontWeight="bold"
            _hover={{ cursor: 'pointer' }}
          >
            Please select the Supplemental Pay
          </Text>
          <Flex mt="2" flexWrap={'wrap'}>
            {renderScheduleTypeData}
          </Flex>
        </SimpleGrid>

        <Flex justify="space-between" mt="24px">
          <Button
            variant="light"
            fontSize="sm"
            borderRadius="16px"
            w={{ base: '128px', md: '148px' }}
            h="46px"
            onClick={() => {
              props.onDataUpdate('synopsisTab');
              dispatch(setJobWizardStatusLevel(2));
            }}
          >
            Prev
          </Button>
          <Box>
            <Button
              variant="outline"
              fontSize="sm"
              borderRadius="16px"
              w={{ base: '160px', md: '200px' }}
              h="46px"
              ms="auto"
              onClick={() => {
                setSaveCompleteFlag(!saveCompleteFlag);
              }}
              isDisabled={loading ? true : false}
              isLoading={loading ? true : false}
            >
              Save And Complete Later
            </Button>
            <Button
              h="46px"
              ml="10px"
              fontSize="sm"
              variant="darkBrand"
              borderRadius="16px"
              w={{ base: '128px', md: '148px' }}
              onClick={() => {
                storeEmployementTypeData();
              }}
              isDisabled={loading ? true : false}
              isLoading={loading ? true : false}
            >
              Next
            </Button>
          </Box>
        </Flex>
      </Flex>
    </>
  );
}

export default JobIntakeWizard3;
