import React, { useState } from 'react';
import { Flex, Box, Image, Text, useColorModeValue, useDisclosure, Tooltip, IconButton, Spacer} from '@chakra-ui/react';
import { BiCheck, BiPencil } from 'react-icons/bi';
import dummyEmployer from '../../../../../src/image/dummy_employer.jpg';
import dummySchool from '../../../../../src/image/dummy_school.png';
import { isObjectEmpty } from '../../../../utils/common';
import EditCandidateEducation from './EditCandidateEducation';


export default function CandidateEducation(props){
    const SchemeTextColor = useColorModeValue('secondaryGray.900', 'gray.800');
    const [candidate, setCandidate] = useState(props.candidate)
    const {job, isEditCandidate, getAllCandidatesForJob} = props;
    const [isEditEducation, setIsEditEducation] = useState(false)
    const [educationForEdit, setEducationForEdit] = useState({index: null, education: {}})
    const { isOpen: isEditEducationPanelOpen, onOpen: onEditEducationPanelOpen, onClose: onEditEducationPanelClose } = useDisclosure();

    const formatDate = (value1, value2) => {
        let dateString1 = null;
        if (value1 != null && value1 != undefined){
            let date = new Date(value1);
            const month = date.toLocaleString('default', { month: 'short' });
            const year = date.toLocaleString('default', { year: 'numeric' });
            dateString1 = month + ', ' + year;
        }
        let dateString2 = null;
        if (value2 != null && value2 != undefined && value2.toLowerCase() != 'present'){
            let date = new Date(value2);
            const month = date.toLocaleString('default', { month: 'short' });
            const year = date.toLocaleString('default', { year: 'numeric' });
            dateString2 = month + ', ' + year;
        }
        if (dateString1 != null && dateString2 != null) {
            return dateString1 + ' - ' + dateString2; 
        } else if (dateString1 != null && dateString2 == null){
            return dateString1 + ' - ' + 'present'; 
        } else if (dateString1 === null && dateString2 ==- null){
            return '';
        } else {
            return '';
        } 
    }

    const isEducationEmpty = () => {
        return isObjectEmpty(candidate) || !candidate?.details?.raw_data?.education || !candidate?.details?.raw_data?.education.length
    }

    const EditEducationButton = ({education, index}) => {
        if (isEditCandidate && !isEditEducation && educationForEdit?.education?.school?.name != education?.school?.name && educationForEdit?.education?.start_date != education?.start_date){
        return <>
            <Tooltip label={'Edit Education'} fontSize='md'>
                <span>
                    <IconButton fontSize={'20px'} bg={'none'} _hover={{'bg': 'none'}} icon={<BiPencil />} color={'gray'}
                        onClick={() => {
                            setIsEditEducation(true)
                            setEducationForEdit({index: index, education: education})
                            onEditEducationPanelOpen()
                        }}
                    />
                </span>
            </Tooltip>
        </>
        }

        if (isEditCandidate && isEditEducation && educationForEdit?.education?.school?.name == education?.school?.name && educationForEdit?.education?.start_date == education?.start_date){
        return <>
            <Tooltip label={'Finish Editing Experience'} fontSize='md'>
                <span><IconButton fontSize={'24px'} bg={'none'} _hover={{'bg': 'none'}} icon={<BiCheck />} color={'gray'}
                        onClick={() => {
                            setIsEditEducation(false)
                            setEducationForEdit({index: null, education: {}})
                            onEditEducationPanelClose()
                        }}
                    />
                </span>
            </Tooltip>
        </>
        }
    }

    const CandidateEducation = () => {
        let edData = candidate?.details?.raw_data?.education || [];
        return(
            <Box mt={8}>
                <Flex>
                    <Text color={SchemeTextColor} fontSize={'16px'} fontWeight={'600'} mb={3}>Education</Text>
                </Flex>
                {!isEducationEmpty() ?
                    edData.map( (edItem, index) => {
                        return(
                            <Flex w={'100%'} textTransform={'capitalize'} mb={4}>
                                <Flex mr={'10px'} direction={'column'}>
                                    <Box width={'60px'} height={'60px'}>
                                    <Image 
                                        borderRadius='full'
                                        boxSize='60px'
                                        objectFit={'contain'}
                                        border={'1px solid'}
                                        bgColor={'gray.200'}
                                        borderColor={'gray.200'}
                                        src={edItem?.school?.logo ? edItem?.school?.logo : dummyEmployer}
                                        onError={e => e.target.src = dummySchool}
                                        />
                                    </Box>
                                </Flex>
                                <Flex direction={'column'} >
                                    <Text fontWeight={'bold'}>{edItem?.degrees?.join(' ')}</Text>

                                    {edItem?.school?.name ?
                                    <Box mb={'2px'}>
                                        <Text color={'black.400'} fontWeight={'500'}>{edItem?.school?.name}</Text>
                                    </Box>
                                    :
                                    null
                                    }
                                    {edItem?.start_date ?
                                    <Text  color={'gray'} fontWeight={'500'} float={'left'}>{formatDate(edItem?.start_date, edItem?.end_date)}</Text>
                                    :null
                                    }
                                </Flex>
                                <Spacer />
                                <EditEducationButton education={edItem} index={index} /> 
                            </Flex>
                        )
                    })
                :
                    <Text as={'b'}>N/A</Text>
                }
            </Box>
        )        
    }

    return (
        <Box>                
            <Flex w={'100%'} direction={'column'}>
                <CandidateEducation />
            </Flex> 

            <EditCandidateEducation
                candidate={candidate}
                job={job}
                educationForEdit={educationForEdit}
                onEditEducationPanelOpen={onEditEducationPanelOpen}
                onEditEducationPanelClose={onEditEducationPanelClose}
                isEditEducationPanelOpen={isEditEducationPanelOpen}
                getAllCandidatesForJob={getAllCandidatesForJob}
            />       
        </Box>
    );
}