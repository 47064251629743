// Chakra imports
import {
  Flex,
  FormLabel,
  Input,
  FormControl,
  Text,
  useColorModeValue,
} from '@chakra-ui/react';
// Custom components
import React from 'react';

export default function Default(props) {
  const { id, label, extra, placeholder, type, mb, ...rest } = props;
  // Chakra Color Mode
  const textColorPrimary = useColorModeValue('secondaryGray.900', 'white');

  return (
    <Flex
      direction="column"
      mb={mb ? mb : '30px'}
      w={props?.width ? props?.width : null}
    >
      <FormControl isRequired={props?.required ? props?.required : false}>
        <FormLabel
          display="flex"
          ms="10px"
          htmlFor={id}
          color={textColorPrimary}
          fontSize="sm"
          fontWeight="bold"
          _hover={{ cursor: 'pointer' }}
        >
          {label}
          <Text fontSize="sm" fontWeight="400" ms="2px">
            {extra}
          </Text>
        </FormLabel>
        <Input
          {...rest}
          type={type}
          id={id}
          fontWeight="500"
          variant="main"
          placeholder={placeholder}
          _placeholder={{ fontWeight: '400', color: 'black.600' }}
          h={props?.height ? props?.height : '44px'}
          maxh="44px"
          p={props?.padding ? props?.padding : null}
        />
      </FormControl>
    </Flex>
  );
}
