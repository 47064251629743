import { Button } from "@chakra-ui/react";

export default function OfferCandidateButton(props){
    const {onClickHandler, onClickHandlerArgs=[], buttonSize='xs', mLeft=1, buttonText='Offer'} = props;

    return <>
        <Button ml={mLeft} size={buttonSize} bg={'gray.200'}
          onClick={() => onClickHandler(...onClickHandlerArgs) } 
          _hover={{'bg': 'green.600', 'color': 'white'}}
        >
          {buttonText}
        </Button>
    </>
}