import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { BiUpload } from 'react-icons/bi';

// Chakra imports
import {
  Box,
  Button,
  Flex,
  Stack,
  Input,
  Icon,
  Text,
  FormControl,
  VStack,
  useColorModeValue,
} from '@chakra-ui/react';
// Custom components
import Card from '../../../../../components/card/Card.js';
// Assets
import { MdUpload } from 'react-icons/md';
import Dropzone from './Dropzone';
import { getEmailId } from '../../../../../utils/localStorageIndex';
import 'react-dropzone-uploader/dist/styles.css';
import { uploadBusinessLogo } from '../../../../../services/businessServices';
import axios from '../../../../../services/axios';
import { useDispatch } from 'react-redux';
import { setBannerFlag } from '../../../../../store/actions/topNotificationActions';

export default function Upload(props) {
  const { used, total, ...rest } = props;
  // Chakra Color Mode
  const textColorPrimary = useColorModeValue('secondaryGray.900', 'white');
  const brandColor = useColorModeValue('brand.500', 'white');
  const textColorSecondary = 'gray.400';

  // Todo:Declared Use-State
  const dispatch = useDispatch();
  const [loading, isLoading] = useState(true);
  const [uploadFile, setUploadFile] = useState();
  const [uploadFileUrl, setUploadFileUrl] = useState();
  const emaiId = getEmailId();
  const navigate = useNavigate();
  const { state } = useLocation();
  const [uploadStatus, setUploadStatus] = useState();
  const [uploadStatusLoading, setUploadStatusLoading] = useState(false);

  function postObjectData(s3Data, selectedFile) {
    const { url, fields } = s3Data;
    const payload = new FormData();
    Object.entries(fields).forEach(([key, val]) => {
      payload.append(key, val);
    });
    payload.append('file', selectedFile);
    var ax_instance = axios.create();
    delete ax_instance.defaults.headers.common['Authorization'];
    const { data: result } = ax_instance
      .post(url, payload)
      .then(res => {
        isLoading(false);
        if (res?.data?.length > 0) {
          setUploadStatus('File is uploaded successfully');
          dispatch(setBannerFlag(true));
          setUploadFile();
        } else {
          setUploadStatus('File is uploaded successfully');
          dispatch(setBannerFlag(true));
          setUploadFile();
          setUploadFileUrl();
        }
      })
      .catch(err => {
        isLoading(false);
        setUploadFile();
        setUploadFileUrl();
        setUploadStatus('Upload failed with Error :' + err);
      });
  }

  const handleFileChange = e => {
    if (e.target.files) {
      setUploadFile(e.target.files[0]);
      setUploadFileUrl(URL.createObjectURL(e.target.files[0]));
    }
  };

  const handleSubmitNew = async () => {
    setUploadStatusLoading(true);
    if (uploadFile) {
      let objData = {
        email: emaiId,
        content_choice: 'headshot',
        file_name: uploadFile.name,
        file_type: uploadFile.type,
      };
      setUploadStatus('Getting upload url for the selected file..');
      const response = await uploadBusinessLogo(objData);
      setUploadStatusLoading(false);
      if (response && response.data && response.data.length > 0) {
        setUploadStatusLoading(false);
        const finalUrl = response.data[0].signedPackage.signedUrl;
        setUploadStatus('Trying to upload the selected file..');
        const s3UploadResult = await postObjectData(finalUrl, uploadFile);
      } else {
        setUploadStatusLoading(false);
        setUploadStatus('Unable to get the upload signed url..');
      }
    }
  };
  return (
    <Card {...rest} mb="20px" align="center" p="20px">
      {/* <Flex h='100%' direction={{ base: "column", "2xl": "row" }}>
        <Dropzone
          w={{ base: "100%", "2xl": "268px" }}
          me='36px'
          maxH={{ base: "60%", lg: "50%", "2xl": "100%" }}
          minH={{ base: "60%", lg: "50%", "2xl": "100%" }}
          content={
            <Box>
              <Icon as={MdUpload} w='80px' h='80px' color={brandColor} />
              <Flex justify='center' mx='auto' mb='12px'>
                <Text fontSize='xl' fontWeight='700' color={brandColor}>
                  Upload Files
                </Text>
              </Flex>
              <Text fontSize='sm' fontWeight='500' color='secondaryGray.500'>
                PNG, JPG and GIF files are allowed
              </Text>
            </Box>
          }
        />
        <Flex direction='column' pe='34px'>
          <Text
            color={textColorPrimary}
            fontWeight='bold'
            textAlign='start'
            fontSize='2xl'
            mt={{ base: "20px", "2xl": "50px" }}>
            Update Business Logo
          </Text>
          <Text
            color={textColorSecondary}
            fontSize='md'
            my={{ base: "auto", "2xl": "10px" }}
            mx='auto'
            textAlign='start'>
            Your business logo adds character to your business profile and highlight your organization.
          </Text>
          <Flex w='100%' mb='50px' mt={{ base: "20px", "2xl": "auto" }}>
            <Button me='auto' w='140px' variant='brand' fontWeight='500'>
              Add Headshot
            </Button>
          </Flex>
        </Flex>
      </Flex> */}
      <Box w={'100%'}>
        <FormControl cursor="pointer">
          <Text fontSize={'12px'}>{uploadFileUrl}</Text>
          <Text mb={4} fontSize={'12px'}>
            {uploadFile && `${uploadFile.name} - ${uploadFile.type}`}
          </Text>
          {/* <Input type="file" onChange={handleFileChange}></Input> */}
          <Text fontSize={'16px'} mb="10px" fontWeight={'bold'}>
            Upload Business Logo
          </Text>

          <Box
            position="relative"
            height="200px"
            width="100%"
            borderWidth="2px"
            borderColor={brandColor}
            borderRadius={'10px'}
            cursor="pointer"
          >
            <Box
              position="absolute"
              top="0"
              left="0"
              height="100%"
              width="100%"
              display="flex"
              flexDirection="column"
              cursor="pointer"
            >
              <Stack
                height="100%"
                width="100%"
                display="flex"
                alignItems="center"
                justify="center"
                spacing="4"
                cursor="pointer"
              >
                <Box textAlign="center">
                  <Icon as={MdUpload} w="80px" h="80px" color={brandColor} />
                  <Flex justify="center" mx="auto" mb="12px">
                    <Text fontSize="xl" fontWeight="700" color={brandColor}>
                      Upload Files
                    </Text>
                  </Flex>
                  <Text
                    fontSize="sm"
                    fontWeight="500"
                    color="secondaryGray.500"
                  >
                    PNG, JPG and GIF files are allowed
                  </Text>
                </Box>
              </Stack>
            </Box>
            <Input
              type="file"
              height="100%"
              width="100%"
              position="absolute"
              top="0"
              left="0"
              opacity="0"
              aria-hidden="true"
              accept=".png, .jpg, .jpeg, .pdf" // Include .pdf here to accept PDF files
              cursor="pointer"
              onChange={handleFileChange}
            />
          </Box>
        </FormControl>
      </Box>
      {uploadFile ? (
        <Button
          mt={5}
          size="sm"
          bg={'green.600'}
          onClick={handleSubmitNew}
          isDisabled={uploadStatusLoading ? true : false}
          isLoading={uploadStatusLoading ? true : false}
        >
          Upload Banner
        </Button>
      ) : null}
      <Box w={'100%'}>
        <Text textAlign="center" mt="3" color="green.200">
          {uploadStatus}
        </Text>
      </Box>
    </Card>
  );
}
