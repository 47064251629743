import axios from './axios';
import { serviceUrls } from './serviceUrls';
import { getEmailId } from '../utils/localStorageIndex';

export const loginPrev = (data, config = null, axiosInstance = axios) => {
  return axiosInstance.get(
    serviceUrls().loginApi +
      '?email=' +
      data.email +
      '&password=' +
      data.password,
    config
  );
};

export const login = (data, config = null, axiosInstance = axios) => {
  return axiosInstance.post(serviceUrls().loginApi, data, config);
};

export const getToken = (data, config = null, axiosInstance = axios) => {
  return axiosInstance.post(serviceUrls().tokenApi, data, config);
};

export const getDummyGetInfo = (data, config = null, axiosInstance = axios) => {
  return axiosInstance.get(serviceUrls().dummyInfo, data, config);
};

export const getDummyPostInfo = (
  data,
  config = null,
  axiosInstance = axios
) => {
  return axiosInstance.post(serviceUrls().dummyInfo, data, config);
};

export const updateUserInfo = (body, config = null, axiosInstance = axios) => {
  return axiosInstance.put(serviceUrls().updateInfo, body, config);
};

export const logout = (data, config = null, axiosInstance = axios) => {
  return axiosInstance.post(serviceUrls().logoutApi, data, config);
};

export const createRecruiter = (body, config = null, axiosInstance = axios) => {
  return axiosInstance.post(serviceUrls().createRecruiter, body, config);
};

export const changePassword = (data, config = null, axiosInstance = axios) => {
  return axiosInstance.post(
    serviceUrls().changePassword +
      '?email=' +
      data.email +
      '&password=' +
      data.new_password,
    config
  );
};

export const updatePassword = (data, config = null, axiosInstance = axios) => {
  return axiosInstance.post(serviceUrls().updatePassword, data, config);
};

export const updateDefaultPassword = (
  data,
  config = null,
  axiosInstance = axios
) => {
  return axiosInstance.post(serviceUrls().updateDefaultPassword, data, config);
};

export const enableDisableUsers = (
  data,
  config = null,
  axiosInstance = axios
) => {
  const enableDisableData = !data.is_enable;
  return axiosInstance.patch(
    serviceUrls().enableDisableUser +
      '?user_uuid=' +
      data.uuid +
      '&email=' +
      getEmailId() +
      '&disabled=' +
      enableDisableData,
    config
  );
};

export const enableDisableRecruiters = (
  data,
  config = null,
  axiosInstance = axios
) => {
  return axiosInstance.patch(serviceUrls().statusRecruiter, data, config);
};

export const resetPassword = (data, config = null, axiosInstance = axios) => {
  return axiosInstance.post(serviceUrls().resetEmailPassword, data, config);
};

export const fetchPersonalInfo = (
  data,
  config = null,
  axiosInstance = axios
) => {
  return axiosInstance.post(serviceUrls().userInfo, data, config);
};

export const fetchPersonalInfoEx = (
  data,
  config = null,
  axiosInstance = axios
) => {
  return axiosInstance.post(serviceUrls().userInfoEx, data, config);
};

export const fetchHeadshotUrl = (
  data,
  config = null,
  axiosInstance = axios
) => {
  return axiosInstance.post(serviceUrls().uploadHeadshot, data, config);
};

export const googleSignOn = (data, config = null, axiosInstance = axios) => {
  return axiosInstance.post(
    serviceUrls().googleLogin + '?token=' + data,
    config
  );
};

export const googleRegister = (data, config = null, axiosInstance = axios) => {
  return axiosInstance.post(
    serviceUrls().googleRegister + '?token=' + data,
    config
  );
};

export const microsoftSignOn = (data, config = null, axiosInstance = axios) => {
  return axiosInstance.post(
   serviceUrls().microsoftLogin,  data, config
  );
};

export const microsoftRegister = (data, config = null, axiosInstance = axios) => {
  return axiosInstance.post(
    serviceUrls().microsoftRegister, data, config
  );
};

export const verifyUserEmail = (data, config = null, axiosInstance = axios) => {
  return axiosInstance.post(serviceUrls().verifyEmail, data, config);
};

export const reGenerateEmailCode = (
  data,
  config = null,
  axiosInstance = axios
) => {
  return axiosInstance.post(serviceUrls().regenerateVerifiedCode, data, config);
};

export const getCalendarIntLink = (
  data,
  config = null,
  axiosInstance = axios
) => {
  return axiosInstance.post(serviceUrls().calendarIntegrationUrl, data, config);
};

export const setNotificationAsRead = (
  data,
  config = null,
  axiosInstance = axios
) => {
  return axiosInstance.post(serviceUrls().setNotificationAsRead, data, config);
};