import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, location } from 'react-router-dom';
import {
    AiFillEdit,
    AiOutlineCheckCircle,
    AiFillFilePdf,
    AiFillInfoCircle,
    AiOutlineLinkedin,
    AiOutlineFilePdf,
    AiOutlineInfoCircle,
    AiOutlineArrowLeft,
    AiOutlineCaretLeft,
  } from 'react-icons/ai';
import {
  Box, Icon,
  HStack,
  Text,
  Spacer,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  CloseButton,
  UnorderedList,
  List,
  ListItem,
  ListIcon,
  Center,
  Flex,
  Button,
  Input,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  Link,
  TabPanel,
  Slider,
  SliderTrack,
  SliderFilledTrack,
  SliderThumb,
  SliderMark,
  useDisclosure,
  useToast,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  AlertDialogCloseButton,
  Spinner,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  ModalBody,
  useColorModeValue,
} from '@chakra-ui/react';
import Card from '../../../../components/card/Card';
import { getEmailId } from '../../../../utils/localStorageIndex';
import { fetchSelectedPlanDetails, modifySelectedPlan } from '../../../../services/paymentServices';
import { fetchActiveJobsList } from '../../../../services/jobTemplateService';
  

function ModifySubscription() {
    const toast = useToast();
    const navigate = useNavigate();
    const { state } = useLocation();
    const { isOpen, onOpen, onClose } = useDisclosure()
    const cancelRef = React.useRef()


    const btnRef = React.useRef();
    const customerEmail = getEmailId() ? getEmailId() : null;
    const subKey = state?.subKey ? state?.subKey : null;
    const [loading, isLoading] = useState(false);

    const [totalJobsCount, setTotalJobsCount] = useState(0);
    const [openJobsCount, setOpenJobsCount] = useState(0);  
    const [totalSlotCount, setTotalSlotCount] = useState(0);  
    const [remainingSlotCount, setRemainingSlotCount] = useState(0);  
  
    const [subscriptionData, setSubscriptionData] = useState([]);
    const [subscriptionKey, setSubscriptionKey] = useState(null);
    const [targetSlotCount, setTargetSlotCount] = useState(0);
    const [sliderValue, setSliderValue] = useState(1);
    const [jobsData, setJobsData] = useState([]);
    
    const textColorPrimary = useColorModeValue('secondaryGray.900', 'gray.600');
    const [alertVisible, setAlertVisible] = useState(false);
    const [alertMessage, setAlertMessage] = useState(
      {
        title:'Bad Setting',
        message:"You do not have proper access to this page.. Please go back to contact Talent Strap support!!",
        type:'error'
      });

    const shadow = useColorModeValue(
        '14px 17px 40px 4px rgba(112, 144, 176, 0.18)',
        '14px 17px 40px 4px rgba(112, 144, 176, 0.06)'
    );

    useEffect(() => {
        fetchSelectedSubscription();
        // collectAllActiveJobs();
        // fetchVariousCountList();
    }, [subKey]);

    const fetchSelectedSubscription = () => {
        if (subKey != null){
            let objBody = {
                email: getEmailId(),
                subKey: subKey
            };
            fetchSelectedPlanDetails(objBody)
            .then(res => {
                isLoading(false);
                if (res?.result && res?.data?.length === 1 && res?.data[0]?.subscriptions?.length > 0) {
                    setSubscriptionData(res?.data[0]?.subscriptions);
                    if (res?.data[0]?.subscriptions[0]?.total_slots > 1){
                        setTargetSlotCount(res?.data[0]?.subscriptions[0]?.total_slots-1);
                    }
                } 
                if (res?.result && res?.data?.length === 1 && res?.data[0]?.counters) {
                    setOpenJobsCount(res?.data[0]?.counters.open_jobs);
                    setTotalJobsCount(res?.data[0]?.counters.total_jobs);
                    setTotalSlotCount(res?.data[0]?.counters.total_slots);
                    setRemainingSlotCount(res?.data[0]?.counters.remaining_slots)
                }
            })
            .catch(err => {
                isLoading(false);
                console.log(err);
            });
        }
    };

    // fetch count list
    // const fetchVariousCountList = () => {
    //     let objBody = {
    //         email: getEmailId(),
    //     };
    //     jobWorkflowStatusCount(objBody)
    //     .then(res => {
    //         if (res.data[0] && res.result) {
    //             setOpenJobsCount(res.data[0].open_jobs);
    //             setTotalJobsCount(res.data[0].total_jobs);
    //             setTotalSlotCount(res.data[0].total_slots);
    //             let remainCount = res.data[0].total_slots - res.data[0].open_jobs;
    //             if (targetSlotCount > remainCount){
    //                 setRemainingSlotCount(targetSlotCount);
    //             } else {
    //                 setRemainingSlotCount(remainCount);
    //             }
    //         }
    //     })
    //     .catch(err => {
    //         console.log(err);
    //     });
    // };

    // const collectAllActiveJobs = () => {
    //     let objData = {
    //       email: getEmailId()
    //     };
    //     fetchActiveJobsList(objData)
    //       .then(res => {
    //         if (res?.result && res?.data.length > 0 ) {
    //             if (res?.data?.[0].records?.length > 0){
    //                 setJobsData(res?.data?.[0].records);
    //             }
    //         } else {
    //           setJobsData([]);
    //         }
    //       })
    //       .catch(err => {
    //         setJobsData([]);
    //       });
    //   };

    const startModificationProcess = () => {
        if (subscriptionKey != null){
            let objBody = {
                email: getEmailId(),
                sid: subscriptionKey,
                slots: targetSlotCount
            };
            modifySelectedPlan(objBody)
            .then(res => {
                isLoading(false);
                if (res?.result && res?.data?.length === 1 && res?.data[0]?.status) {
                    onClose();
                    toastFunction('Subscription Modified Successfully', 'success');
                    navigate('/business/billing', {
                        state: {
                            sourcePage:'cancelplan',
                        },
                    });
                } else{
                    toastFunction('Unable to modify the subscription. Please contact Talent Strap support.', 'error');
                    onClose();
                }
            })
            .catch(err => {
                toastFunction('Unable to modify the subscription. Please contact Talent Strap support.', 'error');
                console.log(err);
                onClose();
            });      
        }
    }

    // const renderJobItem = (jobIem) => {
    //     return(
    //         <Flex w={'100%'} p={1} borderWidth={'0.5px'} rounded={'lg'} borderColor={'gray.200'}>
    //             <Flex w={'50%'}>
    //                 <Text>{jobIem.job_title}</Text>
    //             </Flex>
    //             <Flex w={'20%'}>
    //                 {jobIem.job_status === 'Open'
    //                 ?<Text p={1} rounded={'md'} bg={'green.200'} color={'gray.600'}>{jobIem.job_status}</Text>:null}
    //                 {jobIem.job_status === 'Draft'
    //                 ?<Text p={1} rounded={'md'} bg={'yellow.300'} color={'gray.600'}>{jobIem.job_status}</Text>:null}
    //             </Flex>
    //             <Flex w={'30%'} align={'end'}>
    //                 <Spacer />
    //                 <Button size={'sm'} bg={'red.100'}  color={'gray.600'}>
    //                     Close Job
    //                 </Button>
    //             </Flex>
    //         </Flex>
    //     )
    // }

    // const renderJobsList = () => {
    //     if (jobsData?.length > 0){
    //         return(
    //             jobsData.map((item, index) => {
    //                 return(
    //                     <Flex w={'100%'} key={index} direction={'column'}>
    //                         {renderJobItem(item, index)}
    //                     </Flex>
    //                 )
    //             }) 
    //         )
    //     }
    // }

    // const updateSlotCount = () => {
    //     if (subscriptionData.length > 0 && subscriptionData[0]?.total_slots >= 2){
    //         if (targetSlotCount > 1){
    //             setTargetSlotCount(targetSlotCount-1);
    //         } else {
    //             toastFunction('The target slot count can not be 0', 'error');
    //         }
    //     }
    // }

    // const resetSlotCount = () => {
    //     if (subscriptionData.length > 0 && subscriptionData[0]?.total_slots >= 2){
    //         setTargetSlotCount(subscriptionData[0].total_slots-1);
    //     }
    // }
    

    const renderSpinner = () => {
        return (
        <Flex h="50vh" alignItems="center" justifyContent="center" mt="10">
            <Spinner size="xl" />
        </Flex>
        );
    };

    const toastFunction = (message, status) => {
        return toast({
        title: message,
        status: status,
        isClosable: true,
        duration: 3000,
        });
    };

    const backToBillingPage = () => {
        navigate('/business/billing', {
            state: {
                sourcePage:'purchaseplan',
                adminSettings: false
            },
        });
    };

    return (
        <>
        {loading ? (
            <>{renderSpinner()}</>
        ) : (
            <Flex direction="column" pt={{ sm: '125px', lg: '75px' }}>
            <Flex
                direction="column"
            >
                <Flex w={'100%'} direction={'column'} align={'center'}>
                    {subKey === null
                    ?<Flex w={'100%'} p={2} direction={'column'}>
                        <Alert status={alertMessage.type} >
                            <AlertIcon />
                            <Box>
                                <AlertTitle>{alertMessage.title}</AlertTitle>
                                <AlertDescription>{alertMessage.message}</AlertDescription>
                            </Box>
                            <Spacer />
                            <CloseButton
                                alignSelf='flex-start'
                                position='relative'
                                right={-1}
                                top={-1}
                            />                    
                        </Alert>
                    </Flex>
                    :<Flex w={'100%'} direction={'column'}>
                    <Card w={'100%'} p={1}>
                        <Flex w={'100%'} p={2}>
                            <Text mt={4} fontSize={'xl'}>{'Modify Existing Subscription (Reduce Slots)'}</Text>
                            <Spacer />
                            <Button 
                                onClick={() => backToBillingPage()}
                                mt={4} color={textColorPrimary} bg={'red.200'}>
                                <Icon as={AiOutlineCaretLeft} mr={2} />Cancel
                            </Button>
                        </Flex>
                    </Card>
                    <Card w={'100%'} mt={1}>
                        <HStack w={'100%'}>
                        <Flex w={'30%'}>
                            <Card w={'100%'} h={'400px'} p={2}>
                                <Text fontSize={'xl'}>Modification is done on the following criteria:</Text>
                                <List spacing={3} mt={5}>
                                    <ListItem>
                                        <ListIcon as={AiOutlineCheckCircle} color='green.500' />
                                        You can reduce number of slots upto minimum 1.
                                    </ListItem>
                                    <ListItem>
                                        <ListIcon as={AiOutlineCheckCircle} color='green.500' />
                                        To reduce your slots to 0, please use <b>"Cancel Subscription"</b> option.
                                    </ListItem>
                                    <ListItem>
                                        <ListIcon as={AiOutlineCheckCircle} color='green.500' />
                                        This page does not help you to add new slots. 
                                    </ListItem>
                                    <ListItem>
                                        <ListIcon as={AiOutlineCheckCircle} color='green.500' />
                                        Please use <b>"+"</b> option to add more slots. 
                                    </ListItem>
                                </List>
                            </Card>
                        </Flex>
                        <Flex w={'20%'} direction={'column'}  h={'400px'}>
                            <Flex w={'100%'} direction={'column'} align={'start'}>
                            {subKey != null 
                            ?<Card align={'start'}  h={'400px'}  p={2}>
                                {subscriptionData?.length > 0
                                ?<Flex w={'100%'} direction={'column'}>
                                    <Text fontSize={'lg'}>{'Plan Info'}</Text>
                                    <Text mt={2}  fontSize={'md'}><b>{subscriptionData[0].plan_type}</b></Text>
                                    <Text>{subscriptionData[0].plan_info}</Text>
                                    <Flex direction={'column'}
                                        rounded={'md'} borderWidth={'0.5px'} borderColor={'gray.200'} p={2}>
                                        <Text fontSize={'3xl'}><b>{subscriptionData[0].total_slots}</b></Text>
                                        <Text>Slots in this Subscription</Text>
                                    </Flex>
                                    <Text>{subscriptionData[0].is_annual ? 'Annual': 'Monthly'} Plan</Text>
                                    <Text>Subscription Started: </Text>
                                    <Text><b>{new Date(subscriptionData[0].start_date).toLocaleDateString('en-us', 
                                    { weekday:"short", year:"numeric", month:"short", day:"numeric"})}</b></Text>
                                </Flex>
                                :<Text color={'red.400'}>Unable to get the subscription details!!</Text>}
                            </Card>
                            :<Flex w={'100%'} p={2} direction={'column'}>
                                <Text color={'red.200'}>Something is not right to subscribe business plan, please contact Talent Strap Support.</Text>
                            </Flex>}
                            </Flex>
                        </Flex>
                        <Flex w={'50%'} direction={'column'}  h={'400px'}>
                            <Flex w={'100%'} direction={'column'} align={'start'}>
                            {subKey != null 
                            ?<Card align={'start'}  h={'400px'} p={2}>
                                {subscriptionData?.length > 0
                                ?<Flex w={'100%'} direction={'column'}>
                                    <Flex w={'100%'} p={2} borderWidth={'0.5px'} rounded={'lg'} borderColor={'gray.200'}>
                                        <Flex w={'50px'} align={'center'} pl={4}>
                                            <Text color={remainingSlotCount === 0 ? 'red' : 'green'} fontSize={'2xl'}>{remainingSlotCount}</Text>
                                        </Flex>
                                        <Flex w={'100%'} align={'center'}>
                                            {remainingSlotCount > 0
                                            ?<Flex w={'100%'} direction={'column'}>
                                                <Text>You have{' '}{remainingSlotCount}{' '}open slots available to cancel here.</Text>
                                                <Text>You can go back to jobs list and close few open jobs to cancel more slots.</Text>
                                            </Flex>
                                            :<Flex w={'100%'} direction={'column'}>
                                                <Text>You do not have any available slots.</Text>
                                                <Text>You will need to close an open job or upgrade your subscription to add more slots.</Text>
                                            </Flex>}
                                        </Flex>
                                    </Flex>
                                    {remainingSlotCount > 0
                                    ?<Flex w={'100%'} direction={'column'} p={2} borderWidth={'0.5px'} rounded={'lg'} borderColor={'gray.200'}>
                                        <Text><b>Step 1:</b>{' '}Please select how many slots you want to cancel based on available slots</Text>
                                        <Flex w={'100%'}>
                                            <Flex p={2} w={'130px'} align={'center'}>
                                                <Text>Minimum <b>{'1'}</b></Text>
                                            </Flex>
                                            <Flex p={2} w={'100%'}>
                                                <Slider aria-label='slider-ex-1' 
                                                    onChange={(val) => {
                                                        setSliderValue(val);
                                                        setTargetSlotCount(subscriptionData[0].total_slots-val);
                                                    }}
                                                    defaultValue={1} min={1} max={remainingSlotCount} step={1}>
                                                    <SliderTrack>
                                                        <SliderFilledTrack />
                                                    </SliderTrack>
                                                    <SliderThumb />
                                                </Slider>                                                
                                            </Flex>
                                            <Flex p={2} w={'130px'} align={'center'}>
                                                <Text>Maximum <b>{remainingSlotCount}</b></Text>
                                            </Flex>
                                        </Flex>
                                    </Flex>:null}
                                    {remainingSlotCount > 0
                                    ?<Flex w={'100%'} p={2} borderWidth={'0.5px'} rounded={'lg'} borderColor={'gray.200'}>
                                        <Flex w={'50px'} align={'center'} pl={4}>
                                            <Text color={sliderValue === 0 ? 'red' : 'green'} fontSize={'2xl'}>{sliderValue}</Text>
                                        </Flex>
                                        <Flex w={'100%'} align={'center'}>
                                            {sliderValue > 0
                                            ?<Flex w={'100%'} direction={'column'}>
                                                <Text>You have selected{' '}{sliderValue}{' '}slot to cancel.</Text>
                                            </Flex>
                                            :<Flex w={'100%'} direction={'column'}>
                                                <Text>You have not selected any slot to cancel.</Text>
                                            </Flex>}
                                        </Flex>
                                    </Flex>:null}                                    
                                    {/* <Text color={'gray.600'} mb={1} pl={1}><b>Step 1:</b>{' '}Please close some of the open jobs to free up slots to cancel.</Text> */}
                                    {/* {renderJobsList()} */}
                                    {remainingSlotCount > 0
                                    ?<Flex w={'100%'} direction={'column'}>
                                        <Text mt={5} fontSize={'sm'}><b>Step 2:</b>{' '}Please use <b>Reduce Slots Count</b> button below to reduce slot count.</Text>
                                        <Flex p={2} borderWidth={'0.5px'} rounded={'lg'} borderColor={'red.200'}
                                            w={'100%'} mt={2}>
                                                {/* <Flex w={'30%'}>
                                                    <Button borderWidth={'0.5px'} rounded={'lg'} borderColor={'red.200'}
                                                        onClick={() => {
                                                            updateSlotCount();
                                                        }}
                                                    >Reduce 1 Slots</Button>
                                                    <Button ml={1} borderWidth={'0.5px'} rounded={'lg'} borderColor={'red.200'}
                                                        onClick={() => {
                                                            resetSlotCount();
                                                        }}
                                                    >Reset</Button>
                                                </Flex> */}
                                                <Flex w={'70%'}>
                                                    <Button  
                                                        isDisabled={targetSlotCount === 0 ? true : false}
                                                        onClick={() => 
                                                            {
                                                                setSubscriptionKey(subscriptionData[0]?.sub_key);
                                                                onOpen();
                                                            }}
                                                        ml={1} size={'md'} px={10} borderWidth={'0.5px'} rounded={'lg'} borderColor={'red.200'}>
                                                            Update Subscription with {targetSlotCount} remaining slots
                                                    </Button>                                                    
                                                </Flex>
                                        </Flex>
                                        <Text color={'red.500'} pl={1}><b>Note:</b>{' '}Subscription modification action is final there is no turing back.</Text>
                                    </Flex>:null}
                                </Flex>
                                :<Text color={'red.400'}>Unable to get the subscription details!!</Text>}
                            </Card>
                            :<Flex w={'100%'} p={2} direction={'column'}>
                                <Text color={'red.200'}>Something is not right to subscribe business plan, please contact Talent Strap Support.</Text>
                            </Flex>}
                            </Flex>
                        </Flex>
                        </HStack>
                        </Card>
                    </Flex>}
                </Flex>
            </Flex>
            <AlertDialog
                isOpen={isOpen}
                leastDestructiveRef={cancelRef}
                onClose={onClose}
            >
                <AlertDialogOverlay>
                <AlertDialogContent>
                    <AlertDialogHeader fontSize='lg' fontWeight='bold'>
                    Modify Subscription to {targetSlotCount} Slots
                    </AlertDialogHeader>
                    <AlertDialogBody p={8}>
                    Are you sure to modify the subscription? 
                    <br/>
                    You can't undo this action afterwards.
                    </AlertDialogBody>

                    <AlertDialogFooter>
                    <Button ref={cancelRef} onClick={onClose}>
                        Cancel
                    </Button>
                    <Button colorScheme='red' onClick={() => startModificationProcess()} ml={3}>
                        Modify Subscription
                    </Button>
                    </AlertDialogFooter>
                </AlertDialogContent>
                </AlertDialogOverlay>
            </AlertDialog>
            </Flex>
        )}
        </>
    );
}

export default ModifySubscription;
