import { useState } from "react";
import { addCandidateEducation } from "../../services/companyServices";
import { isObjectEmpty, toTitleCase } from "../../utils/common";
import { Button, Checkbox, Drawer, DrawerCloseButton, DrawerContent, Flex, Input, Text, useToast } from "@chakra-ui/react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

export default function AddEducation(props) {
    const candidate = props?.candidate
    const [addEducationStartDate, setAddEducationStartDate] = useState();
    const [addEducationEndDate, setAddEducationEndDate] = useState();
    const [addEducationPresent, setAddEducationPresent] = useState(false);
    const [isSubmittingEducation, setIsSubmittingEducation] = useState(false);
    const toast = useToast();

    if (!candidate || isObjectEmpty(candidate)){
        return null
    }

    const onSubmitEducation= (e) => {
        e.preventDefault()
        const form = new FormData(e.target)
        form.set('start_date', form.get('start_date').split('/').reverse().join('-'))
        if (form.get('end_date')){
            form.set('end_date', form.get('end_date').split('/').reverse().join('-'))
        }
        form.set('candidate_uuid', candidate?.candidate_uuid)
        setIsSubmittingEducation(true);
        addCandidateEducation(form)
        .then(res => {
            if (res?.result){
                props.getCandidatePanelData(candidate);
                toast({
                    title: 'Education added successfully.',
                    status: 'success', 
                    isClosable: true, 
                    duration: 3000
                });
                props.onAddEducationPanelClose()
            }else{
                toast({
                    title: 'Education not added.',
                    description: res?.message,
                    status: 'error', 
                    isClosable: true, 
                    duration: 3000
                  });
            }
            setIsSubmittingEducation(false);
        })
        .catch(err => {
            console.log(err)
            setIsSubmittingEducation(false);
            toast({
                title: 'Education not added.',
                description: err.toString(),
                status: 'error', 
                isClosable: true, 
                duration: 3000
              });
        })
        setIsSubmittingEducation(false);
    }

    return (
        <Drawer size={'lg'} isOpen={props.isAddEducationPanelOpen} placement="right" onClose={props.onAddEducationPanelClose} >
                <DrawerContent p={5}>
                <form onSubmit={onSubmitEducation}>
                    <Text fontWeight={'bold'} mb={10} fontSize={'18px'}>Add Education for {candidate?.first_name}</Text>                    

                    <Flex alignItems={'center'} mb={8}>
                        <Text as={'b'} width={'150px'} mr={3}>Degree Title:</Text>
                        <Input name='degree_title' required />
                    </Flex>

                    <Flex alignItems={'center'} mb={8}>
                        <Text as={'b'} width={'150px'} mr={3}>Start Date:</Text>
                        <DatePicker
                            className='chakra-input css-1jaduyy' required selected={addEducationStartDate}
                            onChange={(date) => setAddEducationStartDate(date)}
                            name='start_date' dateFormat="MM/yyyy" showMonthYearPicker
                        />
                    </Flex>

                    <Flex alignItems={'center'} mb={10} direction={'column'}>
                        <Flex width={'100%'}>
                            <Text as={'b'} width={'150px'} mr={3}>End Date:</Text>
                            <DatePicker
                            className='chakra-input css-1jaduyy' selected={addEducationEndDate}
                            onChange={(date) => {
                                setAddEducationEndDate(date);
                                setAddEducationPresent(false);
                            }}
                            name='end_date' dateFormat="MM/yyyy" showMonthYearPicker
                        />
                        </Flex>

                        <Flex width={'50%'} mt={3}>
                            <Text width={'50px'} mr={3}>Present</Text>
                            <Checkbox value={addEducationPresent} isChecked={addEducationPresent} name='present' onChange={(e) => {
                                if (e.target.checked){                                    
                                    setAddEducationEndDate(null)                                    
                                }
                                setAddEducationPresent(!addEducationPresent);
                            }} />
                        </Flex>
                    </Flex>

                    <Flex alignItems={'center'} mb={8}>
                        <Text as={'b'} width={'150px'} mr={3}>School Name:</Text>
                        <Input name='school_name' required />
                    </Flex>

                    <Flex alignItems={'center'} mb={8}>
                        <Text as={'b'} width={'150px'} mr={3}>School Location:</Text>
                        <Input name='school_location' required />
                    </Flex>

                    <Flex alignItems={'center'} mb={8}>
                        <Text as={'b'} width={'150px'} mr={3}>School Website:</Text>
                        <Input name='school_website' />
                    </Flex>

                    <Flex alignItems={'center'} mb={8}>
                        <Text as={'b'} width={'150px'} mr={3}>School Logo:</Text>
                        <Input type='file' name={'school_logo'} accept='.png, .jpg, .jpeg, .svg, .webp' />
                    </Flex>
                    
                    <Button mt={5} float={'right'} width={'100px'} variant={'solid'} colorScheme='blue' loadingText={'Adding...'}
                    isLoading={isSubmittingEducation} type='submit'>Add</Button>
                </form>
                <DrawerCloseButton />
                </DrawerContent>
            </Drawer>
    )
}