import React, { useState } from 'react';
import { Button, Text, Flex, Input, Drawer, DrawerContent, DrawerHeader, DrawerCloseButton, useToast,
  useColorModeValue, Box, Checkbox, Image, Spacer} from '@chakra-ui/react';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import dummyEmployer from '../../../../../src/image/dummy_employer.jpg';
import { addCandidateEducation } from '../../../../services/companyServices';
import moment from 'moment'


export default function EditCandidateEducation(props) {
  const {candidate, educationForEdit} = props;
  const education = educationForEdit?.education
  const educationIndex = educationForEdit?.index
  const toast = useToast()
  const [isFormSubmitting, setIsFormSubmitting] = useState(false)
  const brandColor = useColorModeValue('brand.500', 'white');
  const [addEducationStartDate, setAddEducationStartDate] = useState(null);
  const [addEducationEndDate, setAddEducationEndDate] = useState(null);
  const [addEducationPresent, setAddEducationPresent] = useState(false);

  const onSubmitForm = (e) => {
    e.preventDefault()
    const form = new FormData(e.target)
    form.set('start_date', form.get('start_date').split('/').reverse().join('-'))
    if (form.get('end_date')){
        form.set('end_date', form.get('end_date').split('/').reverse().join('-'))
    }
    form.set('candidate_uuid', candidate?.candidate_uuid)
    form.set('education_index', educationIndex)
    setIsFormSubmitting(true);
    addCandidateEducation(form)
    .then(res => {
        if (res?.result){
            props.getAllCandidatesForJob();
            toast({
                title: 'Education updated successfully.',
                status: 'success', 
                isClosable: true, 
                duration: 3000
            });
        }else{
            toast({
                title: 'Education not updated.',
                description: res?.message,
                status: 'error', 
                isClosable: true, 
                duration: 3000
              });
        }
        setIsFormSubmitting(false);
    })
    .catch(err => {
        console.log(err)
        setIsFormSubmitting(false);
        toast({
            title: 'Education not updated.',
            description: err.toString(),
            status: 'error', 
            isClosable: true, 
            duration: 3000
          });
    })
  }

  const getEducationStartDate = () => {
    if (addEducationStartDate){
      return addEducationStartDate
    }
    if (education?.start_date){
      return moment(education?.start_date).format('MM/YYYY')
    }
  }

  const getEducationEndDate = () => {
    if (!addEducationPresent && addEducationEndDate){
      return addEducationEndDate
    }
    if (!addEducationPresent && education?.end_date && education?.end_date?.toLowerCase() != 'present'){
      return moment(education?.end_date).format('MM/YYYY')
    }
  }

  const getEducationTillPresent = () => {
    if (addEducationPresent){
      return true
    }

    if (!getEducationEndDate() && education?.end_date && education?.end_date?.toLowerCase() == 'present'){
      return true
    }

    return false
  }

  return (
    <Drawer size={'lg'} isOpen={props.isEditEducationPanelOpen} onClose={props.onEditEducationPanelClose}>
      <DrawerContent pl={5} >
        <DrawerHeader>Edit Candidate Education</DrawerHeader>
        <DrawerCloseButton />
        <form onSubmit={onSubmitForm}>
          <Flex p={'30px'} direction={'column'} mt={5}>
            <Flex alignItems={'center'} mb={8}>
                <Text as={'b'} minW={'120px'} mr={3}>School Name</Text>
                <Input defaultValue={education?.school?.name} name='school_name' required />
            </Flex>

            <Flex alignItems={'center'} mb={8}>
                <Text as={'b'} minW={'120px'} mr={3}>Degree Title</Text>
                <Input defaultValue={education?.degrees?.join(' ')} name='degree_title' required />
            </Flex>

            <Flex alignItems={'center'} mb={8}>
                <Text as={'b'} minWidth={'120px'} mr={3}>Start Date</Text>
                <Box width={'40%'}>
                <DatePicker
                    autoComplete="off"
                    value={getEducationStartDate()}
                    className='chakra-input css-1jaduyy' required selected={addEducationStartDate}
                    onChange={(date) => setAddEducationStartDate(date)}
                    name='start_date' dateFormat="MM/yyyy" showMonthYearPicker
                />
                </Box>

                <Text as={'b'} minW={'80px'} ml={10}>End Date</Text>
                <Box width={'40%'}>                    
                    <DatePicker
                    autoComplete="off"
                    value={getEducationEndDate()}
                    className='chakra-input css-1jaduyy' selected={addEducationEndDate}
                    onChange={(date) => {
                        setAddEducationEndDate(date);
                        setAddEducationPresent(false)
                    }}
                    name='end_date' dateFormat="MM/yyyy" showMonthYearPicker
                />
                </Box>

                <Text width={'50px'} ml={5} mr={3}>Present</Text>
                <Checkbox value={getEducationTillPresent()} isChecked={getEducationTillPresent()} name='present' onChange={(e) => {
                    setAddEducationPresent(e.target.checked);
                }} />
            </Flex>

            <Flex alignItems={'center'} mb={8}>
                <Text as={'b'} minW={'120px'} mr={3}>School Location</Text>
                <Input defaultValue={education?.location_names?.join('. ')} name='school_location' />
            </Flex>

            <Flex alignItems={'center'} mb={8}>
                <Text as={'b'} minW={'120px'} mr={3}>School Website</Text>
                <Input defaultValue={education?.school?.website} name='school_website' />
            </Flex>

            <Flex alignItems={'center'} mb={8}>
                <Text as={'b'} minW={'120px'} mr={3}>School Logo</Text>
                <Input width={'50%'} type='file' name={'school_logo'} accept='.png, .jpg, .jpeg, .svg, .webp' />
                <Spacer />
                <Box width={'100px'} height={'100px'}>
                    <Image
                        borderRadius='full'
                        boxSize='100px'
                        objectFit={'contain'}
                        border={'1px solid'}
                        bgColor={'gray.200'}
                        borderColor={'gray.200'}
                        src={education?.school?.logo ? education?.school?.logo : dummyEmployer}
                        onError={e => e.target.src = dummyEmployer}
                    />
                </Box>
            </Flex>

            <Button type='submit' width={'200px'} variant="brand" _hover={{'bg': brandColor}}
                fontWeight="500" isLoading={isFormSubmitting} loadingText={'Updating...'}
            >
              Update
            </Button>
          </Flex>
        </form>  
      </DrawerContent>
    </Drawer>    
  );
}
