import React, { useState } from 'react';
import { Button, Text, Flex, Input, Drawer, DrawerContent, DrawerHeader, DrawerCloseButton, useToast,
  useColorModeValue, Box, Checkbox, Textarea, Image, Spacer} from '@chakra-ui/react';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import dummyEmployer from '../../../../../src/image/dummy_employer.jpg';
import { addCandidateExperience } from '../../../../services/companyServices';
import moment from 'moment'


export default function EditCandidateExperience(props) {
  const {candidate, experienceForEdit} = props;
  const experience = experienceForEdit?.experience
  const experienceIndex = experienceForEdit?.index
  const toast = useToast()
  const [isFormSubmitting, setIsFormSubmitting] = useState(false)
  const brandColor = useColorModeValue('brand.500', 'white');
  const [addExperienceStartDate, setAddExperienceStartDate] = useState(null);
  const [addExperienceEndDate, setAddExperienceEndDate] = useState(null);
  const [addExperiencePresent, setAddExperiencePresent] = useState(false);

  const onSubmitForm = (e) => {
    e.preventDefault()
    const form = new FormData(e.target)
    form.set('start_date', form.get('start_date').split('/').reverse().join('-'))
    if (form.get('end_date')){
        form.set('end_date', form.get('end_date').split('/').reverse().join('-'))
    }
    form.set('candidate_uuid', candidate?.candidate_uuid)
    form.set('experience_index', experienceIndex)
    setIsFormSubmitting(true);
    addCandidateExperience(form)
    .then(res => {
        if (res?.result){
            props.getAllCandidatesForJob();
            toast({
                title: 'Experience updated successfully.',
                status: 'success', 
                isClosable: true, 
                duration: 3000
            });
        }else{
            toast({
                title: 'Experience not updated.',
                description: res?.message,
                status: 'error', 
                isClosable: true, 
                duration: 3000
              });
        }
        setIsFormSubmitting(false);
    })
    .catch(err => {
        console.log(err)
        setIsFormSubmitting(false);
        toast({
            title: 'Experience not updated.',
            description: err.toString(),
            status: 'error', 
            isClosable: true, 
            duration: 3000
          });
    })
  }

  const getExperienceStartDate = () => {
    if (addExperienceStartDate){
      return addExperienceStartDate
    }
    if (experience?.start_date){
      return moment(experience?.start_date).format('MM/YYYY')
    }
  }

  const getExperienceEndDate = () => {
    if (!addExperiencePresent && addExperienceEndDate){
      return addExperienceEndDate
    }
    if (!addExperiencePresent && experience?.end_date && experience?.end_date?.toLowerCase() != 'present'){
      return moment(experience?.end_date).format('MM/YYYY')
    }
  }

  const getExperienceTillPresent = () => {
    if (addExperiencePresent){
      return true
    }

    if (!getExperienceEndDate() && experience?.end_date && experience?.end_date?.toLowerCase() == 'present'){
      return true
    }

    return false
  }

  return (
    <Drawer size={'lg'} isOpen={props.isEditExperiencePanelOpen} onClose={props.onEditExperiencePanelClose}>
      <DrawerContent pl={5} >
        <DrawerHeader>Edit Candidate Experience</DrawerHeader>
        <DrawerCloseButton />
        <form onSubmit={onSubmitForm}>
          <Flex p={'30px'} direction={'column'} mt={5}>
            <Flex alignItems={'center'} mb={8}>
                <Text as={'b'} minW={'120px'} mr={3}>Company Name</Text>
                <Input defaultValue={experience?.company?.name} name='company_name' required />
            </Flex>

            <Flex alignItems={'center'} mb={8}>
                <Text as={'b'} minW={'120px'} mr={3}>Job Title</Text>
                <Input defaultValue={experience?.title?.name} name='job_title' required />
            </Flex>

            <Flex alignItems={'center'} mb={8}>
                <Text as={'b'} minWidth={'120px'} mr={3}>Start Date</Text>
                <Box width={'40%'}>
                <DatePicker
                    autoComplete="off"
                    value={getExperienceStartDate()}
                    className='chakra-input css-1jaduyy' required selected={addExperienceStartDate}
                    onChange={(date) => setAddExperienceStartDate(date)}
                    name='start_date' dateFormat="MM/yyyy" showMonthYearPicker
                />
                </Box>

                <Text as={'b'} minW={'80px'} ml={10}>End Date</Text>
                <Box width={'40%'}>                    
                    <DatePicker
                    autoComplete="off"
                    value={getExperienceEndDate()}
                    className='chakra-input css-1jaduyy' selected={addExperienceEndDate}
                    onChange={(date) => {
                        setAddExperienceEndDate(date);
                        setAddExperiencePresent(false)
                    }}
                    name='end_date' dateFormat="MM/yyyy" showMonthYearPicker
                />
                </Box>

                <Text width={'50px'} ml={5} mr={3}>Present</Text>
                <Checkbox value={getExperienceTillPresent()} isChecked={getExperienceTillPresent()} name='present' onChange={(e) => {
                    setAddExperiencePresent(e.target.checked);
                }} />
            </Flex>

            <Flex alignItems={'center'} mb={8}>
                <Text as={'b'} minW={'120px'} mr={3}>Job Summary</Text>
                <Textarea defaultValue={experience?.summary} name='job_summary' rows={10} />
            </Flex>

            <Flex alignItems={'center'} mb={8}>
                <Text as={'b'} minW={'120px'} mr={3}>Job Location</Text>
                <Input defaultValue={experience?.location_names?.join('. ')} name='job_location' />
            </Flex>

            <Flex alignItems={'center'} mb={8}>
                <Text as={'b'} minW={'120px'} mr={3}>Company Website</Text>
                <Input defaultValue={experience?.company?.website} name='company_website' />
            </Flex>

            <Flex alignItems={'center'} mb={8}>
                <Text as={'b'} minW={'120px'} mr={3}>Company Logo</Text>
                <Input width={'50%'} type='file' name={'company_logo'} accept='.png, .jpg, .jpeg, .svg, .webp' />
                <Spacer />
                <Box width={'100px'} height={'100px'}>
                    <Image
                        borderRadius='full'
                        boxSize='100px'
                        objectFit={'contain'}
                        border={'1px solid'}
                        bgColor={'gray.200'}
                        borderColor={'gray.200'}
                        src={experience?.company?.logo ? experience?.company?.logo : dummyEmployer}
                        onError={e => e.target.src = dummyEmployer}
                    />
                </Box>
            </Flex>

            <Button type='submit' width={'200px'} variant="brand" _hover={{'bg': brandColor}}
                fontWeight="500" isLoading={isFormSubmitting} loadingText={'Updating...'}
            >
              Update
            </Button>
          </Flex>
        </form>  
      </DrawerContent>
    </Drawer>    
  );
}
