import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Flex, Box, Image, Text, useColorModeValue, useDisclosure, IconButton, Tooltip, Spacer} from '@chakra-ui/react';
import { BiCheck, BiPencil } from 'react-icons/bi';
import dummyEmployer from '../../../../../src/image/dummy_employer.jpg';
import { isObjectEmpty } from '../../../../utils/common';
import EditCandidateExperience from './EditCandidateExperience';

export default function CandidateExperience(props) {
    const SchemeTextColor = useColorModeValue('secondaryGray.900', 'gray.800');
    const [readMore, setReadMore] = useState({summary: false, skills: false, experience: false, experienceSummary: {}});
    const [candidate, setCandidate] = useState(props.candidate)
    const {job, isEditCandidate, getAllCandidatesForJob} = props;
    const [isEditExperience, setIsEditExperience] = useState(false)
    const [experienceForEdit, setExperienceForEdit] = useState({index: null, experience: {}})
    const { isOpen: isEditExperiencePanelOpen, onOpen: onEditExperiencePanelOpen, onClose: onEditExperiencePanelClose } = useDisclosure();

    const formatDate = (value1, value2) => {
        let dateString1 = null;
        if (value1 != null && value1 != undefined){
            let date = new Date(value1);
            const month = date.toLocaleString('default', { month: 'short' });
            const year = date.toLocaleString('default', { year: 'numeric' });
            dateString1 = month + ', ' + year;
        }
        let dateString2 = null;
        if (value2 != null && value2 != undefined && !['present', 'current'].includes(value2?.toLowerCase())){
            let date = new Date(value2);
            const month = date.toLocaleString('default', { month: 'short' });
            const year = date.toLocaleString('default', { year: 'numeric' });
            dateString2 = month + ', ' + year;
        }
        if (dateString1 != null && dateString2 != null) {
            return dateString1 + ' - ' + dateString2; 
        } else if (dateString1 != null && dateString2 == null){
            return dateString1 + ' - ' + 'present'; 
        } else if (dateString1 === null && dateString2 ==- null){
            return '';
        } else {
            return '';
        } 
    } 

    const ReadMoreButton = ({targetName, targetValue, style}) => {
        // targetName: summary, skills, experiences
        // targetValue: true, false
        return <>
            <Link to={'javascript:void(0)'} style={{'margin-left': '5px', 'color': 'blue', 'width': '100px', ...style}} 
                  onClick={() => {
                    const target = {}
                    target[targetName] = targetValue
                    setReadMore({...readMore, ...target})
                  }}>
                read more
            </Link>
        </>
    }

    const ReadLessButton = ({targetName, targetValue, style}) => {
        return <>
            <Link to={'javascript:void(0)'} style={{'margin-left': '5px', 'color': 'blue', 'width': '100px', ...style}}
                  onClick={() => {
                    const target = {}
                    target[targetName] = targetValue
                    setReadMore({...readMore, ...target})
                  }}>
                read less
            </Link>
        </>
    }

    const isExperienceEmpty = () => {
        return isObjectEmpty(candidate) || !candidate?.details?.raw_data?.experience || !candidate?.details?.raw_data?.experience.length
    }

    const renderLocation = (exItem) => {
        if (exItem?.location_names?.length > 0) {
            return(
                    <Text textTransform={'capitalize'} color={'gray'} fontWeight={'500'} float={'left'}>
                        {exItem?.location_names?.join('. ')}
                    </Text>
            )
        } else if (exItem?.company?.location?.country){
            return(
                <Text textTransform={'capitalize'} color={'gray'} fontWeight={'500'} float={'left'}>
                    {exItem?.company?.location?.country}
                </Text>
            )
        } else {
            return(
                <Text textTransform={'capitalize'} color={'gray'} fontWeight={'500'} float={'left'}>
                    {exItem?.company?.location?.country}
                </Text>
        )
        }
    }

    const EditExperienceButton = ({experience, index}) => {
        if (isEditCandidate && !isEditExperience && experienceForEdit?.experience?.company?.name != experience?.company?.name && experienceForEdit?.experience?.start_date != experience?.start_date){
        return <>
            <Tooltip label={'Edit Experience'} fontSize='md'>
                <span>
                    <IconButton fontSize={'20px'} bg={'none'} _hover={{'bg': 'none'}} icon={<BiPencil />} color={'gray'}
                        onClick={() => {
                            setIsEditExperience(true)
                            setExperienceForEdit({index: index, experience: experience})
                            onEditExperiencePanelOpen()
                        }}
                    />
                </span>
            </Tooltip>
        </>
        }

        if (isEditCandidate && isEditExperience && experienceForEdit?.experience?.company?.name == experience?.company?.name && experienceForEdit?.experience?.start_date == experience?.start_date){
        return <>
            <Tooltip label={'Finish Editing Experience'} fontSize='md'>
                <span><IconButton fontSize={'24px'} bg={'none'} _hover={{'bg': 'none'}} icon={<BiCheck />} color={'gray'}
                        onClick={() => {
                            setIsEditExperience(false)
                            setExperienceForEdit({index: null, experience: {}})
                            onEditExperiencePanelClose()
                        }}
                    />
                </span>
            </Tooltip>
        </>
        }
    }

    const ExperienceItems = ({experiences}) => {
        return experiences.map( (exItem, index) => {
            const experienceSummaryReadMore = {...readMore.experienceSummary}
            experienceSummaryReadMore[exItem?.start_date] = true
            const experienceSummaryReadLess = {...readMore.experienceSummary}
            experienceSummaryReadLess[exItem?.start_date] = false
            return(
                <Flex key={index} w={'100%'} color={'gray.600'} mb={4}> 
                    <Flex mr={'10px'} direction={'column'}>
                        <Box width={'60px'} height={'60px'}>
                            <Image 
                                borderRadius='full'
                                boxSize='60px'
                                objectFit={'contain'}
                                border={'1px solid'}
                                bgColor={'gray.200'}
                                borderColor={'gray.200'}
                                src={exItem?.company?.logo ? exItem?.company?.logo : dummyEmployer}
                                onError={e => e.target.src = dummyEmployer}
                            />
                        </Box>
                    </Flex>
                    <Flex direction={'column'} width={'80%'}>                             
                        <Box mb={'2px'}>
                            <Text textTransform={'capitalize'} fontWeight={'bold'}>{exItem?.title?.name}</Text>
                        </Box>
                        <Box mb={'2px'}>
                            <Text textTransform={'capitalize'} color={'black.400'} fontWeight={'500'}>{exItem?.company?.name}</Text>
                        </Box>
                        <Box>
                            <Text textTransform={'capitalize'} color={'gray'} fontWeight={'500'} float={'left'}>{formatDate(exItem?.start_date, exItem?.end_date)}</Text>
                        </Box>

                        {renderLocation(exItem)}
                        <Box mt={2} hidden={readMore.experienceSummary[exItem?.start_date]}>
                            <Text>
                                <span>{exItem?.summary?.slice(0, 200)}</span>
                                {exItem?.summary?.length > 200 && <ReadMoreButton targetName={'experienceSummary'} targetValue={experienceSummaryReadMore} style={{'margin-left': '5px'}} />}
                            </Text>                            
                        </Box>
                        <Box mt={2} hidden={!readMore.experienceSummary[exItem?.start_date]}>
                            <Text>
                                <span>{exItem?.summary}</span>
                                <ReadLessButton targetName={'experienceSummary'} targetValue={experienceSummaryReadLess} style={{'margin-left': '5px'}} />
                            </Text>
                        </Box>
                    </Flex>
                    <Spacer />
                    <EditExperienceButton experience={exItem} index={index} />                       
                </Flex>
            )
        })
    }

    const CandidateExperience = () => {
        let expData = candidate?.details?.raw_data?.experience || [];
        const expDataLess = expData.slice(0, 4)      
        return(
            <Box mt={8}>
                <Flex>
                    <Text color={SchemeTextColor} fontSize={'16px'} fontWeight={'600'} mb={3}>Experience</Text>
                </Flex>
                {!isExperienceEmpty() ?        
                <>
                    <Box hidden={readMore.experience}>
                        <ExperienceItems experiences={expDataLess} />
                        {expData.length > 4 && <ReadMoreButton targetName={'experience'} targetValue={true} style={{'margin-left': '80px'}} />}
                    </Box>
                    <Box hidden={!readMore.experience}
                    >
                        <ExperienceItems experiences={expData} />
                        <ReadLessButton targetName={'experience'} targetValue={false} style={{'margin-left': '80px'}} />
                    </Box>
                </>
                :
                    <Text as={'b'}>N/A</Text>
                }
            </Box>
        )
    }

    return (
        <Box>                
            <Flex w={'100%'} direction={'column'}>                        
                <CandidateExperience />
            </Flex> 

            <EditCandidateExperience
                candidate={candidate}
                job={job}
                experienceForEdit={experienceForEdit}
                onEditExperiencePanelOpen={onEditExperiencePanelOpen}
                onEditExperiencePanelClose={onEditExperiencePanelClose}
                isEditExperiencePanelOpen={isEditExperiencePanelOpen}
                getAllCandidatesForJob={getAllCandidatesForJob}
            /> 
        </Box>
    );
}
