import React, { useState } from 'react';
import {
  Button,
  Text,
  Flex,
  Input,
  Drawer,
  DrawerContent,
  DrawerHeader,
  DrawerCloseButton,
  useToast,
  useColorModeValue,
} from '@chakra-ui/react';
import { getEmailId } from '../../../../utils/localStorageIndex';
import { submitCandidateByForm } from '../../../../services/recruiterServices';


export default function AddCandidateByForm(props) {
  const toast = useToast()
  const [isFormSubmitting, setIsFormSubmitting] = useState(false)
  const brandColor = useColorModeValue('brand.500', 'white');

  const submitForm = (e) => {
    e.preventDefault()
    const form = new FormData(e.target)
    form.set('job_uuid', props?.job?.job_uuid)
    form.set('email', getEmailId())
    setIsFormSubmitting(true);
    submitCandidateByForm(form)
    .then( res => {
        if (res.result){
          props.getAllCandidatesForJob()
          props.onAddCandidateByFormPanelClose()
          toast({
            title: 'Candidates added succesfully.',
            status: 'success', 
            isClosable: true, 
            duration: 3000
          });
        } else {
          toast({
            title: 'Unable to add candidate.',
            description: res.message,
            status: 'error', 
            isClosable: true, 
            duration: 3000
          });
        }
        setIsFormSubmitting(false);        
    })
    .catch( err =>  {
        setIsFormSubmitting(false);
        toast({
            title: 'Unable to add candidate.', 
            description: err.toString(),
            status: 'error', 
            isClosable: true, 
            duration: 3000
        });
    })
    return false
  }

  return (
    <Drawer size={'lg'} isOpen={props.isAddCandidateByFormPanelOpen} placement="right" onClose={props.onAddCandidateByFormPanelClose}>
      <DrawerContent pl={5} >
        <DrawerHeader>Add Candidate</DrawerHeader>
        <DrawerCloseButton />
        <form onSubmit={submitForm}>
          <Flex pl={'30px'} direction={'column'} mt={5}>
              <Flex direction={'column'}>
                <Flex>
                  <Text color={'red'} mr={1}>*</Text>
                  <Text as='b' mb={1} fontSize={'16px'}>First Name</Text>
                </Flex>
                <Input isRequired={true} name={'first_name'} width={'400px'} />
              </Flex>

              <Flex direction={'column'} mt={10}>
                <Flex>
                  <Text color={'red'} mr={1}>*</Text>
                  <Text as='b' mb={1} fontSize={'16px'}>Last Name</Text>
                </Flex>
                <Input isRequired={true} name={'last_name'} width={'400px'} />
              </Flex>

            <Flex direction={'column'} mt={10}>
              <Text as='b' mb={1} fontSize={'16px'}>Email</Text>
              <Input name={'candidate_email'} width={'400px'} />
            </Flex>

            <Flex direction={'column'} mt={10}>
              <Text as='b' mb={1} fontSize={'16px'}>Linkedin</Text>
              <Input name={'linkedin'} width={'400px'} />
            </Flex>

            <Flex direction={'column'} mt={10}>
              <Text as='b' mb={1} fontSize={'16px'}>Phone</Text>
              <Input name={'phone'} width={'400px'} />
            </Flex>

            <Button type='submit' width={'200px'} mt={10} variant="brand" _hover={{'bg': brandColor}}
                fontWeight="500" isLoading={isFormSubmitting} loadingText={'Adding...'}
            >
              Add
            </Button>
          </Flex>
        </form>  
      </DrawerContent>
    </Drawer>    
  );
}
