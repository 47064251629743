import React, { useEffect, useState } from 'react';
import { Link as ReactRouterLink } from 'react-router-dom';
import { useLocation, useNavigate, location } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import {Link as ChakraLink, DrawerHeader, Spacer, Tooltip} from '@chakra-ui/react';
import {
  Box,
  Icon,
  Flex,
  Image,
  Text,
  Modal,
  ModalContent,
  ModalCloseButton,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Drawer,
  Spinner,
  MenuButton,
  IconButton,
  MenuList,
  Menu,
  MenuItem,
  DrawerContent,
  useDisclosure,
  DrawerCloseButton,
  Input,
  useToast,
  useColorMode,
  useColorModeValue,
} from '@chakra-ui/react';
import { BiCalendar, BiLocationPlus, BiMailSend, BiPhone, BiStar, BiMoney } from 'react-icons/bi';
import { BiGlobe, BiPurchaseTag, BiTag, BiBook } from 'react-icons/bi';
import { MdOutlineSchool, MdPhoneInTalk, MdSchool } from "react-icons/md";
import {
  AiFillFilePdf,
  AiOutlineEllipsis,
  AiFillLinkedin,
  AiOutlineStar,
  AiOutlineLinkedin,
  AiFillEdit,
  AiOutlineLeftCircle,
  AiOutlineRightCircle,
  AiFillStar,
  AiOutlineMail,
} from 'react-icons/ai';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

import { defaultThemeColor } from '../../../constants/index';
import { getEmailId, getJob, getJobUuid } from '../../../utils/localStorageIndex';
import {
  changeProcessingHeadingData,
  fetchProcessingHeadingData,
  jobWorkflowKanbanList, //jobWorkflowInprocess,
  candidateKanbanStageUpdate, //candidateProcessingStage,
  jobWorkflowKanbanAction,
} from '../../../services/jobTemplateService';
import ResumePdfView from '../../business/jobWizard/jobWorkflow/Workflow/CandidateSearch/ResumePDFView';
import CandidateDetails from '../../business/jobWizard/jobWorkflow/Workflow/CandidateSearch/CandidateDetails';
import RejectionInfo from '../../business/jobWizard/jobWorkflow/Workflow/CandidateProcessing/RejectionInfo';
import CandidateRanking from '../../common/CandidateRanking';

import { setJobWorkflowStatus } from '../../../store/actions/topNotificationActions';
import SocialIcon from '../SocialIcon';
import NewInterviewSchedule from './../../../tscomponents/business/applicants/listingbyjob/components/NewInterviewSchedule';
import { fetchPersonalInfo } from '../../../services/sharedServices';
import { BsFileEarmarkPdf } from 'react-icons/bs';
import CandidateSalaryExpectations from '../CandidateSalaryExpectations';
import CandidateHighestEducation from '../CandidateHighestEducation';
import { toTitleCase } from '../../../utils/common';
import CandidateLocation from '../CandidateLocation';
import CandidateExperienceCount from '../CandidateExperienceCount';
import ScheduleInterviewYesButton from '../buttons/ScheduleInterviewYesButton';
import ScheduleInterviewNoButton from '../buttons/ScheduleInterviewNoButton';
import ReadyToInterviewYesButton from '../buttons/ReadToInterviewYesButton';
import ScheduleInterviewButton from '../buttons/ScheduleInterviewButton';
import OfferCandidateButton from '../buttons/OfferCandidateButton';
import RejectCandidateButton from '../buttons/RejectCandidateButton';
import CandidateLatestJob from '../CandidateLatestJob';
import CandidateWorkStatus from '../CandidateWorkStatus';
import CandidateHeadshot from '../CandidateHeadshot';
import RejectCandidate from '../../business/jobWizard/jobWorkflow/Workflow/CandidateSearch/RejectCandidate';

const StageOneSourcedCandidates = props => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const emaiId = getEmailId();
  const toast = useToast();
  const emailId = getEmailId();
  const { state } = useLocation();
  const urlParams = new URLSearchParams(window.location.search);
  const nylasAuthCode = urlParams.has('code') ? urlParams.get('code') : null;
  let selectedJob = props.job;
  if (nylasAuthCode){
    selectedJob = getJob();
  }

  const { colorMode } = useColorMode();
  const adminSettings = state?.adminSettings ? state?.adminSettings : null; //Distinguishes admin and business user
  const { isOpen, onOpen, onClose } = useDisclosure();
  const btnRef = React.useRef();
  const [tasks, setTasks] = useState([]);
  const [headingValue, setHeadingValue] = useState('');
  const { isOpen: isRejectionPanelOpen, onOpen: onRejectionPanelOpen, onClose: onRejectionPanelClose } = useDisclosure();

  const themeBorderColor = useColorModeValue(
    'secondaryGray.400',
    'whiteAlpha.100'
  );
  const themeButtonTextColor = useColorModeValue(
    'secondaryGray.900',
    'gray.700'
  );

  const [canPanOpen, setCanPanOpen] = useState(false);
  const [candidateForInterview, setCandidateForInterview] = useState({});
  const { isOpen: isInterviewPanelOpen, onOpen: onInterviewPanelOpen, onClose: onInterviewPanelClose } = useDisclosure();
  const [userDetails, setUserDetails] = useState({});

  const [candidateResumeUrl, setCandidateResumeUrl] = useState(null);
  const [componentSelected, setComponentSelected] = useState('');
  const [selectedCanidateId, setSelecetedCandidateId] = useState('');
  const [selectedCandidate, setSelectedCandidate] = useState({});
  const [candidateDetailsData, setCandidateDetailsData] = useState('');
  const [candidateProcessingLoader, setCandidateProcessingLoader] =
    useState(false);
  const [isModalOpen, setModalOpen] = useState(false);
  const [columnSelected, setColumnSelected] = useState({});
  const error = 'No candidate found for';

  const [columns, setColumns] = useState([
    {
      id: 'candidate_screening',
      processing_stage: 'Screening',
      isToggle: true,
    },
    {
      id: 'ready_to_interview',
      processing_stage: 'Ready To Interview',
      isToggle: true,
    },
    {
      id: 'scheduled_for_interview',
      processing_stage: 'Interviewing',
      isToggle: true,
    },
    {
      id: 'candidate_hired',
      processing_stage: 'Offer',
      isToggle: true,
    },
    {
      id: 'candidate_rejected',
      processing_stage: 'Rejected',
      isToggle: true,
    },
  ]);

  useEffect(() => {
    let objData = {
        email: emaiId,
    };
    fetchPersonalInfo(objData)
      .then(res => {
        if (res.result) {
          setUserDetails(res.data[0]);
        }
      })
  }, []);

  useEffect(() => {
    processingCandidateDetails();
    fetchHeadingData();
  }, [props?.workFlowReloadFlag]);

  const fetchHeadingData = () => {
    let objBody = {
      email: emailId,
      selection_name: selectedJob.job_uuid,
    };
    fetchProcessingHeadingData(objBody)
      .then(res => {
        // To do :Set heading name for colums
        if (res?.data && res.data[0]?.section_settings) {
          let storedObjData = res.data[0].section_settings;
          let cloneColumnArr = { ...columns };
          for (const key of Object.keys(cloneColumnArr)) {
            if (storedObjData.hasOwnProperty(key)) {
              cloneColumnArr[key].processing_stage = storedObjData[key];
            }
          }
          setColumns(cloneColumnArr);
        }
      })
      .catch(err => {
        console.log(err);
      });
  };

  const launchCandidateProfilePage = (candidate) => {
    if (adminSettings){
      navigate('/admin/jobworkflow/candidateview', {
        state: {
          candidate: candidate,
          job: selectedJob,
          sourcePage:'kanban',
          adminSettings: true
        },
      });
    } else {
      navigate('/business/jobsourcing/candidateview', {
        state: {
          candidate: candidate,
          job: selectedJob,
          sourcePage:'kanban',
          adminSettings: false
        },
      });
    }
  };

  async function copyTextToClipboard(text) {
    return await navigator.clipboard.writeText(text);
  }

  const processingCandidateDetails = () => {
    setCandidateProcessingLoader(true);
    let objBody = {
      email: emailId,
      job_uuid: selectedJob.job_uuid,
    };
    jobWorkflowKanbanList(objBody, adminSettings)
      .then(res => {
        setCandidateProcessingLoader(false);
        if (res.result && res.data.length) {          
          setTasks(res.data);
        } else {
          toast({
            title: "Unable to get candidate list",
            description: res?.message,
            status: 'error',
            duration: 4000,
          });
        }
      })
      .catch(err => {
        setCandidateProcessingLoader(false);
        toast({
          title: "Unable to get candidate list",
          description: err?.toString(),
          status: 'error',
          duration: 5000,
        });
      });
  };

  // modal functions
  const openModal = () => {
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
    setHeadingValue('');
    setColumnSelected({});
  };

  const onDragEnd = result => {
    const { destination, source, draggableId } = result;
    if (!destination) return;

    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return;
    }

    const updatedTasks = [...tasks];
    const task = updatedTasks.find(t => t.candidate_uuid === draggableId);

    if (source.droppableId == 'candidate_screening' && destination.droppableId != 'candidate_rejected' && !adminSettings){
      toast({
        title: "Cannot Move at this stage",
        description: 'Candidate is currently being screened. After screening, Candidate will be moved to "Ready to interview" or "Rejected" stage.',
        duration: 5000,
        status: 'info'
      })
      return
    }    

    if (destination.droppableId == 'scheduled_for_interview' && !adminSettings){
      setCandidateInterviewPanel(task);
    }

    if (destination.droppableId == 'candidate_rejected'){
      openRejectionPrompt(task);
    }

    task.processing_stage = destination.droppableId;
    setTasks(updatedTasks);
    setCandidateProcessingLoader(true);
    updateProcessingStage(draggableId, destination?.droppableId);
  };

  // update stage proecessing
  const updateProcessingStage = (candidateId, stage) => {
    dispatch(setJobWorkflowStatus(false));

    let obj = {
      email: emailId,
      job_uuid: selectedJob.job_uuid,
      candidate_uuid: candidateId,
      job_workflow_position: stage,
    };
    candidateKanbanStageUpdate(obj)
      .then(res => {
        dispatch(setJobWorkflowStatus(true));
        if (res?.result) {
          setCandidateProcessingLoader(false);
          processingCandidateDetails();
          toast({
            title: res?.message,
            status: 'success',
            isClosable: true,
            duration: 3000,
          });
        } else {
          console.log(res)
          setCandidateProcessingLoader(false);
          toast({
            title: 'Unable to complete the action',
            status: 'error',
            isClosable: true,
            duration: 3000,
          });
        }
      })
      .catch(err => {
        console.log(err);
        setCandidateProcessingLoader(false);
        toast({
          title: 'Unable to complete the action',
          status: 'error',
          isClosable: true,
          duration: 3000,
        });
      });
  };
  // fetch data to close drawer
  const handleDataFromChild = data => {
    if (data === 'candidate-state-changed') {
      processingCandidateDetails();
    }
    onClose();
  };

  // Toggle Column
  const toggleColumn = columnData => {
    const currentObjIndex = columns.findIndex(obj => obj.id === columnData.id);
    const updatedObj = Object.assign({}, columns[currentObjIndex]);
    updatedObj.isToggle = !columnData.isToggle;
    const newColumns = columns.slice();
    newColumns[currentObjIndex] = updatedObj;
    setColumns(newColumns);
  };

  // Todo:Change Heading Data
  const setHeaderName = () => {
    if (headingValue.length) {
      let cloneObj = { ...columns };
      cloneObj[columnSelected?.id].processing_stage = headingValue;
      // setColumns(cloneObj);
      let combinedObj = {};

      for (const [key, value] of Object.entries(cloneObj)) {
        let objValue = {
          [key]: value.processing_stage,
        };
        combinedObj = { ...combinedObj, ...objValue };
      }
      let objBody = {
        email: emailId,
        selection_name: selectedJob.job_uuid,
        selection_setting: combinedObj,
      };
      changeProcessingHeadingData(objBody)
        .then(res => {
          if (res.result) {
            fetchHeadingData();
          }
        })
        .catch(err => {
          console.log(err);
        });
    }
    closeModal();
  };


  // update stage proecessing
  const updateCandidateStage = (candidateId, job_uuid, new_position) => {
    let obj = {
      email: emaiId,
      job_uuid: job_uuid,
      candidate_uuid: candidateId,
      job_workflow_position: new_position,
    };
    candidateKanbanStageUpdate(obj)
      .then(res => {
        if (res?.result) {
          processingCandidateDetails();
        }
      })
      .catch(err => {
        console.log(err);
      });
  };

  const setCandidateInterviewPanel = (candidate) => {
    setCandidateForInterview(candidate);
    onInterviewPanelOpen();
  }

  const renderReadyToInterviewButtons = (candidate) => {
    if (
        (candidate.workflow_position === 'ready_to_interview'  || candidate.workflow_position === 'candidate_shortlisted')
        && !adminSettings
      ){
      return (
          <Flex p="2" fontSize={'12px'} alignItems="center">
            Schedule Interview:
            <ScheduleInterviewYesButton onClickHandler={setCandidateInterviewPanel} onClickHandlerArgs={[candidate]} />
            <ScheduleInterviewNoButton onClickHandler={openRejectionPrompt} onClickHandlerArgs={[candidate]} />
          </Flex>
        )
    }
  }

  const renderScreeningButtons = (candidate) => {
    if (
        (candidate.workflow_position === 'candidate_screening')
        && adminSettings
      ){
      return (
          <Flex p="2" fontSize={'12px'} alignItems="center">
            Ready To Interview:
            <ReadyToInterviewYesButton onClickHandler={updateCandidateStage} onClickHandlerArgs={[candidate.candidate_uuid, selectedJob.job_uuid, 'ready_to_interview']} />
          </Flex>
        )
    }
    
    if (candidate.workflow_position === 'candidate_screening'){
      return <>
          <Flex p="2" fontSize={'12px'} alignItems="center">
            <RejectCandidateButton onClickHandler={openRejectionPrompt} onClickHandlerArgs={[candidate]} />
          </Flex>
        </>
    }
  }

  const renderInterviewingButtons = (candidate) => {
    if (candidate.workflow_position === 'scheduled_for_interview'){
      return (
          <Flex p="2" fontSize={'12px'} alignItems="center">
            {!adminSettings && <ScheduleInterviewButton onClickHandler={setCandidateInterviewPanel} onClickHandlerArgs={[candidate]} /> }            
            <OfferCandidateButton onClickHandler={updateCandidateStage} onClickHandlerArgs={[candidate.candidate_uuid, selectedJob.job_uuid, 'candidate_hired']} />
            <RejectCandidateButton onClickHandler={openRejectionPrompt} onClickHandlerArgs={[candidate]} />            
          </Flex>
        )
    }
  }

  const openRejectionPrompt = (candidate) => {
    setSelectedCandidate(candidate);
    setSelecetedCandidateId(candidate?.candidate_uuid);
    onRejectionPanelOpen()
  }

  const CandidateSkillSet = ({candidate}) => {
    const skills = JSON.parse(candidate?.workflow?.tags_skill_set || "[]")
    return <>
      <Flex borderTopWidth={'1px'} alignItems="center" pt={1} pb={1} pl={2}>
        <Tooltip label={`Matching Skills`} fontSize='md'>
          <Flex alignItems={'center'}>
            <Icon fontSize={18} as={BiTag} />
            <Text ml={2} fontSize={14} >{skills.length ? skills?.join(', ') : "N/A"}</Text>
          </Flex>
        </Tooltip>
      </Flex>
    </>
  }

  return (
    <>
      <Flex>
        <Flex justifyContent="space-between" w="100%" mt="2">
          <DragDropContext onDragEnd={onDragEnd}>
            {Object.values(columns).map(column =>
              column?.isToggle ? (
                <Box
                  key={column.id}
                  mr="3"
                  w="380px"
                  borderRadius={'10px'}
                  bg={colorMode === 'dark' ? '#111c44' : '#fff'}
                  boxShadow="xl"
                >
                  <Flex
                    p="2"
                    alignItems="center"
                    justifyContent="space-between"
                    cursor="pointer"
                  >
                    <Text textAlign="center" fontWeight="bold">
                      {column.processing_stage}
                      <Text
                        as="span"
                        ml="2"
                        fontSize={'14px'}
                        fontWeight={'normal'}
                      >
                        {`(${
                          tasks.filter(
                            task => task.workflow_position === column.id
                          ).length
                        })`}
                      </Text>
                    </Text>
                    {/* {adminSettings === 'true' ? null : ( */}
                      <>
                        <Box>
                          <Icon
                            as={AiOutlineLeftCircle}
                            onClick={() => {
                              toggleColumn(column);
                            }}
                          />
                          {adminSettings != 'true' 
                          ?<Menu>
                            <MenuButton
                              as={IconButton}
                              aria-label="Options"
                              icon={<AiOutlineEllipsis />}
                              p="0"
                              bg="none"
                              h="10px"
                              _hover={{ bg: 'none' }}
                              _active={{ bg: 'none' }}
                            />
                            <MenuList>
                              <MenuItem
                                fontSize="sm"
                                px="2"
                                py="0"
                                onClick={() => {
                                  openModal();
                                  setColumnSelected(column);
                                }}
                              >
                                Change Heading
                              </MenuItem>
                            </MenuList>
                          </Menu>:null}
                        </Box>
                      </>
                    {/* )} */}
                  </Flex>

                  <Flex
                    overflow="auto"
                    borderTopWidth="0px"
                    minH={'100%'}
                    width={'100%'}
                    css={{
                      '&::-webkit-scrollbar': {
                        width: '4px',
                      },
                      '&::-webkit-scrollbar-track': {
                        width: '6px',
                      },
                      '&::-webkit-scrollbar-thumb': {
                        borderRadius: '24px',
                      },
                    }}
                  >
                    {candidateProcessingLoader ? (
                      <Flex alignItems="center" justifyContent="center" mt="10">
                        <Spinner size="xl" color={'#d7d7d7'} />
                      </Flex>
                    ) : (
                      <Droppable droppableId={column.id}>
                        {(provided, snapshot) => (
                          <Box
                            minH={'100%'}
                            width={'100%'}
                            ref={provided.innerRef}
                            {...provided.droppableProps}
                            style={{
                              padding: '8px',
                            }}
                          >
                            {tasks?.length ? (
                              tasks
                                .filter(
                                  task => task.workflow_position === column.id
                                )
                                .map((task, index) => (
                                  <Draggable
                                    key={task.candidate_uuid}
                                    draggableId={task.candidate_uuid}
                                    index={index}
                                    isDragDisabled={false}
                                  >
                                    {(provided, snapshot) => (
                                      <Box
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                        bg={
                                          colorMode === 'dark'
                                            ? '#1b254b'
                                            : '#fff'
                                        }
                                        borderRadius="10px"
                                        borderColor={themeBorderColor}
                                        borderWidth="0.5px"
                                        mb="10px"
                                        _hover={{
                                          borderColor: 'gray.500',
                                          borderWidth: '0.5px',
                                        }}
                                        boxShadow="xl"
                                      >
                                        <Box ref={btnRef}>
                                          <Box p={2}>
                                            <Flex mt={3}>
                                              <CandidateHeadshot candidate={task} mRight={'15px'}/>
                                              <Flex width={'75%'} direction={'column'} onClick={() => launchCandidateProfilePage(task)} rounded={'md'} pl={1}>
                                                <Text cursor={'pointer'} lineHeight={'12px'} color={defaultThemeColor} fontWeight="bold" fontSize={'16px'}>
                                                  {`${task?.first_name} ${task?.last_name}`}
                                                </Text>
                                                <CandidateLocation candidate={task} mTop={2} />
                                                <CandidateLatestJob candidate={task} rawData={task?.details?.raw_data} mTop={2} />
                                                <CandidateHighestEducation candidate={task} rawData={task?.details?.raw_data} mTop={2} />
                                                <CandidateSalaryExpectations salary={JSON.parse(task?.expectation || "{}")?.salary} mTop={2}/>                                                
                                                {adminSettings && <CandidateWorkStatus candidate={task} mTop={2}/> }
                                              </Flex>
                                            </Flex>
                                          </Box>
                                          <CandidateSkillSet candidate={task} />
                                          <Flex borderTopWidth={'1px'} borderBottomWidth={'1px'} alignItems="center" pt={1} pl={2}>
                                          {task?.email
                                            ?<ChakraLink to={'javascript:void(0)'} mr={1} onClick={ () => {
                                                copyTextToClipboard(task?.email).then(
                                                    toast({
                                                        title: "Email copied to clipboard!",
                                                        status: "success",
                                                        duration: 3000
                                                    })
                                                )
                                            }}>
                                                <Tooltip label={`${task?.email}`} fontSize='md'>
                                                    <span><Icon as={AiOutlineMail} fontSize={'22px'} /></span>
                                                </Tooltip>                        
                                            </ChakraLink>:null}
                                            {task?.phone && 
                                                <ChakraLink to={'javascript:void(0)'} mr={1} onClick={ () => {
                                                    copyTextToClipboard(task?.phone).then(
                                                        toast({
                                                            title: "Phone copied to clipboard!",
                                                            status: "success",
                                                            duration: 3000
                                                        })
                                                    )
                                                }}>
                                                    <Tooltip label={`${task?.phone}`} fontSize='md'>
                                                        <span><Icon as={MdPhoneInTalk} fontSize={'20px'} /></span>
                                                    </Tooltip>
                                                </ChakraLink>
                                            }
                                            {task?.resume ? (
                                              <ChakraLink
                                                to={'javascript:void(0)'}  
                                                mr={1}
                                                onClick={() => {
                                                  setCandidateResumeUrl(task?.resume);
                                                  onOpen();
                                                  setComponentSelected('resume');
                                                }}                                                  
                                              >
                                                <Icon as={BsFileEarmarkPdf} fontSize={16} fontWeight={'bold'}/>
                                              </ChakraLink>
                                            ) : null}

                                            {task?.linkedin &&
                                              <ChakraLink as={ReactRouterLink} isExternal to={task?.linkedin}>
                                                <Icon fontSize={20} fontWeight={'bold'}
                                                  mr={1} color={'blue.400'} as={AiFillLinkedin} />
                                              </ChakraLink>
                                            }
                                            <SocialIcon socialData={JSON.parse(task?.social)} />
                                          </Flex>
                                                                                      
                                          {renderScreeningButtons(task)}
                                          {renderReadyToInterviewButtons(task)}
                                          {renderInterviewingButtons(task)}
                                        </Box>
                                      </Box>
                                    )}
                                  </Draggable>
                                ))
                            ) : (
                              <Text
                                fontSize="14px"
                                fontWeight={'bold'}
                                textAlign="left"
                              >
                                {error}{' '}
                                <Text as="span" textTransform={'lowercase'}>
                                  {column.processing_stage}
                                </Text>
                              </Text>
                            )}
                            {provided.placeholder}
                          </Box>
                        )}
                      </Droppable>
                    )}
                  </Flex>
                </Box>
              ) : (
                <Box
                  w="40px"
                  h="calc(100vh - 210px)"
                  mr="10px"
                  borderRadius={'5px'}
                  bg={colorMode === 'dark' ? '#11385b' : 'gray.200'}
                  position={'relative'}
                  cursor={'pointer'}
                  p="1"
                >
                  <Icon
                    as={AiOutlineRightCircle}
                    mt="2"
                    ml="1"
                    onClick={() => {
                      toggleColumn(column);
                    }}
                  />
                  <Text
                    mt="1"
                    whiteSpace={'noWrap'}
                    fontSize={'18px'}
                    fontWeight={'bold'}
                    transform="rotate(270deg)"
                    display={'flex'}
                    alignItems={'center'}
                    justifyContent={'center'}
                    h="200px"
                  >
                    {column.processing_stage}
                  </Text>
                </Box>
              )
            )}
          </DragDropContext>
        </Flex>

        <Drawer
          size={'xl'}
          isOpen={isOpen}
          placement="right"
          onClose={onClose}
          finalFocusRef={btnRef}
        >
          <DrawerContent overflow={'auto'} pl={5}>
            <DrawerHeader>
                        {componentSelected === 'resume' ? 'Candidate Resume' : null}
                        {componentSelected === 'reject' ? `Reject ${selectedCandidate.first_name} ${selectedCandidate.last_name}` : null}
            </DrawerHeader>
            <DrawerCloseButton />
            componentSelected === 'resume' ? (
              candidateResumeUrl != null ? (
                <ResumePdfView pdfUrl={candidateResumeUrl} />
              ) : (
                <Text>Error with the PDf Url</Text>
              )
            ) : componentSelected === 'rejection-info' ? (
              <RejectionInfo
                job_uuId={selectedJob.job_uuid}
                candidate_uuid={selectedCanidateId}
              />
            ) : (
              <CandidateDetails
                job_uuId={selectedJob.job_uuid}
                candidate_uuid={selectedCanidateId}
                details={candidateDetailsData}
                sendDataToParent={handleDataFromChild}
              />
            )}
          </DrawerContent>
        </Drawer>

        <RejectCandidate
          selectedCandidates={[selectedCandidate]}
          isRejectionPanelOpen={isRejectionPanelOpen}
          onRejectionPanelClose={onRejectionPanelClose}
          postRejectActions={{
            processingCandidateDetails: () => processingCandidateDetails()
          }}
        />
        {/* popup modal to change heading name */}
        <Modal
          borderWidth="1px"
          borderColor={defaultThemeColor}
          isOpen={isModalOpen}
          onClose={closeModal}
        >
          <ModalContent>
            <ModalHeader fontSize={'16px'}>Change Heading</ModalHeader>
            <ModalCloseButton />
            <ModalBody pb={6}>
              <Input
                size="md"
                type="text"
                placeholder="Enter Heading Name"
                value={headingValue}
                onChange={e => {
                  setHeadingValue(e.target.value);
                }}
              ></Input>
            </ModalBody>

            <ModalFooter>
              <Button
                size="sm"
                colorScheme="green"
                borderRadius={'5px'}
                mr={3}
                onClick={() => {
                  setHeaderName();
                }}
              >
                Save
              </Button>

              <Button
                size="sm"
                borderRadius={'5px'}
                colorScheme="red"
                onClick={closeModal}
              >
                Cancel
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>

      <NewInterviewSchedule
        {
            ...{
                onInterviewPanelOpen,
                onInterviewPanelClose,
                isInterviewPanelOpen,
                candidateForInterview,
                selectedJob,
                userDetails,
                updateCandidateStage,
            }
        }
        />
      </Flex>
    </>
  );
};

export default StageOneSourcedCandidates;
