import React, { useEffect, useState } from 'react';
import {
  Text,
  Flex, Icon, Box,
  AlertTitle,
  Spinner,
  useToast,
  SimpleGrid,
  useColorModeValue,
  Input,
  Button,
  Heading,
  Spacer,
  Badge,
} from '@chakra-ui/react';

import { getEmailId } from '../../../../utils/localStorageIndex.js';
import { fetchAllActiveJobList } from '../../../../services/jobTemplateService.js';
import { AiOutlineUser } from 'react-icons/ai';
import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
} from '@chakra-ui/react';
import DraftJob from '../../applicants/listingbyjob/components/DraftJob.js';
import { isObjectEmpty } from '../../../../utils/common.js';
import { useLocation, useNavigate } from 'react-router-dom';

// Assets
export default function CalendarJobListing(props) {
  const { state } = useLocation();
  const business = state?.business
  const adminSettings = state?.adminSettings ? state.adminSettings : false
  const toast = useToast();

  const { ...rest } = props;
  // Chakra Color Mode
  const textColorPrimary = useColorModeValue('secondaryGray.900', 'white');
  const [loading, isLoading] = useState(false);
  const email = getEmailId();  
  const navigate = useNavigate();
  const [noRecordFlag, setNoRecordFlag] = useState(true);
  const [tableData, setTableData] = useState([]);
  const [alertMessage, setAlertMessage] = useState('No Jobs Found');
  const borderColorStyle = useColorModeValue('gray.300', 'gray.600'); // {light, dark}

  const [selectJobUuid, setSelectJobUuid] = useState(null);
  const [openJobs, setOpenJobs] = useState([]);
  const [draftJobs, setDraftJobs] = useState([]);
  const [selectedJobs, setSelectedJobs] = useState([]);
  const [selectedJobsType, setSelectedJobsType] = useState('open')
  const [searchQuery, setSearchQuery] = useState(null);
  const [allJobs, setAllJobs] = useState([]);
  const textColorBadge = "gray.700";

  useEffect( () => {
    filterJobsBySearchQuery();
  }, [searchQuery])

  useEffect(() => {
    const openJobs = tableData.filter( job => job.job_status.toLowerCase() == 'open');
    setOpenJobs(openJobs);

    const draftJobs = tableData.filter( job => job.job_status.toLowerCase() == 'draft');
    setDraftJobs(draftJobs);

    setSelectedJobs(selectedJobsType == 'open' ? openJobs : draftJobs);

  }, [tableData])

  const showOpenJobs = () => {
    if (selectedJobsType == 'open'){
      return
    }

    setSelectedJobsType('open');
    props.setSelectedJobsType('open');
    setSelectedJobs(openJobs);
    props.setSelectedDraftJob({});
    props.setSelectedCandidateUuid(null);
    props.setSelectedJobUuid(null);
    props.setSelectedJobTitle(null);
  }

  const showDraftJobs = () => {
    if (selectedJobsType == 'draft'){
      return
    }

    setSelectedJobsType('draft');
    props.setSelectedJobsType('draft');
    setSelectedJobs(draftJobs);
    props.setSelectedDraftJob({});
    props.setSelectedCandidateUuid(null);
    props.setSelectedJobUuid(null);
    props.setSelectedJobTitle(null);
  }

  useEffect(() => {
    fetchJobWorkflowFlowData();
  }, []);

  const fetchJobWorkflowFlowData = () => {
    isLoading(true);
    let objData = {
      email: email,
      business_uuid: business?.business_uuid || null
    };
    fetchAllActiveJobList(objData)
      .then(res => {
        isLoading(false);
        if (res?.data.length > 0 && res?.result) {
          setNoRecordFlag(false);
          setTableData(res.data);
          setAllJobs(res.data);
        } else {
          setNoRecordFlag(false);
          setTableData([]);
          setAllJobs([]);
          toast({
            title: res?.message,
            status: 'error',
            isClosable: true,
            duration: 3000,
          });
          setAlertMessage(res?.message);
        }
      })
      .catch(err => {
        isLoading(false);
        setNoRecordFlag(true);
        toast({
          title: err?.response?.message,
          status: 'error',
          isClosable: true,
          duration: 3000,
        });
        setAlertMessage('Oops Something Went Wrong !!!');
      });
  };

  const getCandidates = data => {
    setSelectJobUuid(data?.job_uuid);
    props.setSelectedJobUuid(data?.job_uuid);
    props?.handleCallback(data);
  };

  const setCandidateUuid = data => {
    launchCandidateProfilePage(data, props.selectedJob);
    // props?.setupCandidateUuid(data);
  };

  const launchCandidateProfilePage = (candidate, job) => {
    const route = adminSettings ? '/admin/jobworkflow/candidateview' : '/business/jobsourcing/candidateview'
    navigate(route, {
        state: {
            candidate: candidate,
            job: job,
            business: business,
            sourcePage:'interviewing',
            adminSettings: adminSettings
        },
    });
  };

  const renderCandidateData = props.scheduledCandidateArr?.map((candidate, index) => (
    <Flex      
      key={index}
      m={1}
      alignItems="center"
      borderWidth="0.5px"
      borderColor={borderColorStyle}
      rounded={'lg'}
      p={'3px 10px'}
      onClick={() => {
        launchCandidateProfilePage(candidate, props.selectedJob);
      }}
      _hover={{ cursor:'pointer'}}
      bg={candidate?.candidate_uuid === props.selectedCandidateUuid ? 'green.100' : ''}
      mt={index == 0 ? '6px' : 'auto'}
      mb={'6px'}
    >
      <Icon w={5} h={5} as={AiOutlineUser} mr="2"></Icon>
      <Box>
        <Text color={textColorPrimary} fontSize={14}>
          {candidate.first_name} {' '} {candidate.last_name}
        </Text>
        <Text color={textColorPrimary} fontSize={12}>
          {candidate.email}
        </Text>
      </Box>
    </Flex>
  ));

  const onChangeSearchQuery = (q) => {
    setSearchQuery(q);
  }
  
  const filterJobsBySearchQuery = () => {
    if (searchQuery){
        const filteredJobsAndCandidates = allJobs?.filter(job => job?.job_title.toLowerCase().search(searchQuery.toLowerCase()) != -1);
        setTableData(filteredJobsAndCandidates);
      }
    else {
        setTableData(allJobs);
    }
  }

  const onJobSelectionChange = (index) => {
    const job = selectedJobs[index]
    if (isObjectEmpty(job)){ // accordion may have been collapsed
      return
    }

    if (job.job_status.toLowerCase() == 'draft'){
        props.setSelectedDraftJob(job);
        return
    }

    getCandidates(job);
  }

  return (
    <Box mb={{ base: '0px', '2xl': '20px' }} {...rest}>
      {loading ? (
        <>
          <Flex alignItems="center" justifyContent="center" mt="10" h={'40vh'}>
            <Spinner size="xl" />
          </Flex>
        </>
      ) : (
        <Box>

          <Input borderRadius={'25px'} mb={'10px'} placeholder='Search job'
              onChange={e => onChangeSearchQuery(e.target.value)}
            />

          <Flex 
              textAlign={'center'}
              justifyContent={'space-between'}
              alignItems={'center'}
              borderWidth={'0.1px 0.1px 0px 0.1px'}
              borderTopRadius={'6px'}
              height={'40px'}
              color={'gray.500'}
              borderBottomWidth={tableData.length > 0 ? '0px' : '0.1px'}            
          >
              <Button w={'50%'} fontWeight={'300'} variant={'ghost'} 
                onClick={showOpenJobs}
                color={selectedJobsType == 'open' ? 'blue.500' : null}
              _ hover={{'cursor': selectedJobsType == 'open' ? 'default' : null}}
              >
                Open ({openJobs.length})
              </Button>
              
              <Button w={'50%'} fontWeight={'300'} variant={'ghost'}
                onClick={showDraftJobs}
                color={selectedJobsType == 'draft' ? 'blue.500' : null}
                _hover={{'cursor': selectedJobsType == 'draft' ? 'default' : null}}
              >
                Drafts ({draftJobs.length})
              </Button>
          </Flex>

        <SimpleGrid columns="1">
        <Accordion allowToggle onChange={onJobSelectionChange} >
          {
            selectedJobs?.map((data, index) => (
              <Flex w={'100%'} direction={'column'} >
                  <AccordionItem
                    borderWidth={'0.1px 0.1px 0px 0.1px'}
                    borderBottomRadius={index == selectedJobs.length - 1 ? '6px !important' : '0px !important'}
                    borderBottomWidth={index == selectedJobs.length - 1 ? '0.1px !important' : '0px !important'}
                    borderColor={borderColorStyle}                 
                  >
                    <Heading>
                      <AccordionButton height={'80px'}>
                        <Flex direction={'column'} width={'100%'}>
                          <Flex justifyContent={'space-between'}>
                            <Box textAlign='left' color={textColorPrimary} fontWeight='500' fontSize='lg'>
                              {data?.job_title}
                            </Box>

                            { selectedJobsType == 'open' ? 
                              <Box>
                                <Spacer />
                                <AccordionIcon textAlign={'right'} />
                              </Box>
                            :
                              null
                            }
                          </Flex>

                          <Flex alignItems={'center'} mt={'5px'} textAlign={'left'} fontSize={'.75rem'} fontWeight={'500'}>
                            <Text>Candidates:</Text>
                            <Badge ml={'30px'} bg={data?.count === 0 ? 'red.100' : 'green.100'} color={textColorBadge}>{data?.count}</Badge>
                          </Flex>
                        </Flex>
                      </AccordionButton>
                    </Heading>

                    {/* hide accordion panel for draft jobs */}
                    {selectedJobsType == 'open' ?                    
                      <AccordionPanel p={props.scheduledCandidateArr?.length > 0 ? 1 : 0}>
                        {
                          selectJobUuid == data?.job_uuid && props.scheduledCandidateArr && props.scheduledCandidateArr?.length > 0
                          ?
                          renderCandidateData
                          :
                          null
                        }
                      </AccordionPanel>
                      :
                      null
                    }
                  </AccordionItem>

              </Flex>
            ))
          }
         </Accordion>
        </SimpleGrid>
        </Box>
      )}
    </Box>
  );
}
