export const jobFunctionJsonList = [
  {
    factive_code: '5000',
    descriptor: 'Administration',
    description:
      'Oversees the general operation or administration of the company. Generally this is assigned to any management level other than the Highest-Level Management, Chief Financial Officer, Chief Operating Officer or Chief Technology Officer.',
  },
  {
    factive_code: '5100',
    descriptor: 'Advertising',
    description:
      'Anything related to advertising, or calling attention to the public. This may include the creative and promotional aspect of the company',
  },
  {
    factive_code: '1250',
    descriptor: 'Chairman (Managing Board)',
    description:
      'Includes the Chairman, Vice or Deputy Chairman, Chairwoman, Chairperson of the Managing Board.',
  },
  {
    factive_code: '1200',
    descriptor: 'Chairman (Supervisory or Board of Directors)',
    description:
      'Includes the Chairman, Vice or Deputy Chairman, Chairwoman, Chairperson of the Supervisory Board or Board of Directors.',
  },
  {
    factive_code: '1300',
    descriptor: 'Chief Executive Officer',
    description:
      'This person is responsible for overseeing the operation and strategies of the company at the highest level. A Managing Director in European countries and a President in Japan is the equivalent of the CEO.',
  },
  {
    factive_code: '1600',
    descriptor: 'Chief Financial Officer',
    description:
      'The top financial executive who is responsible financial planning and record keeping for the company.',
  },
  {
    factive_code: '1500',
    descriptor: 'Chief Operating Officer',
    description:
      'The top operational executive who is responsible for day-to-day management of the company.',
  },
  {
    factive_code: '1700',
    descriptor: 'Chief Technology/Information Officer',
    description:
      'The top executive responsible for application development and information management for the company.',
  },
  {
    factive_code: '0500',
    descriptor: 'C-Level Management',
    description:
      'Key executives who usually report to the President or Chief Executive Officer such as other Chief Officers, Senior, Group and Executive Vice Presidents.',
  },
  {
    factive_code: '2400',
    descriptor: 'Company Secretary',
    description:
      'The Company Secretary is responsible for corporate governance matters such as committee meetings and proxy statement preparation. This position is often appointed by and sits on the Board. This individual may also have administrative responsibilities in non-US companies.',
  },
  {
    factive_code: '5300',
    descriptor: 'Corporate Communications',
    description:
      'Deals with the communications and public relations of a company. The director of shareholder services, and spokesman for the company would be included in this category.',
  },
  {
    factive_code: '5200',
    descriptor: 'Customer Service',
    description:
      'Related to the ability of a company to satisfy their customer’s needs. Includes assistance, the call and help desks, and client services.',
  },
  {
    factive_code: '5400',
    descriptor: 'Engineering/Technical/Scientific',
    description:
      'Dealing with the mathematical, scientific and mechanical aspects of a company. Includes engineer, software designer, and draftsman',
  },
  {
    factive_code: '2200',
    descriptor: 'Executive Vice President',
    description: 'Includes Executive, Senior and Group Vice Presidents.',
  },
  {
    factive_code: '5500',
    descriptor: 'Facilities',
    description:
      'A physical plant, such as a building or a complex which provides the means or makes easier the performance of a company function. One example would be a warehouse',
  },
  {
    factive_code: '5600',
    descriptor: 'Finance',
    description:
      'Responsible for managing company financial activities. Includes Controllers, Treasurers, CFOs as well other financial executives and managers',
  },
  {
    factive_code: '2050',
    descriptor: 'General Manager',
    description:
      'A general manager has a broad, overall responsibility for a business or organization. They manage through subordinate managers, and have the power to hire, fire or promote employees. He / she is responsible for the overall strategic planning and direction of a company and leaves the day-to-day management of the various functions to the managers',
  },
  {
    factive_code: '5700',
    descriptor: 'Government and Public Affairs',
    description:
      'Related to professional level positions that deal with government and public affairs. This function may include positions that range from planning, developing and implementing projects for the public to positions held within a corporation such as a Vice President of Public Affairs. Also includes government officials such as legislators, diplomats and government administrators.',
  },
  {
    factive_code: '5800',
    descriptor: 'Healthcare and Medical Specialty',
    description:
      'Any job function or title related to the maintenance and restoration of health. Such titles as doctor, nurse, Medicare, and prescription are allocated here.',
  },
  {
    factive_code: '0100',
    descriptor: 'Highest Level Management',
    description:
      'This represents the most senior executive for a company. It can include President, Managing Director, Owner, Partner, General Manager and Chief Executive Officer. The level of authority for these functions can vary based on geographic location and company size.',
  },
  {
    factive_code: '5900',
    descriptor: 'Human Resources',
    description:
      'An integrated function dealing with human resources, including payroll, personnel records, training, and employee benefits.',
  },
  {
    factive_code: '6000',
    descriptor: 'Information Systems/Data Processing',
    description:
      'Any title related to information systems and data processing. Includes the storing and updating of computer processed text, and the writing of software.',
  },
  {
    factive_code: '6100',
    descriptor: 'Insurance and Risk Management',
    description:
      'Any field dealing with the insurance of a company. This may include risk management, underwriting, and the policy holder.',
  },
  {
    factive_code: '6200',
    descriptor: 'Legal',
    description:
      'Any function dealing with the law or legal department of a company. Some key words include lawyer, contracts, and litigation.',
  },
  {
    factive_code: '2100',
    descriptor: 'Managing Director',
    description:
      'The level of authority of this function varies greatly based on geographic location.',
  },
  {
    factive_code: '6300',
    descriptor: 'Manufacturing and Production',
    description:
      'Anything dealing with manufacturing, production, distribution and inventory in a company.',
  },
  {
    factive_code: '6400',
    descriptor: 'Marketing',
    description:
      'Dealing with the process or technique of moving goods from producer to consumer. Includes commerce, business and product development, as well as merchandising.',
  },
  {
    factive_code: '6450',
    descriptor: 'Media',
    description:
      'Functions involving the numerous methods used in communication. Media encompasses all processes utilized in this field, including editing, publishing and broadcasting.',
  },
  {
    factive_code: '1900',
    descriptor: 'MeMember of the Board (Supervisory or Directors)dia',
    description:
      'Any director or member of the Board of Directors or Supervisory Board other than the Chairman.',
  },
  {
    factive_code: '2000',
    descriptor: 'Member of the Management Board',
    description:
      'Any director or member of the Managing Board other than the Chairman.',
  },
  {
    factive_code: '6500',
    descriptor: 'Operations',
    description:
      'Relating to the operations of a company, including the aspect of trade, import and export',
  },
  {
    factive_code: '7500',
    descriptor: 'Other',
    description: 'Represents a job function category not specified here.',
  },
  {
    factive_code: '1800',
    descriptor: 'Other Chief Officers',
    description:
      'Includes other corporate officers such as Chief Marketing Officer, Chief Advertising Officer and Chief Sales Officer.',
  },
  {
    factive_code: '2600',
    descriptor: 'Other Executive',
    description: 'Any other person designated as an executive of the company.',
  },
  {
    factive_code: '1000',
    descriptor: 'Owner',
    description: 'Includes partners, shareholders…',
  },
  {
    factive_code: '1400',
    descriptor: 'President',
    description:
      'The corporate President of the company. The level of authority and duties for this function can vary based on geographic location and company size.',
  },
  {
    factive_code: '6700',
    descriptor: 'Purchasing and Procurement',
    description:
      'Positions related to the buying and obtaining of a company. Supplier exchange and the consumer are located under this title',
  },
  {
    factive_code: '6800',
    descriptor: 'Quality',
    description:
      'to the characteristics by which clients or customers judge a company’s product or service. This includes such functions as product control',
  },
  {
    factive_code: '6900',
    descriptor: 'Real Estate',
    description:
      'Deals with the possession of land and property of a company. Includes the construction, property development and mortgage of land',
  },
  {
    factive_code: '2300',
    descriptor: 'Regional / Division / Sector / Branch President',
    description:
      'This individual is usually responsible for overseeing company operations of a specific division, product, sector or region.',
  },
  {
    factive_code: '7000',
    descriptor: 'Research & Development',
    description:
      'Deals with the changing and developing aspects of a company. Can range from the investigation of new ideas to the advent of new products.',
  },
  {
    factive_code: '7100',
    descriptor: 'Sales',
    description:
      'Relating to the commerce and sales of a particular company, primarily their trade, retail and accounts',
  },
  {
    factive_code: '7200',
    descriptor: 'Strategic Planning/Business Development',
    description:
      'Any functions related to the preparation and growth of a business, mainly mergers and acquisitions',
  },
  {
    factive_code: '7400',
    descriptor: 'Training and Education',
    description:
      'Aspect of a company or institution that deals with instructing or teaching to help develop a skill. This function includes titles that range from teacher to training manager.',
  },
  {
    factive_code: '7300',
    descriptor: 'Transport and Logistics',
    description:
      'The components of a company that make up its transport and the details of an operation. This includes transportation, travel, and shipping.',
  },
  {
    factive_code: '2500',
    descriptor: 'Treasurer / Controller / Comptroller',
    description:
      'The corporate treasurer of the company is responsible for the receipt and disbursement of corporate funds, and designing and implementing many of the firms financing and investing activities.Titles include Treasurer, Assistant Treasurer, Controller and Comptroller.',
  },
];
